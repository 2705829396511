<script>
import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

/* COMPOSANTS */
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import AkInputText from '@components/input/AkInputText';
import AkDropdown from '@components/input/AkDropdown';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import documentModelTypeConst from '@mixins/const/documentModelTypeConst';

/* SERVICES */
import documentModelService from '@services/documentModelService';
import GaView from '@components/layout/GaView';
import backMixin from '@mixins/backMixin';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';

export default {
  components: {GaView, AkDropdown, AkFormSubmitted, AkInputText},
  mixins: [randomRef, backMixin, loaderMixin, messageMixin, documentModelTypeConst],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'document_model.update',
    };
  },
  data() {
    return {
      submitted: false,
      documentModel: {},
    };
  },
  validations() {
    return {
      documentModel: {
        type: {required},
        name: {required},
        subType: {required},
      },
    };
  },
  computed: {
    getSubType() {
      if (this.documentModel.type) return this.childrenForDocumentModelType(this.documentModel.type);
      return [];
    },
  },
  watch: {
    'documentModel.type': function (newType, oldType) {
      if (oldType != undefined) this.documentModel.subType = undefined;
    },
  },
  mounted() {
    this.showTotalLoader();
    documentModelService.documentModel(this.$route.params.documentModelId).then((data) => {
      this.documentModel = data;
      this.hideLoader();
    });
  },
  methods: {
    update() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$error) return;
      this.showLoader(this.$refs.form);
      documentModelService
        .update(this.documentModel)
        .then((data) => {
          this.hideLoader();
          this.success(this.$t('document_model.updated'));
          this.submitted = false;
          this.documentModel = data;
        })
        .catch((e) => {
          this.hideLoader();
          this.addError(this.$t('error.' + e.response.data.error));
          this.submitted = false;
        });
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :title="$t('document_model.update')">
    <template #back>
      <a class="mb-1 d-block pointer" @click="goBack()"><i class="fe fe-arrow-left pr-1" />{{ $t('back') }}</a>
    </template>
    <template #content>
      <AkFormSubmitted :submitted="submitted" reference="form" @submit="update()">
        <div class="form-row">
          <AkInputText
            v-model="documentModel.name"
            :label="$t('document_model.name')"
            :validator="v$.documentModel.name"
            class-name="col-md-4" />
          <AkDropdown
            v-model="documentModel.type"
            :label="$t('document_model.type')"
            :options="documentModelTypeConst"
            :validator="v$.documentModel.type" />
          <AkDropdown
            v-if="documentModel.type"
            v-model="documentModel.subType"
            :label="$t('document_model.subtype')"
            :options="getSubType"
            :validator="v$.documentModel.subType" />
        </div>
        <button class="btn btn-primary float-right">
          {{ $t('update') }}
        </button>
      </AkFormSubmitted>
    </template>
  </GaView>
</template>
