<script>
import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

/* COMPOSANTS */
import AkInputText from '@components/input/AkInputText';
import AkCheckbox from '@components/input/AkCheckbox';
import AkDialog from '@components/general/AkDialog';
import GaView from '@components/layout/GaView';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import itemLabelService from '@services/itemLabelService';

export default {
  components: {GaView, AkInputText, AkCheckbox, AkDialog},
  mixins: [randomRef, utilsMixin, messageMixin],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'settings.raison_achat_hote',
    };
  },
  data() {
    return {
      loading: true,
      list: [],
      item: {
        type: 'RAISON_ACHAT_HOTE',
        enabled: true,
        label: '',
      },
    };
  },
  validations() {
    return {
      item: {
        label: {required},
      },
    };
  },
  mounted() {
    itemLabelService.itemLabels({type: 'RAISON_ACHAT_HOTE'}).then((data) => {
      this.list = data;
      this.loading = false;
    });
  },
  methods: {
    create() {
      this.v$.$touch();
      if (this.v$.$error) return;

      this.getRef().showTotalLoader();
      itemLabelService
        .create(this.item)
        .then((data) => {
          this.getRef().success(this.$t('item_label.raison_achat_hote_added'));
          this.list.push(data);
        })
        .finally(() => {
          this.getRef().hideLoader();
          this.$refs.dialogCreate.hide();
        });
    },
    update() {
      this.v$.$touch();
      if (this.v$.$error) return;

      this.getRef().showTotalLoader();
      itemLabelService
        .update(this.item)
        .then((data) => {
          this.replaceInListById(this.list, data);
          this.getRef().success(this.$t('item_label.raison_achat_hote_updated'));
        })
        .finally(() => {
          this.getRef().hideLoader();
          this.$refs.dialogEdit.hide();
        });
    },
    delete() {
      itemLabelService
        .delete(this.item)
        .then((data) => {
          this.removeInListById(this.list, data.id);
          this.getRef().success(this.item.label + ' a bien été supprimé');
        })
        .catch((e) => {
          this.addError(this.$t('item_label.error_item_label_achat_hote_used'));
        });
    },

    openCreateDialog() {
      this.item = {
        type: 'RAISON_ACHAT_HOTE',
        enabled: true,
        label: '',
      };
      this.$refs.dialogCreate.show();
    },
    openEditDialog(item) {
      this.item = {...item};
      this.$refs.dialogEdit.show();
    },
    openDeleteDialog(item) {
      this.item = item;
      this.$refs.dialogDelete.show();
    },
    increasePosition(item) {
      this.loading = true;
      itemLabelService.increasePosition(item).then(() => {
        this.increaseInListById(this.list, item.id);
        this.loading = false;
      });
    },
    decreasePosition(item) {
      this.loading = true;
      itemLabelService.decreasePosition(item).then(() => {
        this.decreaseInListById(this.list, item.id);
        this.loading = false;
      });
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :title="$t('settings.raison_achat_hote')">
    <template #action>
      <div class="d-flex justify-content-end">
        <button class="btn btn-primary float-right" @click="openCreateDialog()">
          <i class="gst-plus pr-1" />
          {{ $t('add') }}
        </button>
      </div>
    </template>

    <template #content>
      <DataTable
        :always-show-paginator="false"
        :loading="loading"
        :paginator="true"
        :rows="10"
        :rows-per-page-options="[10, 20, 50]"
        :value="list"
        class="table"
        current-page-report-template="{first}-{last}/{totalRecords}"
        removable-sort
        responsive-layout="scroll"
        sort-field="position"
        striped-rows>
        <template #empty>
          {{ $t('item_label.raison_achat_hote_empty') }}
        </template>
        <Column :header="$t('item_label.position')" field="position" header-style="width: 130px; text-align: center">
          <template #body="slotProps">
            <div class="d-flex">
              <span
                v-if="slotProps.index !== 0"
                class="btn btn-xs btn-inverse-primary mr-1"
                @click="decreasePosition(slotProps.data)">
                <i class="fe fe-arrow-up" />
              </span>
              <span
                v-if="slotProps.index !== list.length - 1"
                :style="slotProps.index === 0 ? 'margin-left:46px;' : ''"
                class="btn btn-xs btn-inverse-primary mr-1"
                @click="increasePosition(slotProps.data)">
                <i class="fe fe-arrow-down" />
              </span>
            </div>
          </template>
        </Column>
        <Column :header="$t('item_label.label')" field="label">
          <template #body="slotProps">
            <span>{{ slotProps.data.label }}</span>
          </template>
        </Column>
        <Column :header="$t('item_label.enabled')" field="enabled">
          <template #body="slotProps">
            <i v-if="slotProps.data.enabled" class="ti ti-check text-primary" />
            <i v-else class="ti ti-close" />
          </template>
        </Column>
        <Column body-style="text-align: right; overflow: visible" header-style=" width: 160px; text-align: btn">
          <template #body="slotProps">
            <div class="d-flex justify-content-end">
              <span class="btn btn-xs btn-inverse-primary mr-1 pointer" @click="openEditDialog(slotProps.data)">
                <i class="fe fe-edit" />
              </span>
              <span class="btn btn-xs btn-inverse-danger pointer" @click="openDeleteDialog(slotProps.data)">
                <i class="fe fe-trash" />
              </span>
            </div>
          </template>
        </Column>
      </DataTable>
    </template>

    <template #extra>
      <AkDialog
        ref="dialogCreate"
        :title="$t('item_label.raison_achat_hote')"
        :validate-label="$t('add')"
        width="450px"
        @validate="create()">
        <div class="form-row">
          <AkInputText
            v-model="item.label"
            :label="$t('item_label.label')"
            :validator="v$.item.label"
            class-name="col-md-12" />
          <AkCheckbox v-model="item.enabled" :label="$t('item_label.enabled')" class-name="col-md-12" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogEdit"
        :title="$t('item_label.raison_achat_hote')"
        :validate-label="$t('update')"
        width="450px"
        @validate="update()">
        <div class="form-row">
          <AkInputText
            v-model="item.label"
            :label="$t('item_label.label')"
            :validator="v$.item.label"
            class-name="col-md-12" />
          <AkCheckbox v-model="item.enabled" :label="$t('item_label.enabled')" class-name="col-md-12" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogDelete"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.delete()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span>{{ $t('item_label.confirm_delete_raison_achat_hote') }}</span>
        </div>
      </AkDialog>
    </template>
  </GaView>
</template>
