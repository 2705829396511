<script>
/* COMPOSANTS */
import PButton from 'primevue/button';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import loaderMixin from '@mixins/loaderMixin';
import maintenanceStatusConst from '@mixins/const/maintenanceStatusConst';

/* SERVICES */
import maintenanceService from '@services/maintenanceService';

export default {
  components: {
    PButton,
  },
  mixins: [randomRef, utilsMixin, messageMixin, permissionsMixin, sidebarMixin, loaderMixin, maintenanceStatusConst],
  metaInfo() {
    return {
      title: 'maintenance.list',
    };
  },
  data() {
    return {
      loading: false,
      filter: {
        page: 1,
        limit: 10,
        sort: ['id:DESC'],
      },
      maintenances: [],
      submitted: false,
    };
  },
  watch: {},
  mounted() {
    this.loadStatuses();
    this.refresh();
  },
  computed: {},
  methods: {
    loadStatuses() {
      this.filter.statuses = this.maintenanceStatusConstNotResolueForFilterOnlyValues();
    },
    refresh() {
      this.loading = true;
      maintenanceService
        .maintenances(this.filter)
        .then((data) => {
          this.maintenances = data;
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openFilterPanel() {
      this.toggleFilter('GaMaintenanceListFilter', this.filter, this.refresh, this.resetFilter);
    },
    resetFilter() {
      this.filter = {};
      this.toggleFilter('GaMaintenanceListFilter', this.filter, this.refresh, this.resetFilter);
    },
    exportMaintenances() {
      this.showTotalLoaderWithAfter(this.$t('litige.list_creation_in_progress'));
      maintenanceService.exportMaintenances(this.filter).then((data) => {
        this.hideLoader();
      });
    },
    rowClick(event) {
      let data = event.data;
      this.$router.push({
        name: 'maintenanceDetails',
        params: {maintenanceId: data.id},
      });
    },

    isStatusDecisionPrise(status) {
      return status === 'DECISION_PRISE';
    },
  },
};
</script>

<template>
  <GaContentView :ref="ref" :title="$t('maintenance.list')">
    <template #action>
      <div class="d-flex justify-content-end" style="column-gap: 16px">
        <PButton
          class="btn btn-inverse-primary btn-xs"
          :badge="this.countFilter(this.filter)"
          badgeClass="p-badge-primary"
          label="Filtrer"
          @click="openFilterPanel()">
        </PButton>
        <button class="btn btn-inverse-primary btn-xs" @click="exportMaintenances()">
          {{ this.$t('payment.download') }}
        </button>
      </div>
    </template>
    <template #content>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive">
                <DataTable
                  :always-show-paginator="false"
                  :loading="loading"
                  :paginator="true"
                  :rows="10"
                  :rows-per-page-options="[10, 20, 50]"
                  :value="maintenances"
                  class="pointer"
                  current-page-report-template="{first}-{last}/{totalRecords}"
                  responsive-layout="scroll"
                  striped-rows
                  removable-sort
                  sortField="displayPriority"
                  :sortOrder="-1"
                  @row-click="rowClick($event)">
                  <template #empty>
                    {{ $t('maintenance.empty') }}
                  </template>
                  <Column :header="$t('maintenance.accommodationId')" :sortable="true" sort-field="accommodationName">
                    <template #body="slotProps">
                      {{ slotProps.data.accommodationName }}
                    </template>
                  </Column>
                  <Column :header="$t('maintenance.tag')" :sortable="true" sort-field="displayTag">
                    <template #body="slotProps">
                      {{ slotProps.data.displayTag }}
                    </template>
                  </Column>
                  <Column :header="$t('maintenance.date_maintenance')" :sortable="true" sort-field="dateMaintenance">
                    <template #body="slotProps">
                      {{ this.displayDate(slotProps.data.dateMaintenance) }}
                    </template>
                  </Column>
                  <Column :header="$t('maintenance.date_resolution')" :sortable="true" sort-field="resolutionDate">
                    <template #body="slotProps">
                      {{ this.displayDate(slotProps.data.resolutionDate) }}
                    </template>
                  </Column>
                  <Column :header="$t('maintenance.problem')" :sortable="true" sort-field="problem">
                    <template #body="slotProps">
                      {{ slotProps.data.problem }}
                    </template>
                  </Column>
                  <Column :header="$t('maintenance.status')" :sortable="true" sort-field="displayStatusOrder">
                    <template #body="slotProps">
                      <span v-if="isStatusDecisionPrise(slotProps.data.status)">
                        {{ slotProps.data.displayOwnerChoice }}
                      </span>
                      <span v-else>
                        {{ slotProps.data.displayStatus }}
                      </span>
                    </template>
                  </Column>
                  <Column :header="$t('maintenance.priority')" :sortable="true" sort-field="displayPriority">
                    <template #body="slotProps">
                      {{ slotProps.data.displayPriority }}
                    </template>
                  </Column>
                  <Column
                    body-style="text-align: center; overflow: visible"
                    header-style=" width: 180px; text-align: center">
                    <template #body="slotProps">
                      <div class="d-flex justify-content-end align-items-center">
                        <router-link
                          :to="{name: 'maintenanceDetails', params: {maintenanceId: slotProps.data.id}}"
                          class="btn btn-xs btn-inverse-primary mr-1">
                          <i class="fe fe-eye" />
                        </router-link>
                      </div>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </GaContentView>
</template>
