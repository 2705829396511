<script>
import {Icon} from '@iconify/vue/dist/iconify';

/* COMPOSANTS */
import Checkbox from 'primevue/checkbox';
import AkDropdown from '@components/input/AkDropdown.vue';
import AkCalendar from '@components/input/AkCalendar.vue';
import PrestationBadge from '@/components/general/PrestationBadge';
import AkAccommodationAutocomplete from '@components/general/AkAccommodationAutocomplete.vue';
import AkReservationAutocomplete from '@components/general/AkReservationAutocomplete.vue';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import prestationTypesConst from '@mixins/const/prestationTypesConst';
import missionMonitoringStatusConst from '@mixins/const/missionMonitoringStatusConst';

/* SERVICES */

export default {
  components: {
    AkDropdown,
    AkCalendar,
    AkAccommodationAutocomplete,
    AkReservationAutocomplete,
    Icon,
    Checkbox,
    PrestationBadge,
  },
  mixins: [permissionsMixin, missionMonitoringStatusConst, prestationTypesConst, sidebarMixin, utilsMixin],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.accommodationAutocomplete) this.$refs.accommodationAutocomplete.reset();
      this.callback[1]();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    prestationTypesList() {
      return this.prestationTypesConst.slice(0, -2);
    },
    missionMonitoringStatusList() {
      return this.missionMonitoringStatusConst.slice(0, -1);
    },
  },
};
</script>

<template>
  <h2>Filtrer</h2>
  <form class="d-flex flex-column" style="row-gap: 16px" @submit.prevent="search">
    <!-- Accommodation -->
    <AkAccommodationAutocomplete
      ref="accommodationAutocomplete"
      v-model="currentValue.accommodationId"
      :placeholder="$t('accommodation_title')"
      class-name="flex-1 form-control"
      inline />
    <!-- Numéro de réservation -->
    <AkReservationAutocomplete
      ref="reservationAutocomplete"
      v-model="currentValue.reservationId"
      :label="$t('planning_intervenant.reservation')"
      :placeholder="$t('planning_intervenant.reservation')"
      :useReservationId="true"
      inline
      class-name="flex-1 form-control" />
    <!-- Date de check-in : réservation -->
    <AkCalendar
      v-model="currentValue.dateCheckin"
      className="flex-1 form-control"
      :inline="true"
      :placeholder="$t('reservation.dateCheckin')"
      date-format="dd/mm/yy"
      selection-mode="single" />
    <!-- Date de check-out : réservation -->
    <AkCalendar
      v-model="currentValue.dateCheckout"
      className="flex-1 form-control"
      :inline="true"
      :placeholder="$t('reservation.dateCheckout')"
      date-format="dd/mm/yy"
      selection-mode="single" />
    <!-- Statut -->
    <AkDropdown
      v-model="currentValue.status"
      :label="$t('mission_monitoring.status')"
      :options="missionMonitoringStatusList"
      class-name="flex-1 form-control"
      inline
      option-label="label"
      option-value="value" />
    <!-- Types de mission : à améliorer -->
    <div>
      <h6>{{ $t('planning_intervenant.mission_type') }}</h6>
      <div>
        <div
          v-for="prestationType in prestationTypesList"
          :key="prestationType.value"
          class="d-flex align-items-center mb-2">
          <Checkbox
            v-model="currentValue.prestationTypes"
            :inputId="prestationType.value"
            :value="prestationType.value"
            class="col-2"
            name="prestationType" />
          <PrestationBadge :type="prestationType.value" class="col-4" />
        </div>
      </div>
    </div>
    <!-- Recherche -->
    <div class="d-flex flex-row">
      <button class="btn btn-primary btn-search flex-1" type="submit">
        {{ this.$t('search.filter') }}
      </button>
      <Icon
        :style="{fontSize: '50px', width: '50px !important'}"
        class="pointer"
        icon="basil:cross-outline"
        v-on:click="this.reset()" />
    </div>
  </form>
</template>
