<script>
import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

/* COMPOSANTS */
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import documentModelService from '@services/documentModelService';
import AkDropdown from '@components/input/AkDropdown';

/* MIXINS */
import messageMixin from '@mixins/messageMixin';
import loaderMixin from '@mixins/loaderMixin';

/* SERVICES */

export default {
  mixins: [messageMixin, loaderMixin],
  setup() {
    return {v$: useVuelidate()};
  },
  props: {
    type: {type: String, required: true},
    subType: {type: String, required: true},
    title: {type: String, required: true},
    call: {type: Function, required: true},
    width: {type: String, default: '600px'},
    cancelLabel: {type: String},
    validateLabel: {type: String},
    autoHideOnValidate: {type: Boolean, default: false},
    autoHideOnCancel: {type: Boolean, default: true},
  },
  components: {AkDropdown, AkFormSubmitted},
  emits: ['show', 'hide', 'cancel'],
  data() {
    return {
      display: false,
      submitted: false,
      list: undefined,
      templateId: undefined,
    };
  },
  validations() {
    return {
      templateId: {required},
    };
  },
  mounted() {
    documentModelService.list({type: this.type, subtype: this.subtype, hasTemplateId: true}).then((e) => {
      this.list = e;
    });
  },
  methods: {
    show() {
      this.submitted = false;
      this.setDisplay(true);
      this.$emit('show');
    },
    hide() {
      this.setDisplay(false);
      this.$emit('hide');
    },
    cancel() {
      if (this.autoHideOnCancel) this.setDisplay(false);
      this.$emit('cancel');
    },
    validate() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$error) return;

      this.showTotalLoaderWithAfter(this.$t('document_model.in_progress'));
      this.call(this.templateId)
        .then(() => {
          if (this.autoHideOnValidate) this.setDisplay(false);
        })
        .finally(() => {
          this.hideLoader();
        });
    },
    setDisplay(value) {
      this.display = value;
    },
  },
  computed: {
    buildCancelLabel() {
      return this.cancelLabel ? this.cancelLabel : this.$t('cancel');
    },
    buildValidateLabel() {
      return this.validateLabel ? this.validateLabel : this.$t('validate');
    },
  },
};
</script>

<template>
  <PDialog v-model:visible="display" :modal="true" :style="{width: width}" :header="title" @hide="cancel">
    <AkFormSubmitted :submitted="submitted" reference="form">
      <div class="form-row">
        <AkDropdown
          v-model="templateId"
          label="Modèle de document"
          :options="list"
          option-label="name"
          :validator="v$.templateId"
          option-value="templateId"
          class-name="col-12" />
      </div>
    </AkFormSubmitted>

    <template #footer>
      <span class="btn btn-outline-primary btn-xs mr-1" @click="cancel">
        {{ buildCancelLabel }}
      </span>
      <span class="btn btn-primary btn-xs" @click="validate">
        {{ buildValidateLabel }}
      </span>
    </template>
  </PDialog>
</template>
