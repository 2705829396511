<script>
import useVuelidate from '@vuelidate/core';
import {required, requiredIf} from '@vuelidate/validators';
import bigDecimal from 'js-big-decimal';

/* COMPOSANTS */
import AkInputNumber from '@components/input/AkInputNumber';
import AkInputMoney from '@components/input/AkInputMoney';
import AkDropdown from '@components/input/AkDropdown';
import AkCalendar from '@components/input/AkCalendar';
import AkDialog from '@components/general/AkDialog';
import AkOwnerAutocomplete from '@components/general/AkOwnerAutocomplete';
import AkInputTextArea from '@components/input/AkInputTextArea';
import AkFilesUpload from '@components/input/AkFilesUpload';
import AkFicItem from '@components/layout/AkFicItem';
import PButton from 'primevue/button';

/* MIXINS */
import messageMixin from '@mixins/messageMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import labelODMixin from '@mixins/labelODMixin';
import {PermissionMap} from '@mixins/const/permissionConst';
import permissionsMixin from '@mixins/permissionsMixin';
import ownerChargeTypeConst from '@mixins/const/ownerChargeTypeConst';

/* SERVICES */
import mandatService from '@services/mandatService';
import itemLabelService from '@services/itemLabelService';
import ownerChargeService from '@services/ownerChargeService';
import ficService from '@services/ficService';
import comptaCommissionService from '@services/comptaCommissionService';

export default {
  components: {
    AkInputTextArea,
    AkFicItem,
    AkFilesUpload,
    AkOwnerAutocomplete,
    AkDialog,
    AkCalendar,
    AkDropdown,
    AkInputMoney,
    AkInputNumber,
    PButton,
  },
  mixins: [messageMixin, utilsMixin, sidebarMixin, labelODMixin, permissionsMixin, ownerChargeTypeConst],
  metaInfo() {
    return {
      title: 'facturation_hote.facturation_hote',
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      loading: false,
      errors: [],
      files: [],
      items: [],
      search: {},
      item: {},
      mandats: [],
      motifDeplacementList: [],
      raisonAchatList: [],
      achatHoteList: [],
      hourlyRateValue: null,
      fileAction: null,
      isMotifDeplacementEmpty: false,
      isRaisonAchatHoteEmpty: false,
      comptaCommission: {},
    };
  },
  validations() {
    return {
      item: {
        ownerId: {required},
        mandatId: {required},
        motifId: {required: requiredIf(this.isMotifRequired)},
        type: {required},
        date: {required: requiredIf(this.isDateRequired)},
        dateTransaction: {required},
        duration: {required: requiredIf(this.isFraisDeMaintenance)},
        amount: {required: requiredIf(this.isAmountRequired)},
        label: {required: requiredIf(this.isLabelRequired)},
        comment: {required: requiredIf(this.isCommentRequired)},
      },
      files: {required: requiredIf(this.isFilesRequired)},
    };
  },
  watch: {
    'item.ownerId'(newVal, oldVal) {
      this.item.mandatId = null;
      if (this.item.ownerId) {
        this.getMandatList();
      }
    },
    'item.mandatId'(newVal, oldVal) {
      this.getHourlyRate();
    },
    'item.dateTransaction'(newVal, oldVal) {
      this.getHourlyRate();
    },
    'item.type'(newVal, oldVal) {
      this.errors = [];
      this.item.motifId = null;
      if (this.isAchatHote) {
        this.item.duration = null;
      }
      if (this.isFraisDeMaintenance) {
        this.item.amount = null;
        this.item.label = null;
        this.item.comment = null;
        this.files = [];
      }
      if (this.isForfaitHivernage) {
        this.item.label = null;
        this.item.date = null;
        this.item.motifId = null;
        this.item.duration = null;
        this.files = [];
      }
    },
  },
  mounted() {
    this.refresh();
  },
  computed: {
    hasManagePermission() {
      return this.hasPermission(PermissionMap.FACTURATION_HOTE.MANAGE);
    },
    buildLabelChoice() {
      if (this.isFraisDeMaintenance) return this.labelODfraisDeMaintenance;
      if (this.isFraisDeMenageHote) return this.labelODfraisDeMenageHote;
      if (this.isAchatHote) return this.labelODachatHote;
      if (this.isOuvertureHivernage) return this.labelOdOuvertureHivernage;
      if (this.isFermetureHivernage) return this.labelOdFermetureHivernage;
      return null;
    },
    isDateRequired() {
      if (!this.item) return false;
      return this.isAchatHote || this.isFraisDeMaintenance;
    },
    isMotifRequired() {
      if (!this.item) return false;
      return this.isAchatHote || this.isFraisDeMaintenance;
    },
    isAmountRequired() {
      if (!this.item) return false;
      return this.isAchatHote || this.isForfaitHivernage || this.isFraisDeMenageHote;
    },
    isLabelRequired() {
      if (!this.item) return false;
      return this.isAchatHote || this.isForfaitHivernage || this.isFraisDeMenageHote;
    },
    isCommentRequired() {
      if (!this.item) return false;
      return this.isAchatHote;
    },
    isFraisDeMaintenance() {
      if (!this.item) return false;
      return this.item.type === 'FRAIS_DE_MAINTENANCE';
    },
    isAchatHote() {
      if (!this.item) return false;
      return this.item.type === 'ACHAT_HOTE';
    },
    isOuvertureHivernage() {
      if (!this.item) return false;
      return this.item.type === 'OUVERTURE_HIVERNAGE';
    },
    isFermetureHivernage() {
      if (!this.item) return false;
      return this.item.type === 'FERMETURE_HIVERNAGE';
    },
    isForfaitHivernage() {
      return this.isOuvertureHivernage || this.isFermetureHivernage;
    },
    isFraisDeMenageHote() {
      if (!this.item) return false;
      return this.item.type === 'FRAIS_DE_MENAGE_HOTE';
    },
    isOwnerSelected() {
      if (!this.item) return false;
      return this.item.ownerId !== null && this.item.ownerId !== undefined;
    },
    isFilesRequired() {
      return this.isAchatHote;
    },
    displayAmountDuration() {
      if (!this.item.duration || !this.hourlyRateValue) return null;
      let amount = new bigDecimal(0);
      amount = amount.add(new bigDecimal(this.item.duration));
      amount = amount.multiply(new bigDecimal(this.hourlyRateValue));
      return parseFloat(amount.round(2, bigDecimal.RoundingModes.HALF_EVEN).getValue());
    },
  },
  methods: {
    refresh() {
      this.files = [];
      this.item = {};
      this.loading = true;

      let p1 = ownerChargeService.ownerCharges(this.search);
      p1.then((data) => {
        this.items = data;
      });

      let p3 = itemLabelService.itemLabels({type: 'MOTIF_DEPLACEMENT', enabled: true});
      p3.then((data) => {
        this.motifDeplacementList = data;
        this.isMotifDeplacementEmpty = this.motifDeplacementList.length === 0;
      });

      let p4 = itemLabelService.itemLabels({type: 'RAISON_ACHAT_HOTE', enabled: true});
      p4.then((data) => {
        this.raisonAchatList = data;
        this.isRaisonAchatHoteEmpty = this.raisonAchatList.length === 0;
      });

      let p5 = itemLabelService.itemLabels({type: 'ACHAT_HOTE', enabled: true});
      p5.then((data) => {
        this.achatHoteList = data;
        for (let achatHote of this.achatHoteList) {
          this.labelODachatHote.push(achatHote.label);
        }
      });

      Promise.all([p3, p4, p5]).then(() => {
        this.loading = false;
      });
    },
    getMandatList() {
      mandatService
        .mandatsByOwner(this.item.ownerId)
        .then((data) => {
          this.mandats = data;
        })
        .catch((error) => {})
        .finally(() => {});
    },
    getHourlyRate() {
      if (!this.item || !this.item.mandatId) return;
      let filter = {
        typeCommission: 'FRAIS_DE_MAINTENANCE_TAUX',
        mandatId: this.item.mandatId,
        date: this.item.dateTransaction,
      };
      comptaCommissionService
        .comptaCommissionForDate(filter)
        .then((data) => {
          this.comptaCommission = data;
          this.hourlyRateValue = this.comptaCommission.fraisDeMaintenanceTaux / 60;
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {});
    },
    getAmountDuration(duration) {
      if (!this.hourlyRateValue) return null;
      let amount = new bigDecimal(0);
      amount = amount.add(new bigDecimal(duration));
      amount = amount.multiply(new bigDecimal(this.hourlyRateValue));
      return parseFloat(amount.round(2, bigDecimal.RoundingModes.HALF_EVEN).getValue());
    },
    openCreateDialog() {
      this.errors = [];
      this.item = {
        date: new Date(),
        dateTransaction: new Date(),
        status: 'IN_PROGRESS',
      };
      this.$refs.dialogCreate.show();
    },
    displayComment(slotProp) {
      this.showComment(slotProp);
    },
    create() {
      this.errors = [];
      this.v$.$touch();
      if (this.v$.files.$errors.length > 0) {
        this.errors.push(this.$t('ticket.error_empty_files'));
      }
      if (this.v$.$error) return;
      this.$refs.dialogCreate.hide();

      if (this.isFraisDeMaintenance) {
        this.item.amount = this.displayAmountDuration;
      }

      ownerChargeService
        .create(this.item, this.files)
        .then(() => {
          this.success(this.$t('facturation_hote.owner_charge_added'));
        })
        .catch((e) => {
          this.addError(this.$t('error.' + e.response.data.error));
        })
        .finally(() => {
          this.loading = false;
          this.refresh();
        });
    },
    resetFilter() {
      this.search = {};
      this.toggleFacturationHoteFilterList(this.search, this.refresh, this.resetFilter);
    },
    openFilterPanel() {
      this.toggleFacturationHoteFilterList(this.search, this.refresh, this.resetFilter);
    },
    confirmDeleteFile(file) {
      this.fileAction = file;
      this.$refs.dialogDelete.show();
    },
    deleteFile() {
      ficService
        .delete(this.fileAction)
        .then((e) => {
          this.success(this.$t('facturation_hote.files_deleted'));
        })
        .catch(() => {
          this.addError(this.$t('facturation_hote.error'));
        })
        .finally(() => {
          this.refresh();
        });
    },
    downloadFile(file, isPreview) {
      ficService.download(file, isPreview).catch((e) => {
        if (e.response.status === 404) {
          this.addError(this.$t('facturation_hote.error_files_missing'));
        }
      });
    },
  },
};
</script>
<template>
  <GaView ref="ref" :title="$t('facturation_hote.facturation_hote')">
    <template #action>
      <div class="d-flex justify-content-end" style="gap: 16px">
        <PButton
          :badge="this.countFilter(this.search)"
          badgeClass="p-badge-primary"
          class="btn btn-primary"
          :label="this.$t('facturation_hote.filter')"
          @click="openFilterPanel()" />
        <button v-if="hasManagePermission" class="btn btn-primary" @click="openCreateDialog()">
          {{ this.$t('add') }}
        </button>
      </div>
    </template>

    <template #content>
      <DataTable
        :always-show-paginator="false"
        :loading="loading"
        :paginator="true"
        :rows="50"
        :rows-per-page-options="[10, 20, 50]"
        :value="this.items"
        class="table"
        current-page-report-template="{first}-{last}/{totalRecords}"
        removable-sort
        responsive-layout="scroll"
        sort-field="position"
        striped-rows>
        <template #header>
          <div class="d-flex align-items-center justify-content-start header-legend-result">
            <span v-if="this.items.length > 0"> {{ this.$t('payment.number_results') }} {{ this.items.length }} </span>
          </div>
        </template>
        <template #empty>
          {{ $t('facturation_hote.empty') }}
        </template>

        <Column :header="this.$t('facturation_hote.date_created')" field="date" :sortable="true" style="width: 5%">
          <template #body="slotProps">
            {{ this.displayDate(slotProps.data.date) }}
          </template>
        </Column>

        <Column
          :header="this.$t('facturation_hote.date_transaction')"
          field="dateTransaction"
          :sortable="true"
          style="width: 5%">
          <template #body="slotProps">
            {{ this.displayDate(slotProps.data.dateTransaction) }}
          </template>
        </Column>

        <Column :header="this.$t('facturation_hote.type')" :sortable="true" field="type" style="width: 5%">
          <template #body="slotProps">
            <span v-if="slotProps.data.type">
              {{ this.$t('facturation_hote.' + slotProps.data.type) }}
            </span>
          </template>
        </Column>

        <Column :header="this.$t('facturation_hote.status')" :sortable="true" field="status" style="width: 5%">
          <template #body="slotProps">
            <span v-if="slotProps.data.type">
              {{ this.$t('facturation_hote.' + slotProps.data.status) }}
            </span>
          </template>
        </Column>

        <Column :header="this.$t('owner.ownerDisplay')" :sortable="true" field="ownerDisplay" style="width: 10%">
          <template #body="slotProps">
            {{ slotProps.data.ownerDisplay }}
          </template>
        </Column>

        <Column :header="this.$t('operation.mandat')" :sortable="true" field="mandatDisplay" style="width: 35%">
          <template #body="slotProps">
            {{ slotProps.data.mandatDisplay }}
          </template>
        </Column>

        <Column :header="this.$t('facturation_hote.motif')" :sortable="true" field="motifDisplay" style="width: 10%">
          <template #body="slotProps">
            {{ slotProps.data.motifDisplay }}
          </template>
        </Column>

        <Column :header="this.$t('operation.label')" :sortable="true" field="label" style="width: 10%">
          <template #body="slotProps">
            <div class="d-flex align-items-center">
              {{ slotProps.data.label }}
              <i
                v-if="slotProps.data.comment"
                class="fa fa-info-circle ml-1 pointer"
                @click="displayComment(slotProps.data)"></i>
            </div>
          </template>
        </Column>

        <Column
          :header="this.$t('facturation_hote.amount')"
          :sortable="true"
          class="text-right"
          field="amount"
          style="width: 5%">
          <template #body="slotProps">
            <span v-if="slotProps.data.amount">
              {{ this.formatCurrency(slotProps.data.amount, 'EUR') }}
            </span>
          </template>
        </Column>

        <Column
          :header="this.$t('operation.minutes')"
          :sortable="true"
          class="text-right"
          field="duration"
          style="width: 5%">
          <template #body="slotProps">
            <span v-if="slotProps.data.duration"> {{ this.formatNumber(slotProps.data.duration) }} min </span>
          </template>
        </Column>

        <Column :header="this.$t('files')" style="width: 10%">
          <template #body="slotProps">
            <div v-for="(item, index) in slotProps.data.fics" v-show="slotProps.data.fics.length" :key="item.id">
              <AkFicItem
                v-model="slotProps.data.fics[index]"
                :fic-preview="false"
                :canDelete="true"
                @delete="confirmDeleteFile"
                @download="downloadFile" />
            </div>
          </template>
        </Column>
      </DataTable>

      <AkDialog
        ref="dialogCreate"
        :title="$t('facturation_hote.add_owner_charge')"
        :validate-label="$t('add')"
        width="800px"
        @validate="create()">
        <div v-if="this.item">
          <div class="form-row">
            <AkDropdown
              v-model="item.type"
              :label="$t('facturation_hote.type')"
              :options="this.ownerChargeTypeConst"
              :validator="v$.item.type"
              optionLabel="label"
              optionValue="value"
              class-name="col-md-6" />
          </div>
          <div class="form-row">
            <AkCalendar
              v-if="isFraisDeMaintenance || isAchatHote"
              v-model="item.date"
              :label="
                isFraisDeMaintenance ? $t('facturation_hote.date_intervention') : $t('facturation_hote.date_purchase')
              "
              :validator="v$.item.date"
              class-name="col-md-6" />
            <AkCalendar
              v-if="isFraisDeMaintenance || isAchatHote || isForfaitHivernage || isFraisDeMenageHote"
              v-model="item.dateTransaction"
              :label="this.$t('accounting.date')"
              :validator="v$.item.dateTransaction"
              class-name="col-md-6" />
          </div>
          <div class="form-row">
            <AkOwnerAutocomplete
              v-if="isFraisDeMaintenance || isAchatHote || isForfaitHivernage || isFraisDeMenageHote"
              ref="ownerAutocomplete"
              v-model="item.ownerId"
              :ownerStatus="'CUSTOMER'"
              :accommodationPublished="true"
              :label="$t('owner.ownerDisplay')"
              :placeholder="$t('owner.ownerDisplay')"
              :validator="v$.item.ownerId"
              class-name="col-md-6" />
            <AkDropdown
              v-if="
                isOwnerSelected && (isFraisDeMaintenance || isAchatHote || isForfaitHivernage || isFraisDeMenageHote)
              "
              ref="dropdownMandat"
              v-model="item.mandatId"
              :label="$t('operation.mandat')"
              :options="mandats"
              optionLabel="display"
              optionValue="id"
              :validator="v$.item.mandatId"
              class-name="col-md-6" />
            <AkInputNumber
              v-if="isFraisDeMaintenance"
              v-model="item.duration"
              :label="$t('facturation_hote.deplacement_minutes')"
              :validator="v$.item.duration"
              :fraction-digits="0"
              class-name="col-md-6"
              :min="0"
              suffix=" minutes" />
            <AkInputMoney
              v-if="isFraisDeMaintenance && item.duration"
              v-model="displayAmountDuration"
              :label="$t('operation.amount')"
              :disabled="true"
              class-name="col-md-6" />
          </div>
          <div class="form-row">
            <AkDropdown
              v-if="isFraisDeMaintenance"
              v-model="item.motifId"
              :label="$t('item_label.motif_deplacement')"
              :options="motifDeplacementList"
              optionLabel="label"
              optionValue="id"
              :validator="v$.item.motifId"
              class-name="col-md-6" />
          </div>
          <div class="form-row">
            <span v-if="isFraisDeMaintenance && isMotifDeplacementEmpty">
              <small class="p-error">
                {{ this.$t('facturation_hote.empty_motif_deplacement') }}
              </small>
            </span>
          </div>
          <div class="form-row">
            <AkInputMoney
              v-if="isAchatHote || isForfaitHivernage || isFraisDeMenageHote"
              v-model="item.amount"
              :label="$t('operation.amount')"
              :validator="v$.item.amount"
              class-name="col-md-6" />
            <AkDropdown
              v-if="buildLabelChoice"
              v-model="item.label"
              :label="$t('operation.label')"
              :options="buildLabelChoice"
              :validator="v$.item.label"
              :optionLabel="(obj) => obj"
              :optionValue="(obj) => obj"
              class-name="col-md-6" />
            <AkDropdown
              v-if="isAchatHote"
              v-model="item.motifId"
              :label="$t('item_label.raison_achat_hote')"
              :options="raisonAchatList"
              :validator="v$.item.motifId"
              optionLabel="label"
              optionValue="id"
              class-name="col-md-6" />
          </div>
          <div class="form-row">
            <span v-if="isAchatHote && isRaisonAchatHoteEmpty">
              <small class="p-error">
                {{ this.$t('facturation_hote.empty_raison_achat_hote') }}
              </small>
            </span>
          </div>
          <div class="form-row">
            <AkInputTextArea
              v-if="isAchatHote || isFraisDeMaintenance || isForfaitHivernage || isFraisDeMenageHote"
              v-model="item.comment"
              :label="isAchatHote ? $t('settings.description') : $t('operation.comment')"
              :validator="v$.item.comment"
              class-name="col-md-12" />
          </div>
          <div class="form-row">
            <AkFilesUpload
              v-if="isAchatHote"
              v-model="files"
              :label="$t('ticket.files')"
              :validator="v$.files"
              class-name="col-md-12" />
            <p v-if="errors.length">
              <small v-for="(error, index) in errors" :key="index" style="color: red">
                {{ error }}
              </small>
            </p>
          </div>
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogDelete"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.deleteFile()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span>{{ $t('facturation_hote.confirm_delete_file') }}</span>
        </div>
      </AkDialog>
    </template>
  </GaView>
</template>
