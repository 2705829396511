<script>
/* COMPOSANTS */
import AkAutocomplete from '@components/general/AkAutocomplete';

/* MIXINS */
import loaderMixin from '@mixins/loaderMixin';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import mandatService from '@services/mandatService';
import {debounce} from '@utils';

export default {
  components: {
    AkAutocomplete,
  },
  mixins: [loaderMixin, utilsMixin],
  props: {
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    modelValue: {
      type: [String, Number],
      required: false,
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    className: {
      type: String,
      required: false,
      default: 'col-md-4 col-6',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      mandats: [],
      debounceFn: undefined,
    };
  },
  mounted() {
    this.debounceFn = debounce((e) => this.searchMandats(e));
    if (this.currentValue !== undefined && this.currentValue !== null) {
      mandatService.mandat(this.currentValue).then((d) => {
        this.mandats.push(d);
        this.$refs.autocomplete.init(d);
      });
    }
  },
  methods: {
    searchMandats(event) {
      let search = {query: event.query};
      mandatService.mandats(search).then((data) => {
        this.mandats = data;
      });
      //      this.debounceFn = debounce((e) => this.search(e))
    },
    reset() {
      this.$refs.autocomplete.reset();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <AkAutocomplete
    ref="autocomplete"
    v-model="currentValue"
    :inline="inline"
    :validator="validator"
    :submitted="submitted"
    :disabled="disabled"
    :label="label"
    :placeholder="placeholder"
    :className="className"
    option-label="num"
    option-value="num"
    :items="mandats"
    @search="debounceFn">
    <template #item="slotProps">
      <div>{{ slotProps.item.num }}</div>
    </template>
  </AkAutocomplete>
</template>
