<script>
import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

/* COMPOSANTS */
import AkInputTextArea from '@components/input/AkInputTextArea';
import AkCalendar from '@components/input/AkCalendar';
import GaView from '@components/layout/GaView';
import AkInputText from '@components/input/AkInputText.vue';
import AkInputMoney from '@components/input/AkInputMoney.vue';
import AkLoader from '@components/general/AkLoader.vue';
import AkDropdown from '@components/input/AkDropdown.vue';
import AkFormSubmitted from '@components/general/AkFormSubmitted.vue';
import LitigeItemList from '@views/litige/components/LitigeItemList.vue';

/* MIXINS */
import backMixin from '@mixins/backMixin';
import messageMixin from '@mixins/messageMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import loaderMixin from '@mixins/loaderMixin';
import litigeStatusConst from '@mixins/const/litigeStatusConst';
import litigeResolutionConst from '@mixins/const/litigeResolutionConst';
import litigeCompleteStatusConst from '@mixins/const/litigeCompleteStatusConst';

/* SERVICES */
import litigeService from '@services/litigeService';

export default {
  components: {
    LitigeItemList,
    AkFormSubmitted,
    AkLoader,
    AkInputMoney,
    AkDropdown,
    AkInputText,
    AkInputTextArea,
    AkCalendar,
    GaView,
  },
  mixins: [
    backMixin,
    loaderMixin,
    messageMixin,
    permissionsMixin,
    randomRef,
    utilsMixin,
    litigeStatusConst,
    litigeResolutionConst,
    litigeCompleteStatusConst,
  ],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'litige.update',
    };
  },
  validations() {
    return {
      // litige: {
      //   description: {required},
      // },
    };
  },
  data() {
    return {
      litigeId: undefined,
      litige: {},
      submitted: false,
    };
  },
  computed: {
    isStatusATraiter() {
      return this.litige.status === 'A_TRAITER';
    },
    isStatusEnCours() {
      return this.litige.status === 'EN_COURS';
    },
    isStatusResolu() {
      return this.litige.status === 'RESOLU';
    },
    isCompleteTrue() {
      return this.litige.complete === true;
    },
    hasCompleteStatus() {
      return !!this.litige.completeStatus;
    },
    totalAmountEstimatedTtc() {
      if (!this.litige) return null;
      let total = 0;
      for (let item of this.litige.litigeItemList) {
        total = this.addNumbers([total, item.amountEstimatedTtc]);
      }
      return total;
    },
  },
  watch: {},
  mounted() {
    this.litigeId = this.$route.params.litigeId;
    this.refresh();
  },
  methods: {
    refresh() {
      this.$refs.loader.load();
      litigeService
        .litige(this.litigeId)
        .then((data) => {
          this.litige = data;
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.$refs.loader.hide();
        });
    },
    updateLitige() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$error) return;

      this.showLoader(this.$refs.litigeForm);
      litigeService
        .update(this.litige)
        .then(() => {
          this.success(this.$t('litige.updated'));
          this.submitted = false;
        })
        .catch((e) => {
          this.addError(this.$t('error.' + e.response.data.error));
          this.submitted = false;
        })
        .finally(() => {
          this.hideLoader();
        });
    },
    linkExternal(link) {
      return {path: link, icon: 'fas fa-link color-label'};
    },
    displayRemovedMessage() {
      this.success(this.$t('facturation_hote.files_deleted'));
    },
    computeAmountEstimatedTtc() {
      this.litige.amountEstimatedTtc = this.totalAmountEstimatedTtc;
    },
  },
};
</script>

<template>
  <AkLoader ref="loader" :message="$t('currently_loading')" :scale="1.5" color="black">
    <template #content>
      <GaView :ref="ref" :title="$t('litige.update')">
        <template #back>
          <a class="mb-1 d-block pointer" @click="goBack()"><i class="fe fe-arrow-left pr-1" />{{ $t('back') }}</a>
        </template>
        <template #content>
          <AkFormSubmitted
            :submitted="submitted"
            reference="litigeForm"
            @submit="updateLitige"
            :disabled="isStatusResolu">
            <!-- Litige -->

            <section id="section-litige">
              <h5>Litige</h5>

              <div class="form-row">
                <AkDropdown
                  v-model="litige.status"
                  :label="$t('litige.status')"
                  :options="litigeStatusConst"
                  option-label="label"
                  option-value="value"
                  class-name="col-6"
                  :disabled="true" />
                <AkInputText
                  v-model="litige.avantioLocalizer"
                  :label="$t('reservation.number')"
                  :placeholder="$t('reservation.number')"
                  class-name="col-6"
                  :link="linkReservation"
                  :disabled="true" />
              </div>
              <div class="form-row">
                <AkInputText
                  v-if="this.isGas()"
                  v-model="litige.companyName"
                  :label="$t('employee.company')"
                  :placeholder="$t('employee.company')"
                  class-name="col-4"
                  :disabled="true" />
                <AkInputText
                  v-model="litige.accommodationName"
                  :label="$t('accommodation.name')"
                  :placeholder="$t('accommodation.name')"
                  class-name="col-4"
                  :disabled="true" />
                <AkInputText
                  v-if="!this.isOwner()"
                  v-model="litige.ownerName"
                  :label="$t('accommodation.ownerName')"
                  :placeholder="$t('accommodation.ownerName')"
                  class-name="col-4"
                  :disabled="true" />
              </div>
              <div class="form-row" v-if="!isStatusATraiter && this.isCompleteTrue && this.hasCompleteStatus">
                <AkDropdown
                  v-model="litige.completeStatus"
                  :label="$t('litige.complete_status')"
                  :options="litigeCompleteStatusConst"
                  option-label="label"
                  option-value="value"
                  class-name="col-4"
                  :disabled="!this.isGas()" />
                <AkCalendar
                  v-model="litige.completeDate"
                  :label="$t('litige.complete_date')"
                  :placeholder="$t('litige.complete_date')"
                  date-format="dd/mm/yy"
                  selection-mode="single"
                  class-name="col-4"
                  :disabled="!this.isGas()" />
                <AkInputTextArea
                  v-if="hasRefusalComment"
                  v-model="litige.refusalComment"
                  :label="$t('litige.refused_comment')"
                  :placeholder="$t('litige.refused_comment')"
                  class-name="col-4"
                  :disabled="!this.isGas()" />
              </div>
              <!-- <div class="form-row">
                <AkInputTextArea
                  v-model="litige.description"
                  :label="$t('litige.description')"
                  :placeholder="$t('litige.description')"
                  class-name="col-12" />
              </div> -->
            </section>

            <!-- Litige items (Articles) -->

            <section id="litige-items" v-if="litige.litigeItemList?.length > 0">
              <!-- <section v-for="(item, idx) in litige.litigeItemList" :key="item.id"> -->
              <LitigeItemList
                ref="equipments"
                v-model="litige.litigeItemList"
                :submitted="this.submitted"
                :displayMaintenanceFiles="true"
                :displayMaintenanceDate="true"
                :canDeleteFiles="!isStatusResolu"
                @amountChanged="computeAmountEstimatedTtc"
                @removed="displayRemovedMessage" />
            </section>

            <!-- Dossier litige -->

            <section id="section-dossier-litige">
              <h5>Dossier litige</h5>

              <div class="form-row">
                <AkInputMoney
                  v-model="litige.amountEstimatedTtc"
                  :label="$t('litige.total_amount_estimated_ttc')"
                  :placeholder="$t('litige.total_amount_estimated_ttc')"
                  class-name="col-4"
                  :disabled="true" />
              </div>
              <div class="form-row">
                <AkDropdown
                  v-model="litige.resolution"
                  :label="$t('litige.resolution')"
                  :options="litigeResolutionConst"
                  option-label="label"
                  option-value="value"
                  class-name="col-4"
                  :disabled="true" />
                <AkInputTextArea
                  v-if="isResolutionPerdu"
                  v-model="litige.reason"
                  :label="$t('litige.reason')"
                  :placeholder="$t('litige.reason')"
                  class-name="col-4"
                  :disabled="true" />
                <AkCalendar
                  v-model="litige.dateDecision"
                  :label="$t('litige.date_decision')"
                  :placeholder="$t('litige.date_decision')"
                  date-format="dd/mm/yy"
                  selection-mode="single"
                  class-name="col-4"
                  :disabled="true" />
              </div>

              <section id="dossier-litige-gagne" v-if="isResolutionGagne">
                <div class="form-row">
                  <AkInputMoney
                    v-model="litige.amountTtc"
                    :label="$t('litige.amount_ttc')"
                    :placeholder="$t('litige.amount_ttc')"
                    class-name="col-4" />
                </div>
                <div class="form-row">
                  <AkInputMoney
                    v-model="litige.amountGas"
                    :label="$t('litige.amount_gas')"
                    :placeholder="$t('litige.amount_gas')"
                    class-name="col-4" />
                  <AkInputMoney
                    v-model="litige.amountGav"
                    :label="$t('litige.amount_gav')"
                    :placeholder="$t('litige.amount_gav')"
                    class-name="col-4" />
                  <AkInputMoney
                    v-model="litige.amountOwner"
                    :label="$t('litige.amount_owner')"
                    :placeholder="$t('litige.amount_owner')"
                    class-name="col-4" />
                </div>
              </section>
            </section>

            <div class="d-flex justify-content-end mb-20" v-if="!isStatusResolu">
              <button class="btn btn-primary">
                {{ $t('update') }}
              </button>
            </div>
          </AkFormSubmitted>
        </template>
      </GaView>
    </template>
  </AkLoader>
</template>
