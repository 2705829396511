import Api from '@/services/api';
import {createFormDataFile, removeEmptyParams} from '@utils';

export default {
  absences(filter) {
    return Api()
      .get('/absence', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  absence(absenceId) {
    return Api()
      .get('/absence/' + absenceId)
      .then((res) => this.formatRes(res.data));
  },
  absencesByEmployeeId(employeeId) {
    return Api()
      .get('/absence/employee/' + employeeId)
      .then((res) => this.formatRes(res.data));
  },
  create(absence, file) {
    let data = createFormDataFile(absence, file);
    return Api()
      .post('/absence', data)
      .then((res) => this.formatRes(res.data));
  },
  update(absence) {
    return Api()
      .put('/absence/' + absence.id, absence)
      .then((res) => this.formatRes(res.data));
  },
  delete(absence) {
    return Api()
      .delete('/absence/' + absence.id)
      .then(() => absence);
  },
  formatRes(e) {
    if (e.startDate) e.startDate = new Date(e.startDate);
    if (e.endDate) e.endDate = new Date(e.endDate);
    if (e.startTime) e.startTime = new Date(e.startTime);
    if (e.endTime) e.endTime = new Date(e.endTime);

    return e;
  },
};
