<script>
/* COMPOSANTS */
import GaContentView from '@components/layout/GaContentView';
import AkDialog from '@components/general/AkDialog';

/* MIXINS */
import loaderMixin from '@mixins/loaderMixin';
import yesNoConst from '@mixins/const/yesNoConst';
import quantityConst from '@mixins/const/quantityConst';
import receptionTypeConst from '@mixins/const/receptionTypeConst';
import residenceTypeConst from '@mixins/const/residenceTypeConst';
import permissionsMixin from '@mixins/permissionsMixin';
import amendmentReasonConst from '@mixins/const/amendmentReasonConst';
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import employeeService from '@services/employeeService';
import employeeContractService from '@services/employeeContractService';
import ficService from '@services/ficService';

export default {
  components: {
    GaContentView,
    AkDialog,
  },
  mixins: [
    utilsMixin,
    randomRef,
    permissionsMixin,
    loaderMixin,
    yesNoConst,
    quantityConst,
    receptionTypeConst,
    residenceTypeConst,
    utilsMixin,
    amendmentReasonConst,
  ],
  props: {
    modelValue: {required: true},
  },
  data() {
    return {
      employee: {},
      contract: {},
      contracts: [],
      employeeId: undefined,
      loading: true,
      item: {},
      contractId: undefined,
    };
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  created() {},
  mounted() {
    this.getRef().showLoader(this.$refs.form);
    this.employeeId = this.$route.params.employeeId;
    let p1 = employeeService.employee(this.employeeId);
    p1.then((data) => {
      this.employee = data;
    });

    let p2 = employeeContractService.employeeContractsByEmployeeId(this.employeeId);
    p2.then((data) => {
      this.contracts = data;
      if (this.contracts.length > 0) {
        this.contractId = this.contracts[this.contracts.length - 1].id;
      }
      this.loading = false;
    });

    Promise.all([p1, p2]).then(this.getRef().hideLoader());
  },
  methods: {
    openDeleteDialog(item) {
      this.item = item;
      this.$refs.dialogDelete.show();
    },
    delete() {
      employeeContractService
        .delete(this.item)
        .then((data) => {
          this.removeInListById(this.contracts, data.id);
          this.getRef().success(this.$t('contract_settings.deleted'));
        })
        .catch((e) => {
          let content = 'Une erreur est survenue durant la suppression';
          if (e.response) content = 'Une erreur est survenue durant la suppression : ' + e.response.data.error;
          this.getRef().error(content);
        });
    },
    reloadList() {
      ficService.fics({tableName: 'employee_contract', tableId: this.contractId}).then((data) => {
        this.fics = data;
      });
    },
  },
};
</script>

<template>
  <GaContentView :ref="ref" :title="$t('employee.contracts')">
    <template #action>
      <router-link
        v-if="this.employeeId"
        :to="{name: 'employeeContractCreate', params: {employeeId: this.employeeId}}"
        class="btn btn-inverse-primary btn-xs">
        <i class="gst-plus pr-1" />{{ $t('contract.add') }}
      </router-link>
    </template>

    <template #content>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive">
                <DataTable
                  :always-show-paginator="false"
                  :loading="loading"
                  :paginator="true"
                  :rows="10"
                  :rows-per-page-options="[10, 20, 50]"
                  :value="contracts"
                  class="table"
                  current-page-report-template="{first}-{last}/{totalRecords}"
                  removable-sort
                  responsive-layout="scroll"
                  striped-rows>
                  <template #empty>
                    {{ $t('contract_settings.empty') }}
                  </template>
                  <Column :header="$t('contract_settings.contract')" :sortable="true" field="contract">
                    <template #body="slotProps">
                      {{ slotProps.data.contract }}
                    </template>
                  </Column>
                  <Column :header="$t('contract_settings.type')" :sortable="true" field="type">
                    <template #body="slotProps">
                      <span v-if="slotProps.data.contractType">{{
                        $t('contract_settings.' + slotProps.data.contractType)
                      }}</span>
                    </template>
                  </Column>
                  <Column :header="$t('contract_settings.amendment')" :sortable="true" field="amendment">
                    <template #body="slotProps">
                      {{ displayForAmendmentReasonConst(slotProps.data.amendmentReason) }}
                    </template>
                  </Column>
                  <Column :header="$t('contract.startDate')" :sortable="true" field="dateStart">
                    <template #body="slotProps">
                      <span v-if="slotProps.data.dateStart">{{
                        displayDate(displayUTCDate(slotProps.data.dateStart))
                      }}</span>
                    </template>
                  </Column>
                  <Column :header="$t('contract.endDate')" :sortable="true" field="dateEnd">
                    <template #body="slotProps">
                      <span v-if="slotProps.data.dateEnd">{{
                        displayDate(displayUTCDate(slotProps.data.dateEnd))
                      }}</span>
                    </template>
                  </Column>
                  <Column :header="$t('contract.trialEndDate')" :sortable="true" field="trialEndDate">
                    <template #body="slotProps">
                      <span v-if="slotProps.data.trialEndDate">{{
                        displayDate(displayUTCDate(slotProps.data.trialEndDate))
                      }}</span>
                    </template>
                  </Column>
                  <Column :header="$t('contract.grossHourlyTx')" :sortable="true" field="grossHourlyTx">
                    <template #body="slotProps">
                      {{ slotProps.data.grossHourlyRate }}
                    </template>
                  </Column>
                  <Column :header="$t('contract.workedDays')" :sortable="true" field="workedDays">
                    <template #body="slotProps">
                      <span v-if="slotProps.data.monday">{{ $t('contract.monday') }}&nbsp;&nbsp;</span>
                      <span v-if="slotProps.data.tuesday">{{ $t('contract.tuesday') }}&nbsp;&nbsp;</span>
                      <span v-if="slotProps.data.wednesday">{{ $t('contract.wednesday') }}&nbsp;&nbsp;</span>
                      <span v-if="slotProps.data.thursday">{{ $t('contract.thursday') }}&nbsp;&nbsp;</span>
                      <span v-if="slotProps.data.friday">{{ $t('contract.friday') }}</span>
                    </template>
                  </Column>
                  <Column :header="$t('filesNbr')" :sortable="true">
                    <template #body="slotProps">
                      <span v-if="slotProps.data.ficList">{{ slotProps.data.ficList.length }}</span>
                    </template>
                  </Column>
                  <Column
                    body-style="text-align: center; overflow: visible"
                    header-style=" width: 160px; text-align: center">
                    <template #body="slotProps">
                      <div class="d-flex justify-content-end align-items-center">
                        <router-link
                          :to="{
                            name: 'employeeContractDetails',
                            params: {employeeId: this.employeeId, employeeContractId: slotProps.data.id},
                          }"
                          class="btn btn-xs btn-inverse-primary mr-1">
                          <i class="fe fe-eye" />
                        </router-link>
                        <router-link
                          v-if="slotProps.data.contractType == 'contract'"
                          :to="{
                            name: 'employeeAmendmentCreate',
                            params: {employeeId: this.employeeId, employeeContractId: slotProps.data.id},
                          }"
                          class="btn btn-xs btn-inverse-primary mr-1">
                          <i class="fe fe-plus-circle" />
                        </router-link>
                        <router-link
                          v-if="slotProps.data.contractType == 'contract'"
                          :to="{
                            name: 'employeeContractUpdate',
                            params: {employeeId: this.employeeId, employeeContractId: slotProps.data.id},
                          }"
                          class="btn btn-xs btn-inverse-primary mr-1">
                          <i class="fe fe-edit" />
                        </router-link>
                        <span class="btn btn-xs btn-inverse-danger mr-1" @click="openDeleteDialog(slotProps.data)">
                          <i class="fe fe-trash" />
                        </span>
                      </div>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #extra>
      <AkDialog
        ref="dialogDelete"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.delete()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span>{{ $t('contract_settings.confirm_delete') }}</span>
        </div>
      </AkDialog>
    </template>
  </GaContentView>
</template>
