export default {
  data() {
    return {
      litigeResolutionConst: [
        {label: this.$t('litige.gagne'), value: 'GAGNE'},
        {label: this.$t('litige.perdu'), value: 'PERDU'},
      ],
    };
  },
  methods: {
    displayForLitigeResolutionConst(value) {
      let result = this.litigeResolutionConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
