import Api from '@/services/api';
import {removeEmptyParams, transformDownloadResponse} from '@utils';
import contentDisposition from 'content-disposition';

export default {
  employees(filter) {
    return Api()
      .get('/employees', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  employeesPagination(filter) {
    return Api()
      .get('/employees/pagination', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach(this.formatRes);
        let response = {};
        response.data = res.data;
        response.totalItems = parseInt(res.headers['x-pagination-total']);
        return response;
      });
  },
  employeesForTicketAttribution(ticketId) {
    return Api()
      .get('/employees/ticketattribution/' + ticketId)
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  employeesForAccommodationAttribution(accommodationId) {
    return Api()
      .get('/employees/accommodationattribution/' + accommodationId)
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  availableemployees(id, filter) {
    return Api()
      .get('/employees/prestation/' + id, {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  employeesForPole(poleId) {
    return Api()
      .get('/employees/pole/' + poleId)
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  employeesForNewActivity(filter) {
    return Api()
      .get('/employees/newactivity', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  employee(employeeId) {
    return Api()
      .get('/employee/' + employeeId)
      .then((res) => this.formatRes(res.data));
  },
  create(employee) {
    return Api()
      .post('/employee', employee)
      .then((res) => this.formatRes(res.data));
  },
  update(employee) {
    return Api()
      .put('/employee/' + employee.id, employee)
      .then((res) => this.formatRes(res.data));
  },
  enable(employee) {
    return Api()
      .get('/employee/enable/' + employee.id)
      .then((res) => this.formatRes(res.data));
  },
  disable(employee) {
    return Api()
      .get('/employee/disable/' + employee.id)
      .then((res) => this.formatRes(res.data));
  },
  generateModel(templateId, itemId) {
    return Api()
      .get('/employee/documentmodel/generate', {
        responseType: 'blob',
        transformResponse: [transformDownloadResponse],
        params: removeEmptyParams({templateId: templateId, itemId: itemId}),
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  },
  delete(employee) {
    return Api()
      .delete('/employee/' + employee.id)
      .then(() => employee);
  },
  formatRes(e) {
    if (e.birthdate) e.birthdate = new Date(e.birthdate);
    if (e.idValidUntil) e.idValidUntil = new Date(e.idValidUntil);
    if (e.drivingPermitObtainingDate) e.drivingPermitObtainingDate = new Date(e.drivingPermitObtainingDate);
    if (e.drivingPermitValidityDate) e.drivingPermitValidityDate = new Date(e.drivingPermitValidityDate);
    if (e.seniorityDate) e.seniorityDate = new Date(e.seniorityDate);
    if (e.lastMedicalVisitDate) e.lastMedicalVisitDate = new Date(e.lastMedicalVisitDate);
    if (e.nextMedicalVisitDate) e.nextMedicalVisitDate = new Date(e.nextMedicalVisitDate);
    if (e.validityStartDate) e.validityStartDate = new Date(e.validityStartDate);
    if (e.validityEndDate) e.validityEndDate = new Date(e.validityEndDate);
    if (e.disabilityValidityStartDate) e.disabilityValidityStartDate = new Date(e.disabilityValidityStartDate);
    if (e.pensionStartDate) e.pensionStartDate = new Date(e.pensionStartDate);
    if (e.entryDate) e.entryDate = new Date(e.entryDate);
    if (e.entryDate) e.entryDate = new Date(e.entryDate);
    if (e.renewalApplicationSubmissionDate)
      e.renewalApplicationSubmissionDate = new Date(e.renewalApplicationSubmissionDate);
    if (e.entryHour) e.entryHour = new Date(e.entryHour);
    if (e.commitmentEndDate) e.commitmentEndDate = new Date(e.commitmentEndDate);
    return e;
  },
};
