<script>
/* COMPOSANTS */
import InputSwitch from 'primevue/inputswitch';
import PButton from 'primevue/button';
import AkDialog from '@components/general/AkDialog';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import ownerTypesConst from '@mixins/const/ownerTypesConst';
import messageMixin from '@mixins/messageMixin';
import {PermissionMap} from '@mixins/const/permissionConst';
import permissionsMixin from '@mixins/permissionsMixin';
import sidebarMixin from '@mixins/sidebarMixin';

/* SERVICES */
import ownerService from '@services/ownerService';

export default {
  components: {
    PButton,
    AkDialog,
    InputSwitch,
  },
  mixins: [ownerTypesConst, randomRef, utilsMixin, messageMixin, permissionsMixin, sidebarMixin],
  metaInfo() {
    return {
      title: 'prospect.list',
    };
  },
  data() {
    return {
      loading: true,
      list: [],
      totalItems: 0,
      item: {},
      filter: {
        page: 1,
        limit: 10,
        ownerStatus: 'PROSPECT',
        sort: ['id:DESC'],
      },
    };
  },
  mounted() {
    this.search();
  },
  computed: {
    hasManagePermission() {
      return this.hasPermission(PermissionMap.PROSPECT.MANAGE);
    },
    hasDeletePermission() {
      return this.hasPermission(PermissionMap.PROSPECT.DELETE);
    },
  },
  methods: {
    search() {
      this.loading = true;
      ownerService.owners(this.filter).then((data) => {
        this.list = data;
        this.loadFieldsForSort();
        this.loading = false;
      });
    },
    loadFieldsForSort() {
      for (let owner of this.list) {
        if (owner.contactList.length > 0) {
          let contact = owner.contactList[0];
          owner.contactPhone = contact.phone;
          owner.contactEmail = contact.email;
          owner.contactDisplay = contact.display;
        }
      }
    },
    submitForm() {
      this.onPage({page: 0, rows: 10});
    },
    displayForName(data) {
      if (data.contactList.length === 0) return '';
      let contact = data.contactList[0];
      if (data.ownerType === 'PERSONNE_MORALE') return contact.companyName;
      return (
        (contact.firstName != null ? contact.firstName + ' ' : '') + (contact.lastName != null ? contact.lastName : '')
      );
    },
    displayForEmail(data) {
      if (data.contactList.length === 0) return '';
      return data.contactList[0].email;
    },
    displayForPhone(data) {
      if (data.contactList.length === 0) return '';
      return data.contactList[0].phone;
    },
    classForEnabled(data) {
      return data.userEnabled ? 'ti ti-check text-primary' : 'ti ti-close';
    },
    classForAccess(data) {
      return data.userId ? 'ti ti-check text-primary' : 'ti ti-close';
    },
    changeUserEnabled(data) {
      if (data.userId == undefined) {
        this.addError(this.$t('prospect.errorNoId'));
        data.userEnabled = false;
        return;
      }
      if (data.userEnabled) this.enableUser(data);
      else this.disableUser(data);
    },
    disableUser(data) {
      ownerService.disable(data).then((data) => {
        //this.search();
      });
    },
    enableUser(data) {
      ownerService.enable(data).then((data) => {
        //this.search();
      });
    },
    openDeleteDialog(item, event) {
      this.item = item;
      this.$refs.dialogConfirmDelete.show();
      if (event) event.stopPropagation();
    },
    deleteProspect() {
      this.loading = true;
      let p1 = ownerService.delete(this.item);
      p1.then(() => {
        this.success(this.$t('prospect.deleted'));
        this.search();
      })
        .catch((error) => {
          if (error.response && error.response.status === 412) {
            this.addError("Il ne s'agit pas d'un prospect");
          } else if (error.response && error.response.status === 406) {
            this.$refs.dialogForceDelete.show();
          } else this.showError();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    forceDeleteProspect() {
      this.loading = true;
      ownerService
        .forceDelete(this.item)
        .then(() => {
          this.success(this.$t('prospect.deleted'));
          this.search();
        })
        .catch((error) => {
          if (error.response && error.response.status === 412) {
            this.addError("Il ne s'agit pas d'un prospect");
          } else this.showError();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openFilterPanel() {
      this.toggleFilter('GaProspectFilterList', this.filter, this.search, this.resetFilter);
    },
    resetFilter() {
      this.filter = {
        page: 1,
        limit: 10,
        ownerStatus: 'PROSPECT',
        sort: ['id:DESC'],
      };
      this.toggleFilter('GaProspectFilterList', this.filter, this.search, this.resetFilter);
    },
  },
};
</script>
<template>
  <GaView :ref="ref" :title="$t('prospect.list')">
    <template v-slot:action>
      <div class="d-flex justify-content-end" style="gap: 16px">
        <PButton
          :badge="this.countFilter(this.filter)"
          badgeClass="p-badge-primary"
          class="btn btn-primary"
          label="Filtrer"
          @click="openFilterPanel()" />
        <router-link v-if="hasManagePermission" :to="{name: 'prospectCreate'}" class="btn btn-primary">
          <i class="fe fe fe-plus pr-1" />
          {{ $t('prospect.add') }}
        </router-link>
      </div>
    </template>

    <template #content>
      <DataTable
        :always-show-paginator="false"
        :loading="loading"
        :paginator="true"
        :rows="10"
        :rows-per-page-options="[10, 20, 50]"
        :value="list"
        class="table"
        current-page-report-template="Showing {first} to {last} of {totalRecords}"
        paginator-template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        responsive-layout="scroll"
        :total-records="totalItems"
        sort-mode="multiple"
        removable-sort>
        <template #empty>
          {{ $t('prospect.empty') }}
        </template>
        <Column :header="$t('prospect.ownerType')" :sortable="true" field="ownerType">
          <template #body="slotProps">
            <router-link :to="{name: 'prospectPage', params: {ownerId: slotProps.data.id}}">
              {{ displayForOwnerTypeConst(slotProps.data.ownerType) }}
            </router-link>
          </template>
        </Column>
        <Column :header="$t('prospect.ownerName')" :sortable="true" field="contactDisplay">
          <template #body="slotProps">
            <router-link :to="{name: 'prospectPage', params: {ownerId: slotProps.data.id}}">
              {{ displayForName(slotProps.data) }}
            </router-link>
          </template>
        </Column>
        <Column :header="$t('contact.email')" :sortable="true" field="contactEmail">
          <template #body="slotProps">
            <router-link :to="{name: 'prospectPage', params: {ownerId: slotProps.data.id}}">
              {{ displayForEmail(slotProps.data) }}
            </router-link>
          </template>
        </Column>
        <Column :header="$t('contact.phone')" :sortable="true" field="contactPhone">
          <template #body="slotProps">
            <router-link :to="{name: 'prospectPage', params: {ownerId: slotProps.data.id}}">
              {{ displayForPhone(slotProps.data) }}
            </router-link>
          </template>
        </Column>
        <Column
          :header="$t('user.access')"
          :sortable="true"
          body-style="text-align: center; overflow: visible"
          field="userId"
          header-style=" width: 200px; text-align: center">
          <template #body="slotProps">
            <router-link :to="{name: 'prospectPage', params: {ownerId: slotProps.data.id}}">
              <i :class="classForAccess(slotProps.data)" />
            </router-link>
          </template>
        </Column>
        <Column
          :header="$t('user.enabled')"
          :sortable="true"
          body-style="text-align: center; overflow: visible"
          field="userEnabled"
          header-style=" width: 100px; text-align: center">
          <template #body="slotProps">
            <InputSwitch
              :disabled="slotProps.data.userId == undefined"
              v-model="slotProps.data.userEnabled"
              @change="changeUserEnabled(slotProps.data)" />
          </template>
        </Column>

        <Column body-style="text-align: center; overflow: visible" header-style=" width: 180px; text-align: center">
          <template #body="slotProps">
            <div class="d-flex justify-content-end align-items-center">
              <router-link
                :to="{name: 'prospectPage', params: {ownerId: slotProps.data.id}}"
                class="btn btn-xs btn-inverse-primary mr-1">
                <i class="fe fe-eye" />
              </router-link>
              <router-link
                v-if="hasManagePermission"
                :to="{name: 'prospectUpdate', params: {ownerId: slotProps.data.id}}"
                class="btn btn-xs btn-inverse-primary">
                <i class="fe fe-edit" />
              </router-link>
              <span
                v-if="hasDeletePermission"
                @click="openDeleteDialog(slotProps.data)"
                class="btn btn-xs btn-inverse-danger ml-1">
                <i class="fe fe-trash" />
              </span>
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
    <template #extra>
      <AkDialog
        ref="dialogConfirmDelete"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('confirm_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.deleteProspect()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span>{{ $t('prospect.confirm_delete') }}</span>
        </div>
      </AkDialog>
      <AkDialog
        ref="dialogForceDelete"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('confirm_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.forceDeleteProspect()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span>{{ $t('prospect.force_delete') }}</span>
        </div>
      </AkDialog>
    </template>
  </GaView>
</template>
