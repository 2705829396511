import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  companies(filter) {
    return Api()
      .get('/companies', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  company(companyId) {
    return Api()
      .get('/company/' + companyId)
      .then((res) => this.formatRes(res.data));
  },
  create(company) {
    return Api()
      .post('/company', company)
      .then((res) => this.formatRes(res.data));
  },
  update(company) {
    return Api()
      .put('/company/' + company.id, company)
      .then((res) => this.formatRes(res.data));
  },
  updateZone(company) {
    return Api()
      .put('/company/' + company.id + '/zone')
      .then(() => company);
  },
  delete(company) {
    return Api()
      .delete('/company/' + company.id)
      .then(() => company);
  },
  formatRes(e) {
    if (e.capital) e.capital = parseFloat(e.capital);
    if (e.dateAgrement) e.dateAgrement = new Date(e.dateAgrement);
    if (e.dateDeclaration) e.dateDeclaration = new Date(e.dateDeclaration);
    return e;
  },
};
