<script>
import useVuelidate from '@vuelidate/core';

/* COMPOSANTS */
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import PrestationBadge from '@/components/general/PrestationBadge';
import PrestationStatusBadge from '@/components/general/PrestationStatusBadge';
import AkDialog from '@components/general/AkDialog.vue';
import AkInputText from '@components/input/AkInputText.vue';
import AkMissionAssignment from '@views/operationnel/mission/components/AkMissionAssignment';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';
import randomRef from '@mixins/randomRef';
import missionMixin from '@mixins/missionMixin';

/* SERVICES */
import missionPlanningParticipantService from '@/services/missionPlanningParticipantService';

export default {
  components: {
    DataTable,
    Column,
    PrestationBadge,
    PrestationStatusBadge,
    AkDialog,
    AkInputText,
    AkMissionAssignment,
  },
  mixins: [utilsMixin, messageMixin, randomRef, missionMixin],
  props: {
    modelValue: {
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      missionAssignment: undefined,
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  watch: {},
  emits: ['mission-assigned'],
  mounted() {},
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  methods: {
    // ASSIGN
    openAssignDialog(mission) {
      if (!mission) return;
      this.missionAssignment = {...mission};
      this.$refs.dialogAssignMission.show();
    },

    assignMission() {
      this.submitted = true;
      this.v$.$touch();

      if (this.v$.$error) {
        // Récupération des messages d'erreur sur l'heure de fin et la durée
        let hourEndOrDurationError = this.v$.$errors.find(
          (e) => e.$validator === 'isHourEndSupOrEqToHourStart' || e.$validator === 'duration',
        );
        // N'afficher un message d'erreur que si on a trouvé une erreur sur l'heure de fin ou la durée
        if (hourEndOrDurationError) this.$refs.dialogAssignMission.error(hourEndOrDurationError.$message);
        return;
      }

      // Formatage des dates pour les missions des salariés
      if (this.isAssignedStaffIntern(this.missionAssignment)) {
        this.missionAssignment.prestationEmployees.forEach((prestation) => {
          let dateStart = new Date(prestation.dateStart);
          let hourStart = new Date(prestation.hourStart);
          dateStart.setHours(hourStart.getHours(), hourStart.getMinutes());
          prestation.dateStart = dateStart;
          let dateEnd = new Date(dateStart);
          let hourEnd = new Date(prestation.hourEnd);
          dateEnd.setHours(hourEnd.getHours(), hourEnd.getMinutes());
          prestation.dateEnd = dateEnd;
        });
      }

      // Formatage des dates pour les missions des concierges
      if (this.isAssignedStaffHousekeeper(this.missionAssignment)) {
        this.missionAssignment.prestationHousekeepers.forEach((prestation) => {
          let dateStart = new Date(prestation.dateStart);
          let hourStart = new Date(prestation.hourStart);
          dateStart.setHours(hourStart.getHours(), hourStart.getMinutes());
          prestation.dateStart = dateStart;
          let dateEnd = new Date(dateStart);
          let hourEnd = new Date(prestation.hourEnd);
          dateEnd.setHours(hourEnd.getHours(), hourEnd.getMinutes());
          prestation.dateEnd = dateEnd;
        });
      }

      let mission = {
        prestationId: this.missionAssignment.id,
        assignedStaff: this.missionAssignment.assignedStaff,
        prestationEmployees: this.missionAssignment.prestationEmployees,
        prestationHousekeepers: this.missionAssignment.prestationHousekeepers,
      };

      this.$refs.dialogAssignMission.hide();

      // Appel WS
      missionPlanningParticipantService
        .assign(mission)
        .then((data) => {
          this.addSuccess(this.$t('planning_intervenant.success'));
          this.$emit('mission-assigned');
        })
        .catch((e) => {
          let error = e.response ? e.response.data?.error : e;
          this.addError(error);
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>

<template>
  <div class="pending-missions">
    <DataTable
      :value="currentValue"
      :paginator="true"
      :always-show-paginator="false"
      :rows="10"
      :rows-per-page-options="[10, 20, 50]"
      :loading="this.loading"
      class="table pointer gd-datatable"
      current-page-report-template="{first}-{last}/{totalRecords}"
      removable-sort
      responsive-layout="scroll"
      striped-rows>
      <Column field="reservationNum" :header="this.$t('planning_intervenant.reservation')">
        <template #body="slotProps">
          <span class="reservation-num">{{ slotProps.data.reservationNum ?? '-' }}</span>
        </template>
      </Column>

      <Column field="accommodationName" :header="this.$t('planning_intervenant.accommodation')">
        <template #body="slotProps">
          <span v-tooltip.bottom="slotProps.data.addressDisplay">{{ slotProps.data.accommodationName }}</span>
        </template>
      </Column>

      <Column field="type" :header="this.$t('planning_intervenant.mission_type')">
        <template #body="slotProps">
          <PrestationBadge :type="slotProps.data.type" />
        </template>
      </Column>

      <Column field="type" :header="this.$t('planning_intervenant.assigned_staff')">
        <template #body="slotProps">
          <PrestationStatusBadge :status="slotProps.data.assignedStaff" />
        </template>
      </Column>

      <Column field="dateStart" :header="this.$t('planning_intervenant.planned_start_date')">
        <template #body="slotProps">
          <span>{{ this.formatDateIfNeeded(slotProps.data.dateStart) }}</span>
        </template>
      </Column>

      <Column field="duration" :header="this.$t('planning_intervenant.duration')">
        <template #body="slotProps">
          <span>{{ slotProps.data.durationDisplay }}</span>
        </template>
      </Column>

      <Column field="nbPrestatairesNeeded" :header="this.$t('planning_intervenant.nb_participants_needed')">
        <template #body="slotProps">
          <span>{{ slotProps.data.nbPrestatairesNeeded }}</span>
        </template>
      </Column>

      <Column>
        <template #body="slotProps">
          <button class="btn btn-primary" @click="openAssignDialog(slotProps.data)">
            {{ this.$t('planning_intervenant.schedule') }}
          </button>
        </template>
      </Column>
    </DataTable>
  </div>

  <!-- DIALOG ASSIGN MISSION -->

  <template>
    <AkDialog
      ref="dialogAssignMission"
      :title="$t('planning_intervenant.schedule_mission')"
      :validate-label="$t('planning_intervenant.schedule')"
      width="800px"
      @validate="assignMission()">
      <div>
        <h6>Résumé de la mission</h6>
        <div class="form-row mb-2">
          <PrestationStatusBadge class="mr-2" :status="this.missionAssignment.assignedStaff" />
          <PrestationBadge :type="this.missionAssignment.type" />
        </div>
        <div class="form-row">
          <AkInputText
            v-model="this.missionAssignment.accommodationName"
            :label="this.$t('planning_intervenant.accommodation')"
            class-name="col-12"
            :disabled="true" />
        </div>
        <div class="form-row">
          <AkInputText
            v-model="this.missionAssignment.durationDisplay"
            :label="this.$t('planning_intervenant.duration')"
            class-name="col-6"
            :disabled="true" />
        </div>
      </div>

      <div v-for="(item, idx) in this.missionAssignment.prestationEmployees" :key="item.id">
        <AkMissionAssignment
          v-model="this.missionAssignment.prestationEmployees[idx]"
          :index="idx"
          :dateStart="this.missionAssignment.dateStart"
          :duration="this.missionAssignment.duration"
          :companyId="this.missionAssignment.companyId"
          :assignedStaff="this.missionAssignment.assignedStaff" />
      </div>
      <div v-for="(item, idx) in this.missionAssignment.prestationHousekeepers" :key="item.id">
        <AkMissionAssignment
          v-model="this.missionAssignment.prestationHousekeepers[idx]"
          :index="idx"
          :dateStart="this.missionAssignment.dateStart"
          :duration="this.missionAssignment.duration"
          :companyId="this.missionAssignment.companyId"
          :assignedStaff="this.missionAssignment.assignedStaff" />
      </div>
    </AkDialog>
  </template>
</template>

<style lang="scss" scoped>
.pending-missions {
  th {
    padding: 13px 0;

    &:first-child {
      width: 133px !important;
      padding: 13px 24px;
    }
  }

  .p-datatable .p-datatable-tbody > tr > td {
    padding: 4px;
    font: 600 14px/20px 'Nunito Sans';
    color: #475467;

    &:first-child {
      text-align: center;
      padding: 8px;
    }

    &:not(:first-child) {
      vertical-align: top;
    }
  }

  .reservation-num {
    word-wrap: break-word;
  }
}
</style>
