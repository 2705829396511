<script>
import useVuelidate from '@vuelidate/core';
import {ref} from 'vue';
import router from '@router';

/* COMPOSANTS */
import PButton from 'primevue/button';

/* MIXINS */
import messageMixin from '@mixins/messageMixin';
import loaderMixin from '@mixins/loaderMixin';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import comptaReconciliationService from '@services/comptaReconciliationService';
import sidebarMixin from '@mixins/sidebarMixin';

export default {
  components: {
    PButton,
  },
  mixins: [messageMixin, loaderMixin, utilsMixin, sidebarMixin],
  metaInfo() {
    return {
      title: 'visitor_tax.transfert',
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      transferts: [],
      reconciliations: [],
      stayratesGroup: [],
      loading: false,

      operations: [],
      reconciliationIds: [],
      groupeTDS: [],
      search: {},
    };
  },
  validations() {
    return {};
  },
  mounted() {
    this.initStayrateTransfert();
  },
  computed: {},
  methods: {
    ref,
    router() {
      return router;
    },
    initStayrateTransfert() {
      this.loading = true;
      this.transferts = [];

      let p1 = comptaReconciliationService.comptaReconciliations(this.search);
      p1.then((data) => {
        this.loading = false;
        this.reconciliations = data;
        for (let reconciliation of this.reconciliations) {
          let transfert = reconciliation.reconciliation;
          transfert.tdsGroupLabel = reconciliation.label;
          this.transferts.push(transfert);
        }
      });
    },
    filterSearch() {
      this.initStayrateTransfert();
    },
    resetFilter() {
      this.search = {};
      this.toggleFilter('GaStayRateTransfertFilter', this.search, this.filterSearch, this.resetFilter);
    },
    openFilterPanel() {
      this.toggleFilter('GaStayRateTransfertFilter', this.search, this.filterSearch, this.resetFilter);
    },
  },
};
</script>
<template>
  <GaContentView :ref="ref" :title="$t('visitor_tax.transfert')">
    <template #action>
      <div class="d-flex justify-content-end" style="gap: 16px">
        <PButton
          class="btn btn-inverse-primary btn-xs"
          :badge="this.countFilter(this.search)"
          badgeClass="p-badge-primary"
          label="Filtrer"
          @click="openFilterPanel()" />
      </div>
    </template>

    <template #content>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive">
                <DataTable
                  :always-show-paginator="false"
                  :loading="loading"
                  :paginator="true"
                  :rows="10"
                  :rows-per-page-options="[10, 20, 50]"
                  :value="this.transferts"
                  class="table"
                  current-page-report-template="{first}-{last}/{totalRecords}"
                  removable-sort
                  sort-field="position"
                  responsive-layout="scroll"
                  striped-rows>
                  <template #empty>
                    {{ $t('operation.empty') }}
                  </template>
                  <Column field="date" :sortable="true">
                    <template #header>
                      <span>
                        {{ $t('operation.date') }}
                      </span>
                    </template>
                    <template #body="slotProps">
                      <router-link
                        :to="{
                          name: 'accountingStayRateDetails',
                          params: {reconciliationId: slotProps.data.id, groupLabel: slotProps.data.tdsGroupLabel},
                        }">
                        {{ displayDate(slotProps.data.date) }}
                      </router-link>
                    </template>
                  </Column>

                  <Column :sortable="true" field="numVirement">
                    <template #header>
                      <span class="table-header">
                        {{ $t('visitor_tax.transfert_num') }}
                      </span>
                    </template>
                    <template #body="slotProps">
                      <router-link
                        :to="{
                          name: 'accountingStayRateDetails',
                          params: {reconciliationId: slotProps.data.id, groupLabel: slotProps.data.tdsGroupLabel},
                        }">
                        {{ slotProps.data.numVirement }}
                      </router-link>
                    </template>
                  </Column>

                  <Column :sortable="true" field="tdsGroupLabel">
                    <template #header>
                      <span class="table-header">
                        {{ $t('visitor_tax.group') }}
                      </span>
                    </template>
                    <template #body="slotProps">
                      <router-link
                        :to="{
                          name: 'accountingStayRateDetails',
                          params: {reconciliationId: slotProps.data.id, groupLabel: slotProps.data.tdsGroupLabel},
                        }">
                        {{ slotProps.data.tdsGroupLabel }}
                      </router-link>
                    </template>
                  </Column>

                  <Column field="dateStart" :sortable="true">
                    <template #header>
                      {{ $t('visitor_tax.date_start') }}
                    </template>
                    <template #body="slotProps">
                      <router-link
                        :to="{
                          name: 'accountingStayRateDetails',
                          params: {reconciliationId: slotProps.data.id, groupLabel: slotProps.data.tdsGroupLabel},
                        }">
                        {{ displayDate(slotProps.data.dateStart) }}
                      </router-link>
                    </template>
                  </Column>

                  <Column field="dateEnd" :sortable="true">
                    <template #header>
                      {{ $t('visitor_tax.date_end') }}
                    </template>
                    <template #body="slotProps">
                      <router-link
                        :to="{
                          name: 'accountingStayRateDetails',
                          params: {reconciliationId: slotProps.data.id, groupLabel: slotProps.data.tdsGroupLabel},
                        }">
                        {{ displayDate(slotProps.data.dateEnd) }}
                      </router-link>
                    </template>
                  </Column>

                  <Column class="text-right" field="balance" :sortable="true">
                    <template #header>
                      {{ $t('accounting.amount') }}
                    </template>
                    <template #body="slotProps">
                      <router-link
                        :to="{
                          name: 'accountingStayRateDetails',
                          params: {reconciliationId: slotProps.data.id, groupLabel: slotProps.data.tdsGroupLabel},
                        }">
                        {{ formatCurrencyEUR(slotProps.data.balance) }}
                      </router-link>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </GaContentView>
</template>
