<script>
import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

/* COMPOSANTS */
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import AkFilesUpload from '@components/input/AkFilesUpload';
import GaView from '@components/layout/GaView';
import InputText from 'primevue/inputtext';
import AkDropdown from '@components/input/AkDropdown';
import AkCalendar from '@components/input/AkCalendar.vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import currentCompanyMixin from '@mixins/currentCompanyMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import messageMixin from '@mixins/messageMixin';
import backMixin from '@mixins/backMixin';

/* SERVICES */
import prestataireService from '@services/prestataireService';
import companyService from '@services/companyService';

export default {
  components: {
    GaView,
    AkFormSubmitted,
    AkFilesUpload,
    InputText,
    AkDropdown,
    AkCalendar,
  },
  mixins: [randomRef, currentCompanyMixin, permissionsMixin, messageMixin, backMixin],
  setup() {
    return {v$: useVuelidate()};
  },
  validations() {
    return {
      companyId: {required},
      prestataire: {
        lastName: {required},
        firstName: {required},
        email: {required},
        phone: {required},
      },
    };
  },
  data() {
    return {
      submitted: false,
      companyId: null,
      prestataire: {
        civility: null,
        lastName: null,
        firstName: null,
        birthDate: null,
        email: null,
        phone: null,
        homePhone: null,
      },
      files: [],
      companies: [],
    };
  },
  mounted() {
    this.companyId = this.currentCompanyId();

    let p1 = companyService.companies();
    p1.then((data) => {
      this.companies = data;
    });
  },
  methods: {
    createPresta() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$error) return;
      const prestataireDTO = {
        companyId: this.companyId,
        contact: this.prestataire,
      };
      this.getRef().showLoader(this.$refs.form);
      prestataireService
        .create(prestataireDTO, this.files)
        .then(() => {
          this.getRef().hideLoader();
          this.success('Prestataire ajouté !');
          this.prestataire = {
            civility: null,
            lastName: null,
            firstName: null,
            birthDate: null,
            email: null,
            phone: null,
            homePhone: null,
          };
          this.files = [];
          this.submitted = false;
        })
        .catch((e) => {
          this.getRef().hideLoader();
          this.addError(this.$t('error.' + e.response.data.error));
          this.submitted = false;
        });
    },
  },
};
</script>

<template>
  <GaView :ref="ref" title="Créer prestataire">
    <template #back>
      <a class="mb-1 d-block pointer" @click="goBack()"><i class="fe fe-arrow-left pr-1" />{{ $t('back') }}</a>
    </template>
    <template #action>
      <button type="submit" form="create-prestataire-form" class="btn btn-create">Créer le prestataire</button>
    </template>
    <template #content>
      <AkFormSubmitted
        :submitted="submitted"
        reference="form"
        @submit="createPresta"
        id="create-prestataire-form"
        class="create-prestataire-form">
        <div class="section">
          <span class="title">Information</span>
          <div>
            <div class="input-item">
              <span>Civilité</span>
              <div>
                <input
                  type="radio"
                  :checked="prestataire.civility === 'M'"
                  @click="($event) => (prestataire.civility = 'M')" />
                <label>Mr</label>
                <input
                  type="radio"
                  :checked="prestataire.civility === 'Mme'"
                  @click="($event) => (prestataire.civility = 'Mme')" />
                <label>Mme</label>
              </div>
            </div>
            <div class="input-item">
              <span>Nom *</span>
              <InputText v-model="prestataire.lastName" />
            </div>
            <div class="input-item">
              <span>Prénom *</span>
              <InputText v-model="prestataire.firstName" />
            </div>
            <div class="input-item">
              <span>Date de naissance</span>
              <AkCalendar
                class="border-grey"
                :className="''"
                :displayLabel="false"
                :inline="true"
                v-model="prestataire.birthDate"
                :date-format="'dd/mm/yy'"
                :maxDate="new Date()" />
            </div>
          </div>
        </div>
        <div class="section">
          <span class="title">Coordonnées</span>
          <div>
            <div class="input-item">
              <span>Email *</span>
              <InputText v-model="prestataire.email" />
            </div>
            <div class="input-item">
              <span>Portable *</span>
              <InputText v-model="prestataire.phone" />
            </div>
            <div class="input-item">
              <span>Fixe</span>
              <InputText v-model="prestataire.homePhone" />
            </div>
          </div>
        </div>
        <div class="section" v-if="isGas()">
          <span class="title">Informations complémentaires</span>
          <div>
            <div class="input-item">
              <span>Conciergerie locale *</span>
              <AkDropdown
                :label="''"
                v-model="companyId"
                :options="companies"
                option-label="name"
                option-value="id"
                :inline="true"
                class="border-grey" />
            </div>
          </div>
        </div>
        <div class="section">
          <span class="title">Pièces jointes</span>
          <AkFilesUpload v-model="files" :displayLabel="false" />
        </div>
      </AkFormSubmitted>
    </template>
  </GaView>
</template>

<style lang="scss" scoped>
.create-prestataire-form {
  .section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    background-color: #ffffff;
    border-radius: 16px;
    margin-bottom: 24px;
    gap: 16px;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 40px;
    }
  }

  .input-item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 6px;

    > span {
      font-family: 'Nunito';
      font-weight: 600;
      font-size: 14px;
      color: #344054;
    }

    > div {
      display: flex;
      flex-direction: row;
      gap: 8px;

      > label {
        font-family: 'Nunito';
        font-weight: 600;
        font-size: 14px;
        color: #344054;
        margin-right: 20px;
      }
    }
  }

  .title {
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 16px;
    color: #344054;
  }

  .p-inputtext {
    height: 40px;
  }

  .border-grey {
    border-color: #d0d5dd;
  }

  .p-fileupload {
    width: 100%;
  }

  .col-4 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.btn-create {
  height: 44px;
  background-color: #0b3575;
  color: #ffffff;
  float: right;
}
</style>
