<script>
import {Icon} from '@iconify/vue/dist/iconify';

/* COMPOSANTS */
import AkAccommodationAutocomplete from '@components/general/AkAccommodationAutocomplete';
import AkCalendar from '@components/input/AkCalendar';
import AkDropdown from '@components/input/AkDropdown';
import AkInputInteger from '@components/input/AkInputInteger';
import AkReservationAutocomplete from '@components/general/AkReservationAutocomplete';
import MultiSelect from 'primevue/multiselect';

/* MIXINS */
import reservationCancelledBefore30 from '@mixins/const/reservationCancelledBefore30';
import reservationSiteConst from '@mixins/const/reservationSiteConst';
import reservationStatusRefundConst from '@mixins/const/reservationStatusRefundConst';
import sidebarMixin from '@mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import companyService from '@services/companyService';

export default {
  components: {
    Icon,
    AkAccommodationAutocomplete,
    AkCalendar,
    AkDropdown,
    AkInputInteger,
    AkReservationAutocomplete,
    MultiSelect,
  },
  mixins: [reservationCancelledBefore30, reservationSiteConst, reservationStatusRefundConst, sidebarMixin, utilsMixin],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      companies: undefined,
    };
  },
  mounted() {
    companyService.companies().then((data) => {
      this.companies = data;
    });
  },
  methods: {
    search() {
      if (this.currentValue.dateCheckout) {
        this.currentValue.dateCheckoutBegin = this.currentValue.dateCheckout[0];
        this.currentValue.dateCheckoutEnd = this.currentValue.dateCheckout[1] ?? this.currentValue.dateCheckout[0];
      }
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.accommodationAutocomplete) this.$refs.accommodationAutocomplete.reset();
      if (this.$refs.reservationAutocomplete) this.$refs.reservationAutocomplete.reset();
      this.callback[1]();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <h2>Filtrer</h2>
  <form class="d-flex flex-column" style="row-gap: 16px" @submit.prevent="search">
    <MultiSelect
      v-model="currentValue.companyIds"
      :placeholder="$t('accommodation.company')"
      :options="this.companies"
      :loading="this.companies === undefined"
      option-label="name"
      option-value="id"
      class="flex-1 form-control"
      :display-label="false" />
    <AkAccommodationAutocomplete
      ref="accommodationAutocomplete"
      v-model="currentValue.accommodationId"
      :inline="true"
      :placeholder="$t('reservation.accommodationId')"
      class-name="flex-1" />
    <AkReservationAutocomplete
      ref="reservationAutocomplete"
      v-model="currentValue.avantioLocalizer"
      :inline="true"
      :placeholder="$t('reservation.number')"
      class-name="flex-1" />
    <AkCalendar
      v-model="currentValue.dateCancel"
      :inline="true"
      :placeholder="$t('reservation.dateCancel')"
      :title="$t('reservation.dateCancel')"
      class-name="flex-1" />
    <AkCalendar
      selection="range"
      v-model="currentValue.dateCheckout"
      :inline="true"
      :placeholder="$t('reservation.dateCheckout')"
      :title="$t('reservation.dateCancel')"
      class-name="flex-1" />
    <AkDropdown
      inline
      v-model="currentValue.cancelled30Days"
      :label="$t('reservation.cancelledBefore30')"
      :options="this.reservationCancelledBefore30"
      class-name="flex-1 form-control"
      option-label="label"
      option-value="value" />
    <MultiSelect
      v-model="currentValue.statusRefundList"
      :placeholder="$t('reservation.statusRefund')"
      :options="reservationStatusRefundConst"
      option-label="label"
      option-value="value"
      class="flex-1 form-control"
      :display-label="false" />
    <MultiSelect
      v-model="currentValue.reservationSiteList"
      :placeholder="$t('reservation.reservationSite')"
      :options="reservationSiteConst"
      option-label="label"
      option-value="value"
      class="flex-1 form-control"
      :display-label="false" />
    <AkInputInteger
      v-model="currentValue.nbDaysCancelled"
      :label="$t('reservation.nbDaysCancelled')"
      :placeholder="$t('reservation.nbDaysCancelled')"
      class-name="flex-1"
      :inline="true"
      :display-label="false" />

    <div class="d-flex flex-row">
      <button class="btn btn-primary btn-search flex-1" type="submit">
        {{ this.$t('search.search') }}
      </button>
      <Icon
        v-on:click="this.reset()"
        class="pointer"
        icon="basil:cross-outline"
        :style="{fontSize: '50px', width: '50px !important'}" />
    </div>
  </form>
</template>
