<script>
/* COMPOSANTS */
import GaContentView from '@components/layout/GaContentView';
import AkDialog from '@components/general/AkDialog';

/* MIXINS */
import loaderMixin from '@mixins/loaderMixin';
import randomRef from '@mixins/randomRef';
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import employeeService from '@services/employeeService';
import unavailabilityService from '@services/unavailabilityService';

export default {
  components: {
    GaContentView,
    AkDialog,
  },
  mixins: [utilsMixin, randomRef, permissionsMixin, loaderMixin, utilsMixin],
  props: {
    modelValue: {required: true},
  },
  data() {
    return {
      employee: {},
      unavailability: {},
      unavailabilities: [],
      employeeId: undefined,
      loading: true,
      item: {},
    };
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  created() {},
  mounted() {
    this.getRef().showLoader(this.$refs.form);
    this.employeeId = this.$route.params.employeeId;
    let p1 = employeeService.employee(this.employeeId);
    p1.then((data) => {
      this.employee = data;
    });

    let p2 = unavailabilityService.unavailabilitiesByEmployeeId(this.employeeId);
    p2.then((data) => {
      this.unavailabilities = data;
      this.loading = false;
    });

    Promise.all([p1, p2]).then(this.getRef().hideLoader());
  },
  methods: {
    openDeleteDialog(item) {
      this.item = item;
      this.$refs.dialogDelete.show();
    },
    delete() {
      unavailabilityService
        .delete(this.item)
        .then((data) => {
          this.removeInListById(this.unavailabilities, data.id);
          this.getRef().success(this.$t('employee_unavailability.deleted'));
        })
        .catch((e) => {
          let content = 'Une erreur est survenue durant la suppression';
          if (e.response) content = 'Une erreur est survenue durant la suppression : ' + e.response.data.error;
          this.getRef().error(content);
        });
    },
    rowClick(event) {
      let data = event.data;
      this.$router.push({
        name: 'unavailabilityUpdate',
        params: {employeeId: this.employeeId, unavailabilityId: data.id},
      });
    },
  },
};
</script>

<template>
  <GaContentView :ref="ref" :title="$t('unavailabilities')">
    <template #action>
      <router-link
        v-if="this.employeeId"
        :to="{name: 'unavailabilityCreate', params: {employeeId: this.employeeId}}"
        class="btn btn-inverse-primary btn-xs">
        <i class="gst-plus pr-1" />{{ $t('employee.unavailability_add') }}
      </router-link>
    </template>

    <template #content>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive">
                <DataTable
                  :always-show-paginator="false"
                  :loading="loading"
                  :paginator="true"
                  :rows="10"
                  :rows-per-page-options="[10, 20, 50]"
                  :value="unavailabilities"
                  class="table pointer"
                  current-page-report-template="{first}-{last}/{totalRecords}"
                  removable-sort
                  responsive-layout="scroll"
                  @row-click="rowClick($event)"
                  striped-rows>
                  <template #empty>
                    {{ $t('employee_unavailability.empty') }}
                  </template>
                  <Column :header="$t('employee_unavailability.label')" :sortable="true" field="label">
                    <template #body="slotProps">
                      {{ slotProps.data.label }}
                    </template>
                  </Column>
                  <Column :header="$t('employee_unavailability.startDate')" :sortable="true" field="startDate">
                    <template #body="slotProps">
                      <span v-if="slotProps.data.startDate">
                        {{ displayDate(displayUTCDate(slotProps.data.startDate)) }}
                      </span>
                    </template>
                  </Column>
                  <Column :header="$t('employee_unavailability.endDate')" :sortable="true" field="endDate">
                    <template #body="slotProps">
                      <span v-if="slotProps.data.endDate">
                        {{ displayDate(displayUTCDate(slotProps.data.endDate)) }}
                      </span>
                    </template>
                  </Column>
                  <Column :header="$t('employee_unavailability.days')" :sortable="true" field="days">
                    <template #body="slotProps">
                      <span v-if="slotProps.data.weeksNumber">
                        {{
                          $t('employee_unavailability.weeksNbr1') +
                          ' ' +
                          slotProps.data.weeksNumber +
                          ' ' +
                          $t('employee_unavailability.weeksNbr2')
                        }}&nbsp;&nbsp;
                      </span>

                      <span v-if="slotProps.data.monday">{{ $t('employee_unavailability.monday') }}&nbsp;&nbsp;</span>
                      <span v-if="slotProps.data.tuesday">{{ $t('employee_unavailability.tuesday') }}&nbsp;&nbsp;</span>
                      <span v-if="slotProps.data.wednesday"
                        >{{ $t('employee_unavailability.wednesday') }}&nbsp;&nbsp;</span
                      >
                      <span v-if="slotProps.data.thursday"
                        >{{ $t('employee_unavailability.thursday') }}&nbsp;&nbsp;</span
                      >
                      <span v-if="slotProps.data.friday">{{ $t('employee_unavailability.friday') }}&nbsp;&nbsp;</span>
                      <span v-if="slotProps.data.saturday"
                        >{{ $t('employee_unavailability.saturday') }}&nbsp;&nbsp;</span
                      >
                      <span v-if="slotProps.data.sunday">{{ $t('employee_unavailability.sunday') }}</span>
                    </template>
                  </Column>
                  <Column
                    body-style="text-align: center; overflow: visible"
                    header-style=" width: 160px; text-align: center">
                    <template #body="slotProps">
                      <div class="d-flex justify-content-end align-items-center">
                        <router-link
                          :to="{
                            name: 'unavailabilityUpdate',
                            params: {employeeId: this.employeeId, unavailabilityId: slotProps.data.id},
                          }"
                          class="btn btn-xs btn-inverse-primary mr-1">
                          <i class="fe fe-edit" />
                        </router-link>
                        <span class="btn btn-xs btn-inverse-danger mr-1" @click.stop="openDeleteDialog(slotProps.data)">
                          <i class="fe fe-trash" />
                        </span>
                      </div>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #extra>
      <AkDialog
        ref="dialogDelete"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.delete()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span>{{ $t('employee_unavailability.confirm_delete') }}</span>
        </div>
      </AkDialog>
    </template>
  </GaContentView>
</template>
