<script>
import {Icon} from '@iconify/vue/dist/iconify';

/* COMPOSANTS */
import AkInputText from '@components/input/AkInputText.vue';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';

/* SERVICES */

export default {
  components: {
    AkInputText,
    Icon,
  },
  mixins: [utilsMixin, sidebarMixin],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      this.callback[1]();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    loadingCompanies() {
      return this.companies === undefined;
    },
  },
};
</script>

<template>
  <h2>Filtrer</h2>
  <form class="d-flex flex-column" style="row-gap: 16px" @submit.prevent="search">
    <AkInputText v-model="currentValue.name" :placeholder="$t('client.name')" class-name="flex-1" type="text" inline />
    <AkInputText
      v-model="currentValue.email"
      :placeholder="$t('client.email')"
      class-name="flex-1"
      type="text"
      inline />
    <AkInputText
      v-model="currentValue.phone"
      :placeholder="$t('client.phone')"
      class-name="flex-1"
      type="text"
      inline />

    <div class="d-flex flex-row">
      <button class="btn btn-primary btn-search flex-1" type="submit">
        {{ this.$t('visitor_tax.filter') }}
      </button>
      <Icon
        v-on:click="this.reset()"
        class="pointer"
        icon="basil:cross-outline"
        :style="{fontSize: '50px', width: '50px !important'}" />
    </div>
  </form>
</template>
