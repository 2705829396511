<script>
/* VALIDATIONS */
import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

/* COMPOSANTS */
import PButton from 'primevue/button';
import GaView from '@components/layout/GaView';
import AkDialog from '@components/general/AkDialog.vue';
import AkInputTextArea from '@components/input/AkInputTextArea.vue';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import randomRef from '@mixins/randomRef';
import permissionsMixin from '@mixins/permissionsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';
import prestationTypesConst from '@mixins/const/prestationTypesConst';
import missionMonitoringStatusConst from '@mixins/const/missionMonitoringStatusConst';

/* SERVICES */
import missionSuiviPartnerService from '@services/missionSuiviPartnerService';

export default {
  components: {PButton, GaView, AkDialog, AkInputTextArea},
  mixins: [
    utilsMixin,
    randomRef,
    sidebarMixin,
    loaderMixin,
    permissionsMixin,
    messageMixin,
    prestationTypesConst,
    missionMonitoringStatusConst,
  ],
  metaInfo() {
    return {
      title: 'mission_monitoring.title',
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      loading: true,
      filter: {
        status: 'TO_DO',
        dateCheckin: new Date(), // Date du jour
        dateCheckout: new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()), // Date du jour + 1 mois
      },
      list: [],
      missionCancel: {},
    };
  },
  validations() {
    return {
      missionCancel: {
        id: {required},
        comment: {required},
      },
    };
  },
  computed: {
    isEmptyList() {
      return !this.list || this.list.length === 0;
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    // FILTER

    openFilterPanel() {
      this.toggleFilter('GaMissionSuiviPartnerFilter', this.filter, this.refresh, this.resetFilter);
    },
    resetFilter() {
      this.filter = {
        status: 'TO_DO',
      };
      this.toggleFilter('GaMissionSuiviPartnerFilter', this.filter, this.refresh, this.resetFilter);
    },

    refresh() {
      missionSuiviPartnerService
        .suivi(this.filter)
        .then((data) => {
          this.list = data;
        })
        .catch((e) => {
          this.addError(e.response.data.error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // EXPORT

    exportList() {
      this.showTotalLoaderWithAfter(this.$t('exporting'));
      missionSuiviPartnerService
        .exportList(this.filter)
        .then((data) => {})
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.hideLoader();
        });
    },

    // COMPLETE

    complete(missionId) {
      this.loading = true;

      const updateDTO = {
        id: missionId,
        comment: '',
      };

      missionSuiviPartnerService
        .complete(updateDTO)
        .then(() => {
          this.addSuccess(this.$t('mission_monitoring.success_complete'));
          this.refresh();
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
          this.loading = false;
        });
    },

    // CANCEL

    openCancelDialog(missionId) {
      this.missionCancel = {
        id: missionId,
      };
      this.$refs.dialogCancelMission.show();
    },

    cancel() {
      this.v$.$touch();
      if (this.v$.missionCancel.$error) return;

      this.$refs.dialogCancelMission.hide();

      this.loading = true;

      missionSuiviPartnerService
        .cancel(this.missionCancel)
        .then(() => {
          this.addSuccess(this.$t('mission_monitoring.success_cancel'));
          this.refresh();
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
          this.loading = false;
        });
    },

    // UTILS

    isStatusTodo(status) {
      return status === 'TO_DO';
    },
  },
  watch: {},
};
</script>
<template>
  <GaView :ref="ref" :title="$t('mission_monitoring.title')">
    <template #action>
      <div class="d-flex justify-content-end" style="gap: 16px">
        <PButton
          class="btn btn-primary"
          @click="openFilterPanel()"
          :badge="this.countFilter(this.filter)"
          badgeClass="p-badge-primary"
          :label="$t('search.filter')">
        </PButton>

        <button class="btn btn-primary" @click="exportList()" :disabled="this.isEmptyList">
          {{ this.$t('export') }}
        </button>
      </div>
    </template>

    <template #content>
      <DataTable
        ref="table"
        :always-show-paginator="true"
        :loading="loading"
        :paginator="true"
        :rows="10"
        :rows-per-page-options="[10, 20, 50]"
        :value="list"
        class="table"
        current-page-report-template="{first}-{last}/{totalRecords}"
        responsive-layout="scroll"
        striped-rows
        removable-sort>
        <template #empty>
          {{ $t('mission_monitoring.empty') }}
        </template>

        <Column :header="$t('mission_monitoring.prestation_date')">
          <template #body="slotProps">
            <span>
              {{ this.formatDateIfNeeded(slotProps.data.prestationDate) }}
            </span>
          </template>
        </Column>

        <Column :header="$t('mission_monitoring.prestation_type')">
          <template #body="slotProps">
            <span>
              {{ this.displayForPrestationTypesConst(slotProps.data.prestationType) }}
            </span>
          </template>
        </Column>

        <Column :header="$t('mission_monitoring.accommodation_name')">
          <template #body="slotProps">
            <span>
              <router-link :to="{name: 'accommodationPage', params: {accommodationId: slotProps.data.accommodationId}}">
                {{ slotProps.data.accommodationName }}
              </router-link>
            </span>
          </template>
        </Column>

        <Column :header="$t('mission_monitoring.reservation_num')">
          <template #body="slotProps">
            <router-link
              :to="{
                name: 'accommodationReservationPage',
                params: {
                  accommodationId: slotProps.data.accommodationId,
                  reservationId: slotProps.data.reservationId,
                },
              }">
              {{ slotProps.data.reservationNum }}
            </router-link>
          </template>
        </Column>

        <Column :header="$t('mission_monitoring.date_checkin')">
          <template #body="slotProps">
            <span>
              {{ this.formatDateIfNeeded(slotProps.data.dateCheckin) }}
            </span>
          </template>
        </Column>

        <Column :header="$t('mission_monitoring.date_checkout')">
          <template #body="slotProps">
            <span>
              {{ this.formatDateIfNeeded(slotProps.data.dateCheckout) }}
            </span>
          </template>
        </Column>

        <Column :header="$t('mission_monitoring.status')">
          <template #body="slotProps">
            <span>
              {{ this.displayForMissionMonitoringStatusConst(slotProps.data.status) }}
            </span>
          </template>
        </Column>

        <Column>
          <template #body="slotProps">
            <div v-if="this.isStatusTodo(slotProps.data.status)" class="flex justify-content-center">
              <button class="btn btn-xs btn-inverse-primary mr-1 w-75" @click="this.complete(slotProps.data.id)">
                {{ this.$t('mission_monitoring.finish') }}
              </button>
              <button class="btn btn-xs btn-danger mr-1 mt-1 w-75" @click="this.openCancelDialog(slotProps.data.id)">
                {{ this.$t('mission_monitoring.canceled') }}
              </button>
            </div>
          </template>
        </Column>
      </DataTable>
    </template>

    <template #extra>
      <!-- DIALOG CANCEL MISSION -->

      <AkDialog
        ref="dialogCancelMission"
        :title="$t('mission_monitoring.cancel_mission')"
        :validate-label="$t('validate')"
        width="800px"
        @validate="cancel()">
        <div class="form-row">
          <AkInputTextArea
            v-model="missionCancel.comment"
            :label="$t('mission_monitoring.cancel_comment')"
            :placeholder="$t('mission_monitoring.cancel_comment')"
            :validator="v$.missionCancel.comment"
            class-name="col-12" />
        </div>
      </AkDialog>
    </template>
  </GaView>
</template>
