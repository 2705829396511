<script>
/* COMPOSANTS */
import AkCheckboxButton from '@components/input/AkCheckboxButton';

/* MIXINS */
import yesNoButtonConst from '@mixins/const/yesNoButtonConst';

/* SERVICES */

export default {
  components: {AkCheckboxButton},
  mixins: [yesNoButtonConst],
  props: {
    modelValue: {
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      required: false,
      default: 'col-md-6',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {};
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  methods: {},
};
</script>

<template>
  <AkCheckboxButton
    v-model="currentValue"
    :label="label"
    :multiple="false"
    :options="yesNoButtonConst"
    :class-name="className" />
</template>
