<script>
import moment from 'moment';
import permissionsMixin from '../../mixins/permissionsMixin';
import AkReservationTooltip from './AkReservationTooltip';
import {Icon} from '@iconify/vue/dist/iconify';

export default {
  components: {AkReservationTooltip, Icon},
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    dateRef: {
      type: moment,
      required: false,
      default: undefined,
    },
  },
  mixins: [permissionsMixin],
  data() {
    return {
      months: [],
      weekDays: [],
      group: [],
      reservationTooltip: undefined,
    };
  },
  emits: ['hide-loader', 'tooltip', 'next', 'prev', 'needReload', 'edit-reservation', 'add-reservation'],
  updated() {
    this.$emit('hide-loader');
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    year() {
      return this.dateRef.year();
    },
  },
  created() {
    this.weekDays = moment.weekdaysMin(); // Get weekdays in the current locale
    this.weekDays.push(this.weekDays.shift()); // Move Sunday to the end
    this.generateYear();
    this.$emit('hide-loader');
  },
  watch: {
    year: function (newVal, oldVal) {
      if (newVal !== oldVal) this.generateYear();
    },
    currentValue: function (newVal, oldVal) {
      this.generateYear();
    },
  },
  methods: {
    next() {
      this.$emit('next');
    },
    prev() {
      this.$emit('prev');
    },
    hasFirstSlot(reservations, year, month, day) {
      var d = moment({year: year, month: month.number, day: day.number});
      if (reservations && reservations.length === 1) {
        let startDate = reservations[0].dateStart;
        if (moment(startDate).startOf('date').isSame(d.startOf('date'))) {
          return true;
        }
      }
      return false;
    },

    hasLastSlot(reservations, year, month, day) {
      var d = moment({year: year, month: month.number, day: day.number});
      if (reservations && reservations.length === 1) {
        let startDate = reservations[0].dateEnd;
        if (moment(startDate).startOf('date').isSame(d.startOf('date'))) {
          return true;
        }
      }
      return false;
    },
    displayTooltipCell(event, year, month, day) {
      if (this.group[year]) {
        if (this.group[year][month.number]) {
          const reservationsList = this.group[year][month.number][day.number];
          if (reservationsList) this.displayTooltip(event, reservationsList[reservationsList.length - 1]);
          else {
            if (!this.isPartenaire() && !this.isHousekeeper()) {
              this.$emit('add-reservation', year, month, day);
            }
          }
        } else {
          if (!this.isPartenaire() && !this.isHousekeeper()) {
            this.$emit('add-reservation', year, month, day);
          }
        }
      } else {
        if (!this.isPartenaire() && !this.isHousekeeper()) {
          this.$emit('add-reservation', year, month, day);
        }
      }
    },
    displayTooltip(event, reservation) {
      this.$refs.tooltip.hide();
      let that = this;
      setTimeout(function () {
        that.reservationTooltip = reservation ? reservation : undefined;
        if (that.reservationTooltip) {
          let target = event.target;
          if (target !== undefined) that.$refs.tooltip.toggle(event, target);
        }
      }, 100);
    },
    typeCss(r) {
      if (r === undefined) return '';
      if (this.isOwner()) {
        if (r.statusSmily === 'Unavailable' && r.type === 'OWNER') return 'owner';
        if (r.statusSmily === 'Unavailable' && r.type === 'BLOCKADE') return 'blockade';
        if (r.statusSmily === 'Booked') return 'occuped';
        return '';
      }
      if (r.statusSmily === 'Unavailable' && r.type === 'OWNER') return 'owner';
      if (r.statusSmily === 'Unavailable' && r.type === 'BLOCKADE') return 'blockade';
      if (r.statusSmily === 'Booked') return 'confirmada';
      if (r.statusSmily === 'Tentative') return 'peticion';

      return '';
    },
    groupReservation() {
      this.group = {};
      if (this.currentValue.reservationList) {
        this.currentValue.reservationList.forEach((objet) => {
          const currentDay = objet.dateStart.clone().startOf('date'); // Commence par la date de début

          while (currentDay.isSameOrBefore(objet.dateEnd, 'day')) {
            const year = currentDay.year();
            const mois = currentDay.month();
            const jour = currentDay.date();

            if (!this.group[year]) {
              this.group[year] = [];
            }
            if (!this.group[year][mois]) {
              this.group[year][mois] = [];
            }

            if (!this.group[year][mois][jour]) {
              this.group[year][mois][jour] = [];
            }
            this.group[year][mois][jour].push(objet);
            this.group[year][mois][jour].sort((a, b) => (a.dateStart.isBefore(b.dateStart) ? -1 : 1));

            currentDay.add(1, 'day'); // Passe au jour suivant
          }
        });
      }

      return this.group;
    },
    generateYear() {
      this.months = [];
      let reservationByDay = this.groupReservation();
      for (let i = 0; i < 12; i++) {
        const month = moment({year: this.year, month: i, day: 1});
        const monthDays = month.daysInMonth();
        const firstDay = month.isoWeekday();

        this.months.push({
          name: month.format('MMMM'),
          number: i,
          days: monthDays,
          weeks: this.generateWeeksArray(this.year, i, monthDays, firstDay, reservationByDay),
        });
      }
    },
    generateWeeksArray(year, month, monthDays, firstDay, reservationByDay) {
      const weeks = [];
      let week = Array(firstDay - 1).fill({number: 0}); // Fill with placeholders for previous month's days
      let resa = undefined;
      for (let day = 1; day <= monthDays; day++) {
        if (reservationByDay[year]) {
          if (reservationByDay[year][month]) {
            resa = reservationByDay[year][month][day];
          }
        }
        week.push({number: day, reservations: resa});
        if (week.length === 7) {
          weeks.push(week);
          week = [];
        }
      }

      // Add placeholders for next month's days
      if (week.length > 0) {
        week = week.concat(Array(7 - week.length).fill({number: 0}));
        weeks.push(week);
      }

      return weeks;
    },
    isActive(reservations) {
      if (!this.isPartenaire() && !this.isHousekeeper()) return true;
      if (reservations === undefined) {
        return false;
      }
      return reservations.length > 0;
    },
  },
};
</script>
<template>
  <div class="planning-yearly">
    <h2>{{ currentValue.accommodation.name }}</h2>

    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center primary-hover pointer" @click="prev()">
        <Icon icon="eva:chevron-left-outline" style="font-size: 30px; width: 30px !important" />
        <span>Année précédente</span>
      </div>
      <div class="poppins-bold f-22 text-black">
        {{ this.year }}
      </div>
      <div class="d-flex align-items-center primary-hover pointer" @click="next()">
        <span>Année suivante</span>
        <Icon icon="eva:chevron-right-outline" style="font-size: 30px; width: 30px !important" />
      </div>
    </div>
    <div class="months-container">
      <div v-for="(month, index) in months" :key="index" class="month">
        <span class="poppins-medium text-center d-block text-capitalize text-black">{{ month.name }}</span>
        <table style="table-layout: fixed">
          <thead>
            <tr>
              <th v-for="day in weekDays" :key="day">{{ day }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(week, weekIndex) in month.weeks" :key="weekIndex">
              <td
                v-for="(day, dayIndex) in week"
                :key="dayIndex"
                :class="[
                  this.isActive(day.reservations) ? 'pointer' : '',
                  dayIndex >= 5 ? 'wk' : '',
                  this.hasFirstSlot(day.reservations, this.year, month, day) ? 'start' : '',
                  this.hasLastSlot(day.reservations, this.year, month, day) ? 'last' : '',
                  day.reservations && day.reservations.length === 2 ? 'double' : '',
                ]"
                @click="displayTooltipCell($event, this.year, month, day)">
                <span v-for="(r, resaIndex) in day.reservations" :key="resaIndex" :class="[typeCss(r)]" />
                <span :class="[day.reservations ? 'text-white' : '']" class="position-relative" style="z-index: 11">{{
                  day.number > 0 ? day.number : ''
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <AkReservationTooltip
    ref="tooltip"
    @edit-reservation="
      (args) => {
        this.$emit('edit-reservation', args);
      }
    "
    @need-reload="
      (args) => {
        this.$emit('needReload', args);
      }
    "
    v-model="reservationTooltip" />
</template>
<style scoped>
.months-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 12px;
  row-gap: 12px;
}

.month {
  flex-basis: calc(25% - 9px);
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

th {
  background: #a6a9b7;
  font-weight: 900;
  color: black;
  text-transform: capitalize;
  padding: 2px 8px;
}

td {
  position: relative;
}

.wk {
  background: #f8f9fa;
}

.prev-month,
.next-month {
  color: #ccc;
}
</style>
