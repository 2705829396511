<script>
import ficService from '@services/ficService';

export default {
  props: {
    fic: {
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      base64: null,
    };
  },
  mounted() {
    ficService
      .preview(this.fic)
      .then((data) => {
        this.base64 = data;
        this.loading = false;
      })
      .catch(() => {
        // Ne pas afficher une erreur Axios à l'utilisateur
      });
  },
  methods: {
    downloadFile(fic, isPreview) {
      ficService.download(fic, isPreview).catch((e) => {
        if (e.response.status === 404) {
          this.addError("Le fichier n'existe pas !");
        }
      });
    },
  },
};
</script>

<template>
  <span class="mission-photo pointer" @click="downloadFile(fic, true)">
    <img v-if="!loading" :src="base64" :alt="fic.name" />
  </span>
</template>

<style lang="scss" scoped>
.mission-photo {
  width: 120px;
  height: 120px;
  border-radius: 8px;
  overflow: hidden;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
