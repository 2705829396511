<script>
import useVuelidate from '@vuelidate/core';
import {ref} from 'vue';
import bigDecimal from 'js-big-decimal';

/* COMPOSANTS */
import PButton from 'primevue/button';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import comptaOperationService from '@services/comptaOperationService';
import permissionsMixin from '@mixins/permissionsMixin';
import {PermissionMap} from '@mixins/const/permissionConst';
import sidebarMixin from '@mixins/sidebarMixin';

export default {
  components: {
    PButton,
  },
  mixins: [utilsMixin, loaderMixin, messageMixin, permissionsMixin, sidebarMixin],
  metaInfo() {
    return {
      title: 'visitor_tax.tds',
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      operations: [],
      operationsToDisplay: [],
      stayrates: [],
      search: {},
      groupId: null,
      selectedStayrates: [],
      selectAll: false,
      loading: false,
      total: 0,
      totalSelected: 0,
      totalReversed: 0,
      totalNotReversed: 0,
    };
  },
  validations() {
    return {};
  },
  mounted() {
    this.initVisitorTax();
  },
  computed: {
    canFlag() {
      if (this.operationsToDisplay.length > 0) {
        let tdsGroupId = this.operationsToDisplay[0].tdsGroupId;
        for (let operation of this.operationsToDisplay) {
          if (operation.tdsGroupId !== tdsGroupId) {
            return false;
          }
        }
        return true;
      }
      return false;
    },
    hasManagePermission() {
      return this.hasPermission(PermissionMap.TDS.MANAGE);
    },
    totalDisplay() {
      return this.formatCurrencyEUR(this.displayAmount(this.total));
    },
    totalSelectedDisplay() {
      return this.formatCurrencyEUR(this.displayAmount(this.totalSelected));
    },
    totalReversedDisplay() {
      return this.formatCurrencyEUR(this.displayAmount(this.totalReversed));
    },
    totalNotReversedDisplay() {
      return this.formatCurrencyEUR(this.displayAmount(this.totalNotReversed));
    },
  },
  methods: {
    ref,
    initVisitorTax() {
      this.loading = true;
      this.search = {};
      this.operationsToDisplay = [];
      this.groupId = null;
      this.total = 0;
      this.totalSelected = 0;
      this.totalReversed = 0;
      this.totalNotReversed = 0;

      let p1 = comptaOperationService.staytaxes(this.search);
      p1.then((data) => {
        this.operations = data;
        this.operationsToDisplay = data;
        let amountReversed = new bigDecimal(0);
        let amountNotReversed = new bigDecimal(0);
        for (let operation of this.operationsToDisplay) {
          if (operation.stateReversed) {
            operation.status = this.$t('visitor_tax.state_reversed');
            amountReversed = amountReversed.add(new bigDecimal(operation.amount));
          } else {
            operation.status = this.$t('visitor_tax.state_not_reversed');
            amountNotReversed = amountNotReversed.add(new bigDecimal(operation.amount));
          }
          this.total = this.addNumbers([this.total, operation.amount]);
        }
        this.totalReversed = amountReversed.round(2, bigDecimal.RoundingModes.HALF_EVEN).getValue();
        this.totalNotReversed = amountNotReversed.round(2, bigDecimal.RoundingModes.HALF_EVEN).getValue();
        this.loading = false;
      });
    },
    resetFilter() {
      this.selectedStayrates = [];
      this.groupId = null;
      this.search = {};
      this.toggleFilter('GaStayRateConsultationFilter', this.search, this.filterSearch, this.resetFilter);
    },
    filterSearch() {
      this.operationsToDisplay = [];
      this.selectedStayrates = [];
      this.totalSelected = 0;
      this.total = 0;
      this.totalReversed = 0;
      this.totalNotReversed = 0;
      this.loading = true;

      let p1 = comptaOperationService.staytaxes(this.search);
      p1.then((data) => {
        this.operationsToDisplay = data;
        let amountReversed = new bigDecimal(0);
        let amountNotReversed = new bigDecimal(0);
        for (let operation of this.operationsToDisplay) {
          if (operation.stateReversed) {
            operation.status = this.$t('visitor_tax.state_reversed');
            amountReversed = amountReversed.add(new bigDecimal(operation.amount));
          } else {
            operation.status = this.$t('visitor_tax.state_not_reversed');
            amountNotReversed = amountNotReversed.add(new bigDecimal(operation.amount));
          }
          this.total += operation.amount;
          let amount = new bigDecimal(0);
          amount = amount.add(new bigDecimal(operation.amount));
          operation.amount = amount.abs().round(2, bigDecimal.RoundingModes.HALF_EVEN).getValue();
        }
        this.totalReversed = amountReversed.round(2, bigDecimal.RoundingModes.HALF_EVEN).getValue();
        this.totalNotReversed = amountNotReversed.round(2, bigDecimal.RoundingModes.HALF_EVEN).getValue();
        this.loading = false;
      });
    },
    exportStayrate() {
      this.showTotalLoaderWithAfter(this.$t('visitor_tax.stayrate_export_in_progress'));
      comptaOperationService
        .exportStayrate(this.search)
        .then((data) => {})
        .catch((error) => {
          let message = this.msgForErr(this.$t, error);
          this.addError(message);
        })
        .finally(() => {
          this.hideLoader();
        });
    },
    selectAllStayrates() {
      this.selectedStayrates = [];

      if (!this.selectAll) {
        for (let stayrate of this.operationsToDisplay) {
          if (stayrate.stateReversed === false) {
            this.selectedStayrates.push(stayrate.idOperation);
          }
        }
      }
    },
    flagStayrates() {
      if (this.selectedStayrates.length === 0) {
        this.addError(this.$t('visitor_tax.error_empty_selection'));
        return;
      }
      this.loading = true;
      this.groupId = this.operationsToDisplay[0].tdsGroupId;

      comptaOperationService
        .createTDSGroup(this.selectedStayrates, this.groupId)
        .then(() => {
          this.success(this.$t('visitor_tax.stayrate_group_added'));
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 412) {
              this.addError(this.$t('visitor_tax.error_stayrate_account'), true, true);
            } else {
              this.addError(this.$t('error.' + e.response.data.error));
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.selectedStayrates = [];
          this.filterSearch();
        });
    },
    updateTotal(data) {
      let added = false;
      for (let id of this.selectedStayrates) {
        if (data.idOperation === id) {
          this.totalSelected += parseFloat(data.amount);
          added = true;
        }
      }
      if (!added) {
        this.totalSelected -= data.amount;
      }
    },
    displayAmount(num) {
      if (num !== undefined && num !== null) {
        let amount = new bigDecimal(0);
        amount = amount.add(new bigDecimal(num));
        return amount.round(2, bigDecimal.RoundingModes.HALF_EVEN).getValue();
      }
    },
    openFilterPanel() {
      this.toggleFilter('GaStayRateConsultationFilter', this.search, this.filterSearch, this.resetFilter);
    },
  },
};
</script>
<template>
  <GaContentView :ref="ref" :title="$t('visitor_tax.tds')">
    <template #action>
      <div class="d-flex justify-content-end" style="gap: 16px">
        <PButton
          class="btn btn-inverse-primary btn-xs"
          :badge="this.countFilter(this.search)"
          badgeClass="p-badge-primary"
          label="Filtrer"
          @click="openFilterPanel()" />
        <button
          v-if="canFlag"
          class="btn btn-inverse-primary btn-xs"
          @click="flagStayrates()"
          :title="$t('visitor_tax.submit')">
          {{ this.$t('visitor_tax.submit') }}
        </button>
        <button class="btn btn-inverse-primary btn-xs" @click="exportStayrate()">
          {{ $t('payment.download_excel') }}
        </button>
      </div>
    </template>

    <template #content>
      <DataTable
        :always-show-paginator="false"
        :paginator="false"
        :rows="10"
        :rows-per-page-options="[10, 20, 50]"
        :value="this.operationsToDisplay"
        :loading="this.loading"
        class="table"
        current-page-report-template="{first}-{last}/{totalRecords}"
        removable-sort
        responsive-layout="scroll"
        sort-field="position"
        striped-rows>
        <template #empty>
          {{ $t('operation.empty') }}
        </template>

        <Column v-if="this.hasManagePermission" class="text-center" style="width: 40px">
          <template #header>
            <input type="checkbox" v-model="selectAll" @click="selectAllStayrates" />
          </template>
          <template #body="slotProps">
            <input
              v-if="!slotProps.data.stateReversed"
              type="checkbox"
              :value="slotProps.data.idOperation"
              v-model="selectedStayrates"
              @change="updateTotal(slotProps.data)" />
          </template>
        </Column>

        <Column field="dateOperation" :sortable="true" :header="$t('accounting.date')">
          <template #body="slotProps">
            {{ displayDate(slotProps.data.dateOperation) }}
          </template>
        </Column>

        <Column :header="$t('visitor_tax.date_checkin')" field="dateStart" :sortable="true">
          <template #body="slotProps">
            {{ this.displayDate(slotProps.data.dateStart) }}
          </template>
        </Column>
        <Column :header="$t('visitor_tax.date_checkout')" field="dateEnd" :sortable="true">
          <template #body="slotProps">
            {{ this.displayDate(slotProps.data.dateEnd) }}
          </template>
        </Column>
        <Column :header="$t('activity.proprietaire')" field="ownerDisplay" :sortable="true"></Column>
        <Column :header="$t('visitor_tax.number')" field="numResa" :sortable="true"></Column>
        <Column :header="$t('visitor_tax.group')" field="tdsGroupLabel" :sortable="true"> </Column>
        <Column class="text-right" field="amount" :sortable="true">
          <template #header>
            {{ this.$t('visitor_tax.amount') }}
          </template>
          <template #body="slotProps">
            {{ formatCurrencyEUR(displayAmount(slotProps.data.amount)) }}
          </template>
        </Column>
        <Column :header="$t('visitor_tax.type')" :sortable="true" field="typeTDS"> </Column>
        <Column :header="$t('visitor_tax.status')" field="status" :sortable="true"> </Column>
        <template #footer>
          <div class="d-flex align-items-center justify-content-end" style="column-gap: 40px">
            <span
              ><span class="poppins-medium">{{ $t('visitor_tax.total_not_reversed') }} :</span>
              {{ this.totalNotReversedDisplay }}</span
            >
            <span
              ><span class="poppins-medium">{{ $t('visitor_tax.total_reversed') }} :</span>
              {{ this.totalReversedDisplay }}</span
            >
            <span
              ><span class="poppins-medium">{{ $t('visitor_tax.total_not_selected') }} :</span>
              {{ this.totalDisplay }}</span
            >
            <span
              ><span class="poppins-medium">{{ $t('visitor_tax.total_selected') }} :</span>
              {{ this.totalSelectedDisplay }}</span
            >
          </div>
        </template>
      </DataTable>
    </template>
  </GaContentView>
</template>
