import {goBackTo} from '@utils';
import permissionsMixin from '@mixins/permissionsMixin';
import {PermissionMap} from '@mixins/const/permissionConst';

export default {
  mixins: [permissionsMixin],
  data() {
    return {
      tabs: [
        {
          name: this.$t('details'),
          id: 'employeeDetails',
          icon: 'home',
          active: true,
          hidden: !this.hasPermission(PermissionMap.RH.VIEW),
        },
        {
          name: this.$t('files'),
          id: 'employeeFiles',
          icon: 'file-text',
          hidden: !this.hasPermission(PermissionMap.RH.VIEW),
        },
        {
          name: this.$t('contracts'),
          id: 'employeeContracts',
          icon: 'file-text',
          hidden: !this.hasPermission(PermissionMap.RH.VIEW),
        },
        {
          name: this.$t('restrictions'),
          id: 'employeeRestrictions',
          icon: 'file-text',
          hidden: !this.hasPermission(PermissionMap.RH.VIEW),
        },
        {
          name: this.$t('absences'),
          id: 'employeeAbsences',
          icon: 'file-text',
          hidden: !this.hasPermission(PermissionMap.RH.VIEW),
        },
        {
          name: this.$t('unavailabilities'),
          id: 'employeeUnavailability',
          icon: 'file-text',
          hidden: !this.hasPermission(PermissionMap.RH.VIEW),
        },
        {
          name: this.$t('epi'),
          id: 'employeeEpi',
          icon: 'file-text',
          hidden: !this.hasPermission(PermissionMap.RH.VIEW),
        },
        {
          name: this.$t('activities'),
          id: 'employeeActivity',
          icon: 'bell',
          hidden: !this.hasPermission(PermissionMap.RH.VIEW),
        },
      ],
    };
  },
  methods: {
    goBack() {
      goBackTo(this.$router, 'employee');
    },
  },
};
