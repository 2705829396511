<script>
/* SERVICES */

export default {
  props: {
    scale: {type: Number, default: 0.75},
    color: {type: String, default: '#002d6d'},
    message: {type: String, default: ''},
  },
  data() {
    return {
      loading: undefined,
    };
  },
  mounted() {},
  methods: {
    hide() {
      this.loading = false;
    },
    load() {
      this.loading = true;
    },
    loadThen(promise, then) {
      this.load();
      promise.then(then).finally(this.hide);
    },
    loadThenCatch(promise, then, catcher) {
      this.load();
      promise.then(then).catch(catcher).finally(this.hide);
    },
    loadThenCatchFinally(promise, then, catcher, finalizer) {
      this.load();
      promise
        .then(then)
        .catch(catcher)
        .finally(() => {
          this.hide();
          finalizer();
        });
    },
  },
};
</script>

<template>
  <div v-if="loading === undefined" />
  <div v-else-if="loading" class="loader-div">
    <vue-loaders
      :color="color"
      :scale="scale"
      class="d-flex align-items-center justify-content-center"
      name="ball-clip-rotate" />
    <h5 class="d-flex align-items-center justify-content-center mt-3 color-secondary">
      {{ message }}
    </h5>
  </div>
  <slot v-else name="content" />
</template>
<style>
.loader-div {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>
