<script>
/* COMPOSANTS */
import {Icon} from '@iconify/vue/dist/iconify';
import AkCalendar from '@components/input/AkCalendar.vue';
import AkAccommodationAutocomplete from '@components/general/AkAccommodationAutocomplete.vue';
import AkOwnerAutocomplete from '@components/general/AkOwnerAutocomplete.vue';
import AkMandatAutocomplete from '@components/general/AkMandatAutocomplete.vue';
import AkReservationAutocomplete from '@components/general/AkReservationAutocomplete.vue';
import MultiSelect from 'primevue/multiselect';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import companyService from '@services/companyService';

export default {
  components: {
    AkReservationAutocomplete,
    AkMandatAutocomplete,
    AkOwnerAutocomplete,
    AkAccommodationAutocomplete,
    AkCalendar,
    Icon,
    MultiSelect,
  },
  mixins: [permissionsMixin, utilsMixin, sidebarMixin],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      companies: undefined,
    };
  },
  mounted() {
    this.initCompanies();
  },
  methods: {
    initCompanies() {
      if (!this.isGav()) {
        companyService.companies().then((data) => {
          this.companies = data;
        });
      }
    },
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.ownerAutocomplete) this.$refs.ownerAutocomplete.reset();
      if (this.$refs.accommodationAutocomplete) this.$refs.accommodationAutocomplete.reset();
      if (this.$refs.mandatAutocomplete) this.$refs.mandatAutocomplete.reset();
      if (this.$refs.reservationAutocomplete) this.$refs.reservationAutocomplete.reset();
      this.callback[1]();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <h2>Filtrer</h2>
  <form class="d-flex flex-column" style="row-gap: 16px" @submit.prevent="search">
    <MultiSelect
      v-if="!this.isGav()"
      v-model="currentValue.companyIds"
      :placeholder="$t('employee.company')"
      :options="this.companies"
      class="flex-1 form-control"
      option-label="name"
      option-value="id" />
    <AkOwnerAutocomplete
      ref="ownerAutocomplete"
      v-model="currentValue.ownerId"
      inline
      :placeholder="$t('owner.ownerDisplay')"
      class-name="flex-1 form-control" />
    <AkMandatAutocomplete
      ref="mandatAutocomplete"
      v-model="currentValue.mandatIds"
      :placeholder="$t('mandat.num')"
      inline
      class-name="flex-1 form-control"
      option-label="num"
      option-value="id" />
    <AkAccommodationAutocomplete
      ref="accommodationAutocomplete"
      v-model="currentValue.accommodationId"
      inline
      :placeholder="$t('activity.accommodation')"
      class-name="flex-1 form-control" />
    <AkReservationAutocomplete
      ref="reservationAutocomplete"
      v-model="currentValue.avantioLocalizer"
      :label="$t('reservation.number')"
      :placeholder="$t('reservation.number')"
      inline
      class-name="flex-1 form-control" />
    <AkCalendar
      v-model="currentValue.dateResiliation"
      inline
      :placeholder="$t('owner_cancel.date_cancel')"
      class-name="flex-1 form-control" />

    <div class="d-flex flex-row">
      <button class="btn btn-primary btn-search flex-1" type="submit">Rechercher</button>
      <Icon
        v-on:click="this.reset()"
        class="pointer"
        icon="basil:cross-outline"
        :style="{fontSize: '50px', width: '50px !important'}" />
    </div>
  </form>
</template>
