export default {
  data() {
    return {
      restrictionConst: [
        {label: this.$t('restriction.restriction1'), value: 'RESTRICTION_1'},
        {label: this.$t('restriction.restriction2'), value: 'RESTRICTION_2'},
        {label: this.$t('restriction.restriction3'), value: 'RESTRICTION_3'},
        {label: this.$t('restriction.restriction4'), value: 'RESTRICTION_4'},
        {label: this.$t('restriction.restriction5'), value: 'RESTRICTION_5'},
        {label: this.$t('restriction.restriction6'), value: 'RESTRICTION_6'},
        {label: this.$t('restriction.restriction7'), value: 'RESTRICTION_7'},
        {label: this.$t('restriction.restriction8'), value: 'RESTRICTION_8'},
        {label: this.$t('restriction.restriction9'), value: 'RESTRICTION_9'},
        {label: this.$t('restriction.restriction10'), value: 'RESTRICTION_10'},
        {label: this.$t('restriction.restriction11'), value: 'RESTRICTION_11'},
      ],
    };
  },
  methods: {
    displayForRestrictionConst(value) {
      let result = this.restrictionConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
