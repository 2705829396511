<script>
/* COMPOSANTS */
import PButton from 'primevue/button';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import ownerTypesConst from '@mixins/const/ownerTypesConst';
import messageMixin from '@mixins/messageMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import loaderMixin from '@mixins/loaderMixin';

/* SERVICES */
import litigeService from '@services/litigeService';
import litigeResolutionConst from '@mixins/const/litigeResolutionConst';
import litigeStatusConst from '@mixins/const/litigeStatusConst';

export default {
  components: {
    PButton,
  },
  mixins: [
    ownerTypesConst,
    randomRef,
    utilsMixin,
    messageMixin,
    permissionsMixin,
    sidebarMixin,
    loaderMixin,
    litigeResolutionConst,
    litigeStatusConst,
  ],
  metaInfo() {
    return {
      title: 'litige.list',
    };
  },
  data() {
    return {
      loading: true,
      list: [],
      listGagne: [],
      listPerdu: [],
      totalItems: 0,
      item: {},
      filter: {
        page: 1,
        limit: 10,
        sort: ['id:DESC'],
      },
    };
  },
  mounted() {
    this.search();
  },
  computed: {},
  methods: {
    search() {
      this.loading = true;
      litigeService
        .litiges(this.filter)
        .then((data) => {
          this.list = data.filter((l) => l.status !== 'RESOLU');
          this.listGagne = data.filter((l) => l.resolution === 'GAGNE');
          this.listPerdu = data.filter((l) => l.resolution === 'PERDU');
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    isStatusResolu(status) {
      return status === 'RESOLU';
    },
    submitForm() {
      this.onPage({page: 0, rows: 10});
    },
    openFilterPanel() {
      this.toggleFilter('GaLitigeListFilter', this.filter, this.search, this.resetFilter);
    },
    resetFilter() {
      this.filter = {
        page: 1,
        limit: 10,
        sort: ['id:DESC'],
      };
      this.toggleFilter('GaLitigeListFilter', this.filter, this.search, this.resetFilter);
    },
    rowClick(event) {
      let data = event.data;
      this.$router.push({
        name: 'litigeDetails',
        params: {litigeId: data.id},
      });
    },
    exportList() {
      this.showTotalLoaderWithAfter(this.$t('litige.list_creation_in_progress'));
      litigeService
        .exportList(this.filter)
        .then((data) => {})
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.hideLoader();
        });
    },

    setClassNameForStatus(status) {
      if (status === 'VALIDATED') return 'color-green';
      if (status === 'REFUSED') return 'color-red';

      return '';
    },
  },
};
</script>
<template>
  <GaView :ref="ref" :title="$t('litige.list')">
    <template v-slot:action>
      <div class="d-flex justify-content-end" style="column-gap: 16px">
        <PButton
          :badge="this.countFilter(this.filter)"
          badgeClass="p-badge-primary"
          class="btn btn-primary"
          label="Filtrer"
          @click="openFilterPanel()" />
        <!-- todo export de la liste? -->
        <!--<button class="btn btn-primary" @click="exportList()">
          {{ this.$t('payment.download') }}
        </button>-->
      </div>
    </template>

    <template #content>
      <DataTable
        :always-show-paginator="false"
        :loading="loading"
        :paginator="true"
        :rows="10"
        :rows-per-page-options="[10, 20, 50]"
        :value="list"
        class="pointer"
        current-page-report-template="Showing {first} to {last} of {totalRecords}"
        responsive-layout="scroll"
        :total-records="totalItems"
        sort-mode="multiple"
        removable-sort
        @row-click="rowClick($event)">
        <template #empty>
          {{ $t('litige.empty') }}
        </template>

        <Column v-if="this.isGas()" :header="$t('company.one')" :sortable="true" field="companyName" style="width: 15%">
          <template #body="slotProps">
            {{ slotProps.data.companyName }}
          </template>
        </Column>
        <Column :header="$t('accommodation.name')" :sortable="true" field="accommodationName">
          <template #body="slotProps">
            {{ slotProps.data.accommodationName }}
          </template>
        </Column>
        <Column :header="$t('accommodation.ownerName')" :sortable="true" field="ownerName">
          <template #body="slotProps">
            {{ slotProps.data.ownerName }}
          </template>
        </Column>
        <Column :header="$t('reservation.number')" :sortable="true" field="avantioLocalizer">
          <template #body="slotProps">
            {{ slotProps.data.avantioLocalizer }}
          </template>
        </Column>
        <Column :header="$t('litige.date')" :sortable="true" field="avantioLocalizer">
          <template #body="slotProps">
            {{ this.displayDate(slotProps.data.date) }}
          </template>
        </Column>
        <Column :header="$t('maintenance.status')" :sortable="true" sort-field="displayStatus">
          <template #body="slotProps">
            <span
              v-if="!isStatusResolu(slotProps.data.status)"
              :className="this.setClassNameForStatus(slotProps.data.displayStatus)">
              {{ displayForLitigeStatusConst(slotProps.data.displayStatus) }}
            </span>
            <span v-else-if="slotProps.data.resolution">
              {{ displayForLitigeResolutionConst(slotProps.data.resolution) }}
            </span>
          </template>
        </Column>
        <Column body-style="text-align: center; overflow: visible" header-style=" width: 180px; text-align: center">
          <template #body="slotProps">
            <div class="d-flex justify-content-end align-items-center">
              <router-link
                :to="{name: 'litigeDetails', params: {litigeId: slotProps.data.id}}"
                class="btn btn-xs btn-inverse-primary mr-1">
                <i class="fe fe-eye" />
              </router-link>
            </div>
          </template>
        </Column>
      </DataTable>

      <!-- GAGNE -->

      <DataTable
        :always-show-paginator="false"
        :loading="loading"
        :paginator="true"
        :rows="10"
        :rows-per-page-options="[10, 20, 50]"
        :value="listGagne"
        class="pointer"
        current-page-report-template="Showing {first} to {last} of {totalRecords}"
        responsive-layout="scroll"
        :total-records="totalItems"
        sort-mode="multiple"
        removable-sort
        @row-click="rowClick($event)">
        <template #empty>
          {{ $t('litige.empty') }}
        </template>

        <template #header>
          <div style="margin-top: 25px">
            <h5>{{ this.$t('litige.gagne') }}</h5>
          </div>
        </template>

        <Column v-if="this.isGas()" :header="$t('company.one')" :sortable="true" field="companyName" style="width: 15%">
          <template #body="slotProps">
            {{ slotProps.data.companyName }}
          </template>
        </Column>
        <Column :header="$t('accommodation.name')" :sortable="true" field="accommodationName">
          <template #body="slotProps">
            {{ slotProps.data.accommodationName }}
          </template>
        </Column>
        <Column :header="$t('accommodation.ownerName')" :sortable="true" field="ownerName">
          <template #body="slotProps">
            {{ slotProps.data.ownerName }}
          </template>
        </Column>
        <Column :header="$t('reservation.number')" :sortable="true" field="avantioLocalizer">
          <template #body="slotProps">
            {{ slotProps.data.avantioLocalizer }}
          </template>
        </Column>
        <Column :header="$t('litige.date')" :sortable="true" field="avantioLocalizer">
          <template #body="slotProps">
            {{ this.displayDate(slotProps.data.date) }}
          </template>
        </Column>
        <Column :header="$t('maintenance.status')" :sortable="true" sort-field="displayStatus">
          <template #body="slotProps">
            <span v-if="!isStatusResolu(slotProps.data.status)">
              {{ slotProps.data.displayStatus }}
            </span>
            <span v-else-if="slotProps.data.resolution">
              {{ displayForLitigeResolutionConst(slotProps.data.resolution) }}
            </span>
          </template>
        </Column>
        <Column body-style="text-align: center; overflow: visible" header-style=" width: 180px; text-align: center">
          <template #body="slotProps">
            <div class="d-flex justify-content-end align-items-center">
              <router-link
                :to="{name: 'litigeDetails', params: {litigeId: slotProps.data.id}}"
                class="btn btn-xs btn-inverse-primary mr-1">
                <i class="fe fe-eye" />
              </router-link>
            </div>
          </template>
        </Column>
      </DataTable>

      <!-- PERDU -->

      <DataTable
        :always-show-paginator="false"
        :loading="loading"
        :paginator="true"
        :rows="10"
        :rows-per-page-options="[10, 20, 50]"
        :value="listPerdu"
        class="pointer"
        current-page-report-template="Showing {first} to {last} of {totalRecords}"
        responsive-layout="scroll"
        :total-records="totalItems"
        sort-mode="multiple"
        removable-sort
        @row-click="rowClick($event)">
        <template #empty>
          {{ $t('litige.empty') }}
        </template>

        <template #header>
          <div style="margin-top: 25px">
            <h5>{{ this.$t('litige.perdu') }}</h5>
          </div>
        </template>

        <Column v-if="this.isGas()" :header="$t('company.one')" :sortable="true" field="companyName" style="width: 15%">
          <template #body="slotProps">
            {{ slotProps.data.companyName }}
          </template>
        </Column>
        <Column :header="$t('accommodation.name')" :sortable="true" field="accommodationName">
          <template #body="slotProps">
            {{ slotProps.data.accommodationName }}
          </template>
        </Column>
        <Column :header="$t('accommodation.ownerName')" :sortable="true" field="ownerName">
          <template #body="slotProps">
            {{ slotProps.data.ownerName }}
          </template>
        </Column>
        <Column :header="$t('reservation.number')" :sortable="true" field="avantioLocalizer">
          <template #body="slotProps">
            {{ slotProps.data.avantioLocalizer }}
          </template>
        </Column>
        <Column :header="$t('litige.date')" :sortable="true" field="avantioLocalizer">
          <template #body="slotProps">
            {{ this.displayDate(slotProps.data.date) }}
          </template>
        </Column>
        <Column :header="$t('maintenance.status')" :sortable="true" sort-field="displayStatus">
          <template #body="slotProps">
            <span v-if="!isStatusResolu(slotProps.data.status)">
              {{ slotProps.data.displayStatus }}
            </span>
            <span v-else-if="slotProps.data.resolution">
              {{ displayForLitigeResolutionConst(slotProps.data.resolution) }}
            </span>
          </template>
        </Column>
        <Column body-style="text-align: center; overflow: visible" header-style=" width: 180px; text-align: center">
          <template #body="slotProps">
            <div class="d-flex justify-content-end align-items-center">
              <router-link
                :to="{name: 'litigeDetails', params: {litigeId: slotProps.data.id}}"
                class="btn btn-xs btn-inverse-primary mr-1">
                <i class="fe fe-eye" />
              </router-link>
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
    <template #extra> </template>
  </GaView>
</template>
