<script>
import {Icon} from '@iconify/vue/dist/iconify';

/* COMPOSANTS */
import MultiSelect from 'primevue/multiselect';
import AkCalendar from '@components/input/AkCalendar.vue';

/* MIXINS */
import sidebarMixin from '@mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';
import permissionsMixin from '@mixins/permissionsMixin';

/* SERVICES */
import upsellStatusConst from '@mixins/const/upsellStatusConst';
import AkReservationAutocomplete from '@components/general/AkReservationAutocomplete.vue';

export default {
  components: {
    AkReservationAutocomplete,
    AkCalendar,
    MultiSelect,
    Icon,
  },
  mixins: [utilsMixin, sidebarMixin, permissionsMixin, upsellStatusConst],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      companies: undefined,
      statuses: [],
    };
  },
  mounted() {
    this.initStatuses();
  },
  methods: {
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.reservationAutocomplete) this.$refs.reservationAutocomplete.reset();
      this.callback[1]();
    },
    initStatuses() {
      this.statuses = this.upsellStatusConstForFilter();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <h2>Filtrer</h2>
  <form class="d-flex flex-column" style="row-gap: 16px" @submit.prevent="search">
    <AkReservationAutocomplete
      ref="reservationAutocomplete"
      v-if="!this.isOwner()"
      v-model="currentValue.reservationLocalizer"
      :inline="true"
      :placeholder="$t('reservation.number')"
      class-name="flex-1 form-control" />

    <AkCalendar
      v-model="currentValue.dateBuy"
      className="flex-1 form-control"
      :inline="true"
      :placeholder="$t('upsell.buyDate')"
      date-format="dd/mm/yy"
      selection-mode="single" />

    <MultiSelect
      v-model="currentValue.statuses"
      :placeholder="$t('upsell.status')"
      :options="statuses"
      option-label="label"
      option-value="value"
      class="flex-1 form-control"
      inline />

    <div class="d-flex flex-row">
      <button class="btn btn-primary btn-search flex-1" type="submit">
        {{ this.$t('upsell.filter') }}
      </button>
      <Icon
        v-on:click="this.reset()"
        class="pointer"
        icon="basil:cross-outline"
        :style="{fontSize: '50px', width: '50px !important'}" />
    </div>
  </form>
</template>
