<script>
import {useMessageStore} from '@store/messagesStore';
import {mapState} from 'pinia/dist/pinia';

/* COMPOSANTS */
import AkLoader from '@components/general/AkLoader';
import AkDialogConfirm from '@components/general/AkDialogConfirm';
import MissionOrderDetails from './tabs/MissionOrderDetails';
import MissionOrderCDC from './tabs/MissionOrderCDC';
import MissionReservationDetails from './tabs/MissionReservationDetails';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import loaderMixin from '@mixins/loaderMixin';
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';
import backMixin from '@mixins/backMixin';

/* SERVICES */
import missionOrderService from '@services/missionOrderService';

export default {
  components: {
    MissionOrderDetails,
    MissionOrderCDC,
    MissionReservationDetails,
    AkLoader,
    AkDialogConfirm,
  },
  mixins: [randomRef, loaderMixin, utilsMixin, messageMixin, backMixin],
  metaInfo() {
    return {
      title: this.title,
    };
  },
  data() {
    return {
      mission: null,
      activeTab: 0,
      prestationEdited: false,
    };
  },
  mounted() {
    this.refresh();
  },
  computed: {
    title() {
      return '#' + this.mission?.id;
    },

    ...mapState(useMessageStore, ['list']),
    statusPrestation() {
      if (!this.mission) return null;
      return this.mission.statusPrestation;
    },
    statusParticipants() {
      if (!this.mission) return null;
      return this.mission.statusParticipants;
    },

    // Statuts d'annulation
    isStatusPrestationCanceled() {
      return this.statusPrestation === 'CANCELED';
    },
    isStatusParticipantsCanceled() {
      return this.statusParticipants === 'CANCELED';
    },
    isStatusParticipantsPartiallyCanceled() {
      return this.statusParticipants === 'PARTIALLY_CANCELED';
    },
    // Statuts de proposition / planification
    isStatusParticipantsProposed() {
      return this.statusParticipants === 'PROPOSED';
    },
    isStatusParticipantsToPlan() {
      return this.statusParticipants === 'TO_PLAN';
    },
    isStatusParticipantsTodo() {
      return this.statusParticipants === 'TODO';
    },
    isStatusParticipantsDoing() {
      return this.statusParticipants === 'DOING';
    },
    isStatusParticipantsCompleted() {
      return this.statusParticipants === 'COMPLETED';
    },
    isAssignedStaffPartner() {
      return this.mission.assignedStaff === 'PARTNER';
    },

    // HAS

    hasReservation() {
      return this.mission && this.mission.reservation;
    },

    // CAN

    canModify() {
      if (this.isStatusPrestationCanceled || this.isAssignedStaffPartner) return false;
      // Peut modifier la mission si le statut est "Proposée" ou "A planifier" ou "Planifiée"
      return (
        !this.prestationEdited &&
        (this.isStatusParticipantsProposed || this.isStatusParticipantsToPlan || this.isStatusParticipantsTodo)
      );
    },
    canCancel() {
      return (
        !this.isStatusPrestationCanceled &&
        !this.isStatusParticipantsDoing &&
        !this.isStatusParticipantsCompleted &&
        !this.isAssignedStaffPartner
      );
    },
    canRepropose() {
      if (this.isStatusPrestationCanceled || this.isAssignedStaffPartner) return false;
      // Peut reproposer la mission si les prestataires ont tous annulé ou partiellement annulé
      return this.isStatusParticipantsCanceled || this.isStatusParticipantsPartiallyCanceled;
    },
  },
  methods: {
    resetMessage() {
      useMessageStore().$reset();
    },
    refresh() {
      this.$refs.loader.load();
      missionOrderService
        .get(this.$route.params.missionOrderId)
        .then((data) => {
          this.mission = data;
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.$refs.loader.hide();
        });
    },
    repropose() {
      this.$refs.loader.load();
      missionOrderService
        .repropose(this.$route.params.missionOrderId)
        .then(() => {
          this.success(this.$t('mission_order_details.repropose_success'));
          this.refresh();
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.$refs.loader.hide();
        });
    },
    startEditing() {
      this.prestationEdited = true;
    },
    update() {
      this.$refs.loader.load();
      missionOrderService
        .update(this.mission.id, this.mission)
        .then(() => {
          this.prestationEdited = false;
          this.success(this.$t('mission_order_details.edit_success'));
          this.refresh();
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.$refs.loader.hide();
        });
    },
    cancel() {
      this.$refs.dialogCancel.show();
    },
    handleCancel() {
      this.$refs.loader.load();
      missionOrderService
        .cancel(this.mission.id)
        .then((data) => {
          this.success(this.$t('mission_order_details.cancel_success'));
          this.refresh();
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.$refs.loader.hide();
        });
    },
  },
};
</script>

<template>
  <AkLoader ref="loader" :message="$t('currently_loading')" :scale="1.5" color="black">
    <template #content>
      <!-- TODO: utiliser une GaView -->
      <Message
        v-for="msg of this.list"
        :key="msg.content"
        :closable="msg.closable"
        :severity="msg.severity"
        @close="resetMessage">
        {{ msg.content }}
      </Message>

      <div class="h-100 d-flex flex-column mission-order-page">
        <GaTitleMenu :with-bullet="false" class="justify-content-between">
          <div class="title-container">
            <div style="cursor: pointer" @click="goBack()">
              <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7 1L1 7L7 13"
                  stroke="#2F384C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </div>
            <GaItemTitle :active="true" :title="this.title" />
          </div>
          <div class="title-container">
            <button class="btn btn-danger" v-if="this.canCancel" @click="this.cancel()">Annuler la mission</button>
            <button class="btn btn-secondary" v-if="this.canModify" @click="this.startEditing()">
              Modifier la mission
            </button>
            <button class="btn btn-secondary" v-if="prestationEdited" @click="this.update()">
              Sauvegarder les changements
            </button>
            <button class="btn btn-secondary" v-if="this.canRepropose" @click="this.repropose()">
              Remettre en ligne
            </button>
          </div>
        </GaTitleMenu>
        <div class="d-flex align-items-center top-container">
          <div class="tab-container">
            <div class="tab" :class="activeTab === 0 ? 'active' : ''" @click="activeTab = 0">Ordre de mission</div>
            <div class="tab" :class="activeTab === 1 ? 'active' : ''" @click="activeTab = 1">
              Cahier des charges du logement
            </div>
            <div v-if="hasReservation" class="tab" :class="activeTab === 2 ? 'active' : ''" @click="activeTab = 2">
              Informations réservation
            </div>
          </div>
        </div>
        <div class="content-list">
          <MissionOrderDetails v-if="activeTab === 0" :modelValue="mission" :editable="prestationEdited" />
          <template v-else-if="activeTab === 1">
            <MissionOrderCDC :cdcId="mission.cdcId" :type="mission.type" />
          </template>
          <MissionReservationDetails v-else-if="hasReservation" :modelValue="mission.reservation" />
        </div>
      </div>
    </template>
  </AkLoader>
  <AkDialogConfirm
    ref="dialogCancel"
    title="Annulation de la mission"
    message="Confirmez l'annulation de la mission"
    @validate="handleCancel()" />
</template>

<style lang="scss" scoped>
.mission-order-page {
  .title-container {
    display: flex;
    flex-direction: row;
    gap: 26px;
    align-items: center;
  }

  .top-container {
    padding: 20px 24px 0 28px;
    display: flex;
    justify-content: space-between;
  }

  .tab-container {
    display: flex;
    flex-direction: row;
    height: 32px;
    gap: 24px;
  }

  .tab {
    width: fit-content;
    cursor: pointer;
    color: #667085;
    height: 32px;
    box-sizing: border-box;

    font-family: 'Nunito';
    font-size: 14px;
    font-weight: 600;
  }

  .tab.active {
    color: #092858;
    border-bottom: solid 2px #092858;
  }
}
</style>
