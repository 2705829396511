export default {
  data() {
    return {
      amendmentReasonConst: [
        {label: this.$t('contract.amendmentReason1'), value: 'AMENDMENT_REASON_1'},
        {label: this.$t('contract.amendmentReason2'), value: 'AMENDMENT_REASON_2'},
        {label: this.$t('contract.amendmentReason3'), value: 'AMENDMENT_REASON_3'},
        {label: this.$t('contract.amendmentReason4'), value: 'AMENDMENT_REASON_4'},
        {label: this.$t('contract.amendmentReason5'), value: 'AMENDMENT_REASON_5'},
        {label: this.$t('contract.amendmentReason6'), value: 'AMENDMENT_REASON_6'},
        {label: this.$t('contract.amendmentReason7'), value: 'AMENDMENT_REASON_7'},
      ],
    };
  },
  methods: {
    displayForAmendmentReasonConst(value) {
      let result = this.amendmentReasonConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
