<script>
import AkLabel from '@components/general/AkLabel';
import Editor from 'primevue/editor';

export default {
  components: {AkLabel, Editor},
  inject: {pSubmitted: {default: null}, pDisabled: {default: null}},
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      required: true,
    },
    className: {
      type: String,
      required: false,
      default: 'col-md-4 col-6',
    },
    editorStyle: {
      type: String,
      required: false,
      default: 'height: 250px',
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    hasValidator() {
      return this.validator !== undefined;
    },
    isInvalid() {
      return this.validator !== undefined && this.validator.$invalid;
    },
    isRequired() {
      return this.validator !== undefined && this.validator.required !== undefined;
    },
    isValidatorRequired() {
      return (
        this.validator !== undefined &&
        this.validator.required !== undefined &&
        this.validator.required.$invalid &&
        this.alreadySubmitted
      );
    },
    isValidatorMinMaxLength() {
      return (
        this.validator !== undefined &&
        (this.validator.minLength !== undefined || this.validator.maxLength !== undefined) &&
        (this.validator.minLength.$invalid || this.validator.maxLength.$invalid) &&
        this.alreadySubmitted
      );
    },
  },
  methods: {},
};
</script>

<template>
  <div :class="className" class="form-group">
    <AkLabel :required="isRequired">
      {{ label }}
    </AkLabel>
    <Editor
      v-if="!isDisabled"
      v-model="currentValue"
      :class="{'p-invalid': isInvalid && alreadySubmitted}"
      :editorStyle="editorStyle">
      <template #toolbar>
        <span class="ql-formats">
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <button class="ql-underline"></button>
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
        </span>
      </template>
    </Editor>
    <div class="input-html-div" v-if="isDisabled" v-html="currentValue"></div>
    <small v-if="isValidatorRequired && alreadySubmitted" class="p-error">{{ $t('field_required', [label]) }}</small>

    <small v-if="isValidatorMinMaxLength && alreadySubmitted" class="p-error">{{
      $t('min_max_length', [$t('company.postalCode'), validator.minLength.$params.min, validator.maxLength.$params.max])
    }}</small>
  </div>
</template>
