<script>
import {useVuelidate} from '@vuelidate/core';
import {email, required, requiredIf} from '@vuelidate/validators';

/* COMPOSANTS */
import AkDialog from '@components/general/AkDialog';
import AkDropdown from '@components/input/AkDropdown';
import AkInputGroup from '@components/input/AkInputGroup';
import AkInputText from '@components/input/AkInputText';
import AkMultiSelect from '@components/input/AkMultiSelect';
import AkDropdownGroup from '@components/input/AkDropdownGroup.vue';
import InputSwitch from 'primevue/inputswitch';
import PButton from 'primevue/button';

/* MIXINS */
import civilitiesConst from '@mixins/const/civilitiesConst';
import permissionsMixin from '@mixins/permissionsMixin';
import {PermissionMap} from '@mixins/const/permissionConst';
import randomRef from '@mixins/randomRef';
import roleKeysConst from '@mixins/const/roleKeysConst';
import roleTypesConst from '@mixins/const/roleTypesConst';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';

/* SERVICES */
import employeeService from '@services/employeeService';
import companyService from '@services/companyService';
import poleService from '@services/poleService';

export default {
  components: {
    AkDropdownGroup,
    AkDialog,
    AkDropdown,
    AkInputGroup,
    AkInputText,
    AkMultiSelect,
    InputSwitch,
    PButton,
  },
  mixins: [civilitiesConst, permissionsMixin, randomRef, roleKeysConst, roleTypesConst, utilsMixin, sidebarMixin],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'employee.list',
    };
  },
  data() {
    return {
      loading: true,
      list: [],
      totalItems: 0,
      item: {},
      employee: {},
      companies: [],
      poles: [],
      filter: {
        page: 1,
        limit: 10,
        sort: ['id:DESC'],
        firstName: '',
        lastName: '',
      },
    };
  },
  validations() {
    return {
      employee: {
        civility: {required},
        firstName: {required},
        lastName: {required},
        email: {required, email},
        roleKey: {required},
        roleType: {required},
        companyId: {required: requiredIf(this.requireCompany)},
      },
    };
  },
  computed: {
    hasManagePermission() {
      return this.hasPermission(PermissionMap.RH.MANAGE);
    },
    isRoleTypeGav() {
      return this.employee.roleType === 'GAV';
    },
    isRoleTypeGas() {
      return this.employee.roleType === 'GAS';
    },
    isRoleTypeDefined() {
      return this.isRoleTypeGav || this.isRoleTypeGas;
    },
    buildRoleKeys() {
      if (this.isRoleTypeGav) return this.roleKeysForGav;
      if (this.isRoleTypeGas) return this.roleKeysForGas;
      return null;
    },
    disableRoleType() {
      return !this.isAdmin();
    },
    displayCompany() {
      return this.isAdmin() && this.isRoleTypeGav;
    },
    displayRoleKey() {
      return this.isRoleTypeDefined;
    },
    requireCompany() {
      return this.isAdmin() && this.isRoleTypeGav;
    },
  },
  mounted() {
    this.initEmployee();
    this.getRef().showLoader(this.$refs.form);

    let p1 = employeeService.employeesPagination(this.filter).then((data) => {
      this.list = data.data;
      this.totalItems = data.totalItems;
      this.loading = false;
    });

    let p2 = companyService.companies().then((data) => {
      this.companies = data;
    });

    let p3 = poleService.poles().then((data) => {
      this.poles = data;
    });

    Promise.all([p1, p2, p3]).then(this.getRef().hideLoader());
  },
  methods: {
    initEmployee() {
      this.employee = {};
      if (this.isGav()) this.employee.roleType = 'GAV';
      else if (this.isGas()) this.employee.roleType = 'GAS';
    },
    onPage(event) {
      this.filter.page = event.page + 1;
      this.filter.limit = event.rows;
      this.search();
    },
    search() {
      this.loading = true;
      employeeService.employeesPagination(this.filter).then((data) => {
        this.list = data.data;
        this.totalItems = data.totalItems;
        this.loading = false;
      });
    },
    onSort(event) {
      this.filter.sort = [];
      for (let sort of event.multiSortMeta) {
        this.filter.sort.push('' + sort.field + ':' + (sort.order === -1 ? 'DESC' : 'ASC'));
      }
      if (this.filter.sort.length === 0) {
        this.filter.sort.push('id:DESC');
      }
      this.search();
    },
    submitForm() {
      this.onPage({page: 0, rows: 10});
    },
    create() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$error) return;
      this.getRef().showLoader(this.$refs.form);
      employeeService
        .create(this.employee)
        .then(() => {
          this.getRef().success(this.$t('employee.added'));
          this.initEmployee();
          this.submitted = false;
          this.search();
          this.$refs.dialogCreate.hide();
        })
        .catch((e) => {
          this.submitted = false;
          this.$refs.dialogCreate.error(this.$t('error.' + e.response.data.error));
        })
        .finally(() => {
          this.getRef().hideLoader();
        });
    },
    openCreateDialog() {
      this.item = {};
      this.$refs.dialogCreate.show();
    },
    changeUserEnabled(data) {
      if (data.userEnabled) this.enableUser(data);
      else this.disableUser(data);
    },
    disableUser(data) {
      employeeService.disable(data).then((data) => {
        this.search();
      });
    },
    enableUser(data) {
      employeeService.enable(data).then((data) => {
        this.search();
      });
    },
    canDisable(data) {
      if (this.isMe(data.userId)) return false;
      return this.isGav() || this.isAdmin();
    },
    classForEnabled(data) {
      return data.userEnabled ? 'ti ti-check text-primary' : 'ti ti-close';
    },
    rowClick(event) {
      if (this.isGav()) {
        let data = event.data;
        this.$router.push({
          name: 'employeePage',
          params: {employeeId: data.id},
        });
      }
    },
    resetFilter() {
      this.filter = {
        page: 1,
        limit: 10,
        sort: ['id:DESC'],
        firstName: '',
        lastName: '',
      };
      this.toggleFilter('GaEmployeeListFilter', this.filter, this.submitForm, this.resetFilter);
    },
    openFilterPanel() {
      this.toggleFilter('GaEmployeeListFilter', this.filter, this.submitForm, this.resetFilter);
    },
  },
};
</script>
<template>
  <GaView :ref="ref" :title="$t('employee.list')">
    <template #action>
      <div class="d-flex justify-content-end" style="gap: 16px">
        <PButton
          :badge="this.countFilter(this.filter)"
          badgeClass="p-badge-primary"
          class="btn btn-primary"
          label="Filtrer"
          @click="openFilterPanel()" />
        <button v-if="hasManagePermission" class="btn btn-primary" @click="openCreateDialog()">
          <i class="gst-plus pr-1" />{{ $t('add') }}
        </button>
      </div>
    </template>
    <template #content>
      <DataTable
        :always-show-paginator="false"
        :class="{pointer: this.isGav()}"
        :loading="loading"
        :paginator="true"
        :rows="10"
        :rows-per-page-options="[10, 20, 50]"
        :value="list"
        class="table"
        current-page-report-template="{first}-{last}/{totalRecords}"
        removable-sort
        responsive-layout="scroll"
        striped-rows
        @row-click="rowClick($event)"
        :total-records="totalItems"
        lazy
        sort-mode="multiple"
        @page="onPage($event)"
        @sort="onSort($event)">
        <template #empty>
          {{ $t('employee.empty') }}
        </template>

        <Column :header="$t('employee.lastName')" :sortable="true" field="last_name">
          <template #body="slotProps">
            {{ slotProps.data.lastName }}
          </template>
        </Column>

        <Column :header="$t('employee.firstName')" :sortable="true" field="first_name">
          <template #body="slotProps">
            {{ slotProps.data.firstName }}
          </template>
        </Column>

        <Column :header="$t('employee.email')" :sortable="true" field="email">
          <template #body="slotProps">
            {{ slotProps.data.email }}
          </template>
        </Column>

        <Column :header="$t('employee.roleKey')">
          <template #body="slotProps">
            {{ displayForRoleKeysConst(slotProps.data.roleKey) }}
          </template>
        </Column>

        <Column :header="$t('user.enabled')" :sortable="true" field="user_enabled">
          <template #body="slotProps">
            <InputSwitch
              v-if="this.canDisable(slotProps.data)"
              v-model="slotProps.data.userEnabled"
              @change="changeUserEnabled(slotProps.data)" />
            <i v-if="!this.canDisable(slotProps.data)" :class="classForEnabled(slotProps.data)" />
          </template>
        </Column>

        <Column body-style="text-align: center; overflow: visible" header-style=" width: 150px; text-align: center">
          <template #body="slotProps">
            <div class="d-flex justify-content-end align-items-center">
              <router-link
                v-if="this.hasPermission('RH_VIEW')"
                :to="{name: 'employeePage', params: {employeeId: slotProps.data.id}}"
                class="btn btn-xs btn-inverse-primary mr-1">
                <i class="fe fe-eye" />
              </router-link>
              <router-link
                v-if="this.hasPermission('RH_MANAGE')"
                :to="{name: 'employeeUpdate', params: {employeeId: slotProps.data.id}}"
                class="btn btn-xs btn-inverse-primary">
                <i class="fe fe-edit" />
              </router-link>
            </div>
          </template>
        </Column>
      </DataTable>
    </template>

    <template #extra>
      <AkDialog
        ref="dialogCreate"
        :title="$t('employee.add')"
        :validate-label="$t('add')"
        width="800px"
        @cancel="initEmployee"
        @validate="create()">
        <div class="form-row">
          <AkDropdown
            v-model="employee.roleType"
            :disabled="disableRoleType"
            :label="$t('employee.roleType')"
            :options="roleTypesForEmployees"
            :validator="v$.employee.roleType"
            class-name="col-md-6" />

          <AkDropdown
            v-if="displayRoleKey && isRoleTypeGav"
            v-model="employee.roleKey"
            :label="$t('employee.roleKey')"
            :options="buildRoleKeys"
            :validator="v$.employee.roleKey"
            class-name="col-md-6" />

          <AkDropdownGroup
            v-if="displayRoleKey && isRoleTypeGas"
            v-model="employee.roleKey"
            :label="$t('employee.roleKey')"
            :options="buildRoleKeys"
            :validator="v$.employee.roleKey"
            class-name="col-md-6" />

          <AkDropdown
            v-if="displayCompany"
            v-model="employee.companyId"
            :label="$t('employee.company')"
            :options="companies"
            :validator="v$.employee.companyId"
            class-name="col-md-6"
            option-label="name"
            option-value="id" />
          <AkDropdown
            v-model="employee.civility"
            :label="$t('employee.civility')"
            :options="civilitiesConst"
            :validator="v$.employee.civility"
            class-name="col-md-6" />
          <AkMultiSelect
            v-if="isRoleTypeGas"
            v-model="employee.poleIds"
            :label="$t('employee.poles')"
            :options="poles"
            class-name="col-md-6"
            option-label="name"
            option-value="id" />
        </div>
        <div class="form-row">
          <AkInputText
            v-model="employee.firstName"
            :label="$t('employee.firstName')"
            :validator="v$.employee.firstName"
            class-name="col-md-6" />
          <AkInputText
            v-model="employee.lastName"
            :label="$t('employee.lastName')"
            :validator="v$.employee.lastName"
            class-name="col-md-6" />
        </div>
        <div class="form-row">
          <AkInputGroup
            v-model="employee.email"
            :label="$t('employee.email')"
            :validator="v$.employee.email"
            class-name="col-md-6"
            icon="fa fa-envelope" />
        </div>
      </AkDialog>
    </template>
  </GaView>
</template>

<style lang="scss" module>
.title {
  text-align: center;
}
</style>
