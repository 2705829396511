<script>
import {required, requiredIf} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import bigDecimal from 'js-big-decimal';

/* COMPOSANTS */
import AkAccountAutocomplete from '@components/general/AkAccountAutocomplete';
import AkCalendar from '@components/input/AkCalendar';
import AkCheckboxButton from '@components/input/AkCheckboxButton';
import AkDialog from '@components/general/AkDialog';
import AkDropdown from '@components/input/AkDropdown';
import AkInputMoney from '@components/input/AkInputMoney';
import AkInputNumber from '@components/input/AkInputNumber';
import AkInputText from '@components/input/AkInputText';
import AkInputTextArea from '@components/input/AkInputTextArea';
import GaContentView from '@components/layout/GaContentView';
import PButton from 'primevue/button';

/* MIXINS */
import accountOperationsConst from '@mixins/const/accountOperationsConst';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import randomRef from '@mixins/randomRef';
import sidebarMixin from '@mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';
import {PermissionMap} from '@mixins/const/permissionConst';
import labelODMixin from '@mixins/labelODMixin';

/* SERVICES */
import comptaAccountService from '@services/comptaAccountService';
import comptaOperationService from '@services/comptaOperationService';
import mandatService from '@services/mandatService';
import itemLabelService from '@services/itemLabelService';
import companyService from '@services/companyService';

export default {
  components: {
    PButton,
    AkAccountAutocomplete,
    AkCalendar,
    AkCheckboxButton,
    AkDialog,
    AkDropdown,
    AkInputMoney,
    AkInputNumber,
    AkInputText,
    AkInputTextArea,
    GaContentView,
  },
  mixins: [
    accountOperationsConst,
    loaderMixin,
    messageMixin,
    permissionsMixin,
    randomRef,
    sidebarMixin,
    utilsMixin,
    labelODMixin,
  ],
  data() {
    return {
      loading: false,
      errors: [],
      operationTypes: [],
      operationTypesForSearch: [],
      operations: [],
      accommodations: [],
      owners: [],
      mandat: {},
      accounts: [],
      operation: {},
      listAchatHote: [],
      companies: [],
      filter: {
        page: 1,
        limit: 10,
        sort: ['id:DESC'],
      },
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  validations() {
    return {
      operation: {
        type: {required},
        date: {required},
        label: {required},
        accountNum: {required},
        recurrence: {required},
        minutes: {required},
        amount: {required: requiredIf(this.amountNeeded)},
        amountTotal: {required: requiredIf(this.isRemboursement)},
        amountGas: {required: requiredIf(this.isRemboursement)},
        amountGav: {required: requiredIf(this.isRemboursement)},
        amountHote: {required: requiredIf(this.isTypeRemboursementVoyageur)},
        amountCommission: {required: requiredIf(this.isTypeFraisAnnulationHote)},
        amountAnnulation: {required: requiredIf(this.isTypeFraisAnnulationHote)},
      },
    };
  },
  mounted() {
    this.operationTypes = this.accountOperationsConstForConsultation();
    this.operationTypesForSearch = this.accountOperationsConstForConsultationSearch();
    this.operationTypes.sort(function compare(a, b) {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });
    this.operationTypesForSearch.sort(function compare(a, b) {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });

    this.loading = true;

    let p1 = itemLabelService.itemLabels({type: 'ACHAT_HOTE', enabled: true});
    p1.then((data) => {
      this.listAchatHote = data;
      for (let achatHote of this.listAchatHote) {
        this.labelODachatHote.push(achatHote.label);
      }
    });

    let p2 = companyService.companies();
    p2.then((data) => {
      this.companies = data;
    }).catch((e) => {
      this.addError(this.$t('unknow_error'));
    });
    Promise.all([p1, p2]).then(() => {
      this.loading = false;
    });
  },
  computed: {
    isOfferOnline() {
      return this.mandat && this.mandat.offer === 'ONLINE';
    },
    isTypeNotForOnline() {
      return (
        this.isTypeDeepCleaning ||
        this.isTypeOuvertureHivernage ||
        this.isTypeFermetureHivernage ||
        this.isTypeFraisDeBlanchisserie ||
        this.isTypeFraisDeMaintenance ||
        this.isTypeFraisDeMenageHote
      );
    },
    isTypeAchatHote() {
      return this.operation.type === 'ACHAT_HOTE';
    },
    isTypeNuisanceSonore() {
      return this.operation.type === 'NUISANCE_SONORE';
    },
    isTypeDeepCleaning() {
      return this.operation.type === 'DEEP_CLEANING';
    },
    isTypeFraisAnnulationHote() {
      return this.operation.type === 'FRAIS_ANNULATION_HOTE';
    },
    isTypeForfaitVerificationMenageHote() {
      return this.operation.type === 'FORFAIT_VERIFICATION_MENAGE_HOTE';
    },
    isTypeFraisDeMaintenance() {
      return this.operation.type === 'FRAIS_DE_MAINTENANCE';
    },
    isTypeFraisDeMenageHote() {
      return this.operation.type === 'FRAIS_DE_MENAGE_HOTE';
    },
    isTypeRemboursementHote() {
      return this.operation.type === 'REMBOURSEMENT_HOTE';
    },
    isTypeRemboursementVoyageur() {
      return this.operation.type === 'REMBOURSEMENT_VOYAGEUR';
    },
    isTypeFraisDeMaintenanceOld() {
      return this.operation.type === 'FRAIS_DE_MAINTENANCE_OLD';
    },
    isTypeFraisDeBlanchisserie() {
      return this.operation.type === 'FRAIS_DE_BLANCHISSERIE';
    },
    isTypeOuvertureHivernage() {
      return this.operation.type === 'OUVERTURE_HIVERNAGE';
    },
    isTypeFermetureHivernage() {
      return this.operation.type === 'FERMETURE_HIVERNAGE';
    },
    typeAccount() {
      if (this.accountFneeded) return 'MANDAT';
      if (this.accountCneeded) return 'RESERVATION';
      return null;
    },
    isRecurrent() {
      return this.isTypeAchatHote || this.isTypeNuisanceSonore || this.isTypeFraisDeMaintenance;
    },
    isRemboursement() {
      return this.isTypeRemboursementHote || this.isTypeRemboursementVoyageur;
    },

    isAccountNumC() {
      return this.operation.accountNum && this.operation.accountNum[0] === 'C';
    },
    isAccountNumF() {
      return this.operation.accountNum && this.operation.accountNum[0] === 'F';
    },

    accountFneeded() {
      return (
        this.isTypeFraisAnnulationHote ||
        this.isTypeFraisDeMaintenance ||
        this.isTypeFraisDeMenageHote ||
        this.isTypeRemboursementHote ||
        this.isTypeAchatHote ||
        this.isTypeDeepCleaning ||
        this.isTypeForfaitVerificationMenageHote ||
        this.isTypeNuisanceSonore ||
        this.isTypeFraisDeBlanchisserie ||
        this.isTypeOuvertureHivernage ||
        this.isTypeFermetureHivernage
      );
    },
    accountCneeded() {
      return this.isTypeRemboursementVoyageur;
    },

    amountNeeded() {
      return (
        !this.isTypeFraisAnnulationHote &&
        !this.isTypeFraisDeMaintenance &&
        !this.isTypeRemboursementHote &&
        !this.isTypeRemboursementVoyageur
      );
    },

    hasAmountTotal() {
      return this.operation.amountTotal !== undefined && this.operation.amountTotal !== null;
    },
    hasAmountGas() {
      return this.operation.amountGas !== undefined && this.operation.amountGas !== null;
    },
    hasAmountGav() {
      return this.operation.amountGav !== undefined && this.operation.amountGav !== null;
    },
    hasAmountHote() {
      return this.operation.amountHote !== undefined && this.operation.amountHote !== null;
    },
    amountGasGavHote() {
      let total = new bigDecimal(0);
      if (this.hasAmountGas) total = total.add(new bigDecimal(this.operation.amountGas));
      if (this.hasAmountGav) total = total.add(new bigDecimal(this.operation.amountGav));
      if (this.hasAmountHote) total = total.add(new bigDecimal(this.operation.amountHote));
      return total;
    },

    hasManagePermission() {
      return this.hasPermission(PermissionMap.OPERATION.MANAGE);
    },
    recurrenceConst() {
      return [
        {label: this.$t('payment.none'), value: 'none'},
        {label: this.$t('payment.monthly'), value: 'monthly'},
      ];
    },
    numberOfResults() {
      return this.operations.length;
    },
    canExport() {
      return this.numberOfResults > 0;
    },

    buildLabelChoice() {
      if (this.isTypeAchatHote) return this.labelODachatHote;
      if (this.isTypeDeepCleaning) return this.labelODdeepCleaning;
      if (this.isTypeForfaitVerificationMenageHote) return this.labelODforfaitVerificationMenageHote;
      if (this.isTypeFraisAnnulationHote) return this.labelODfraisAnnulationHote;
      if (this.isTypeFraisDeMaintenance) return this.labelODfraisDeMaintenance;
      if (this.isTypeFraisDeMenageHote) return this.labelODfraisDeMenageHote;
      if (this.isTypeNuisanceSonore) return this.labelODnuisanceSonore;
      if (this.isTypeRemboursementHote) return this.labelODremboursementHote;
      if (this.isTypeRemboursementVoyageur) return this.labelODremboursementVoyageur;
      if (this.isTypeFraisDeMaintenance) return this.labelODfraisDeMaintenance;
      if (this.isTypeOuvertureHivernage) return this.labelOdOuvertureHivernage;
      if (this.isTypeFermetureHivernage) return this.labelOdFermetureHivernage;
      return null;
    },
    singleLabelChoice() {
      let choice = this.buildLabelChoice;
      if (!choice || choice.length != 1) return null;
      return choice[0];
    },
  },
  methods: {
    getMandat(mandatNum) {
      if (this.isAccountNumF) {
        mandatService.mandatByNumWithLastMandatData(mandatNum).then((data) => {
          this.mandat = data;
        });
      }
    },
    openCreateDialog() {
      this.errors = [];
      this.operation = {
        date: new Date(),
        recurrence: 'none',
        minutes: 0,
        amountGas: 0,
        amountGav: 0,
        amountHote: 0,
      };
      this.$refs.dialogCreate.show();
    },
    create() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$invalid) return;

      this.errors = [];

      if (this.isOfferOnline && this.isTypeNotForOnline) {
        this.errors.push(this.$t('accounting.error_not_for_online'));
        this.submitted = false;
        return;
      }
      if (this.accountFneeded && !this.isAccountNumF) {
        this.errors.push(this.$t('accounting.error_not_mandat'));
        this.submitted = false;
        return;
      }
      if (this.accountCneeded && !this.isAccountNumC) {
        this.errors.push(this.$t('accounting.error_not_resa'));
        this.submitted = false;
        return;
      }
      if (this.isTypeFraisDeMaintenance && this.operation.minutes === 0) {
        this.errors.push(this.$t('operation.error_no_minutes'));
        this.submitted = false;
        return;
      }
      if (this.amountNeeded && this.operation.amount === 0) {
        this.errors.push(this.$t('operation.error_amount_zero'));
        this.submitted = false;
        return;
      }
      if (this.isRemboursement && this.operation.amountTotal === 0) {
        this.errors.push(this.$t('operation.error_amount_zero'));
        this.submitted = false;
        return;
      }
      if (this.isRemboursement && this.amountGasGavHote.compareTo(new bigDecimal(this.operation.amountTotal)) !== 0) {
        this.errors.push(this.$t('operation.error_valid_amounts'));
        this.submitted = false;
        return;
      }

      this.$refs.dialogCreate.hide();
      if (this.v$.$error) return;

      comptaOperationService
        .create(this.operation)
        .then(() => {
          this.success(this.$t('operation.added'));
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.loading = false;
          this.submitted = false;
        });
    },
    clickSearch() {
      this.filter.page = 1;
      this.search();
    },
    search() {
      this.loading = true;
      this.operations = [];

      let p1 = comptaOperationService.filter(this.filter);
      p1.then((data) => {
        this.operations = data;
        for (let operation of this.operations) {
          operation.information = operation.information.replace('START_FEES', this.$t('operation.FRAIS_DE_LANCEMENT'));
        }
      })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 412) {
              this.addError(this.$t('operation.empty'), true, true);
            } else {
              this.addError(this.$t('error.' + e.response.data.error));
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatCredit(amount) {
      if (amount < 0) return '';
      return this.formatCurrencyEUR(amount);
    },
    formatDebit(amount) {
      if (amount >= 0) return '';
      return this.formatCurrencyEUR(-amount);
    },
    initAmountForfait() {
      if (this.mandat && this.isTypeFraisAnnulationHote && this.isAccountNumF) {
        this.operation.amountAnnulation = this.mandat.feeCancel;
      }
    },
    inputsAvailable() {
      this.errors = [];
      this.operation.amount = 0;
      this.operation.recurrence = 'none';
      if (this.isTypeNuisanceSonore) {
        this.operation.recurrence = 'monthly';
      }
    },
    searchAccounts(event) {
      comptaAccountService.comptaAccounts({query: event.query}).then((data) => {
        this.accounts = data;
      });
    },
    displayComment(slotProp) {
      this.showComment(slotProp);
    },
    exportOperations() {
      comptaOperationService.exportOperations(this.filter);
    },
    openFilterPanel() {
      this.toggleFilter('GaOperationsConsultationFilterList', this.filter, this.clickSearch, this.resetFilter);
    },
    resetFilter() {
      this.filter = {
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      };
      this.toggleFilter('GaOperationsConsultationFilterList', this.filter, this.search, this.resetFilter);
    },
  },
  watch: {
    'operation.type'(newVal, oldVal) {
      this.errors = [];
      let singleChoice = this.singleLabelChoice;
      this.operation.label = singleChoice ? singleChoice : '';
      if (this.isTypeFraisAnnulationHote) this.initAmountForfait();
    },
    'operation.accountNum'(newVal, oldVal) {
      this.errors = [];
      if (this.operation.accountNum) {
        this.getMandat(this.operation.accountNum.substring(1));
        if (this.isTypeFraisAnnulationHote) {
          this.initAmountForfait();
        }
      }
    },
  },
};
</script>

<template>
  <GaContentView :ref="ref" :title="$t('operation.consultation')">
    <template #action>
      <div class="d-flex align-items-center pr-1">
        <PButton
          :badge="this.countFilter(this.filter)"
          badgeClass="p-badge-primary"
          class="btn btn-inverse-primary btn-xs"
          label="Filtrer"
          @click="openFilterPanel()" />
      </div>
      <div v-if="canExport">
        <button class="btn btn-inverse-primary btn-xs" @click="exportOperations()" style="margin-right: 15px">
          {{ this.$t('payment.download') }}
        </button>
      </div>
      <div v-if="hasManagePermission">
        <button class="btn btn-inverse-primary btn-xs" @click="openCreateDialog()">
          <i class="gst-plus pr-1" />{{ $t('add') }}
        </button>
      </div>
    </template>

    <template #content>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive">
                <DataTable
                  :always-show-paginator="false"
                  :loading="loading"
                  :paginator="true"
                  :rows="50"
                  :rows-per-page-options="[10, 20, 50]"
                  :value="this.operations"
                  class="table"
                  current-page-report-template="{first}-{last}/{totalRecords}"
                  removable-sort
                  responsive-layout="scroll"
                  sort-field="position"
                  striped-rows>
                  <template #header>
                    <div class="d-flex align-items-center justify-content-start header-legend-result">
                      {{ this.$t('payment.number_results') }} {{ this.numberOfResults }}
                    </div>
                  </template>
                  <template #empty>
                    {{ $t('operation.empty') }}
                  </template>

                  <Column :header="this.$t('operation.date')" field="date" :sortable="true" style="width: 10%">
                    <template #body="slotProps">
                      {{ this.displayDate(slotProps.data.date) }}
                    </template>
                  </Column>

                  <Column :header="this.$t('employee.company')" field="companyName" :sortable="true" style="width: 10%">
                    <template #body="slotProps">
                      {{ slotProps.data.companyName }}
                    </template>
                  </Column>

                  <Column :header="this.$t('account.number')" field="accountNum" :sortable="true" style="width: 5%">
                    <template #body="slotProps">
                      {{ slotProps.data.accountNum }}
                    </template>
                  </Column>

                  <Column :header="this.$t('mandat.num')" field="mandatNum" :sortable="true" style="width: 5%">
                    <template #body="slotProps">
                      {{ slotProps.data.mandatNum }}
                    </template>
                  </Column>

                  <Column
                    :header="this.$t('accommodation.ownerName')"
                    field="ownerDisplay"
                    :sortable="true"
                    style="width: 10%">
                    <template #body="slotProps">
                      {{ slotProps.data.ownerDisplay }}
                    </template>
                  </Column>

                  <Column
                    :header="this.$t('accounting.informations')"
                    field="information"
                    :sortable="true"
                    style="width: 20%">
                    <template #body="slotProps">
                      {{ slotProps.data.information }}
                    </template>
                  </Column>

                  <Column :header="this.$t('operation.type')" :sortable="true" field="typeOperation" style="width: 15%">
                    <template #body="slotProps">
                      <span v-if="slotProps.data.typeTransaction">
                        {{ this.$t('operation.' + slotProps.data.typeTransaction) }}
                      </span>
                    </template>
                  </Column>

                  <Column :header="this.$t('activity.comment')" :sortable="true" field="label" style="width: 25%">
                    <template #body="slotProps">
                      <div class="d-flex align-items-center">
                        {{ slotProps.data.label }}
                        <i
                          v-if="slotProps.data.comment"
                          class="fa fa-info-circle ml-1 pointer"
                          @click="displayComment(slotProps.data)"></i>
                      </div>
                    </template>
                  </Column>

                  <Column
                    :header="this.$t('accounting.debit')"
                    :sortable="true"
                    class="text-right"
                    field="amount"
                    style="width: 5%">
                    <template #body="slotProps">
                      {{ this.formatDebit(slotProps.data.amount) }}
                    </template>
                  </Column>

                  <Column
                    :header="this.$t('accounting.credit')"
                    :sortable="true"
                    class="text-right"
                    field="amount"
                    style="width: 5%">
                    <template #body="slotProps">
                      {{ this.formatCredit(slotProps.data.amount) }}
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #extra>
      <AkDialog
        ref="dialogCreate"
        :title="$t('operation.add')"
        :validate-label="$t('add')"
        width="800px"
        @validate="create()">
        <div class="form-row">
          <p v-if="errors.length">
            <b v-for="(error, index) in errors" :key="index" style="color: red">
              {{ error }}
            </b>
          </p>
        </div>
        <div v-if="this.operation">
          <div class="form-row">
            <AkDropdown
              ref="dropdownType"
              v-model="operation.type"
              :label="$t('operation.type')"
              :options="operationTypes"
              :validator="v$.operation.type"
              class-name="col-md-6"
              @update:modelValue="inputsAvailable()" />

            <AkAccountAutocomplete
              v-model="operation.accountNum"
              :label="$t('operation.number')"
              class-name="col-md-6"
              :validator="v$.operation.accountNum"
              :pool="this" />

            <AkCalendar
              v-model="operation.date"
              :label="$t('operation.date')"
              :validator="v$.operation.date"
              class-name="col-md-6" />

            <AkDropdown
              v-if="buildLabelChoice"
              v-model="operation.label"
              :label="$t('operation.label')"
              :options="buildLabelChoice"
              :validator="v$.operation.label"
              :optionLabel="(obj) => obj"
              :optionValue="(obj) => obj"
              class-name="col-md-6" />
            <AkInputText
              v-else
              v-model="operation.label"
              :label="$t('operation.label')"
              :validator="v$.operation.label"
              class-name="col-md-6" />

            <AkInputMoney
              v-if="amountNeeded"
              v-model="operation.amount"
              :label="$t('operation.amount')"
              :validator="v$.operation.amount"
              class-name="col-md-6" />
            <AkInputMoney
              v-if="isRemboursement"
              v-model="operation.amountTotal"
              :label="$t('operation.amount_total')"
              :validator="v$.operation.amountTotal"
              class-name="col-md-6" />
            <AkInputMoney
              v-if="isRemboursement && hasAmountTotal"
              v-model="operation.amountGas"
              :label="$t('operation.amount_GAS')"
              :validator="v$.operation.amountGas"
              class-name="col-md-6" />
            <AkInputMoney
              v-if="isRemboursement && hasAmountTotal"
              v-model="operation.amountGav"
              :label="$t('operation.amount_GAV')"
              :validator="v$.operation.amountGav"
              class-name="col-md-6" />
            <AkInputMoney
              v-if="isTypeRemboursementVoyageur && hasAmountTotal"
              v-model="operation.amountHote"
              :label="$t('operation.amount_owner')"
              :validator="v$.operation.amountHote"
              class-name="col-md-6" />
            <AkInputMoney
              v-if="isTypeFraisAnnulationHote"
              v-model="operation.amountCommission"
              :label="$t('operation.amount_annulation_commission')"
              :validator="v$.operation.amountCommission"
              class-name="col-md-6" />
            <AkInputMoney
              v-if="isTypeFraisAnnulationHote"
              v-model="operation.amountAnnulation"
              :label="$t('operation.amount_annulation_forfait')"
              :validator="v$.operation.amountAnnulation"
              class-name="col-md-6" />
            <AkInputNumber
              v-if="isTypeFraisDeMaintenance"
              v-model="operation.minutes"
              :label="$t('operation.minutes')"
              :validator="v$.operation.minutes"
              :fraction-digits="0"
              class-name="col-md-6"
              :min="0"
              suffix=" minutes" />
            <AkCheckboxButton
              v-if="isRecurrent"
              v-model="operation.recurrence"
              :label="$t('payment.recurrence')"
              :multiple="false"
              :options="recurrenceConst"
              :validator="v$.operation.recurrence" />
            <AkInputTextArea v-model="operation.comment" :label="$t('operation.comment')" class-name="col-md-12" />
          </div>
        </div>
      </AkDialog>
    </template>
  </GaContentView>
</template>
