<script>
import moment from 'moment';

/* COMPOSANTS */
import AkInputText from '@components/input/AkInputText';
import AkInputTextArea from '@components/input/AkInputTextArea';
import AkCalendar from '@components/input/AkCalendar';
import AkTime from '@components/input/AkTime';
import AkDateTime from '@components/input/AkDateTime';
import MissionPhoto from './MissionPhoto';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';

export default {
  components: {AkInputText, AkInputTextArea, AkCalendar, AkTime, AkDateTime, MissionPhoto},
  mixins: [utilsMixin],
  props: {
    modelValue: {
      required: true,
    },
    editable: {
      required: true,
    },
  },
  data() {
    return {
      time: '',
      duration: 0,
    };
  },
  watch: {
    time(newVal, oldVal) {
      // Mettre à jour les heures et minutes de la mission
      this.currentValue.dateStart.setHours(newVal.getHours());
      this.currentValue.dateStart.setMinutes(newVal.getMinutes());
      // Ajouter la durée (en minutes) à la date de début pour obtenir la date de fin
      this.currentValue.dateEnd = new Date(moment(this.currentValue.dateStart).add(this.duration, 'm'));
    },
  },
  mounted() {
    this.initDate();
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },

    // GET

    imagesFicList() {
      return this.currentValue?.imagesFicList;
    },
    prestationParticipants() {
      return this.currentValue?.prestationParticipants;
    },

    // IS

    isAssignedStaffPrestataire() {
      return this.currentValue?.assignedStaff === 'PRESTATAIRE';
    },
    isAssignedStaffIntern() {
      return this.currentValue?.assignedStaff === 'INTERN';
    },
    isAssignedStaffHousekeeper() {
      return this.currentValue?.assignedStaff === 'HOUSEKEEPER';
    },
    isAssignedStaffPartner() {
      return this.currentValue?.assignedStaff === 'PARTNER';
    },

    isStatusPrestationCanceled() {
      return this.currentValue?.statusPrestation === 'CANCELED';
    },

    isTimeToPlan() {
      if (!(this.time instanceof Date)) return true;
      return (
        (this.isAssignedStaffIntern || this.isAssignedStaffHousekeeper) &&
        this.time.getHours() === 0 &&
        this.time.getMinutes() === 0
      );
    },
  },
  methods: {
    initDate() {
      if (!this.currentValue) return;
      // Formatage des dates pour missions des prestataires / salariés / concierges
      this.prestationParticipants?.forEach((prestationParticipant) => {
        if (prestationParticipant.clockIn) prestationParticipant.clockIn = new Date(prestationParticipant.clockIn);
      });
      // Calcul de la durée (minutes) à partir de la différence entre date de début et date de fin
      const dateStart = new Date(this.currentValue.dateStart);
      const dateEnd = new Date(this.currentValue.dateEnd);
      const diffMins = this.diffMins(dateStart, dateEnd);
      this.duration = diffMins;
      this.time = dateStart;
    },
  },
};
</script>

<template>
  <div class="mission-details">
    <!-- Informations de l'ordre de mission -->

    <div class="d-flex align-items-baseline">
      <div class="input-item">
        <span>Jour prévu</span>
        <AkCalendar
          v-model="currentValue.dateStart"
          date-format="dd/mm/yy"
          :disabled="!editable"
          class-name="col-12"
          class="no-pad-no-gap" />
      </div>
      <div class="input-item" v-if="!this.isAssignedStaffPartner">
        <span>Heure de début prévue</span>
        <AkTime
          v-if="!this.isTimeToPlan"
          v-model="time"
          :label="''"
          :disabled="!editable"
          class-name="col-12"
          class="no-pad-no-gap" />
        <div v-else class="col-12 form-group no-pad-no-gap">
          <input type="text" value="A planifier" disabled class="p-inputtext p-component p-filled form-control" />
        </div>
      </div>
      <div class="input-item">
        <span>Durée prévue</span>
        <AkInputText v-model="currentValue.duration" disabled class-name="col-12" class="no-pad-no-gap" />
      </div>
    </div>

    <!-- Missions prestataires (offres)-->

    <div class="container mt-2" v-if="isAssignedStaffPrestataire && prestationParticipants">
      <h5>Informations des prestataires</h5>
      <div class="container" v-for="(offer, index) in prestationParticipants" v-bind:key="offer.id">
        <div class="input-item">
          <h6>Prestataire {{ index + 1 }}</h6>
          <AkInputText v-model="offer.personDisplay" disabled class-name="col-12" class="no-pad-no-gap" />
        </div>
        <div class="input-item">
          <span>Commentaire pour le prestataire</span>
          <AkInputTextArea
            auto-resize
            :rows="4"
            v-model="offer.commentForPerson"
            placeholder=""
            label=""
            :displayLabel="false"
            :disabled="!editable"
            class-name="col-12"
            class="no-pad-no-gap" />
        </div>
        <div class="line">
          <div class="input-item">
            <span>Tarif de la prestation</span>
            <AkInputText v-model="offer.priceHt" :disabled="!editable" class-name="col-12" class="no-pad-no-gap" />
          </div>
          <div class="input-item">
            <span>Heure de début du prestataire</span>
            <AkTime v-model="offer.clockIn" :label="''" disabled class-name="col-12" class="no-pad-no-gap" />
          </div>
        </div>
        <div class="input-item">
          <span>Commentaire du prestataire sur le logement</span>
          <AkInputTextArea
            auto-resize
            :rows="4"
            v-model="offer.commentOnAccommodation"
            placeholder=""
            label=""
            :displayLabel="false"
            disabled
            class-name="col-12"
            class="no-pad-no-gap" />
        </div>
      </div>
    </div>

    <!-- Missions salariés -->

    <div class="container mt-2" v-if="isAssignedStaffIntern && prestationParticipants">
      <h5>Informations des salariés</h5>
      <div
        class="container"
        v-for="(prestationEmployee, index) in prestationParticipants"
        v-bind:key="prestationEmployee.id">
        <div class="input-item">
          <h6>Salarié {{ index + 1 }}</h6>
          <AkInputText v-model="prestationEmployee.personDisplay" disabled class-name="col-12" class="no-pad-no-gap" />
        </div>
        <div class="input-item">
          <span>Commentaire pour le salarié</span>
          <AkInputTextArea
            auto-resize
            :rows="4"
            v-model="prestationEmployee.commentForPerson"
            placeholder=""
            label=""
            :displayLabel="false"
            :disabled="!editable"
            class-name="col-12"
            class="no-pad-no-gap" />
        </div>
        <div class="line">
          <div class="input-item">
            <span>Heure de début du salarié</span>
            <AkTime
              v-model="prestationEmployee.clockIn"
              :label="''"
              :disabled="!editable"
              class-name="col-12"
              class="no-pad-no-gap" />
          </div>
        </div>
        <div class="input-item">
          <span>Commentaire du salarié sur le logement</span>
          <AkInputTextArea
            auto-resize
            :rows="4"
            v-model="prestationEmployee.commentOnAccommodation"
            label=""
            :displayLabel="false"
            disabled
            class-name="col-12"
            class="no-pad-no-gap" />
        </div>
      </div>
    </div>

    <!-- Missions concierges -->

    <div class="container mt-2" v-if="isAssignedStaffHousekeeper && prestationParticipants">
      <h5>Informations des concierges</h5>
      <div
        class="container"
        v-for="(prestationHousekeeper, index) in prestationParticipants"
        v-bind:key="prestationHousekeeper.id">
        <div class="input-item">
          <h6>Concierge {{ index + 1 }}</h6>
          <AkInputText
            v-model="prestationHousekeeper.personDisplay"
            disabled
            class-name="col-12"
            class="no-pad-no-gap" />
        </div>
        <div class="input-item">
          <span>Commentaire pour le concierge</span>
          <AkInputTextArea
            auto-resize
            :rows="4"
            v-model="prestationHousekeeper.commentForPerson"
            label=""
            :displayLabel="false"
            :disabled="!editable"
            class-name="col-12"
            class="no-pad-no-gap" />
        </div>
        <div class="line">
          <div class="input-item">
            <span>Heure de début du concierge</span>
            <AkTime
              v-model="prestationHousekeeper.clockIn"
              :label="''"
              :disabled="!editable"
              class-name="col-12"
              class="no-pad-no-gap" />
          </div>
        </div>
        <div class="input-item">
          <span>Commentaire du concierge sur le logement</span>
          <AkInputTextArea
            auto-resize
            :rows="4"
            v-model="prestationHousekeeper.commentOnAccommodation"
            label=""
            :displayLabel="false"
            disabled
            class-name="col-12"
            class="no-pad-no-gap" />
        </div>
      </div>
    </div>

    <!-- Missions partenaires (objectif : être générique à tous les profils) -->

    <div class="container mt-2" v-if="isAssignedStaffPartner && prestationParticipants">
      <h5>Informations des partenaires</h5>
      <div
        class="container"
        v-for="(prestationParticipant, index) in prestationParticipants"
        v-bind:key="prestationParticipant.id">
        <h6>Partenaire {{ index + 1 }}</h6>
        <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 16px">
          <div class="input-item">
            <span>Nom du partenaire</span>
            <AkInputText
              v-model="prestationParticipant.personDisplay"
              disabled
              class-name="col-12"
              class="no-pad-no-gap" />
          </div>
          <div class="input-item" v-if="!this.isStatusPrestationCanceled">
            <span>Date de validation par le partenaire</span>
            <AkDateTime
              v-model="prestationParticipant.clockIn"
              :label="''"
              disabled
              class-name="col-12"
              class="no-pad-no-gap" />
          </div>
          <div class="input-item" v-if="this.isStatusPrestationCanceled">
            <span>Date d'annulation</span>
            <AkDateTime
              v-model="prestationParticipant.dateCancel"
              :label="''"
              disabled
              class-name="col-12"
              class="no-pad-no-gap" />
          </div>
        </div>
        <div class="input-item" v-if="this.isStatusPrestationCanceled">
          <span>Raison de l'annulation de la mission</span>
          <AkInputTextArea
            auto-resize
            :rows="4"
            v-model="prestationParticipant.commentCancel"
            label=""
            :displayLabel="false"
            disabled
            class-name="col-12"
            class="no-pad-no-gap" />
        </div>
      </div>
    </div>

    <div class="input-item" v-if="this.imagesFicList?.length">
      <span>Photos</span>
      <div class="d-flex flex-wrap">
        <MissionPhoto v-for="fic in this.imagesFicList" :key="fic.id" :fic="fic" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.mission-details {
  display: flex;
  flex-direction: column;
  gap: 16px;

  > div:not(.input-item):not(.container) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
  }

  .line {
    display: flex;
    gap: 16px;
  }

  .input-item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 6px;

    > span {
      font-family: 'Nunito';
      font-weight: 600;
      font-size: 14px;
      color: #344054;
    }

    > div {
      display: flex;
      flex-direction: row;
      gap: 8px;

      > label {
        font-family: 'Nunito';
        font-weight: 600;
        font-size: 14px;
        color: #344054;
        margin-right: 20px;
      }
    }

    .no-pad-no-gap {
      padding: 0;
      gap: 0;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0;
  }
}
</style>
