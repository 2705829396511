import permissionService from '@services/permissionService';

export default {
  methods: {
    isAdmin() {
      return permissionService.isAdmin();
    },
    isGas() {
      return permissionService.isGas();
    },
    isGav() {
      return permissionService.isGav();
    },
    isGasGav() {
      return permissionService.isGasGav();
    },
    isOwner() {
      return permissionService.isOwner();
    },
    isDeveloper() {
      return permissionService.isDeveloper();
    },
    isPrestataire() {
      return permissionService.isPrestataire();
    },
    isPartenaire() {
      return permissionService.isPartenaire();
    },
    isHousekeeper() {
      return permissionService.isHousekeeper();
    },
    isMe(userId) {
      return permissionService.isMe(userId);
    },
    hasPermission(permission) {
      return permissionService.hasPermission(permission);
    },
    hasOneOfThem(permissions) {
      return permissionService.hasOneOfThem(permissions);
    },
    myUserId() {
      return permissionService.myUserId();
    },
    myEmployeeId() {
      return permissionService.myEmployeeId();
    },
    myCompanyId() {
      return permissionService.myCompanyId();
    },
    myPartenaireId() {
      return permissionService.myPartenaireId();
    },
    myHousekeeperId() {
      return permissionService.myHousekeeperId();
    },
    isPoleComptabiliteMandante() {
      return permissionService.isPoleComptabiliteMandante();
    },
  },
};
