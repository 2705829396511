<script>
import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';
import moment from 'moment';

/* COMPOSANTS */
import ConfirmDialog from 'primevue/confirmdialog';
import Checkbox from 'primevue/checkbox';
import GaView from '@components/layout/GaView';
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import PrestationBadge from '@components/general/PrestationBadge.vue';
import AkMissionOrderForm from '@views/operationnel/waitingorders/components/AkMissionOrderForm.vue';
import AkAccommodationAutocomplete from '@components/general/AkAccommodationAutocomplete.vue';
import AkReservationAutocomplete from '@components/general/AkReservationAutocomplete.vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import permissionsMixin from '@mixins/permissionsMixin';
import messageMixin from '@mixins/messageMixin';
import backMixin from '@mixins/backMixin';
import utilsMixin from '@mixins/utilsMixin';
import missionMixin from '@mixins/missionMixin';

/* SERVICES */
import missionOrderCreateService from '@services/missionOrderCreateService';
import documentModelService from '@services/documentModelService';

export default {
  components: {
    GaView,
    AkReservationAutocomplete,
    AkAccommodationAutocomplete,
    AkMissionOrderForm,
    AkFormSubmitted,
    Checkbox,
    PrestationBadge,
    ConfirmDialog,
  },
  mixins: [randomRef, permissionsMixin, messageMixin, backMixin, utilsMixin, missionMixin],
  setup() {
    return {v$: useVuelidate()};
  },
  validations() {
    return {
      accommodationId: {required},
    };
  },
  metaInfo() {
    return {
      title: 'mission_order.title_create',
    };
  },
  data() {
    return {
      accommodationId: null,
      isChoosingAccommodation: null,
      isChoosingReservation: null,
      reservationId: null,
      documentModelList: [],
      submitted: false,
      tabs: {
        cleaning: {
          selected: false,
        },
        cleaningAfter: {
          selected: false,
        },
        cleaningBefore: {
          selected: false,
        },
        check_in: {
          selected: false,
        },
        check_out: {
          selected: false,
        },
        running: {
          selected: false,
        },
        qualityControl: {
          selected: false,
        },
        maintenance: {
          selected: false,
        },
      },
      cleaningMission: {
        type: 'CLEANING',
        startHour: '',
        date: '',
        duration: new Date(2000, 1, 1, 1, 0),
        comments: [''],
        price: 0,
        numberOfPresta: 1,
        prestationOffers: [{}],
        documentModelId: undefined,
        upsells: [],
      },
      cleaningAfterMission: {
        type: 'CLEANING_AFTER',
        startHour: '',
        date: '',
        duration: new Date(2000, 1, 1, 1, 0),
        comments: [''],
        price: 0,
        numberOfPresta: 1,
        prestationOffers: [{}],
        documentModelId: undefined,
        upsells: [],
      },
      cleaningBeforeMission: {
        type: 'CLEANING_BEFORE',
        startHour: '',
        date: '',
        duration: new Date(2000, 1, 1, 1, 0),
        comments: [''],
        price: 0,
        numberOfPresta: 1,
        prestationOffers: [{}],
        documentModelId: undefined,
        upsells: [],
      },
      checkInMission: {
        type: 'CHECK_IN',
        startHour: '',
        date: '',
        duration: new Date(2000, 1, 1, 1, 0),
        comments: [''],
        price: 0,
        numberOfPresta: 1,
        prestationOffers: [{}],
        documentModelId: undefined,
        upsells: [],
      },
      checkOutMission: {
        type: 'CHECK_OUT',
        startHour: '',
        date: '',
        duration: new Date(2000, 1, 1, 1, 0),
        comments: [''],
        price: 0,
        numberOfPresta: 1,
        prestationOffers: [{}],
        documentModelId: undefined,
        upsells: [],
      },
      runningMission: {
        type: 'RUNNING',
        startHour: '',
        date: '',
        duration: new Date(2000, 1, 1, 1, 0),
        comments: [''],
        price: 0,
        numberOfPresta: 1,
        prestationOffers: [{}],
        documentModelId: undefined,
        upsells: [],
      },
      qualityControlMission: {
        type: 'QUALITY_CONTROL',
        startHour: '',
        date: '',
        duration: new Date(2000, 1, 1, 1, 0),
        comments: [''],
        price: 0,
        numberOfPresta: 1,
        prestationOffers: [{}],
        documentModelId: undefined,
        upsells: [],
      },
      maintenanceMission: {
        type: 'MAINTENANCE',
        startHour: '',
        date: '',
        duration: new Date(2000, 1, 1, 1, 0),
        comments: [''],
        price: 0,
        numberOfPresta: 1,
        prestationOffers: [{}],
        documentModelId: undefined,
        upsells: [],
      },
      cdc: [],
      chosenAccommodation: {},
      allUpsells: [],
    };
  },
  mounted() {
    this.accommodationId = parseInt(this.$route.params.accommodationId) || null;
    this.reservationId = this.$route.query.reservationId ?? null;
    this.isChoosingAccommodation = !this.accommodationId;
    this.isChoosingReservation = !this.reservationId;
    this.getChosenAccommodation();
    this.getDocumentModels();
  },
  computed: {
    // partenairePrestations() {
    //   return this.chosenAccommodation?.partenairePrestations;
    // },
    // isCheckinDoneByPartenaire() {
    //   return this.isGav() && this.partenairePrestations?.prestationTypes?.find((type) => type === 'CHECK_IN');
    // },
    // isCheckoutDoneByPartenaire() {
    //   return this.isGav() && this.partenairePrestations?.prestationTypes?.find((type) => type === 'CHECK_OUT');
    // },
    // isCleaningDoneByPartenaire() {
    //   return this.isGav() && this.partenairePrestations?.prestationTypes?.find((type) => type === 'CLEANING');
    // },
    // isCleaningBeforeDoneByPartenaire() {
    //   return this.isGav() && this.partenairePrestations?.prestationTypes?.find((type) => type === 'CLEANING_BEFORE');
    // },
    // isCleaningAfterDoneByPartenaire() {
    //   return this.isGav() && this.partenairePrestations?.prestationTypes?.find((type) => type === 'CLEANING_AFTER');
    // },
    // isRunningDoneByPartenaire() {
    //   return this.isGav() && this.partenairePrestations?.prestationTypes?.find((type) => type === 'RUNNING');
    // },
    // isMaintenanceDoneByPartenaire() {
    //   return this.isGav() && this.partenairePrestations?.prestationTypes?.find((type) => type === 'MAINTENANCE');
    // },
    // isQualityControlDoneByPartenaire() {
    //   return this.isGav() && this.partenairePrestations?.prestationTypes?.find((type) => type === 'QUALITY_CONTROL');
    // },
    // isAllDoneByPartner() {
    //   return (
    //     this.isCheckinDoneByPartenaire &&
    //     this.isCheckoutDoneByPartenaire &&
    //     this.isCleaningDoneByPartenaire &&
    //     this.isCleaningBeforeDoneByPartenaire &&
    //     this.isCleaningAfterDoneByPartenaire &&
    //     this.isRunningDoneByPartenaire &&
    //     this.isMaintenanceDoneByPartenaire &&
    //     this.isQualityControlDoneByPartenaire
    //   );
    // },
    housekeeperPrestations() {
      return this.chosenAccommodation?.housekeeperPrestations;
    },
    isCheckinAssignedToHousekeeper() {
      return this.housekeeperPrestations?.prestationTypes?.includes('CHECK_IN');
    },
    isCheckoutAssignedToHousekeeper() {
      return this.housekeeperPrestations?.prestationTypes?.includes('CHECK_OUT');
    },
    isCleaningAssignedToHousekeeper() {
      return this.housekeeperPrestations?.prestationTypes?.includes('CLEANING');
    },
    isCleaningBeforeAssignedToHousekeeper() {
      return this.housekeeperPrestations?.prestationTypes?.includes('CLEANING_BEFORE');
    },
    isCleaningAfterAssignedToHousekeeper() {
      return this.housekeeperPrestations?.prestationTypes?.includes('CLEANING_AFTER');
    },
    isRunningAssignedToHousekeeper() {
      return this.housekeeperPrestations?.prestationTypes?.includes('RUNNING');
    },
    isMaintenanceAssignedToHousekeeper() {
      return this.housekeeperPrestations?.prestationTypes?.includes('MAINTENANCE');
    },
    isQualityControlAssignedToHousekeeper() {
      return this.housekeeperPrestations?.prestationTypes?.includes('QUALITY_CONTROL');
    },
    cdcList() {
      return this.chosenAccommodation?.cdcList;
    },
    cdcCheckin() {
      return this.cdcList.find((cdc) => cdc.type === 'CHECK_IN');
    },
    cdcCheckout() {
      return this.cdcList.find((cdc) => cdc.type === 'CHECK_OUT');
    },
    isCheckinTypePhysical() {
      return this.chosenAccommodation?.receptionCheckinType === 'PHYSICAL';
    },
    isCheckoutTypePhysical() {
      return this.chosenAccommodation?.receptionCheckoutType === 'PHYSICAL';
    },
    hasTabSelected() {
      return (
        this.tabs.cleaning.selected ||
        this.tabs.cleaningAfter.selected ||
        this.tabs.cleaningBefore.selected ||
        this.tabs.check_in.selected ||
        this.tabs.check_out.selected ||
        this.tabs.running.selected ||
        this.tabs.qualityControl.selected ||
        this.tabs.maintenance.selected
      );
    },
    nbTabSelected() {
      return Object.values(this.tabs).reduce((sum, tab) => sum + tab.selected, 0);
    },
  },
  watch: {
    accommodationId(value) {
      if (this.isChoosingReservation) {
        this.reservationId = null;
        if (this.$refs.reservationAutocomplete) this.$refs.reservationAutocomplete.reset();
      }
      if (value) {
        this.getChosenAccommodation();
      }
    },
    reservationId(value) {
      if (value && this.accommodationId) {
        this.getChosenAccommodation();
      }
    },
    'tabs.check_in.selected'(newVal, oldVal) {
      // Si sélectionné -> par défaut heure de début à 16h00
      if (newVal) {
        if (!this.checkInMission.startHour) this.checkInMission.startHour = '16:00';
        this.checkInMission.duration = this.computeDuration(this.cdcCheckin?.duration);
      }
    },
    'tabs.check_out.selected'(newVal, oldVal) {
      // Si sélectionné -> par défaut heure de début à 10h00
      if (newVal) {
        if (!this.checkOutMission.startHour) this.checkOutMission.startHour = '10:00';
        this.checkOutMission.duration = this.computeDuration(this.cdcCheckout?.duration);
      }
    },
  },
  methods: {
    // SERVICE CALLS

    getChosenAccommodation() {
      if (!this.accommodationId) return;
      missionOrderCreateService
        .accommodationMissionOrder(this.accommodationId, {reservationId: this.reservationId})
        .then((data) => {
          this.chosenAccommodation = data;
          this.allUpsells = this.chosenAccommodation.upsells?.slice(0);
          // Récupérer le prix et la durée des missions
          // Prix global pour Check-in / Check-out / Contrôle Qualité
          // Prix unitaire pour Ménage interlocatif / Ménage départ hôte / Ménage retour hôte / Maintenance / Running
          this.cdcList.forEach((item) => {
            if (this.isTypeCheckin(item.type)) {
              this.checkInMission.price = item.price;
              this.checkInMission.duration = this.computeDuration(item.duration);
            }
            if (this.isTypeCheckout(item.type)) {
              this.checkOutMission.price = item.price;
              this.checkOutMission.duration = this.computeDuration(item.duration);
            }
            if (this.isTypeQualityControl(item.type)) {
              this.qualityControlMission.price = item.price;
              this.qualityControlMission.duration = this.computeDuration(item.duration);
            }
            if (this.isTypeCleaning(item.type)) {
              this.cleaningMission.prestationOffers[0].priceHt = item.price;
              this.cleaningMission.duration = this.computeDuration(item.duration);
            }
            if (this.isTypeCleaningAfter(item.type)) {
              this.cleaningAfterMission.prestationOffers[0].priceHt = item.price;
              this.cleaningAfterMission.duration = this.computeDuration(item.duration);
            }
            if (this.isTypeCleaningBefore(item.type)) {
              this.cleaningBeforeMission.prestationOffers[0].priceHt = item.price;
              this.cleaningBeforeMission.duration = this.computeDuration(item.duration);
            }
            if (this.isTypeMaintenance(item.type)) {
              this.maintenanceMission.prestationOffers[0].priceHt = item.price;
              this.maintenanceMission.duration = this.computeDuration(item.duration);
            }
            if (this.isTypeRunning(item.type)) {
              this.runningMission.prestationOffers[0].priceHt = item.price;
              this.runningMission.duration = this.computeDuration(item.duration);
            }
          });
        });
    },
    getDocumentModels() {
      // TODO: rétablir la gestion par agence quand côté portail admin le choix de l'agence sera implémenté
      // let companyId = undefined;
      // if (this.isGav()) companyId = this.myCompanyId();
      const filter = {
        // companyId: companyId,
        type: 'PRESTATION',
      };

      documentModelService
        .list(filter)
        .then((data) => {
          this.documentModelList = data;
        })
        .catch((e) => {
          this.addError(e.response.data.error);
        });
    },

    // FIXME : enlever le async sans tout casser
    async createOrder() {
      if (this.tabs.check_in.selected /* && !this.isCheckinDoneByPartenaire*/) {
        if (!(await this.createMission(this.checkInMission))) return false;
      }
      if (this.tabs.check_out.selected /* && !this.isCheckoutDoneByPartenaire*/) {
        if (!(await this.createMission(this.checkOutMission))) return false;
      }
      if (this.tabs.cleaning.selected /* && !this.isCleaningDoneByPartenaire*/) {
        if (!(await this.createMission(this.cleaningMission))) return false;
      }
      if (this.tabs.cleaningAfter.selected /* && !this.isCleaningAfterDoneByPartenaire*/) {
        if (!(await this.createMission(this.cleaningAfterMission))) return false;
      }
      if (this.tabs.cleaningBefore.selected /* && !this.isCleaningBeforeDoneByPartenaire*/) {
        if (!(await this.createMission(this.cleaningBeforeMission))) return false;
      }
      if (this.tabs.running.selected /* && !this.isRunningDoneByPartenaire*/) {
        if (!(await this.createMission(this.runningMission))) return false;
      }
      if (this.tabs.qualityControl.selected /* && !this.isQualityControlDoneByPartenaire*/) {
        if (!(await this.createMission(this.qualityControlMission))) return false;
      }
      if (this.tabs.maintenance.selected /* && !this.isMaintenanceDoneByPartenaire*/) {
        if (!(await this.createMission(this.maintenanceMission))) return false;
      }
      this.$router.push({name: 'waiting-orders'});
      return true;
    },
    // FIXME : enlever le async sans tout casser
    async createMission(mission) {
      let cdc = this.cdcList.find((cdc) => cdc.type === mission.type);
      let cdcId = cdc ? cdc.id : null;

      const documentModelId = mission.documentModelId;
      mission.prestationOffers.forEach((element) => {
        element.documentModelId = documentModelId;
      });

      // Formatage des dates
      const dateStart = this.compileStartDate(mission.date, mission.startHour);
      const dateEnd = this.compileEndDate(dateStart, mission.duration);
      // Si interne => on ignore la date de début
      const isIntern = this.isAssignedStaffIntern(mission);

      // Extras
      const upsells = this.allUpsells?.filter((u) => u.missionType === mission.type);

      const presta = {
        type: mission.type,
        accommodationId: parseInt(this.accommodationId),
        reservationId: parseInt(this.reservationId),
        cdcId: cdcId,
        comment: mission.comment,
        assignedStaff: mission.assignedStaff,
        dateStartHourIgnored: isIntern,
        dateStart: dateStart,
        dateEnd: dateEnd,
        prestationOffers: mission.prestationOffers,
        upsells: upsells,
      };
      if (
        this.isTypeCheckin(mission.type) ||
        this.isTypeCheckout(mission.type) ||
        this.isTypeQualityControl(mission.type)
      ) {
        presta.prestationOffers[0].priceHt = mission.price;
        presta.prestationOffers[0].commentForPrestataire = mission.comment;
      }
      if (this.reservationId) presta.reservationId = this.reservationId;
      await missionOrderCreateService.create(presta);
      return true;
    },

    // COMPILE DATE

    compileStartDate(date, time) {
      let hours;
      let minutes;
      if (typeof time === 'string') {
        hours = time.substring(0, 2);
        minutes = time.substring(3, 5);
      } else {
        hours = time.getHours();
        minutes = time.getMinutes();
      }
      return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes);
    },
    compileEndDate(startDate, duration) {
      let momentDuration = moment.duration({h: duration.getHours(), m: duration.getMinutes()});
      const endDate = new Date(moment(startDate.getTime()).add(momentDuration));
      return endDate;
    },

    computeDuration(cdcDuration) {
      // Pas de durée définie dans le cahier des charges, mettre par défaut 60 minutes
      if (!cdcDuration || cdcDuration < 0) return new Date(2000, 1, 1, 1, 0);
      let hours = Math.floor(cdcDuration / 60);
      let minutes = cdcDuration % 60;
      return new Date(2000, 1, 1, hours, minutes);
    },

    // DIALOG CONFIRMATION

    validateAndShowConfirmation() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$error) {
        this.addError(this.$t('mission_order.error_missing_fields'));
        return;
      }

      let isCheckInSelected = this.tabs.check_in.selected; /* && !this.isCheckinDoneByPartenaire*/
      let isCheckOutSelected = this.tabs.check_out.selected; /* && !this.isCheckoutDoneByPartenaire*/
      // Bloquer la création si pas de numéro de réservation renseigné pour des missions Check-in et/ou Check-out
      if ((isCheckInSelected || isCheckOutSelected) && !this.reservationId) {
        this.addError(this.$t('mission_order.error_no_reservation'));
        return;
      }

      // if (this.tabs.check_in.selected && this.isCheckinDoneByPartenaire) this.tabs.check_in.selected = false;
      // if (this.tabs.check_out.selected && this.isCheckoutDoneByPartenaire) this.tabs.check_out.selected = false;
      // if (this.tabs.cleaning.selected && this.isCleaningDoneByPartenaire) this.tabs.cleaning.selected = false;
      // if (this.tabs.cleaningAfter.selected && this.isCleaningAfterDoneByPartenaire)
      //   this.tabs.cleaningAfter.selected = false;
      // if (this.tabs.cleaningBefore.selected && this.isCleaningBeforeDoneByPartenaire)
      //   this.tabs.cleaningBefore.selected = false;
      // if (this.tabs.qualityControl.selected && this.isQualityControlDoneByPartenaire)
      //   this.tabs.qualityControl.selected = false;
      // if (this.tabs.maintenance.selected && this.isMaintenanceDoneByPartenaire) this.tabs.maintenance.selected = false;
      // if (this.tabs.running.selected && this.isRunningDoneByPartenaire) this.tabs.running.selected = false;

      this.showConfirmationDialog();
    },
    showConfirmationDialog() {
      this.$confirm.require({
        message: `Êtes-vous sûr de vouloir créer ${this.nbTabSelected} prestation${
          this.nbTabSelected > 1 ? 's' : ''
        } ?`,
        header: 'Confirmation',
        acceptLabel: 'Oui',
        rejectLabel: 'Non',
        acceptIcon: 'pi pi-check',
        rejectIcon: 'pi pi-times',
        accept: () => {
          if (!this.createOrder()) this.addError(this.$t('mission_order.error_missing_fields'));
        },
        reject: () => {},
      });
    },

    checkItem(id, missionType) {
      // Recherche l'upsell qui a été coché pour mettre à jour la liste globale
      this.allUpsells = this.allUpsells.map((item) => {
        if (item.id === id) {
          item.missionType = missionType;
        }
        return item;
      });
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :title="$t('mission_order.title_create')">
    <template #back>
      <a class="mb-1 d-block pointer" @click="goBack()"> <i class="fe fe-arrow-left pr-1" />{{ $t('back') }} </a>
    </template>

    <!-- ACTION -->

    <template #action>
      <div class="d-flex justify-content-end" style="column-gap: 16px">
        <button class="btn btn-create" @click="validateAndShowConfirmation" :disabled="!hasTabSelected">
          {{ $t('mission_order.create') }}
        </button>
      </div>
    </template>

    <!-- CONTENT -->

    <template #content>
      <AkFormSubmitted :submitted="submitted" reference="form" @submit="createOrder" id="create-mission-form">
        <div class="section" v-if="isChoosingAccommodation || isChoosingReservation">
          <div class="input-item" v-if="isChoosingAccommodation">
            <div class="d-flex align-items-center">
              <AkAccommodationAutocomplete
                ref="accommodationAutocomplete"
                v-model="accommodationId"
                :validator="v$.accommodationId"
                :label="$t('mission_order.accommodation')"
                className="col-6" />
              <span v-if="accommodationId" class="col-6">
                {{ this.chosenAccommodation.addressDisplay }}
              </span>
            </div>
          </div>
          <div class="input-item" v-if="isChoosingReservation && accommodationId">
            <div class="d-flex align-items-center">
              <AkReservationAutocomplete
                ref="reservationAutocomplete"
                v-model="reservationId"
                :accommodationId="accommodationId"
                :label="$t('mission_order.reservation')"
                :placeholder="$t('mission_order.reservation')"
                :useReservationId="true"
                className="col-6" />
            </div>
          </div>
        </div>

        <!-- <div class="section" v-if="this.isAllDoneByPartner">
          <span>Les missions de ce logement sont gérées par un partenaire.</span>
        </div> -->

        <!-- CHECK IN -->

        <div class="section" v-if="isCheckinTypePhysical">
          <div class="div-badge">
            <Checkbox v-model="tabs.check_in.selected" :binary="true" />
            <PrestationBadge type="CHECK_IN" />
          </div>
          <AkMissionOrderForm
            v-if="tabs.check_in.selected"
            v-model="checkInMission"
            type="CHECK_IN"
            :upsells="this.allUpsells"
            :documentModelList="this.documentModelList"
            :isAssignedToHousekeeper="this.isCheckinAssignedToHousekeeper"
            @checked-upsell="checkItem" />
        </div>

        <!-- CHECK OUT -->

        <div class="section" v-if="isCheckoutTypePhysical">
          <div class="div-badge">
            <Checkbox v-model="tabs.check_out.selected" :binary="true" />
            <PrestationBadge type="CHECK_OUT" />
          </div>
          <AkMissionOrderForm
            v-if="tabs.check_out.selected"
            v-model="checkOutMission"
            type="CHECK_OUT"
            :upsells="this.allUpsells"
            :documentModelList="this.documentModelList"
            :isAssignedToHousekeeper="this.isCheckoutAssignedToHousekeeper"
            @checked-upsell="checkItem" />
        </div>

        <!-- CLEANING -->

        <div class="section">
          <div class="div-badge">
            <Checkbox v-model="tabs.cleaning.selected" :binary="true" />
            <PrestationBadge type="CLEANING" />
          </div>
          <AkMissionOrderForm
            v-if="tabs.cleaning.selected"
            v-model="cleaningMission"
            type="CLEANING"
            :upsells="this.allUpsells"
            :documentModelList="this.documentModelList"
            :isAssignedToHousekeeper="this.isCleaningAssignedToHousekeeper"
            @checked-upsell="checkItem" />
        </div>

        <!-- CLEANING AFTER (ménage départ hote) -->

        <div class="section">
          <div class="div-badge">
            <Checkbox v-model="tabs.cleaningAfter.selected" :binary="true" />
            <PrestationBadge type="CLEANING_AFTER" />
          </div>
          <AkMissionOrderForm
            v-if="tabs.cleaningAfter.selected"
            v-model="cleaningAfterMission"
            type="CLEANING_AFTER"
            :upsells="this.allUpsells"
            :documentModelList="this.documentModelList"
            :isAssignedToHousekeeper="this.isCleaningAfterAssignedToHousekeeper"
            @checked-upsell="checkItem" />
        </div>

        <!-- CLEANING BEFORE (ménage retour hote) -->

        <div class="section">
          <div class="div-badge">
            <Checkbox v-model="tabs.cleaningBefore.selected" :binary="true" />
            <PrestationBadge type="CLEANING_BEFORE" />
          </div>
          <AkMissionOrderForm
            v-if="tabs.cleaningBefore.selected"
            v-model="cleaningBeforeMission"
            type="CLEANING_BEFORE"
            :upsells="this.allUpsells"
            :documentModelList="this.documentModelList"
            :isAssignedToHousekeeper="this.isCleaningBeforeAssignedToHousekeeper"
            @checked-upsell="checkItem" />
        </div>

        <!-- QUALITY CONTROL -->

        <div class="section">
          <div class="div-badge">
            <Checkbox v-model="tabs.qualityControl.selected" :binary="true" />
            <PrestationBadge type="QUALITY_CONTROL" />
          </div>
          <AkMissionOrderForm
            v-if="tabs.qualityControl.selected"
            v-model="qualityControlMission"
            type="QUALITY_CONTROL"
            :upsells="this.allUpsells"
            :documentModelList="this.documentModelList"
            :isAssignedToHousekeeper="this.isQualityControlAssignedToHousekeeper"
            @checked-upsell="checkItem" />
        </div>

        <!-- MAINTENANCE -->

        <div class="section">
          <div class="div-badge">
            <Checkbox v-model="tabs.maintenance.selected" :binary="true" />
            <PrestationBadge type="MAINTENANCE" />
          </div>
          <AkMissionOrderForm
            v-if="tabs.maintenance.selected"
            v-model="maintenanceMission"
            type="MAINTENANCE"
            :upsells="this.allUpsells"
            :documentModelList="this.documentModelList"
            :isAssignedToHousekeeper="this.isMaintenanceAssignedToHousekeeper"
            @checked-upsell="checkItem" />
        </div>

        <!-- RUNNING -->

        <div class="section">
          <div class="div-badge">
            <Checkbox v-model="tabs.running.selected" :binary="true" />
            <PrestationBadge type="RUNNING" />
          </div>
          <AkMissionOrderForm
            v-if="tabs.running.selected"
            v-model="runningMission"
            type="RUNNING"
            :upsells="this.allUpsells"
            :documentModelList="this.documentModelList"
            :isAssignedToHousekeeper="this.isRunningAssignedToHousekeeper"
            @checked-upsell="checkItem" />
        </div>
      </AkFormSubmitted>
    </template>

    <!-- CONFIRM DIALOG -->

    <template #extra>
      <ConfirmDialog />
    </template>
  </GaView>
</template>

<style lang="scss" scoped>
.div-badge {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.section {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 16px;
  margin-bottom: 24px;
  gap: 16px;
}

.input-item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 6px;

  > span {
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 14px;
    color: #344054;
  }

  > div {
    display: flex;
    flex-direction: row;
    gap: 8px;

    > label {
      font-family: 'Nunito';
      font-weight: 600;
      font-size: 14px;
      color: #344054;
      margin-right: 20px;
    }
  }
}

.btn-create {
  height: 44px;
  background-color: #0b3575;
  color: #ffffff;
}
</style>
