import Api from '@/services/api';
import {createFormDataFile, removeEmptyParams} from '@utils';

export default {
  partenaireAccommodations(filter) {
    return Api()
      .get('/partenaireaccommodations', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  count(filter) {
    return Api()
      .get('/partenaireaccommodations/count', {params: removeEmptyParams(filter)})
      .then((res) => res.data);
  },
  partenaireAccommodation(id) {
    return Api()
      .get('/partenaireaccommodation/' + id)
      .then((res) => this.formatRes(res.data));
  },
  formatRes(e) {
    return e;
  },
};
