<script>
/* MIXINS */
import backMixin from '@mixins/backMixin';
import messageMixin from '@mixins/messageMixin';
import loaderMixin from '@mixins/loaderMixin';

export default {
  mixins: [backMixin, messageMixin, loaderMixin],
  props: {
    title: {type: String, required: true},
    displayGoBack: {
      type: Boolean,
      required: false,
      default: true,
    },
    classTitle: {
      type: String,
      required: false,
      default: '',
    },
    classSearch: {
      type: String,
      required: false,
      default: '',
    },
    classAction: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>

<template>
  <div class="h-100 d-flex flex-column">
    <div class="row align-items-center mb-20 justify-content-between ml-2 mr-2">
      <div :class="classTitle" class="d-flex align-items-center">
        <span class="poppins-bold fs-20 color-secondary">
          {{ title }}
        </span>
        <div :class="classSearch" class="ml-4">
          <slot name="search" />
        </div>
      </div>
      <div :class="classAction" class="d-flex">
        <slot name="action" />
      </div>
    </div>
    <slot name="content" />
  </div>
  <slot name="extra" />
</template>
