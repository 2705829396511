<script>
/* COMPOSANTS */
import InputSwitch from 'primevue/inputswitch';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';

/* SERVICES */
import partenaireService from '../../services/partenaireService';
import PButton from 'primevue/button';
import loaderMixin from '@mixins/loaderMixin';

export default {
  components: {PButton, InputSwitch},
  mixins: [permissionsMixin, utilsMixin, sidebarMixin, loaderMixin],
  metaInfo() {
    return {
      title: 'partenaire.list',
    };
  },
  data() {
    return {
      list: [],
      loading: true,
      query: '',
      filter: {
        page: 1,
        limit: 10,
        sort: ['id:DESC'],
      },
    };
  },
  mounted() {
    this.search();
  },
  methods: {
    search() {
      this.loading = true;
      partenaireService.partenaires(this.filter).then((data) => {
        this.list = data;
        this.hydrateContactFields();
        this.loading = false;
      });
    },
    hydrateContactFields() {
      for (let partenaire of this.list) {
        partenaire.contactCompanyName = partenaire.contact.companyName;
        partenaire.contactLastName = partenaire.contact.lastName;
        partenaire.contactFirstName = partenaire.contact.firstName;
        partenaire.contactEmail = partenaire.contact.email;
        partenaire.contactPhone = partenaire.contact.phone;
        partenaire.accommodationNumber = partenaire.accommodationIds.length;
      }
    },
    changeEnabled(data) {
      if (data.userId === undefined) {
        this.addError(this.$t('partenaire.errorNoId'));
        data.userEnabled = false;
        return;
      }
      if (data.userEnabled) this.enable(data);
      else this.disable(data);
    },
    enable(data) {
      partenaireService.enable(data).then((data) => {
        this.search();
      });
    },
    disable(data) {
      partenaireService.disable(data).then((data) => {
        this.search();
      });
    },
    openFilterPanel() {
      this.toggleFilter('GaPartenaireFilterList', this.filter, this.search, this.resetFilter);
    },
    rowClick(event) {
      let data = event.data;
      this.$router.push({
        name: 'partenairePage',
        params: {partenaireId: data.id},
      });
    },
    resetFilter() {
      this.filter = {
        page: 1,
        limit: 10,
        sort: ['id:DESC'],
      };
      this.toggleFilter('GaPartenaireFilterList', this.filter, this.search, this.resetFilter);
    },
    exportList() {
      this.showTotalLoaderWithAfter(this.$t('litige.list_creation_in_progress'));
      partenaireService
        .exportList(this.filter)
        .then((data) => {})
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.hideLoader();
        });
    },
  },
};
</script>

<template>
  <GaView :title="$t('partenaire.title')" class="partenaire-list">
    <template #action>
      <div class="d-flex justify-content-end" style="gap: 16px">
        <PButton
          :badge="this.countFilter(this.filter)"
          badgeClass="p-badge-primary"
          class="btn btn-primary"
          label="Filtrer"
          @click="openFilterPanel()" />
        <button class="btn btn-primary" @click="exportList()">
          {{ this.$t('payment.download') }}
        </button>
        <div class="d-flex justify-content-end">
          <router-link :to="{name: 'partenaireCreate'}" class="btn btn-primary">
            <i class="gst-plus pr-1" />{{ $t('add') }}
          </router-link>
        </div>
      </div>
    </template>
    <template #content>
      <DataTable
        :always-show-paginator="false"
        :loading="loading"
        :paginator="true"
        :rows="10"
        :rows-per-page-options="[10, 20, 50]"
        :value="list"
        class="pointer"
        current-page-report-template="{first}-{last}/{totalRecords}"
        responsive-layout="scroll"
        striped-rows
        removable-sort
        @row-click="rowClick($event)">
        <template #empty>
          {{ $t('partenaire.empty') }}
        </template>
        <Column
          :header="$t('partenaire.company')"
          :sortable="true"
          sort-field="companyName"
          v-if="this.isGas() || this.isAdmin()">
          <template #body="slotProps">
            {{ slotProps.data.companyName }}
          </template>
        </Column>
        <Column :header="$t('contact.companyName')" :sortable="true" sort-field="contactCompanyName">
          <template #body="slotProps">
            {{ slotProps.data.contactCompanyName }}
          </template>
        </Column>
        <Column :header="$t('partenaire.representantLastName')" :sortable="true" sort-field="contactLastName">
          <template #body="slotProps">
            {{ slotProps.data.contactLastName }}
          </template>
        </Column>
        <Column :header="$t('partenaire.representantFirstName')" :sortable="true" sort-field="contactFirstName">
          <template #body="slotProps">
            {{ slotProps.data.contactFirstName }}
          </template>
        </Column>
        <Column :header="$t('contact.email')" :sortable="true" sort-field="contactEmail">
          <template #body="slotProps">
            {{ slotProps.data.contactEmail }}
          </template>
        </Column>
        <Column :header="$t('contact.phone')" :sortable="true" sort-field="contactPhone">
          <template #body="slotProps">
            {{ slotProps.data.contactPhone }}
          </template>
        </Column>
        <Column :header="$t('partenaire.accommodation_number')" :sortable="true" field="accommodationNumber">
          <template #body="slotProps">
            {{ slotProps.data.accommodationIds.length }}
          </template>
        </Column>
        <Column
          :header="$t('contact.enabled')"
          :sortable="true"
          body-style="text-align:center"
          sort-field="userEnabled">
          <template #body="slotProps">
            <InputSwitch
              v-model="slotProps.data.userEnabled"
              :disabled="slotProps.data.userId === undefined"
              @change="changeEnabled(slotProps.data)" />
          </template>
        </Column>
        <Column body-style="text-align: center; overflow: visible" header-style=" width: 180px; text-align: center">
          <template #body="slotProps">
            <div class="d-flex justify-content-end align-items-center">
              <router-link
                :to="{name: 'partenairePage', params: {partenaireId: slotProps.data.id}}"
                class="btn btn-xs btn-inverse-primary mr-1">
                <i class="fe fe-eye" />
              </router-link>
              <router-link
                :to="{name: 'partenaireUpdate', params: {partenaireId: slotProps.data.id}}"
                class="btn btn-xs btn-inverse-primary">
                <i class="fe fe-edit" />
              </router-link>
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
  </GaView>
</template>
