export default {
  data() {
    return {
      ownerTypesConst: [
        {label: this.$t('owner.ownerType_individu'), value: 'INDIVIDU'},
        {label: this.$t('owner.ownerType_indivision'), value: 'INDIVISION'},
        {label: this.$t('owner.ownerType_personneMorale'), value: 'PERSONNE_MORALE'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForOwnerTypeConst(value) {
      let result = this.ownerTypesConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
