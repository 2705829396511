import Api from '@/services/api';
import {removeEmptyParams, transformDownloadResponse} from '@utils';
import contentDisposition from 'content-disposition';

export default {
  find(id) {
    return Api()
      .get('/reservations/cancel/' + id)
      .then((res) => {
        return res.data;
      });
  },
  findAll(filter) {
    return Api()
      .get('/reservations/cancel', {params: removeEmptyParams(filter)})
      .then((res) => {
        return res.data;
      });
  },
  exportFile(filter) {
    return Api()
      .get('/reservations/cancel/exportfile', {
        params: removeEmptyParams(filter),
        responseType: 'blob',
        transformResponse: [transformDownloadResponse],
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  },

  accept(dto) {
    return Api()
      .post('/reservation/cancel/accept', dto)
      .then((res) => this.formatRes(res.data));
  },
  contest(dto) {
    return Api()
      .put('/reservation/cancel/contest', dto)
      .then((res) => this.formatRes(res.data));
  },
  refuse(dto) {
    return Api()
      .put('/reservation/cancel/refuse', dto)
      .then((res) => this.formatRes(res.data));
  },
  comment(dto) {
    return Api()
      .put('/reservation/cancel/comment', dto)
      .then((res) => this.formatRes(res.data));
  },

  formatRes(e) {
    return e;
  },
};
