<script>
/* COMPOSANTS */
import {Icon} from '@iconify/vue/dist/iconify';
import moment from 'moment';

/* MIXINS */
import blockadeReasonsConst from '@mixins/const/blockadeReasonsConst';
import loaderMixin from '@mixins/loaderMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import reservationTypesConst from '@mixins/const/reservationTypesConst';
import smilyStatusConst from '@mixins/const/smilyStatusConst';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import reservationService from '@services/reservationService';
import upsellStatusConst from '@mixins/const/upsellStatusConst';

export default {
  components: {Icon},
  mixins: [
    loaderMixin,
    permissionsMixin,
    reservationTypesConst,
    upsellStatusConst,
    blockadeReasonsConst,
    utilsMixin,
    smilyStatusConst,
  ],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  methods: {
    cancelReservation(reservation) {
      this.showTotalLoaderWithAfter('Suppresion en cours');
      reservationService
        .cancelReservationHote(reservation)
        .then((data) => {
          this.$emit('needReload', true);
          this.callback[0](true);
        })
        .catch(() => {
          this.callback[0](false);
        })
        .finally(() => {
          this.hideLoader();
        });
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    canDisplayYagooLink() {
      return (
        this.currentValue.boardingPass &&
        moment(this.currentValue.dateEnd).add(1, 'day').startOf('day').isAfter(moment())
      );
    },
    canDeleteReservation() {
      return (
        this.currentValue.statusSmily === 'Unavailable' &&
        this.currentValue.type === 'OWNER' &&
        this.currentValue.externalId != undefined &&
        moment(this.currentValue.dateEnd).isAfter(moment()) &&
        moment(this.currentValue.dateStart).isAfter(moment())
      );
    },
    title() {
      if (!this.currentValue) return '';
      let type = this.currentValue.type;
      let title = this.currentValue.title;

      if (type === 'GUEST' || type === 'GUEST_CANCEL') return this.currentValue.guestDisplay;
      if (type === 'BLOCKADE') return this.displayForBlockadeReason(title);
      if (type === 'OWNER') return this.displayForReservationTypeConst(type);
      return '';
    },
    period() {
      if (!this.currentValue) return '';
      let dateStart = this.currentValue.dateStart;
      let dateEnd = this.currentValue.dateEnd;

      let start = dateStart ? this.displayDate(dateStart) : '';
      let end = dateEnd ? this.displayDate(dateEnd) : '';
      return start + ' - ' + end;
    },
    statusSmily() {
      if (!this.currentValue || !this.currentValue.statusSmily) return '';
      return this.displayForSmilyStatusConst(this.currentValue.statusSmily);
    },
    dateCreated() {
      if (!this.currentValue) return '';
      let date = this.currentValue.dateCreated;
      return date ? this.displayDate(date) : '-';
    },
    dateCheckin() {
      if (!this.currentValue) return '';
      let date = this.currentValue.dateCheckin;
      return date ? this.displayTime(date) : '-';
    },
    dateCheckout() {
      if (!this.currentValue) return '';
      let date = this.currentValue.dateCheckout;
      return date ? this.displayTime(date) : '-';
    },
    isBookedReservation() {
      return this.currentValue.statusSmily !== 'Canceled' && this.currentValue.type === 'GUEST';
    },
    displayCommission() {
      if (this.isOwner()) {
        let val = this.currentValue.commission ? this.currentValue.commission : 0;
        if (this.isSiteVga) {
          val += this.currentValue.feeService ? this.currentValue.feeService : 0;
        }
        return this.formatCurrency(val, this.currentValue.currency);
      } else {
        let display = this.formatCurrency(this.currentValue.commission, this.currentValue.currency);
        if (this.isSiteVga) {
          display += ' (' + this.formatCurrency(this.currentValue.feeService, this.currentValue.currency) + ')';
        }
        return display;
      }
    },
    isSiteVga() {
      if (!this.currentValue.reservationSite) return false;
      return (
        this.currentValue.reservationSite.includes('happystay') ||
        this.currentValue.reservationSite.includes('guest-adom')
      );
    },
    hasUpsell() {
      return this.currentValue.upsellList && this.currentValue.upsellList.length > 0;
    },
  },
};
</script>

<template>
  <div>
    <div v-if="currentValue">
      <div class="d-flex justify-content-between align-items-center">
        <h4>Réservation {{ currentValue.avantioLocalizer }}</h4>
      </div>
      <h5>Données réservation</h5>
      <p>
        <b>Logement :</b> <span class="poppins-bold text-uppercase">{{ currentValue.ownerDisplay }}</span> -
        {{ currentValue.accommodationName }}
        <Icon class="icon-tooltip" v-if="currentValue.physical" icon="ic:twotone-vpn-key" color="black" />
        <Icon class="icon-tooltip" v-if="currentValue.laundry" icon="ic:twotone-local-laundry-service" color="black" />
      </p>
      <p>
        <b>Date de création de réservation :</b>
        {{ dateCreated }}
      </p>
      <p>
        <b>Statut Smily de la réservation :</b>
        {{ statusSmily }}
      </p>
      <p>
        <b>Date :</b>
        {{ period }}
      </p>
      <p>
        <b>Heure du checkin :</b>
        {{ dateCheckin }}
      </p>
      <p>
        <b>Heure de check-out :</b>
        {{ dateCheckout }}
      </p>
      <p>
        <b>OTA :</b>
        {{ currentValue.reservationSite }}
      </p>
      <p v-if="this.currentValue.type === 'GUEST' && this.currentValue.statusSmily === 'Booked' && canDisplayYagooLink">
        <b>Livret d'accueil :</b>
        <a :href="this.currentValue.boardingPass" v-if="this.currentValue.boardingPass" target="_blank">
          <Icon class="icon-book-link" icon="fluent:book-open-globe-24-regular" />
        </a>
      </p>

      <h5 class="mt-1">Données voyageur</h5>
      <p><b>Client : </b>{{ title }}</p>
      <p>
        <b>Adultes : </b> {{ currentValue.nbAdults }} / <b>Enfants : </b> {{ currentValue.nbChildren }} /
        <b>Bébés : </b> {{ currentValue.nbBabies }}
      </p>
      <p><b>Téléphone : </b>{{ currentValue.guestPhone }}</p>
      <p v-if="!this.isOwner()"><b>Commentaire : </b>{{ currentValue.comments }}</p>

      <div v-if="this.isBookedReservation && !this.isPartenaire() && !this.isHousekeeper()">
        <h5 class="mt-1">Revenus</h5>

        <p>
          <b>Montant total : </b>
          {{ formatCurrency(currentValue.price, currentValue.currency) }}
        </p>
        <p>
          <b>Commission de la plateforme : </b>
          {{ displayCommission }}
        </p>
        <p>
          <b>Taxe de séjour : </b>
          {{ formatCurrency(currentValue.tdsAmount ? currentValue.tdsAmount : 0, currentValue.currency) }}
          <span v-if="currentValue.tdsAmountForAirbnb">
            ({{
              formatCurrency(
                currentValue.tdsAmountForAirbnb ? currentValue.tdsAmountForAirbnb : 0,
                currentValue.currency,
              )
            }})</span
          >
        </p>
        <p>
          <b>Montant des nuits : </b>
          {{ formatCurrency(currentValue.nightsPrice, currentValue.currency) }}
        </p>
        <p>
          <b>Revenu Hôte : </b>
          {{ formatCurrency(currentValue.priceOwner, currentValue.currency) }}
        </p>
        <p v-if="!this.isOwner()">
          <b>Revenu Guestadom : </b>
          {{ formatCurrency(currentValue.priceGas, currentValue.currency) }}
        </p>
        <p v-if="!this.isOwner()">
          <b>Revenu conciergerie locale : </b>
          {{ formatCurrency(currentValue.priceGav, currentValue.currency) }}
        </p>

        <p v-if="this.isOwner()">
          <b>Revenu Guestadom : </b>
          {{ formatCurrency(currentValue.priceGav + currentValue.priceGas, currentValue.currency) }}
        </p>
      </div>

      <div v-if="this.hasUpsell && this.isGasGav()">
        <h5 class="mt-1">Extras</h5>

        <div v-for="u in this.currentValue.upsellList" :key="u.id">
          <div class="d-flex flex-column mb-1">
            <p>{{ u.name }} - {{ u.amount }}€</p>
            <p>
              {{ this.displayForUpsellStatusConst(u.status) }} -
              {{ this.displayForUpsellStatusEnsoConst(u.statusEnso) }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="canDeleteReservation" class="mt-2">
      <span class="btn btn-primary button pointer" @click="cancelReservation(currentValue)">
        Annuler la reservation
      </span>
    </div>
  </div>
</template>
