<script>
import scrollToTopMixin from '@mixins/scrollToTopMixin';

export default {
  mixins: [scrollToTopMixin],
  props: {},
  data() {
    return {
      messages: [],
    };
  },
  methods: {
    error(content, reset = true, closable = true) {
      if (reset) this.resetMessages();
      this.messages.push({closable: closable, severity: 'error', content: content});
      this.setLastMessageLifeTime(10000);
      this.scrollToTop();
    },
    success(content, reset = true, closable = true) {
      if (reset) this.resetMessages();
      this.messages.push({closable: closable, severity: 'success', content: content});
      this.setLastMessageLifeTime(10000);
      this.scrollToTop();
    },
    resetMessages() {
      this.messages = [];
    },
    closeMessage(message) {
      this.messages = this.messages.filter((val) => val !== message);
    },
    setLastMessageLifeTime(lifeTime) {
      let message = this.messages[this.messages.length - 1];
      let that = this;
      setTimeout(function () {
        that.closeMessage(message);
      }, lifeTime);
    },
  },
};
</script>

<template>
  <Message
    v-for="msg of messages"
    :key="msg.content"
    :closable="msg.closable"
    :severity="msg.severity"
    :style="{'margin-top': '0'}"
    @close="closeMessage(msg)">
    {{ msg.content }}
  </Message>
</template>
