export const fr = {
  app_name: 'GuestAdom',
  welcome: 'Bienvenue !',
  'welcome-title':
    'Enregistrez dès maintenant votre mot de passe pour découvrir votre portail hôte et l’estimation de vos revenus locatifs.',
  500: 'Une erreur imprévue est survenue',
  404: 'La ressource demandée est introuvable',
  403: 'Ressource interdite',
  home: 'Dashboard',
  back: 'Retour',
  cancel: 'Annuler',
  send: 'Envoyer',
  modify: 'Modifier',
  filesNbr: 'Nombre de fichiers',
  yes: 'Oui',
  no: 'Non',
  at: 'à',
  export: 'Exporter',
  export_done: 'Export terminé',
  exporting: "En cours d'export",
  currently_loading: 'En cours de chargement',
  comments: 'Aucun commentaire | 1 commentaire | {count} commentaires',
  addComment: 'Ajouter un commentaire',
  commentAdded: 'Commentaire ajouté',
  filesNb: 'Aucun fichier | 1 fichier | {count} fichiers',
  prev: 'Précédent',
  next: 'Suivant',
  my_notification: 'Mes notifications',
  notification: 'Notifications',
  waiting_since: 'En attente depuis',
  done_since: 'Réalisé le',
  download: 'Télécharger',
  next_save: 'Enregistrer et continuer',
  continue: 'Continuer',
  created_at: 'Crée le',
  date_created: 'Date de création',
  validate: 'Valider',
  submit: 'Soumettre',
  start: 'Démarrer',
  reply: 'Répondre',
  synchronize: 'Synchroniser',
  synchronized: 'Synchronisation effectuée',
  synchronizing: 'Synchronisation en cours',
  generating: 'Génération en cours',
  associate: 'Associer',
  dissociate: 'Dissocier',
  enable: 'Activer',
  disable: 'Désactiver',
  enabled: 'Activé',
  disabled: 'Désactivé',
  hide: 'Masquer',
  updated: 'Sauvegarde effectuée',
  confirm_dialog: 'Confirmation',
  delete_dialog: 'Confirmation de suppression',
  dissociate_dialog: 'Confirmation de dissociation',
  submit_dialog: 'Confirmation de soumission',
  validate_dialog: 'Confirmation de validation',
  refuse_dialog: 'Confirmation de refus',
  delete_confirm: 'Etes-vous sûr de vouloir supprimer {item} ?',
  list_template: '{first}-{last} sur {totalRecords} items',
  field_required: 'Le champ {0} est requis',
  error_min_date: 'Le champ {0} dépasse la date minimale autorisée',
  error_max_date: 'Le champ {0} dépasse la date maximale autorisée',
  bad_format: "Le format du champ {0} n'est pas correct",
  check_phone_number: 'Merci de vérifier le format du numéro de téléphone',
  check_iban_number: "Merci de vérifier le format du numéro d'iban",
  check_bic_number: 'Merci de vérifier le format du numéro de bic',
  key_already_used: 'Ce champ est déjà utilisé',
  max_length: 'Le champ {0} doit faire au maximum {1} caractères',
  min_length: 'Le champ {0} doit faire au minimum {1} caractères',
  min_max_length: 'Le champ {0} doit faire entre {1} et {2} caractères',
  min_max_value: 'Le champ {0} doit être compris entre {1} et {2}',
  min_value: 'Le champ {0} doit être supérieur à {1}',
  bad_type: 'Le champ {0} doit être {1}',
  type_integer: 'un nombre entier',
  login_not_found: 'Utilisateur inconnu, merci de vérifier vos identifiants',
  bad_pwd: 'Merci de vérifier votre mot de passe',
  reset_pwd_err: 'Les deux mots de passe doivent être identiques',
  jwt_expire: 'Délai dépassé, merci de retourner sur la page de connexion et faire mot de passe oublié',
  jwt_expire_info:
    'Le délai pour modifier votre mot de passe est dépassé. Merci de retourner sur la page de connexion, et de cliquer sur mot de passe oublié.',
  not_enabled: 'Votre compte est desactivé, merci de contacter le service client pour plus de renseignements',
  unauthorized_cleaner: "Profil 'Cleaner' non autorisé",
  unauthorized_prestataire: "Profil 'Prestataire' non autorisé",
  physical_arivee: 'Arrivée physique',
  owner_reservation: 'Réservation hôte seulement',
  include_tentative: "Ajouter les demandes d'informations",
  unknow_error: 'Une erreur est survenue',
  date_error: 'Veuillez vérifier les dates de début et de fin',
  pwd_current: 'Vérification de votre mot de passe actuel',
  pwd_new1: 'Nouveau mot de passe',
  pwd_new2: 'Répéter le nouveau mot de passe',
  email_new1: 'Nouvelle adresse mail',
  email_new2: 'Répéter la nouvelle adresse mail',
  sign_in: 'Se connecter',
  pwd_forget: 'Mot de passe oublié ?',
  pwd_forget_enter_mail: 'Saisissez votre adresse mail',
  add: 'Ajouter',
  create: 'Créer',
  update: 'Modifier',
  save: 'Sauvegarder',
  sign_ok: "J'ai signé",
  sign_gav_ok: 'Mandat signé',
  refuse: 'Refuser',
  update_dialog: 'Mise à jour',
  undefined: 'Non défini',
  choose_file: 'Choisir',
  add_file: 'Ajouter',
  add_picture_onboarding: 'Ajouter une photo du logement',
  import_file: 'Importer',
  cancel_file: 'Annuler',
  details: 'Détails',
  files: 'Pièces jointes',
  contracts: 'Contrats/Avenants',
  restrictions: 'Restrictions',
  epi: 'EPI',
  absences: 'Absences',
  activities: 'Activités',
  unavailabilities: 'Indisponibilités fixes',
  support: 'Support',
  purchase: 'Achats',
  status: 'Statut',
  drag_files: 'Déposez des fichiers ici',
  attached_files_title: 'Fichiers attachés',
  reset_pwd_err_empty: 'Vous devez saisir un mot de passe',
  reset_pwd_err_confirm: 'Vous devez saisir deux fois le même mot de passe',
  reset_pwd_err_invalid: 'Votre mot de passe est invalide',
  reset_email_err_empty: 'Vous devez saisir une adresse mail',
  reset_email_err_confirm: 'Vous devez saisir deux fois la même adresse mail',
  reset_email_err_invalid: 'Votre  est invalide',
  reset_pwd_perform: 'Changer le mot de passe',
  return_login: 'Retour',
  reset_email_perform: "Changer l'adresse mail",
  changepwd_title: 'Changement de mot de passe',
  changepwd_success:
    'Changement de mot de passe effectué avec succès. Vous pouvez désormais vous connecter avec ce nouveau mot de passe.',
  changeemail_success: "Changement d'adresse mail effectué avec succès",
  file_confirm_delete: 'Etes-vous sûr de vouloir supprimer le fichier {item} ?',
  badpwd_previous: 'Vérifiez votre mot de passe actuel',
  badpwd_empty: "Le mot de passe n'est pas renseigné",
  bademail_empty: "L'email n'est pas renseigné",
  accommodation_title: 'Logement',
  date_start: 'Date de début',
  date_end: 'Date de fin',
  date_from: 'A partir du',
  date_to: "Jusqu'au",
  select_all: 'Tout sélectionner',
  unselect_all: 'Tout désélectionner',
  user: {
    list: 'Utilisateurs',
    details: 'Fiche utilisateur',
    one: 'Utilisateur',
    empty: 'Aucun utilisateur',
    name: 'Nom',
    firstname: 'Prénom',
    lastname: 'Nom',
    maidenName: 'Nom de jeune fille',
    password: 'Mot de passe',
    password_new: 'Nouveau mot de passe',
    password_previous: 'Mot de passe précédent',
    password_repeat: 'Confirmation du mot de passe',
    email: 'E-mail',
    roleKey: 'Rôle',
    enabled: 'Actif',
    access: 'Accès hôte',
    add: 'Ajouter un utilisateur',
    update: 'Modifier un utilisateur',
    my_profile: 'Mon profil',
  },
  address: {
    address: 'Adresse',
    complement: 'Complément',
    postalCode: 'Code postal',
    city: 'Ville',
    country: 'Pays',
    state: 'Département',
  },
  company: {
    list: 'Agences',
    details: 'Fiche agence',
    one: 'Agence',
    empty: 'Aucune agence',
    name: 'Raison sociale',
    type: 'Type',
    email: 'E-mail',
    calendar_url: 'Lien du calendrier',
    address1: 'Adresse',
    address2: 'Complément',
    postalCode: 'Code postal',
    city: 'Ville',
    country: 'Pays',
    enabled: 'Active',
    siret: 'Siret',
    rcs: 'RCS',
    rcsCity: 'Ville RCS',
    ape: 'Code APE',
    urssaf: 'URSSAF',
    urssafZone: 'Région URSSAF',
    juridique: 'Forme juridique',
    capital: 'Capital',
    numTva: 'N° TVA Intracommunautaire',
    numAgrement: 'N° Agrément',
    dateAgrement: "Date d'agrément",
    numDeclaration: 'N° de déclaration',
    dateDeclaration: 'Date de déclaration',
    civility: 'Civilité',
    stock: 'Stock',
    ownerLastName: 'Nom',
    ownerFirstName: 'Prénom',
    phone1: 'Téléphone',
    phone2: 'Fax',
    add: 'Ajouter une agence',
    update: 'Modifier une agence',
    updateThis: 'Modifier',
    accommodation_types: 'Types de logement',
    companyType_filiale: 'Filiale',
    companyType_partenaire: 'Partenaire',
    companyType_franchise: 'Franchise',
    denominationCommerciale: 'Dénomination commerciale',
    zoneUrbaine: 'Zones urbaines & métropoles',
  },
  error: {
    USER_MAIL_ALREADY_EXISTS: 'Cette adresse mail est déjà utilisée',
    RESERVATION_ALREADY_EXISTS: 'Une réservation existe déjà pour ce créneau',
    MANDAT_TYPE_ALREADY_EXISTS: 'Il existe déjà un mandat avec le même type pour ce logement',
    MANDAT_NUM_ALREADY_EXISTS: 'Il existe déjà un mandat avec le même numéro',
    ACCOUNT_NUM_ALREADY_EXISTS: 'Il existe déjà un compte avec le même numéro',
    AVANTIO_ID_ALREADY_EXISTS: 'Il existe déjà un logement avec le même identifiant Avantio',
    SIGN: 'Merci de vérifier votre signature YouSign avant de cliquer sur le bouton',
    NOT_BALANCED: "Le montant de la transaction n'est pas correct, le débit et le crédit doivent se valoir",
    CANT_RENDER_TEMPLATE: 'Un problème est survenue pour générer le template',
    SMILY_TIME_OUT:
      'Une erreur est survenue pour la synchronisation vers Smily, merci de réessayer pour synchroniser correctement le logement',
    SMILY_RATE_LIMIT: 'Vous avez atteint le quota maximal de requêtes pour Smily',
    OWNER_CHARGE_ALREADY_VALIDATED: 'Cette charge hôte est déjà validée',
    INVALID_SIGN_DATE: 'Date de signature invalide',
    DATE_ALREADY_USED: 'Cette date est déjà utilisée',
    DATE_NOT_FOUND: 'Une date est manquante',
    ERROR_CANCEL_SIGNATURE_REQUEST: "Impossible d'annuler la demande de signature",
    RESA_HOTE_OVERLAP:
      'Vous ne pouvez pas effectuer de réservation hôte sur une réservation voyageur déjà existante. Vous pouvez donc contacter votre contact privilégié chez Guest Adom afin de voir les démarches à suivre. Vous remerciant de votre compréhension.',
    NO_PARTENAIRE_FOUND: 'Aucun partenaire trouvé sur ce litige',
    NO_LITIGE_ID: "Le litige n'a pas d'id",
    NO_RESERVATION_ID: 'Le litige ne contient pas de reservationId',
    NO_PARTENAIRE_ID: 'Le litige ne contient pas de partenaireId',
    NO_PARTENAIRE_PARTENAIRE_ID: "Le partenaire ne contient pas d'id",
    NO_PARTENAIRE_CONTACT: 'Le partenaire ne contient pas de contact',
    NO_PARTENAIRE_COMPANY: 'Le partenaire ne contient pas de companie',
    NO_PARTENAIRE_ACCOMMODATION: "Le partenaire n'a pas de logement",
    ERROR_CANCEL_RESERVATION: "Une erreur est survenue lors de l'annulation de réservation",
    ERROR_DATE_RESILIATION_3_MONTH: 'La date de fin doit être au moins 3 mois après la date de résiliation',
    ALREADY_RESILIATED: 'Le mandat est déjà résilié',
    SMILY_OVERLAPS: 'Les périodes de blocage sont déjà utilisées',
    NO_MANDAT_ID: 'Aucun mandat id trouvé',
    MAINTENANCE_STATUS: 'Cette maintenance a déjà été résolue',
    FILE_IS_MANDATORY: 'La pièce-jointe est obligatoire',
    ERROR_AMOUNT_PAID_OTA: 'Le montant déjà payé de la commission OTA est supérieur au montant saisie',
    unbalanced_transaction: "Une erreur est survenue: la transaction n'est pas équilibrée",
    CDC_NOT_FOUND: "Le cahier des charges n'a pas pu être récupéré",
    undefined: 'Une erreur est survenue',
    error: 'Une erreur est survenue',
  },
  role: {
    //SUPPORT
    traveller: 'Voyageur',
    relation_manager: 'Chargé de clientèle voyageur',
    accountant: 'Comptable voyageur',
    owner: 'Hôte',
    owner_relation_manager: 'Chargé de clientèle propriétaire',
    income_management: 'Revenue management',
    income_manager: 'Revenue Manager',
    assistant_income_manager: 'Assistant revenu manager',
    general_administration_and_accounting: 'Admin / Compta Générale',
    administrative_manager: 'Responsable administratif',
    executive_assistant: 'Assistant de direction',
    administrative_assistant: 'Assistant administratif',
    accountant_assistant: 'Assistant comptable',
    mandant_accounting: 'Comptabilité Mandante',
    accountant_assistant_mandant: 'Assistant comptable mandant',
    accountant_mandant: 'Comptable mandant',
    sales: 'Sales',
    head_of_sales: 'Head of Sales',
    sales_development_representative: 'Sales Development Representative',
    sales_operations: 'Sales Operations',
    marketing_and_communication: 'Com / Marketing',
    communication_manager: 'Chargé de communication',
    marketing_and_communication_manager: 'Responsable Marketing / Communication',
    communication_assistant: 'Assistant communication',
    top_management: 'Top management',
    ceo: 'CEO',
    coo: 'COO',
    country_manager: 'Country Manager',
    project_manager: 'Chef de projet',
    technical_division: 'Pôle Tech',
    digital_project_manager: 'Digital Project Manager',

    //VILLE
    poste: 'Poste',
    city_manager: 'City Manager',
    assistant_city_manager: 'Assistant city manager',
    business_developer: 'Business Developer',
    cleaner: 'Agent polyvalent',
    welcomer: 'Welcomer',
    household_assistant: 'Assistant ménage',
    administrative_and_accounting_manager: 'Responsable Administratif et Comptable',

    other: 'Autre',
    prestataire: 'Prestataire',
    gas: 'GuestAdom Support',
    gav: 'GuestAdom Ville',
    photographer: 'Photographe',
    administrator: 'Administrateur',
    custom_relation_manager: 'Gestionnaire de Relations Personalisées',
    onboarding_manager: 'Onboarding Manager',
  },
  management_type: {
    professional: 'Professionnel',
    non_professional: 'Non-professionnel',
  },
  owner: {
    list: 'Hôtes',
    details: 'Fiche hôte',
    activity: 'Activités',
    ticket: 'Tickets',
    accommodations: 'Logements',
    empty: 'Aucun hôte',
    add: 'Ajouter un hôte',
    update: 'Modifier un hôte',
    updateThis: 'Modifier',
    confirm_delete: 'Etes-vous sûr de vouloir supprimer cet hôte ?',
    deleted: "L'hôte a bien été supprimé",
    updated: "L'hôte a bien été modifié",
    added: "L'hôte a bien été ajouté",
    enabled: "L'hôte a bien été activé",
    disabled: "L'hôte a bien été désactivé",
    ownerType: 'Type',
    userEnabled: 'Actif',
    ownerType_individu: 'Personne physique',
    ownerType_indivision: 'Indivision',
    ownerType_personneMorale: 'Personne morale',
    ownerName: 'Nom',
    ownerDisplay: "Nom d'hôte",
    address: 'Adresse',
    create_access: 'Créer accès hôte',
    resend_access: "Renvoyer l'accès hôte",
    access_sent: 'Les accès ont été envoyés',
    mes_contacts: 'Mes contacts',
    relation_managers: 'Relation managers',
    all: 'Tous les hôtes',
    enabled_only: 'Uniquement les hôtes actifs',
    disabled_only: 'Uniquement les hôtes inactifs',
  },
  prospect: {
    list: 'Prospects',
    details: 'Fiche prospect',
    activity: 'Activités',
    empty: 'Aucun prospect',
    add: 'Ajouter un prospect',
    update: 'Modifier un prospect',
    updateThis: 'Modifier',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer ce prospect ?',
    force_delete:
      'Une erreur est survenue car des onboardings sont liés à ce prospect. Êtes-vous sûr de vouloir supprimer ce prospect ainsi que ses onboardings ?',
    deleted: 'Le prospect a bien été supprimé',
    updated: 'Le prospect a bien été modifié',
    added: 'Le prospect a bien été ajouté',
    enabled: 'Le prospect a bien été activé',
    disabled: 'Le prospect a bien été désactivé',
    errorNoId: "Ce prospect n'a pas de compte utilisateur",
    ownerType: 'Type',
    userEnabled: 'Actif',
    ownerType_individu: 'Personne physique',
    ownerType_indivision: 'Indivision',
    ownerType_personneMorale: 'Personne morale',
    ownerName: 'Nom',
    company: 'Conciergerie locale',
    comment: 'Commentaire',
    leadStatus: 'Status du lead',
    offer: 'Offre',
    status: 'Statut',
    priority: 'Priorité',
    source: 'Source de connaissances',
    area: 'Superficie',
    residenceType: 'Type de résidence',
    habitatType: "Type d'habitat",
    regimeJuridique: 'Régime juridique',
    accommodationType: 'Type de logement',
    accommodationType2: 'Type du logement',
    valeur: 'Valeur',
    leadStatus_OPEN_DEAL: 'Deal ouvert',
    leadStatus_NEW: 'Nouveau',
    leadStatus_OPEN: 'Ouvert',
    leadStatus_PERDU: 'perdu',
    leadStatus_GAGNE: 'gagné',
    leadStatus_IN_PROGRESS: 'En cours',
    identiteContact: 'Identité du contact',
    receptionType: 'Gestion des accueils',
    receptionCheckoutType: 'Gestion des départs',
    checkin_person: 'Personne en charge des accueils',
    checkout_person: 'Personne en charge des départs',
    housework_person: 'Personne en charge du ménage',
    prestataire_name: 'Nom du prestataire',
    nbBathroom: 'Nombre de salles de bain',
    nbBedroom: 'Nombre de chambres',
    disponibilite: 'Disponibilité',
    capacity: "Capacité d'accueil",
    all: 'Tous les prospects',
    enabled_only: 'Uniquement les prospects actifs',
    disabled_only: 'Uniquement les prospects inactifs',
  },
  onboarding: {
    shootingDate: 'Date de shooting',
    comment: 'Commentaire',
    newComment: 'Nouveau commentaire',
    list: 'Onboardings',
    details: 'Fiche onboarding',
    empty: 'Aucun onboarding',
    add: 'Ajouter un onboarding',
    update: 'Modifier un onboarding',
    updateThis: 'Modifier',
    confirm_delete: 'Etes-vous sûr de vouloir supprimer cet onboarding ?',
    deleted: 'Le onboarding a bien été supprimé',
    updated: 'Le onboarding a bien été modifié',
    added: 'Le onboarding a bien été ajouté',
    add_estimation: "Ajouter l'estimation locative",
    step_updated: 'Etape mise à jour',
    step_validated: 'Etape validée',
    step_refused: 'Etape refusée',
    add_mandat: 'Ajouter le mandat de gestion',
    nbBedroom: 'Nombre de chambres',
    nbBathroom: 'Nombre de salles de bain',
    nbWc: 'Nombre de WC',
    nbKitchen: 'Nombre de cuisines',
    nbDiningroom: 'Nombre de salles à manger',
    nbLaundryroom: 'Nombre de buanderies',
    nbSalon: 'Nombre de salons',
    step1: 'Vos informations personnelles',
    step2: 'Votre logement',
    step3: 'Intendance de votre logement',
    step4: 'Consigne de votre logement',
    step5: 'Agencement intérieur du logement',
    step6: 'Agencement extérieur du logement',
    step8: 'Contacts utiles',
    step7: 'Liste des équipements supplémentaires',
    step9: 'Calendrier',
    silentMode: 'Mode silencieux',
    mandat_creation_in_progress: 'Mandat en cours de création',
    document_upload_in_progress: "Document en cours d'upload",
    contacts: 'Contacts utiles',
    step2_missing_fic: "Des documents requis sont en attente d'upload",
    send_estimation: "Envoyer l'estimation",
  },

  accommodation: {
    intendance: {
      tableauElectriqueEmplacement: 'Emplacement du tableau électrique',
      tableauElectriqueFonctionnement: 'Fonctionnement du tableau électrique',
      tableauElectriqueConsignes: 'Consignes',
      eauChaudeEmplacement: "Emplacement de l'arrivée d'eau chaude",
      eauChaudeFonctionnement: "Fonctionnement de l'arrivée d'eau chaude",
      eauChaudeExterieureEmplacement: "Emplacement de l'arrivée d'eau chaude extérieure",
      eauChaudeExterieureFonctionnement: "Fonctionnement de l'arrivée d'eau chaude extérieure",
      disjoncteurEmplacement: 'Emplacement du disjoncteur électrique',
      disjoncteurFonctionnement: 'Fonctionnement du disjoncteur électrique ',
      disjoncteurConsignes: 'Consignes',
      ballonEauChaudeEmplacement: "Emplacement du ballon d'eau chaude",
      ballonEauChaudeFonctionnement: "Fonctionnement du ballon d'eau chaude",
      ballonEauChaudeMarque: 'Marque',
      bouteilleGazEmplacement: 'Emplacement du stockage des bouteilles de gaz',
      bouteilleGazCommentaire: 'Commentaire',
      chauffageEmplacement: 'Emplacement',
      chauffageFonctionnement: 'Fonctionnement',
      thermostatFonctionnement: 'Fonctionnement',
      thermostatMarque: 'Marque',
      vmcEmplacement: 'Emplacement',
      vmcFonctionnement: 'Fonctionnement',
      climatisationFonctionnement: 'Fonctionnement',
      coffreFort: "Présence d'un coffre fort",
      coffreFortCode: 'Code',
      coffreFortEmplacement: 'Emplacement',
      coffreFortFonctionnement: 'Fonctionnement',
      detecteurFumee: 'Détecteur de fumée',
      detecteurFumeeLocalisation: 'Localisation',
      moyenAcces: "Moyen d'accès",
      moyenAccesCode: "Code du moyen d'accès",
    },
    consigne: {
      checkinTime: "Heure d'arrivée",
      checkinEndTime: 'Heure d’arrivée au plus tard',
      checkoutTime: 'Heure de départ',
      consigneMenage: 'Consignes ménage',
      consigneWelcome: 'Consignes welcoming',
      consigneRetourHote: 'Consignes retours hote',
      smokeInside: 'Interdiction de fumer à l’intérieur du logement',
      allPets: 'Tous les animaux ?',
      petsConsidered: 'Animaux acceptés',
      onlyDog: 'Seulement les chiens ?',
      onlyCat: 'Seulement les chats ?',
      onlySmallPets: 'Animaux de petite taille ?',
      partyAllowed: 'Soirées / fêtes acceptées',
      forbiddenRoom: 'Pièces non accessibles pour les voyageurs',
      forbiddenEquipments: 'Equipements pas à disposition des voyageurs ',
      espaceHote: 'Souhaitez-vous définir un espace hôte dans votre logement ?',
      espaceHoteEmplacement: 'Emplacement',
      espaceHoteCommentaire: 'Commentaires',
    },
    garden: {
      garden: 'Jardin',
      surface: 'Surface du jardin',
      type: 'Type de jardin',
      cloture: 'Cloturé',
      entretien: 'Entretien',
      arrosage: 'Arrosage automatique',
      eclairage: 'Eclairage extérieur',
      eclairageFonctionnement: 'Fonctionnement',
      arrosageFonctionnement: 'Fonctionnement',
      barbecueFonctionnement: 'Fonctionnement du barbecue',
      mobilierRentreHiver: "Mobilier de jardin rentré l'hiver? ",
      commentaire: 'Commentaire',
    },
    pool: {
      pool: 'Piscine',
      type: 'Type de piscine',
      dateOuverture: "Date d'ouverture",
      dateFermeture: 'Date de fermeture',
      temperature: 'Température',
      dimension: 'Dimension',
      ouverture: "Type d'ouverture",
      fonctionnement: 'Fonctionnement de la piscine',
      robot: 'Robot',
      robotFonctionnement: 'Fonctionnement',
      robotNettoyage: 'Nettoyage',
      doucheExterieure: 'Douche extérieure',
      deshumidificateur: 'Déshumidificateur',
      spotLumineux: 'Spot lumineux',
    },
    jacuzzi: {
      jacuzzi: 'Jacuzzi',
      dateOuverture: "Date d'ouverture",
      dateFermeture: 'Date de fermeture',
      fonctionnement: 'Fonctionnement du jacuzzi',
      vidage: 'Fréquence pour vider le jacuzzi',
      nettoyage: 'Nettoyage spécifique',
      utilisationMaximale: "Temps maximum d'utilisation",
    },
    advert: {
      reviews: 'Avis de votre logement',
      reviews_global_rating: 'Note globale',
      reviews_nb_total: "Nombre total d'avis déposés",
      reviews_all: 'Consulter tous les avis',
    },
    nbDiningroom: 'Nombre de salle à manger',
    step1: 'Votre logement',
    step2: 'Agencement de votre logement',
    step3: 'Les règles à respecter par vos voyageurs',
    step4: 'Intendance du logement',
    step5: 'Espace hôte',
    step6: 'Contacts utiles',
    step7: 'Liste des équipements supplémentaires',
    step8: 'Votre annonce',
    step9: 'Calendrier',
    general_step: 'Informations générales',
    agencement_step: 'Agencement intérieur',
    exterieur_step: 'Agencement extérieur',
    consigne_step: 'Consigne du logement',
    intendance_step: 'Intendance du logement',
    pictures_step: 'Photos du logement',
    step5_ga: 'Espace scellé hôte',
    contact_step: 'Contacts utiles du logement',
    amenities_step: 'Liste des équipements',
    description_step: 'Description',
    step9_ga: 'Indisponibilités du logement',
    list: 'Logements',
    my_list: 'Mes logements',
    add: 'Ajouter un logement',
    update: 'Modifier un logement',
    updated: 'Le logement a été modifié',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer ce logement ?',
    msgNoAccommodationType: 'Attention, vous n’avez pas configuré de cluster pour cette agence.',
    empty: 'Aucun logement',
    name: 'Nom du logement',
    files: 'Pièces jointes',
    add_file: 'Ajouter une pièce jointe',
    file_added: 'Pièce jointe ajoutée',
    filetype: 'Type de fichier',
    title: 'Idée de titre pour votre annonce',
    precision: 'Ce qui vous semble pertinent de faire apparaitre comme information clef sur votre annonce',
    informations: 'Informations pratiques',
    info_accommodation: 'Informations logement',
    info_mandat: 'Informations mandat',
    receptionType: 'Gestion des accueils',
    checkinType: "Systeme d'arrivée",
    picture_door: "Photo de l'entrée du logement",
    physical: 'Physique',
    smart_lock: 'Serrure connectée',
    lockbox: 'Boite à clé',
    '24_hours_staff': 'Staff 24h/24',
    autonomous: 'Autonome',
    accommodationType: 'Type de logement',
    residenceType: 'Type de résidence',
    habitatType: "Type d'habitat",
    regimeJuridique: 'Régime juridique',
    typologie: 'Typologie du logement',
    access: "Moyen d'accès",
    itinerary: 'Itinéraire',
    wifi: 'Wi-fi',
    wifi_name: 'Nom du réseau wi-fi',
    wifi_password: 'Mot de passe wi-fi',
    wifi_place: 'Emplacement de la box',
    modele_box: 'Modèle de la box',
    emplacement: 'Emplacement',
    fonctionnement: 'Fonctionnement',
    marque: 'Marque',
    typeChauffage: 'Type de chauffage',
    typeEauChaude: "Type d'eau chaude",
    alarm: 'Alarme',
    alarm_code: "Code de l'alarme",
    alarm_place: "Emplacement de l'alarme",
    alarm_fonctionnement: "Fonctionnement de l'alarme",
    parking: 'Parking',
    parkingInstructionsAcces: "Instruction d'accès",
    picture_parking: 'Photo du parking',
    picture_tableau: 'Photo du tableau éléctrique',
    parking_type: 'Type de parking',
    parking_place: 'Nombre de places à disposition',
    pmr: 'Accessible PMR',
    capacity: "Capacité d'accueil",
    capacityMax: 'Couchage max',
    surfaceUnit: 'Unité de surface',
    nbRoom: 'Nombre de piéces',
    nbRoomBed: 'Nombre de chambres',
    nbRoomShared: 'Nombre de piéces communes',
    nbBathroom: 'Nombre de salles de bain',
    nbDoubleBed: 'Nombre de lit double',
    nbSimpleBed: 'Nombre de lit simple',
    conditions: 'Conditions de location',
    soumis_tva: 'Soumis à la TVA',
    apartment: 'Appartement',
    bastide: 'Bastide',
    boat: 'Bateau',
    bungalow: 'Bungalow',
    bed_breakfast: "Chambre d'hôtes",
    cabin: 'Cabane',
    castle: 'Chateau',
    cave_house: 'Caverne',
    chalet: 'Chalet',
    condominium: 'Co-propriété',
    cottage: 'Cottage',
    dormitory: 'Dortoir',
    'earth-house': 'Maison écologique',
    farmhouse: 'Ferme',
    gite: 'Gîte',
    'holiday-home': 'Maison de vacances',
    house: 'Maison',
    hut: 'Hutte',
    igloo: 'Igloo',
    island: 'Ile',
    lighthouse: 'Phare',
    loft: 'Loft',
    longere: 'Longère',
    manor: 'Manoir',
    mas: 'Mas',
    mill: 'Moulin',
    plane: 'Avion',
    'private-room': 'Chambre privée',
    'private-room-in-apartment': 'Chambre privée dans un appartement',
    'private-room-in-house': 'Chambre privée dans une maison',
    'recreational-vehicle': 'Véhicule de loisir',
    studio: 'Studio',
    tent: 'Tente',
    tipi: 'Tipi',
    townhouse: 'Maison de ville',
    train: 'Train',
    treehouse: 'Cabane dans les arbres',
    villa: 'Villa',
    yurt: 'Yourte',
    thermostat: 'Thermostat',
    climatisation: 'Climatisation',
    vmc: 'VMC',
    mainResidence: 'Résidence principale',
    secondResidence: 'Résidence secondaire',
    rentalInvestment: 'Investissement locatif',
    linkAdGuestadom: "Lien de l'annonce GUEST ADOM",
    linkYaago: "Livre d'accueil YAAGO",
    website_url: 'Annonce',
    commentAdGuestadom: "Commentaire de l'annonce GUEST ADOM",
    address: 'Adresse',
    postalCode: 'Code postal',
    city: 'Ville',
    country: 'Pays',
    state: 'Département',
    certifications: 'Certifications',
    managementType: 'Type de gestion de location',
    details: 'Fiche logement',
    updateThis: 'Modifier',
    avantioId: 'Identifiant avantio',
    smilyId: 'Identifiant smily',
    area: 'Surface du logement',
    areaExt: 'Surface extérieure',
    areaTerrasse: 'Surface de la terrasse',
    storiesCount: "Nombre d'étages",
    floor: "Numéro d'étage",
    elevator: 'Ascenceur',
    owner: 'Hôte du logement',
    company: 'Agence',
    complement: 'Complément',
    localisation: 'Localisation',
    enabled: 'Actif',
    bookableInstant: 'Reservation instantanée',
    attribution: 'Attribution',
    activity: 'Activités',
    ticket: 'Tickets',
    cdc: 'Cahiers de charges',
    tds: 'TDS',
    ownerName: 'Hôte',
    companyName: 'GA Ville',

    toutVenantLocalisation: 'Tout venant localisation',
    toutVenantJour: 'Jour de passage tout venant',
    recyclageLocalisation: 'Recyclage localisation',
    recyclageJour: 'Jour de passage recyclage',
    verreLocalisation: 'Verre localisation',
    verreJour: 'Jour de passage verre',
    code: 'Code',
    picture_wifi: 'Photo de la box',
    picture_water: "Photo de l'arrivée d'eau chaude",
    detecteurFumee: 'Détecteur de fumée',
    coffreFort: "Présence d'un coffre fort",
    systemeAutonome: 'Système d’arrivée autonome',
    appartmentFloor: "Numéro d'étage",
    appartmentAccess: "Instructions d'accès",
    ownerSpace: 'Souhaitez-vous définir un espace hôte dans votre logement ?',
    ownerSpaceComment: 'Commentaires',
    commentaire: 'Commentaires',
    reservation: 'Réservations',
    mandat: 'Mandats',
    mandatNum: 'N° de mandat',
    description: 'Description du logement',
    headline: 'Résumé du logement',
    status: 'Statut',
    available: 'Disponible',
    maintenance: 'En maintenance',
    hostReservation: 'Réservation hôte',
    rented: 'Loué',
    townStayRateId: 'Groupe de taxe de séjour pour la ville',
    regionStayRateId: 'Groupe de taxe de séjour additionnelle',
    maisonIndividuelle: 'Maison individuelle',
    maisonEnCopro: 'Maison en copropriété',
    appartImmeubleCollectif: 'Appartement dans un immeuble collectif',
    accommodation_creation_in_progress: 'Fichier en cours de création',
    immeubleRegimeCopro: 'Immeuble soumis au régime de la copropriété',
    maisonRegimeCopro: 'Maison soumise au régime de la copropriété',
    noRegimeCopro: 'Biens non soumis au régime de la copropriété',
    autresParties: 'Autres parties du logement',
    garage: 'Garage/Cabanon',
    cave: 'Cave',
    dependance: 'Dépendance',
    outsideDescription:
      "Votre extérieur, pouvez-vous nous préciser toutes les informations concernant l'extérieur de votre logement",
    registrationNumber: "Numéro d'enregistrement",
    registrationDate: 'Date de délivrance',
    portail: 'Portail',
    portailType: 'Type de portail',
    portailOuverture: "Type d'ouverture",
    portailCode: 'Code du portail',
    terrasse: 'Terrasse',
    velos: 'Vélos',
    nbVelo: 'Nombre de vélos',
    velosEmplacement: 'Emplacement des vélos',
    not_send_to_smily: 'Ne pas envoyer sur Smily',
    all: 'Tous les logements',
    enabled_only: 'Uniquement les logements actifs',
    disabled_only: 'Uniquement les logements inactifs',
  },
  contact: {
    job: 'Métier',
    civility: 'Civilité',
    civilityManager: 'Civilité du représentant',
    firstName: 'Prénom',
    firstNameManager: 'Prénom du représentant',
    lastName: 'Nom',
    lastNameManager: 'Nom du représentant',
    function: 'Qualité',
    functionManager: 'Qualité du représentant',
    email: 'E-mail',
    phone: 'Téléphone',
    phone2: 'Téléphone (2)',
    address1: 'Adresse',
    address1Manager: 'Adresse du siège social',
    address2: 'Complément',
    postalCode: 'Code postal',
    city: 'Ville',
    country: 'Pays',
    remarque: 'Remarque',
    request: 'Votre demande',
    companyName: 'Nom de la société',
    formeJuridique: 'Forme juridique',
    raisonSociale: 'Raison sociale',
    siret: 'SIRET',
    villeRcs: 'Ville RCS',
    addMember: 'Ajouter un indivisaire',
    deleteMemberWarning: 'Etes-vous sûr de vouloir supprimer cet indivisaire ?',
    enabled: 'Activé',
  },
  ownerPurchase: {
    list: 'Achats hôte',
    one: 'Achat hôte',
    add: 'Ajouter un achat',
    update: 'Modifier un achat',
    delete: 'Etes-vous sûr de vouloir supprimer cet achat ?',
    price: 'Prix',
    name: "Nom de l'achat",
    purchaseDate: "Date de l'achat",
    type: "Type d'achat",
    details: 'Achat hôte',
    updateThis: 'Modifier',
    empty: 'Aucun achat',
    tva: 'TVA',
    accommodationId: 'Logement associé',
    added: "L'achat hôte a bien été ajouté",
    updated: "L'achat hôte a bien été modifié",
  },
  reservation: {
    activity: 'Activités',
    action: 'Action',
    list: 'Réservations',
    one: 'Réservation',
    add: 'Ajouter une réservation',
    update: 'Modifier une réservation',
    delete: 'Etes-vous sûr de vouloir supprimer cette réservation ?',
    added: 'La réservation a bien été ajoutée',
    updated: 'La réservation a bien été modifiée',
    deleted: 'La réservation a bien été supprimée',
    details: 'Réservation',
    updateThis: 'Modifier',
    empty: 'Aucune réservation',
    accommodationId: 'Logement associé',
    type: 'Type de réservation',
    guest: 'Client',
    guestName: 'Nom du voyageur',
    statusSmily: 'Status',
    creation_date: 'Date de création',
    avantioLocalizer: 'Numéro',
    avantioLocalizerLong: 'Numéro de réservation Avantio',
    excludePlanning: 'Exclu du planning',
    paymentType: 'Type de paiement',
    comment: 'Commentaire',
    reservationType_guest: 'Voyageur',
    reservationType_guest_cancel: 'Annulation voyageur',
    reservationType_owner: 'Hôte',
    reservationType_owner_cancel: 'Annulation Hôte',
    reservationType_blockade: 'Indisponible',
    reservationType_blockade_cancel: 'Annulation Indisponible',
    statusRefund: 'Statut de remboursement',
    statusRefund_pending: 'En attente',
    statusRefund_accepted: 'Accepté',
    statusRefund_refused: 'Refusé',
    statusRefund_contested: 'Contesté',
    dateStart: 'Date de début de séjour',
    dateEnd: 'Date de fin de séjour',
    hourCheckin: 'Heure de check-in',
    hourCheckout: 'Heure de check-out',
    dateCheckin: 'Date de check-in',
    dateCheckout: 'Date de check-out',
    reservationSite: 'Site de réservation (OTA)',
    reservation_site: 'OTA',
    guestFirstName: 'Prénom du client',
    guestLastName: 'Nom du client',
    guestPhone: 'Téléphone',
    guestPhone2: 'Téléphone (2)',
    guestMail: 'Email',
    nbAdults: "Nb d'adultes",
    nbChildren: "Nb d'enfants",
    nbBabies: 'Nb de bébés',
    cautionDone: 'Caution déposée',
    paymentDone: 'Paiement effectué',
    comments: 'Commentaires',
    occupantInfo: "Information sur l'occupant",
    blockadeReason: 'Motif',
    blockade_travaux: 'Travaux',
    blockade_renov: 'Rénovation',
    contact_data_mandatory: 'Une donnée de contact est obligatoire pour le client',
    nb_people_mandatory: 'Le nombre de personnes doit être supérieur à 0',
    period: 'Période',
    dateCancel: "Date d'annulation",
    dateRefund: 'Date de remboursement',
    planning: 'Planning des réservations',
    accommodation: 'Logement',
    prestations: 'Prestations',
    extra: 'Extras',
    stayTax: 'Taxe de séjour',
    plateformFee: 'Frais de plateforme',
    amountStay: 'Montant de séjour',
    feeWelcome: "Frais d'accueil",
    feeService: 'Frais de service',
    feeHousework: 'Frais de ménage',
    feeHouseworkOwner: 'Frais de ménage départ hôte',
    amountThreshold: 'Seuil de montant de séjour',
    gainMinGuestadom: 'Gain minimal pour Guestatom',
    comGa: 'Pourcentage de commission pour Guestadom',
    comGas: 'Pourcentage de commission pour GAS',
    number: 'Numéro de réservation',
    state_reversed: 'Réservations payées',
    state_not_reversed: 'Réservations impayées',
    payment: 'Réglement',
    cancel: 'Annulation',
    payment_details: 'Détails de réglement de la réservation',
    cancel_details: "Détails de l'annulation de la réservation",
    agentLocalizator: 'Localisateur agent intermédiaire',
    managementStatus: 'Statut de gestion',
    pending_refund: 'Remboursement en attente',
    cancelled: 'Annulée',
    cancelled_creation_in_progress: 'Annulation en cours de création',
    payment_creation_in_progress: 'Paiement en cours de création',
    error_export: "Une erreur est survenue lors de l'export",
    error_no_cancelled: 'Aucune annulation à exporter',
    amountTotal: 'Montant total',
    amountPaid: 'Montant total encaissé',
    amountPaidNight: 'Montant encaissé pour les nuits',
    amountPaidOta: 'Montant encaissé par la plateforme',
    amountPaidHousework: 'Montant encaissé pour le ménage',
    amountPaidTds: 'Montant encaissé pour la TDS',
    litige: 'Litige',
    litigeStarted: 'Litige déclaré',
    litigeEnded: 'Litige résolu',
    litigePending: 'Litige',
    dateStartLitige: 'Date de début de litige',
    dateEndLitige: 'Date de fin de litige',
    amountLitige: 'Montant du litige',
    amountLitigeEstimated: 'Montant du litige estimé',
    amountLitigeReported: 'Montant du litige encaissé',
    amountLitigeGas: 'Part pour GAS',
    amountLitigeGav: 'Part pour GAV',
    amountLitigeOwner: "Part pour l'hôte",
    startLitige: 'Déclarer un litige',
    endLitige: 'Résoudre le litige',
    reclamation: 'Réclamation',
    reclamationStarted: 'Réclamation déclarée',
    reclamationEnded: 'Réclamation résolue',
    reclamationPending: 'Réclamation',
    dateStartReclamation: 'Date de début de réclamation',
    dateEndReclamation: 'Date de fin de réclamation',
    amountReclamation: 'Montant de la réclamation',
    amountReclamationEstimated: 'Montant de la réclamation estimé',
    amountReclamationReported: 'Montant de la réclamation reversé',
    amountReclamationGas: 'Part pour GAS',
    amountReclamationGav: 'Part pour GAV',
    amountReclamationOwner: "Part pour l'hôte",
    startReclamation: 'Déclarer une réclamation',
    endReclamation: 'Résoudre la réclamation',
    contestation: 'Contestation',
    contestationStarted: 'Contestation déclarée',
    contestationEnded: 'Contestation résolue',
    contestationPending: 'Contestation',
    dateContestation: 'Date de contestation',
    dateStartContestation: 'Date de début de contestation',
    dateEndContestation: 'Date de fin de contestation',
    amountContestation: 'Montant de la contestation',
    amountContestationEstimated: 'Montant de la contestation estimé',
    amountContestationReported: 'Montant de la contestation reversé',
    amountContestationGas: 'Part pour GAS',
    amountContestationGav: 'Part pour GAV',
    amountContestationOwner: "Part pour l'hôte",
    startContestation: 'Déclarer une contestation',
    endContestation: 'Résoudre la contestation',
    refundAccept: 'Accepter',
    refundContest: 'Contester',
    refundRefuse: 'Refuser',
    refundAcceptTitle: 'Accepter le remboursement',
    refundContestTitle: 'Contester le remboursement',
    refundRefuseTitle: 'Refuser le remboursement',
    refundAccepted: 'Remboursement accepté',
    refundContested: 'Remboursement contesté',
    refundRefused: 'Remboursement refusé',
    defautPending: 'Défaut de paiement',
    cancelledBefore30: 'Annulé plus de 30J avant',
    nbDaysCancelled: 'Nb de jours entre annulation et check-in',
    cleaningDuration: 'Durée de cleaning prévue',
    avenantMandat: 'Rattaché à',
  },
  maintenance: {
    list: 'Maintenances',
    details: 'Fiche maintenance',
    one: 'Maintenance',
    empty: 'Aucune maintenance',
    add: 'Nouvelle maintenance',
    added: 'La maintenance a bien été ajoutée',
    update: 'Modifier une maintenance',
    updated: 'La maintenance a bien été modifiée',
    updateThis: 'Modifier',
    confirm_delete: 'Etes-vous sûr de vouloir supprimer cette maintenance ?',
    responsable: 'Responsable',
    repairer: 'Réparateur',
    repairer_artisan: 'Artisan',
    repairer_gav: 'GAV',
    cost: 'Coût',
    responsable_guest: 'Client',
    responsable_usure: 'Usure',
    responsable_salary: 'Salarié',
    accommodationId: 'Logement associé',
    companyName: 'GA Ville',
    date_maintenance: 'Date de la découverte',
    date_resolution: 'Date de la résolution',
    tag: 'Tag',
    status: 'Statut',
    owner: 'Hôte',
    ownerChoice: "Choix de l'hôte",
    action: 'Action',
    resolve: 'Résoudre',
    acknowledged: 'La maintenance a bien été classifiée',
    resolved: 'La maintenance a bien été résolue',
    resolve_maintenance: 'Résoudre la maintenance',
    trait: 'Traiter la maintenance',
    trait_maintenance: 'Traiter la maintenance',
    traited: 'La maintenance a bien été traitée',
    refuse_maintenance: "Maintenance gérée par l'hôte",
    accept_maintenance: 'Maintenance gérée par Guest Adom',
    managed_by: 'Maintenance gérée par :',
    refus_comment: 'Raison du refus',
    refus: 'Refusée',
    refused: "La maintenance sera gérée par l'hôte",
    accept: 'Acceptée',
    accepted: 'La maintenance sera gérée par Guest Adom',
    start: 'Démarrer la maintenance',
    started: 'La maintenance a bien été démarrée',
    usure: 'Usure',
    litige: 'Litige',
    discovery_person: 'Personne qui a fait la découverte',
    problem: 'Problème',
    description: 'Description',
    photos: 'Photos',
    error_empty_photos: 'Le champ Photos est requis',
    reference: 'Marque / référence équipement',
    comment: 'Commentaire',
    comment_treatment: 'Commentaire sur le traitement',
    priority: 'Priorité',
    priority_urgent: 'Urgent',
    priority_high: 'Haut',
    priority_medium: 'Normal',
    priority_low: 'Bas',
    a_resoudre: 'A résoudre',
    a_traiter: 'A traiter',
    en_attente: 'En attente',
    decision_prise: 'Decision prise',
    en_cours: 'En cours',
    resolue: 'Résolue',
    amount_estimated: 'Montant estimé',
    amount_ttc: 'Montant TTC',
    link_url: 'Lien URL',
    silent: "Mode silencieux (ne pas notifier l'hôte)",
    file: 'Pièce jointe',
    error_empty_files: 'Le champ Fichier est requis',
    description_resolution: 'Description de la résolution',
    amount_ht: 'Montant HT',
    amount_gas: 'Imputé à GAS',
    amount_gav: 'Imputé à GAV',
    amount_owner: "Imputé à l'hôte",
    error_total_amount: 'La somme des montants saisis ne correspond pas au montant total TTC renseigné',
  },
  assigned_staff: {
    prestataire: 'Prestataire',
    intern: 'Interne',
    housekeeper: 'Concierge',
    partner: 'Partenaire',
  },
  planification: {
    owner: "Nom de l'hôte",
    reservation: 'Réservation actuelle',
    next_reservation: 'Réservation suivante',
    last_reservation: 'Réservation précédente du logement',
    last_comment_prestataire: 'Commentaire intervenant',
    accommodation_address: 'Adresse du logement',
    date_created_reservation: 'Date création réservation',
    laundry_included: 'Linge inclus',
    checkin_autonome: 'Arrivée autonome',
    checkout_autonome: 'Départ autonome',
    extras: 'Extras commandés',
    extra: 'Extra',
    prestataire: 'Prestataire',
    intern: 'Interne (salariés GA)',
    housekeeper: 'Concierge',
    start_hour: 'Heure de début',
    duration: 'Durée',
    comment: 'Commentaire',
    price: 'Tarif',
    number_staff: 'Nombre de personne affecté',
  },
  mission_order: {
    title: 'Ordres de mission',
    title_create: 'Créer des ordres de mission',
    create: "Créer l'ordre de mission",
    empty: 'Aucun ordre de mission',
    accommodation: 'Logement',
    reservation: 'Numéro de réservation',
    document_model: 'Modèle de contrat',
    assigned_staff: 'Personnel affecté',
    date: 'Date prévue',
    start_hour: 'Heure de début',
    duration: 'Durée prévue',
    comment: 'Commentaire',
    price: 'Tarif',
    number_staff: 'Nombre de personne(s) affectée(s)',
    offer_price: 'Tarif de la prestation n°',
    comment_on_offer: 'Commentaire de la prestation n°',
    error_missing_fields: 'Un ou plusieurs champs obligatoires ne sont pas renseignés.',
    error_no_reservation:
      'Vous devez choisir une réservation pour pouvoir créer des missions "Check-in" et/ou "Check-out"',
    tabs: {
      all: 'Tous les ordres',
      proposed: 'Proposée',
      accepted: 'Acceptée',
      completed: 'Terminée',
      litigation: 'Litige',
      canceled: 'Annulée',
    },
    mission_status: 'Statut de la mission',
    nb_participants: "Nombre d'intervenants",
    date_created: "Date de l'ordre",
    date_start: 'Date de début de la prestation',
    date_cancel: "Date d'annulation",
    canceller: 'Personne qui a annulé la mission',
    type: 'Type de mission',
  },
  mission_order_details: {
    planned_mission_duration: 'Durée de mission prévue',
    edit_success: 'La mission a bien été modifiée',
    cancel_success: 'La mission a bien été annulée',
    repropose_success: 'La mission a bien été remise en ligne',
  },
  mission_dashboard: {
    title: 'Dashboard des missions',
  },
  waiting_orders: {
    title: "Réservation en attente d'ordre",
    period: 'Période',
    new: 'Nouvelle mission',
    empty: "Aucune réservation en attente d'ordre",
    company: 'Agence',
    accommodation: 'Logement',
    reservation: 'Numéro de réservation',
    client: 'Nom du voyageur',
    owner: 'Propriétaire',
    accommodation_address: 'Adresse du logement',
    date_created: 'Date création réservation',
    date_start: 'Date de début',
    date_end: 'Date de fin',
    client_infos: 'Informations voyageur',
    create: 'Créer des missions',
  },
  accounting: {
    informations: 'Informations',
    mandant: 'Comptabilité mandante',
    stay_rate: 'Groupe de TDS',
    operations: 'Opérations',
    account: 'Comptes',
    balance: 'Balance',
    solde: 'Solde',
    payment: 'Règlement',
    cancel: 'Annulation',
    visitor_tax: 'Taxes de séjour',
    third_party_account: 'Compte de tiers',
    third_party_details: 'Détails de compte de tiers',
    bank_reconciliation: 'Rapprochement bancaire',
    bank_reconciliation_details: 'Détails du rapprochement bancaire',
    date: "Date d'opération",
    label: 'Libellé',
    debit: 'Débit',
    credit: 'Crédit',
    empty: 'La liste des comptes est vide',
    total: 'Total',
    number: 'Numéro de compte',
    amount: 'Montant',
    type: 'Type',
    consultation: 'Consultation',
    transactions: 'Ecritures comptables',
    free_od: 'Saisie OD libre',
    error_not_mandat: "Le compte saisi n'est pas un compte de mandat",
    error_not_resa: "Le compte saisi n'est pas un compte de réservation",
    error_not_for_online: 'Cette transaction ne peut être facturée sur une offre Essentielle',
    owner_crg: 'Mes comptes-rendus de gestion',
    defautpaiement: 'Défaut de paiement',
  },
  transaction: {
    update: 'Modification de transaction',
    updated: 'Transaction modifiée',
  },
  balance: {
    balance_creation_in_progress: 'Balance en cours de création',
    show_resa_account: 'Comptes des résa solde nul',
    show_mandats_terminated: 'Comptes des mandats résiliés',
    show_only_resa_accounts: 'Comptes des réservations uniquement',
    unknown_template: 'Aucun template trouvé',
  },
  operation: {
    consultation: 'Consultation',
    recurring_charges: 'OD récurrentes',
    validate_owner_charge: 'Opérations à valider',
    account_from: 'Compte débité',
    account_to: 'Compte crédité',
    date_created: 'Date créée',
    date_end: 'Date de fin',
    date_paused: 'Date de pause',
    date_updated: 'Date modification',
    date_validated: 'Date validation',
    mandat: 'Mandat',
    monthly: 'Mensuelle',
    none: 'Aucune',
    add: 'Ajouter une OD',
    edit: 'Modifier une OD',
    add_line: 'Ajouter une ligne',
    delete_line: 'Supprimer une ligne',
    delete_transaction: 'Etes-vous sûr de vouloir supprimer cette écriture comptable ?',
    delete_transaction_ok: 'Ecriture comptable supprimée',
    delete_line_warning: 'Supprimer la ligne?',
    limit_size_line: 'Suppression impossible, une transaction ne peux faire moins de deux lignes',
    type: "Type d'OD",
    number: 'Numéro de compte',
    added: 'OD ajoutée',
    added_free_od: 'OD libres ajoutées',
    added_bank_reconciliation: 'Rapprochement bancaire ajouté',
    date: 'Date',
    label: 'Libellé',
    creator: 'Crée par',
    updater: 'Mis à jour par',
    company: 'Agence',
    amount: 'Montant',
    amount_total: 'Montant total',
    amount_GAS: 'Montant GAS',
    amount_GAV: 'Montant GAV',
    amount_owner: 'Montant hôte',
    amount_annulation_commission: 'Montant commission annulation',
    amount_annulation_forfait: 'Montant forfait annulation',
    comment: 'Commentaire',
    minutes: 'Temps de déplacement',
    minute: 'minutes',
    ota: 'OTA',
    empty: 'Aucune opérations à afficher',
    empty_bank_reconciliation: 'Aucun rapprochement bancaire à afficher',
    empty_date: "La date d'opération doit être renseignée",
    empty_account_num: 'Le numéro de compte doit être renseigné',
    empty_label: 'Le libellé ne peut etre vide',
    empty_amount: 'Un montant de débit ou de crédit doit être saisi',
    double_amount: 'Un montant de débit et de crédit ne peuvent être saisi en même temps',
    total: 'Total',
    revert: 'Rejet de paiement',
    payment: 'Paiement',
    tds: 'Taxe de séjours',
    fstripe: 'Frais Stripe',
    free_od: 'OD Libre',
    rebalance: 'Rééquilibrage',
    recurrence_period: 'Récurrence',
    recurrence: 'Récurrente',
    reservation: 'Réservation',
    update: 'Modifier',
    delete: 'Supprimer',
    pause_recurring: 'Mettre en pause',
    pause_recurring_back: 'Activer',
    compta_charge_paused: 'Récurrence mise en pause',
    compta_charge_actived: 'Récurrence activée',
    compta_charge_deleted: 'Récurrence effacée',
    compta_charge_modify: 'Modifier une charge récurrente',
    compta_charge_updated: 'Charge récurrente modifiée',
    create_bank_reconciliation: 'Ajouter un rapprochement bancaire',
    transaction_free_od: ' TRANSACTION OD LIBRE',
    error_amount: 'Le montant ne peux contenir que des chiffres, virgules ou points',
    error_amount_zero: 'Le montant ne peux pas être zéro',
    error_valid_amounts: 'La somme des montants saisis ne correspond pas au montant total renseigné',
    error_transaction: "Le montant de la transaction n'est pas correct, le débit et le crédit doivent se valoir",
    error_reject_more_paid: 'Le montant du rejet ne peux dépasser le montant reglé',
    error_price_less: 'Le montant du paiement ne peux dépasser le prix total',
    error_already_payed: 'Le montant à déjà été reglé',
    error_no_minutes: 'Le temps de déplacement doit être supérieur à 0',
    error_update: 'Un problème est survenu lors de la mise à jour',
    error_delete: 'Un problème est survenu lors de la suppression',
    error_add_bank_reconciliation: "Un problème est survenu lors de l'ajout du rapprochement bancaire",
    undefined: 'Non définie',
    delete_warning_recurrence: 'Supprimer cette charge récurrente?',

    ACHAT_HOTE: 'Achat hôte',
    ACOMPTE: 'Acompte',
    ASSURANCE: "Frais d'assurance",
    AVOIR: 'Avoir',
    CAUTION_OD_ENCAISSEMENT: "Caution (OD d'encaissement)",
    COMMISSION: 'Commission',
    FRAIS_ANNULATION_HOTE: "Frais d'annulation hôte",
    COMMISSION_FRAIS_ANNULATION_VOYAGEUR: "Commission - Frais d'annulation voyageur",
    COMPTE_A_COMPTE: "D'un compte à un compte",
    CRG_CLOSED: 'Clôture de CRG',
    CRG_REPORT: 'Report de CRG',
    DEEP_CLEANING: 'Deep cleaning',
    EXTRA_VOYAGEURS: 'Extra voyageurs',
    FORFAIT_VERIFICATION_MENAGE_HOTE: "Forfait - 'Vérification' ménage hôte",
    FRAIS_ACCUEIL: "Frais d'accueil",
    FRAIS_DE_BLANCHISSERIE: 'Frais de blanchisserie',
    FRAIS_DE_MAINTENANCE: 'Frais de maintenance',
    FRAIS_DE_LANCEMENT: 'Frais de lancement',
    FRAIS_DE_MAINTENANCE_OLD: 'Frais de maintenance (ancien)',
    MAINTENANCE: 'Maintenance',
    FRAIS_DE_MENAGE_HOTE: 'Frais de ménage hôte',
    FRAIS_DE_MENAGE_VOYAGEUR: 'Frais de ménage voyageur',
    FRAIS_DE_SERVICE_GA: 'Frais de service GA',
    FRAIS_OPERATIONNEL: 'Frais opérationnel',
    FSTRIPE: 'Frais Stripe',
    LITIGE: 'Litige voyageur',
    NUISANCE_SONORE: 'Nuisance sonore',
    OD_LIBRE: 'OD Libre',
    OUVERTURE_HIVERNAGE: 'Forfait ouverture hivernage',
    FERMETURE_HIVERNAGE: 'Forfait fermeture hivernage',
    PAYMENT: 'Paiement',
    REFUND: 'Remboursement',
    RECLAMATION: 'Réclamation voyageur',
    REMBOURSEMENT_HOTE: 'Remboursement hôte',
    REMBOURSEMENT_VOYAGEUR: 'Remboursement voyageur',
    REFUND_GUEST: 'Remboursement voyageur',
    REFUND_GUESTADOM: 'Remboursement GUEST ADOM',
    RESERVATION: 'Réservation',
    REVERT: 'Rejet de paiement',
    TDS: 'Taxe de séjours',
    TROP_PERCU: 'Trop perçu',
    VERIFICATION_MENAGE_HOTE: 'Vérification ménage hôte',
    VIREMENT_CRG: 'Virement de CRG',
    VIREMENT_OTA: 'Virement OTA',
    VIREMENT_TDS: 'Virement TDS',
    START_FEES: 'Frais de lancement',
    CANCEL_FEE: "Frais d'annulation",
    CANCEL: 'Annulation',
    HOTE: "Remboursement pour l'hôte",
    GAV: 'Remboursement pour GAV',
    GAS: 'Remboursement pour GAS',
    od22: "OD d'encaissement remboursement champs libre",
  },
  owner_cancel: {
    item: 'Annulation hôte',
    empty: 'Aucune annulation hôte à afficher',
    date_cancel: "Date d'annulation",
    details: "Détails de l'annulation",
    guest_contacted: 'Guest contacté',
    guest_refunded: 'Remboursement voyageur fait',
    status_updated: 'Le statut a été mis à jour',
    GUEST_TO_CONTACT: 'Guest à contacter',
    GUEST_TO_REFUND: 'Remboursement voyageur à faire',
    DONE: 'Annulation traitée',
  },
  facturation_hote: {
    filter: 'Filtrer',
    facturation_hote: 'Facturation hôte',
    owner_charge: 'Charge hôte',
    type: 'Type de facturation',
    ACHAT_HOTE: 'Achat hôte',
    FRAIS_DE_MAINTENANCE: 'Frais de maintenance',
    FORFAIT_HIVERNAGE: 'Forfait hivernage',
    OUVERTURE_HIVERNAGE: 'Forfait ouverture hivernage',
    FERMETURE_HIVERNAGE: 'Forfait fermeture hivernage',
    FRAIS_DE_MENAGE_HOTE: 'Frais de ménage hôte',
    status: 'Statut',
    IN_PROGRESS: 'En cours',
    VALIDATED: 'Validée',
    DELETED: 'Supprimée',
    amount: 'Montant',
    motif: 'Motif',
    deplacement_minutes: 'Temps de déplacement (minutes)',
    date_created: 'Date de création',
    date_transaction: "Date d'opération",
    date_intervention: "Date d'intervention",
    date_purchase: "Date d'achat hôte",
    type_operation: "Type d'opération",
    add_owner_charge: 'Ajouter une charge hôte',
    empty: 'Aucune charge hôte à afficher',
    owner_charge_added: 'Charge hôte ajoutée',
    owner_charge_validated: 'Charge hôte validée',
    owner_charge_deleted: 'Charge hôte supprimée',
    validate: 'Valider une charge hôte',
    update: 'Modifier une charge hôte',
    update_validate: 'Modifier et valider',
    delete: 'Supprimer une charge hôte',
    files_deleted: 'Le fichier a bien été supprimé',
    confirm_validate: 'Êtes-vous sûr de vouloir valider cette charge hôte?',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer cette charge hôte?',
    confirm_delete_file: 'Êtes-vous sûr de vouloir supprimer ce fichier?',
    error: 'Une erreur est survenue',
    error_already_validated: 'Cette charge hôte est déjà validée',
    error_add: "Une erreur est survenue lors de l'ajout de charge hôte",
    error_delete: 'Une erreur est survenue lors de la suppression',
    error_validate: 'Une erreur est survenue lors de la validation',
    error_files_missing: "Le fichier n'existe pas",
    empty_motif_deplacement: "Les motifs de déplacement doivent être parametrés par l'admin",
    empty_raison_achat_hote: "Les raisons d'achat hôte doivent être parametrées par l'admin",
  },
  owner_charge: {
    list: 'Achats hôtes',
    empty: 'Aucun achat hôte validé',
    IN_PROGRESS: 'En cours',
    VALIDATED: 'Validé',
    DELETED: 'Supprimé',
    error_empty_date_transaction: "Impossible de valider une charge hôte sans date d'opération",
  },
  account: {
    showPositive: 'Soldes positifs',
    showNegative: 'Soldes négatifs',
    showZero: 'Soldes à zéro',
    number: 'Numéro de compte',
    empty: 'Aucune opération trouvée sur ce compte',
    progressive_balance: 'Solde progressif',
    consultation: 'Consultation',
    no_exist: 'Aucun compte trouvé',
    no_cancel_fee: "Aucun frais d'annulation pour ce mandat",
    no_operations: 'Aucunes opérations trouvées sur ce compte',
    no_mandat: "Un des mandats n'existe pas",
    already_exist: 'Le compte comptable saisie existe déjà',
    balance: 'Solde',
    third_party_creation_in_progress: 'Comptes de tiers en cours de création',
    fic_mandat_creation_in_progress: 'Registre des mandats en cours de création',
    fic_avenant_creation_in_progress: 'Registre des avenants en cours de création',
    account_creation_in_progress: 'Consultation en cours de création',
    no_account_number_or_accommodation: 'Le numéro de compte ou le logement doit être renseigné',
    all_third_party: 'Tous les comptes de tiers',
    enabled_only_third_party: 'Uniquement les comptes de tiers actifs',
    disabled_only_third_party: 'Uniquement les comptes de tiers inactifs',
    mandat_resiliated: 'Mandats résiliés',
  },
  visitor_tax: {
    visitor_tax: 'Taxes de séjour',
    tds: 'TDS',
    date: 'Date de réservation',
    number: 'Numéro de réservation',
    group: 'Groupe TDS',
    amount: 'Montant TDS',
    regional_amount: 'Montant TDS additionnelle',
    total: 'Total TDS',
    status: 'Etat',
    transfert_num: 'Numéro de virement',
    update: 'Virement de TDS',
    update_transfert_num: 'Num Virement',
    filter_group: 'Rechercher par groupe de TDS',
    filter_region: 'Rechercher par TDS additionnelle',
    filter_date: 'Rechercher par période',
    filter_status: 'Rechercher par état',
    filter_type: 'Rechercher par type',
    date_from: 'A partir du',
    date_to: "Jusqu'au",
    date_created: 'Date création',
    filter: 'Rechercher',
    total_reversed: 'Total reversé',
    total_not_reversed: 'Total en attente',
    state_reversed: 'Reversée',
    state_not_reversed: 'En attente',
    select_all: 'Tout sélectionner',
    submit: 'Générer virement',
    transfert: 'Virement de TDS',
    transferts: 'Virements',
    total_not_selected: 'Total',
    total_selected: 'Total selectionné',
    empty_stayrate_group: "Le groupe de TDS selectionné n'a pas de compte",
    error_stayrate_account: 'Le compte de la TDS ne correspond pas à celui du groupe de TDS',
    error_already_reversed: 'Une TDS selectionnée à déjà été reversée',
    error_empty_selection: 'Aucune TDS selectionnée dans ce lot',
    type: 'Type de TDS',
    details: 'Details des virements TDS',
    date_start: 'Date de début',
    date_end: 'Date de fin',
    date_checkin: 'Date de checkin',
    date_checkout: 'Date de checkout',
    period: 'Période',
    to: 'au',
    stayrate_group_added: 'Lot de TDS enregistré avec succès',
    error_not_same_group: 'Le lot selectionné appartient à plusieurs groupes de TDS différent',
    stayrate_export_in_progress: 'Export des TDS en cours',
    undefined: 'Non défini',
  },
  refund: {
    date: 'Date',
    refund: 'Rembourser',
    add: 'Rembourser',
    added: 'Remboursement ajouté',
    amountTotal: 'Total à rembourser au voyageur',
    amountNight: 'A rembourser pour la nuit',
    amountOta: 'A rembourser à la plateforme',
    change_amount: 'Le montant du remboursement à été modifié',
    change_amount_confirm: 'Le montant à rembourser à été modifié. Confirmer le remboursement?',
  },
  payment: {
    euros: '€',
    statut: 'Statut',
    refundStatut: 'Statut de remboursement',
    filter: 'Rechercher',
    filter_accommodation: 'Rechercher par logement',
    filter_avantio_localizer: 'Rechercher par numéro de réservation',
    filter_owner: 'Rechercher par hôte',
    filter_mandat: 'Rechercher par mandat',
    filter_state_reversed: 'Rechercher par état',
    reset_filter: 'Vider les filtres',
    total: 'Total TTC',
    remaining_payment: 'Reste à payer',
    no_payment: 'Aucun réglèment à afficher',
    next_operations: 'Opérations à venir',
    past_operations: 'Paiements effectués',
    fully_paid: 'Paiement complet',
    action: 'Action',
    actions: 'Actions',
    payment: 'Régler',
    add_payment: 'Nouveau réglement',
    reject_payment: 'Saisir un rejet de paiement',
    reject: 'Rejeter',
    reject_disclaimer1: 'Attention, montant du rejet',
    reject_disclaimer2: 'Pour un rejet partiel, merci de passer par une OD libre.',
    rejected_payment: 'Rejet de paiement enregistré',
    rejected_reason: 'Motif du rejet',
    rejected_amount: 'Montant du rejet',
    rejected_date: 'Date du rejet',
    add: 'Ajouter',
    added: 'Règlement ajouté',
    owner_tds: 'TDS hôte',
    guest_tds: 'TDS voyageur',
    guest_adom_tds: 'TDS Guest Adom',
    commission_ota: 'Commission OTA',
    housework: 'Frais de ménage',
    service: 'Frais de service',
    paid: 'Payé',
    paidByGuest: 'Payé par le voyageur',
    refundedToGuest: 'Remboursé au voyageur',
    amount: 'Montant',
    amount_to_refund: 'Montant à rembourser',
    amount_fee: 'Montant frais',
    amount_by_night: 'Montant des nuits',
    amount_by_night_percent: '30% montant de la nuit',
    amount_stayrate: 'Montant TDS',
    amount_owner_tds: 'Montant TDS hôte',
    amount_guest_tds: 'Montant TDS voyageur',
    amount_guest_adom_tds: 'Montant TDS GUEST ADOM',
    amount_tds_paid: 'Montant TDS payé',
    amount_owner_tds_paid: 'Montant TDS payé hôte',
    amount_guest_tds_paid: 'Montant TDS payé voyageur',
    amount_guest_adom_tds_paid: 'Montant TDS payé Guest Adom',
    amount_tds_total: 'Montant TDS total',
    amount_commission: 'Montant de la commission OTA',
    amount_housework: 'Montant total de ménage',
    extra: 'Extra',
    stripe_fee: 'Frais STRIPE',
    who: 'TDS payé par',
    extra_paid: 'Extra payé?',
    recurrence: 'Récurrence',
    monthly: 'Mensuelle',
    none: 'Aucune',
    date: 'Date',
    days_left_before_start: 'Jours avant résa',
    date_start_end: 'Date',
    source: 'Source',
    method: 'Mode de règlement',
    transfer_num: 'Numéro de virement',
    VACATION_CHECK: 'Chèque vacances',
    CREDIT_CARD: 'TPV Carte de crédit',
    VIRTUAL_CARD: 'Carte virtuelle',
    TRANSFERT: 'Virement',
    check: 'Chèque',
    stripe: 'Stripe',
    other: 'Autre',
    download: 'Télécharger',
    download_mandat: 'Télécharger le rapport des réservations',
    download_pdf: 'Télécharger PDF',
    download_excel: 'Excel',
    download_transfert: 'Exporter le virement',
    number_results: 'Nombre de résultat : ',
    state_reversed: 'Etat',
    update: 'Modifier',
    delete: 'Supprimer',
    delete_warning: 'Supprimer le réglement?',
    success_update: 'Transaction modifiée',
    error_no_payment_reservation: 'Aucun paiement à rejeter sur cette réservation',
    error_no_reservation: 'Aucune réservation trouvée',
    error_transaction_closed: 'Cette transaction est clôturée',
    notPay: 'Ne pas encaisser',
    not_pay_confirm_title: 'Non encaissement de la réservation',
    not_pay_confirm_message: 'Confirmer le non encaissement de la réservation',
  },
  bank_reconciliation: {
    error_cant_create: "Impossible de créer un nouveau rapprochement bancaire si le dernier n'est pas validé",
    empty_operation: 'Aucune opération dans ce rapprochement bancaire',
    empty_reconciliated: 'Aucune opération rapprochée',
    empty_not_reconciliated: 'Aucune opération à rapprocher',
    operations_flagged: 'Opérations mises à jour',
    operations_to_reconciliate: 'Ecritures à rapprocher',
    operations_reconciliated: 'Ecritures rapprochées',
    validate: 'Valider',
    details: 'Détails',
    difference: 'Ecart',
    update_reconciliation: 'Modifier',
    add_operation: 'Ajouter',
    add_operations: 'Ajouter des opérations',
    save_operations: 'Enregistrer',
    amount: 'Montant à rapprocher',
    amount_reconciliated: 'Montant déjà rapproché',
    remove_operation: "Supprimer l'opération",
    delete_warning: 'Supprimer le rapprochement ?',
    delete_reconciliation: 'Supprimer le rapprochement bancaire',
    success_update: 'Rapprochement bancaire modifié',
    success_delete: 'Rapprochement bancaire supprimé',
    success_remove_operation: 'Opération retirée du rapprochement bancaire',
    success_remove_operations: 'Opérations retirées du rapprochement bancaire',
    cant_delete: 'Impossible de supprimer un rapprochement bancaire validé',
    success_validate: 'Rapprochement bancaire validé',
    error_update: 'Un problème est survenue lors de la modification',
    error_details: 'Un problème est survenu lors de la récupération des opérations',
    error_wrong_amount: 'Le montant du rapprochement bancaire ne correspond pas aux opérations ajoutées',
    error_remove_operations: 'Un problème est survenu lors de la suppression des opérations',
    error_no_operations: "Aucunes opérations enregistrée dans ce rapprochement. Merci d'enregistrer avant de valider.",
    error_validate: 'Un problème est survenu lors de la validation',
    error_delete: 'Un problème est survenu lors de la suppression',
    error_empty_selected: 'Aucune opération sélectionnées',
    error_add: "Un problème est survenu lors de l'ajout d'opérations",
  },
  recapitulatif_facturation: {
    recapitulatif: 'Récapitulatif de facturation',
    partenaire: 'Partenaire',
    partenaire_GAV_HT: 'Partenaire',
    partenaire_GAV_TTC: 'Partenaire(TTC)',
    type: 'Type',
    gas_ht: 'Guest Adom',
    gas_ttc: 'Guest Adom (TTC)',
    total_ht: 'Total',
    total_ttc: 'Total (TTC)',
    display_not_closed: 'OD non clôturées',
    offer_all_inclusive: 'Offre Premium',
    offer_online: 'Offre Essentielle',
    label: 'Libellé',
    total_amount: 'Montant total',
    guest_adom: 'Guest adom',
    date: 'Date',
    add: 'Nouvelle grille tarifaire',
    added: 'Grille tarifaire ajoutée',
    update: 'Modifier la grille tarifaire',
    updated: 'Grille tarifaire modifiée',
    delete: 'Supprimer la grille tarifaire',
    deleted: 'Grille tarifaire supprimée',
    confirm_delete: 'Etes-vous sûr de vouloir supprimer la grille tarifaire ?',
    empty: 'Aucune donnée à afficher',

    achat_hote_type: 'Achat hôte (Type)',
    achat_hote_gas: 'Achat hôte (Guest adom)',
    achat_hote_gav: 'Achat hôte (Partenaire)',

    commission_type: 'Commission (Type)',
    commission_gas: 'Commission (Guest adom)',
    commission_gav: 'Commission (Partenaire)',

    commission_frais_annulation_hote_type: "Commission frais d'annulation hôte (Type)",
    commission_frais_annulation_hote_gas: "Commission frais d'annulation hôte (Guest adom)",
    commission_frais_annulation_hote_gav: "Commission frais d'annulation hôte (Partenaire)",

    commission_frais_annulation_voyageur_type: "Commission frais d'annulation voyageur (Type)",
    commission_frais_annulation_voyageur_gas: "Commission frais d'annulation voyageur (Guest adom)",
    commission_frais_annulation_voyageur_gav: "Commission frais d'annulation voyageur (Partenaire)",

    deep_cleaning_type: 'Deep cleaning (Type)',
    deep_cleaning_gas: 'Deep cleaning (Guest adom)',
    deep_cleaning_gav: 'Deep cleaning (Partenaire)',

    fermeture_hivernage_type: 'Fermeture hivernage (Type)',
    fermeture_hivernage_gas: 'Fermeture hivernage (Guest adom)',
    fermeture_hivernage_gav: 'Fermeture hivernage (Partenaire)',

    forfait_frais_annulation_hote_type: "Forfait frais d'annulation hote (Type)",
    forfait_frais_annulation_hote_gas: "Forfait frais d'annulation hote (Guest adom)",
    forfait_frais_annulation_hote_gav: "Forfait frais d'annulation hote (Partenaire)",

    forfait_verification_menage_hote_type: 'Forfait vérification ménage hôte (Type)',
    forfait_verification_menage_hote_gas: 'Forfait vérification ménage hôte (Guest adom)',
    forfait_verification_menage_hote_gav: 'Forfait vérification ménage hôte (Partenaire)',

    frais_accueil_type: "Frais d'accueil (Type)",
    frais_accueil_gas: "Frais d'accueil (Guest adom)",
    frais_accueil_gav: "Frais d'accueil (Partenaire)",

    frais_assurance_type: "Frais d'assurance (Type)",
    frais_assurance_gas: "Frais d'assurance (Guest adom)",
    frais_assurance_gav: "Frais d'assurance (Partenaire)",

    frais_blanchisserie_type: 'Frais de blanchisserie (Type)',
    frais_blanchisserie_gas: 'Frais de blanchisserie (Guest adom)',
    frais_blanchisserie_gav: 'Frais de blanchisserie (Partenaire)',

    frais_deplacement_type: 'Frais de déplacement (Type)',
    frais_deplacement_gas: 'Frais de déplacement (Guest adom)',
    frais_deplacement_gav: 'Frais de déplacement (Partenaire)',

    frais_lancement_type: 'Frais de lancement (Type)',
    frais_lancement_gas: 'Frais de lancement (Guest adom)',
    frais_lancement_gav: 'Frais de lancement (Partenaire)',

    frais_maintenance_type: 'Frais de maintenance (Type)',
    frais_maintenance_gas: 'Frais de maintenance (Guest adom)',
    frais_maintenance_gav: 'Frais de maintenance (Partenaire)',
    frais_maintenance_taux: 'Taux horaire de frais de maintenance',

    frais_menage_hote_type: 'Frais de ménage hôte (Type)',
    frais_menage_hote_gas: 'Frais de ménage hôte (Guest adom)',
    frais_menage_hote_gav: 'Frais de ménage hôte (Partenaire)',

    frais_menage_voyageur_type: 'Frais de ménage voyageur (Type)',
    frais_menage_voyageur_gas: 'Frais de ménage voyageur (Guest adom)',
    frais_menage_voyageur_gav: 'Frais de ménage voyageur (Partenaire)',

    frais_service_ga_type: 'Frais de service GA (Type)',
    frais_service_ga_gas: 'Frais de service GA (Guest adom)',
    frais_service_ga_gav: 'Frais de service GA (Partenaire)',

    frais_operationnel_type: 'Frais opérationnel (Type)',
    frais_operationnel_gas: 'Frais opérationnel (Guest adom)',
    frais_operationnel_gav: 'Frais opérationnel (Partenaire)',

    nuisance_sonore_type: 'Nuisance sonore (Type)',
    nuisance_sonore_gas: 'Nuisance sonore (Guest adom)',
    nuisance_sonore_gav: 'Nuisance sonore (Partenaire)',

    ouverture_hivernage_type: 'Ouverture hivernage (Type)',
    ouverture_hivernage_gas: 'Ouverture hivernage (Guest adom)',
    ouverture_hivernage_gav: 'Ouverture hivernage (Partenaire)',

    error_empty_filter: 'La période doit être renseigné',
    error_empty_filter_param: "L'agence doit être renseignée",
  },
  settings: {
    list: 'Paramètres',
    purchase_types: "Types d'achat",
    recapitulatif_facturation: 'Récapitulatif de facturation',
    achat_hote_types: "Types d'achat hôte",
    raison_achat_hote: "Raison d'achat hôte",
    motif_deplacement: 'Motif de déplacement',
    mandat_cluster_rm: 'Cluster RM',
    cancel_rule: "Règle d'annulation",
    cancel_rule_added: "Règle d'annulation ajoutée",
    cancel_rule_empty: "Aucune règle d'annulation",
    cancel_rule_updated: "La règle d'annulation à bien été modifiée",
    confirm_delete_cancel_rule: "Êtes-vous sûr de vouloir supprimer cette règle d'annulation?",
    ticket_category: 'Catégorie de ticket',
    ticket_category_added: 'Catégorie de ticket ajoutée',
    ticket_category_empty: 'Aucune catégorie de ticket',
    ticket_category_updated: 'La catégorie de ticket à bien été modifiée',
    add_ticket_category: 'Ajouter une catégorie de ticket',
    update_ticket_category: 'Modifier une catégorie de ticket',
    delete_ticket_category: 'Effacer une catégorie de ticket',
    confirm_delete_ticket_category: 'Êtes-vous sûr de vouloir supprimer cette catégorie de ticket?',
    error_empty_field: 'Tous les champs sont requis',
    societe_guestadom: 'Société Guestadom',
    extra_types: "Types d'extra",
    origin_marketing: 'Origine marketing',
    profile_client: 'Profil voyageur',
    forme_juridique: 'Forme juridique',
    stocks: 'Stocks',
    zones: 'Zones',
    tasks: 'Tâches',
    taskgroups: 'Groupes de tâches',
    cdcmodels: 'Modèles de CDC',
    mandats: 'Registre des mandats',
    motifrefus: 'Motifs de refus',
    resiliation_reason: 'Raisons de résiliation',
    document_model: 'Modèles de documents',
    document_balance: 'Balance',
    poles: 'Poles',
    mandat_num: 'Numéro de mandat',
    mandat_num_next: 'Prochain numéro de mandat',
    mandat_num_updated: 'Numéro de mandat mis à jour',
    mandats_empty: 'Aucun mandats de gestion',
    avenants_empty: 'Aucun avenants',
    mandat_edit_num: 'Editer le numéro',
    jobs: 'Postes',
    geosector: 'Secteurs géographiques d’intervention',
    filetype: 'Types de fichier',
    contracts: 'Contrats',
    epitype: "Types d'EPI",
    infos_mandat: 'Informations mandat',
    travel_expenses: 'Frais de déplacement',
    description: 'Description',
  },
  sub_category: {
    create: 'Ajouter une sous-catégorie',
    sub_category: 'Sous-catégorie',
    added: 'Sous-catégorie ajoutée',
    updated: 'Sous-catégorie modifiée',
    delete_sub_category: 'Effacer une sous-catégorie',
    confirm_delete_sub_category: 'Êtes-vous sûr de vouloir supprimer cette sous-catégorie ?',
  },
  travel_expenses: {
    amount: 'Taux horaire',
  },
  stock: {
    stock: 'Stock',
    label: 'Libellé',
    details: 'Fiche de stock',
    empty: 'Aucun stock',
    add: 'Ajouter un stock',
    added: 'Le stock a bien été ajouté',
    update: 'Modifier un stock',
    updated: 'Le stock a bien été modifié',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer ce stock ?',
  },
  zone: {
    zone: 'Zone',
    zones: 'Zones',
    label: 'Libellé',
    type: 'Type',
    subzone: 'Sous-zone',
    subzones: 'Sous-zones',
    details: 'Fiche de zone',
    empty: 'Aucune zone',
    add: 'Ajouter une zone',
    added: 'La zone a bien été ajoutée',
    update: 'Modifier une zone',
    updated: 'La zone a bien été modifiée',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer cette zone ?',
    zoneType_zone: 'Zone',
    zoneType_subzone: 'Sous-zone',
  },
  job: {
    job: 'Poste',
    label: 'Libellé',
    empty: 'Aucun poste',
    add: 'Ajouter un poste',
    added: 'Le poste a bien été ajouté',
    update: 'Modifier un poste',
    updated: 'Le poste a bien été modifié',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer ce poste ?',
  },
  restriction: {
    restriction: 'Restriction',
    file: 'Fichier',
    restrictionType: 'Type',
    empty: 'Aucune restriction',
    add: 'Ajouter une restriction',
    added: 'La restriction a bien été ajoutée',
    update: 'Modifier une restriction',
    updated: 'La restriction a bien été modifiée',
    deleted: 'La restriction a bien été supprimée',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer cette restriction ?',
    startDate: 'Date début',
    endDate: 'Date fin',
    details: 'Précisez',
    value: 'Valeur',
    restriction1: "Nombre maximum d'heures",
    restriction2: 'Mi-temps',
    restriction3: 'Pas de port de charges',
    restriction4: "Pas d'escaliers",
    restriction5: "Pas de contact avec les produits d'entretien",
    restriction6: "Pas d'utilisation de machines (type mono brosse)",
    restriction7: 'Pas de travail le WE et jours fériés',
    restriction8: 'Pause obligatoire',
    restriction9: 'Aménagement du poste de travail',
    restriction10: 'EPI (Equipement de Protection Individuel)',
    restriction11: 'Autre',
  },
  ppe: {
    ppe: 'Équipement de protection individuelle',
    label: 'Libellé',
    empty: 'Aucun équipement',
    add: 'Ajouter un équipement',
    added: "L'équipement a bien été ajouté",
    update: 'Modifier un équipement',
    updated: "L'équipement a bien été modifié",
    deleted: "L'équipement a bien été supprimé",
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer cet équipement ?',
    deliveryDate: 'Date de remise',
    quantity: 'Quantité',
    epiType: 'Type',
  },
  absence: {
    absence: 'Absence',
    file: 'Fichier',
    reason: 'Motif',
    empty: 'Aucune absence',
    add: 'Ajouter une absence',
    update: 'Modifier une absence',
    added: "L'absence a bien été ajoutée",
    updated: "L'absence a bien été modifiée",
    deleted: "L'absence a bien été supprimée",
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer cette absence ?',
    startDate: 'Date début',
    startTime: 'Heure début',
    endDate: 'Date fin',
    endTime: 'Heure fin',
    replace: 'Remplacer?',
    substitute: 'Remplaçant',
    comment: 'Commentaire',
    abs1: 'Accident de travail',
    abs2: 'Maladie non professionnelle',
    abs3: 'Congés de deuil',
    abs4: 'Maladie enfant',
    abs5: 'Maladie grave enfant',
    abs6: 'Maternité',
    abs7: 'Congés Payés',
    abs8: 'Absence légale autorisée sans retenue',
    abs9: 'Autre absence légale avec retenue',
    abs10: 'Absence non rémunérée (autorisée)',
    abs11: 'Absence non rémunérée (non autorisée)',
    abs12: 'Absence rémunérée',
    abs13: 'Fermeture de site',
    abs14: 'Accident de trajet',
    abs15: 'Accident de travail',
    abs16: 'Congé pathologique post-natal',
    abs17: 'Congé pathologique pré-natal (14 jours)',
    abs18: 'Femme enceinte dispensée de travail',
    abs19: 'Hospitalisation Accident de trajet',
    abs20: 'Hospitalisation AT',
    abs21: 'Hospitalisation Maladie non professionnelle',
    abs22: 'Invalidité catégorie 1',
    abs23: 'Invalidité catégorie 2',
    abs24: 'Invalidité catégorie 3',
    abs25: 'Longue maladie (secteur public)',
    abs26: 'Maladie longue durée (secteur public)',
    abs27: 'Maladie non professionnelle',
    abs28: 'Maladie non professionnelle (ALD)',
    abs29: 'Maladie non professionnelle (IJSSAT/multi-employeurs)',
    abs30: 'Maladie professionnelle',
    abs31: 'Temps partiel thérapeutique',
    abs32: 'Temps partiel thérapeutique (AT)',
    abs33: 'Absence événement familial (sans retenue)',
    abs34: 'Adoption',
    abs35: 'Congé conventionnel / maintien partiel',
    abs36: 'Congé conventionnel / maintien total',
    abs37: 'Congé de présence parentale',
    abs38: 'Congé de proche aidant',
    abs39: 'Congé de solidarité familiale',
    abs40: 'Congé parental',
    abs41: 'Congés de deuil',
    abs42: 'Maladie enfant',
    abs43: 'Maladie grave conjoint',
    abs44: 'Maladie grave enfant',
    abs45: 'Maternité',
    abs46: 'Paternité',
    abs47: 'Congés Payés',
    abs48: 'CP supplémentaires',
    abs49: 'CP supplémentaires 2',
    abs50: 'Férié chômé',
    abs51: 'Heure banque',
    abs52: 'Repos compensateur complémentaire',
    abs53: 'Repos compensateur de remplacement',
    abs54: 'Repos compensateur obligatoire',
    abs55: 'Repos forfait jour',
    abs56: 'RTT',
    abs57: 'Absence Formation en Alternance',
    abs58: 'Absence Formation non rémunérée',
    abs59: 'Congé de mobilité (au-delà du préavis)',
    abs60: 'Congé de reclassement (au-delà du préavis)',
    abs61: 'Congé de reconversion',
    abs62: 'Congé de restructuration',
    abs63: 'Congé formation rémunérée',
    abs64: 'Congé individuel de formation',
    abs65: 'Congé pour bilan de compétences',
    abs66: 'Congé pour formation syndicale',
    abs67: 'Congé pour VAE',
    abs68: 'Absence légale autorisée sans retenue',
    abs69: 'Absence pour Représentation du personnel',
    abs70: 'Autre absence légale avec retenue',
    abs71: 'Congé sabbatique',
    abs72: 'Mobilité volontaire sécurisée',
    abs73: 'Solidarité internationale',
    abs74: 'Absence non rémunérée (autorisée)',
    abs75: 'Absence non rémunérée (non autorisée)',
    abs76: 'Absence rémunérée',
    abs77: 'Activité partielle (réduction du teams)',
    abs78: 'Activité partielle (suspension intégrale)',
    abs79: 'Activité partielle/ formation',
    abs80: 'Cessation concertée de travail (grève)',
    abs81: 'Chômage intempérie',
    abs82: 'Chômage partiel (activité partielle de longue durée)',
    abs83: 'Chômage partiel congés payés',
    abs84: 'Congés Sans Solde',
    abs85: 'Détention provisoire',
    abs86: 'Férié chômé non payé',
    abs87: 'Fermeture de site',
    abs88: 'Mise à pied conservatoire',
    abs89: 'Mise à pied disciplinaire',
    abs90: 'Préavis non effectué',
    abs91: 'Préavis non effectué payé',
    abs92: 'Préavis non effectué payé (congé de mobilité)',
    abs93: 'Préavis non effectué payé (congé de reclassement)',
    abs94: 'Autre',
  },
  unavailability: {
    unavailability: 'Indisponibilité fixe',
    label: 'Libellé',
    type: 'Type',
    comment: 'Commentaire',
    empty: 'Aucune indisponibilité',
    manage: 'Gérer les indisponibilités',
    add: 'Nouvelle réservation hôte',
    edit: 'Modifier la réservation hôte',
    remove: 'Supprimer une indisponibilité',
    change: 'Changer une indisponibilité',
    added: "L'indisponibilité a bien été ajoutée",
    update: 'Modifier une indisponibilité',
    updated: "L'indisponibilité a bien été modifiée",
    deleted: 'Réservation annulée avec succés',
    confirm_delete:
      'Attention, vous souhaitez supprimer une réservation hôte. Souhaitez-vous confirmer cette suppression ?',
    impossible_to_cancel:
      "Une erreur est survenue durant l'annulation, rapprochez-vous de votre conciergerie locale pour plus d'informations",
    add_success: 'Votre demande de réservation hôte a été prise en compte.',
    edit_success: 'Votre demande de modification de réservation hôte a bien été prise en compte.',
    in_progress: 'Réservation en cours de création',
    wholeDay: 'Journée entière',
    dateStart: 'Date de début',
    dateEnd: 'Date de fin',
    hourCheckin: 'Heure de check-in',
    hourCheckout: 'Heure de check-out',
    startDateNew: 'Nouvelle date de début',
    endDateNew: 'Nouvelle date de fin',
    timeFrom: 'De',
    timeTo: 'À',
    weeksNbr1: 'Toutes les',
    weeksNbr2: 'semaine(s), le',
    monday: 'L',
    tuesday: 'Ma',
    wednesday: 'Me',
    thursday: 'J',
    friday: 'V',
    saturday: 'S',
    sunday: 'D',
    days: 'Jours',
  },
  employee_unavailability: {
    unavailability: 'Indisponibilité fixe',
    label: 'Libellé',
    type: 'Type',
    comment: 'Commentaire',
    empty: 'Aucune indisponibilité fixe',
    manage: 'Gérer les indisponibilités fixes',
    add: 'Nouvelle indisponibilité fixe',
    edit: "Modifier l'indisponibilité fixe",
    remove: 'Supprimer une indisponibilité fixe',
    change: 'Changer une indisponibilité fixe',
    added: "L'indisponibilité fixe a bien été ajoutée",
    update: 'Modifier une indisponibilité fixe',
    updated: "L'indisponibilité fixe a bien été modifiée",
    deleted: 'Indisponibilité fixe annulée avec succés',
    confirm_delete:
      'Attention, vous souhaitez supprimer une indisponibilité fixe. Souhaitez-vous confirmer cette suppression ?',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    startDateNew: 'Nouvelle date de début',
    endDateNew: 'Nouvelle date de fin',
    wholeDay: 'Journée entière',
    timeFrom: 'De',
    timeTo: 'À',
    weeksNbr1: 'Toutes les',
    weeksNbr2: 'semaine(s), le',
    monday: 'L',
    tuesday: 'Ma',
    wednesday: 'Me',
    thursday: 'J',
    friday: 'V',
    saturday: 'S',
    sunday: 'D',
    days: 'Jours',
  },
  contract_settings: {
    contract: 'Contrat',
    amendment: 'Avenant',
    amendment_add: 'Ajouter un avenant',
    type: 'Type',
    label: 'Libellé',
    empty: 'Aucun contrat',
    add: 'Ajouter un contrat',
    added: 'Le contrat a bien été ajouté',
    added_amendment: "L'avenant a bien été ajouté",
    update: 'Modifier un contrat',
    updated: 'Le contrat a bien été modifié',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer ce contrat/avenant ?',
    confirm_contract_delete: 'Êtes-vous sûr de vouloir supprimer ce contrat ?',
    deleted: 'Le contract/avenant a bien été supprimé',
  },
  geosector: {
    geosector: 'Secteur géographique d’intervention',
    label: 'Libellé',
    empty: 'Aucun secteur géographique',
    add: 'Ajouter un secteur géographique',
    added: 'Le secteur géographique a bien été ajouté',
    update: 'Modifier un secteur géographique',
    updated: 'Le secteur géographique a bien été modifié',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer ce secteur géographique ?',
  },
  filetype: {
    filetype: 'Type de fichier',
    label: 'Libellé',
    empty: 'Aucun type de fichier',
    add: 'Ajouter un type de fichier',
    added: 'Le type de fichier a bien été ajouté',
    update: 'Modifier un type de fichier',
    updated: 'Le type de fichier a bien été modifié',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer ce type de fichier ?',
  },
  epitype: {
    epitype: "Type d'EPI",
    label: 'Libellé',
    empty: "Aucun type d'EPI",
    add: "Ajouter un type d'EPI",
    added: "Le type d'EPI a bien été ajouté",
    update: "Modifier un type d'EPI",
    updated: "Le type d'EPI a bien été modifié",
    confirm_delete: "Êtes-vous sûr de vouloir supprimer ce type d'EPI ?",
  },
  contract: {
    add: 'Ajouter un contrat',
    contract: 'Contrat',
    file: 'Fichier',
    amendment_pattern: 'Motif avenant',
    added: 'Le contrat a bien été ajouté',
    startDate: 'Date début',
    endDate: 'Date fin',
    endReason: 'Motif de fin',
    startingEmploymentReason: 'Motif début emploi',
    startTime: 'Horaire de début',
    trialPeriodDuration: 'Durée période essai',
    trialEndDate: 'Date fin essai',
    occupationClassification: 'Classification métier',
    employmentNature: "Nature de l'emploi",
    grossHourlyTx: 'Tx horaire brut',
    conventionalMinimumWage: 'Salaire minimum conventionnel',
    doNotTransmitTheRate: 'Ne pas transmettre le taux',
    endReason1: 'Changement de situation administrative du salarié ou de l’assuré',
    endReason2: 'Congé de présence parentale',
    endReason3: 'Congé de solidarité familiale',
    endReason4: 'Congé demi solde',
    endReason5: 'Congé parental d’éducation',
    endReason6:
      'Congé sabbatique, disponibilité, congé sans traitement de l’agent public stagiaire, fonctionnaire hors cadre',
    endReason7: 'Congé sans solde supérieur ou égal à 30 jours consécutifs',
    endReason8: 'Continuité d’activité en fin de période',
    endReason9: 'Convention de conversion',
    endReason10: 'Créateur d’entreprise',
    endReason11: 'Décès, disparition',
    endReason12: 'Démission',
    endReason13: 'Départ en détachement',
    endReason14: 'Départ volontaire à la retraite',
    endReason15: 'Evènements prévoyance (réservé aux déclarations prévoyance événementielles)',
    endReason16:
      'Fin de contrat de travail, fin d’activité, fin de détachement, perte de la qualité culturelle (cultes)',
    endReason17: 'Fin de période d’apprentissage dans le cadre d’un CDI',
    endReason18: 'Fin de période pré retraite IEG (réservé échanges inter-organismes)',
    endReason19: 'Fin de période RATP (réservé échanges inter organisme)',
    endReason20: 'Licenciement',
    endReason21: 'Mise a la retraite d’office à l’initiative de l’employeur',
    endReason22: 'Paiement des congés payé',
    endReason23: 'Rupture conventionnelle du contrat de travail',
    endReason24:
      'Salarié quittant ou ayant quitté l’entreprise (sommes versées dont indemnités de non concurrence pour l’Agirc)',
    endReason25: 'Service national volontaire (y compris volontaires du service civique)',
    endReason26: 'Sortie d’activité d’un retraité cumulant retraite et activité',
    endReason27: 'Suppression du contrat de travail',
    daysNumber: 'Nb Jours / Semaine',
    monday: 'L',
    tuesday: 'Ma',
    wednesday: 'Me',
    thursday: 'J',
    friday: 'V',
    workedDays: 'Jours travaillés',
    signed: 'Signé',
    signatureDate: 'Date de signature',
    startPattern1:
      "embauche, début d'activité, recrutement direct ou sur concours (fonction publique), début de détachement, début",
    startPattern2: "reprise d'activité suite à suspension du contrat de travail",
    startPattern3: 'congés sans solde supérieur ou égal à 30 jours consécutifs',
    startPattern4: 'congé demi solde',
    startPattern5: 'service national volontaire (y compris volontaires du service civique)',
    startPattern6: 'paiement des congés payés',
    startPattern7: "congé parental d'éducation",
    startPattern8: "embauche d'un retraité reprenant une activité",
    startPattern9: "salarié quittant ou ayant quitté l'entreprise (sommes versées dont indemnités de non concurrence)",
    startPattern10: "continuité d'activité en début de période",
    startPattern11: 'congés de solidarité familiale',
    startPattern12: 'congés de présence parentale',
    startPattern13: 'début de période IEG pensions (réservé aux échanges inter organismes)',
    startPattern14: 'congé sabbatique',
    startPattern15: 'début de période RATP',
    startPattern16: 'évènement prévoyance',
    startPattern17: 'retour de détachement',
    startPattern18: "changement de situation administrative du salarié ou de l'assuré",
    startPattern19: "début de période d'apprentissage dans le cadre d'un CDI",
    occupation1: 'Agent d’entretien petits travaux de bricolage',
    occupation2: 'Agent d’entretien petits travaux de jardinage',
    occupation3: 'Assistant(e) de vie 1',
    occupation4: 'Garde d’enfant(s) 1',
    occupation5: 'Assistant(e) ménager(e) 1',
    occupation6: 'Garde d’enfant(s) 2',
    occupation7: 'Assistant(e) ménager(e) 2',
    occupation8: 'Assistant(e) de vie 2',
    occupation9: 'Garde d’enfant(s) 3',
    occupation10: 'Assistant(e) de vie 3',
    amendmentReason1: '10-Courrier augmentation taux horaire',
    amendmentReason2: '11-Modification de la durée annuelle de travail',
    amendmentReason3: '5-Avenant de transformation du CDI temps partiel mensualisé à annualisé réel',
    amendmentReason4: '6-Avenant de transformation du CD2i à annualisé réel',
    amendmentReason5: '7-Annexe de mise à disposition du véhicule déplacement professionnel',
    amendmentReason6: '8-Annexe de mise à disposition du véhicule professionnel domicile – travail',
    amendmentReason7: '9-Annexe de mise à disposition du téléphone portable',
  },
  subzone: {
    empty: 'Aucune sous-zone',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer cette sous-zone ?',
    updated: 'La sous-zone a bien été modifiée',
    added: 'La sous-zone a bien été ajoutée',
  },
  motifrefus: {
    item: 'Motif de refus',
    empty: 'Aucun motif de refus',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer ce motif de refus ?',
    update: 'Modifier un motif de refus',
    updated: 'Le motif de refus a bien été modifié',
    add: 'Ajouter un motif de refus',
    added: 'Le motif de refus a bien été ajouté',
    delete: 'Supprimer un motif de refus',
  },
  resiliation_reason: {
    list: 'Raisons de résiliation',
    item: 'Raison de résiliation',
    empty: 'Aucune raison de résiliation',
    update: 'Modifier une raison de résiliation',
    updated: 'La raison de résiliation a bien été modifiée',
    add: 'Ajouter une raison de résiliation',
    added: 'La raison de résiliation a bien été ajoutée',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer cette raison de résiliation ?',
    delete: 'Supprimer une raison de résiliation',
  },
  document_model: {
    item: 'Modéle de document',
    empty: 'Aucun modéle de document',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer ce modéle de document ?',
    update: 'Modifier un modéle de document',
    updated: 'Le modéle de document a bien été modifié',
    add: 'Ajouter un modéle de document',
    added: 'Le modéle de document a bien été ajouté',
    delete: 'Supprimer un modéle de document',
    name: 'Nom du modéle',
    type: 'Type',
    subtype: 'Sous-type',
    file: 'Modéle Word',
    updateThis: 'Modifier',
    working_contract: 'Générer le contrat de travail',
    in_progress: 'Génération du document en cours',
  },
  pole: {
    item: 'Pole',
    list: 'Poles',
    name: 'Nom',
    technicalKey: 'Clé technique',
    empty: 'Aucun pole',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer ce pole ?',
    update: 'Modifier un pole',
    updated: 'Le pole a bien été modifié',
    add: 'Ajouter un pole',
    added: 'Le pole a bien été ajouté',
    delete: 'Supprimer un pole',
    error_pole_owner: 'Une erreur est survenue lors de la récupération du pole Hôte',
  },
  product: {
    label: 'Libellé',
    type: 'Type',
    quantity: 'Quantité',
    products: 'Produits du kit',
    details: 'Fiche de produit',
    empty: 'Aucun produit',
    list: 'Produits',
    add: 'Ajouter un produit',
    update: 'Modifier un produit',
    updateThis: 'Modifier',
    added: 'Le produit a bien été ajouté',
    updated: 'Le produit a bien été modifié',
    deleted: 'Le produit a bien été supprimé',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer ce produit ?',
    productType_consumable: 'Consommable',
    productType_extra: 'Extra',
    productType_kit: 'Kit',
  },
  prestataire: {
    prestataire: 'Prestataire',
    title: 'Prestataires',
    status_available: 'Disponible',
    status_unavailable: 'Indisponible',
    status_in_mission: 'En mission',
    create: 'Créer un prestataire',
    filter: 'Filtrer',
    search: 'Recherche',
    civility: 'Civilité',
    phone: 'Numéro de téléphone',
    availability: 'Disponibilité',
    lastName: 'Nom',
    firstName: 'Prénom',
    email: 'E-mail',
    enabled: 'Actif',
    company: 'Conciergerie locale',
  },
  prestation: {
    filter: 'Filtrer',
    label: 'Libellé',
    type: 'Type',
    statusRealisation: 'Statut de réalisation',
    status: 'Statut de la mission',
    assigned_staff: 'Personnel affecté',
    dateStart: 'Date de début',
    dateEnd: 'Date de fin',
    timeStart: 'Heure de début',
    timeEnd: 'Heure de fin',
    date: "Date de création de l'ordre",
    schedule: 'Horaires',
    company: 'Agence',
    accommodation_name: 'Nom du logement',
    date_checkin: 'Date de check-in',
    date_checkout: 'Date de check-out',
    details: 'Fiche de prestation',
    empty: 'Aucune prestation',
    list: 'Prestations',
    add: 'Ajouter une prestation',
    update: 'Modifier une prestation',
    updateThis: 'Modifier',
    added: 'La prestation a bien été ajoutée',
    updated: 'La prestation a bien été modifiée',
    deleted: 'La prestation a bien été supprimée',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer cette prestation ?',
    prestationType_check_in: 'Check-in',
    prestationType_check_out: 'Check-out',
    prestationType_cleaning: 'Ménage interlocatif',
    prestationType_cleaning_before: 'Ménage retour hôte',
    prestationType_cleaning_after: 'Ménage départ hôte',
    prestationType_running: 'Running',
    prestationType_quality_control: 'Contrôle qualité',
    prestationType_maintenance: 'Maintenance',
    status_accepted: 'Acceptée',
    status_proposed: 'Proposée',
    status_partially_accepted: 'Partiellement Acceptée',
    status_to_plan: 'A planifier',
    status_todo: 'Planifiée',
    status_to_do: 'A faire',
    status_doing: 'En cours',
    status_completed: 'Terminée',
    status_canceled: 'Annulée',
    status_partially_canceled: 'Partiellement annulée',
    status_litigation: 'Litige',
  },
  partenaire: {
    title: 'Partenaires',
    name: 'Nom du partenaire',
    details: 'Fiche partenaire',
    create: 'Créer un partenaire',
    update: 'Modifier le partenaire',
    list: 'Partenaires',
    updated: 'Le partenaire a bien été modifié',
    enabled: 'Le partenaire a bien été activé',
    enabledState: 'Actif / Inactif',
    disabled: 'Le partenaire a bien été désactivé',
    accessSent: 'Les accès ont été envoyés',
    representantLastName: 'Nom du représentant',
    representantFirstName: 'Prénom du représentant',
    empty: 'Aucun partenaire',
    updateThis: 'Modifier',
    createAccess: "Créer l'accès",
    resendAccess: "Renvoyer l'accès",
    accommodation: 'Logement',
    company: 'Conciergerie locale',
    errorNoId: "Ce partenaire n'a pas de compte utilisateur",
    accommodation_number: 'Nombre de logements',
  },
  housekeeper: {
    title: 'Concierges',
    name: 'Nom du concierge',
    details: 'Fiche concierge',
    create: 'Créer un concierge',
    update: 'Modifier le concierge',
    list: 'Concierges',
    updated: 'Le concierge a bien été modifié',
    enabled: 'Le concierge a bien été activé',
    enabledState: 'Actif / Inactif',
    disabled: 'Le concierge a bien été désactivé',
    accessSent: 'Les accès ont été envoyés',
    representantLastName: 'Nom du représentant',
    representantFirstName: 'Prénom du représentant',
    empty: 'Aucun concierge',
    updateThis: 'Modifier',
    createAccess: "Créer l'accès",
    resendAccess: "Renvoyer l'accès",
    accommodation: 'Logement',
    company: 'Conciergerie locale',
    errorNoId: "Ce concierge n'a pas de compte utilisateur",
    accommodation_number: 'Nombre de logements',
  },
  employee: {
    files: 'Pièces jointes',
    add_file: 'Ajouter une pièce jointe',
    file_added: 'Pièce jointe ajoutée',
    file_deleted: 'Pièce jointe supprimée',
    file_confirm_delete: 'Êtes-vous sûr de vouloir supprimer cette pièce jointe ?',
    activity: 'Activités',
    contracts: 'Contrats/Avenants',
    civility: 'Civilité',
    firstName: 'Prénom',
    lastName: 'Nom',
    type: 'Type',
    maidenName: 'Nom de jeune fille',
    company: 'Agence',
    color: 'Couleur',
    poles: 'Poles',
    roleKey: 'Poste',
    roleType: 'Type de rôle',
    userEnabled: 'Actif',
    email: 'E-mail',
    details: 'Fiche de salarié',
    empty: 'Aucun salarié',
    one: 'Salarié',
    list: 'Salariés',
    add: 'Ajouter un salarié',
    update: 'Modifier un salarié',
    updateThis: 'Modifier',
    added: 'Le salarié a bien été ajouté',
    updated: 'Le salarié a bien été modifié',
    deleted: 'Le salarié a bien été supprimé',
    enabled: 'Le salarié a bien été activé',
    disabled: 'Le salarié a bien été désactivé',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer ce salarié ?',
    birthdate: 'Date de naissance',
    birthPlace: 'Lieu de naissance',
    birthDepartment: 'Département de naissance',
    birthCountry: 'Pays de naissance',
    maritalStatus: 'Situation familiale',
    childrenNumber: "Nombre d'enfants",
    source: 'Source de connaissance',
    address: 'Adresse',
    additionalAddress: 'Complément d’adresse',
    postalCode: 'Code postal',
    country: 'Pays',
    city: 'Ville',
    phoneNumber: 'Numéro de fixe',
    mobileNumber: 'Numéro de mobile',
    personalInformation: 'Informations personnelles',
    contractInformation: 'Informations Contrat/Avenant',
    contact: 'Coordonnées',
    identityDocument: 'Justificatif d’identité',
    idNumber: 'Numéro du justificatif d’identité',
    idIssuedAt: 'Délivré à',
    idValidUntil: "Valide jusqu'au",
    nationality: 'Nationalité',
    socialSecurityNumber: 'Numéro de sécurité sociale',
    drivingPermit: 'Permis de conduire',
    drivingPermitNumber: 'Numéro de permis de conduire',
    drivingPermitObtainingDate: "Date d'obtention du permis",
    drivingPermitValidityDate: 'Date de validité du permis',
    registeredEmploymentCenter: 'Inscrit au pôle emploi',
    employmentCenterMembershipNumber: "Numéro d'adhésion au pôle emploi",
    professionalInformation: 'Informations professionnelles',
    seniorityDate: 'Date d’ancienneté',
    entryDate: 'Date d’entrée',
    entryHour: 'Heure d’entrée',
    dpaeNumber: 'Numéro DPAE',
    lastMedicalVisitDate: 'Date de la dernière visite médicale',
    nextMedicalVisitDate: 'Date de la prochaine visite médicale',
    usualTransportMode: 'Mode de transport habituel',
    personalVehicle: 'Véhicule personnel',
    companyVehicle: 'Véhicule de société',
    bicycle: 'Vélo',
    onFoot: 'À pied',
    publicTransport: 'Transport en commun',
    subscribedToComplementaryHealth: 'Adhère à la complémentaire santé',
    motif: 'Motif',
    iban: 'IBAN',
    bic: 'BIC',
    handicappedWorkerRecognition: 'Reconnaissance travailleur handicapé',
    recognitionGrantedByTheCDAPH: 'Reconnaissance attribuée par la CDAPH',
    rqth: 'RQTH',
    aah: 'AAH',
    disabilityCard: "Carte d'invalidité (min. 80%)",
    validityStartDate: 'Date de début de validité',
    validityEndDate: 'Date de fin de validité',
    renewalApplicationSubmissionDate: 'Date de dépôt de la demande de renouvellement',
    disabilityRate: "Taux d'incapacité",
    disabilityCauses: "Victime d'accident du travail, de maladie professionnelle ou invalide pensionné",
    typology: 'Typologie',
    disabilityValidityStartDate: 'Date de début de validité',
    IPPRate: "Taux d'IPP",
    category: 'Catégorie',
    otherCategories: 'Autres catégories',
    otherCategory: 'Autre catégorie',
    pensionStartDate: 'Date de début de pension',
    skills: 'Compétences',
    languages: 'Langues',
    graduate: 'Diplômé',
    diplomas: 'Diplômes',
    married: 'Marié(e)',
    divorced: 'Divorcé(e)',
    widower: 'Veuf/Veuve',
    single: 'Célibataire',
    maritalLife: 'Vie maritale',
    pacs: 'Pacsé(e)',
    separated: 'Séparé(e)',
    typology1: 'Accident du travail / Maladie professionnelle',
    typology2: 'Invalide pensionné',
    otherCategory1: 'Mutilé de guerre',
    otherCategory2: 'Assimilé mutilé de guerre',
    otherCategory3: 'Sapeur-Pompier volontaire',
    motif1: "Vous avez déjà une mutuelle en tant qu'ayant droit (couverture collective)",
    motif2: 'Vous avez déjà une mutuelle (complémentaire individuelle)',
    motif3: "Vous êtes déjà dans l'entreprise lors de la mise en place du dispositif",
    motif4: "Vous bénéficiez de la CMUC ou de l'ACS",
    motif5: 'Vous êtes salarié à temps très partiel',
    motif6: "Vous êtes en CDD ou en contrat de mission (jusqu'à 3 mois)",
    motif7: 'Vous êtes en CDD ou en contrat de mission (entre 3 mois et 1 an)',
    motif8: "Vous êtes en CDD ou en contrat de mission d'1 an ou plus",
    motif9: 'Vous êtes apprenti',
    category1: 'Première catégorie',
    category2: 'Deuxième catégorie',
    category3: 'Troisième catégorie',
    job: 'Poste',
    multiEmployer: 'Multi-employeurs',
    employer1: 'Employeur 1',
    employer2: 'Employeur 2',
    employer3: 'Employeur 3',
    employer4: 'Employeur 4',
    employer5: 'Employeur 5',
    duration: 'Durée',
    geosector: 'Secteurs géographiques d’intervention',
    filetype: 'Type de fichier',
    commitmentEndDate: "Date de fin d'engagement",
    bankingInformation: 'Informations bancaires',
    unavailability_add: 'Ajouter une indisponibilité',
  },
  client: {
    details: 'Fiche voyageur',
    empty: 'Aucun voyageur',
    list: 'Voyageurs',
    add: 'Ajouter un voyageur',
    update: 'Modifier un voyageur',
    updateThis: 'Modifier',
    added: 'Le voyageur a bien été ajouté',
    updated: 'Le voyageur a bien été modifié',
    deleted: 'Le voyageur a bien été supprimé',
    disabled: 'Le voyageur a bien été désactivé',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer ce voyageur ?',
    type: 'Type',
    profile: 'Profil voyageur',
    originMarketing: 'Origine marketing',
    spokenLanguages: 'Langues parlées',
    phone: 'Mobile',
    phone2: 'Mobile 2',
    phoneFix: 'Numéro téléphone fixe',
    email: 'Email',
    name: 'Nom et prénom',
    newsletter: 'Newsletters',
    enabled: 'Actif',
    blocked: 'Bloqué',
    title_general: 'Informations générales',
    title_invoice: 'Informations de facturation',
    title_society: 'Societé',
    title_individual: 'Particulier',
    title_address: 'Adresse',
    civility: 'Civilité',
    firstName: 'Prénom',
    lastName: 'Nom',
    birthDate: 'Date de naissance',
    companyName: "Nom de l'entreprise",
    formeJuridique: 'Forme juridique',
    siren: 'SIREN',
    numTva: 'Numéro TVA intracommunautaire',
    companyOwner: 'Représentant de l’entreprise',
    address: 'Adresse postale',
    complement: "Complement d'adresse",
    city: 'Ville',
    postalCode: 'Code postal',
    country: 'Pays',
    state: 'Etat',
    province: 'Province',
    autorisationPrel: 'Autorisation de prélèvement',
    accountHolder: 'Titulaire du compte',
    accountIban: 'IBAN',
    accountBic: 'BIC',
    reservation: 'Réservations',
    activity: 'Activités',
    litigation: 'Litiges',
    claiming: 'Réclamations',
    referrer: 'Référents',
    files: 'Pièces jointes',
  },
  item_label: {
    label: 'Libellé',
    type: 'Type',
    enabled: 'Activé',
    position: 'Position',
    purchase_type: "Type d'achat",
    confirm_delete_purchase_type: "Êtes-vous sûr de vouloir supprimer ce type d'achat ?",
    purchase_type_added: "Le type d'achat a bien été ajouté",
    purchase_type_empty: "Aucun type d'achat",
    purchase_type_updated: "Le type d'achat a bien été modifié",
    achat_hote_type: "Type d'achat hôte",
    confirm_delete_achat_hote_type: "Êtes-vous sûr de vouloir supprimer ce type d'achat hôte ?",
    achat_hote_type_added: "Le type d'achat hôte a bien été ajouté",
    achat_hote_type_empty: "Aucun type d'achat hôte",
    achat_hote_type_updated: "Le type d'achat hôte a bien été modifié",
    raison_achat_hote: "Raison d'achat hôte",
    confirm_delete_raison_achat_hote: "Êtes-vous sûr de vouloir supprimer cette raison d'achat hôte ?",
    raison_achat_hote_added: "La raison d'achat hôte a bien été ajoutée",
    raison_achat_hote_empty: "Aucune raison d'achat hôte",
    raison_achat_hote_updated: "La raison d'achat hôte a bien été modifiée",
    motif_deplacement: 'Motif de déplacement',
    confirm_delete_motif_deplacement: 'Êtes-vous sûr de vouloir supprimer ce motif de déplacement ?',
    motif_deplacement_added: 'Le motif de déplacement a bien été ajouté',
    motif_deplacement_empty: 'Aucun motif de déplacement',
    motif_deplacement_updated: 'Le motif de déplacement a bien été modifié',
    mandat_cluster_rm: 'Cluster RM',
    mandat_cluster_rm_added: 'Le cluster RM a bien été ajouté',
    mandat_cluster_rm_empty: 'Aucun cluster RM',
    mandat_cluster_rm_updated: 'Le cluster RM a bien été modifié',
    confirm_delete_mandat_cluster_rm: 'Êtes-vous sûr de vouloir supprimer ce cluster RM ?',
    extra_type: "Type d'extra",
    confirm_delete_extra_type: "Êtes-vous sûr de vouloir supprimer ce type d'extra ?",
    extra_type_added: "Le type d'extra a bien été ajouté",
    extra_type_empty: "Aucun type d'extra",
    extra_type_updated: "Le type d'extra a bien été modifié",
    origin_marketing: 'Origine marketing',
    origin_marketing_added: "L'origine marketing a bien été ajoutée",
    origin_marketing_empty: 'Aucune origine marketing',
    origin_marketing_updated: "L'origine marketing a bien été modifiée",
    confirm_delete_origin_marketing: 'Êtes-vous sûr de vouloir supprimer cette origine marketing ?',
    profile_client: 'Profil voyageur',
    profile_client_added: 'Le profil voyageur a bien été ajouté',
    profile_client_empty: 'Aucun profil voyageur',
    profile_client_updated: 'Le profil voyageur a bien été modifié',
    confirm_delete_profile_client: 'Êtes-vous sûr de vouloir supprimer ce profil voyageur ?',
    forme_juridique: 'Forme juridique',
    forme_juridique_added: 'La forme juridique a bien été ajoutée',
    forme_juridique_empty: 'Aucune forme juridique',
    forme_juridique_updated: 'La forme juridique a bien été modifiée',
    confirm_delete_forme_juridique: 'Êtes-vous sûr de vouloir supprimer cette forme juridique ?',
    error_item_label_cluster_used: 'Impossible de supprimer ce cluster, il est utilisé dans un mandat',
    error_item_label_achat_hote_used:
      "Impossible de supprimer cette raison d'achat, elle est utilisée dans une charge hôte",
    error_item_label_motif_deplacement_used:
      'Impossible de supprimer ce motif de déplacement, il est utilisé dans une charge hôte',
  },
  accommodationRole: {
    attribute: "M'attribuer",
    attributeTo: 'Attribuer à',
    unattribute: 'Retirer',
    chooseUser: 'Choisir un salarié',
    error_already_exist: 'Cette attribution existe déjà',
  },
  clientRole: {
    attribute: "M'attribuer",
    unattribute: 'Se retirer',
  },
  accommodationType: {
    type: 'Type',
    file: 'Fichier',
    add: 'Ajouter un type de logement',
    added: 'Le type de logement a bien été ajouté',
    updated: 'Le type de logement a bien été modifié',
    deleted: 'Le type de logement a bien été supprimé',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer ce type de logement ?',
  },
  file: {
    name: 'Nom du fichier',
    type: 'Type de fichier',
    size: 'Taille du fichier',
    empty: 'Aucun fichier',
    err_size: 'La taille du fichier ne peut pas dépasser 10 Mo.',
  },
  task: {
    item: 'Tâche',
    label: 'Libellé',
    position: 'Position',
    group: 'Groupe',
    groups: 'Groupes',
    groupsAssociated: 'Groupes associés',
    groupsNb: 'Nombre de groupes',
    selectGroups: 'Selectionnez des groupes',
    empty: 'Aucune tâche',
    type: 'Type de tâche',
    add: 'Ajouter une tâche',
    update: 'Modifier une tâche',
    associate_group: 'Associer des groupes',
    added: 'La tâche a bien été ajoutée',
    updated: 'La tâche a bien été modifiée',
    deleted: 'La tâche a bien été supprimée',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer cette tâche ?',
    confirm_dissociategroup: 'Êtes-vous sûr de vouloir dissocier ce groupe de la tâche ?',
    dialog_dissociategroup: 'Association de groupes',
    groupDissociated: 'Le groupe a été dissocié de la tâche',
  },
  taskgroup: {
    item: 'Groupe de tâches',
    label: 'Libellé',
    task: 'Tâche',
    tasks: 'Tâches',
    tasksAssociated: 'Tâches associées',
    tasksNb: 'Nombre de tâches',
    selectTasks: 'Selectionnez des tâches',
    empty: 'Aucun groupe de tâches',
    add: 'Ajouter un groupe de tâches',
    update: 'Modifier un groupe de tâches',
    associate_task: 'Associer des tâches',
    added: 'Le groupe de tâches a bien été ajouté',
    updated: 'Le groupe de tâches a bien été modifié',
    deleted: 'Le groupe de tâches a bien été supprimé',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer ce groupe de tâches ?',
    confirm_dissociatetask: 'Êtes-vous sûr de vouloir dissocier cette tâche du groupe ?',
    dialog_dissociatetask: 'Association de tâches',
    taskDissociated: 'La tâche a été dissociée du groupe',
  },
  cdcmodel: {
    reset: 'Réinitialiser les CDCs',
    cdc_reset_done: 'Réinitialisation effectuée',
    confirm_reset: 'Etes-vous sûr de vouloir réinitialiser les CDCs ?',
    model: 'Modèle de cahier des charges',
    type: 'Type de modèle',
    item_count: 'Nombre de blocs',
    add_item: 'Ajouter un bloc',
    edit_item: 'Editer un bloc',
    delete_item: 'Supprimer un bloc',
    cdcmodelType_check_in: 'Check-in',
    cdcmodelType_check_out: 'Check-out',
    cdcmodelType_cleaning: 'Ménage interlocatif',
    cdcmodelType_cleaning_before: 'Ménage retour hôte',
    cdcmodelType_cleaning_after: 'Ménage départ hôte',
    cdcmodelType_running: 'Running',
    cdcmodelType_quality_control: 'Contrôle qualité',
    cdcmodelType_maintenance: 'Maintenance',
    error_reset_cdc_prestation: 'Un cdc est utilisé dans une prestation, réinitialisation impossible',
  },
  cdcmodelitem: {
    zone: 'Zone',
    subzone: 'Sous-zone',
    task: 'Tâche',
    taskgroup: 'Groupe de tâches',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer ce bloc ?',
  },
  cdc: {
    type: 'Type',
    duration: 'Durée',
    price: 'Prix',
    itemsNb: 'Nombre de blocs',
    tasksNb: 'Nombre de tâches',
    comment: 'Commentaire',
    updated: 'Le cahier des charges a bien été modifié',
    error_update: 'Une erreur est survenue lors de la mise à jour du cahier des charges',
  },
  cdcitem: {
    delete_item: 'Supprimer ce bloc',
    add_item: 'Ajouter un bloc',
    add_task: 'Ajouter une tâche',
    confirm_delete: "Êtes-vous sûr de vouloir supprimer cet item ? L'ensemble des sous-tâches sera également supprimée",
  },
  cdcitemtask: {
    delete_item: 'Supprimer cette tâche',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer cette tâche ?',
  },

  list: {
    additionalEquipment: {
      elements_basiques: 'Éléments basiques',
      cintres: 'Cintres',
      television_ecran_plat: 'Télévision écran plat',
      television_ecran_panoramique: 'Télévision écran panoramique',
      entree_privee: 'Entrée privée',
      salon_prive: 'Salon privé',
      serrure_dans_la_porte_de_la_chambre: 'Serrure dans la porte de la chambre',
      shampoing: 'Shampoing',
      oreillers_et_couvertures_supplementaires: 'Oreillers et couvertures supplémentaires',
      wifi_de_poche: 'Wifi de poche',
      espace_de_travail_pour_ordinateur_portable: 'Espace de travail pour ordinateur portable',
      chargeur_pour_vehicule_electrique: 'Chargeur pour véhicule électrique',
      pas_d_escalier_dans_le_logemnt: 'Pas d’escalier dans le logement',
      accessoires_de_plage: 'Accessoires de plage',
      bagagerie_autorisee: 'Bagagerie autorisée',
      extincteur: 'Extincteur',
      kit_de_premier_secours: 'Kit de premier secours',
      detecteur_de_fumee: 'Détecteur de fumée',
      detecteur_de_monoxyde_de_carbone: 'Détecteur de monoxyde de carbone',
      table_a_langer: 'Table à langer',
      livre_et_jeux_pour_enfants: 'Livre et jeux pour enfants',
      vaisselle_pour_enfants: 'Vaisselle pour enfants',
      par_feu_cheminee: 'Par feu cheminée',
      console_de_jeu: 'Console de jeu',
      cache_prise: 'Cache prise',
      berceau: 'Berceau',
      regulateur_de_lumiere: 'Régulateur de lumière',
      ecalier_avec_porte: 'Escalier avec porte',
      protection_coins_de_table: 'Protection coins de table',
      grille_de_securite_fenetre: 'Grille de sécurité fenêtre',
      couloir_spacieux: 'Couloir spacieux',
      hall_d_entree_illumine: 'Hall d’entrée illuminé',
      entree_de_la_maison_spacieuse: 'Entrée de la maison spacieuse',
      allee_ample_vers_la_porte_d_entree: 'Allée ample vers la porte d’entrée',
      pakring_pour_handicape: 'Parking pour handicapé',
      sortie_de_lit_tres_spacieuse: 'Sortie de lit très spacieuse',
      entree_de_chambre_tres_spacieuse: 'Entrée de chambre très spacieuse',
      hauteur_de_lit_accessible: 'Hauteur de lit accessible',
      lit_reglable_electriquement: 'Lit réglable électriquement',
      acces_a_la_salle_de_bain_sans_escalier: 'Accès à la salle de bain sans escalier',
      bar_d_appui_dans_la_douche: 'Bar d’appui dans la douche',
      bar_d_appui_dans_les_wc: 'Bar d’appui dans les WC',
      hauteur_des_toilettes_accessibles: 'Hauteur des toilettes accessibles',
      pas_de_marche_a_la_sortie_de_la_doiuche: 'Pas de marche à la sortie de la douche',
      douche_avec_chaise_incorpore: 'Douche avec chaise incorporée',
      entree_dans_la_salle_de_bain_spacieuse: 'Entrée dans la salle de bain spacieuse',
      baignoire_avec_chaise_incorpore: 'Baignoire avec chaise incorporée',
      sortie_de_douche_et_de_toilettes_spacieuses: 'Sortie de douche et de toilettes spacieuses',
      pommeau_de_douche_manuelle: 'Pommeau de douche manuel',
      acces_piece_commune_sans_escalier: 'Accès pièce commune sans escalier',
      entree_et_pieces_communes_tres_spacieuse: 'Entrée et pièces communes très spacieuses',
      souleve_personne: 'Soulève personne',
      piscine_avec_fauteuil_elevateur: 'Piscine avec fauteuil élévateur',
      leve_personne_au_plafond: 'Lève personne au plafond',
      coffre_fort_pour_ordinateur_portable: 'Coffre fort pour ordinateur portable',
      canape: 'Canapé',
      reveil: 'Réveil',
      salle_de_reunion: 'Salle de réunion',
      enceinte_ipod: 'Enceinte ipod',
      equipments_de_cuisine: 'Equipements de cuisine',
      bidet: 'Bidet',
      ordinateur: 'Ordinateur',
      ipad: 'Ipad',
      ordinateur_portable: 'Ordinateur portable',
      produit_de_nettoyage: 'Produit de nettoyage',
      couverture_electrique: 'Couverture électrique',
      vue_sur_la_ville: 'Vue sur la ville',
      table_de_salle_a_manger: 'Table de salle à manger',
      salle_a_manger_d_exterieur: 'Salle à manger d’extérieure',
      propriete_de_plein_pied: 'Propriété de plein pied',
      etendoir_a_linge: 'Etendoir à linge',
      protection_prise_pour_enfant: 'Protection prise pour enfant',
      jeu_de_societe: 'Jeu de société',
      puzzle: 'Puzzle',
      porte_de_secuite_pour_bebe: 'Porte de sécuité pour bébé',
      baignoire_adapte: 'Baignoire adaptée',
      cuvette_de_toilette_eleve: 'Cuvette de toilette élevée',
      lavabo_accessible: 'Lavabo accessible',
      piscine_sur_le_toit: 'Piscine sur le toit',
      piscine_a_debordement: 'Piscine à débordement',
      piscine_avec_vue: 'Piscine avec vue',
      piscine_d_eau_sale: 'Piscine d’eau salée',
      piscine_d_immersion: 'Piscine d’immersion',
      serviette_de_piscine: 'Serviette de piscine',
      piscine_avec_zone_peu_profonde: 'Piscine avec zone peu profonde',
      bache_de_protection_piscine: 'Bache de protection piscine',
      vin_champagne: 'Vin / champagne',
      bouteille_d_eau: 'Bouteille d’eau',
      fruit: 'Fruit',
      chocolat_biscuits: 'Chocolat / biscuits',
      poubelle: 'Poubelle',
      verre_a_vin: 'Verre à vin',
      brosse_a_dents: 'Brosse à dents',
      apres_shampoing: 'Après shampoing',
      gel_douche: 'Gel douche',
      baignoire_a_remou: 'Baignoire à remous',
      baignoire: 'Baignoire',
      article_de_bain_et_de_toilette_gratuit: 'Article de bain et de toilette gratuit',
      telephone_sans_fil: 'Téléphone sans fil',
      bureau: 'Bureau',
      bureau_avec_lampe: 'Bureau avec lampe',
      fax: 'Fax',
      fims_gratuit: 'Films gratuits',
      baignoire_grec: 'Baignoire grecque',
      table_a_repasser: 'Table à repasser',
      grand_bureau: 'Grand bureau',
      telephone_multi_ligne: 'Téléphone multi ligne',
      video_a_la_demande_vod: 'Vidéo à la demande / vod',
      telephone_dans_la_salle_de_bain: 'Téléphone dans la salle de bain',
      assiettes_et_bols: 'Assiettes et bols',
      refregirateur_avec_appareil_a_glacon: 'Réfrigérateur avec appareil à glaçons',
      armoire_separe: 'Armoire séparée',
      douche_uniquement: 'Douche uniquement',
      salon: 'Salon',
      climatisation_avec_telecommande_dans_la_chambre: 'Climatisation avec télécommande dans la chambre',
      baignoire_et_hydromassage_separe: 'Baignoire et hydromassage séparés',
      table_et_chaise: 'Table et chaise',
      baignoire_et_douche_separe: 'Baignoire et douche séparées',
      ventilateur_de_plagond: 'Ventilateur de plafond',
      armoire_dans_la_chambre: 'Armoire dans la chambre',
      systeme_de_chauffage_climatisation_auto_controlee: 'Système de chauffage / climatisation auto controlée',
      vue_sur_le_golf: 'Vue sur le golf',
      vue_sur_mer: 'Vue sur mer',
      armoire: 'Armoire',
      salon_avec_canape_chause: 'Salon avec canapé / chaise',
      zone_de_toilette_independante: 'Zone de toilette indépendante',
      raclette: 'Raclette',
      salle_a_manger: 'Salle à manger',
      epices: 'Epices',
      salle_de_jeu: 'Salle de jeux',
      poele_a_bois: 'Poêle à bois',
      non_accessible_pour_personnes_en_chaise_roulante: 'Non accessible pour personnes en chaise roulante',
      douche_et_baignoire_ensemble: 'Douche et baignoire ensemble',
      douche: 'Douche',
      baby_foot: 'Baby foot',
      bibliotheque_musical: 'Bibliothèque musicale',
      table_de_ping_pong: 'Table de ping pong',
      billard: 'Billard',
      stereo: 'Stéréo',
      video_tech: 'Vidéo tech',
      velo: 'Vélo',
      veranda: 'Véranda',
      piscine_interieur: 'Piscine intérieure',
      fetes_et_evenement_non_autorisees: 'Fêtes et évènements non autorisés',
      salle_de_musculation: 'Salle de musculation',
      famille: 'Famille',
      romantique: 'Romantique',
      spa: 'Spa',
      grill_pour_barbecue: 'Grill pour barbecue',
      baignoire_seulement: 'Baignoire seulement',
      bain_ou_douche: 'Bain ou douche',
      television_par_cable: 'Télévision par cable',
      chambre_communicante: 'Chambre communicante',
      materiel_de_cuisine: 'Matériel de cuisine',
      kitchenette: 'Kitchenette',
      salle_de_bain_prive: 'Salle de bain privée',
      cuisiniere: 'Cuisinière',
      xbox: 'Xbox',
      ps2: 'Ps2',
      ps3: 'ps3',
      wii: 'Wii',
      xbox_one: 'Xbox one',
      wii_u: 'Wii u',
      ps4: 'Ps4',
      espace_salon: 'Espace salon',
      patio: 'Patio',
      dressing: 'Dressing',
      salle_de_bain_supplementaire: 'Salle de bain supplémentaire',
      mobilier_d_exterieur: 'Mobilier d’extérieur',
      appartement_prive_dans_un_immeuble: 'Appartement privé dans un immeuble',
      sechoir_a_linge: 'Séchoir à linge',
      espace_barbecue_pique_nique: 'Espace barbecue / pique nique',
      piscine_exterieur: 'Piscine extérieure',
      stationnement_en_voirie: 'Stationnement en voirie',
      parking_securise: 'Parking sécurisé',
      tous_les_espaces_non_fumeur: 'Tous les espaces non fumeur',
      station_de_recharge_pour_vehicule_electrique: 'Station de recharge pour véhicule électrique',
      velo_gratuit: 'Vélo gratuit',
      cheminee_exterieur: 'Cheminée exterieure',
      terrasse_ensoleille: 'Terrasse ensoleillée',
      plage: 'Plage',
      flechette: 'Fléchette',
      snorkeling: 'Snorkeling',
      squash: 'Squash',
      canoe: 'Canoé',
      acces_ski_au_pied: 'Accès ski au pied',
      plongee: 'Plongée',
      equipement_de_tennis: 'Equipement de tennis',
      equipement_de_badminton: 'Equipement de badminton',
      local_a_ski: 'Local à ski',
      plage_privee: 'Plage privée',
      enfants_bienvenue: 'Enfants bienvenus',
      douche_exterieur: 'Douche extérieure',
      oreiller: 'Oreiller',
      veranda_meuble: 'Véranda meublée',
      patio_meuble: 'Patio meublé',
      balcon_ou_pation: 'Balcon ou patio',
      controle_de_la_climatisation: 'Contrôle de la climatisation',
      coffre_fort_standard: 'Coffre fort standard',
      rideau_opaque: 'Rideau opaque',
      chambre_decoree: 'Chambre décorée',
    },
    bathroomEquipment: {
      seche_cheveux: 'Sèche-cheveux',
      baignoire_bebe: 'Baignoire bébé',
      seche_serviette: 'Sèche-serviette',
      lave_linge: 'Lave-linge',
      seche_linge: 'Sèche-linge',
      radio: 'Radio',
      jacuzzi: 'Jacuzzi',
      sauna: 'Sauna',
      baignoire_spa: 'Baignoire Spa',
      radiateur: 'Radiateur',
      balance: 'Balance',
      porte_savon: 'Porte-savon',
    },
    bathroomFurniture: {
      douche: 'Douche',
      baignoire: 'Baignoire',
      vasques: 'Vasques',
      miroir: 'Miroir',
      meuble_de_rangement: 'Meuble de rangement',
      rangement_sous_vasques: 'Rangement sous vasques',
      wc: 'WC',
    },
    bedroomBed: {
      double: 'Double (140 x 200)',
      queen_size: 'Queen size (160 x 200)',
      king_size: 'King size (180 x 200)',
      simple: 'Simple (90 x 200)',
      super_pose: 'Super posé',
      canape_lit: 'Canapé lit',
      lit_d_appoint: 'Lit d’appoint',
      lit_gigogne: 'Lit gigogne',
    },
    bedroomEquipment: {
      television: 'Télévision',
      jouet: 'Jouet',
      porte_avec_verrou: 'Porte avec verrou',
      climatisation: 'Climatisation',
      oreillers_supplementaires: 'Oreillers supplémentaires',
      plaids: 'Plaids',
      couverture_supplementaire: 'Couverture supplémentaire',
      rideau_opaque: 'Rideau opaque',
      ventilateur_plafond: 'Ventilateur plafond',
      moustiquaires: 'Moustiquaires',
    },
    bedroomFurniture: {
      table_de_chevet: 'Table de chevet',
      lampe_de_chevet: 'Lampe de chevet',
      bureau: 'Bureau',
      chaise: 'Chaise',
      radiateur: 'Radiateur',
      armoire: 'Armoire',
      placard: 'Placard',
      commodes_semainier: 'Commodes / Semainier',
      cintres: 'Cintres',
      miroir: 'Miroir',
      cadres: 'Cadres',
    },
    bedroomLayout: {
      chambre_communicante: 'Chambre communicante',
      chambre_separee: 'Chambre séparée',
    },
    diningroomEquipment: {
      radiateur: 'Radiateur',
      ventilateur: 'Ventilateur',
      television: 'Télévision',
      radio: 'Radio',
    },
    diningroomFurniture: {
      table_a_manger: 'Table à manger',
      rallonge: 'Rallonge',
      chaises: 'Chaises',
      chaise_haute: 'Chaise haute',
      rangement: 'Rangement',
    },
    espaceExterieur: {
      barbecue: 'Barbecue',
      plancha: 'Plancha',
      mobilier_de_jardin: 'Mobilier de jardin',
      transat: 'Transat',
      parasol: 'Parasol',
      jeu_pour_enfants: 'Jeu pour enfants',
      arrosage: 'Arrosage',
      squash: 'Squash',
      tennis: 'Tennis',
      paddle: 'Paddle',
      piscine: 'Piscine',
      sauna: 'Sauna',
      hammam: 'Hammam',
      jacuzzi: 'Jacuzzi',
    },
    kitchenEquipment: {
      television: 'Télévision',
      refrigerateur: 'Réfrigérateur',
      congelateur: 'Congélateur',
      lave_vaisselle: 'Lave-vaisselle',
      four: 'Four',
      friteuse: 'Friteuse',
      micro_ondes: 'Micro-ondes',
      appareil_raclette: 'Appareil à raclette',
      machine_a_cafe_a_filtre: 'Machine à café à filtre',
      machine_a_cafe_a_dosette_nespresso: 'Machine à café à dosette Nespresso',
      machine_a_cafe_a_dosette_tassimo: 'Machine à café à dosette Tassimo',
      machine_a_cafe_a_dosette_senseo: 'Machine à café à dosette Senseo',
      machine_a_cafe_a_dosette_dolce_gusto: 'Machine à café à dosette Dolce gusto',
      machine_a_cafe_a_grain: 'Machine à café à grain',
      cafetiere_piston: 'Cafetière à piston',
      machine_a_cafe_a_dosette_krups: 'Machine à café à dosette KRUPS',
      grille_pain: 'Grille pain',
      bouilloire: 'Bouilloire',
      presse_agrume: 'Presse agrume',
      couverts: 'Couverts',
      ustensiles_de_cuisine: 'Ustensiles de cuisine',
      lave_linge: 'Lave linge',
      seche_linge: 'Sèche linge',
    },
    systemeAutonome: {
      locker_box: 'Locker box',
      nuki: 'Nuki',
      igloo_home: 'Igloo home',
      bnb_access: 'Bnb access',
      key_nest: 'Key Nest',
      others: 'Autres',
    },
    kitchenFurniture: {
      tables: 'Tables',
      chaises: 'Chaises',
      placards_de_rangement: 'Placards de rangement',
    },
    officeFurnitures: {
      bureau: 'Bureau',
      chaise: 'Chaise',
      rangement: 'Rangement',
      radiateur: 'Radiateur',
      ventilateur: 'Ventilateur',
      cadre: 'Cadre',
      placard: 'Placard',
    },
    officeEquipments: {
      tv: 'Télévision',
      radio: 'Radio',
      lampe: 'Lampe',
      rallonge: 'Rallonge',
      livre: 'Livres',
    },
    kitchenHotplates: {
      plaque_a_induction: 'Plaque à induction',
      vitroceramique: 'Vitrocéramique',
      gaz: 'Gaz',
      electrique: 'Electrique',
    },
    kitchenStyle: {
      ouverte: 'Ouverte (Américaine)',
      fermee: 'Fermée',
      semi_ouverte: 'Semi-ouverte',
    },
    laundryroomEquipment: {
      lave_linge: 'Lave linge',
      seche_linge: 'Sèche linge',
      tancarville: 'Tancarville',
      table_a_repasser: 'Table à repasser',
      fer_a_repasser: 'Fer à repasser',
      fil_a_linge: 'Fil à linge',
    },
    laundryroomFurniture: {
      tables: 'Tables',
      chaises: 'Chaises',
      placards_de_rangement: 'Placards de rangement',
    },
    outsideEquipment: {
      barbecue: 'Barbecue',
      plancha: 'Plancha',
      mobilier_de_jardin: 'Mobilier de jardin',
      transat: 'Transat',
      parasol: 'Parasol',
      jeu_pour_enfants: 'Jeu pour enfants',
      arrosage: 'Arrosage',
      squash: 'Squash',
      tennis: 'Tennis',
      paddle: 'Paddle',
      piscine: 'Piscine',
      sauna: 'Sauna',
      hammam: 'Hammam',
      jacuzzi: 'Jacuzzi',
    },
    outsidePlace: {
      balcon: 'Balcon',
      jardin: 'Jardin',
      jardin_cloture: 'Jardin clôturé',
      terrasse: 'Terrasse',
      cabanon_de_jardin: 'Cabanon de jardin',
      salle_de_sport: 'Salle de sport',
      potager: 'Potager',
    },
    outsidePlaceAppartment: {
      balcon: 'Balcon',
      terrasse: 'Terrasse',
      mobilier_de_jardin: 'Mobilier de jardin',
      barbecue: 'Barbecue',
      plancha: 'Plancha',
      cour: 'Cour',
      table: 'Table',
      chause: 'Chause',
      transat: 'Transat',
      poufs: 'Poufs',
      parasol: 'Parasol',
      plantes: 'Plantes',
      jouets: 'Jouets',
    },
    outsidePlaceResidence: {
      squash_dans_la_residence: 'Squash dans la résidence',
      tennis_dans_la_residence: 'Tennis dans la résidence',
      paddle_dans_la_residence: 'Paddle dans la résidence',
      piscine_dans_la_residence: 'Piscine dans la résidence',
      salle_de_sport_dans_la_residence: 'Salle de sport dans la résidence',
    },
    salonEquipment: {
      television: 'Télévision',
      plateforme_de_streaming_netflix: 'Plateforme de streaming Netflix',
      plateforme_de_streaming_amazon_prime_video: 'Plateforme de streaming Amazon prime video',
      plateforme_de_streaming_canal: 'Plateforme de streaming Canal +',
      plateforme_de_streaming_disney_plus: 'Plateforme de streaming Disney plus',
      plateforme_de_streaming_salto: 'Plateforme de streaming Salto',
      plateforme_de_streaming_ocs: 'Plateforme de streaming OCS',
      plateforme_de_streaming_apple_tv: 'Plateforme de streaming Apple TV',
      lecteur_dvd: 'Lecteur dvd',
      console_de_jeu_ps2: 'Console de jeu PS2',
      console_de_jeu_ps3: 'Console de jeu PS3',
      console_de_jeu_ps4: 'Console de jeu PS4',
      console_de_jeu_ps5: 'Console de jeu PS5',
      console_de_jeu_xbox_360: 'Console de jeu XBOX 360',
      console_de_jeu_xbox_one: 'Console de jeu XBOX ONE',
      console_de_jeu_autre: 'Console de jeu autre',
      radio: 'Radio',
      lecteur_cd: 'Lecteur CD',
      enceinte_bluetooth: 'Enceinte bluetooth',
      jeux: 'Jeux',
      livres: 'Livres',
      cheminee: 'Cheminée',
      poele_a_bois: 'Poele à bois',
      moustiquaires: 'Moustiquaires',
      radiateur: 'Radiateur',
      thermostat: 'Thermostat',
    },
    salonFurniture: {
      canape: 'Canapé',
      canape_lit: 'Canapé lit',
      fauteuil: 'Fauteuil',
      fauteuil_lit: 'Fauteuil lit',
      chaise: 'Chaise',
      table_basse: 'Table basse',
      lampe: 'Lampe',
      cadres: 'Cadres',
      miroir: 'Miroir',
    },
    typeChauffage: {
      pompe_a_chaleur: 'Pompe à chaleur',
      chauffage_central: 'Chauffage central',
      chauffage_electrique: 'Chauffage électrique',
      chaudiere_a_gaz_individuel: 'Chaudière à gaz individuel',
      chauffage_a_gaz_collectif: 'Chauffage à gaz collectif',
      chauffage_fioul: 'Chauffage au fioul ',
    },
    typeParking: {
      garage: 'Garage',
      stationnement_couvert_reserve: 'Stationnement couvert réservé',
      stationnement_exterieur_prive: 'Stationnement extérieur privé',
      stationnement_possible_dans_le_jardin: 'Stationnement possible dans le jardin',
    },
    wcEquipment: {
      balai_wc: 'Balai WC',
      support_papier_toilette: 'Support papier toilette',
    },
    wcFurniture: {
      lavabo: 'Lavabo',
      meuble_de_rangement: 'Meuble de rangement',
      bidet: 'Bidet',
    },
  },
  accommodationContact: {
    type: 'Type de contact',
    civility: 'Civilité',
    syndic: 'Responsable du syndicat de copropriétaires',
    gardien: 'Gardien de l’immeuble',
    voisin: 'Voisin',
    urgence: "Contact d'urgence",
    plombier: 'Plombier',
    electricien: 'Electricien',
    chauffagiste: 'Chauffagiste',
    pisciniste: 'Pisciniste',
    serrurier: 'Serrurier',
    jardinier: 'Jardinier',
    other: 'Autre',
    deleteContactWarning: 'Etes-vous sûr de vouloir supprimer ce contact ?',
  },
  bedroom: {
    layout: 'Agencement',
    beds: 'Lits',
    equipment: 'Equipements',
    furniture: 'Meubles',
    comment: 'Commentaire',
  },
  bathroom: {
    equipment: 'Equipements',
    furniture: 'Meubles',
    comment: 'Commentaire',
  },
  diningroom: {
    seatNb: 'Nombre de sièges',
    equipment: 'Equipements',
    furniture: 'Meubles',
    comment: 'Commentaire',
  },
  laundryroom: {
    equipment: 'Equipements',
    furniture: 'Meubles',
    vacuumType: "Type d'aspirateur",
    vacuumLocation: "Emplacement de l'aspirateur",
    mopLocation: 'Emplacement de la serpilière',
    comment: 'Commentaire',
  },
  salon: {
    equipment: 'Equipements',
    furniture: 'Meubles',
    comment: 'Commentaire',
  },
  wc: {
    equipment: 'Equipements',
    furniture: 'Meubles',
    comment: 'Commentaire',
    independant: 'Indépendant ',
  },
  kitchen: {
    style: 'Style',
    hotplates: 'Plaques de cuisson',
    equipment: 'Equipements',
    furniture: 'Meubles',
    comment: 'Commentaire',
  },
  ficType: {
    ESTIMATION_LOCATIVE: 'Estimation locative',
    MANDAT_GESTION_UNSIGNED: 'Mandat de gestion à signer',
    MANDAT_GESTION_SIGNED: 'Mandat de gestion signé',
    AVENANT_SIGNED: 'Avenant au mandat de gestion signé',
    AVENANT_UNSIGNED: 'Avenant au mandat de gestion à signer',
    avenant_signed_date: 'Avenant signé du {date}',
    RIB: 'RIB',
    identity_indivision: "Piece d'identité de {firstname} {lastname} (recto)",
    identity_indivision2: "Piece d'identité de {firstname} {lastname} (verso)",
    IDENTITY: "Piece d'identité (recto)",
    IDENTITY2: "Piece d'identité (verso)",
    JUSTIFPROPRI: 'Acte de proprieté',
    CONTRATASSU: "Contrat d'assurance",
    ACCORDLOCATION: 'Accord de sous-location',
    CERFA: 'Déclaration de mise en location auprès de la mairie',
    TDS: 'Tarifs de la taxe de séjour',
    REGCOPRO: 'Règlement de la copropriété',
    KBIS: 'Kbis de la société',
    PIECE_IDENTITY: "Piece d'identité",
    MANDAT_LETTRE_RESILIATION: 'Lettre de résiliation',
  },
  requestModif: {
    message: 'Saisissez le texte de votre message de demande de modification',
    title: 'Demande de modification',
    msgSent: 'Demande de modification envoyée',
    messageRequired: 'Le message est requis',
  },
  activity: {
    new: 'Nouvelle activité',
    empty: 'Aucune activité',
    details: "Fiche d'activité",
    interlocutorType: "Type d'interlocuteur",
    interlocutor: 'Interlocuteur',
    proprietaire: 'Hôte',
    prospect: 'Prospect',
    salarie: 'Salarié',
    candidat: 'Candidat',
    voyageur: 'Voyageur',
    prestataire: 'Prestataire',
    accommodation: 'Logement',
    reservation: 'Réservation',
    dateHour: 'Date et heure',
    who: 'Qui',
    contactWay: 'Moyen de contact',
    motif: 'Motif',
    comment: 'Description',
    files: 'Fichiers associés',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer cette activité ?',
    add: 'Ajouter une activité',
    added: 'Activité ajoutée',
    update: "Modifier l'activité",
    updated: 'Activité modifiée',
    delete: "Supprimer l'activité",
    deleted: 'Activité supprimée',
  },
  ticket: {
    list: 'Tickets',
    empty: 'Aucun ticket',
    attribution: 'Attribution',
    pole: 'Attribution Pole',
    employee: 'Attribution Membre',
    company: 'Attribution agence',
    activity: 'Activité',
    label: 'Libellé',
    status: 'Statut',
    priority: 'Priorité',
    description: 'Description',
    commentsNb: 'Nb de commentaires',
    ficsNb: 'Nb de fichiers',
    dueDate: "Date d'échéance",
    startDate: 'Date de démarrage',
    waitingValidationDate: 'Date de fermeture',
    validatedDate: 'Date de validation',
    dateCreated: 'Date de création',
    creator: 'Auteur',
    comments: 'Commentaires',
    files: 'Fichiers',
    historic: 'Historique',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer ce ticket ?',
    confirm_start: 'Êtes-vous sûr de vouloir démarrer ce ticket ?',
    confirm_renew: 'Êtes-vous sûr de vouloir ré-ouvrir ce ticket ?',
    confirm_close: 'Êtes-vous sûr de vouloir fermer ce ticket ?',
    add: 'Ajouter un ticket',
    added: 'Ticket ajouté',
    start: 'Démarrer',
    started: 'Ticket démarré',
    renew: 'Ré-ouvrir',
    renewed: 'Ticket ré-ouvert',
    close: 'Fermer le ticket',
    closed: 'Ticket en attente de validation',
    attribute: 'Attribuer',
    attributeTicket: 'Attribuer le ticket',
    changePriority: 'Changer la priorité',
    priorityChanged: 'Priorité changée',
    attributed: 'Ticket attribué',
    reattribute: 'Réattribuer',
    reattributed: 'Ticket réattribué',
    update: 'Modifier le ticket',
    updated: 'Ticket modifié',
    delete: 'Supprimer le ticket',
    deleted: 'Ticket supprimé',
    details: 'Fiche de ticket',
    status_new: 'Nouveau',
    status_in_progress: 'En cours',
    status_waiting_validation: 'En attente de validation',
    status_validated: 'Validé',
    priority_low: 'Bas',
    priority_medium: 'Moyen',
    priority_high: 'Haut',
    priority_critical: 'Critique',
    prospect_display: 'Nom du prospect',
    client_display: 'Nom du client',
    category: 'Catégorie de la demande',
    subCategory: 'Sous catégorie de la demande',
    reason: 'Raison',
    amount_ttc: 'Montant TTC',
    commission_ga: 'Commission GA',
    attributed_to_me: 'me sont attribués',
    validate_resolution: 'Valider la résolution',
    refuse_resolution: 'Refuser la résolution',
    resolution_refused: 'Résolution du ticket refusée',
    resolution_validated: 'Résolution validée',
    validate: 'Valider',
    refusal_comment: 'Commentaire de refus',
    confirm_validate_resolution: 'Êtes-vous sûr de vouloir valider la résolution de ce ticket ?',
    error: 'Une erreur est survenue',
    error_empty_files: 'Le champ Fichier est requis',
    error_no_owner_pole: "Une erreur est survenue lors de la création d'indisponibilité",
    error_empty_refusal_comment: 'Le commentaire de refus est requis',
    error_validate_not_closed: 'Impossible de valider un ticket non fermé',
  },
  ticketcomment: {
    message: 'Message',
    replyTitle: 'Répondre à un commentaire',
    comment: 'Commenter le ticket',
    added: 'Commentaire ajouté',
    replied: 'Réponse ajoutée',
    reply: 'Répondre',
    newComment: 'Ecrire un nouveau commentaire',
  },
  amenity: {
    antique: 'Antique',
    art_deco: 'Art Déco',
    atypique: 'Atypique',
    baroque: 'Baroque',
    bois: 'Bois',
    contemporain: 'Contemporain',
    ecologique: 'Ecologique',
    empire: 'Empire',
    historique: 'Historique',
    hi_tech: 'Hi-tech',
    minimaliste: 'Minimaliste',
    romantique: 'Romantique',
    victorien: 'Victorien',

    bouteille_eau: "Bouteilles d'eau",
    cave: 'Cave',
    cuisine_equipee: 'Cuisine équipée',
    cuisiniere: 'Cuisinière',
    eau_chaude: 'Eau chaude',
    eau_filtree: 'Eau filtrée',
    fontaine_a_eau: 'Fontaine à eau',
    kitchenette: 'Kitchenette',
    machine_a_glace: 'Machine à glace',
    mixeur: 'Mixeur',
    toaster: 'Toaster',
    verres_a_vin: 'Verres à vin',

    adaptee_aux_enfants: 'Adapté aux enfants',
    babyphone: 'Babyphone',
    baignoire_bebe: 'Baignoire bébé',
    balancoire: 'Balançoire',
    chaise_haute: 'Chaise haute',
    convient_aux_bebes: 'Convient aux bébés (moins de 2 ans)',
    convient_aux_enfants: 'Convient aux enfants (2 ans ou plus)',
    garde_robe: 'Garde-robe',
    jeux_pour_enfants: 'Jeux pour enfants',
    jeux_pour_tous_ages: 'Jeux pour tous âges',
    lit_bebe: 'Lit bébé',
    lit_parapluie: 'Lit parapluie',
    livres_pour_enfants: 'Livres pour enfants',
    mariage: 'Mariage',
    piscine_enfants: 'Piscine enfants',
    recommandation_de_babysitter: 'Recommandation de babysitter',
    salle_de_jeux: 'Salle de jeux',
    table_a_langer: 'Table à langer',
    vaiselle_pour_enfants: 'Vaisselle pour enfants',

    espace_de_travail: 'Espace de travail pour ordinateur portable',
    bureau_de_travail: 'Bureau de travail',
    chargeur_ev: 'Chargeur EV',
    ordinateur_disponible: 'Ordinateur disponible',
    reveil: 'Réveil',
    satellite_ou_cable: 'Satellite ou cable',
    systeme_stereo: 'Système stéréo',
    telephone: 'Téléphone',
    wifi_portable: 'Wi-fi portable',

    air_conditionne: 'Air conditionné',
    bar_prive: 'Bar privé',
    bowling_prive: 'Bowling privé',
    chauffeur: 'Chauffeur',
    chef_cuisinier_particulier: 'Chef cuisinier particulier',
    cinema: 'Cinéma',
    concierge: 'Concierge',
    depot_de_bagage: 'Dépôt de bagages autorisé',
    draps_et_serviettes: 'Draps et serviettes',
    helipad: 'Helipad',
    linge_de_luxe: 'Le linge de luxe',
    location_longue_duree: 'Location longue durée possible',
    majordome: 'Majordome',
    menage_inclus: 'Ménage inclus',
    menage_professionnel: 'Ménage professionnel',
    personnel_avec_equipement: 'Personnel avec équipement de protection',
    petit_dejeuner_inclus: 'Petit-déjeuner inclus',
    service_de_menage_quotidien: 'Service de ménage quotidien',
    ventilateurs_de_plafond: 'Ventilateurs de plafond',
    voiture_incluse: 'Voiture incluse',

    barriere_de_securite: 'Barrière de sécurité pour escalier',
    caches_prises: 'Caches-prises',
    consignes_de_securite: 'Consignes de sécurité',
    detecteur_de_monoxyde: 'Détecteur de monoxyde de carbone',
    distanciation_physique: 'Distanciation physique',
    extincteur: 'Extincteur',
    kit_de_premier_secours: 'Kit de premier secours',
    pare_feu: 'Pare-feu pour la cheminée',
    porte_chambre_avec_verrou: 'Porte de la chambre avec verrou',
    protections_coins_de_table: 'Protections sur les coins de tables',
    protections_fenetres: 'Protections sur les fenêtres',
    systeme_de_securite: 'Système de sécurité',

    affichage_pratiques_eco_responsables: 'Affichage des pratiques éco-responsables',
    aliments_et_boissons_bio: 'Aliments et boissons bios',
    appareils_basse_consommation: 'Appareils à basse consommation',
    changement_serviettes_sur_demande: 'Changement de serviettes sur demande',
    collecte_des_eaux_pluviales: 'Collecte des eaux pluviales',
    eco_construction: 'Eco-construction',
    eco_tourisme: 'Eco-tourisme',
    pas_d_article_de_toilette_individuel: "Pas d'article de toilette individuel",
    pas_de_plastique_a_usage_unique: 'Pas de plastique à usage unique',
    reduction_du_papier: 'Réduction du papier/papier recyclé',
    soutien_a_des_projets_durables: 'Soutien à des projets durables',
    systeme_energie_renouvelable: "Système d'énergie renouvelable",
    tri_des_dechets: 'Tri des déchets',

    acces_entree_bien_eclaire: "Accès à l'entrée bien éclairé",
    acces_plain_pied_chambre: 'Accès de plain-pied à la chambre',
    acces_plain_pied_salle_de_bain: 'Accès de plain-pied à la salle de bain',
    acces_plain_pied_espaces_communs: 'Accès de plain-pied aux espaces communs',
    acces_large_douche_et_wc: 'Accès large à la douche et au WC',
    acces_large_lit: 'Accès large au lit',
    acces_plat_porte_entree: "Accès plat à la porte d'entrée",
    baie: 'Baie',
    baignoire_avec_siege_de_bain: 'Baignoire avec siège de bain',
    barres_appui_douche: "Barres d'appui pour douche",
    barres_appui_wc: "Barres d'appui pour WC",
    couloir_large: 'Couloir large',
    douche_plain_pied: 'Douche de plain-pied',
    entree_large_salle_de_bain: 'Entrée large à la salle de bain',
    entree_large_espaces_communs: 'Entrée large aux espaces communs',
    leve_personne_mobile: 'Lève-personne mobile',
    leve_personne_plafonnier: 'Lève-personne plafonnier',
    lit_a_hauteur_accessible: 'Lit à hauteur accessible',
    lit_ajustable_en_hauteur: 'Lit ajustable en hauteur',
    piscine_avec_leve_personne: 'Piscine avec lève-personne',
    place_de_parking_handicapee: 'Place de parking handicapée',
    pomme_de_douche_amovible: 'Pomme de douche amovible',
    porte_large: 'Porte large',
    porte_large_chambre: 'Porte large pour la chambre',
    siege_de_douche: 'Siège de douche',
    toilettes_ajustables_en_hauteur: 'Toilettes ajustables en hauteur',

    gants_disponibles: 'Gants disponibles',
    gel_hydroalcoolique: 'Gel hydroalcoolique',
    laverie_automatique: 'Laverie automatique',
    menage_fait_avant_le_depart: 'Le ménage doit être fait avant le départ',
    masques_disponibles: 'Masques disponibles',
    materiel_de_repassage: 'Matériel de repassage',
    menage_et_desinfection: 'Ménage et désinfection',
    produits_de_base: 'Produits de base',
    produit_de_nettoyage: 'Produits de nettoyage',

    balcon: 'Balcon',
    cloture_entourant_piscine: 'Cloture entourant piscine',
    couverture_de_piscine: 'Couverture de piscine',
    entree_privee: 'Entrée privée',
    patio: 'Patio',
    piscine_a_debordement: 'Piscine à débordement',
    piscine_chauffee: 'Piscine chauffée',
    piscine_commune: 'Piscine commune',
    piscine_en_eau_salée: 'Piscine en eau salée',
    piscine_exterieure_saisonnier: 'Piscine extérieure (saisonnier)',
    piscine_interieure_saisonnier: 'Piscine intérieure (saisonnier)',
    piscine_peu_profonde: 'Piscine peu profonde',
    piscine_privative: 'Piscine privative',
    propriete_cloturee: 'Propriété cloturée',
    quai_a_bateau: 'Quai à bateau',
    residence_fermee: 'Résidence fermée',
    terrasse: 'Terrasse',

    proximite_terrain_de_golf: 'A proximité du terrain de golf',
    bord_de_lac: 'Bord de lac',
    bord_de_mer: 'Bord de mer',
    bord_de_plage: 'Bord de plage',
    campagne: 'Campagne',
    centre_ville: 'Centre-ville',
    domaine_viticole: 'Domaine viticole',
    fleuve_riviere: 'Fleuve/rivière',
    lac: 'Lac',
    montagne: 'Montagne',
    plage: 'Plage',
    proche_mer: 'Proche de la mer',
    ski_in: 'Ski In',
    ski_out: 'Ski Out',
    village: 'Village',
    village_vacances_tout_compris: 'Village vacances tout compris',
    ville: 'Ville',
    vue_mer: 'Vue mer',
    vue_montagne: 'Vue sur la montagne',
    vue_plage: 'Vue sur la plage',
    vue_lac: 'Vue sur le lac',
    vue_terrain_de_golf: 'Vue sur le terrain de golf',

    baignade: 'Baignade',
    balades_en_bateau: 'Balades en bateau',
    bateau: 'Bateau',
    bouee_tractee: 'Bouée tractée',
    canoe: 'Canoé',
    jet_ski: 'Jet-ski',
    kayak: 'Kayak',
    marina: 'Marina',
    paddle: 'Paddle',
    planche_a_voile: 'Planche à voile',
    plongee: 'Plongée',
    plongee_avec_tuba: 'Plongée avec tuba',
    sports_aquatiques: 'Sports aquatiques',
    surf: 'Surf',
    voile: 'Voile',
    produits_indipensables_plage: 'Produits indispensables pour la plage',

    alpinisme: 'Alpinisme',
    casier_a_skis: 'Casier à skis',
    luge: 'Luge',
    motoneige: 'Motoneige',
    patinage_sur_glace: 'Patinage sur glace',
    ski: 'Ski',
    ski_de_fond: 'Ski de fond',
    ski_nautique: 'Ski nautique',
    snowboard: 'Snowboard',

    chasse: 'Chasse',
    cours_de_tennis: 'Cours de tennis',
    equipement_fitness: 'Equipement fitness',
    salle_de_gym: 'Salle de gym',
    equitation: 'Equitation',
    escalade: 'Escalade',
    mountain_climbing: 'Escalade en montagne',
    rock_climbing: 'Escalade',

    golf: 'Golf',
    peche: 'Pêche',
    ping_pong: 'Ping Pong',
    rafting: 'Rafting',
    randonnee: 'Randonnée',
    table_de_billard: 'Table de billard',
    terrain_de_basketball: 'Terrain de basketball',
    terrain_de_petanque: 'Terrain de pétanque',
    cyclisme: 'Cyclisme',
    velo: 'Vélo',
    vtt: 'VTT',

    massage: 'Massage',
    hammam: 'Hammam',
    massage_sur_demande: 'Massage sur demande',
    salle_de_massage: 'Salle de massage',
    sauna: 'Sauna',
    solarium: 'Solarium',
    spa_a_proximite: 'Spa à proximitée',

    casino: 'Casino',
    magasins_antiquites: "Magasins d'antiquités",
    magasins_usine: "Magasins d'usine",
    magasins_duty_free: 'Magasins duty-free',
    musees: 'Musées',
    observation_faune_flore: 'Observation de la faune et de la flore',
    observation_des_baleines: 'Observation des baleines',
    observation_des_oiseaux: 'Observation des oiseaux',
    parachute_ascensionnel: 'Parachute ascensionnel',
    parc_aquatique: 'Parc aquatique',
    parc_attraction: "Parc d'attraction",
    shopping: 'Shopping',
    speleologie: 'Spéléologie',
    visites_de_vignobles: 'Visites de vignobles',

    bibliotheque_musicale: 'Bibliothèque musicale',
    videotheque: 'Vidéothèque',
  },
  notifications: {
    new_onboarding: "Création de l'onboarding de {0} {1} - {2}",
    new_accommodation: 'Nouveau logement',
    waiting_for_sign: 'Votre onboarding est en attente de signature',
    waiting_for_register: 'Inscrire le mandat {0} au registre papier',
    document_to_validate: "Valider les documents de l'onboarding de {0}",
    document_validated: 'Vos documents ont été approuvés',
    document_validated_for_gav: 'Les documents de {0} ont été approuvés par le support',
    document_refused: 'Vos documents ont été refusés',
    audit_validated_by_owner: '{0} a validé son audit',
    audit_validated_by_gav: 'Votre audit a été validé',
    audit_photo_for_gas: "L'agence {0} vient de terminer l'audit de {1}",
    audit_photo_for_hote: "L'annonce de votre onboarding est en cours de création",
    price_setted_for_pole_rm: "Fixer les prix de l'onboarding de {0}",
    homebook_to_create_for_pole_hote: "Créer le homebook de l'onboarding de {0}",
    homebook_created_for_gav: "Le homebook a été crée pour l'onboarding de {0}",
    homebook_created_for_hote: 'Le homebook de votre onboarding a été crée',
    ad_published_for_gav: "L'annonce a été publiée pour l'onboarding de {0}",
    ad_published_for_hote: "L'annonce de votre onboarding a été publiée",
    notif_ticket_created: 'Un nouveau ticket a été crée : {0}',
    notif_ticket_pole_change: 'Le ticket {0} a été affecté au pole {1}',
    notif_ticket_attribution: 'Le ticket {0} vous a été affecté',
    notif_ticket_priority_change: 'La priorité du ticket {0} est passée de {1} à {2}',
    notif_ticket_renewed: 'Le ticket {0} a été réouvert',
    notif_ticket_started: 'Le ticket {0} a été démarré',
    notif_ticket_closed: 'Le ticket {0} a été fermé',
    notif_ticket_to_be_validate: '{0} est en attente de validation de votre part pour le ticket {1}',
    notif_ticket_validated: 'Votre ticket {0} a été validé par {1}',
    notif_ticket_refused: 'Votre ticket {0} a été refusé par {1}',
    notif_litige_completed: 'Le dossier de litige de la réservation {0} est complet',
    notif_litige_complete_validated: 'Le dossier de litige de la réservation {0} a été validé',
    notif_litige_complete_refused: 'Le dossier de litige de la réservation {0} a été refusé',
  },
  onboardingsuivi: {
    title: 'Suivi des onboardings',
    completeBtn: "C'est fait !",
    completeTitle: 'Effectué par :',
    user: 'Utilisateur',
    dateDone: "Date d'accomplissement",
    done_by: 'Réalisé par {0}, le {1}',
    waiting_validation_guestadom: 'Etape en attente de validation par le support Guestadom',
    error_document_required: "Tout les documents sont requis pour passer à l'étape suivante",
    error_no_mandat: "L'onboarding n'a pas de mandat",
    error_no_mandat_num: "Le mandat relié n'a pas de numéro de mandat",
    error_mandat_num_duplicated: 'Ce numéro de mandat existe déjà',
    success_add_unavailability:
      'Votre indisponibilité à bien été prise en compte, le pôle hôte va traiter votre demande. Vous pourrez voir votre indisponibilité une fois la demande traitée dans votre calendrier',
  },
  onboardingsuivi_action: {
    ETP0_CREATE: "Création de l'onboarding",
    ETP1_WAITING_FOR_SIGN: "En attente de signature de l'hôte",
    ETP2_REGISTER_ENTRY: 'Inscription dans le registre papier',
    ETP3_WAITING_FOR_DOCUMENT: "En attente des documents de l'hôte",
    ETP4_DOCUMENT_TO_VALIDATE: 'Documents à valider',
    ETP5_AUDIT_IN_PROGRESS: 'Audit du logement',
    ETP6_CURRENTLY_CREATING: "Création de l'annonce",
    ETP7_AD_VALIDATED: 'Annonce en attente de validation',
    ETP8_AD_REFUSED: 'Annonce refusée',
    ETP9_PRICE_SETTING: 'Fixation des prix',
    ETP10_HOMEBOOK_CREATION: 'Création du Homebook',
    ETP11_TOURIST_TAX: 'Taxe de séjour',
    ETP12_PUBLICATION: 'Publication',
    ETP13_OPTIMISATION: 'Optimisation',
  },
  tasktype: {
    picture: 'Prise de photo',
    picture_text: 'Prise de photo avec commentaire',
    yesno: 'Oui/Non',
    done: 'Fait/Pas fait',
    done2: 'Contrôle ménage',
    free_text: 'Texte libre',
    date: 'Champ date',
    rating: 'Echelle de 1 à 5',
    simple_option: 'Choix unique',
    multi_option: 'Choix multiple',
  },
  search: {
    results: 'Résultats de la recherche',
    here: 'Rechercher ici',
    search: 'Rechercher',
    filter: 'Filtrer',
  },
  fee: {
    update: 'Modifier',
    period: 'Quinzaine',
    period_start: 'Début de quinzaine',
    period_end: 'Fin de quinzaine',
    reservationNb: 'Nb de réservations',
    empty: 'Aucune commission',
    stayTax: 'Taxe de séjour',
    plateformFee: 'Frais de plateforme',
    amountStay: 'Montant de séjour',
    gain: 'Gain',
    gainGa: 'Gain de Guestadom',
    gainGas: 'Gain de Guestadom Support',
    gainGav: 'Gain de Guestadom Ville',
    gainOwner: "Gain de l'hôte",
    feeStart: 'Frais de lancement',
    feeStartGas: 'Frais de lancement (GAS)',
    feeStartGav: 'Frais de lancement (GAV)',
    feeWelcome: "Frais d'accueil",
    fee_operationel_owner: 'Frais opérationnel hôte',
    fee_housework_interlocatif: 'Frais de ménage interlocatif',
    fee_housework_interlocatif_owner: "Pris en charge par l'hôte",
    fee_housework_interlocatif_voyageur: 'Pris en charge par le voyageur',
    feeHousework: 'Frais de ménage voyageur',
    feeHouseworkOwner: 'Forfait ménage départ hôte',
    feeService: 'Frais de service',
    feeCancel: "Frais d'annulation GAS",
    feeLaundry: 'Frais de blanchisserie',
    fee_assurance: "Frais d'assurance",
    fee_wintering_open: 'Frais ouverture hivernage',
    fee_wintering_close: 'Frais fermeture hivernage',
    clientToOwner: "A verser à l'hôte",
    clientToGav: 'A verser à GAV (voyageur)',
    clientToGas: 'A verser à GAS (voyageur)',
    ownerToGav: 'A verser à GAV (hôte)',
    ownerToGas: 'A verser à GAS (hôte)',
    startDate: 'Date de début',
    endDate: 'Date de fin',
  },
  mandat: {
    add: 'Créer un mandat',
    details: 'Mandat',
    resiliation: 'Résiliation de mandat',
    fee_owner_cancel: "Frais d'annulation",
    update: 'Modifier un mandat',
    update_bank_info: 'Modifier les infos bancaires',
    bank_info_updated: 'Les infos bancaires ont bien été modifiées',
    updated: 'Mandat modifié',
    empty: 'Aucun mandat',
    added: 'Mandat ajouté',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer ce mandat ?',
    avenant_confirm_delete: 'Attention, vous allez supprimer un avenant, êtes vous sûr de vouloir le supprimer ?',
    documentModel: 'Modèle de document',
    num: 'Numéro de mandat',
    type: 'Type de mandat',
    duration: 'Durée de mandat',
    renew: 'Tacite reconduction',
    houseworkFeeForOwner: "Frais de ménage pour l'hôte",
    dateStart: 'Début de mandat',
    datePeriodNew: 'Début de la nouvelle période',
    datePeriod: 'Période depuis le',
    dateEnd: 'Fin de mandat',
    dateReception: 'Réception de la lettre',
    dateResiliation: 'Date de résiliation',
    datePreavis: 'Date de début de préavis',
    signDate: 'Date de signature',
    companyName: 'Agence liée au mandat',
    type_courte_duree: 'Courte durée',
    type_moyenne_duree: 'Moyenne durée',
    type_longue_duree: 'Longue durée',
    type_gestion_en_ligne: 'Gestion en ligne',
    COURTE_DUREE: 'Courte durée',
    MOYENNE_DUREE: 'Moyenne durée',
    LONGUE_DUREE: 'Longue durée',
    GESTION_EN_LIGNE: 'Gestion en ligne',
    duration_year1: '1 an',
    duration_year3: '3 ans',
    duration_year5: '5 ans',
    amountThreshold: 'Minimum de facturation',
    comGa: 'Commission totale (%)',
    comGas: 'Commission GAS (%)',
    comGav: 'Commission GAV (%)',
    file_signed: 'Fichier PDF signé',
    iban: 'IBAN',
    bic: 'BIC',
    offer: 'Offre du mandat',
    gestionLinge: 'Gestion du linge',
    reception_type_physical: 'Gestion des accueils physiques',
    resilation_action: 'Résilier',
    resilation_action2: 'Résilier le mandat',
    upload_signed: "Dépot d'avenant signé",
    upload_signed_action: 'Déposer',
    upload_signed_action2: "Déposer l'avenant signé",
    lettreResiliation: 'Courrier de résiliation',
    resiliationReason: 'Raison de la résiliation',
    feeHouseworkOwnerForfait: 'Forfait ménage départ hote ',
    cancelFee: "Frais d'annulation",
    new_mandat: 'Nouveau mandat',
    prixMinimumNuit: 'Prix minimum à la nuit',
    depotGarantie: 'Caution',
    caution: 'Caution',
    dureeSejourMinimum: 'Durée de séjour minimum',
    resiliationPlanningBlocked: 'Blocage complet du calendrier',
    status: 'Statut',
    num_avenant: "Numéro d'avenant",
    date_start_avenant: "Début de l'avenant",
    date_end_avenant: "Fin de l'avenant",
    duration_avenant: "Durée de l'avenant",
    add_avenant: 'Créer un avenant',
    avenant_added: 'Avenant ajouté',
    avenant_date_start: "Date de début d'avenant",
    avenant_date_end: "Date de fin d'avenant",
    avenant_duration: "Durée d'avenant",
    avenant_sign_title: 'Avenant du {dateStart} au mandat de gestion numéro {parentMandatNum}',
    avenant_dashboard_title: 'Avenant du {dateStart}',
    error_avenant_not_signed: "La signature de l'avenant est en cours de création, veuillez patienter puis réessayer",
    avenant_signed: "L'avenant a bien été signé.",
    mandat_list: 'Mandats',
    avenant_list: 'Avenants',
    RESILIE: 'Résilié',
    EN_LIGNE: 'En ligne',
    EN_COURS_DE_SIGNATURE: 'En cours de signature',
    housework_person: 'Personne en charge du ménage',
    all_avenants: 'Tous les avenants',
    all: 'Tous les mandats',
    enabled_only: 'Uniquement les mandats actifs',
    disabled_only: 'Uniquement les mandats inactifs',
    resiliation_pending: 'Uniquement les mandats en cours de résiliation',
    en_cours: 'Uniquement les mandats en cours',
    enabled_only_avenant: 'Uniquement les avenants actifs',
    disabled_only_avenant: 'Uniquement les avenants inactifs',
    resiliation_pending_avenant: 'Uniquement les avenants en cours de résiliation',
    en_cours_de_signature_avenant: 'Uniquement les avenants en cours de signature',
    last_crg_amount: 'Solde du dernier CRG',
    alert_last_crg_amount: 'Le solde du dernier crg est négatif',
    add_resiliation_bloc: 'Ajouter une période de blocage',
  },
  extra: {
    empty: 'Aucun extra',
    type: 'Type',
    price: 'Prix',
    tva: 'TVA',
    add: 'Ajouter un extra',
    update: 'Modifier un extra',
    added: 'Extra ajouté',
    updated: 'Extra modifié',
  },
  upsell: {
    list: 'Extras',
    empty: 'Aucun extra',
    type: 'Type',
    name: "Nom de l'extra",
    amount: 'Prix',
    ownerName: 'Hôte',
    accommodationName: 'Logement',
    companyName: 'Agence',
    checkinDate: 'Date de check-in',
    checkoutDate: 'Date de check-out',
    approbationDate: 'Date de traitement',
    buyDate: 'Date de commande',
    status: 'Statut',
    reservationLocalizer: 'Numéro de réservation',
    download: 'Exporter',
    filter: 'Filtrer',
    refused: 'Refusée',
    to_plan: 'A planifier',
    planned: 'Planifié',
    closed: 'Terminé',
    paid: 'Payé',
    refunded: 'Remboursé',
    denied: 'Refusé',
    requested: 'Demandé',
    list_creation_in_progress: 'Export en cours...',
  },
  period: {
    days: 'Jours',
    weeks: 'Semaines',
    months: 'Mois',
  },
  countries: {
    AF: 'Afghanistan',
    ZA: 'Afrique du Sud',
    AL: 'Albanie',
    DZ: 'Algérie',
    DE: 'Allemagne',
    AD: 'Andorre',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctique',
    AG: 'Antigua-et-Barbuda',
    SA: 'Arabie saoudite',
    AR: 'Argentine',
    AM: 'Arménie',
    AW: 'Aruba',
    AU: 'Australie',
    AT: 'Autriche',
    AZ: 'Azerbaïdjan',
    BS: 'Bahamas',
    BH: 'Bahreïn',
    BD: 'Bangladesh',
    BB: 'Barbade',
    BE: 'Belgique',
    BZ: 'Belize',
    BM: 'Bermudes',
    BT: 'Bhoutan',
    BY: 'Biélorussie',
    BO: 'Bolivie',
    BA: 'Bosnie-Herzégovine',
    BW: 'Botswana',
    BN: 'Brunéi Darussalam',
    BR: 'Brésil',
    BG: 'Bulgarie',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    BJ: 'Bénin',
    KH: 'Cambodge',
    CM: 'Cameroun',
    CA: 'Canada',
    CV: 'Cap-Vert',
    CL: 'Chili',
    CN: 'Chine',
    CY: 'Chypre',
    CO: 'Colombie',
    KM: 'Comores',
    CG: 'Congo-Brazzaville',
    CD: 'Congo-Kinshasa',
    KP: 'Corée du Nord',
    KR: 'Corée du Sud',
    CR: 'Costa Rica',
    HR: 'Croatie',
    CU: 'Cuba',
    CW: 'Curaçao',
    CI: 'Côte d’Ivoire',
    DK: 'Danemark',
    DJ: 'Djibouti',
    DM: 'Dominique',
    ES: 'Espagne',
    EE: 'Estonie',
    SZ: 'Eswatini',
    FJ: 'Fidji',
    FI: 'Finlande',
    FR: 'France',
    GA: 'Gabon',
    GM: 'Gambie',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GD: 'Grenade',
    GL: 'Groenland',
    GR: 'Grèce',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernesey',
    GN: 'Guinée',
    GQ: 'Guinée équatoriale',
    GW: 'Guinée-Bissau',
    GY: 'Guyana',
    GF: 'Guyane française',
    GE: 'Géorgie',
    GS: 'Géorgie du Sud et îles Sandwich du Sud',
    HT: 'Haïti',
    HN: 'Honduras',
    HU: 'Hongrie',
    IN: 'Inde',
    ID: 'Indonésie',
    IQ: 'Irak',
    IR: 'Iran',
    IE: 'Irlande',
    IS: 'Islande',
    IL: 'Israël',
    IT: 'Italie',
    JM: 'Jamaïque',
    JP: 'Japon',
    JE: 'Jersey',
    JO: 'Jordanie',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KG: 'Kirghizistan',
    KI: 'Kiribati',
    KW: 'Koweït',
    RE: 'La Réunion',
    LA: 'Laos',
    LS: 'Lesotho',
    LV: 'Lettonie',
    LB: 'Liban',
    LY: 'Libye',
    LR: 'Libéria',
    LI: 'Liechtenstein',
    LT: 'Lituanie',
    LU: 'Luxembourg',
    MK: 'Macédoine du Nord',
    MG: 'Madagascar',
    MY: 'Malaisie',
    MW: 'Malawi',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malte',
    MA: 'Maroc',
    MQ: 'Martinique',
    MU: 'Maurice',
    MR: 'Mauritanie',
    YT: 'Mayotte',
    MX: 'Mexique',
    MD: 'Moldavie',
    MC: 'Monaco',
    MN: 'Mongolie',
    MS: 'Montserrat',
    ME: 'Monténégro',
    MZ: 'Mozambique',
    MM: 'Myanmar (Birmanie)',
    NA: 'Namibie',
    NR: 'Nauru',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigéria',
    NU: 'Niue',
    NO: 'Norvège',
    NC: 'Nouvelle-Calédonie',
    NZ: 'Nouvelle-Zélande',
    NP: 'Népal',
    OM: 'Oman',
    UG: 'Ouganda',
    UZ: 'Ouzbékistan',
    PK: 'Pakistan',
    PW: 'Palaos',
    PA: 'Panama',
    PG: 'Papouasie-Nouvelle-Guinée',
    PY: 'Paraguay',
    NL: 'Pays-Bas',
    BQ: 'Pays-Bas caribéens',
    PH: 'Philippines',
    PL: 'Pologne',
    PF: 'Polynésie française',
    PR: 'Porto Rico',
    PT: 'Portugal',
    PE: 'Pérou',
    QA: 'Qatar',
    HK: 'R.A.S. chinoise de Hong Kong',
    MO: 'R.A.S. chinoise de Macao',
    RO: 'Roumanie',
    GB: 'Royaume-Uni',
    RU: 'Russie',
    RW: 'Rwanda',
    CF: 'République centrafricaine',
    DO: 'République dominicaine',
    EH: 'Sahara occidental',
    BL: 'Saint-Barthélemy',
    KN: 'Saint-Christophe-et-Niévès',
    SM: 'Saint-Marin',
    MF: 'Saint-Martin',
    SX: 'Saint-Martin (partie néerlandaise)',
    PM: 'Saint-Pierre-et-Miquelon',
    VC: 'Saint-Vincent-et-les-Grenadines',
    SH: 'Sainte-Hélène',
    LC: 'Sainte-Lucie',
    SV: 'Salvador',
    WS: 'Samoa',
    AS: 'Samoa américaines',
    ST: 'Sao Tomé-et-Principe',
    RS: 'Serbie',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapour',
    SK: 'Slovaquie',
    SI: 'Slovénie',
    SO: 'Somalie',
    SD: 'Soudan',
    SS: 'Soudan du Sud',
    LK: 'Sri Lanka',
    CH: 'Suisse',
    SR: 'Suriname',
    SE: 'Suède',
    SJ: 'Svalbard et Jan Mayen',
    SY: 'Syrie',
    SN: 'Sénégal',
    TJ: 'Tadjikistan',
    TZ: 'Tanzanie',
    TW: 'Taïwan',
    TD: 'Tchad',
    CZ: 'Tchéquie',
    TF: 'Terres australes françaises',
    IO: 'Territoire britannique de l’océan Indien',
    PS: 'Territoires palestiniens',
    TH: 'Thaïlande',
    TL: 'Timor oriental',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinité-et-Tobago',
    TN: 'Tunisie',
    TM: 'Turkménistan',
    TR: 'Turquie',
    TV: 'Tuvalu',
    UA: 'Ukraine',
    UY: 'Uruguay',
    VU: 'Vanuatu',
    VE: 'Venezuela',
    VN: 'Vietnam',
    WF: 'Wallis-et-Futuna',
    YE: 'Yémen',
    ZM: 'Zambie',
    ZW: 'Zimbabwe',
    EG: 'Égypte',
    AE: 'Émirats arabes unis',
    EC: 'Équateur',
    ER: 'Érythrée',
    VA: 'État de la Cité du Vatican',
    FM: 'États fédérés de Micronésie',
    US: 'États-Unis',
    ET: 'Éthiopie',
    BV: 'Île Bouvet',
    CX: 'Île Christmas',
    NF: 'Île Norfolk',
    IM: 'Île de Man',
    KY: 'Îles Caïmans',
    CC: 'Îles Cocos',
    CK: 'Îles Cook',
    FO: 'Îles Féroé',
    HM: 'Îles Heard et McDonald',
    FK: 'Îles Malouines',
    MP: 'Îles Mariannes du Nord',
    MH: 'Îles Marshall',
    PN: 'Îles Pitcairn',
    SB: 'Îles Salomon',
    TC: 'Îles Turques-et-Caïques',
    VG: 'Îles Vierges britanniques',
    VI: 'Îles Vierges des États-Unis',
    UM: 'Îles mineures éloignées des États-Unis',
    AX: 'Îles Åland',
  },
  languages: {
    ab: 'Abkhaze',
    aa: 'Afar',
    af: 'Afrikaans',
    ak: 'Akan',
    sq: 'Albanais',
    de: 'Allemand',
    am: 'Amharique',
    en: 'Anglais',
    ar: 'Arabe',
    an: 'Aragonais',
    hy: 'Arménien',
    as: 'Assamais',
    av: 'Avar',
    ae: 'Avestique',
    ay: 'Aymara',
    az: 'Azéri',
    ba: 'Bachkir',
    bm: 'Bambara',
    eu: 'Basque',
    bn: 'Bengali',
    bi: 'Bichelamar',
    bh: 'Bihari',
    my: 'Birman',
    be: 'Biélorusse',
    bs: 'Bosniaque',
    br: 'Breton',
    bg: 'Bulgare',
    ks: 'Cachemiri',
    ca: 'Catalan',
    ch: 'Chamorro',
    ny: 'Chewa',
    zh: 'Chinois',
    si: 'Cingalais',
    kw: 'Cornique',
    co: 'Corse',
    ko: 'Coréen',
    cr: 'Cree',
    hr: 'Croate',
    ht: 'Créole haïtien',
    da: 'Danois',
    dz: 'Dzongkha',
    es: 'Espagnol',
    eo: 'Espéranto',
    et: 'Estonien',
    fj: 'Fidjien',
    fi: 'Finnois',
    fr: 'Français',
    fy: 'Frison occidental',
    fo: 'Féroïen',
    gl: 'Galicien',
    cy: 'Gallois',
    lg: 'Ganda',
    gd: 'Gaélique écossais',
    gu: 'Goudjerati',
    el: 'Grec',
    kl: 'Groenlandais',
    gn: 'Guarani',
    ka: 'Géorgien',
    ha: 'Haoussa',
    hi: 'Hindi',
    ho: 'Hiri motu',
    hu: 'Hongrois',
    iw: 'Hébreu',
    he: 'Hébreu',
    hz: 'Héréro',
    io: 'Ido',
    ig: 'Igbo',
    id: 'Indonésien',
    in: 'Indonésien',
    ia: 'Interlingua',
    ie: 'Interlingue',
    iu: 'Inuktitut',
    ik: 'Inupiaq',
    ga: 'Irlandais',
    is: 'Islandais',
    it: 'Italien',
    ja: 'Japonais',
    jv: 'Javanais',
    kn: 'Kannada',
    kr: 'Kanouri',
    kk: 'Kazakh',
    km: 'Khmer',
    kg: 'Kikongo',
    ki: 'Kikuyu',
    rw: 'Kinyarwanda',
    ky: 'Kirghize',
    kv: 'Komi',
    kj: 'Kuanyama',
    ku: 'Kurde',
    lo: 'Lao',
    la: 'Latin',
    lv: 'Letton',
    li: 'Limbourgeois',
    ln: 'Lingala',
    lt: 'Lituanien',
    lu: 'Luba-katanga (kiluba)',
    lb: 'Luxembourgeois',
    mk: 'Macédonien',
    ms: 'Malais',
    ml: 'Malayalam',
    dv: 'Maldivien',
    mg: 'Malgache',
    mt: 'Maltais',
    gv: 'Mannois',
    mi: 'Maori',
    mr: 'Marathi',
    mh: 'Marshallais',
    mo: 'Moldave',
    mn: 'Mongol',
    na: 'Nauruan',
    nv: 'Navajo',
    ng: 'Ndonga',
    nd: 'Ndébélé du Nord',
    nr: 'Ndébélé du Sud',
    no: 'Norvégien',
    nb: 'Norvégien bokmål',
    nn: 'Norvégien nynorsk',
    nl: 'Néerlandais',
    ne: 'Népalais',
    oc: 'Occitan',
    or: 'Odia',
    oj: 'Ojibwa',
    om: 'Oromo',
    os: 'Ossète',
    ur: 'Ourdou',
    uz: 'Ouzbek',
    ug: 'Ouïghour',
    ps: 'Pachto',
    pi: 'Pali',
    pa: 'Pendjabi',
    fa: 'Persan',
    ff: 'Peul',
    pl: 'Polonais',
    pt: 'Portugais',
    qu: 'Quechua',
    rm: 'Romanche',
    ro: 'Roumain',
    rn: 'Roundi',
    ru: 'Russe',
    se: 'Same du Nord',
    sm: 'Samoan',
    sg: 'Sango',
    sa: 'Sanskrit',
    sc: 'Sarde',
    sr: 'Serbe',
    sn: 'Shona',
    sd: 'Sindhi',
    cu: 'Slavon d’église',
    sk: 'Slovaque',
    sl: 'Slovène',
    so: 'Somali',
    st: 'Sotho du Sud',
    su: 'Soundanais',
    sv: 'Suédois',
    sw: 'Swahili',
    ss: 'Swati',
    tg: 'Tadjik',
    tl: 'Tagalog',
    ty: 'Tahitien',
    ta: 'Tamoul',
    tt: 'Tatar',
    cv: 'Tchouvache',
    cs: 'Tchèque',
    ce: 'Tchétchène',
    th: 'Thaï',
    bo: 'Tibétain',
    ti: 'Tigrigna',
    to: 'Tongien',
    ts: 'Tsonga',
    tn: 'Tswana',
    tr: 'Turc',
    tk: 'Turkmène',
    tw: 'Twi',
    te: 'Télougou',
    uk: 'Ukrainien',
    ve: 'Venda',
    vi: 'Vietnamien',
    vo: 'Volapük',
    wa: 'Wallon',
    wo: 'Wolof',
    xh: 'Xhosa',
    ii: 'Yi du Sichuan',
    yi: 'Yiddish',
    ji: 'Yiddish',
    yo: 'Yoruba',
    za: 'Zhuang',
    zu: 'Zoulou',
    ee: 'Éwé',
  },
  idProofs: {
    idproof1: "Carte Nationale d'Identité",
    idproof2: 'Carte de séjour',
    idproof3: 'Carte de résident',
    idproof4: 'Récépissé',
    idproof5: 'Permis de conduire',
    idproof6: 'Passeport',
    idproof7: 'Visa',
    idproof8: "Carte de séjour 'vie privée et familiale'",
    idproof9: "Carte de séjour 'salarié'",
    idproof10: "Carte de séjour 'étudiant'",
    idproof11: "Carte de séjour 'travailleur temporaire'",
    idproof12: "Carte de séjour 'travailleur saisonnier'",
    idproof13: "Autorisation provisoire de séjour portant la mention 'autorise son titulaire à travailler'",
    idproof14: 'Certificat de résidence Algérien',
  },
  societe: {
    formeJuridiqueDeLaSociete: 'Forme juridique de la sociéte',
    raisonSocialeDeLaSociete: 'Raison sociale de la sociéte',
    capital: 'Capital de la société',
    adresseDuSiegeSocial: 'Adresse du siège social',
    complementDAdresseSiegeSocial: "Complément d'adresse siège social",
    cpDuSiegeSocial: 'CP du siège social',
    villeDuSiegeSocial: 'Ville du siège social',
    lieuDuRcs: 'Lieu du rcs',
    numeroDuRcs: 'Numéro du rcs',
    nomDuGerant: 'Nom du gérant',
    prenomDuGerant: 'Prénom du gérant',
    fonctionDuRepresentantDeLaSociete: 'Fonction du représentant de la sociéte',
    numeroCarteProfessionnelImmobiliere: 'Numéro de la carte professionnelle immobilière',
    dateDeDelivranceCarteProfessionnelImmobiliere: 'Date de délivrance de la carte professionnelle immobilière',
    dateDeValiditeCarteProfessionnelImmobiliere: 'Date de validité de la carte professionnelle immobilière',
    organismeDelivranceCarteProfessionnelImmobiliere: 'Organisme délivrance carte professionnelle immobilière',
    nomCaisseDeGarantie: 'Nom de la caisse de garantie',
    adresseCaisseDeGarantie: 'Adresse de la caisse de garantie',
    complementDAdresseCaisseDeGarantie: "Complément d'adresse caisse de garantie",
    cpCaisseDeGarantie: 'CP de la caisse de garantie',
    villeCaisseDeGarantie: 'Ville de la caisse de garantie',
    montantCaisseDeGarantie: 'Montant de la caisse de garantie',
    zoneGeographiqueCouvertureCaisseDeGarantie: 'Zone géographique couverture de la caisse de garantie',
    nomAssuranceRc: "Nom de l'assurance rc",
    adresseAssuranceRc: "Adresse de l'assurance rc",
    complementDAdresseAssuranceRc: "Complément d'adresse de l'assurance rc",
    cpAssuranceRc: "CP de l'assurance rc",
    villeAssuranceRc: "Ville de l'assurance rc",
    numeroDePoliceAssuranceRc: "Numéro de police de l'assurance rc",
    zoneDeGeographieCouvertureAssuranceRc: "Zone de geographie couverture de l'assurance rc",
    numeroTvaIntracommunautaire: 'Numéro TVA intracommunautaire',
    civiliteResponsableRgpd: 'Civilité du responsable RGPD',
    prenomResponsableRgpd: 'Prénom du responsable RGPD',
    nomResponsableRgpd: 'Nom du responsable RGPD',
    comGas: 'Commission GAS',
    comGa: 'Commission',
    amountMaxRenovation: 'Montant maximum de travaux',
    amountMaxEquipPurchase: "Montant maximum d'achat d'équipement",
    feeCancel: 'Pénalité annulation hôte - forfait',
    feeCancelCom: 'Pénalité annulation hôte - commission',
    feeLitigation: 'Frais de dossier litige',
    feeServiceVgaMinimum: 'Frais de service VGA - minimum',
    feeServiceVgaCom: 'Frais de service VGA - commission',
    siret: 'SIRET',
    siren: 'SIREN',
    ape: 'Code APE',
    numero_garantie: 'Numéro de garantie',
    numero_compte_sequestre: 'Numéro de compte séquestre',
    nom_banque_compte_sequestre: 'Nom de la banque du compte séquestre',
  },
  stayrate: {
    stayrate: 'Groupe de taxe de séjour',
    label: 'Libellé',
    codes: 'Codes INSEE',
    codesChip: 'Codes INSEE (terminer la saisie par une virgule)',
    account: 'Compte comptable',
    iban: 'IBAN',
    bic: 'BIC',
    empty: 'Aucun groupe de taxe de séjour',
    add: 'Ajouter un groupe de taxe de séjour',
    added: 'Le groupe de taxe de séjour a bien été ajouté',
    update: 'Modifier un groupe de taxe de séjour',
    updated: 'Le groupe de taxe de séjour a bien été modifié',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer ce groupe de taxe de séjour ?',
    frequency: 'Périodicité de versement',
    frequency_month_6: '6 mois',
    frequency_year_1: '1 an',
    type: 'Type de taxe de séjour',
    regional: 'Additionnelle',
    city: 'Ville',
    avantio_label: 'Libellé Avantio',
  },
  dashboard: {
    accommodation_status: 'Statuts de vos logements',
    avenants_to_sign: 'Avenant au contrat à signer',
    accommodation_reviews: 'Avis de vos logements',
  },
  attribution: {
    business_developer: 'Business developer',
    city_manager: 'City manager',
    account_manager: 'Account manager',
    distribution_manager: 'Distribution manager',
  },
  crg: {
    crg: 'CRG',
    details: 'Détails du CRG',
    consultation: 'Consultation',
    generation: 'Génération',
    closing: 'Clôture en cours',
    regenerating: 'Régénération en cours',
    payment: 'Virements',
    payment_details: 'Détails du virement',
    historique: 'Historique',
    payment_crg: 'Virement de CRG',
    date: 'Date',
    date_resa_end: 'Date du crg',
    date_required: 'La date est requise',
    date_generated: 'Date de génération',
    date_closed: 'Date de clôture',
    number: 'Nombre de CRGs',
    mandatNums: 'Numéros des mandats concernés',
    label: 'Libellé',
    success_update_num_virement: 'Numéro de virement renseigné',

    generate_one: 'Générer',
    regenerate_one: 'Regénérer',
    close_one: 'Clôturer',

    generate_many: 'Générer les CRGs',
    regenerate_many: 'Regénérer les CRGs',
    close_many: 'Clôturer les CRGs',

    confirm_generate_one: 'Êtes-vous sûr de vouloir générer un nouveau CRG ?',
    confirm_regenerate_one: 'Êtes-vous sûr de vouloir regénérer ce CRG ?',
    confirm_close_one: 'Êtes-vous sûr de vouloir clotûrer ce CRG ?',

    confirm_generate_many: 'Êtes-vous sûr de vouloir générer de nouveaux CRGs ?',
    confirm_regenerate_many: 'Êtes-vous sûr de vouloir regénérer ces CRGs ?',
    confirm_close_many: 'Êtes-vous sûr de vouloir clotûrer ces CRGs ?',

    generated_one: 'CRG généré',
    regenerated_one: 'CRG regénéré',
    closed_one: 'CRG clôturé',

    generated_many: 'CRGs générés',
    regenerated_many: 'CRGs regénérés',
    closed_many: 'CRGs clôturés',

    error_missing_iban: "Clôture impossible. L'IBAN est manquant sur le mandat ",
  },
  amountThreshold: {
    add: 'Ajout un seuil de facturation',
    update: 'Modifier un seuil de facturation',
    delete: 'Supprimer un seuil de facturation',
    label: 'Libellé',
    amountGa: 'Seuil minimal de facturation',
    amountGav: 'Montant reversé à GAV',
  },
  litige: {
    list: 'Litiges',
    empty: 'Aucun litige',
    add: 'Ajouter un litige',
    update: 'Modifier un litige',
    delete: 'Supprimer un litige',
    details: 'Détails du litige',
    status: 'Statut du litige',
    status_list: 'Statut',
    date: 'Date de litige',
    status_1: 'Statut n°1',
    status_2: 'Statut n°2',
    status_3: 'Statut n°3',
    comment: 'Commentaire',
    added: 'Votre litige a bien été créé',
    updated: 'Le litige a bien été modifié',
    list_creation_in_progress: 'Fichier en cours de création',
    accommodation: 'Logement',
    reservation: 'Réservation',
    error_empty_files: 'Le champ Fichier est requis',
    error_missing_fields: 'Le lien ou la facture sont requis',
    error_missing_amount: 'Le montant estimé est requis',
    date_decision: 'Date de décision',
    resolution: 'Résolution',
    reason: 'Raison',
    description: 'Description',
    amount_estimated_ttc: 'Montant estimé TTC',
    total_amount_estimated_ttc: 'Montant total estimé TTC',
    amount_ttc: 'Montant réellement encaissé TTC',
    amount_gas: 'Part pour GAS',
    amount_gav: 'Part pour GAV',
    amount_owner: "Part pour l'hôte",
    link: 'Lien',
    trait_litige: 'Traiter le litige',
    traited: 'Le litige a bien été traité',
    file: 'Facture',
    resolve: 'Résoudre le litige',
    resolved: 'Le litige a bien été résolu',
    gagne: 'Gagné',
    perdu: 'Perdu',
    a_resoudre: 'A résoudre',
    a_traiter: 'A traiter',
    en_cours: 'En cours',
    resolu: 'Résolu',
    description_litige: 'Description du litige',
    description_maintenance: 'Description de la maintenance',
    problem_maintenance: 'Problème de la maintenance',
    date_maintenance: 'Date de découverte de la maintenance',
    date_declaration: 'Date de déclaration du litige',
    litige_complete: 'Dossier complet',
    litige_complete_validated: 'Dossier validé',
    litige_complete_refused: 'Dossier refusé',
    litige_completed: 'Le dossier a été correctement complété',
    validate_complete: 'Valider le dossier',
    refuse_complete: 'Refuser le dossier',
    complete_status: 'Statut du dossier',
    complete_date: 'Date de revue du dossier',
    complete_validated: 'Le dossier a été validé',
    complete_status_validated: 'Validé',
    complete_refused: 'Le dossier a été refusé',
    complete_status_refused: 'Refusé',
    refused_comment: 'Commentaire de refus',
    step_validated: 'Etape validée',
    waiting_gav_completion: 'Dossier en attente de complétion par la ville',
    waiting_gas_validation: 'Dossier en attente de validation par le support Guestadom',
  },
  interlocutor: {
    interlocutor: 'Interlocuteur',
    interlocutorType: "Type d'interlocuteur",
    proprietaire: 'Hôte',
    prospect: 'Prospect',
    salarie: 'Salarié',
    candidat: 'Candidat',
    voyageur: 'Voyageur',
    prestataire: 'Prestataire',
    partenaire: 'Partenaire',
    housekeeper: 'Concierge',
    accommodation: 'Logement',
    reservation: 'Réservation',
  },
  planning_accommodation: {
    title: 'Planning logement',
    last_week: 'Semaine dernière',
    next_week: 'Semaine prochaine',
    search: 'Rechercher un logement',
    filter: 'Filtrer',
    assigned_staff: 'Personne affectée',
    status: 'Statut',
    company: 'Agence',
    mission_type: 'Type de mission',
    accommodation_name: 'Nom du logement',
  },
  planning_intervenant: {
    title: 'Planning des intervenants',
    last_week: 'Semaine dernière',
    next_week: 'Semaine prochaine',
    current_week: 'Semaine actuelle',
    search: 'Rechercher un intervenant',
    filter: 'Filtrer',
    assigned_staff: 'Type de personnel affecté',
    status: 'Statut',
    company: 'Agence',
    mission_type: 'Type de mission',
    participant: 'Intervenant',
    reservation: 'Numéro de réservation',
    accommodation: 'Logement',
    planned_start_date: 'Date de début prévue',
    duration: 'Durée prévue',
    duration_minutes: 'Durée prévue (en minutes)',
    nb_participants_needed: 'Nombre de personnes requises',
    schedule: 'Planifier',
    schedule_mission: 'Planifier une mission',
    start_date: 'Date de début de la mission',
    start_hour: 'Heure de début',
    end_hour: 'Heure de fin',
    participant_name: "Nom de l'intervenant",
    comment: "Commentaire pour l'intervenant",
    success: 'La mission a bien été affectée aux intervenants',
    hour_end_error: "L'heure de fin ne peut pas être inférieure à celle de début",
    duration_error: "La durée ne peut pas être inférieure à celle prévue par l'ordre de mission",
  },
  mission_monitoring: {
    title: 'Suivi de missions',
    time_start_low_margin: 'Heure de début (marge basse)',
    time_start_high_margin: 'Heure de début (marge haute)',
    time_end_low_margin: 'Heure de fin (marge basse)',
    time_end_high_margin: 'Heure de fin (marge haute)',
    filter: 'Filtrer',
    todo: 'A faire',
    doing: 'En cours',
    done: 'Terminé',
    finish: 'Terminée',
    canceled: 'Annulée',
    status: 'Statut de la mission',
    empty: 'Aucune mission',
    prestation_date: 'Date de prestation',
    prestation_type: 'Type de prestation',
    accommodation_name: 'Logement',
    reservation_num: 'Numéro de réservation',
    date_checkin: 'Date de check-in',
    date_checkout: 'Date de check-out',
    cancel_mission: 'Annulation de la mission',
    cancel_comment: "Raison de l'annulation",
    success_complete: 'La mission a bien été marquée comme "Terminée"',
    success_cancel: 'La mission a bien été marquée comme "Annulée"',
  },
  undetermined_date: 'Date indéterminée',
  messaging: 'Messagerie',
  revenueManagement: 'Revenue Management',
  review: {
    title: 'Avis',
    filter: 'Filtrer',
    export: 'Exporter',
    list_creation_in_progress: 'Export en cours...',
    empty: 'Aucun avis',
    reservation_num: 'Numéro de réservation',
    rating: 'Note',
    rating_ten: 'Note globale (sur 10)',
    review_title: 'Titre',
    reservation_site: 'Plateforme',
    company_name: 'Agence',
    accommodation_name: 'Nom du logement',
    owner_name: "Nom de l'hôte",
    submitted_at: "Date de dépôt de l'avis",
    name: 'Voyageur',
    companies: 'Agences',
    reservation_sites: 'Plateformes',
    accommodations: 'Logements',
    details: "Détails de l'avis",
    data: "Données de l'avis",
    comment: 'Commentaire',
    public_comment: 'Commentaire public',
    private_comment: 'Commentaire privé',
    criterias: 'Critères',
    criteria: {
      cleanliness: 'Propreté',
      checkin: 'Arrivée',
      communication: 'Communication',
      location: 'Emplacement',
      facilities: 'Équipements',
      comfort: 'Confort',
      staff: 'Personnel',
      value_for_money: 'Qualité-prix',
      accuracy: 'Précision',
      property_condition: 'État de la propriété',
      eco_friendliness: "Respect de l'environnement",
    },
    fetching: 'Récupération en cours...',
  },
  anomaly: 'Anomalie',
};
