<script>
/* COMPOSANTS */
import GaView from '@components/layout/GaView';
import PButton from 'primevue/button';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import clientTypeConst from '@mixins/const/clientTypeConst';
import civilitiesConst from '@mixins/const/civilitiesConst';
import yesNoConst from '@mixins/const/yesNoConst';
import permissionsMixin from '@mixins/permissionsMixin';
import {PermissionMap} from '@mixins/const/permissionConst';
import sidebarMixin from '@mixins/sidebarMixin';

/* SERVICES */
import clientService from '@services/clientService';
import itemLabelService from '@services/itemLabelService';

export default {
  components: {GaView, PButton},
  mixins: [civilitiesConst, clientTypeConst, randomRef, utilsMixin, yesNoConst, permissionsMixin, sidebarMixin],
  metaInfo() {
    return {
      title: 'client.list',
    };
  },
  data() {
    return {
      loading: true,
      listOriginMarketing: [],
      listProfileClient: [],
      list: [],
      totalItems: 0,
      item: {},
      filter: {
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      },
    };
  },
  mounted() {
    let p1 = clientService.clientsPagination(this.filter).then((data) => {
      this.list = data.data;
      this.totalItems = data.totalItems;
    });

    let p2 = itemLabelService
      .itemLabels({type: 'PROFILE_CLIENT'})
      .then((data) => {
        this.listProfileClient = data;
      })
      .then((data) => {
        itemLabelService.itemLabels({type: 'ORIGIN_MARKETING'}).then((data) => {
          this.listOriginMarketing = data;
        });
      });

    Promise.all([p1, p2]).then(() => {
      this.loading = false;
    });
  },
  methods: {
    onPage(event) {
      this.filter.page = event.page + 1;
      this.filter.limit = event.rows;
      this.search();
    },
    search() {
      this.loading = true;
      clientService.clientsPagination(this.filter).then((data) => {
        this.list = data.data;
        this.totalItems = data.totalItems;
        this.loading = false;
      });
    },
    onSort(event) {
      this.filter.sort = [];
      for (let sort of event.multiSortMeta) {
        this.filter.sort.push('' + sort.field + ':' + (sort.order === -1 ? 'DESC' : 'ASC'));
      }
      if (this.filter.sort.length === 0) {
        this.filter.sort.push('id:DESC');
      }
      this.search();
    },
    submitForm() {
      this.onPage({page: 0, rows: 50});
    },
    rowClick(event) {
      let data = event.data;
      this.$router.push({name: 'clientPage', params: {clientId: data.id}});
    },
    resetFilter() {
      this.filter = {
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      };
      this.toggleFilter('GaClientListFilter', this.filter, this.search, this.resetFilter);
    },
    openFilterPanel() {
      this.toggleFilter('GaClientListFilter', this.filter, this.search, this.resetFilter);
    },
  },
  computed: {
    hasManagePermission() {
      return this.hasPermission(PermissionMap.CLIENT.MANAGE);
    },
  },
};
</script>
<template>
  <GaView :ref="ref" :title="$t('client.list')">
    <template #action>
      <div class="d-flex justify-content-end" style="gap: 16px">
        <PButton
          :badge="this.countFilter(this.filter)"
          badgeClass="p-badge-primary"
          class="btn btn-primary"
          label="Filtrer"
          @click="openFilterPanel()" />
        <router-link v-if="hasManagePermission" :to="{name: 'clientCreate'}" class="btn btn-primary">
          <i class="gst-plus pr-1" />
          {{ $t('client.add') }}
        </router-link>
      </div>
    </template>
    <template #content>
      <DataTable
        :always-show-paginator="false"
        :loading="loading"
        :paginator="true"
        :rows="50"
        :rows-per-page-options="[10, 20, 50]"
        :value="list"
        class="table pointer"
        current-page-report-template="{first}-{last}/{totalRecords}"
        removable-sort
        responsive-layout="scroll"
        striped-rows
        @row-click="rowClick($event)"
        :total-records="totalItems"
        sort-mode="multiple"
        lazy
        @page="onPage($event)"
        @sort="onSort($event)">
        <template #empty>
          {{ $t('client.empty') }}
        </template>
        <Column :header="$t('client.name')" :sortable="true" field="first_name">
          <template #body="slotProps">
            {{ slotProps.data.display }}
          </template>
        </Column>
        <Column :header="$t('client.email')" :sortable="true" field="email">
          <template #body="slotProps">
            {{ slotProps.data.email }}
          </template>
        </Column>
        <Column :header="$t('client.phone')" :sortable="true" field="phone">
          <template #body="slotProps">
            {{ slotProps.data.phone }}
          </template>
        </Column>
        <Column body-style="text-align: center; overflow: visible" header-style="width: 150px; text-align: center">
          <template #body="slotProps">
            <div class="d-flex justify-content-end align-items-center">
              <router-link
                :to="{name: 'clientPage', params: {clientId: slotProps.data.id}}"
                class="btn btn-xs btn-inverse-primary mr-1">
                <i class="fe fe-eye" />
              </router-link>
              <router-link
                v-if="hasManagePermission"
                :to="{name: 'clientUpdate', params: {clientId: slotProps.data.id}}"
                class="btn btn-xs btn-inverse-primary">
                <i class="fe fe-edit" />
              </router-link>
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
  </GaView>
</template>

<style lang="scss" module>
.title {
  text-align: center;
}
</style>
