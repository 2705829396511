import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  accommodationMissionOrder(accommodationId, filter) {
    return Api()
      .get(`/mission-order/accommodation/${accommodationId}`, {params: removeEmptyParams(filter)})
      .then((res) => this.formatRes(res.data));
  },
  create(missionOrder) {
    return Api()
      .post('/mission-order', missionOrder)
      .then((res) => {
        return res.data;
      });
  },
  formatRes(e) {
    return e;
  },
};
