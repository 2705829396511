export default {
  data() {
    return {
      categoryConst: [
        {label: this.$t('employee.category1'), value: 'FIRST_CATEGORY'},
        {label: this.$t('employee.category2'), value: 'SECOND_CATEGORY'},
        {label: this.$t('employee.category3'), value: 'THIRD_CATEGORY'},
      ],
    };
  },
};
