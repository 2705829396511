export default {
  data() {
    return {
      usualTransportModeConst: [
        {label: this.$t('employee.personalVehicle'), value: 'PERSONAL_VEHICLE'},
        {label: this.$t('employee.companyVehicle'), value: 'COMPANY_VEHICLE'},
        {label: this.$t('employee.bicycle'), value: 'BICYCLE'},
        {label: this.$t('employee.onFoot'), value: 'ON_FOOT'},
        {label: this.$t('employee.publicTransport'), value: 'PUBLIC_TRANSPORT'},
      ],
    };
  },
};
