import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  amountThresholds(filter) {
    return Api()
      .get('/amountthresholds', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  amountThreshold(id) {
    return Api()
      .get('/amountthreshold/' + id)
      .then((res) => this.formatRes(res.data));
  },
  create(amountThreshold) {
    return Api()
      .post('/amountthreshold', amountThreshold)
      .then((res) => this.formatRes(res.data));
  },
  update(amountThreshold) {
    return Api()
      .put('/amountthreshold/' + amountThreshold.id, amountThreshold)
      .then((res) => this.formatRes(res.data));
  },
  delete(amountThreshold) {
    return Api()
      .delete('/amountthreshold/' + amountThreshold.id)
      .then(() => amountThreshold);
  },
  formatRes(e) {
    return e;
  },
};
