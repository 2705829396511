export default {
  data() {
    return {
      otherCategoryConst: [
        {label: this.$t('employee.otherCategory1'), value: 'WAR_DISABLED'},
        {label: this.$t('employee.otherCategory2'), value: 'ASSIMILATED_WAR_DISABLED'},
        {label: this.$t('employee.otherCategory3'), value: 'VOLUNTEER_FIREFIGHTER'},
      ],
    };
  },
};
