export default {
  data() {
    return {
      maritalStatusConst: [
        {label: this.$t('employee.married'), value: 'MARRIED'},
        {label: this.$t('employee.divorced'), value: 'DIVORCED'},
        {label: this.$t('employee.widower'), value: 'WIDOWER'},
        {label: this.$t('employee.single'), value: 'SINGLE'},
        {label: this.$t('employee.maritalLife'), value: 'MARITAL_LIFE'},
        {label: this.$t('employee.pacs'), value: 'PACS'},
        {label: this.$t('employee.separated'), value: 'SEPARATED'},
      ],
    };
  },
};
