<script>
import useVuelidate from '@vuelidate/core';

/* COMPOSANTS */
import AkInputMoney from '@components/input/AkInputMoney.vue';
import AkInputText from '@components/input/AkInputText.vue';
import AkFicList from '@components/layout/AkFicList.vue';
import AkInputTextArea from '@components/input/AkInputTextArea.vue';
import AkCalendar from '@components/input/AkCalendar.vue';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';

/* SERVICES */
import permissionService from '@services/permissionService';

export default {
  components: {AkCalendar, AkFicList, AkInputText, AkInputMoney, AkInputTextArea},
  mixins: [permissionsMixin],
  props: {
    modelValue: {
      required: true,
    },
    submitted: {
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    index: {
      type: Number,
      required: true,
    },
    displayMaintenanceFiles: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayMaintenanceDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    canDeleteFiles: {
      type: Boolean,
      required: false,
      default: true,
    },
    canUploadFiles: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },
  mounted() {
    if (!this.currentValue.ficList) this.currentValue.ficList = [];
  },
  data() {
    return {};
  },
  watch: {
    'currentValue.amountEstimatedTtc'(newVal, oldVal) {
      if (newVal !== oldVal) this.$emit('amount-changed');
    },
  },
  emits: ['removed', 'amount-changed'],
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    permissionsMixin() {
      return permissionsMixin;
    },
    permissionService() {
      return permissionService;
    },
    title() {
      return 'Article ' + (this.index + 1);
    },
    canUpload() {
      return this.canUploadFiles && this.currentValue.ficList?.length === 0;
    },
  },
  methods: {
    removed() {
      this.$emit('removed');
    },
    linkExternal(link) {
      return {path: link, icon: 'fas fa-link color-label'};
    },
  },
  validations() {
    return {
      currentValue: {
        amountEstimatedTtc: {},
        link: {},
        ficList: {},
      },
    };
  },
};
</script>

<template>
  <section>
    <h5>{{ title }}</h5>

    <div class="form-row">
      <AkCalendar
        v-model="currentValue.maintenanceDate"
        :label="$t('litige.date_maintenance')"
        :placeholder="$t('litige.date_maintenance')"
        date-format="dd/mm/yy"
        selection-mode="single"
        class-name="col-6"
        :disabled="true" />
      <AkCalendar
        v-model="currentValue.dateCreated"
        :label="$t('litige.date_declaration')"
        :placeholder="$t('litige.date_declaration')"
        date-format="dd/mm/yy"
        selection-mode="single"
        class-name="col-6"
        :disabled="true" />
    </div>
    <div class="form-row">
      <AkInputText
        v-model="currentValue.maintenanceProblem"
        :label="$t('litige.problem_maintenance')"
        :placeholder="$t('litige.problem_maintenance')"
        class-name="col-12"
        :disabled="true" />
    </div>
    <div class="form-row">
      <AkInputTextArea
        v-model="currentValue.maintenanceDescription"
        :label="$t('litige.description_maintenance')"
        :placeholder="$t('litige.description_maintenance')"
        class-name="col-12"
        :disabled="true" />
    </div>

    <!-- Photos maintenance -->

    <AkFicList
      v-if="currentValue.maintenanceId && displayMaintenanceFiles"
      v-model="currentValue.maintenanceFicList"
      :table-id="currentValue.maintenanceId"
      accept=".png, .jpeg, .jpg"
      table-name="maintenance"
      :display-title="false"
      :fic-preview="true"
      :canDelete="false"
      :canUpload="false"
      :label="$t('maintenance.photos')"
      :displayLabel="false" />

    <section class="litige-item-traitement">
      <div class="form-row">
        <AkInputMoney
          v-model="currentValue.amountEstimatedTtc"
          :label="$t('litige.amount_estimated_ttc')"
          :placeholder="$t('litige.amount_estimated_ttc')"
          class-name="col-4"
          :submitted="submitted"
          :disabled="disabled" />
        <AkInputText
          v-model="currentValue.link"
          :label="$t('litige.link')"
          :placeholder="$t('litige.link')"
          class-name="col-8"
          :link-external="linkExternal(currentValue.link)"
          :submitted="submitted"
          :disabled="disabled" />
      </div>
      <!-- PJ liée au litige item -->
      <AkFicList
        v-if="currentValue"
        v-model="currentValue.ficList"
        :display-title="false"
        :title="$t('files')"
        :table-id="currentValue.id"
        table-name="litige_item"
        :canUpload="canUpload"
        :canDelete="canDeleteFiles"
        :uniqueFic="true"
        class-name="col-4"
        @removed="removed" />
    </section>
  </section>
</template>
