<script>
import useVuelidate from '@vuelidate/core';
import {required, email, requiredIf} from '@vuelidate/validators';

import {isValidPhoneNumber} from 'libphonenumber-js';

/* COMPOSANTS */
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import GaView from '@components/layout/GaView';
import AkDropdown from '@components/input/AkDropdown';
import AkInputMoney from '@components/input/AkInputMoney';
import AkInputText from '@components/input/AkInputText';
import AkInputGroup from '@components/input/AkInputGroup';
import AkAddressFull from '@components/input/AkAddressFull';
import AkPictureAccommodation from '@components/general/AkPictureAccommodation';
import PartenairePrestationForm from '@views/partenaires/PartenairePrestationForm.vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import currentCompanyMixin from '@mixins/currentCompanyMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import messageMixin from '@mixins/messageMixin';
import backMixin from '@mixins/backMixin';
import civilitiesConst from '@mixins/const/civilitiesConst';
import prestationTypesConst from '@mixins/const/prestationTypesConst';

/* SERVICES */
import partenaireService from '@services/partenaireService';
import itemLabelService from '@services/itemLabelService';
import companyService from '@services/companyService';
import accommodationService from '@services/accommodationService';
import partenairePrestationService from '@services/partenairePrestationService';

const phoneValidator = (value) => isValidPhoneNumber(value, 'FR');

export default {
  components: {
    PartenairePrestationForm,
    AkPictureAccommodation,
    AkAddressFull,
    AkInputGroup,
    AkInputText,
    AkInputMoney,
    AkDropdown,
    GaView,
    AkFormSubmitted,
  },
  mixins: [
    randomRef,
    currentCompanyMixin,
    permissionsMixin,
    messageMixin,
    backMixin,
    civilitiesConst,
    prestationTypesConst,
  ],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'partenaire.create',
    };
  },
  validations() {
    return {
      partenaire: {
        companyId: {required: requiredIf(this.isGas() || this.isAdmin())},
        contact: {
          companyName: {required},
          formeJuridiqueId: {required},
          raisonSociale: {required},
          email: {required, email},
          phone: {
            required,
            phoneValidator: phoneValidator,
          },
          civility: {required},
          lastName: {required},
          firstName: {required},
        },
      },
    };
  },
  data() {
    return {
      submitted: false,
      listFormeJuridique: [],
      companies: [],
      accommodations: [],
      partenairePrestations: [],
      partnerMissions: [],
      partenaire: {
        companyId: null,
        accommodations: [],
        contact: {
          companyName: null,
          formeJuridiqueId: null,
          raisonSociale: null,
          capital: null,
          address: null,
          complement: null,
          postalCode: null,
          city: null,
          state: null,
          country: null,
          email: null,
          phone: null,
          villeRcs: null,
          siret: null,
          civility: null,
          lastName: null,
          firstName: null,
          function: null,
        },
      },
    };
  },
  mounted() {
    this.partenaire.companyId = this.currentCompanyId();

    itemLabelService.itemLabels({type: 'FORME_JURIDIQUE'}).then((data) => {
      this.listFormeJuridique = data;
    });
    companyService.companies().then((data) => {
      this.companies = data;
    });

    this.getAccommodations(this.partenaire.companyId);
  },
  methods: {
    createPartenaire() {
      this.partenaire.accommodationIds = this.selectedAccommodationIds;
      this.partenaire.partenairePrestations = this.partenairePrestations;

      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$error) return;

      this.getRef().showLoader(this.$refs.form);
      partenaireService
        .create(this.partenaire)
        .then(() => {
          this.getRef().hideLoader();
          this.success('Partenaire ajouté !');
          this.partenaire = {
            companyId: null,
            accommodationIds: [],
            contact: {
              companyName: null,
              formeJuridiqueId: null,
              raisonSociale: null,
              capital: null,
              address: null,
              complement: null,
              postalCode: null,
              city: null,
              state: null,
              country: null,
              email: null,
              phone: null,
              villeRcs: null,
              siret: null,
              civility: null,
              lastName: null,
              firstName: null,
              function: null,
            },
          };
          this.submitted = false;
        })
        .catch((e) => {
          this.getRef().hideLoader();
          this.addError(this.$t('error.' + e.response.data.error));
          this.submitted = false;
        });
    },
    toggleAccommodation(accommodation) {
      accommodation.isSelected ^= true;
      if (accommodation.isSelected) {
        this.partenairePrestations.push({accommodationId: accommodation.id});
      } else {
        let index = this.partenairePrestations.map((el) => el.accommodationId).indexOf(accommodation.id);
        this.partenairePrestations.splice(index, 1);
      }
    },
    selectAllAccommodation() {
      for (let accommodation of this.accommodations) {
        accommodation.isSelected = true;
        this.partenairePrestations.push({accommodationId: accommodation.id});
      }
    },
    unselectAllAccommodation() {
      for (let accommodation of this.accommodations) {
        accommodation.isSelected = false;
        let index = this.partenairePrestations.map((el) => el.accommodationId).indexOf(accommodation.id);
        this.partenairePrestations.splice(index, 1);
      }
    },
    getPartenairePrestationIndex(accommodation) {
      return this.partenairePrestations.map((el) => el.accommodationId).indexOf(accommodation.id);
    },

    // ACCOMMODATIONS
    getAccommodations(companyId) {
      if (!companyId) return;

      accommodationService.accommodations({companyId: companyId, enabled: true}).then((data) => {
        this.accommodations = data;
      });
    },

    // PARTNER MISSIONS
    getPartnerMissions(companyId) {
      const filter = {
        companyId: companyId,
      };

      partenairePrestationService.partenairePrestations(filter).then((data) => {
        this.partnerMissions = data;
      });
    },

    getPartnerMissionTypesForForm(accommodationId) {
      return this.partnerMissions.filter((el) => el.accommodationId === accommodationId);
    },
  },
  computed: {
    isLoadingFormeJuridique() {
      return this.listFormeJuridique === undefined;
    },
    selectedAccommodations() {
      return this.accommodations.filter((acc) => acc.isSelected);
    },
    selectedAccommodationIds() {
      return this.selectedAccommodations.map((acc) => acc.id);
    },
  },
  watch: {
    'partenaire.companyId'(newVal, oldVal) {
      if (newVal === oldVal) return;
      this.accommodations = [];
      this.partenaire.accommodationIds = [];
      this.partenairePrestations = [];
      this.getAccommodations(newVal);
      this.getPartnerMissions(newVal);
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :title="$t('partenaire.create')">
    <template #back>
      <a class="mb-1 d-block pointer" @click="goBack()"> <i class="fe fe-arrow-left pr-1" />{{ $t('back') }} </a>
    </template>
    <template #content>
      <AkFormSubmitted id="create-partenaire-form" :submitted="submitted" reference="form" @submit="createPartenaire">
        <h6 class="title">Contact</h6>
        <div class="form-row">
          <AkDropdown
            v-model="partenaire.contact.formeJuridiqueId"
            :label="$t('contact.formeJuridique')"
            :loading="isLoadingFormeJuridique"
            :options="this.listFormeJuridique"
            :validator="v$.partenaire.contact.formeJuridiqueId"
            class-name="col-3"
            optionLabel="label"
            optionValue="id" />
          <AkInputText
            v-model="partenaire.contact.raisonSociale"
            :label="$t('contact.raisonSociale')"
            :validator="v$.partenaire.contact.raisonSociale"
            class-name="col-3" />
          <AkInputText
            v-model="partenaire.contact.companyName"
            :label="$t('contact.companyName')"
            :validator="v$.partenaire.contact.companyName"
            class-name="col-3" />
          <AkInputMoney v-model="partenaire.contact.capital" class-name="col-3" label="Capital (€)" />
        </div>
        <AkAddressFull
          v-model="partenaire.contact"
          :always-display-map="false"
          :can-change-position="false"
          :label="$t('contact.address1Manager')"
          :validator="v$.partenaire" />
        <div class="form-row">
          <AkInputGroup
            v-model="partenaire.contact.email"
            :label="$t('contact.email')"
            :validator="v$.partenaire.contact.email"
            class-name="col-3"
            icon="fa fa-envelope" />
          <AkInputGroup
            v-model="partenaire.contact.phone"
            :label="$t('contact.phone')"
            :validator="v$.partenaire.contact.phone"
            class-name="col-3"
            icon="fa fa-phone" />
          <span class="col-6"></span>

          <AkInputText v-model="partenaire.contact.villeRcs" :label="$t('contact.villeRcs')" class-name="col-3" />
          <AkInputText v-model="partenaire.contact.siret" :label="$t('contact.siret')" class-name="col-3" />
          <span class="col-6"></span>

          <AkDropdown
            v-model="partenaire.contact.civility"
            :label="$t('contact.civilityManager')"
            :options="this.getCivilitiesConstForProspect()"
            :validator="v$.partenaire.contact.civility"
            class-name="col-3" />
          <AkInputText
            v-model="partenaire.contact.lastName"
            :label="$t('contact.lastNameManager')"
            :validator="v$.partenaire.contact.lastName"
            class-name="col-3" />
          <AkInputText
            v-model="partenaire.contact.firstName"
            :label="$t('contact.firstNameManager')"
            :validator="v$.partenaire.contact.firstName"
            class-name="col-3" />
          <AkInputText
            v-model="partenaire.contact.function"
            :label="$t('contact.functionManager')"
            class-name="col-3" />
        </div>
        <h6 v-if="isGas() || isAdmin()" class="title mt-3">Informations complémentaires</h6>
        <div v-if="isGas() || isAdmin()" class="form-row">
          <AkDropdown
            v-model="partenaire.companyId"
            :validator="v$.partenaire.companyId"
            :options="companies"
            class-name="col-6"
            label="Conciergerie locale"
            option-label="name"
            option-value="id" />
        </div>
        <template v-if="accommodations && accommodations.length > 0 && partenaire.companyId">
          <h6 class="title mt-3 d-flex">
            Logements
            <span class="btn btn-xs btn-inverse-primary ml-1" @click="selectAllAccommodation()">
              <i class="fe fe-check-square mr-1" /> {{ $t('select_all') }}
            </span>
            <span class="btn btn-xs btn-inverse-primary ml-1" @click="unselectAllAccommodation()">
              <i class="fe fe-square mr-1" /> {{ $t('unselect_all') }}
            </span>
          </h6>
          <div class="accommodation-cards">
            <div :key="accommodation.id" class="accommodation-card col-4" v-for="accommodation in accommodations">
              <div
                :class="{'blue-border': accommodation.isSelected}"
                @click="toggleAccommodation(accommodation)"
                class="accommodation-item">
                <figure>
                  <AkPictureAccommodation radius="0" height="80" :md5="accommodation.picture" />
                </figure>
                <div class="accommodation-name d-flex flex-column flex-1 justify-content-center">
                  <h6>
                    {{ accommodation.name }}
                  </h6>
                  <div class="owner-name">
                    {{ accommodation.ownerContactName }}
                  </div>
                </div>
              </div>
              <div class="accommodation-missions" v-if="accommodation.isSelected">
                <PartenairePrestationForm
                  v-model="partenairePrestations"
                  :index="getPartenairePrestationIndex(accommodation)"
                  :receptionTypeCheckin="accommodation.receptionTypeCheckin"
                  :receptionTypeCheckout="accommodation.receptionTypeCheckout"
                  :partnerMissionTypes="getPartnerMissionTypesForForm(accommodation.id)" />
              </div>
            </div>
          </div>
        </template>
        <div class="d-flex justify-content-end mb-20">
          <button class="btn btn-primary">
            {{ $t('add') }}
          </button>
        </div>
      </AkFormSubmitted>
    </template>
  </GaView>
</template>

<style scoped>
.accommodation-cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .accommodation-card {
    margin-bottom: 1rem;

    > .accommodation-item {
      border: 2px solid transparent;
      border-radius: 7px;
      cursor: pointer;

      display: flex;

      figure {
        margin-bottom: 0px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        overflow: hidden;
      }

      &.blue-border {
        border: 2px solid #40b4e5;
      }

      .accommodation-image {
        grid-area: image;
      }

      .accommodation-name {
        padding-left: 10px;
        background: white;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }

  .accommodation-missions {
    background: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-bottom: 10px;
    padding-top: 10px;
  }
}
</style>
