<template>
  <div class="d-flex justify-content-between mb-20">
    <div aria-label="duration" class="gd-btn-group" role="group">
      <button
        v-for="({label}, index) in durationOptions"
        :class="durationSelectedId === index && 'selected'"
        type="button"
        v-text="label"
        :key="label"
        @click="this.selectDuration(index)"></button>
    </div>
    <div class="calendar">
      <div class="calendar__icon">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.5 8.33332H2.5M13.3333 1.66666V4.99999M6.66667 1.66666V4.99999M6.5 18.3333H13.5C14.9001 18.3333 15.6002 18.3333 16.135 18.0608C16.6054 17.8212 16.9878 17.4387 17.2275 16.9683C17.5 16.4335 17.5 15.7335 17.5 14.3333V7.33332C17.5 5.93319 17.5 5.23313 17.2275 4.69835C16.9878 4.22794 16.6054 3.84549 16.135 3.60581C15.6002 3.33332 14.9001 3.33332 13.5 3.33332H6.5C5.09987 3.33332 4.3998 3.33332 3.86502 3.60581C3.39462 3.84549 3.01217 4.22794 2.77248 4.69835C2.5 5.23313 2.5 5.93319 2.5 7.33332V14.3333C2.5 15.7335 2.5 16.4335 2.77248 16.9683C3.01217 17.4387 3.39462 17.8212 3.86502 18.0608C4.3998 18.3333 5.09987 18.3333 6.5 18.3333Z"
            stroke="#344054"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </div>
      <Calendar
        v-model="calendarValue"
        class="calendar__calendar"
        selectionMode="range"
        placeholder="Sélectionner les dates"
        dateFormat="dd/mm/yy"
        hideOnRangeSelection
        @date-select="onCalendarDateSelected"
        showButtonBar
        @clear-click="onClearClick" />
    </div>
  </div>
</template>

<script>
import Calendar from 'primevue/calendar';
import {
  MILLISECONDS_PER_YEAR,
  MILLISECONDS_PER_30DAYS,
  MILLISECONDS_PER_WEEK,
  MILLISECONDS_PER_DAY,
} from '@/utils/dateTime';

export default {
  components: {
    Calendar,
  },
  data() {
    return {
      durationSelectedId: 0,
      durationOptions: [
        {
          label: '12 mois',
          value: MILLISECONDS_PER_YEAR,
        },
        {
          label: '30 jours',
          value: MILLISECONDS_PER_30DAYS,
        },
        {
          label: '7 jours',
          value: MILLISECONDS_PER_WEEK,
        },
        {
          label: '24 heures',
          value: MILLISECONDS_PER_DAY,
        },
      ],
      calendarValue: null,
    };
  },
  methods: {
    selectDuration(index) {
      if (index === this.durationSelectedId) return;
      this.calendarValue = null;
      this.durationSelectedId = index;
      const startingDate = new Date();
      const endingDate = new Date(
        new Date().setHours(0, 0, 0, 0) + this.durationOptions[this.durationSelectedId].value,
      );
      this.$emit('intervalChange', startingDate, endingDate);
    },
    onClearClick() {
      this.selectDuration(0);
    },
    onCalendarDateSelected() {
      if (!this.calendarValue || !this.calendarValue[0] || !this.calendarValue[1]) return;
      this.durationSelectedId = -1;
      this.$emit('intervalChange', this.calendarValue[0], this.calendarValue[1]);
    },
  },
};
</script>

<style lang="scss">
.calendar {
  position: relative;

  & .p-calendar > input {
    background: #ffffff;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font: 600 14px/1 'Nunito Sans';
    color: #667085;
    padding: 10px 16px 10px 46px;
  }

  &__icon {
    position: absolute;
    z-index: 1;
    pointer-events: none;
    top: 10px;
    left: 16px;
    width: 20px;
    height: 20px;
  }
}
</style>
