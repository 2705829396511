<script>
import useVuelidate from '@vuelidate/core';
import {maxLength, required, requiredIf} from '@vuelidate/validators';

/* COMPOSANTS */
import AkAccommodationAutocomplete from '@components/general/AkAccommodationAutocomplete';
import AkCalendar from '@components/input/AkCalendar';
import AkFilesUpload from '@components/input/AkFilesUpload';
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import AkInputTextArea from '@components/input/AkInputTextArea';
import AkListAutocomplete from '@components/general/AkListAutocomplete';
import GaView from '@components/layout/GaView';

/* MIXINS */
import backMixin from '@mixins/backMixin';
import messageMixin from '@mixins/messageMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import loaderMixin from '@mixins/loaderMixin';

/* SERVICES */
import litigeService from '@services/litigeService';
import reservationService from '@services/reservationService';

export default {
  components: {
    AkListAutocomplete,
    AkAccommodationAutocomplete,
    AkCalendar,
    AkFilesUpload,
    AkFormSubmitted,
    AkInputTextArea,
    GaView,
  },
  mixins: [backMixin, loaderMixin, messageMixin, permissionsMixin, randomRef, utilsMixin],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'litige.add',
    };
  },
  validations() {
    return {
      litige: {
        accommodationId: {required},
        comment: {required, maxLengthValue: maxLength(500)},
        date: {required},
        reservationId: {required},
      },
    };
  },
  data() {
    return {
      submitted: false,
      litige: {},
      reservations: [],
      files: [],
      errors: [],
    };
  },
  computed: {},
  watch: {
    'litige.accommodationId'(newVal, oldVal) {
      this.litige.reservationId = null;
      this.initReservations();
    },
  },
  mounted() {},
  methods: {
    createLitige() {
      this.errors = [];
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$error) return;

      if (this.files.length == 0) {
        this.errors.push(this.$t('litige.error_empty_files'));
        return;
      }

      this.getRef().showLoader(this.$refs.form);
      litigeService
        .create(this.litige, this.files)
        .then((data) => {
          this.getRef().hideLoader();
          this.success(this.$t('litige.added'));
          this.submitted = false;

          this.litige = {};
          this.files = [];
          this.$refs.fileUpload.clear();
          this.$refs.accommodationAutocomplete.reset();
        })
        .catch((e) => {
          this.getRef().hideLoader();
          this.addError(this.$t('error.' + e.response.data.error));
          this.submitted = false;
        });
    },
    initReservations() {
      let accommodationId = this.litige.accommodationId;
      reservationService
        .reservations({accommodationId: accommodationId, status: 'Booked'})
        .then((data) => {
          this.reservations = data.filter((resa) => resa.status === 'Booked');
        })
        .catch((e) => {
          this.reservations = [];
          this.addError(this.$t('error.' + e.response.data.error));
        });
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :title="$t('litige.add')">
    <template #back>
      <a class="mb-1 d-block pointer" @click="goBack()"><i class="fe fe-arrow-left pr-1" />{{ $t('back') }}</a>
    </template>
    <template #content>
      <AkFormSubmitted :submitted="submitted" reference="form" @submit="createLitige">
        <div class="form-row">
          <AkAccommodationAutocomplete
            ref="accommodationAutocomplete"
            v-model="litige.accommodationId"
            :validator="v$.litige.accommodationId"
            :label="$t('litige.accommodation')"
            className="col-6" />
          <AkListAutocomplete
            v-if="this.litige.accommodationId"
            v-model="litige.reservationId"
            className="col-6"
            :validator="v$.litige.reservationId"
            :options="reservations"
            :dropdown="false"
            :label="$t('litige.reservation')"
            option-label="display"
            option-value="id" />
        </div>
        <div class="form-row">
          <AkCalendar
            v-model="litige.date"
            :validator="v$.litige.date"
            class-name="col-md-6"
            :label="$t('litige.date')" />
        </div>
        <div class="form-row">
          <AkInputTextArea
            v-model="litige.comment"
            :validator="v$.litige.comment"
            class-name="col-md-12"
            :label="$t('litige.comment')" />
        </div>
        <div class="form-row">
          <AkFilesUpload
            ref="fileUpload"
            v-model="files"
            :label="$t('ticket.files')"
            :validator="v$.files"
            class-name="col-md-12"
            accept=".png, .jpeg, .jpg" />
          <p v-if="errors.length">
            <small v-for="(error, index) in errors" :key="index" style="color: red">
              {{ error }}
            </small>
          </p>
        </div>
        <div class="d-flex justify-content-end mb-20">
          <button class="btn btn-primary">
            {{ $t('add') }}
          </button>
        </div>
      </AkFormSubmitted>
    </template>
  </GaView>
</template>
