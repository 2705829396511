<script>
/* COMPOSANTS */
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import TextArea from 'primevue/textarea';
import AkInputSwitch from '@components/input/AkInputSwitch';

/* SERVICES */

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import receptionTypeConst from '@mixins/const/receptionTypeConst';
import litigeStatusConst from '@mixins/const/litigeStatusConst';
import maintenanceStatusConst from '@mixins/const/maintenanceStatusConst';
import reservationTypesConst from '@mixins/const/reservationTypesConst';

export default {
  components: {
    AkInputSwitch,
    InputText,
    Dropdown,
    TextArea,
  },
  mixins: [
    permissionsMixin,
    receptionTypeConst,
    utilsMixin,
    litigeStatusConst,
    maintenanceStatusConst,
    reservationTypesConst,
  ],
  props: {
    modelValue: {
      required: true,
    },
  },
  data() {
    return {
      clientDisplay: '',
      clientPhoneDisplay: '',
      clientMailDisplay: '',
      dateCreated: new Date().toLocaleDateString(),
      dateCheckin: '',
      dateCheckout: '',
      previousDateCheckin: '',
      previousDateCheckout: '',
      previousDateStartLitige: '',
      previousDateEndLitige: '',
      nextDateCheckin: '',
      nextDateCheckout: '',
      ownerDisplay: '',
      previousReservation: {},
      nextReservation: {},
    };
  },
  mounted() {
    this.initFields();
  },
  methods: {
    initFields() {
      if (this.hasClient) {
        if (this.modelValue.client.firstName) this.clientDisplay += this.modelValue.client.firstName + ' ';
        if (this.modelValue.client.lastName) this.clientDisplay += this.modelValue.client.lastName;
        if (this.modelValue.client.phone) this.clientPhoneDisplay = this.modelValue.client.phone;
        if (this.modelValue.client.email) this.clientMailDisplay = this.modelValue.client.email;
      }

      this.ownerDisplay = `${this.modelValue.accommodation.ownerFirstName} ${this.modelValue.accommodation.ownerLastName}`;

      this.dateCreated = this.formatDateIfNeeded(this.modelValue.dateCreated);
      this.dateCheckin = this.formatDateIfNeeded(this.modelValue.dateCheckin);
      this.dateCheckout = this.formatDateIfNeeded(this.modelValue.dateCheckout);

      if (this.hasPreviousReservation) {
        this.previousReservation = this.modelValue.previousReservation;
        this.previousDateCheckin = this.formatDateIfNeeded(this.previousReservation.dateCheckin);
        this.previousDateCheckout = this.formatDateIfNeeded(this.previousReservation.dateCheckout);
        if (this.hasPreviousLitiges) {
          this.previousDateStartLitige = this.formatDateIfNeeded(this.previousReservation.dateStartLitige);
          this.previousDateEndLitige = this.formatDateIfNeeded(this.previousReservation.dateEndLitige);
        }
      }
      if (this.hasNextReservation) {
        this.nextReservation = this.modelValue.nextReservation;
        this.nextDateCheckin = this.formatDateIfNeeded(this.nextReservation.dateCheckin);
        this.nextDateCheckout = this.formatDateIfNeeded(this.nextReservation.dateCheckout);
      }
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
    },
    hasClient() {
      return this.currentValue.client;
    },
    hasPreviousReservation() {
      return this.currentValue.previousReservation && this.currentValue.previousReservation.id !== null;
    },
    hasNextReservation() {
      return this.currentValue.nextReservation && this.currentValue.nextReservation.id !== null;
    },
    hasPreviousLitiges() {
      return this.hasPreviousReservation && this.currentValue.previousReservation.litiges !== null;
    },
    hasPreviousComment() {
      return this.hasPreviousReservation && this.currentValue.previousReservation.comment !== null;
    },
    hasMaintenances() {
      return this.currentValue.maintenances !== null;
    },
  },
};
</script>

<template>
  <div class="prestataire-details">
    <div class="section" v-if="this.hasPreviousReservation">
      <h6 class="mt-2">{{ this.$t('planification.last_reservation') }}</h6>
      <div>
        <div class="input-item">
          <span>{{ this.$t('reservation.number') }}</span>
          <InputText v-model="previousReservation.avantioLocalizer" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('reservation.dateCheckin') }}</span>
          <InputText v-model="previousDateCheckin" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('reservation.dateCheckout') }}</span>
          <InputText v-model="previousDateCheckout" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('reservation.type') }}</span>
          <Dropdown
            v-model="previousReservation.type"
            :label="$t('reservation.type')"
            :options="reservationTypesConst"
            option-label="label"
            option-value="value"
            class="form-control mr-sm-2 border-grey"
            :disabled="true" />
        </div>
      </div>

      <!-- LITIGE RESERVATION -->

      <article v-if="hasPreviousLitiges">
        <div class="section-no-pad" v-for="(litige, idx) in previousReservation.litiges" :key="litige.id">
          <h6 class="mt-2">Litige n°{{ idx + 1 }}</h6>
          <div>
            <div class="input-item">
              <span>{{ this.$t('litige.status') }}</span>
              <Dropdown
                v-model="litige.status"
                :label="$t('maintenance.status')"
                :options="litigeStatusConst"
                option-label="label"
                option-value="value"
                class="form-control mr-sm-2 border-grey"
                :disabled="true" />
            </div>
          </div>
          <article class="input-item">
            <span>{{ this.$t('litige.description') }}</span>
            <TextArea auto-resize rows="4" v-model="litige.description" disabled />
          </article>
        </div>
      </article>

      <div v-if="hasPreviousComment">
        <div class="input-item">
          <span>{{ this.$t('planification.last_comment_prestataire') }}</span>
          <TextArea auto-resize rows="4" v-model="previousReservation.comment" disabled />
        </div>
      </div>
    </div>

    <div class="section">
      <h6 class="mt-2">{{ this.$t('planification.reservation') }}</h6>
      <div>
        <div class="input-item">
          <span>{{ this.$t('reservation.number') }}</span>
          <InputText v-model="currentValue.avantioLocalizer" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('accommodation.name') }}</span>
          <InputText v-model="currentValue.accommodation.name" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('planification.owner') }}</span>
          <InputText v-model="ownerDisplay" disabled />
        </div>
        <div class="input-item" v-if="this.isGas()">
          <span>{{ this.$t('prospect.company') }}</span>
          <InputText v-model="currentValue.companyDisplay" disabled />
        </div>
      </div>
      <div>
        <div class="input-item">
          <span>{{ this.$t('planification.accommodation_address') }}</span>
          <InputText v-model="currentValue.accommodation.address" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('accommodation.postalCode') }}</span>
          <InputText v-model="currentValue.accommodation.postalCode" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('accommodation.city') }}</span>
          <InputText v-model="currentValue.accommodation.city" disabled />
        </div>
      </div>
      <div>
        <div class="input-item">
          <span>{{ this.$t('planification.date_created_reservation') }}</span>
          <InputText v-model="dateCreated" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('reservation.dateCheckin') }}</span>
          <InputText v-model="dateCheckin" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('reservation.dateCheckout') }}</span>
          <InputText v-model="dateCheckout" disabled />
        </div>
      </div>
      <div>
        <div class="input-item">
          <span>{{ this.$t('reservation.guestName') }}</span>
          <InputText v-model="clientDisplay" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('prestataire.phone') }}</span>
          <InputText v-model="clientPhoneDisplay" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('prestataire.email') }}</span>
          <InputText v-model="clientMailDisplay" disabled />
        </div>
      </div>
      <div>
        <div class="input-item">
          <span>{{ this.$t('planification.laundry_included') }}</span>
          <AkInputSwitch
            v-model="currentValue.gestionLinge"
            :label="$t('planification.laundry_included')"
            class-name="col-3"
            :displayLabel="false"
            :disabled="true" />
        </div>
      </div>
      <div>
        <div class="input-item">
          <span>{{ this.$t('prospect.receptionType') }}</span>
          <Dropdown
            v-model="currentValue.receptionCheckinType"
            :options="receptionTypeConst"
            :placeholder="$t('prospect.receptionType')"
            class="form-control mr-sm-2 border-grey"
            option-label="label"
            option-value="value"
            :disabled="true" />
        </div>
        <div class="input-item">
          <span>{{ this.$t('prospect.receptionCheckoutType') }}</span>
          <Dropdown
            v-model="currentValue.receptionCheckoutType"
            :options="receptionTypeConst"
            :placeholder="$t('prospect.receptionCheckoutType')"
            class="form-control mr-sm-2 border-grey"
            option-label="label"
            option-value="value"
            :disabled="true" />
        </div>
      </div>

      <!-- MAINTENANCES EN COURS LOGEMENT -->

      <article v-if="hasMaintenances">
        <div class="section-no-pad" v-for="(maintenance, idx) in currentValue.maintenances" :key="maintenance.id">
          <h6 class="mt-2">Maintenance n°{{ idx + 1 }}</h6>
          <div>
            <div class="input-item">
              <span>{{ this.$t('maintenance.status') }}</span>
              <Dropdown
                v-model="maintenance.status"
                :label="$t('maintenance.status')"
                :options="maintenanceStatusConst"
                option-label="label"
                option-value="value"
                class="form-control mr-sm-2 border-grey"
                :disabled="true" />
            </div>
            <div class="input-item">
              <span>{{ this.$t('maintenance.problem') }}</span>
              <InputText v-model="maintenance.problem" disabled />
            </div>
          </div>
          <article class="input-item">
            <span>{{ this.$t('maintenance.description') }}</span>
            <TextArea auto-resize rows="4" v-model="maintenance.description" disabled />
          </article>
        </div>
      </article>
    </div>

    <div class="section" v-if="this.hasNextReservation">
      <h6 class="mt-2">{{ this.$t('planification.next_reservation') }}</h6>
      <div>
        <div class="input-item">
          <span>{{ this.$t('reservation.number') }}</span>
          <InputText v-model="nextReservation.avantioLocalizer" disabled />
        </div>
      </div>
      <div>
        <div class="input-item">
          <span>{{ this.$t('reservation.dateCheckin') }}</span>
          <InputText v-model="nextDateCheckin" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('reservation.dateCheckout') }}</span>
          <InputText v-model="nextDateCheckout" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('reservation.type') }}</span>
          <Dropdown
            v-model="nextReservation.type"
            :label="$t('reservation.type')"
            :options="reservationTypesConst"
            option-label="label"
            option-value="value"
            class="form-control mr-sm-2 border-grey"
            :disabled="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.prestataire-details {
  .section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    background-color: #ffffff;
    border-radius: 16px;
    margin-bottom: 24px;
    gap: 16px;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 40px;
    }
  }

  .section-no-pad {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #ffffff;
    border-radius: 16px;
    margin-bottom: 24px;
    gap: 16px;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 40px;
    }
  }

  .input-item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 6px;

    > span {
      font-family: 'Nunito';
      font-weight: 600;
      font-size: 14px;
      color: #344054;
    }

    > div {
      display: flex;
      flex-direction: row;
      gap: 8px;

      > label {
        font-family: 'Nunito';
        font-weight: 600;
        font-size: 14px;
        color: #344054;
        margin-right: 20px;
      }
    }
  }

  .title {
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 16px;
    color: #344054;
  }

  .p-inputtext {
    width: auto !important;
    height: 40px;
  }

  .date-input {
    border: solid 1px #d0d5dd;
    border-radius: 8px;
    padding: 0 16px;
    box-sizing: border-box;
  }

  .border-grey {
    border-color: #d0d5dd;
  }

  .p-fileupload {
    width: 100%;
  }

  .btn-create {
    height: 44px;
    background-color: #0b3575;
    color: #ffffff;
  }

  .col-4 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .file-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start !important;
    align-items: center;
    gap: 15px !important;
  }

  .svg-container {
    width: fit-content;
  }

  .clickable {
    cursor: pointer;
  }
}
</style>
