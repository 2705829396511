<script>
/* COMPOSANTS */
import GaContentView from '@components/layout/GaContentView';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import loaderMixin from '@mixins/loaderMixin';
import utilsMixin from '@mixins/utilsMixin';
import cdcmodelTypesConst from '@mixins/const/cdcmodelTypesConst';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import cdcService from '@services/cdcService';
import zoneService from '@services/zoneService';
import taskService from '@services/taskService';

export default {
  props: {
    cdcId: {
      required: true,
    },
    type: {
      required: true,
    },
  },
  data() {
    return {
      cdc: {
        id: null,
        type: null,
        duration: null,
      },
      zones: undefined,
      tasks: undefined,
    };
  },
  components: {
    GaContentView,
  },
  mixins: [cdcmodelTypesConst, loaderMixin, messageMixin, randomRef, utilsMixin],
  computed: {
    listZones() {
      if (!this.zones) return null;
      return this.zones.filter((elem) => elem.type === 'ZONE');
    },
    listSubzones() {
      if (!this.zones) return null;
      return this.zones.filter((elem) => elem.type === 'SUBZONE');
    },
  },
  mounted() {
    this.showTotalLoader();

    // Anomalie, le cdc n'existe plus
    if (!this.cdcId) {
      this.hideLoader();
      return;
    }

    let p1 = cdcService.cdc(this.cdcId).then((data) => {
      this.cdc = data;
    });

    let p2 = zoneService.zones().then((data) => {
      this.zones = data;
    });

    let p3 = taskService.tasks().then((data) => {
      this.tasks = data;
    });

    Promise.all([p1, p2, p3]).finally(this.hideLoader());
  },
  methods: {
    getZoneValue(zoneId) {
      const zone = this.listZones?.find(({id}) => id === zoneId);
      return zone ? zone.label : '';
    },
    getSubZoneValue(subzoneId) {
      const subZone = this.listSubzones?.find(({id}) => id === subzoneId);
      return subZone ? subZone.label : '';
    },
    getTaskValue(taskId) {
      const task = this.tasks?.find(({id}) => id === taskId);
      return task ? task.label : '';
    },
  },
};
</script>

<template>
  <GaContentView :ref="ref" :title="''">
    <template #content>
      <div class="mission-order-cdc">
        <div class="section" v-if="this.cdcId">
          <h5>{{ displayForCdcmodelTypeConst(type) }}</h5>
          <div class="item">
            <span class="title">Durée</span>
            <span class="value">{{ cdc.duration }} minutes</span>
          </div>
          <div v-for="(item, index) in cdc.itemList" :key="index" class="item">
            <div class="col-12">
              <hr />
            </div>
            <!-- Zone et sous-zone -->
            <div class="row">
              <div class="item zone-item col-6">
                <span class="title">{{ $t('cdcmodelitem.zone') }}</span>
                <span class="value">{{ getZoneValue(item.zoneId) }}</span>
              </div>
              <div class="item zone-item col-6">
                <span class="title">{{ $t('cdcmodelitem.subzone') }}</span>
                <span class="value">{{ getSubZoneValue(item.subzoneId) }}</span>
              </div>
            </div>
            <!-- Titres tâches et commentaire -->
            <div class="row mt-1">
              <div class="item zone-item col-6">
                <span class="title">Tâches</span>
              </div>
              <div class="item zone-item col-6">
                <span class="title">Commentaires</span>
              </div>
            </div>
            <!-- Valeurs tâches et commentaire -->
            <div class="row" v-for="(task, taskIdx) in item.taskList" :key="taskIdx">
              <div class="item zone-item col-6">
                <span class="value mt-1">{{ getTaskValue(task.taskId) }}</span>
              </div>
              <div class="item zone-item col-6">
                <span class="value mt-1">{{ task.comment }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="section" v-else>
          <!-- Bloc d'anomalie (le cdc n'existe plus) -->
          <h5>{{ $t('error.error') }}</h5>
          <div class="item">
            <span class="title">{{ $t('error.CDC_NOT_FOUND') }}</span>
          </div>
        </div>
      </div>
    </template>
  </GaContentView>
</template>

<style lang="scss" scoped>
.mission-order-cdc {
  .section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    background-color: #ffffff;
    border-radius: 16px;
    margin-bottom: 24px;
    gap: 16px;
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  .title {
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 14px;
    color: #344054;
  }

  .value {
    word-wrap: break-word;
  }
}
</style>
