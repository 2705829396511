<script>
import {Icon} from '@iconify/vue/dist/iconify';

/* COMPOSANTS */
import MultiSelect from 'primevue/multiselect';
import AkCalendar from '@components/input/AkCalendar.vue';

/* MIXINS */
import sidebarMixin from '@mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';
import reservationSiteConst from '@mixins/const/reservationSiteConst';
import permissionsMixin from '@mixins/permissionsMixin';

/* SERVICES */

export default {
  components: {
    AkCalendar,
    MultiSelect,
    Icon,
  },
  mixins: [utilsMixin, sidebarMixin, permissionsMixin, reservationSiteConst],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    reservationSiteConstForFilter() {
      return this.reservationSiteConst.slice(0, -1);
    },
  },
  watch: {
    'currentValue.submittedAt'(newVal, oldVal) {
      this.currentValue.dateStart = this.currentValue.submittedAt ? this.currentValue.submittedAt[0] : undefined;
      this.currentValue.dateEnd = this.currentValue.submittedAt ? this.currentValue.submittedAt[1] : undefined;
    },
  },
  methods: {
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      this.callback[1]();
    },
  },
};
</script>

<template>
  <h2>Filtrer</h2>
  <form class="d-flex flex-column" style="row-gap: 16px" @submit.prevent="search">
    <AkCalendar
      v-model="currentValue.submittedAt"
      inline
      :placeholder="$t('review.submitted_at')"
      selection="range"
      :maxDate="new Date()"
      class-name="flex-1 form-control" />
    <MultiSelect
      v-model="currentValue.reservationSites"
      :placeholder="$t('review.reservation_sites')"
      :options="reservationSiteConstForFilter"
      option-label="label"
      option-value="value"
      class="flex-1 form-control"
      inline />
    <div class="d-flex flex-row">
      <button class="btn btn-primary btn-search flex-1" type="submit">
        {{ this.$t('review.filter') }}
      </button>
      <Icon
        v-on:click="this.reset()"
        class="pointer"
        icon="basil:cross-outline"
        :style="{fontSize: '50px', width: '50px !important'}" />
    </div>
  </form>
</template>
