<script>
import moment from 'moment/moment';

/* COMPOSANTS */
import AkFormList from '@components/layout/AkFormList';
import AkCalendar from '@components/input/AkCalendar';
import AkAvatarStack from '@components/general/AkAvatarStack';
import ScrollPanel from 'primevue/scrollpanel';
import PButton from 'primevue/button';
import {Icon} from '@iconify/vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import messageMixin from '@mixins/messageMixin';
import missionMixin from '@mixins/missionMixin';

/* SERVICES */
import missionSuiviService from '@services/missionSuiviService';

export default {
  components: {
    AkAvatarStack,
    AkCalendar,
    AkFormList,
    Icon,
    PButton,
    ScrollPanel,
  },
  mixins: [messageMixin, randomRef, sidebarMixin, utilsMixin, missionMixin],
  metaInfo() {
    return {
      title: this.$t('mission_monitoring.title'),
    };
  },
  data() {
    return {
      dtoList: [],
      filter: {
        startDate: new Date(),
        endDate: new Date(),
      },
    };
  },
  computed: {
    toDoPrestations() {
      if (!this.dtoList) return [];
      return this.dtoList.filter((t) => t.status === 'TO_DO');
    },
    doingPrestations() {
      if (!this.dtoList) return [];
      return this.dtoList.filter((t) => t.status === 'DOING');
    },
    finishPrestations() {
      if (!this.dtoList) return [];
      return this.dtoList.filter((t) => t.status === 'FINISH');
    },
    displayTitle() {
      const {startDate, endDate} = this.filter;
      const start = moment(startDate).format('dddd DD MMMM YYYY');
      const end = moment(endDate).format('dddd DD MMMM YYYY');
      if (start === 'Invalid date') {
        return end === 'Invalid date' ? this.$t('undetermined_date') : this.$t('undetermined_date') + ' - ' + end;
      }
      if (end === 'Invalid date') {
        return start + ' - ' + this.$t('undetermined_date');
      }
      return start === end ? start : start + ' - ' + end;
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    // FILTER

    openFilterPanel() {
      this.toggleFilter('GaMissionSuiviFilter', this.filter, this.refresh, this.resetFilter);
    },
    resetFilter() {
      this.filter = {};
      this.toggleFilter('GaMissionSuiviFilter', this.filter, this.refresh, this.resetFilter);
    },

    refresh() {
      missionSuiviService
        .suivi(this.filter)
        .then((data) => {
          this.dtoList = data;
        })
        .catch((e) => {
          this.addError(e.response.data.error);
        });
    },
    prevDay() {
      if (this.filter.startDate) this.filter.startDate = moment(this.filter.startDate).add(-1, 'days').toDate();
      if (this.filter.endDate) this.filter.endDate = moment(this.filter.endDate).add(-1, 'days').toDate();
      this.refresh();
    },
    nextDay() {
      if (this.filter.startDate) this.filter.startDate = moment(this.filter.startDate).add(1, 'days').toDate();
      if (this.filter.endDate) this.filter.endDate = moment(this.filter.endDate).add(1, 'days').toDate();
      this.refresh();
    },
    cssForType(type) {
      if (this.isTypeCheckin(type)) return 'checkin';
      if (this.isTypeCheckout(type)) return 'checkout';
      if (this.isTypeCleaning(type)) return 'cleaning';

      // A REDEFINIR ...
      if (this.isTypeCleaningAfter(type)) return 'cleaning';
      if (this.isTypeCleaningBefore(type)) return 'cleaning';
      if (this.isTypeRunning(type)) return 'cleaning';
      if (this.isTypeQualityControl(type)) return 'cleaning';
      if (this.isTypeMaintenance(type)) return 'cleaning';
      return type;
    },
    iconForType(type) {
      if (this.isTypeCheckin(type)) return 'mdi:clock-plus-outline';
      if (this.isTypeCheckout(type)) return 'mdi:clock-minus-outline';
      if (this.isTypeCleaning(type)) return 'mdi:vacuum';

      // A REDEFINIR ...
      if (this.isTypeCleaningAfter(type)) return 'mdi:vacuum';
      if (this.isTypeCleaningBefore(type)) return 'mdi:vacuum';
      if (this.isTypeRunning(type)) return 'mdi:vacuum';
      if (this.isTypeQualityControl(type)) return 'mdi:vacuum';
      if (this.isTypeMaintenance(type)) return 'mdi:vacuum';
      return type;
    },
    displayParticipantNb(item) {
      if (this.isAssignedStaffIntern(item.prestation)) return this.displayInternNb(item);
      if (this.isAssignedStaffHousekeeper(item.prestation)) return this.displayHousekeeperNb(item);
      return this.displayPrestataireNb(item);
    },
    displayInternNb(item) {
      if (item.participants.length == 0) return 'Aucun salarié';
      if (item.participants.length == 1) return '1 salarié';
      return item.participants.length + ' salariés';
    },
    displayPrestataireNb(item) {
      if (item.participants.length == 0) return 'Aucun prestataire';
      if (item.participants.length == 1) return '1 prestataire';
      return item.participants.length + ' prestataires';
    },
    displayHousekeeperNb(item) {
      if (item.participants.length == 0) return 'Aucun concierge';
      if (item.participants.length == 1) return '1 concierge';
      return item.participants.length + ' concierges';
    },
  },
  watch: {
    'filter.startDate'(e) {
      if (e !== null && this.filter.endDate < e) this.filter.endDate = e;
      if (!e && !this.filter.endDate) {
        this.filter.startDate = new Date();
        this.filter.endDate = new Date();
      }
      this.refresh();
    },
    'filter.endDate'(e) {
      if (e !== null && e < this.filter.startDate) this.filter.startDate = e;
      if (!e && !this.filter.startDate) {
        this.filter.startDate = new Date();
        this.filter.endDate = new Date();
      }
      this.refresh();
    },
  },
};
</script>
<template>
  <AkFormList :ref="ref" :title="$t('mission_monitoring.title')">
    <template #action>
      <div class="d-flex align-items-center mb-20 mt-2">
        <div class="d-flex mr-2 justify-content-end">
          <AkCalendar v-model="filter.startDate" :placeholder="$t('date_start')" inline class="mr-2" label="" />
          <AkCalendar v-model="filter.endDate" :placeholder="$t('date_end')" inline label="" />
        </div>
        <PButton
          class="btn btn-primary"
          :badge="this.countFilter(this.filter)"
          badgeClass="p-badge-primary"
          :label="$t('mission_monitoring.filter')"
          @click="openFilterPanel()">
        </PButton>
      </div>
    </template>
    <template #list>
      <div class="suivi">
        <div class="header">
          <Icon icon="eva:chevron-left-outline" @click="prevDay()" />
          <span style="flex: unset">{{ displayTitle }}</span>
          <Icon icon="eva:chevron-right-outline" @click="nextDay()" />
        </div>
        <div class="content">
          <div>
            <div class="title">{{ $t('mission_monitoring.todo') }}</div>
            <ScrollPanel class="prestations">
              <div
                v-for="item in toDoPrestations"
                :key="item.prestation.id"
                :class="this.cssForType(item.prestation.type)"
                class="prestation task">
                <div :class="this.cssForType(item.prestation.type)" class="type">
                  <Icon :icon="iconForType(item.prestation.type)" />
                </div>
                <div class="data">
                  <div class="accommodation">
                    {{ item.prestation.accommodationName }}
                  </div>
                  <div>
                    {{ displayDateTime(item.prestation.dateStart) }} - {{ displayDateTime(item.prestation.dateEnd) }}
                  </div>
                  <div>
                    {{ displayParticipantNb(item) }}
                  </div>
                </div>
                <div class="picture justify-content-end" style="width: 150px">
                  <AkAvatarStack :avatars="item.participants" :limit="3" size="medium" v-if="item.participants" />
                </div>
              </div>
            </ScrollPanel>
          </div>
          <div>
            <div class="title">{{ $t('mission_monitoring.doing') }}</div>
            <ScrollPanel class="prestations">
              <div
                v-for="item in doingPrestations"
                :key="item.prestation.id"
                :class="this.cssForType(item.prestation.type)"
                class="prestation task">
                <div :class="this.cssForType(item.prestation.type)" class="type">
                  <Icon :icon="iconForType(item.prestation.type)" />
                </div>
                <div class="data">
                  <div class="accommodation">
                    {{ item.prestation.accommodationName }}
                  </div>
                  <div>
                    {{ displayDateTime(item.prestation.dateStart) }} - {{ displayDateTime(item.prestation.dateEnd) }}
                  </div>
                  <div>
                    {{ displayParticipantNb(item) }}
                  </div>
                </div>
                <div class="picture justify-content-end" style="width: 150px">
                  <AkAvatarStack :avatars="item.participants" :limit="3" size="medium" v-if="item.participants" />
                </div>
              </div>
            </ScrollPanel>
          </div>
          <div>
            <div class="title">{{ $t('mission_monitoring.done') }}</div>
            <ScrollPanel class="prestations">
              <div
                v-for="item in finishPrestations"
                :key="item.prestation.id"
                :class="this.cssForType(item.prestation.type)"
                class="prestation task">
                <div :class="this.cssForType(item.prestation.type)" class="type">
                  <Icon :icon="iconForType(item.prestation.type)" />
                </div>
                <div class="data">
                  <div class="accommodation">
                    {{ item.prestation.accommodationName }}
                  </div>
                  <div>
                    {{ displayDateTime(item.prestation.dateStart) }} - {{ displayDateTime(item.prestation.dateEnd) }}
                  </div>
                  <div>
                    {{ displayParticipantNb(item) }}
                  </div>
                </div>
                <div class="picture justify-content-end" style="width: 150px">
                  <AkAvatarStack :avatars="item.participants" :limit="3" size="medium" v-if="item.participants" />
                </div>
              </div>
            </ScrollPanel>
          </div>
        </div>
      </div>
    </template>
  </AkFormList>
</template>

<style lang="scss" module>
.title {
  text-align: center;
}
</style>
