<script>
import moment from 'moment';

/* COMPOSANTS */
import Calendar from 'primevue/calendar';
import PrestationBadge from '@components/general/PrestationBadge.vue';
import PrestationStatusBadge from '@components/general/PrestationStatusBadge.vue';

/* SERVICES */
import prestatairePageService from '@services/prestatairePageService';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';

export default {
  components: {Calendar, PrestationBadge, PrestationStatusBadge},
  mixins: [utilsMixin],
  props: {
    prestataireId: {
      required: true,
    },
  },
  data() {
    return {
      list: [],
      count: 0,
      stats: {
        turnover: 0,
        minutesDone: 0,
        missionsDone: 0,
        totalRecords: 0,
      },
      loading: false,
      monthChosen: new Date(),
    };
  },
  watch: {
    monthChosen: function () {
      this.getStats();
      this.onPage({rows: 10, page: 0});
    },
  },
  mounted() {
    this.getStats();
    this.onPage({rows: 10, page: 0});
  },
  methods: {
    rowClick(event) {
      let data = event.data;
      this.$router.push({
        name: 'prestatairePage',
        params: {prestataireId: data.contact.id},
      });
    },
    getStats() {
      const startDate = new Date(this.monthChosen.getFullYear(), this.monthChosen.getMonth(), 1).toJSON();
      const endDate = new Date(
        this.monthChosen.getMonth() === 11 ? this.monthChosen.getFullYear() + 1 : this.monthChosen.getFullYear(),
        this.monthChosen.getMonth() === 11 ? 0 : this.monthChosen.getMonth() + 1,
        1,
      ).toJSON();

      prestatairePageService.getStats(this.prestataireId, startDate, endDate).then((data) => {
        this.stats = data;
      });
    },

    onPage(event) {
      this.loading = true;
      const startDate = new Date(this.monthChosen.getFullYear(), this.monthChosen.getMonth(), 1).toJSON();
      const endDate = new Date(
        this.monthChosen.getMonth() === 11 ? this.monthChosen.getFullYear() + 1 : this.monthChosen.getFullYear(),
        this.monthChosen.getMonth() === 11 ? 0 : this.monthChosen.getMonth() + 1,
        1,
      ).toJSON();

      let filter = {
        prestataireId: this.prestataireId,
        dateStart: startDate,
        dateEnd: endDate,
        limit: event.rows,
        page: event.page + 1,
        sort: ['j_prestation_date_start'],
      };
      prestatairePageService.getMissions(filter).then((data) => {
        this.list = data;
        this.loading = false;
      });
    },

    onRowClick(event) {
      this.$router.push({name: 'mission-order-detail', params: {missionOrderId: event.data.prestationId}});
    },
    getDuration(slotProps) {
      const duration = moment.duration(slotProps.data.dateEnd - slotProps.data.dateStart);
      if (duration.asDays() >= 1) return `${Math.round(duration.asDays() * 100) / 100}j`;
      return `${duration.asMinutes()} min`;
    },
  },
};
</script>

<template>
  <div class="h-100 d-flex flex-column prestataire-missions">
    <div class="calendar-container">
      <Calendar class="calendar" view="month" v-model="monthChosen" date-format="MM yy" />
      <div class="calendar-svg">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.5 8.33332H2.5M13.3333 1.66666V4.99999M6.66667 1.66666V4.99999M6.5 18.3333H13.5C14.9001 18.3333 15.6002 18.3333 16.135 18.0608C16.6054 17.8212 16.9878 17.4387 17.2275 16.9683C17.5 16.4335 17.5 15.7335 17.5 14.3333V7.33332C17.5 5.93319 17.5 5.23313 17.2275 4.69835C16.9878 4.22794 16.6054 3.84549 16.135 3.60581C15.6002 3.33332 14.9001 3.33332 13.5 3.33332H6.5C5.09987 3.33332 4.3998 3.33332 3.86502 3.60581C3.39462 3.84549 3.01217 4.22794 2.77248 4.69835C2.5 5.23313 2.5 5.93319 2.5 7.33332V14.3333C2.5 15.7335 2.5 16.4335 2.77248 16.9683C3.01217 17.4387 3.39462 17.8212 3.86502 18.0608C4.3998 18.3333 5.09987 18.3333 6.5 18.3333Z"
            stroke="#344054"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </div>
    </div>

    <div class="section-container">
      <div class="section">
        <div class="title">Missions effectuées</div>
        <div class="value">{{ stats.missionsDone }}</div>
      </div>
      <div class="section">
        <div class="title">Heures effectuées</div>
        <div class="value">{{ stats.durationDone }}</div>
      </div>
      <div class="section">
        <div class="title">CA généré</div>
        <div class="value">{{ formatCurrencyEUR(stats.turnover) + ' HT' }}</div>
      </div>
    </div>
    <DataTable
      :always-show-paginator="false"
      lazy
      :paginator="true"
      :totalRecords="stats.totalRecords"
      :rows="10"
      :rows-per-page-options="[10, 20, 50]"
      :value="list"
      :loading="loading"
      class="table pointer gd-datatable"
      current-page-report-template="{first}-{last}/{totalRecords}"
      removable-sort
      responsive-layout="scroll"
      striped-rows
      @page="onPage($event)"
      @row-click="onRowClick">
      <template #empty> Aucune mission </template>

      <Column header="Date mission">
        <template #body="slotProps">
          {{ new Date(slotProps.data.dateStart).toLocaleDateString() }}
        </template>
      </Column>

      <Column header="Heure">
        <template #body="slotProps">
          {{ new Date(slotProps.data.dateStart).toLocaleTimeString().slice(0, -3) }}
        </template>
      </Column>

      <Column header="Durée">
        <template #body="slotProps">
          {{ getDuration(slotProps) }}
        </template>
      </Column>

      <Column header="Type">
        <template #body="slotProps">
          <PrestationBadge :type="slotProps.data.type" />
        </template>
      </Column>
      <Column header="Réservation">
        <template #body="slotProps">
          {{ slotProps.data.reservationNum }}
        </template>
      </Column>

      <Column header="Statut du prestataire">
        <template #body="slotProps">
          <PrestationStatusBadge :status="slotProps.data.statusParticipant" />
        </template>
      </Column>

      <Column header="Statut de la mission">
        <template #body="slotProps">
          <PrestationStatusBadge :status="slotProps.data.statusPrestation" />
        </template>
      </Column>

      <Column header="Date d'annulation">
        <template #body="slotProps">
          {{ displayDateTime(slotProps.data.dateCancel) }}
        </template>
      </Column>

      <Column header="Personne qui a annulé la mission">
        <template #body="slotProps">
          {{ slotProps.data.cancellerDisplay }}
        </template>
      </Column>
    </DataTable>

    <span class="mb-1">Indisponibilités</span>
    <DataTable
      :always-show-paginator="false"
      :paginator="true"
      :totalRecords="stats.unavailabilities?.length"
      :rows="10"
      :rows-per-page-options="[10, 20, 50]"
      :value="stats.unavailabilities"
      :loading="loading"
      class="table pointer gd-datatable mb"
      current-page-report-template="{first}-{last}/{totalRecords}"
      removable-sort
      responsive-layout="scroll"
      striped-rows
      @page="onPage($event)">
      <template #empty> Aucune indisponibilité </template>
      <Column header="Date d'ajout">
        <template #body="slotProps">
          {{ new Date(slotProps.data.dateCreated).toLocaleDateString() }}
        </template>
      </Column>

      <Column header="Date de début">
        <template #body="slotProps">
          {{ new Date(slotProps.data.startDate).toLocaleDateString() }}
        </template>
      </Column>

      <Column header="Date de fin">
        <template #body="slotProps">
          {{ new Date(slotProps.data.endDate).toLocaleDateString() }}
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<style lang="scss">
.prestataire-missions {
  .calendar-container {
    position: relative;
    margin-bottom: 24px;

    > .calendar-svg {
      position: absolute;
      left: 0;
      top: 0;
      width: 44px;
      height: 44px;
      display: grid;
      place-content: center;
      pointer-events: none;
    }
  }

  .calendar {
    width: 100%;
    .p-inputtext {
      height: 44px;
    }
  }
  .section-container {
    display: flex;
    flex-direction: row;
    gap: 24px;
  }
  .section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 24px;
    background-color: #ffffff;
    border-radius: 16px;
    margin-bottom: 24px;
    gap: 8px;

    .title {
      font-family: 'Nunito';
      font-size: 14px;
      font-weight: 600;
      color: #475467;
    }
    .value {
      font-family: 'Poppins';
      font-size: 36px;
      font-weight: 600;
      color: #101828;
    }
  }
  .p-input-icon-left {
    display: flex;
    align-items: center;
  }

  .p-inputtext {
    width: 400px;
    padding-left: 42px;
  }

  .pi-search {
    left: 14px !important;
  }

  .date-badge {
    border-radius: 32px;
    padding: 5px 8px;
    font: 600 12px/18px 'Nunito Sans';
    color: white;
  }

  .task {
    padding: 8px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    > span {
      font: 400 14px/20px 'Nunito Sans';
      color: white;
    }
  }

  .gd-btn-filter {
    margin: 0;
  }

  .btn-create {
    height: 44px;
    background-color: #0b3575;
    color: #ffffff;
  }

  .gap-16 {
    gap: 16px;
  }

  .btn-filter {
    display: flex;
    flex-direction: row;
  }
}
</style>
