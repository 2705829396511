<script>
/* COMPOSANTS */
import GaView from '@components/layout/GaView';
import PButton from 'primevue/button';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import permissionsMixin from '@mixins/permissionsMixin';
import accommodationTypeConst from '@mixins/const/accommodationTypeConst';
import messageMixin from '@mixins/messageMixin';
import loaderMixin from '@mixins/loaderMixin';
import residenceTypeConst from '@mixins/const/residenceTypeConst';
import sidebarMixin from '@mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';
import countryConst from '@mixins/const/countryConst';

/* SERVICES */
import accommodationService from '@services/accommodationService';
import companyService from '@services/companyService';
import AkPictureAccommodation from '../../components/general/AkPictureAccommodation';

export default {
  components: {AkPictureAccommodation, PButton, GaView},
  mixins: [
    accommodationTypeConst,
    loaderMixin,
    messageMixin,
    permissionsMixin,
    randomRef,
    residenceTypeConst,
    sidebarMixin,
    utilsMixin,
    countryConst,
  ],
  metaInfo() {
    return {
      title: 'accommodation.list',
    };
  },

  props: {
    resource: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      accommodations: [],
      totalItems: 0,
      loading: true,
      accommodationAction: null,
      displayDeleteDialog: false,
      accommodationTypeFilter: {},
      companies: [],
      filter: {
        page: 1,
        limit: 10,
        enabled: true,
        sort: ['id:DESC'],
      },
      hasPrepublished: false,
    };
  },

  mounted() {
    this.loading = true;
    let p1 = accommodationService.accommodationsPagination(this.filter).then((data) => {
      this.hasPrepublished = data.data.some((acc) => acc.status === 'PREPUBLISHED');
      this.accommodations = this.isOwner() ? data.data.filter((acc) => acc.status === 'PUBLISHED') : data.data;
      this.totalItems = data.totalItems;
    });

    let p2 = companyService.companies();
    p2.then((data) => {
      this.companies = data;
    }).catch((e) => {
      this.showError();
    });

    Promise.all([p1, p2]).then(() => {
      this.loading = false;
    });
  },
  methods: {
    rowClick(event) {
      let data = event.data;
      this.$router.push({name: 'accommodationPage', params: {accommodationId: data.id}});
    },
    onPage(event) {
      this.filter.page = event.page + 1;
      this.filter.limit = event.rows;
      this.search();
    },
    clickSearch() {
      this.filter.page = 1;
      this.search();
    },
    search() {
      this.loading = true;
      accommodationService.accommodationsPagination(this.filter).then((data) => {
        this.hasPrepublished = data.data.some((acc) => acc.status === 'PREPUBLISHED');
        this.accommodations = this.isOwner() ? data.data.filter((acc) => acc.status === 'PUBLISHED') : data.data;
        this.totalItems = data.totalItems;
        this.loading = false;
      });
    },
    onSort(event) {
      this.filter.sort = [];
      for (let sort of event.multiSortMeta) {
        if (sort.field === 'country') {
          this.filter.sort.push('city:' + (sort.order === -1 ? 'DESC' : 'ASC'));
        } else {
          this.filter.sort.push('' + sort.field + ':' + (sort.order === -1 ? 'DESC' : 'ASC'));
        }
      }
      if (this.filter.sort.length === 0) {
        this.filter.sort.push('id:DESC');
      }
      this.search();
    },
    urlPicture(md5) {
      if (md5) {
        return {background: 'url(' + process.env.VUE_APP_PUBLIC_API_URL + '/picture/' + md5 + ')'};
      }
      return {background: 'url(' + require('@/assets/avatar.jpg') + ')'};
    },
    submitForm() {
      this.onPage({page: 0, rows: 10});
    },
    deleteAccommodation() {
      accommodationService
        .delete(this.accommodationAction)
        .then((accommodation) => {
          this.accommodations = this.accommodations.filter((val) => val.id !== accommodation.id);
          this.success('Le logement a bien été supprimé');
          this.displayDeleteDialog = false;
          this.id = null;
        })
        .catch((e) => {
          let content = 'Une erreur est survenue durant la suppression';
          if (e.response) {
            content = 'Une erreur est survenue durant la suppression : ' + e.response.data.error;
          }
          this.addError(content);
          this.displayDeleteDialog = false;
          this.id = null;
        });
    },
    confirmDeleteAccommodation(accommodation) {
      this.accommodationAction = accommodation;
      this.displayDeleteDialog = true;
    },
    displayForArea(data) {
      if (!data.area) return '';
      return data.area + ' m²';
    },
    displayForPlace(data) {
      let countryName = this.displayForCountryConst(data.country);
      if (countryName === 'France') return data.city;
      return countryName;
    },
    classForEnabled(data) {
      return 'ti ti-check text-primary';
    },
    changeEnabled(data) {
      accommodationService
        .update(data)
        .then((accommodation) => {})
        .catch((e) => {
          let content = 'Une erreur est survenue durant la modification';
          if (e.response) {
            content = 'Une erreur est survenue durant la modification : ' + e.response.data.error;
          }
          this.addError(content);
          this.id = null;
        });
    },
    exportAccommodations() {
      this.showTotalLoaderWithAfter(this.$t('accommodation.accommodation_creation_in_progress'));
      accommodationService.exportAccommodations(this.filter).then((data) => {
        this.hideLoader();
      });
    },
    openFilterPanel() {
      this.toggleFilter('GaAccommodationFilterList', this.filter, this.clickSearch, this.resetFilter);
    },
    resetFilter() {
      this.filter = {
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      };
      this.toggleFilter('GaAccommodationFilterList', this.filter, this.search, this.resetFilter);
    },
  },
  computed: {
    canDisplayOnboardingMessageForOwner() {
      return this.isOwner() && this.hasPrepublished && this.accommodations.length === 0;
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :title="this.isOwner() ? $t('accommodation.my_list') : $t('accommodation.list')">
    <template #action>
      <div class="d-flex justify-content-end" style="gap: 16px">
        <PButton
          :badge="this.countFilter(this.filter)"
          badgeClass="p-badge-primary"
          class="btn btn-primary"
          label="Filtrer"
          @click="openFilterPanel()" />
        <button class="btn btn-primary" @click="exportAccommodations()">
          {{ this.$t('payment.download') }}
        </button>
      </div>
    </template>

    <template #content>
      <DataTable
        v-if="!canDisplayOnboardingMessageForOwner"
        :always-show-paginator="false"
        :loading="loading"
        :paginator="true"
        :rows="10"
        :rows-per-page-options="[10, 20, 50]"
        :value="accommodations"
        class="table pointer"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        removable-sort
        responsive-layout="scroll"
        @row-click="rowClick($event)"
        :total-records="totalItems"
        lazy
        sort-mode="multiple"
        @page="onPage($event)"
        @sort="onSort($event)">
        <template #empty>
          {{ $t('accommodation.empty') }}
        </template>

        <Column style="padding: 5px 0px" :sortable="false">
          <template #body="slotProps">
            <AkPictureAccommodation :md5="slotProps.data.picture" :height="80" />
          </template>
        </Column>

        <Column :header="$t('accommodation_title')" :sortable="true" field="name">
          <template #body="slotProps">
            {{ slotProps.data.name }}
          </template>
        </Column>

        <Column
          v-if="!isOwner()"
          :header="$t('accommodation.ownerName')"
          :sortable="false"
          field="contact_first_name"
          class="text-center">
          <template #body="slotProps">
            <span v-if="this.isPartenaire()" class="poppins-light fs-15 color-secondary">{{
              slotProps.data.ownerContactName
            }}</span>
            <router-link
              v-else
              :to="{name: 'ownerPage', params: {ownerId: slotProps.data.ownerId}}"
              class="poppins-light fs-15 color-secondary">
              <span class="poppins-light fs-15 color-secondary">{{ slotProps.data.ownerContactName }}</span>
            </router-link>
          </template>
        </Column>

        <Column
          v-if="isGas()"
          :header="$t('accommodation.companyName')"
          :sortable="true"
          field="company_name"
          class="text-center">
          <template #body="slotProps">
            <span class="poppins-light fs-15 color-secondary">{{ slotProps.data.companyName }}</span>
          </template>
        </Column>

        <Column :header="$t('accommodation.localisation')" :sortable="true" field="country" class="text-center">
          <template #body="slotProps">
            <span class="poppins-light fs-15 color-secondary">{{ displayForPlace(slotProps.data) }}</span>
          </template>
        </Column>

        <Column :header="$t('accommodation.description')" field="capacity" class="text-center">
          <template #body="slotProps">
            <span v-if="slotProps.data.capacity" class="poppins-light fs-15 color-secondary">
              {{ slotProps.data.capacity + ' Voyageur(s)' }}
            </span>
            <span v-if="slotProps.data.capacity" class="sm-bullet"></span>
            <span v-if="slotProps.data.nbRoom" class="poppins-light fs-15 color-secondary">
              {{ slotProps.data.nbRoom + ' Chambre(s)' }}
            </span>
            <span v-if="slotProps.data.nbRoom" class="sm-bullet"></span>
            <span v-if="slotProps.data.nbBathroom" class="poppins-light fs-15 color-secondary">
              {{ slotProps.data.nbBathroom + ' Salle(s) de bain ' }}
            </span>
          </template>
        </Column>

        <Column>
          <template #body="slotProps">
            <router-link
              v-if="!isOwner()"
              :to="{name: 'accommodationPage', params: {accommodationId: slotProps.data.id}}"
              class="btn btn-xs mr-1 float-right">
              <span class="settings-bullet fs-20">...</span>
            </router-link>
            <router-link v-if="isOwner()" :to="{}" class="btn btn-xs mr-1 float-right">
              <span class="settings-bullet fs-20">...</span>
            </router-link>
          </template>
        </Column>
      </DataTable>
      <div v-else class="flex flex-center">
        <span class="text-primary font-md"
          >Les équipes de Guest Adom ont bien reçu votre audit. Vous serez informé par mail dès que l'annonce sera en
          ligne.</span
        >
      </div>
    </template>
    <template #extra>
      <PDialog
        v-model:visible="displayDeleteDialog"
        :header="$t('delete_dialog')"
        :modal="true"
        :style="{width: '450px'}">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span v-if="accommodationAction">{{ $t('accommodation.confirm_delete') }}</span>
        </div>
        <template #footer>
          <span class="btn btn-outline-primary btn-xs mr-1" @click="displayDeleteDialog = false">Non</span>
          <span class="btn btn-danger btn-xs" @click="deleteAccommodation">Oui</span>
        </template>
      </PDialog>
    </template>
  </GaView>
</template>

<style lang="scss" module>
.title {
  text-align: center;
}
</style>
