<script>
import {Icon} from '@iconify/vue';
import ficService from '@services/ficService';
import FileUpload from 'primevue/fileupload';
import Avatar from 'primevue/avatar';
import EnvConst from '@mixins/const/envConst';

export default {
  components: {
    Avatar,
    FileUpload,
    Icon,
  },
  mixins: [EnvConst],
  props: {
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    canUpload: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: 'large',
    },
    className: {
      type: String,
      required: false,
      default: 'profile',
    },
  },
  data() {
    return {
      base64data: undefined,
    };
  },
  computed: {
    getInitiale() {
      return `${this.firstName ? this.firstName.charAt(0).toUpperCase() : ''}${
        this.lastName ? this.lastName.charAt(0).toUpperCase() : ''
      }`;
    },
  },
  mounted() {
    ficService
      .displayUser(this.userId)
      .then((data) => {
        this.base64data = data;
      })
      .catch(() => {});
  },
  methods: {
    uploadPicture() {
      if (this.canUpload) this.$refs.uploader.$refs.fileInput.click();
    },
    selectFiles(event) {
      if (event.files) {
        ficService.uploadUserPicture(event.files[0]).finally(() => {
          ficService.displayUser(this.userId).then((data) => {
            this.base64data = data;
          });
          this.$refs.uploader.clear();
        });
      }
    },
  },
};
</script>

<template>
  <div class="v-avatar">
    <div :class="[className, size && 'is-' + this.size]" class="ak-avatar">
      <div class="avatar">
        <Avatar v-if="!base64data" :label="getInitiale" :size="this.size" shape="circle" @click="uploadPicture" />
        <img v-if="base64data" :src="base64data" />
      </div>

      <div v-if="canUpload" class="change-picture" @click="uploadPicture">
        <Icon icon="fluent:camera-add-48-regular" />
      </div>
    </div>
    <div class="clearboth" />
    <FileUpload
      ref="uploader"
      :auto="true"
      :cancel-label="$t('cancel_file')"
      :choose-label="$t('add_file')"
      :custom-upload="true"
      :invalidFileSizeMessage="$t('file.err_size')"
      :maxFileSize="getMaxFileUpload()"
      :multiple="false"
      :show-upload-button="false"
      class="d-none"
      mode="basic"
      name="uploader"
      @select="selectFiles" />
  </div>
</template>
