import Api from '@/services/api';
import {createFormDataFile, removeEmptyParams} from '@utils';

export default {
  restrictions(filter) {
    return Api()
      .get('/restriction', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  restriction(restrictionId) {
    return Api()
      .get('/restriction/' + restrictionId)
      .then((res) => this.formatRes(res.data));
  },
  restrictionsByEmployeeId(employeeId) {
    return Api()
      .get('/restriction/employee/' + employeeId)
      .then((res) => this.formatRes(res.data));
  },
  create(restriction, file) {
    let data = createFormDataFile(restriction, file);
    return Api()
      .post('/restriction', data)
      .then((res) => this.formatRes(res.data));
  },
  update(restriction) {
    return Api()
      .put('/restriction/' + restriction.id, restriction)
      .then((res) => this.formatRes(res.data));
  },
  delete(restriction) {
    return Api()
      .delete('/restriction/' + restriction.id)
      .then(() => restriction);
  },
  formatRes(e) {
    if (e.startDate) e.startDate = new Date(e.startDate);
    if (e.endDate) e.endDate = new Date(e.endDate);

    return e;
  },
};
