import Api from '@/services/api';
import {removeEmptyParams} from '@utils';
import contentDisposition from 'content-disposition';

export default {
  stayrates(filter) {
    return Api()
      .get('/stayrate', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  stayratesGroups(rateId) {
    return Api()
      .get('/stayrate/groups')
      .then((res) => this.formatRes(res.data));
  },
  stayrate(rateId) {
    return Api()
      .get('/stayrate/' + rateId)
      .then((res) => this.formatRes(res.data));
  },
  create(rate) {
    return Api()
      .post('/stayrate', rate)
      .then((res) => this.formatRes(res.data));
  },
  update(rate) {
    return Api()
      .put('/stayrate/' + rate.id, rate)
      .then((res) => this.formatRes(res.data));
  },
  delete(rate) {
    return Api()
      .delete('/stayrate/' + rate.id)
      .then(() => rate);
  },
  formatRes(e) {
    return e;
  },
};
