<script>
/* COMPOSANTS */
import {Icon} from '@iconify/vue/dist/iconify';
import MultiSelect from 'primevue/multiselect';
import AkDropdown from '@components/input/AkDropdown.vue';
import AkOwnerAutocomplete from '@components/general/AkOwnerAutocomplete.vue';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@/mixins/sidebarMixin';
import accommodationTypeConst from '@mixins/const/accommodationTypeConst';
import residenceTypeConst from '@mixins/const/residenceTypeConst';
import ownerTypesConst from '@mixins/const/ownerTypesConst';

/* SERVICES */
import companyService from '@services/companyService';

export default {
  components: {
    AkOwnerAutocomplete,
    AkDropdown,
    MultiSelect,
    Icon,
  },
  mixins: [permissionsMixin, utilsMixin, sidebarMixin, accommodationTypeConst, residenceTypeConst, ownerTypesConst],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      companies: undefined,
      enabledStateConst: [
        {label: this.$t('prospect.all'), value: null},
        {label: this.$t('prospect.enabled_only'), value: true},
        {label: this.$t('prospect.disabled_only'), value: false},
      ],
    };
  },
  mounted() {
    companyService.companies().then((data) => {
      this.companies = data;
    });
  },
  methods: {
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.ownerAutocomplete) this.$refs.ownerAutocomplete.reset();
      this.callback[1]();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <h2>Filtrer</h2>
  <form class="d-flex flex-column" style="row-gap: 16px" @submit.prevent="search">
    <MultiSelect
      v-if="isGas()"
      v-model="currentValue.companyIds"
      :placeholder="$t('employee.company')"
      :options="this.companies"
      class="flex-1 form-control"
      option-label="name"
      option-value="id" />
    <AkOwnerAutocomplete
      ref="ownerAutocomplete"
      inline
      v-model="currentValue.ids"
      :placeholder="$t('prospect.ownerName')"
      class-name="flex-1 form-control" />
    <AkDropdown
      v-model="currentValue.ownerType"
      inline
      :label="$t('prospect.ownerType')"
      :options="ownerTypesConst"
      class-name="flex-1 form-control"
      option-label="label"
      option-value="value" />
    <AkDropdown
      v-model="currentValue.userEnabled"
      inline
      :label="$t('prospect.all')"
      :options="enabledStateConst"
      class-name="flex-1 form-control"
      option-label="label"
      option-value="value" />
    <div class="d-flex flex-row">
      <button class="btn btn-primary btn-search flex-1" type="submit">Rechercher</button>
      <Icon
        v-on:click="this.reset()"
        class="pointer"
        icon="basil:cross-outline"
        :style="{fontSize: '50px', width: '50px !important'}" />
    </div>
  </form>
</template>
