export default {
  data() {
    return {
      litigeStatusConst: [
        {label: this.$t('litige.a_resoudre'), value: 'A_RESOUDRE'},
        {label: this.$t('litige.a_traiter'), value: 'A_TRAITER'},
        {label: this.$t('litige.en_cours'), value: 'EN_COURS'},
        {label: this.$t('litige.litige_complete'), value: 'COMPLETE'},
        {label: this.$t('litige.litige_complete_validated'), value: 'VALIDATED'},
        {label: this.$t('litige.litige_complete_refused'), value: 'REFUSED'},
        {label: this.$t('litige.resolu'), value: 'RESOLU'},
      ],
    };
  },
  methods: {
    displayForLitigeStatusConst(value) {
      let result = this.litigeStatusConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
    // liste utilisée pour le filtre de la liste des litiges
    litigeStatusConstForFilter() {
      return this.litigeStatusConst.filter((el) => el.value !== 'RESOLU');
    },
  },
};
