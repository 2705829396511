<script>
/* COMPOSANTS */
import InputText from 'primevue/inputtext';
import PButton from 'primevue/button';
import PrestationBadge from '@components/general/PrestationBadge.vue';
import PrestationStatusBadge from '@components/general/PrestationStatusBadge.vue';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';

/* SERVICES */
import missionOrderService from '@services/missionOrderService';

export default {
  components: {
    InputText,
    PButton,
    PrestationBadge,
    PrestationStatusBadge,
  },
  mixins: [permissionsMixin, utilsMixin, sidebarMixin],
  metaInfo() {
    return {
      title: 'mission_order.title',
    };
  },
  data() {
    return {
      list: [],
      loading: false,
      textSearch: '',
      filter: {
        page: 0,
        limit: 10,
      },
      totalItems: 0,
      activeTab: 0,
    };
  },
  watch: {
    activeTab: function () {
      this.filter.page = 0;
      this.onPage({page: this.filter.page, rows: this.filter.limit});
    },
  },
  mounted() {
    this.onPage({page: this.filter.page, rows: this.filter.limit});
  },
  computed: {
    getStatusFromTab() {
      if (this.activeTab === 1) {
        return 'PROPOSED';
      }
      if (this.activeTab === 2) {
        return 'ACCEPTED';
      }
      if (this.activeTab === 3) {
        return 'COMPLETED';
      }
      if (this.activeTab === 4) {
        return 'LITIGATION';
      }
      if (this.activeTab === 5) {
        return 'CANCELED';
      }
      return '';
    },
    isActiveTabCanceled() {
      return this.activeTab === 5;
    },
    first() {
      return (this.filter.page - 1) * this.filter.limit;
    },
  },
  methods: {
    rowClick(event) {
      this.$router.push({
        name: 'mission-order-detail',
        params: {missionOrderId: event.data.id},
      });
    },
    refresh() {
      this.loading = true;

      this.filter.query = this.textSearch;
      this.filter.status = this.getStatusFromTab;

      missionOrderService
        .pagination(this.filter)
        .then((data) => {
          this.totalItems = data.totalItems;
          this.list = data.data;
        })
        .finally(() => (this.loading = false));
    },
    onPage(event) {
      this.filter.page = event.page + 1;
      this.filter.limit = event.rows;
      this.refresh();
    },
    submitForm() {
      this.filter.page = 0;
      this.onPage({page: this.filter.page, rows: this.filter.limit});
    },

    // FILTER

    openFilterPanel() {
      this.toggleFilter('GaMissionOrderListFilter', this.filter, this.refresh, this.resetFilter);
    },
    resetFilter() {
      this.filter = {
        page: 1,
        limit: 10,
      };
      this.toggleFilter('GaMissionOrderListFilter', this.filter, this.refresh, this.resetFilter);
    },
  },
};
</script>

<template>
  <div class="h-100 d-flex flex-column no-mission-order-list">
    <GaTitleMenu :with-bullet="false">
      <GaItemTitle :active="true" :title="$t('mission_order.title')" />
      <form class="p-input-icon-left ml-auto" @submit.prevent="submitForm">
        <i class="pi pi-search" />
        <InputText :placeholder="$t('search.search')" v-model="textSearch" />
      </form>
      <PButton
        class="btn btn-primary ml-2"
        :badge="this.countFilter(this.filter)"
        badgeClass="p-badge-primary"
        :label="$t('search.filter')"
        @click="openFilterPanel()">
      </PButton>
    </GaTitleMenu>

    <!-- TODO : refondre en GaTabView-->

    <div class="tab-container mt-2">
      <div class="tab" :class="activeTab === 0 ? 'active' : ''" @click="activeTab = 0">
        {{ this.$t('mission_order.tabs.all') }}
      </div>
      <div class="tab" :class="activeTab === 1 ? 'active' : ''" @click="activeTab = 1">
        {{ this.$t('mission_order.tabs.proposed') }}
      </div>
      <div class="tab" :class="activeTab === 2 ? 'active' : ''" @click="activeTab = 2">
        {{ this.$t('mission_order.tabs.accepted') }}
      </div>
      <div class="tab" :class="activeTab === 3 ? 'active' : ''" @click="activeTab = 3">
        {{ this.$t('mission_order.tabs.completed') }}
      </div>
      <div class="tab" :class="activeTab === 4 ? 'active' : ''" @click="activeTab = 4">
        {{ this.$t('mission_order.tabs.litigation') }}
      </div>
      <div class="tab" :class="activeTab === 5 ? 'active' : ''" @click="activeTab = 5">
        {{ this.$t('mission_order.tabs.canceled') }}
      </div>
    </div>
    <div class="content-list">
      <DataTable
        :always-show-paginator="false"
        :paginator="true"
        :rows="10"
        :rows-per-page-options="[10, 20, 50]"
        :first="this.first"
        :value="list"
        lazy
        :loading="loading"
        :totalRecords="totalItems"
        class="table pointer gd-datatable"
        current-page-report-template="{first}-{last}/{totalRecords}"
        removable-sort
        responsive-layout="scroll"
        striped-rows
        @row-click="rowClick($event)"
        @page="onPage($event)">
        <template #empty> {{ this.$t('mission_order.empty') }} </template>

        <Column :header="$t('mission_order.reservation')">
          <template #body="slotProps">
            <span class="text-break">{{ slotProps.data.reservation?.reservationNum ?? '-' }}</span>
          </template>
        </Column>

        <Column :header="$t('mission_order.mission_status')">
          <template #body="slotProps">
            <PrestationStatusBadge :status="slotProps.data.statusParticipants" />
          </template>
        </Column>

        <Column :header="$t('mission_order.assigned_staff')">
          <template #body="slotProps">
            <PrestationStatusBadge :status="slotProps.data.statusPrestation" />
          </template>
        </Column>

        <Column :header="$t('mission_order.nb_participants')" class="text-center">
          <template #body="slotProps">
            {{ slotProps.data.participantNumber }}
          </template>
        </Column>

        <Column :header="$t('mission_order.date_created')">
          <template #body="slotProps">
            {{ new Date(slotProps.data.dateCreated).toLocaleDateString() }}
          </template>
        </Column>

        <Column :header="$t('company.one')" v-if="this.isGas()">
          <template #body="slotProps">
            {{ slotProps.data.companyName }}
          </template>
        </Column>

        <Column :header="$t('mission_order.accommodation')">
          <template #body="slotProps">
            {{ slotProps.data.accommodation.name }}
          </template>
        </Column>

        <Column :header="$t('mission_order.date_start')">
          <template #body="slotProps">
            {{ slotProps.data.dateStart ? new Date(slotProps.data.dateStart).toLocaleDateString() : '-' }}
          </template>
        </Column>

        <Column :header="$t('reservation.dateCheckin')">
          <template #body="slotProps">
            {{
              slotProps.data.reservation?.dateCheckin
                ? new Date(slotProps.data.reservation?.dateCheckin).toLocaleDateString()
                : '-'
            }}
          </template>
        </Column>

        <Column :header="$t('reservation.dateCheckout')">
          <template #body="slotProps">
            {{
              slotProps.data.reservation?.dateCheckout
                ? new Date(slotProps.data.reservation?.dateCheckout).toLocaleDateString()
                : '-'
            }}
          </template>
        </Column>

        <Column :header="$t('accommodation.address')">
          <template #body="slotProps">
            <div>
              {{ slotProps.data.accommodation.address }}
            </div>
            <div>
              {{ slotProps.data.accommodation.postalCode + ' ' + slotProps.data.accommodation.city }}
            </div>
          </template>
        </Column>

        <Column :header="$t('mission_order.date_cancel')" v-if="this.isActiveTabCanceled">
          <template #body="slotProps">
            {{ this.displayDateTime(slotProps.data.cancellationDate) }}
          </template>
        </Column>

        <Column :header="$t('mission_order.canceller')" v-if="this.isActiveTabCanceled">
          <template #body="slotProps">
            {{ slotProps.data.cancellationUsername }}
          </template>
        </Column>

        <Column :header="$t('mission_order.type')">
          <template #body="slotProps">
            <PrestationBadge :type="slotProps.data.type" :key="slotProps.data.type" />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.no-mission-order-list {
  .calendar {
    width: 200px;
  }

  .p-input-icon-left {
    display: flex;
    align-items: center;
  }

  .p-inputtext {
    width: 400px;
  }

  .pi-search {
    left: 14px !important;
  }

  .status-badge {
    width: fit-content;
    padding: 2px 8px;
    background-color: #ecfdf3;
    color: #027a48;
    border-radius: 16px;
  }

  .date-badge {
    border-radius: 32px;
    padding: 5px 8px;
    font: 600 12px/18px 'Nunito Sans';
    color: white;
  }

  .task {
    padding: 8px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    > span {
      font: 400 14px/20px 'Nunito Sans';
      color: white;
    }
  }

  .gd-btn-filter {
    margin: 0;
  }

  .btn-create {
    height: 44px;
    background-color: #0b3575;
    color: #ffffff;
  }

  .gap-16 {
    gap: 16px;
  }

  .btn-filter {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }

  .btn-create {
    height: 44px;
    background-color: #0b3575;
    color: #ffffff;
  }

  .filter {
    max-width: 200px;
  }

  .tab-container {
    display: flex;
    flex-direction: row;
    height: 32px;
    gap: 24px;
  }

  .tab {
    width: fit-content;
    cursor: pointer;
    color: #667085;
    height: 32px;
    box-sizing: border-box;

    font-family: 'Nunito';
    font-size: 14px;
    font-weight: 600;
  }

  .tab.active {
    color: #092858;
    border-bottom: solid 2px #092858;
  }

  .filter {
    margin-bottom: 0;
    > .mb-label {
      margin-bottom: 0;
    }
  }
}
.title-container {
  display: flex;
  flex-direction: row;
  gap: 26px;
  align-items: center;
}
</style>
