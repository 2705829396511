<script>
import AkLabel from '@components/general/AkLabel';
import InputNumber from 'primevue/inputnumber';

export default {
  components: {AkLabel, InputNumber},
  inject: {pSubmitted: {default: null}, pDisabled: {default: null}},
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    className: {
      type: String,
      required: false,
      default: 'col-md-4 col-6',
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    suffix: {
      type: String,
      required: false,
      default: undefined,
    },
    min: {
      type: Number,
      required: false,
      default: null,
    },
    max: {
      type: Number,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    displayLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    isInvalid() {
      return this.validator !== undefined && this.validator.$invalid;
    },
    isRequired() {
      return this.validator !== undefined && this.validator.required !== undefined;
    },
    isValidatorRequired() {
      return (
        this.validator !== undefined &&
        this.validator.required !== undefined &&
        this.validator.required.$invalid &&
        this.alreadySubmitted
      );
    },
    isBoundaryRequired() {
      return (
        this.validator !== undefined &&
        this.validator.betweenValue !== undefined &&
        this.validator.betweenValue.$invalid &&
        this.alreadySubmitted
      );
    },
    isMinValueRequired() {
      return (
        this.validator !== undefined &&
        this.validator.minValue !== undefined &&
        this.validator.minValue.$invalid &&
        this.alreadySubmitted
      );
    },
  },
  methods: {},
};
</script>

<template>
  <div :class="className" class="form-group">
    <AkLabel v-if="displayLabel" :required="isRequired">
      {{ label }}
    </AkLabel>
    <InputNumber
      v-model="currentValue"
      :class="{'p-invalid': isInvalid && alreadySubmitted, disabled: isDisabled}"
      :disabled="isDisabled"
      :max="max"
      :min="min"
      :placeholder="getPlaceholder"
      :suffix="suffix"
      class="form-control"
      type="text" />
    <small v-if="isValidatorRequired" class="p-error">{{ $t('field_required', [label]) }}</small>

    <small v-if="isBoundaryRequired" class="p-error">{{
      $t('min_max_value', [label, validator.betweenValue.$params.min, validator.betweenValue.$params.max])
    }}</small>

    <small v-if="isMinValueRequired" class="p-error">{{
      $t('min_value', [label, validator.minValue.$params.min])
    }}</small>
  </div>
</template>
