import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  epis(filter) {
    return Api()
      .get('/epi', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  epi(epiId) {
    return Api()
      .get('/epi/' + epiId)
      .then((res) => this.formatRes(res.data));
  },
  episByEmployeeId(employeeId) {
    return Api()
      .get('/epi/employee/' + employeeId)
      .then((res) => this.formatRes(res.data));
  },
  create(epi) {
    return Api()
      .post('/epi', epi)
      .then((res) => this.formatRes(res.data));
  },
  update(epi) {
    return Api()
      .put('/epi/' + epi.id, epi)
      .then((res) => this.formatRes(res.data));
  },
  delete(epi) {
    return Api()
      .delete('/epi/' + epi.id)
      .then(() => epi);
  },
  formatRes(e) {
    if (e.deliveryDate) e.deliveryDate = new Date(e.deliveryDate);
    return e;
  },
};
