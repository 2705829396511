import Api from '@/services/api';
import {removeEmptyParams} from '@utils';
import moment from 'moment/moment';

export default {
  comptaRecurrences(filter) {
    return Api()
      .get('/comptarecurrences', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  comptaRecurrence(filter) {
    return Api()
      .get('/comptarecurrence/' + filter.id, {params: removeEmptyParams(filter)})
      .then((res) => this.formatRes(res.data));
  },
  findRecurrence(filter) {
    return Api()
      .get('/comptarecurrence/recurrence', {params: removeEmptyParams(filter)})
      .then((res) => this.formatRes(res.data));
  },
  create(comptaCharge) {
    return Api()
      .post('/comptarecurrence', comptaCharge)
      .then((res) => this.formatRes(res.data));
  },
  update(comptaCharge) {
    return Api()
      .put('/comptarecurrence/' + comptaCharge.id, comptaCharge)
      .then((res) => this.formatRes(res.data));
  },
  delete(comptaCharge) {
    return Api()
      .delete('/comptarecurrence/' + comptaCharge.id)
      .then(() => comptaCharge);
  },
  formatRes(data) {
    return data;
  },
};
