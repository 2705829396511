<script>
/* COMPOSANTS */
import AkDialog from '@components/general/AkDialog';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import messageMixin from '@mixins/messageMixin';
import cdcmodelTypesConst from '@mixins/const/cdcmodelTypesConst';

/* SERVICES */
import cdcmodelService from '@services/cdcmodelService';
import loaderMixin from '@mixins/loaderMixin';
import GaView from '@components/layout/GaView';

export default {
  components: {GaView, AkDialog},
  mixins: [cdcmodelTypesConst, loaderMixin, messageMixin, randomRef],
  metaInfo() {
    return {
      title: 'settings.cdcmodels',
    };
  },
  data() {
    return {
      models: [],
    };
  },
  computed: {},
  mounted() {
    this.showTotalLoader();
    cdcmodelService.cdcmodelsDefault().then((data) => {
      this.models = data;
      this.hideLoader();
    });
  },
  methods: {
    resetCdc() {
      this.showTotalLoader();
      cdcmodelService
        .resetCdc()
        .then(() => {
          this.success(this.$t('cdcmodel.cdc_reset_done'));
        })
        .catch((e) => {
          if (e.response && e.response.status === 412) {
            this.addError(this.$t('cdcmodel.error_reset_cdc_prestation'));
          } else {
            this.addError(this.$t('error.' + e.response.data.error));
          }
        })
        .finally(() => {
          this.hideLoader();
        });
    },
    openDialogConfirmReset(item) {
      this.item = item;
      this.$refs.dialogConfirmReset.show();
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :title="$t('settings.cdcmodels')">
    <template #action>
      <div class="d-flex justify-content-end">
        <button class="btn btn-danger" @click="openDialogConfirmReset()">
          {{ this.$t('cdcmodel.reset') }}
        </button>
      </div>
    </template>

    <template #content>
      <DataTable
        :always-show-paginator="false"
        :paginator="true"
        :rows="10"
        :rows-per-page-options="[10, 20, 50]"
        :value="models"
        class="table"
        current-page-report-template="{first}-{last}/{totalRecords}"
        removable-sort
        responsive-layout="scroll"
        sort-field="position"
        striped-rows>
        <template #empty>
          {{ $t('zone.empty') }}
        </template>
        <Column :header="$t('cdcmodel.type')">
          <template #body="slotProps">
            <router-link :to="{name: 'cdcmodelDetails', params: {cdcmodelId: slotProps.data.id}}">
              {{ displayForCdcmodelTypeConst(slotProps.data.type) }}
            </router-link>
          </template>
        </Column>
        <Column :header="$t('cdcmodel.item_count')">
          <template #body="slotProps">
            <router-link :to="{name: 'cdcmodelDetails', params: {cdcmodelId: slotProps.data.id}}">
              {{ slotProps.data.itemCount }}
            </router-link>
          </template>
        </Column>
      </DataTable>
    </template>

    <template #extra>
      <AkDialog
        ref="dialogConfirmReset"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('confirm_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.resetCdc()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span>{{ $t('cdcmodel.confirm_reset') }}</span>
        </div>
      </AkDialog>
    </template>
  </GaView>
</template>
