<script>
import {required} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

/* COMPOSANTS */
import OperationsOwnerCharge from '@views/accounting/operations/tabs/OperationsOwnerCharge';
import OperationsRecurringCharges from '@views/accounting/operations/tabs/OperationsRecurringCharges';
import OperationsConsultation from '@views/accounting/operations/tabs/OperationsConsultation';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import messageMixin from '@mixins/messageMixin';
import loaderMixin from '@mixins/loaderMixin';
import accountOperationsConst from '@mixins/const/accountOperationsConst';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import operationsTabMixin from '@mixins/tab/operationsTabMixin';
import {PermissionMap} from '@mixins/const/permissionConst';
import permissionsMixin from '@mixins/permissionsMixin';

/* SERVICES */

export default {
  computed: {
    PermissionMap() {
      return PermissionMap;
    },
  },
  components: {
    OperationsOwnerCharge,
    OperationsRecurringCharges,
    OperationsConsultation,
  },
  mixins: [
    randomRef,
    accountOperationsConst,
    messageMixin,
    loaderMixin,
    utilsMixin,
    sidebarMixin,
    operationsTabMixin,
    permissionsMixin,
  ],
  metaInfo() {
    return {
      title: 'accounting.operations',
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      activeTab: undefined,
    };
  },
  mounted() {
    if (this.$route.hash) {
      this.activeTab = this.$route.hash.slice(1);
    }
  },
  methods: {
    getFirstTab() {
      if (this.hasPermission(PermissionMap.OPERATION_OWNER_CHARGE.VIEW) || this.isPoleComptabiliteMandante()) {
        return 'operationsOwnerCharge';
      }
      return 'operationsConsultation';
    },
  },
};
</script>

<template>
  <GaTabView :firstTab="getFirstTab()" :title="$t('accounting.operations')" :tabs="tabs">
    <template
      #operationsOwnerCharge
      v-if="this.hasPermission(PermissionMap.OPERATION_OWNER_CHARGE.VIEW) || this.isPoleComptabiliteMandante">
      <OperationsOwnerCharge />
    </template>
    <template #operationsConsultation>
      <OperationsConsultation />
    </template>
    <template #operationsRecurringCharges>
      <OperationsRecurringCharges />
    </template>
  </GaTabView>
</template>
