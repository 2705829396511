import {defineStore} from 'pinia';

export const useMessageStore = defineStore('messages', {
  state: () => ({list: []}),
  getters: {
    messages: (state) => state.list,
  },
  actions: {
    addError(content, reset = true, closable = true) {
      if (reset) this.resetMessages();
      this.list.push({closable: closable, severity: 'error', content: content});
      this.setLastMessageLifeTime(10000);
    },
    addWarning(content, reset = true, closable = true) {
      if (reset) this.resetMessages();
      this.list.push({closable: closable, severity: 'warn', content: content});
      this.setLastMessageLifeTime(10000);
    },
    success(content, reset = true, closable = true) {
      if (reset) this.resetMessages();
      this.list.push({closable: closable, severity: 'success', content: content});
      this.setLastMessageLifeTime(10000);
    },
    addSuccess(content, reset = true, closable = true) {
      if (reset) this.resetMessages();
      this.list.push({closable: closable, severity: 'success', content: content});
      this.setLastMessageLifeTime(10000);
    },
    resetMessages() {
      this.list = [];
    },
    closeMessage(message) {
      this.list = this.list.filter((val) => val !== message);
    },
    setLastMessageLifeTime(lifeTime) {
      let message = this.list[this.list.length - 1];
      let that = this;
      setTimeout(function () {
        that.closeMessage(message);
      }, lifeTime);
    },
  },
});
