<script>
import moment from 'moment/moment';

/* COMPOSANTS */
import AkLoader from '@components/general/AkLoader.vue';
import AkDialog from '@components/general/AkDialog';
import PButton from 'primevue/button';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import messageMixin from '@mixins/messageMixin';
import loaderMixin from '@mixins/loaderMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import permissionConst, {PermissionMap} from '@mixins/const/permissionConst';

/* SERVICES */
import comptaOperationService from '@services/comptaOperationService';

export default {
  components: {
    PButton,
    AkDialog,
    AkLoader,
  },
  mixins: [randomRef, messageMixin, loaderMixin, utilsMixin, permissionsMixin, permissionConst, sidebarMixin],
  metaInfo() {
    return {
      title: 'accounting.transactions',
    };
  },
  data() {
    return {
      loading: false,
      list: [],
      listOperations: [],
      item: {},
      filter: {
        dateStart: moment().toDate(),
        dateEnd: moment().toDate(),
        page: 1,
        limit: 10,
        sort: ['id:DESC'],
      },
    };
  },
  mounted() {
    this.search();
  },
  computed: {
    hasManagePermission() {
      return this.hasPermission(PermissionMap.TRANSACTION.MANAGE);
    },
    totalDebit() {
      if (!this.list) return 0;
      let total = 0;
      for (let operation of this.list) {
        if (operation.amount < 0) total += operation.amount;
      }
      return total;
    },
    totalCredit() {
      if (!this.list) return 0;
      let total = 0;
      for (let operation of this.list) {
        if (operation.amount >= 0) total += operation.amount;
      }
      return total;
    },
    totalBalance() {
      return this.totalCredit + this.totalDebit;
    },

    totalDebitDisplay() {
      return this.formatAmount(-1 * this.totalDebit);
    },
    totalCreditDisplay() {
      return this.formatAmount(this.totalCredit);
    },
    totalBalanceDisplay() {
      return this.formatAmount(this.totalBalance);
    },
  },
  methods: {
    clickSearch() {
      this.filter.page = 1;
      this.search();
    },
    search() {
      this.$refs.loader.load();
      this.listOperations = [];
      let p1 = comptaOperationService.transactions({
        dateStart: this.filter.dateStart,
        dateEnd: this.filter.dateEnd,
        amount: this.filter.amount,
        reservation: this.filter.reservation,
      });
      p1.then(this.loadOperations).catch((e) => {
        if (e.response && e.response.status === 412) {
          this.$refs.loader.hide();
          this.addError(this.$t('account.no_exist'), true, true);
        }
      });
      Promise.all([p1]).then(() => {
        this.$refs.loader.hide();
      });
    },
    loadOperations(data) {
      this.list = data;
      for (const element of this.list) {
        element.operations[0].first = true;
        element.operations[0].locked = element.locked;
        this.listOperations.push(...element.operations);
      }
      let totalDebit = 0;
      let totalCredit = 0;
      let total = {};
      for (let account of this.listOperations) {
        if (account.amount > 0) {
          totalCredit += account.amount;
        } else {
          totalDebit += account.amount;
        }
      }
      total.transactionId = -1;
      total.typeTransaction = 'total';
      total.creditDisplay = totalCredit.toFixed(2);
      total.debitDisplay = (totalDebit.toFixed(2) * -1).toFixed(2);
      this.listOperations.push(total);
    },
    rowClass(data) {
      return [{'hightlight-total': data.transactionId === -1}];
    },
    formatAmount(num) {
      return Number.parseFloat(num).toFixed(2);
    },
    formatCredit(amount) {
      if (amount < 0) return '';
      return this.formatAmount(amount);
    },
    formatDebit(amount) {
      if (amount >= 0) return '';
      return this.formatAmount(-amount);
    },
    openDeleteDialog(item) {
      this.item = item;
      this.$refs.dialogDelete.show();
    },
    delete() {
      comptaOperationService
        .deleteFromTransaction(this.item.transactionId)
        .then((data) => {
          this.success(this.$t('operation.delete_transaction_ok'));
          this.listOperations = this.listOperations.filter((t) => t.transactionId !== data);
        })
        .catch((e) => {
          let content = 'Une erreur est survenue durant la suppression';
          if (e.response) content = 'Une erreur est survenue durant la suppression : ' + e.response.data.error;
          this.addError(content);
        })
        .finally(() => {
          this.$refs.loader.hide();
        });
    },
    displayComment(slotProp) {
      this.showComment(slotProp);
    },
    openFilterPanel() {
      this.toggleFilter('GaTransactionsFilterList', this.filter, this.clickSearch, this.resetFilter);
    },
    resetFilter() {
      this.filter = {
        dateStart: moment().toDate(),
        dateEnd: moment().toDate(),
        page: 1,
        limit: 10,
        sort: ['id:DESC'],
      };
      this.toggleFilter('GaTransactionsFilterList', this.filter, this.search, this.resetFilter);
    },
  },
};
</script>
<template>
  <GaView ref="ref" :title="$t('accounting.transactions')">
    <template #action>
      <div class="d-flex justify-content-end">
        <PButton
          :badge="this.countFilter(this.filter)"
          badgeClass="p-badge-primary"
          class="btn btn-primary"
          label="Filtrer"
          @click="openFilterPanel()">
        </PButton>
      </div>
    </template>

    <template #content>
      <AkLoader ref="loader" :message="$t('currently_loading')" :scale="1.5">
        <template #content>
          <DataTable
            :row-class="rowClass"
            :sortOrder="1"
            :value="listOperations"
            class="compta-table"
            groupRowsBy="transactionId"
            responsiveLayout="scroll"
            rowGroupMode="subheader"
            stripedRows>
            <template #groupheader>
              <span></span>
            </template>
            <Column field="transactionId" header="Transaction"> </Column>
            <Column header="Date">
              <template #body="slotProps">
                <span :title="slotProps.data.transactionId">{{ displayDate(dateToUtc(slotProps.data.date)) }}</span>
              </template>
            </Column>
            <Column :header="$t('operation.number')">
              <template #body="slotProps">
                <span>{{ slotProps.data.accountNum }}</span>
              </template>
            </Column>
            <Column :header="$t('operation.company')">
              <template #body="slotProps">
                <span>{{ slotProps.data.companyName }}</span>
              </template>
            </Column>
            <Column :header="$t('operation.label')">
              <template #body="slotProps">
                <div class="d-flex align-items-center">
                  {{ slotProps.data.label }}
                  <i
                    v-if="slotProps.data.comment && slotProps.data.first"
                    class="fa fa-info-circle ml-1 pointer"
                    @click="displayComment(slotProps.data)"></i>
                </div>
              </template>
            </Column>
            <Column :header="$t('operation.creator')">
              <template #body="slotProps">
                <div class="d-flex align-items-center">
                  {{ slotProps.data.creatorName }}
                </div>
              </template>
            </Column>
            <Column :header="$t('operation.updater')">
              <template #body="slotProps">
                <div class="d-flex align-items-center">
                  {{ slotProps.data.updaterName }}
                </div>
              </template>
            </Column>
            <Column :header="$t('operation.type')">
              <template #body="slotProps">
                <span>{{ this.$t('operation.' + slotProps.data.typeTransaction) }}</span>
              </template>
            </Column>
            <Column :header="$t('accounting.debit')" class="text-right">
              <template #body="slotProps">
                <span v-if="slotProps.data.debitDisplay !== ''">{{ slotProps.data.debitDisplay }}</span>
              </template>
            </Column>
            <Column :header="$t('accounting.credit')" class="text-right">
              <template #body="slotProps">
                <span v-if="slotProps.data.creditDisplay !== ''">{{ slotProps.data.creditDisplay }}</span>
              </template>
            </Column>
            <Column>
              <template #body="slotProps">
                <div
                  v-if="slotProps.data.first && !slotProps.data.locked && hasManagePermission"
                  class="d-flex justify-content-center align-items-center">
                  <router-link
                    :to="{name: 'accountingTransactionUpdate', params: {transactionId: slotProps.data.transactionId}}"
                    class="btn btn-xs btn-inverse-secondary mr-1">
                    <i class="fe fe-edit" />
                  </router-link>
                  <i class="btn btn-inverse-danger btn-xs" @click="openDeleteDialog(slotProps.data)"
                    ><i class="fe fe-trash"
                  /></i>
                </div>
                <div v-if="slotProps.data.dateClosed" class="d-flex justify-content-center align-items-center">
                  <i class="fe fe-lock" />
                </div>
              </template>
            </Column>
          </DataTable>
        </template>
      </AkLoader>
    </template>
    <template #extra>
      <AkDialog
        ref="dialogDelete"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.delete()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span>{{ $t('operation.delete_transaction') }}</span>
        </div>
      </AkDialog>
    </template>
  </GaView>
</template>
