<script>
import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

/* COMPOSANTS */
import AkInputText from '@components/input/AkInputText';
import AkDialog from '@components/general/AkDialog';
import GaView from '@components/layout/GaView';
import AkMultiSelect from '@components/input/AkMultiSelect.vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import ticketCategoryService from '@services/ticketCategoryService';
import poleService from '@services/poleService';
import ticketSubCategoryService from '@services/ticketSubCategoryService';

export default {
  components: {
    AkMultiSelect,
    GaView,
    AkInputText,
    AkDialog,
  },
  mixins: [randomRef, utilsMixin, messageMixin],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'settings.ticket_category',
    };
  },
  data() {
    return {
      loading: true,
      list: [],
      poles: [],
      item: {
        label: '',
      },
      subCategory: {},
    };
  },
  validations() {
    return {
      item: {
        label: {required},
        poleIds: {required},
      },
      subCategory: {
        label: {required},
      },
    };
  },
  mounted() {
    this.initTicketCategoryList();
  },
  methods: {
    initTicketCategoryList() {
      this.loading = true;

      let p1 = ticketCategoryService.ticketCategorys();
      p1.then((data) => {
        this.list = data;
      });

      let p2 = poleService.poles();
      p2.then((data) => {
        this.poles = data;
      });

      Promise.all([p1, p2]).then(() => {
        this.loading = false;
      });
    },
    create() {
      this.v$.$touch();
      if (this.v$.item.$error) return;

      this.getRef().showTotalLoader();
      ticketCategoryService
        .create(this.item)
        .then((data) => {
          this.getRef().success(this.$t('settings.ticket_category_added'));
          this.list.push(data);
        })
        .catch((e) => {
          if (e.response.status === 412) {
            this.addError(this.$t('settings.error_empty_field'));
          } else {
            this.addError(this.$t('unknow_error'));
          }
        })
        .finally(() => {
          this.getRef().hideLoader();
          this.$refs.dialogCreate.hide();
          this.initTicketCategoryList();
        });
    },
    createSubCategory() {
      this.v$.$touch();
      if (this.v$.subCategory.$error) return;

      this.getRef().showTotalLoader();
      ticketSubCategoryService
        .create(this.subCategory)
        .then((data) => {
          this.getRef().success(this.$t('sub_category.added'));
        })
        .catch((e) => {
          if (e.response.status === 412) {
            this.addError(this.$t('settings.error_empty_field'));
          } else {
            this.addError(this.$t('unknow_error'));
          }
        })
        .finally(() => {
          this.getRef().hideLoader();
          this.$refs.dialogSubCategoryCreate.hide();
          this.initTicketCategoryList();
        });
    },
    update() {
      this.v$.$touch();
      if (this.v$.item.$error) return;

      this.getRef().showTotalLoader();
      ticketCategoryService
        .update(this.item)
        .then((data) => {
          this.getRef().success(this.$t('settings.ticket_category_updated'));
        })
        .finally(() => {
          this.getRef().hideLoader();
          this.$refs.dialogEdit.hide();
          this.initTicketCategoryList();
        });
    },
    updateSubCategory() {
      this.v$.$touch();
      if (this.v$.subCategory.$error) return;

      this.getRef().showTotalLoader();
      ticketSubCategoryService
        .update(this.subCategory)
        .then(() => {
          this.getRef().success(this.$t('sub_category.updated'));
        })
        .finally(() => {
          this.getRef().hideLoader();
          this.$refs.dialogSubCategoryEdit.hide();
          this.initTicketCategoryList();
        });
    },
    delete() {
      ticketCategoryService
        .delete(this.item)
        .then((data) => {
          this.getRef().success(this.item.label + ' a bien été supprimé');
        })
        .catch((e) => {
          let content = 'Une erreur est survenue durant la suppression';
          if (e.response && e.response.status === 406) {
            content =
              'Une erreur est survenue durant la suppression : la catégorie est associée à un ou plusieurs tickets.';
          } else if (e.response) {
            content = 'Une erreur est survenue durant la suppression : ' + e.response.data.error;
            this.addError(content);
          }
          this.addError(content);
        })
        .finally(() => {
          this.initTicketCategoryList();
        });
    },
    deleteSubCategory() {
      ticketSubCategoryService
        .delete(this.subCategory)
        .then((data) => {
          this.getRef().success(this.subCategory.label + ' a bien été supprimé');
        })
        .catch((e) => {
          let content = 'Une erreur est survenue durant la suppression';
          if (e.response && e.response.status === 406) {
            content =
              'Une erreur est survenue durant la suppression : la sous-categorie est associée à un ou plusieurs tickets.';
          } else if (e.response) {
            content = 'Une erreur est survenue durant la suppression : ' + e.response.data.error;
          }
          this.addError(content);
        })
        .finally(() => {
          this.initTicketCategoryList();
        });
    },
    openCreateDialog() {
      this.item = {
        label: '',
        poleIds: null,
      };
      this.$refs.dialogCreate.show();
    },
    openCreateSubCategoryDialog(data) {
      this.subCategory = {
        ticketCategoryId: data.id,
      };
      this.$refs.dialogSubCategoryCreate.show();
    },
    openDeleteSubCategoryDialog(data) {
      this.subCategory = data;
      this.$refs.dialogDeleteSubCategory.show();
    },
    openEditDialog(item) {
      this.item = {...item};
      this.$refs.dialogEdit.show();
    },
    openDeleteDialog(item) {
      this.item = item;
      this.$refs.dialogDelete.show();
    },
    openSubCategoryEditDialog(subCategory) {
      this.subCategory = {...subCategory};
      this.$refs.dialogSubCategoryEdit.show();
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :title="$t('settings.ticket_category')">
    <template #action>
      <div class="d-flex justify-content-end">
        <button
          :title="this.$t('settings.add_ticket_category')"
          class="btn btn-primary float-right"
          @click="openCreateDialog()">
          <i class="gst-plus pr-1" />
          {{ $t('add') }}
        </button>
      </div>
    </template>

    <template #content>
      <DataTable
        :always-show-paginator="false"
        :loading="loading"
        :paginator="true"
        :rows="10"
        :rows-per-page-options="[10, 20, 50]"
        :value="list"
        class="table"
        current-page-report-template="{first}-{last}/{totalRecords}"
        removable-sort
        responsive-layout="scroll"
        sort-field="position"
        striped-rows
        v-model:expandedRows="list.subCategories">
        <template #empty>
          {{ $t('settings.ticket_category_empty') }}
        </template>
        <Column expander></Column>
        <Column :header="$t('item_label.label')" field="label">
          <template #body="slotProps">
            <span>{{ slotProps.data.label }}</span>
          </template>
        </Column>
        <Column :header="$t('pole.list')" field="poleDisplay">
          <template #body="slotProps">
            <span>{{ slotProps.data.poleDisplay }}</span>
          </template>
        </Column>
        <Column body-style="text-align: right; overflow: visible" header-style=" width: 160px; text-align: btn">
          <template #body="slotProps">
            <div class="d-flex justify-content-end">
              <span
                class="btn btn-xs btn-inverse-primary mr-1 pointer"
                @click="openCreateSubCategoryDialog(slotProps.data)"
                :title="this.$t('sub_category.create')">
                <i class="gst-plus" />
              </span>
              <span
                class="btn btn-xs btn-inverse-primary mr-1 pointer"
                @click="openEditDialog(slotProps.data)"
                :title="this.$t('settings.update_ticket_category')">
                <i class="fe fe-edit" />
              </span>
              <span
                class="btn btn-xs btn-inverse-danger pointer"
                @click="openDeleteDialog(slotProps.data)"
                :title="this.$t('settings.delete_ticket_category')">
                <i class="fe fe-trash" />
              </span>
            </div>
          </template>
        </Column>
        <template #expansion="slotProps">
          <div v-for="(subcategory, index) in slotProps.data.subCategories" :key="subcategory.id" style="width: 100%">
            <div style="width: 50%; float: left">
              <span>
                {{ subcategory.label }}
              </span>
            </div>
            <div class="d-flex justify-content-end">
              <span
                class="btn btn-xs btn-inverse-primary mr-1 pointer"
                @click="openSubCategoryEditDialog(subcategory)"
                :title="this.$t('sub_category.update_ticket_category')">
                <i class="fe fe-edit" />
              </span>
              <span
                class="btn btn-xs btn-inverse-danger pointer"
                @click="openDeleteSubCategoryDialog(subcategory)"
                :title="this.$t('sub_category.delete_sub_category')">
                <i class="fe fe-trash" />
              </span>
            </div>
            <hr v-if="index !== slotProps.data.subCategories.length - 1" />
          </div>
        </template>
      </DataTable>
    </template>

    <template #extra>
      <AkDialog
        ref="dialogCreate"
        :title="$t('settings.ticket_category')"
        :validate-label="$t('add')"
        width="450px"
        @validate="create()">
        <div class="form-row">
          <AkInputText
            v-model="item.label"
            :label="$t('item_label.label')"
            :validator="v$.item.label"
            class-name="col-md-12" />
          <AkMultiSelect
            v-model="item.poleIds"
            :label="$t('pole.list')"
            :validator="v$.item.poleIds"
            :options="this.poles"
            option-label="name"
            option-value="id"
            class-name="col-md-12" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogEdit"
        :title="$t('settings.ticket_category')"
        :validate-label="$t('update')"
        width="450px"
        @validate="update()">
        <div class="form-row">
          <AkInputText
            v-model="item.label"
            :label="$t('item_label.label')"
            :validator="v$.item.label"
            class-name="col-md-12" />
          <AkMultiSelect
            v-model="item.poleIds"
            :label="$t('pole.list')"
            :options="this.poles"
            option-label="name"
            option-value="id"
            class-name="col-md-12" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogDelete"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.delete()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span>{{ $t('settings.confirm_delete_ticket_category') }}</span>
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogSubCategoryCreate"
        :title="$t('sub_category.sub_category')"
        :validate-label="$t('add')"
        width="450px"
        @validate="createSubCategory()">
        <div class="form-row">
          <AkInputText
            v-model="subCategory.label"
            :label="$t('item_label.label')"
            :validator="v$.subCategory.label"
            class-name="col-md-12" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogSubCategoryEdit"
        :title="$t('sub_category.sub_category')"
        :validate-label="$t('update')"
        width="450px"
        @validate="updateSubCategory()">
        <div class="form-row">
          <AkInputText
            v-model="subCategory.label"
            :label="$t('item_label.label')"
            :validator="v$.subCategory.label"
            class-name="col-md-12" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogDeleteSubCategory"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.deleteSubCategory()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span>{{ $t('sub_category.confirm_delete_sub_category') }}</span>
        </div>
      </AkDialog>
    </template>
  </GaView>
</template>
