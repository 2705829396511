<script>
import {Icon} from '@iconify/vue/dist/iconify';

/* COMPOSANTS */
import Checkbox from 'primevue/checkbox';
import PrestationBadge from '@/components/general/PrestationBadge';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import prestationStatusFilterConst from '@mixins/const/prestationStatusConst';
import prestationTypesConst from '@mixins/const/prestationTypesConst';
import sidebarMixin from '@mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import companyService from '@services/companyService';
import AkAccommodationAutocomplete from '@components/general/AkAccommodationAutocomplete.vue';
import AkDropdown from '@components/input/AkDropdown.vue';

export default {
  components: {
    AkDropdown,
    AkAccommodationAutocomplete,
    Icon,
    Checkbox,
    PrestationBadge,
  },
  mixins: [permissionsMixin, prestationStatusFilterConst, prestationTypesConst, sidebarMixin, utilsMixin],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      companies: undefined,
      companyId: undefined,
    };
  },
  mounted() {
    if (this.isGav()) this.companyId = this.myCompanyId();
    this.initCompanies();
  },
  methods: {
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.accommodationAutocomplete) this.$refs.accommodationAutocomplete.reset();
      this.callback[1]();
    },
    initCompanies() {
      companyService
        .companies()
        .then((data) => {
          this.companies = data;
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        });
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    loadingCompanies() {
      return this.companies === undefined;
    },
    prestationTypesList() {
      return this.prestationTypesConst.slice(0, -2);
    },
    prestationStatusList() {
      return this.prestationStatusFilterConst.slice(0, -1);
    },
  },
};
</script>

<template>
  <h2>Filtrer</h2>
  <form class="d-flex flex-column" style="row-gap: 16px" @submit.prevent="search">
    <!-- Accommodation -->
    <AkAccommodationAutocomplete
      ref="accommodationAutocomplete"
      v-model="currentValue.accommodationId"
      :placeholder="$t('accommodation_title')"
      class-name="flex-1 form-control"
      inline />
    <!-- Agence -->
    <AkDropdown
      v-if="this.isGas() && this.companies && !this.currentValue.isFromAccommodationPage"
      v-model="currentValue.companyId"
      :label="$t('employee.company')"
      :loading="this.loadingCompanies"
      :options="companies"
      class-name="flex-1 form-control"
      inline
      option-label="name"
      option-value="id" />
    <!-- Types de mission : à améliorer -->
    <div>
      <h6>{{ $t('planning_intervenant.mission_type') }}</h6>
      <div>
        <div
          v-for="prestationType in prestationTypesList"
          :key="prestationType.value"
          class="d-flex align-items-center mb-2">
          <Checkbox
            v-model="currentValue.types"
            :inputId="prestationType.value"
            :value="prestationType.value"
            class="col-2"
            name="prestationType" />
          <PrestationBadge :type="prestationType.value" class="col-4" />
        </div>
      </div>
    </div>
    <!-- Recherche -->
    <div class="d-flex flex-row">
      <button class="btn btn-primary btn-search flex-1" type="submit">
        {{ this.$t('visitor_tax.filter') }}
      </button>
      <Icon
        :style="{fontSize: '50px', width: '50px !important'}"
        class="pointer"
        icon="basil:cross-outline"
        v-on:click="this.reset()" />
    </div>
  </form>
</template>
