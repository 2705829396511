<script>
import {Icon} from '@iconify/vue/dist/iconify';

/* COMPOSANTS */
import AkMandatAutocomplete from '@components/general/AkMandatAutocomplete';
import AkOwnerAutocomplete from '@components/general/AkOwnerAutocomplete';
import AkInputSwitch from '@components/input/AkInputSwitch';
import AkCalendar from '@components/input/AkCalendar';
import MultiSelect from 'primevue/multiselect';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';

/* SERVICES */
import companyService from '@services/companyService';

export default {
  components: {
    AkMandatAutocomplete,
    AkInputSwitch,
    MultiSelect,
    AkOwnerAutocomplete,
    AkCalendar,
    Icon,
  },
  mixins: [utilsMixin, sidebarMixin],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      companies: undefined,
    };
  },
  mounted() {
    companyService.companies().then((data) => {
      this.companies = data;
    });
  },
  methods: {
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.ownerAutocomplete) this.$refs.ownerAutocomplete.reset();
      if (this.$refs.mandatAutocomplete) this.$refs.mandatAutocomplete.reset();
      this.callback[1]();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    loadingCompanies() {
      return this.companies === undefined;
    },
  },
};
</script>

<template>
  <h2>Filtrer</h2>
  <form class="d-flex flex-column" style="row-gap: 16px" @submit.prevent="search">
    <MultiSelect
      v-model="currentValue.companyIds"
      :placeholder="$t('employee.company')"
      :options="this.companies"
      :loading="this.loadingCompanies"
      option-label="name"
      option-value="id"
      class="flex-1 form-control"
      :display-label="false" />

    <AkMandatAutocomplete
      ref="mandatAutocomplete"
      v-model="currentValue.mandatId"
      :placeholder="$t('mandat.num')"
      :inline="true"
      class-name="flex-1"
      option-label="num"
      option-value="id" />

    <AkOwnerAutocomplete
      ref="ownerAutocomplete"
      v-model="currentValue.ownerId"
      :ownerStatus="'CUSTOMER'"
      :placeholder="$t('owner.ownerDisplay')"
      :inline="true"
      class-name="flex-1" />

    <AkInputSwitch
      v-model="currentValue.showNegative"
      :label="$t('account.showNegative')"
      :inline="true"
      :labelBefore="false"
      class-name="flex-1" />

    <AkInputSwitch
      v-model="currentValue.showPositive"
      :label="$t('account.showPositive')"
      :inline="true"
      :labelBefore="false"
      class-name="flex-1" />

    <AkInputSwitch
      v-model="currentValue.showZero"
      :label="$t('account.showZero')"
      :inline="true"
      :labelBefore="false"
      class-name="flex-1" />

    <AkInputSwitch
      v-model="currentValue.showResiliated"
      :label="$t('account.mandat_resiliated')"
      :inline="true"
      :labelBefore="false"
      class-name="flex-1" />

    <AkCalendar
      v-model="currentValue.dateEnd"
      :label="$t('date_end')"
      class-name="form-control flex-1"
      date-format="dd/mm/yy"
      :inline="true"
      selection-mode="single" />

    <div class="d-flex flex-row">
      <button class="btn btn-primary btn-search flex-1" type="submit">
        {{ this.$t('visitor_tax.filter') }}
      </button>
      <Icon
        v-on:click="this.reset()"
        class="pointer"
        icon="basil:cross-outline"
        :style="{fontSize: '50px', width: '50px !important'}" />
    </div>
  </form>
</template>
