<script>
/* COMPOSANTS */
import AkCalendar from '@components/input/AkCalendar.vue';
import AkOwnerAutocomplete from '@components/general/AkOwnerAutocomplete.vue';
import AkAccommodationAutocomplete from '@components/general/AkAccommodationAutocomplete.vue';
import AkDropdown from '@components/input/AkDropdown.vue';

/* MIXINS */
import ownerChargeTypeConst from '@mixins/const/ownerChargeTypeConst';
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import {Icon} from '@iconify/vue/dist/iconify';

/* SERVICES */
import accommodationService from '@services/accommodationService';
import AkMultiSelect from '@components/input/AkMultiSelect.vue';
import companyService from '@services/companyService';

export default {
  components: {
    AkMultiSelect,
    AkDropdown,
    AkCalendar,
    AkOwnerAutocomplete,
    AkAccommodationAutocomplete,
    Icon,
  },
  mixins: [permissionsMixin, utilsMixin, sidebarMixin, ownerChargeTypeConst],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      ownerAccommodations: [],
      companys: [],
    };
  },
  mounted() {
    if (this.isOwnerSelected) this.getAccommodationList();
    companyService.companies().then((data) => {
      this.companys = data;
    });
  },
  methods: {
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.ownerAutocomplete) this.$refs.ownerAutocomplete.reset();
      if (this.$refs.accommodationAutocomplete) this.$refs.accommodationAutocomplete.reset();
      this.callback[1]();
    },
    getAccommodationList() {
      if (!this.isOwnerSelected) {
        this.ownerAccommodations = [];
        this.currentValue.accommodationId = null;
        return;
      }
      accommodationService
        .accommodations({ownerId: this.currentValue.ownerId})
        .then((data) => {
          this.ownerAccommodations = data;
        })
        .catch((error) => {
          this.addError(this.$t('ticket.error'));
        });
    },
  },
  watch: {
    'currentValue.ownerId'(newVal, oldVal) {
      if (this.currentValue.ownerId) {
        this.getAccommodationList();
      }
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    isOwnerSelected() {
      return !!((this.currentValue.ownerId !== null) & (this.currentValue.ownerId !== undefined));
    },
  },
};
</script>

<template>
  <h2>{{ this.$t('facturation_hote.filter') }}</h2>
  <form class="d-flex flex-column" style="row-gap: 16px" @submit.prevent="search">
    <AkOwnerAutocomplete
      ref="ownerAutocomplete"
      v-model="currentValue.ownerId"
      :inline="true"
      className="flex-1 form-control"
      :placeholder="$t('owner.ownerDisplay')" />
    <AkAccommodationAutocomplete
      v-if="!isOwnerSelected"
      ref="accommodationAutocomplete"
      v-model="currentValue.accommodationId"
      className="flex-1 form-control"
      :inline="true"
      :placeholder="$t('accommodation.name')" />
    <AkDropdown
      v-if="isOwnerSelected"
      v-model="currentValue.accommodationId"
      :inline="true"
      className="flex-1 form-control"
      :options="ownerAccommodations"
      optionLabel="name"
      optionValue="id"
      :placeholder="$t('accommodation.name')"
      :label="$t('accommodation.name')" />
    <AkCalendar
      v-model="currentValue.date"
      className="flex-1 form-control"
      :inline="true"
      :placeholder="$t('operation.date')"
      date-format="dd/mm/yy"
      selection-mode="single" />
    <AkDropdown
      v-model="currentValue.type"
      :inline="true"
      className="flex-1 form-control"
      :options="ownerChargeTypeConst"
      optionLabel="label"
      optionValue="value"
      :placeholder="$t('facturation_hote.type')"
      :label="$t('facturation_hote.type')" />
    <AkMultiSelect
      v-model="currentValue.companyIds"
      :displayLabel="false"
      :label="$t('company.list')"
      :options="companys"
      option-label="name"
      option-value="id"
      :inline="true"
      className="" />
    <div class="d-flex flex-row">
      <button class="btn btn-primary btn-search flex-1" type="submit">
        {{ this.$t('visitor_tax.filter') }}
      </button>
      <Icon
        v-on:click="this.reset()"
        class="pointer"
        icon="basil:cross-outline"
        :style="{fontSize: '50px', width: '50px !important'}" />
    </div>
  </form>
</template>
