<script>
import {required} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

/* COMPOSANTS */
import AkInputText from '@components/input/AkInputText';
import AkLabel from '@components/general/AkLabel';
import PButton from 'primevue/button';
/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import taskService from '@services/taskService';
import taskgroupService from '@services/taskgroupService';
import {FilterMatchMode} from 'primevue/api';
import taskTypeConst from '@mixins/const/taskTypeConst';
import AkDropdown from '@components/input/AkDropdown';
import AkChips from '@components/input/AkChips';
import GaView from '@components/layout/GaView';
import messageMixin from '@mixins/messageMixin';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';

export default {
  components: {GaView, AkChips, AkDropdown, AkLabel, AkInputText, PButton, MultiSelect, InputText},
  mixins: [randomRef, utilsMixin, messageMixin, taskTypeConst],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'settings.tasks',
    };
  },
  data() {
    return {
      filter: undefined,
      filterGroup: undefined,
      tasks: [],
      taskgroups: [],
      task: {
        typeData: {},
      },
      taskgroup: {},
      selected: [],

      displayCreateTaskDialog: false,
      displayEditTaskDialog: false,
      displayDeleteTaskDialog: false,

      displayCreateTaskgroupDialog: false,
      displayEditTaskgroupDialog: false,
      displayDeleteTaskgroupDialog: false,

      submitted: false,
      loading: true,
      loadingGroup: true,
    };
  },
  validations() {
    return {
      task: {
        label: {required},
        type: {required},
      },
      taskgroup: {
        label: {required},
      },
    };
  },
  created() {
    this.initFilters();
    this.initFiltersGroup();
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      let p1 = taskService.tasks();
      p1.then((data) => {
        this.tasks = data;
        this.loading = false;
      });

      let p2 = taskgroupService.taskgroups();
      p2.then((data) => {
        this.taskgroups = data;
        this.loadingGroup = false;
      });
    },
    createTask() {
      this.submitted = true;
      this.v$.task.$touch();
      if (this.v$.task.$error) return;
      this.getRef().showTotalLoader();
      taskService.create(this.task).then(() => {
        this.refresh();
        this.task = {};
        this.selected = [];
        this.displayCreateTaskDialog = false;
        this.getRef().hideLoader();
        this.getRef().success(this.$t('task.added'));
        this.submitted = false;
      });
    },
    createTaskgroup() {
      this.submitted = true;
      this.v$.taskgroup.$touch();
      if (this.v$.taskgroup.$error) return;
      this.getRef().showTotalLoader();
      taskgroupService.create(this.taskgroup).then(() => {
        this.refresh();
        this.taskgroup = {};
        this.selected = [];
        this.displayCreateTaskgroupDialog = false;
        this.getRef().hideLoader();
        this.success(this.$t('taskgroup.added'));
        this.submitted = false;
      });
    },
    updateTask() {
      this.submitted = true;
      this.v$.task.$touch();
      if (this.v$.task.$error) return;
      this.getRef().showTotalLoader();
      taskService.update(this.task).then((data) => {
        this.refresh();
        this.task = {};
        this.selected = [];
        this.displayEditTaskDialog = false;
        this.getRef().hideLoader();
        this.success(this.$t('task.updated'));
        this.submitted = false;
      });
    },
    updateTaskgroup() {
      this.submitted = true;
      this.v$.taskgroup.$touch();
      if (this.v$.taskgroup.$error) return;

      this.getRef().showTotalLoader();
      taskgroupService.update(this.taskgroup).then(() => {
        this.refresh();
        this.taskgroup = {};
        this.selected = [];
        this.displayEditTaskgroupDialog = false;
        this.getRef().hideLoader();
        this.success(this.$t('taskgroup.updated'));
        this.submitted = false;
      });
    },
    deleteTask() {
      this.getRef().showTotalLoader();
      taskService
        .delete(this.task)
        .then(() => {
          this.refresh();
          this.getRef().hideLoader();
          this.displayDeleteTaskDialog = false;
          this.success('La tâche a bien été supprimée');
        })
        .catch((e) => {
          if (e.response.status === 406) {
            this.addError('La tâche est liée à un modéle de cahier des charges, et ne peut être supprimée');
          } else {
            this.addError(this.$t('unknow_error'));
          }
          this.getRef().hideLoader();
          this.displayDeleteTaskDialog = false;
        });
    },
    deleteTaskgroup() {
      this.getRef().showTotalLoader();
      taskgroupService
        .delete(this.taskgroup)
        .then(() => {
          this.refresh();
          this.getRef().hideLoader();
          this.displayDeleteTaskgroupDialog = false;
          this.success('Le groupe de tâche a bien été supprimé');
        })
        .catch((e) => {
          if (e.response.status === 406) {
            this.addError('Le groupe de tâche est lié à un modéle de cahier des charges, et ne peut être supprimé');
          } else {
            this.addError(this.$t('unknow_error'));
          }
          this.getRef().hideLoader();
          this.displayDeleteTaskDialog = false;
        });
    },
    openCreateTaskDialog() {
      this.task = {
        typeData: {},
      };
      this.selected = [];
      this.displayCreateTaskDialog = true;
    },
    openCreateTaskgroupDialog() {
      this.taskgroup = {};
      this.selected = [];
      this.displayCreateTaskgroupDialog = true;
    },
    openEditTaskDialog(task) {
      this.task = {...task};
      this.displayEditTaskDialog = true;
    },
    openEditTaskgroupDialog(taskgroup) {
      this.taskgroup = {...taskgroup};
      this.displayEditTaskgroupDialog = true;
    },
    openDeleteTaskDialog(task) {
      this.task = task;
      this.displayDeleteTaskDialog = true;
    },
    openDeleteTaskgroupDialog(taskgroup) {
      this.taskgroup = taskgroup;
      this.displayDeleteTaskgroupDialog = true;
    },
    clearFilter() {
      this.initFilters();
    },
    initFilters() {
      this.filter = {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
      };
    },
    clearFilterGroup() {
      this.initFiltersGroup();
    },
    initFiltersGroup() {
      this.filterGroup = {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
      };
    },
  },
  computed: {
    isRating() {
      return this.task.type === 'RATING';
    },
    hasOptions() {
      return this.task.type === 'SIMPLE_OPTION' || this.task.type === 'MULTI_OPTION';
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :submitted="submitted" :title="$t('settings.tasks')">
    <template #content>
      <div class="row">
        <div class="col-md-6">
          <div class="row title-sublist">
            <div class="col-6 col-md-4">
              <h6>{{ $t('settings.tasks') }}</h6>
            </div>
            <div class="col-6 col-md-8 d-flex justify-content-end">
              <button class="btn btn-xs btn-inverse-primary" @click="openCreateTaskDialog()">
                <i class="gst-plus pr-1" />{{ $t('task.add') }}
              </button>
            </div>
          </div>

          <DataTable
            v-model:filters="filter"
            :always-show-paginator="false"
            :global-filter-fields="['label']"
            :loading="loading"
            :paginator="true"
            :rows="10"
            :rows-per-page-options="[10, 20, 50]"
            :value="tasks"
            class="table"
            current-page-report-template="{first}-{last}/{totalRecords}"
            filter-display="menu"
            removable-sort
            responsive-layout="scroll"
            sort-field="position"
            striped-rows>
            <template #header>
              <div class="d-flex justify-content-between">
                <Button
                  class="p-button-outlined"
                  icon="pi pi-filter-slash"
                  label="Effacer"
                  type="button"
                  @click="clearFilter()" />
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filter['global'].value" placeholder="Recherche" />
                </span>
              </div>
            </template>
            <template #empty>
              {{ $t('task.empty') }}
            </template>
            <Column :header="$t('task.item')">
              <template #body="slotProps">
                <router-link :to="{name: 'taskDetails', params: {taskId: slotProps.data.id}}">
                  {{ slotProps.data.label }}
                </router-link>
              </template>
            </Column>
            <Column :header="$t('task.type')">
              <template #body="slotProps">
                <router-link :to="{name: 'taskDetails', params: {taskId: slotProps.data.id}}">
                  {{ displayForTaskTypeConst(slotProps.data.type) }}
                </router-link>
              </template>
            </Column>
            <Column :header="$t('task.groupsNb')">
              <template #body="slotProps">
                {{ slotProps.data.groupIds ? slotProps.data.groupIds.length : 0 }}
              </template>
            </Column>

            <Column body-style="text-align: right; overflow: visible" header-style=" width: 160px; text-align: btn">
              <template #body="slotProps">
                <div class="d-flex justify-content-end">
                  <span class="btn btn-xs btn-inverse-primary mr-1 pointer" @click="openEditTaskDialog(slotProps.data)">
                    <i class="fe fe-edit" />
                  </span>
                  <span class="btn btn-xs btn-inverse-danger pointer" @click="openDeleteTaskDialog(slotProps.data)">
                    <i class="fe fe-trash"
                  /></span>
                </div>
              </template>
            </Column>
          </DataTable>
        </div>

        <div class="col-md-6">
          <div class="row title-sublist">
            <div class="col-6 col-md-4">
              <h6>{{ $t('taskgroup.item') }}</h6>
            </div>
            <div class="col-6 col-md-8 d-flex justify-content-end">
              <button class="btn btn-xs btn-inverse-primary" @click="openCreateTaskgroupDialog()">
                <i class="gst-plus pr-1" />{{ $t('taskgroup.add') }}
              </button>
            </div>
          </div>
          <DataTable
            v-model:filters="filterGroup"
            :always-show-paginator="false"
            :global-filter-fields="['label']"
            :loading="loadingGroup"
            :paginator="true"
            :rows="10"
            :rows-per-page-options="[10, 20, 50]"
            :value="taskgroups"
            class="table"
            current-page-report-template="{first}-{last}/{totalRecords}"
            filter-display="menu"
            removable-sort
            responsive-layout="scroll"
            sort-field="position"
            striped-rows>
            <template #header>
              <div class="d-flex justify-content-between">
                <PButton
                  class="p-button-outlined"
                  icon="pi pi-filter-slash"
                  label="Effacer"
                  type="button"
                  @click="clearFilterGroup()" />
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filterGroup['global'].value" placeholder="Recherche" />
                </span>
              </div>
            </template>
            <template #empty>
              {{ $t('taskgroup.empty') }}
            </template>
            <Column :header="$t('taskgroup.item')">
              <template #body="slotProps">
                <router-link :to="{name: 'taskgroupDetails', params: {taskgroupId: slotProps.data.id}}">
                  {{ slotProps.data.label }}
                </router-link>
              </template>
            </Column>
            <Column :header="$t('taskgroup.tasksNb')">
              <template #body="slotProps">
                {{ slotProps.data.taskIds ? slotProps.data.taskIds.length : 0 }}
              </template>
            </Column>
            <Column body-style="text-align: right; overflow: visible" header-style=" width: 160px; text-align: btn">
              <template #body="slotProps">
                <div class="d-flex justify-content-end">
                  <span
                    class="btn btn-xs btn-inverse-primary mr-1 pointer"
                    @click="openEditTaskgroupDialog(slotProps.data)">
                    <i class="fe fe-edit" />
                  </span>
                  <span
                    class="btn btn-xs btn-inverse-danger pointer"
                    @click="openDeleteTaskgroupDialog(slotProps.data)">
                    <i class="fe fe-trash"
                  /></span>
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </template>

    <template #extra>
      <PDialog
        v-model:visible="displayCreateTaskDialog"
        :header="$t('task.add')"
        :modal="true"
        :style="{width: '450px'}"
        @hide="displayCreateTaskDialog = false">
        <div class="form-row">
          <AkInputText
            v-model="task.label"
            :label="$t('task.label')"
            :submitted="submitted"
            :validator="v$.task.label"
            class-name="col-12" />
        </div>
        <div class="form-row">
          <AkDropdown
            v-model="task.type"
            :label="$t('task.type')"
            :options="taskTypeConst"
            :submitted="this.submitted"
            :validator="v$.task.type"
            class-name="col-12"
            option-label="label"
            option-value="value" />
        </div>

        <div v-if="isRating" class="form-row">
          <AkInputText v-model="task.typeData.rating" class-name="col-12" label="Echelle (consigne)" />
        </div>
        <div v-if="hasOptions" class="form-row">
          <AkChips v-model="task.typeData.options" class-name="col-12" label="Choix" />
        </div>

        <div v-if="taskgroups.length > 0" class="form-row">
          <div class="col-12">
            <AkLabel>{{ $t('task.groups') }}</AkLabel>
            <MultiSelect
              v-model="task.groupIds"
              :filter="true"
              :options="taskgroups"
              :placeholder="$t('task.selectGroups')"
              display="chip"
              option-label="label"
              option-value="id" />
          </div>
        </div>
        <template #footer>
          <div class="d-flex justify-content-end">
            <span class="btn btn-outline-secondary btn-xs mr-1" @click="displayCreateTaskDialog = false">
              {{ $t('cancel') }}
            </span>
            <span class="btn btn-primary btn-xs" @click="createTask()">{{ $t('add') }}</span>
          </div>
        </template>
      </PDialog>

      <PDialog
        v-model:visible="displayCreateTaskgroupDialog"
        :header="$t('taskgroup.add')"
        :modal="true"
        :style="{width: '450px'}"
        @hide="displayCreateTaskgroupDialog = false">
        <div class="form-row">
          <AkInputText
            v-model="taskgroup.label"
            :label="$t('taskgroup.label')"
            :submitted="submitted"
            :validator="v$.taskgroup.label"
            class-name="col-12" />
        </div>
        <div v-if="tasks.length > 0" class="form-row">
          <div class="col-md-12">
            <AkLabel>{{ $t('taskgroup.tasks') }}</AkLabel>
            <MultiSelect
              v-model="taskgroup.taskIds"
              :filter="true"
              :options="tasks"
              :placeholder="$t('taskgroup.selectTasks')"
              display="chip"
              option-label="label"
              option-value="id" />
          </div>
        </div>
        <template #footer>
          <div class="d-flex justify-content-end">
            <span class="btn btn-outline-secondary btn-xs mr-1" @click="displayCreateTaskgroupDialog = false">
              {{ $t('cancel') }}
            </span>
            <span class="btn btn-primary btn-xs" @click="createTaskgroup()">{{ $t('add') }}</span>
          </div>
        </template>
      </PDialog>

      <PDialog
        v-model:visible="displayEditTaskDialog"
        :header="$t('task.update')"
        :modal="true"
        :style="{width: '450px'}"
        @hide="displayEditTaskDialog = false">
        <div class="form-row">
          <AkInputText
            v-model="task.label"
            :label="$t('task.label')"
            :submitted="submitted"
            :validator="v$.task.label"
            class-name="col-12" />
        </div>
        <div class="form-row">
          <AkDropdown
            v-model="task.type"
            :label="$t('task.type')"
            :options="taskTypeConst"
            :submitted="this.submitted"
            :validator="v$.task.type"
            class-name="col-12"
            option-label="label"
            option-value="value" />
        </div>

        <div v-if="isRating" class="form-row">
          <AkInputText v-model="task.typeData.rating" class-name="col-12" label="Echelle (consigne)" />
        </div>
        <div v-if="hasOptions" class="form-row">
          <AkChips v-model="task.typeData.options" class-name="col-12" label="Choix" />
        </div>

        <div v-if="taskgroups.length > 0" class="form-row">
          <div class="col-12">
            <AkLabel>{{ $t('task.groups') }}</AkLabel>
            <MultiSelect
              v-model="task.groupIds"
              :filter="true"
              :options="taskgroups"
              :placeholder="$t('task.selectGroups')"
              display="chip"
              option-label="label"
              option-value="id" />
          </div>
        </div>
        <template #footer>
          <div class="d-flex justify-content-end">
            <span class="btn btn-outline-secondary btn-xs mr-1" @click="displayEditTaskDialog = false">
              {{ $t('cancel') }}
            </span>
            <span class="btn btn-primary btn-xs" @click="updateTask()">{{ $t('update') }}</span>
          </div>
        </template>
      </PDialog>

      <PDialog
        v-model:visible="displayEditTaskgroupDialog"
        :header="$t('taskgroup.update')"
        :modal="true"
        :style="{width: '450px'}"
        @hide="displayEditTaskgroupDialog = false">
        <div class="form-row">
          <AkInputText
            v-model="taskgroup.label"
            :label="$t('taskgroup.label')"
            :submitted="submitted"
            :validator="v$.taskgroup.label"
            class-name="col-12" />
        </div>
        <div v-if="tasks.length > 0" class="form-row">
          <div class="col-md-12">
            <AkLabel>{{ $t('taskgroup.tasks') }}</AkLabel>
            <MultiSelect
              v-model="taskgroup.taskIds"
              :filter="true"
              :options="tasks"
              :placeholder="$t('taskgroup.selectTasks')"
              display="chip"
              option-label="label"
              option-value="id" />
          </div>
        </div>
        <template #footer>
          <div class="d-flex justify-content-end">
            <span class="btn btn-outline-secondary btn-xs mr-1" @click="displayEditTaskgroupDialog = false">
              {{ $t('cancel') }}
            </span>
            <span class="btn btn-primary btn-xs" @click="updateTaskgroup()">{{ $t('update') }}</span>
          </div>
        </template>
      </PDialog>

      <PDialog
        v-model:visible="displayDeleteTaskDialog"
        :header="$t('delete_dialog')"
        :modal="true"
        :style="{width: '450px'}"
        @hide="displayDeleteTaskDialog = false">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span v-if="task">{{ $t('task.confirm_delete') }}</span>
        </div>
        <template #footer>
          <div class="d-flex justify-content-end">
            <span class="btn btn-outline-primary btn-xs mr-1" @click="displayDeleteTaskDialog = false">{{
              $t('no')
            }}</span>
            <span class="btn btn-danger btn-xs" @click="deleteTask()">{{ $t('yes') }}</span>
          </div>
        </template>
      </PDialog>

      <PDialog
        v-model:visible="displayDeleteTaskgroupDialog"
        :header="$t('delete_dialog')"
        :modal="true"
        :style="{width: '450px'}"
        @hide="displayDeleteDialog = false">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span v-if="taskgroup">{{ $t('taskgroup.confirm_delete') }}</span>
        </div>
        <template #footer>
          <div class="d-flex justify-content-end">
            <span class="btn btn-outline-primary btn-xs mr-1" @click="displayDeleteTaskgroupDialog = false">{{
              $t('no')
            }}</span>
            <span class="btn btn-danger btn-xs" @click="deleteTaskgroup()">{{ $t('yes') }}</span>
          </div>
        </template>
      </PDialog>
    </template>
  </GaView>
</template>
