<script>
import {Icon} from '@iconify/vue/dist/iconify';

/* COMPOSANTS */
import AkInputText from '@components/input/AkInputText.vue';
import AkInputSwitch from '@components/input/AkInputSwitch.vue';
import AkAccommodationAutocomplete from '@components/general/AkAccommodationAutocomplete.vue';
import AkOwnerAutocomplete from '@components/general/AkOwnerAutocomplete.vue';
import MultiSelect from 'primevue/multiselect';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import ticketPriorityConst from '@mixins/const/ticketPriorityConst';
import ticketStatusConst from '@mixins/const/ticketStatusConst';

/* SERVICES */
import poleService from '@services/poleService';

export default {
  components: {
    AkOwnerAutocomplete,
    AkAccommodationAutocomplete,
    MultiSelect,
    AkInputSwitch,
    AkInputText,
    Icon,
  },
  mixins: [utilsMixin, sidebarMixin, ticketPriorityConst, ticketStatusConst],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      statusConst: [],
      poles: [],
    };
  },
  mounted() {
    this.statusConst = this.displayForTicketStatusConstWithoutUndefined();
    this.loadPoles();
  },
  methods: {
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.ownerAutocomplete) this.$refs.ownerAutocomplete.reset();
      if (this.$refs.accommodationAutocomplete) this.$refs.accommodationAutocomplete.reset();
      this.callback[1]();
    },
    loadPoles() {
      poleService.poles().then((data) => {
        this.poles = data;
      });
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    loadingCompanies() {
      return this.companies === undefined;
    },
  },
};
</script>

<template>
  <h2>Filtrer</h2>
  <form class="d-flex flex-column" style="row-gap: 16px" @submit.prevent="search">
    <AkOwnerAutocomplete
      ref="ownerAutocomplete"
      v-model="currentValue.ownerId"
      optionValue="id"
      optionLabel="display"
      :placeholder="$t('owner.ownerDisplay')"
      style="min-width: 100px"
      inline
      class-name="flex-1" />
    <AkAccommodationAutocomplete
      ref="accommodationAutocomplete"
      v-model="currentValue.accommodationId"
      :placeholder="$t('activity.accommodation')"
      inline
      class-name="flex-1" />
    <MultiSelect
      v-model="currentValue.poleIds"
      :placeholder="$t('ticket.attribution')"
      :options="poles"
      optionValue="id"
      optionLabel="display"
      :displayLabel="false"
      class="flex-1 form-control"
      inline />
    <MultiSelect
      v-model="currentValue.status"
      :placeholder="$t('ticket.status')"
      :options="statusConst"
      option-label="label"
      option-value="value"
      :display-label="false"
      class="flex-1 form-control"
      inline />
    <MultiSelect
      v-model="currentValue.prioritys"
      :placeholder="$t('ticket.priority')"
      :options="ticketPriorityConst"
      option-label="label"
      option-value="value"
      :display-label="false"
      class="flex-1 form-control"
      inline />
    <AkInputText
      v-model="currentValue.label"
      :placeholder="$t('ticket.label')"
      class-name="flex-1"
      style="min-width: 100px"
      type="text"
      inline />
    <AkInputSwitch
      v-model="currentValue.attributedToMe"
      :label="$t('ticket.attributed_to_me')"
      class-name="flex-1"
      :labelBefore="false"
      inline />

    <div class="d-flex flex-row">
      <button class="btn btn-primary btn-search flex-1" type="submit">
        {{ this.$t('visitor_tax.filter') }}
      </button>
      <Icon
        v-on:click="this.reset()"
        class="pointer"
        icon="basil:cross-outline"
        :style="{fontSize: '50px', width: '50px !important'}" />
    </div>
  </form>
</template>
