import Api from '@/services/api';
import {createFormDataFiles, removeEmptyParams} from '@utils';

export default {
  activitys(filter) {
    return Api()
      .get('/activitys', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  activity(id) {
    return Api()
      .get('/activity/' + id)
      .then((res) => this.formatRes(res.data));
  },
  create(activity, files) {
    let data = createFormDataFiles(activity, files);
    return Api()
      .post('/activity', data)
      .then((res) => this.formatRes(res.data));
  },
  update(activity, files) {
    let data = createFormDataFiles(activity, files);
    return Api()
      .put('/activity/' + activity.id, data)
      .then((res) => this.formatRes(res.data));
  },
  delete(activity) {
    return Api()
      .delete('/activity/' + activity.id)
      .then(() => activity);
  },
  formatRes(e) {
    if (e.date) e.date = new Date(e.date);
    return e;
  },
};
