import Api from '@/services/api';
import {removeEmptyParams, transformDownloadResponse} from '@utils';
import contentDisposition from 'content-disposition';

export default {
  housekeepers(filter) {
    return Api()
      .get('/housekeepers', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  housekeepersForAutocomplete(filter) {
    return Api()
      .get('/housekeepers/autocomplete', {params: removeEmptyParams(filter)})
      .then((res) => this.formatRes(res.data));
  },
  housekeeper(id) {
    return Api()
      .get('/housekeeper/' + id)
      .then((res) => this.formatRes(res.data));
  },
  enable(housekeeper) {
    return Api()
      .get('/housekeeper/enable/' + housekeeper.id)
      .then((res) => this.formatRes(res.data));
  },
  disable(housekeeper) {
    return Api()
      .get('/housekeeper/disable/' + housekeeper.id)
      .then((res) => this.formatRes(res.data));
  },
  exportList(filter) {
    return Api()
      .get('/housekeepers/export', {
        responseType: 'blob',
        transformResponse: [transformDownloadResponse],
        params: removeEmptyParams(filter),
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  },
  create(housekeeper) {
    return Api()
      .post('/housekeeper', housekeeper)
      .then((res) => this.formatRes(res.data));
  },
  access(housekeeper) {
    return Api()
      .post('/housekeeper/' + housekeeper.id + '/access')
      .then((res) => this.formatRes(res.data));
  },
  update(housekeeper) {
    return Api()
      .put('/housekeeper/' + housekeeper.id, housekeeper)
      .then((res) => this.formatRes(res.data));
  },
  delete(housekeeper) {
    return Api()
      .delete('/housekeeper/' + housekeeper.id)
      .then(() => housekeeper);
  },
  formatRes(e) {
    return e;
  },
};
