export default {
  data() {
    return {
      yesNoButtonConst: [
        {label: this.$t('yes'), value: true},
        {label: this.$t('no'), value: false},
      ],
    };
  },
};
