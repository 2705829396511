import Api from '@/services/api';
import {removeEmptyParams, transformDownloadResponse} from '@utils';
import contentDisposition from 'content-disposition';

export default {
  reservationsPayment(filter, signal) {
    return Api()
      .get('/reservations/payment', {params: removeEmptyParams(filter), signal: signal})
      .then((res) => {
        return res.data;
      });
  },
  reservationsPaymentPagination(filter, signal) {
    return Api()
      .get('/reservations/payment/pagination', {params: removeEmptyParams(filter), signal: signal})
      .then((res) => {
        res.data.forEach(this.formatRes);
        let response = {};
        response.data = res.data;
        response.totalItems = parseInt(res.headers['x-pagination-total']);
        return response;
      });
  },
  exportFile(filter) {
    return Api()
      .get('/reservations/payment/exportfile', {
        params: removeEmptyParams(filter),
        responseType: 'blob',
        transformResponse: [transformDownloadResponse],
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  },
  reservationDTO(reservationId) {
    return Api()
      .get('/reservation/payment/details/' + reservationId)
      .then((res) => this.formatRes(res.data));
  },
  createPayment(payment) {
    return Api()
      .post('/reservations/payment/create', payment)
      .then((res) => this.formatRes(res.data));
  },
  rejectPayment(rejectedPayment) {
    return Api()
      .put('/reservations/payment/reject/' + rejectedPayment.reservationId, rejectedPayment)
      .then((res) => this.formatRes(res.data));
  },
  ignore(reservationId) {
    return Api()
      .put('/reservations/payment/ignore/' + reservationId)
      .then((res) => res.data);
  },
  formatRes(e) {
    if (e.dateStart) e.dateStart = new Date(e.dateStart);
    if (e.dateEnd) e.dateEnd = new Date(e.dateEnd);
    if (e.dateCheckin) e.dateCheckin = new Date(e.dateCheckin);
    if (e.dateCheckout) e.dateCheckout = new Date(e.dateCheckout);
    if (e.reservationDate) e.reservationDate = new Date(e.reservationDate);

    if (e.type === 'GUEST') {
      e.blockadeReason = null;
      e.comments = null;
      e.occupantInfo = null;
    } else if (e.type === 'OWNER') {
      e.reservationSite = null;
      e.guestFirstName = null;
      e.guestLastName = null;
      e.guestPhone = null;
      e.guestPhone2 = null;
      e.guestMail = null;
      e.nbBabies = null;
      e.cautionDone = null;
      e.paymentDone = null;
      e.blockadeReason = null;
      e.dateCheckin = null;
      e.dateCheckout = null;
    } else if (e.type === 'BLOCKADE') {
      e.reservationSite = null;
      e.guestFirstName = null;
      e.guestLastName = null;
      e.guestPhone = null;
      e.guestPhone2 = null;
      e.guestMail = null;
      e.nbAdults = null;
      e.nbChildren = null;
      e.nbBabies = null;
      e.cautionDone = null;
      e.paymentDone = null;
      e.occupantInfo = null;
    }
    return e;
  },
};
