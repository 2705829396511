/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';

export default {
  mixins: [permissionsMixin],
  data() {
    return {
      tabs: [
        {
          name: this.$t('maintenance.list'),
          id: 'maintenanceOwner',
          icon: 'list',
          hidden: !this.isOwner(),
        },
        {
          name: this.$t('litige.list'),
          id: 'litigeOwner',
          icon: 'list',
          hidden: !this.isOwner(),
        },
      ],
    };
  },
  methods: {},
};
