<script>
import {maxLength, required, requiredIf} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

/* COMPOSANTS */
import AkDropdown from '@components/input/AkDropdown.vue';
import AkDialog from '@components/general/AkDialog.vue';
import AkAccommodationAutocomplete from '@components/general/AkAccommodationAutocomplete.vue';
import AkInputTextArea from '@components/input/AkInputTextArea.vue';
import AkCalendar from '@components/input/AkCalendar.vue';
import AkInputText from '@components/input/AkInputText.vue';
import PButton from 'primevue/button';
import AkFilesUpload from '@components/input/AkFilesUpload.vue';
import AkClientAutocomplete from '@components/general/AkClientAutocomplete';
import AkOwnerAutocomplete from '@components/general/AkOwnerAutocomplete';
import AkPrestataireAutocomplete from '@components/general/AkPrestataireAutocomplete';
import AkEmployeeAutocomplete from '@components/general/AkEmployeeAutocomplete';
import AkPartenaireAutocomplete from '@components/general/AkPartenaireAutocomplete';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import loaderMixin from '@mixins/loaderMixin';
import maintenanceInterlocutorTypeConst from '@mixins/const/maintenanceInterlocutorTypeConst';
import maintenanceStatusConst from '@mixins/const/maintenanceStatusConst';

/* SERVICES */
import maintenanceService from '@services/maintenanceService';
import companyService from '@services/companyService';
import clientService from '@services/clientService';
import upsellService from '@services/upsellService';
import upsellStatusConst from '@mixins/const/upsellStatusConst';

export default {
  components: {PButton},
  mixins: [randomRef, utilsMixin, messageMixin, permissionsMixin, upsellStatusConst, sidebarMixin, loaderMixin],
  metaInfo() {
    return {
      title: 'upsell.list',
    };
  },
  data() {
    return {
      loading: false,
      filter: {
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      },
      totalItems: 0,
      upsells: [],
      companies: [],
    };
  },
  watch: {},
  mounted() {
    this.refresh();
  },
  computed: {
    isEmptyUpsells() {
      return this.upsells.length === 0;
    },
  },
  methods: {
    refresh() {
      this.loading = true;
      upsellService
        .upsellsPagination(this.filter)
        .then((data) => {
          this.upsells = data.data;
          this.totalItems = data.totalItems;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // FILTER
    openFilterPanel() {
      this.toggleFilter('GaUpsellListFilter', this.filter, this.refresh, this.resetFilter);
    },
    resetFilter() {
      this.filter = {};
      this.toggleFilter('GaUpsellListFilter', this.filter, this.refresh, this.resetFilter);
    },
    exportUpsells() {
      this.showTotalLoaderWithAfter(this.$t('upsell.list_creation_in_progress'));
      upsellService.exportUpsells(this.filter).then((data) => {
        this.hideLoader();
      });
    },
    rowClick(event) {},
    onPage(event) {
      this.filter.page = event.page + 1;
      this.filter.limit = event.rows;
      this.refresh();
    },
    onSort(event) {
      this.filter.sort = [];
      for (let sort of event.multiSortMeta) {
        this.filter.sort.push('' + sort.field + ':' + (sort.order === -1 ? 'DESC' : 'ASC'));
      }
      if (this.filter.sort.length === 0) {
        this.filter.sort.push('id:DESC');
      }
      this.refresh();
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :title="$t('upsell.list')">
    <template #action>
      <div class="d-flex justify-content-end" style="column-gap: 16px">
        <PButton
          class="btn btn-primary"
          :badge="this.countFilter(this.filter)"
          badgeClass="p-badge-primary"
          :label="this.$t('upsell.filter')"
          @click="openFilterPanel()">
        </PButton>
        <button class="btn btn-primary" @click="exportUpsells()" :disabled="this.isEmptyUpsells">
          {{ this.$t('upsell.download') }}
        </button>
      </div>
    </template>
    <template #content>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive">
                <DataTable
                  :always-show-paginator="false"
                  :loading="loading"
                  :paginator="true"
                  :rows="10"
                  :rows-per-page-options="[10, 20, 50]"
                  :value="upsells"
                  class="pointer"
                  current-page-report-template="{first}-{last}/{totalRecords}"
                  responsive-layout="scroll"
                  striped-rows
                  lazy
                  :total-records="totalItems"
                  removable-sort
                  sort-mode="multiple"
                  :sortOrder="1"
                  @row-click="rowClick($event)"
                  @page="onPage($event)"
                  @sort="onSort($event)">
                  <template #empty>
                    {{ $t('upsell.empty') }}
                  </template>
                  <Column :header="$t('upsell.ownerName')">
                    <template #body="slotProps">
                      {{ slotProps.data.ownerName }}
                    </template>
                  </Column>
                  <Column :header="$t('upsell.accommodationName')" :sortable="true" sort-field="j_accommodation_name">
                    <template #body="slotProps">
                      {{ slotProps.data.accommodationName }}
                    </template>
                  </Column>
                  <Column :header="$t('upsell.name')" :sortable="true" sort-field="name">
                    <template #body="slotProps">
                      {{ slotProps.data.name }}
                    </template>
                  </Column>
                  <Column :header="$t('upsell.checkinDate')" :sortable="true" sort-field="j_date_checkin">
                    <template #body="slotProps">
                      {{ this.formatDateIfNeeded(slotProps.data.dateCheckin) }}
                    </template>
                  </Column>
                  <Column :header="$t('upsell.checkoutDate')" :sortable="true" sort-field="j_date_checkout">
                    <template #body="slotProps">
                      {{ this.formatDateIfNeeded(slotProps.data.dateCheckout) }}
                    </template>
                  </Column>
                  <Column :header="$t('upsell.buyDate')" :sortable="true" sort-field="date_buy">
                    <template #body="slotProps">
                      {{ this.formatDateIfNeeded(slotProps.data.dateBuy) }}
                    </template>
                  </Column>
                  <Column :header="$t('upsell.approbationDate')" :sortable="true" sort-field="date_approbation">
                    <template #body="slotProps">
                      {{ this.formatDateIfNeeded(slotProps.data.dateApprobation) }}
                    </template>
                  </Column>
                  <Column :header="$t('upsell.status')" :sortable="true" sort-field="status">
                    <template #body="slotProps">
                      {{ this.displayForUpsellStatusConst(slotProps.data.status) }}
                      <br />
                      <span class="font-xs">
                        {{ this.displayForUpsellStatusEnsoConst(slotProps.data.statusEnso) }}
                      </span>
                    </template>
                  </Column>
                  <Column :header="$t('upsell.companyName')" :sortable="true" sort-field="j_company_name">
                    <template #body="slotProps">
                      {{ slotProps.data.companyName }}
                    </template>
                  </Column>
                  <Column :header="$t('upsell.reservationLocalizer')" :sortable="true" sort-field="j_avantio_localizer">
                    <template #body="slotProps">
                      <router-link
                        :to="{
                          name: 'accommodationReservationPage',
                          params: {
                            accommodationId: slotProps.data.accommodationId,
                            reservationId: slotProps.data.reservationId,
                          },
                        }">
                        {{ slotProps.data.reservationLocalizer }}
                      </router-link>
                    </template>
                  </Column>
                  <Column :header="$t('upsell.amount')">
                    <template #body="slotProps">
                      {{ this.formatCurrencyEUR(slotProps.data.amount) }}
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #extra></template>
  </GaView>
</template>
