<script>
/* COMPOSANTS */
import {Icon} from '@iconify/vue/dist/iconify';
import AkCalendar from '@components/input/AkCalendar';
import AkInputMoney from '@components/input/AkInputMoney';
import AkReservationAutocomplete from '@components/general/AkReservationAutocomplete';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import accommodationTypeConst from '@mixins/const/accommodationTypeConst';
import residenceTypeConst from '@mixins/const/residenceTypeConst';
import accountOperationsConst from '@mixins/const/accountOperationsConst';

export default {
  components: {
    AkReservationAutocomplete,
    AkInputMoney,
    AkCalendar,
    Icon,
  },
  mixins: [
    permissionsMixin,
    utilsMixin,
    sidebarMixin,
    accommodationTypeConst,
    residenceTypeConst,
    accountOperationsConst,
  ],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      operationsConst: [],
    };
  },
  mounted() {
    for (let type of this.accountOperationsConstForRecapitulatif()) {
      this.operationsConst.push(type);
    }
    this.operationsConst.push({label: this.$t('operation.CRG_CLOSED'), value: 'CRG_CLOSED'});
    this.operationsConst.push({label: this.$t('operation.REVERT'), value: 'REVERT'});
    this.operationsConst.sort(function compare(a, b) {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });
  },
  methods: {
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.inputReservation) this.$refs.inputReservation.reset();
      this.callback[1]();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <h2>Filtrer</h2>
  <form class="d-flex flex-column" style="row-gap: 16px" @submit.prevent="search">
    <AkCalendar
      v-model="currentValue.dateStart"
      inline
      :placeholder="$t('date_start')"
      class-name="flex-1 form-control" />
    <AkCalendar v-model="currentValue.dateEnd" inline :placeholder="$t('date_end')" class-name="flex-1 form-control" />
    <AkInputMoney
      ref="inputSearchAmount"
      v-model="currentValue.amount"
      :placeholder="$t('accounting.amount')"
      inline
      class-name="flex-1 form-control" />
    <AkReservationAutocomplete
      ref="inputReservation"
      v-model="currentValue.reservation"
      :placeholder="$t('reservation.number')"
      inline
      class-name="flex-1 form-control" />

    <div class="d-flex flex-row">
      <button class="btn btn-primary btn-search flex-1" type="submit">Rechercher</button>
      <Icon
        v-on:click="this.reset()"
        class="pointer"
        icon="basil:cross-outline"
        :style="{fontSize: '50px', width: '50px !important'}" />
    </div>
  </form>
</template>
