export default {
  data() {
    return {
      dateFormat: /[+-]?\d{4}(-[01]\d(-[0-3]\d(T[0-2]\d:[0-5]\d:?([0-5]\d(\.\d+)?)?[+-][0-2]\d:[0-5]\dZ?)?)?)?/,
    };
  },
  methods: {
    isValidDate(date) {
      return date != null && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
    },
    dateReviver(key, value) {
      if (
        (key.toLowerCase().includes('date') || key.toLowerCase().includes('time')) &&
        typeof value === 'string' &&
        this.dateFormat.test(value)
      ) {
        const potentialDate = new Date(value);
        if (this.isValidDate(potentialDate)) {
          return potentialDate;
        }
      }
      return value;
    },
    getFilter(name, defautFilter) {
      let filters = window.localStorage.getItem('filters');
      if (filters) {
        let filtersObj = JSON.parse(filters, this.dateReviver);
        if (filtersObj[name]) {
          return filtersObj[name];
        }
        return defautFilter;
      } else {
        return defautFilter;
      }
    },
    saveFilter(name, filter) {
      let filters = window.localStorage.getItem('filters');
      if (filters) {
        let filtersObj = JSON.parse(filters);
        filtersObj[name] = filter;
        window.localStorage.setItem('filters', JSON.stringify(filtersObj));
      } else {
        filters = {};
        filters[name] = filter;
        window.localStorage.setItem('filters', JSON.stringify(filters));
      }
    },
  },
};
