<script>
import useVuelidate from '@vuelidate/core';
import {required, requiredIf} from '@vuelidate/validators';

/* COMPOSANTS */
import AkDialog from '@components/general/AkDialog';
import AkCalendar from '@components/input/AkCalendar';
import AkDropdown from '@components/input/AkDropdown';
import AkInputMoney from '@components/input/AkInputMoney';
import AkInputTextArea from '@components/input/AkInputTextArea';
import PButton from 'primevue/button';

/* MIXINS */
import messageMixin from '@mixins/messageMixin';
import paymentSourceConst from '@mixins/const/paymentSourceConst';
import permissionConst, {PermissionMap} from '@mixins/const/permissionConst';
import reservationStatusRefundConst from '@mixins/const/reservationStatusRefundConst';
import loaderMixin from '@mixins/loaderMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';

/* SERVICES */
import reservationCancelService from '@services/reservationCancelService';

export default {
  components: {
    AkInputTextArea,
    AkInputMoney,
    AkDropdown,
    AkCalendar,
    AkDialog,
    PButton,
  },
  mixins: [
    loaderMixin,
    messageMixin,
    reservationStatusRefundConst,
    paymentSourceConst,
    permissionConst,
    permissionsMixin,
    sidebarMixin,
    utilsMixin,
  ],
  metaInfo() {
    return {
      title: 'accounting.cancel',
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      loading: true,
      list: [],
      search: {},
      itemSelected: {},
      itemAccept: {},
      itemContest: {},
      itemRefuse: {},
    };
  },
  validations() {
    return {
      itemAccept: {
        reservationId: {required},
        date: {required},
        source: {required},
        amountTotal: {required},
        amountNight: {required},
        amountOta: {required: requiredIf(this.itemSelected.reservationSite)},
        comment: {required},
      },
      itemContest: {
        reservationId: {required},
        date: {required},
        amountEstimated: {required},
        comment: {required},
      },
      itemRefuse: {
        reservationId: {required},
        comment: {required},
      },
    };
  },
  mounted() {
    this.refresh();
  },
  computed: {
    hasManagePermission() {
      return this.hasPermission(PermissionMap.RESERVATION_REGLEMENT.MANAGE);
    },

    // au minimum, on rembourse au voyageur
    // TDS + frais de ménage

    amountRefundTotalMin() {
      if (!this.itemAccept || !this.itemSelected) return 0;
      let housework = this.itemAccept.paidHousework;
      let tds = this.itemAccept.paidTds;
      return this.addNumbers([housework, tds]);
    },

    // au maximum, on rembourse au voyageur
    // le montant total qu'il a payé

    amountRefundTotalMax() {
      if (!this.itemAccept || !this.itemSelected) return 0;
      return this.itemAccept.amountPaid;
    },

    // montant remboursé pour le ménage
    // 100% du montant versé

    amountRefundHousework() {
      if (!this.itemAccept || !this.itemSelected) return 0;
      return this.itemAccept.paidHousework;
    },

    // montant remboursé pour la TDS
    // 100% du montant versé

    amountRefundTds() {
      if (!this.itemAccept || !this.itemSelected) return 0;
      return this.itemAccept.paidTds;
    },

    // montant remboursé pour la nuit
    // total remboursé - tds - ménage

    amountRefundNight() {
      if (!this.itemAccept || !this.itemSelected) return null;
      let total = this.itemAccept.amountTotal;
      if (total === null) return null;
      let housework = this.itemSelected.amountPaidHousework;
      let tds = this.itemSelected.amountPaidTds;
      return this.addNumbers([total, -housework, -tds]);
    },

    // à supprimer ?
    itemSelectedPartialPay() {
      return !this.itemSelected.fullyPaid;
    },
  },
  methods: {
    refresh() {
      this.loading = true;
      let p1 = reservationCancelService.findAll(this.search);
      p1.then((data) => {
        this.list = data;
      }).finally(() => {
        this.loading = false;
      });
    },

    // IS

    isAccepted(data) {
      return data.statusRefund === 'ACCEPTED';
    },
    isRefused(data) {
      return data.statusRefund === 'REFUSED';
    },
    isContested(data) {
      return data.statusRefund === 'CONTESTED';
    },

    // CAN

    canAccept(data) {
      if (data.crgClosed) return false;
      if (data.cancelTransaction) return false;
      if (this.isAccepted(data)) return false;
      return data.amountPaid > 0;
    },
    canRefuse(data) {
      if (data.crgClosed) return false;
      return !this.isRefused(data);
    },
    canContest(data) {
      if (data.crgClosed) return false;
      return !this.isContested(data) && data.amountPaid > 0;
    },

    // OPEN

    openAcceptDialog(data) {
      this.itemSelected = {...data};
      this.itemAccept = {reservationId: data.id};
      this.$refs.dialogAccept.show();
    },
    openContestDialog(data) {
      this.itemSelected = {...data};
      this.itemContest = {reservationId: data.id};
      this.$refs.dialogContest.show();
    },
    openRefuseDialog(data) {
      this.itemSelected = {...data};
      this.itemRefuse = {reservationId: data.id};
      this.$refs.dialogRefuse.show();
    },

    // HANDLE

    handleAccept() {
      if (!this.itemAccept || !this.itemSelected) return;

      let total = this.itemAccept.amountTotal;
      if (total === null) return;

      let housework = this.itemSelected.amountPaidHousework;
      let tds = this.itemSelected.amountPaidTds;
      let min = this.addNumbers([housework, tds]);

      if (total < min) {
        this.$refs.dialogAccept.error(
          "Le montant total à rembourser n'est pas assez élevé (montant minimum: " + this.formatAmount(min) + ')',
        );
        return;
      }

      this.itemAccept.amountNight = this.amountRefundNight;

      this.v$.itemAccept.$touch();
      if (this.v$.itemAccept.$error) return;

      this.showTotalLoader();
      reservationCancelService
        .accept(this.itemAccept)
        .then(() => {
          this.success(this.$t('reservation.refundAccepted'));
          this.$refs.dialogAccept.hide();
          this.refresh();
        })
        .catch((e) => {
          this.$refs.dialogAccept.error(this.msgForErr(this.$t, e));
        })
        .finally(() => {
          this.hideLoader();
        });
    },

    handleContest() {
      this.v$.itemContest.$touch();
      if (this.v$.itemContest.$error) return;

      if (this.itemContest.date) this.itemContest.date = new Date(this.itemContest.date);

      this.showTotalLoader();
      reservationCancelService
        .contest(this.itemContest)
        .then((data) => {
          this.success(this.$t('reservation.refundContested'));
          this.$refs.dialogContest.hide();
          this.refresh();
        })
        .catch((e) => {
          this.$refs.dialogContest.error(this.msgForErr(this.$t, e));
        })
        .finally(() => {
          this.hideLoader();
        });
    },

    handleRefuse() {
      this.v$.itemRefuse.$touch();
      if (this.v$.itemRefuse.$error) return;

      this.showTotalLoader();
      reservationCancelService
        .refuse(this.itemRefuse)
        .then((data) => {
          this.success(this.$t('reservation.refundRefused'));
          this.$refs.dialogRefuse.hide();
          this.refresh();
        })
        .catch((e) => {
          this.$refs.dialogRefuse.error(this.msgForErr(this.$t, e));
        })
        .finally(() => {
          this.hideLoader();
        });
    },

    // EXPORT

    exportFile() {
      this.showTotalLoaderWithAfter(this.$t('reservation.cancelled_creation_in_progress'));
      let p2 = reservationCancelService.exportFile(this.search);
      p2.then((data) => {})
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.hideLoader();
        });
    },

    // FILTER

    openFilterPanel() {
      this.toggleFilter('GaCancelFilter', this.search, this.refresh, this.resetFilter);
    },
    resetFilter() {
      this.search = {};
      this.toggleFilter('GaCancelFilter', this.search, this.refresh, this.resetFilter);
    },

    // COLOR

    computeColorClass(data) {
      if (data.amountPaid === 0) return 'bg-danger text-white p-1';
      if (data.fullyPaid) return 'bg-success text-white p-1';
      return 'bg-yellow text-white p-1';
    },
  },
};
</script>
<template>
  <GaView :title="$t('reservation.cancel')">
    <template #action>
      <div class="d-flex justify-content-end" style="gap: 16px">
        <PButton
          :badge="this.countFilter(this.search)"
          badgeClass="p-badge-primary"
          class="btn btn-primary"
          label="Filtrer"
          @click="openFilterPanel()" />
        <button class="btn btn-primary" @click="exportFile()">
          {{ this.$t('payment.download') }}
        </button>
      </div>
    </template>

    <template #content>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive">
                <DataTable
                  :always-show-paginator="false"
                  :loading="loading"
                  :paginator="true"
                  :rows="10"
                  :rows-per-page-options="[10, 20, 50]"
                  :value="list"
                  class="table"
                  current-page-report-template="{first}-{last}/{totalRecords}"
                  removable-sort
                  responsive-layout="scroll"
                  sort-field="position"
                  striped-rows>
                  <template #header>
                    <div class="d-flex align-items-center justify-content-start header-legend-result">
                      {{ this.$t('payment.number_results') }} {{ this.list.length }}
                    </div>
                  </template>
                  <template #empty>
                    {{ $t('reservation.empty') }}
                  </template>

                  <Column :sortable="true" field="reservationNum">
                    <template #header>
                      {{ this.$t('reservation.number') }}
                    </template>
                    <template #body="slotProps">
                      <router-link
                        :to="{
                          name: 'cancelDetails',
                          params: {reservationId: slotProps.data.id},
                        }">
                        {{ slotProps.data.reservationNum }}
                      </router-link>
                    </template>
                  </Column>

                  <Column :header="$t('reservation.reservation_site')" :sortable="true" field="reservationSite">
                    <template #body="slotProps">
                      <span v-tooltip.bottom="slotProps.data.reservationSite">
                        {{ reservationSiteDisplay(slotProps.data.reservationSite) }}
                      </span>
                    </template>
                  </Column>

                  <Column :header="$t('reservation.guestName')" :sortable="true" field="guestLastName">
                    <template #body="slotProps">
                      <span v-tooltip.bottom="slotProps.data.guestEmail">
                        {{ slotProps.data.guestLastName }}
                      </span>
                    </template>
                  </Column>

                  <Column
                    :header="$t('reservation.accommodationId')"
                    :sortable="true"
                    class="text-right"
                    field="accommodationName">
                  </Column>
                  <Column :header="$t('settings.cancel_rule')" :sortable="true" class="text-right" field="cancelRule">
                  </Column>

                  <Column :header="$t('reservation.dateCancel')" :sortable="true" field="dateCancel">
                    <template #body="slotProps">
                      {{ displayDate(slotProps.data.dateCancel) }}
                    </template>
                  </Column>

                  <Column :header="$t('reservation.nbDaysCancelled')" :sortable="true" field="nbDaysCancelled">
                    <template #body="slotProps">
                      {{ slotProps.data.nbDaysCancelled }}
                    </template>
                  </Column>

                  <Column :header="$t('reservation.period')" :sortable="true" field="dateStart">
                    <template #body="slotProps">
                      {{ slotProps.data.periodDisplay }}
                    </template>
                  </Column>

                  <Column :header="$t('payment.refundStatut')">
                    <template #body="slotProps">
                      {{ displayForReservationStatusRefundConst(slotProps.data.statusRefund) }}
                    </template>
                  </Column>

                  <Column :header="$t('payment.total')" class="text-right">
                    <template #body="slotProps">
                      {{ formatAmount(slotProps.data.amountTotal) }}
                    </template>
                  </Column>

                  <Column :header="$t('payment.paidByGuest')" class="text-right">
                    <template #body="slotProps">
                      <span :class="computeColorClass(slotProps.data)">
                        {{ formatAmount(slotProps.data.amountPaid) }}
                      </span>
                    </template>
                  </Column>

                  <Column v-if="hasManagePermission" :header="$t('payment.actions')">
                    <template #body="slotProps">
                      <div class="">
                        <button
                          class="btn btn-inverse-success btn-xs"
                          style="margin: 5px"
                          @click="openAcceptDialog(slotProps.data)"
                          v-if="canAccept(slotProps.data)">
                          {{ $t('reservation.refundAccept') }}
                        </button>

                        <button
                          class="btn btn-inverse-primary btn-xs"
                          style="margin: 5px"
                          @click="openContestDialog(slotProps.data)"
                          v-if="canContest(slotProps.data)">
                          {{ $t('reservation.refundContest') }}
                        </button>

                        <button
                          class="btn btn-inverse-danger btn-xs"
                          style="margin: 5px"
                          @click="openRefuseDialog(slotProps.data)"
                          v-if="canRefuse(slotProps.data)">
                          {{ $t('reservation.refundRefuse') }}
                        </button>
                      </div>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!----------------------------------------------------------------------------------------->
    <template #extra>
      <AkDialog
        ref="dialogAccept"
        :title="$t('reservation.refundAcceptTitle')"
        :validate-label="$t('reservation.refundAccept')"
        width="1000px"
        @validate="handleAccept()">
        <h5>Réservation initiale</h5>
        <div class="form-row">
          <AkInputMoney
            v-model="itemSelected.amountTotal"
            label="Montant total"
            :disabled="true"
            class-name="col-md-6" />
          <AkInputMoney
            v-model="itemSelected.amountPaid"
            label="Montant total encaissé"
            :disabled="true"
            class-name="col-md-6" />
        </div>

        <div class="form-row">
          <AkInputMoney
            v-model="itemSelected.amountPaidNight"
            label="Montant des nuits"
            :disabled="true"
            class-name="col-md-6" />
          <AkInputMoney
            v-model="itemSelected.amountPaidHousework"
            label="Frais de ménage"
            :disabled="true"
            class-name="col-md-6" />
        </div>

        <div class="form-row">
          <AkInputMoney
            v-model="itemSelected.amountOta"
            label="Commission plateforme"
            :disabled="true"
            class-name="col-md-6" />
          <AkInputMoney
            v-model="itemSelected.amountPaidTds"
            label="Taxe de séjour"
            :disabled="true"
            class-name="col-md-6" />
        </div>

        <h5>Remboursement</h5>

        <div class="form-row">
          <AkCalendar v-model="itemAccept.date" label="Date" :validator="v$.itemAccept.date" class-name="col-md-6" />
          <AkDropdown
            v-model="itemAccept.source"
            :label="$t('payment.method')"
            :options="paymentSourceConst"
            :validator="v$.itemAccept.source"
            class-name="col-md-6" />
        </div>

        <div class="form-row">
          <AkInputMoney
            v-model="itemAccept.amountTotal"
            label="Total remboursé au voyageur"
            :validator="v$.itemAccept.amountTotal"
            :min="amountRefundTotalMin"
            :max="amountRefundTotalMax"
            class-name="col-md-6" />
          <AkInputMoney
            :disabled="!itemSelected.reservationSite"
            v-model="itemAccept.amountOta"
            label="Commission plateforme"
            :validator="v$.itemAccept.amountOta"
            :min="0"
            class-name="col-md-6" />
        </div>

        <div class="form-row">
          <AkInputTextArea
            v-model="itemAccept.comment"
            :label="$t('reservation.comment')"
            :validator="v$.itemAccept.comment"
            class-name="col-md-12" />
        </div>
      </AkDialog>
      <!-- ----------------------------------------------------------------------- -->
      <AkDialog
        ref="dialogContest"
        :title="$t('reservation.refundContestTitle')"
        :validate-label="$t('reservation.refundContest')"
        @validate="handleContest()">
        <div class="form-row">
          <AkCalendar
            v-model="itemContest.date"
            :validator="v$.itemContest.date"
            :label="$t('reservation.dateContestation')"
            class-name="col-md-12" />
        </div>
        <div class="form-row">
          <AkInputMoney
            v-model="itemContest.amountEstimated"
            :label="$t('reservation.amountContestationEstimated')"
            :validator="v$.itemContest.amountEstimated"
            class-name="col-md-12" />
        </div>
        <div class="form-row">
          <AkInputTextArea
            v-model="itemContest.comment"
            :label="$t('reservation.comment')"
            :validator="v$.itemContest.comment"
            class-name="col-md-12" />
        </div>
      </AkDialog>
      <!-- ----------------------------------------------------------------------- -->
      <AkDialog
        ref="dialogRefuse"
        :title="$t('reservation.refundRefuseTitle')"
        :validate-label="$t('reservation.refundRefuse')"
        @validate="handleRefuse()">
        <div class="form-row">
          <AkInputTextArea
            v-model="itemRefuse.comment"
            :label="$t('reservation.comment')"
            :validator="v$.itemRefuse.comment"
            class-name="col-md-12" />
        </div>
      </AkDialog>
      <!-- ----------------------------------------------------------------------- -->
    </template>
  </GaView>
</template>
<style>
aside {
  color: white;
}
</style>
