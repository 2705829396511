<script>
import useVuelidate from '@vuelidate/core';

/* COMPOSANTS */
import GaView from '@components/layout/GaView';
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import AkInputMoney from '@components/input/AkInputMoney';
import AkInputPercentage from '@components/input/AkInputPercentage';
import AkInputInteger from '@components/input/AkInputInteger';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import societeService from '@services/societeService';
import settingService from '@services/settingService';

export default {
  components: {
    AkFormSubmitted,
    AkInputPercentage,
    AkInputMoney,
    AkInputInteger,
    GaView,
  },
  mixins: [loaderMixin, messageMixin, randomRef, utilsMixin],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'settings.societe_guestadom',
    };
  },
  data() {
    return {
      loading: true,
      submitted: false,
      societe: {},
      mandatNumSetting: {},
    };
  },
  validations() {
    return {};
  },
  mounted() {
    let p1 = societeService.societeGuestadom();
    p1.then((data) => {
      this.societe = data;
    });

    let p2 = settingService.setting('MANDATE_NUM');
    p2.then((data) => {
      this.mandatNumSetting = data;
    });

    Promise.all([p1, p2]).then(() => {
      this.loading = false;
    });
  },
  methods: {
    update() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$error) return;
      this.showTotalLoader();

      let p1 = societeService.update(this.societe);
      p1.then((data) => {
        this.societe = data;
      });

      let p2 = settingService.update(this.mandatNumSetting);
      p2.then((data) => {
        this.mandatNumSetting = data;
      });

      Promise.all([p1, p2]).then(() => {
        this.success(this.$t('updated'));
        this.hideLoader();
        this.submitted = false;
      });
    },
  },
  computed: {},
};
</script>

<template>
  <GaView :ref="ref" :title="$t('settings.infos_mandat')">
    <template #content>
      <AkFormSubmitted :submitted="submitted" reference="form" @submit="update()">
        <div class="row">
          <AkInputPercentage v-model="societe.comGas" :label="$t('societe.comGas')" class-name="col-md-6" />
          <AkInputPercentage v-model="societe.comGa" :label="$t('societe.comGa')" class-name="col-md-6" />
          <AkInputMoney
            v-model="societe.amountMaxRenovation"
            :label="$t('societe.amountMaxRenovation')"
            class-name="col-md-6" />
          <AkInputMoney
            v-model="societe.amountMaxEquipPurchase"
            :label="$t('societe.amountMaxEquipPurchase')"
            class-name="col-md-6" />
          <AkInputMoney v-model="societe.feeCancel" :label="$t('societe.feeCancel')" class-name="col-md-6" />
          <AkInputPercentage v-model="societe.feeCancelCom" :label="$t('societe.feeCancelCom')" class-name="col-md-6" />
          <AkInputMoney
            v-model="societe.feeServiceVgaMinimum"
            :label="$t('societe.feeServiceVgaMinimum')"
            class-name="col-md-6" />
          <AkInputPercentage
            v-model="societe.feeServiceVgaCom"
            :label="$t('societe.feeServiceVgaCom')"
            class-name="col-md-6" />
          <AkInputMoney v-model="societe.feeLitigation" :label="$t('societe.feeLitigation')" class-name="col-md-6" />
          <AkInputInteger v-model="mandatNumSetting.val" :label="$t('mandat.num')" class-name="col-md-6" />
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-content-end">
            <button class="btn btn-primary float-right">
              {{ $t('update') }}
            </button>
          </div>
        </div>
      </AkFormSubmitted>
    </template>
  </GaView>
</template>
