<script>
import moment from 'moment/moment';
import useVuelidate from '@vuelidate/core';

/* COMPOSANTS */
import PButton from 'primevue/button';
import InputText from 'primevue/inputtext';
import PendingMissionList from '@views/operationnel/mission/components/PendingMissionList';
import ParticipantPlanningList from '@views/operationnel/mission/components/ParticipantPlanningList';
import ParticipantPlanningDetails from '@views/operationnel/mission/components/ParticipantPlanningDetails';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';
import randomRef from '@mixins/randomRef';
import sidebarMixin from '@mixins/sidebarMixin';
import missionMixin from '@mixins/missionMixin';

/* SERVICES */
import missionPlanningParticipantService from '@/services/missionPlanningParticipantService';

export default {
  components: {
    PButton,
    InputText,
    PendingMissionList,
    ParticipantPlanningList,
    ParticipantPlanningDetails,
  },
  mixins: [permissionsMixin, utilsMixin, messageMixin, randomRef, sidebarMixin, missionMixin],
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      loading: false,
      planningList: [],
      pendingList: [],
      dates: [],
      textSearch: null,
      filter: {
        statusList: ['ACCEPTED', 'PROPOSED', 'CANCELED', 'COMPLETED'],
      },
    };
  },
  metaInfo() {
    return {
      title: 'planning_intervenant.title',
    };
  },
  computed: {
    hasOneParticipant() {
      return this.planningList.length === 1;
    },
  },
  mounted() {
    this.getCurrentWeek();
    this.refresh();
  },
  watch: {
    dates: function () {
      this.refresh();
    },
  },
  methods: {
    // FILTER

    openFilterPanel() {
      this.toggleFilter('GaMissionPlanningParticipantFilter', this.filter, this.refresh, this.resetFilter);
    },
    resetFilter() {
      this.filter = {};
      this.toggleFilter('GaMissionPlanningParticipantFilter', this.filter, this.refresh, this.resetFilter);
    },

    // REFRESH DATA

    refresh() {
      // Reset les listes pour forcer le rafraîchissement des Datatable
      this.pendingList = [];
      this.planningList = [];

      this.loading = true;

      // Filtres
      this.filter.dateStart = this.dateToUtc(this.dates[0]);
      this.filter.dateEnd = this.dateToUtc(this.dates[6]);
      this.filter.query = this.textSearch;

      // Rechercher les missions à planifier pour les salariés

      missionPlanningParticipantService
        .pending(this.filter)
        .then((data) => {
          this.pendingList = data;
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.loading = false;
        });

      // Rechercher les missions affectées aux salariés et/ou prestataires

      missionPlanningParticipantService
        .planning(this.filter)
        .then((data) => {
          this.planningList = data;
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // Actions sur dates et recherche

    getCurrentWeek() {
      this.dates = this.getDatesStrOfTheWeek(moment().weekday(0));
    },

    onPreviousWeekClick() {
      this.dates = this.getDatesStrOfTheWeek(this.addDays(moment(this.dates[0]), -7));
    },
    onNextWeekClick() {
      this.dates = this.getDatesStrOfTheWeek(this.addDays(moment(this.dates[0]), 7));
    },
    submitQuery() {
      this.refresh();
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :title="$t('planning_intervenant.title')">
    <template #search>
      <div class="d-flex align-items-center mb-20 mt-2">
        <button type="button" class="gd-btn-white mr-1" aria-label="previous week" @click="onPreviousWeekClick">
          <div class="gd-btn-white__svg">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.33333 4.16669L2.5 9.99996M2.5 9.99996L8.33333 15.8333M2.5 9.99996H17.5"
                stroke="#2F384C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </div>
          <span>{{ $t('planning_intervenant.last_week') }}</span>
        </button>
        <button type="button" class="gd-btn-white mr-1" aria-label="previous week" @click="getCurrentWeek">
          <span>{{ $t('planning_intervenant.current_week') }}</span>
        </button>
        <button type="button" class="gd-btn-white" aria-label="next week" @click="onNextWeekClick">
          <div class="gd-btn-white__svg">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.5 9.99996H17.5M17.5 9.99996L11.6667 4.16669M17.5 9.99996L11.6667 15.8333"
                stroke="#2F384C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </div>
          <span>{{ $t('planning_intervenant.next_week') }}</span>
        </button>
      </div>
    </template>
    <template #action>
      <div class="d-flex align-items-center mb-20 mt-2">
        <form class="p-input-icon-left ml-auto mr-2" @submit.prevent="submitQuery">
          <i class="pi pi-search" />
          <InputText :placeholder="$t('planning_intervenant.search')" v-model="textSearch" />
        </form>
        <PButton
          class="btn btn-primary"
          :badge="this.countFilter(this.filter)"
          badgeClass="p-badge-primary"
          label="Filtrer"
          @click="openFilterPanel()">
        </PButton>
      </div>
    </template>

    <!-- Contenu de la page -->
    <template #content>
      <div class="planning">
        <!-- LISTE DES MISSIONS A AFFECTER -->

        <PendingMissionList v-model="this.pendingList" :loading="this.loading" @mission-assigned="refresh()" />

        <!-- PLANNING DES INTERVENANTS -->

        <!-- Vision individuelle -->
        <ParticipantPlanningDetails
          v-if="this.hasOneParticipant"
          v-model="this.planningList[0]"
          :dates="this.dates"
          :filter="this.filter" />

        <!-- Vision globale -->
        <ParticipantPlanningList v-else v-model="this.planningList" :dates="this.dates" :loading="this.loading" />
      </div>
    </template>
  </GaView>
</template>
