import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  clients(filter) {
    return Api()
      .get('/clients', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  clientsPagination(filter) {
    return Api()
      .get('/clients/pagination', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach(this.formatRes);
        let response = {};
        response.data = res.data;
        response.totalItems = parseInt(res.headers['x-pagination-total']);
        return response;
      });
  },
  client(id) {
    return Api()
      .get('/clients/' + id)
      .then((res) => this.formatRes(res.data));
  },
  create(client) {
    return Api()
      .post('/clients', client)
      .then((res) => this.formatRes(res.data));
  },
  update(client) {
    return Api()
      .put('/clients/' + client.id, client)
      .then((res) => this.formatRes(res.data));
  },
  delete(client) {
    return Api()
      .delete('/clients/' + client.id)
      .then(() => client);
  },
  formatRes(e) {
    return e;
  },
};
