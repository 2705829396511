<script>
/* COMPOSANTS */
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import TextArea from 'primevue/textarea';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import reservationTypesConst from '@mixins/const/reservationTypesConst';

/* SERVICES */

export default {
  components: {
    InputText,
    Dropdown,
    TextArea,
  },
  mixins: [permissionsMixin, utilsMixin, reservationTypesConst],
  props: {
    modelValue: {
      required: true,
    },
  },
  data() {
    return {
      clientDisplay: '',
      clientPhoneDisplay: '',
      clientEmailDisplay: '',
      dateCreated: new Date().toLocaleDateString(),
      dateStart: '',
      dateEnd: '',
      dateCheckin: '',
      dateCheckout: '',
      ownerDisplay: '',
      previousReservation: {},
      previousDateCheckin: '',
      previousDateCheckout: '',
      previousDateStartLitige: '',
      previousDateEndLitige: '',
      nextReservation: {},
      nextDateCheckin: '',
      nextDateCheckout: '',
    };
  },
  mounted() {
    if (this.hasClient) {
      this.clientDisplay = this.currentValue.client.firstName + ' ' + this.currentValue.client.lastName;
      this.clientPhoneDisplay = this.currentValue.client.phone;
      this.clientEmailDisplay = this.currentValue.client.email;
    }

    this.currentValue.type = this.displayForReservationTypeConst(this.currentValue.type);
    this.ownerDisplay = `${this.currentValue.accommodation.ownerFirstName} ${this.currentValue.accommodation.ownerLastName}`;
    this.dateCreated = new Date(this.currentValue.dateCreated).toLocaleDateString();
    this.dateStart = new Date(this.currentValue.dateStart).toLocaleDateString();
    this.dateEnd = new Date(this.currentValue.dateEnd).toLocaleDateString();
    if (this.currentValue.dateCheckin) {
      this.dateCheckin = new Date(this.currentValue.dateCheckin).toLocaleDateString();
    }
    if (this.currentValue.dateCheckout) {
      this.dateCheckout = new Date(this.currentValue.dateCheckout).toLocaleDateString();
    }

    if (this.hasPreviousReservation) {
      this.previousReservation = this.currentValue.previousReservation;
      this.previousReservation.type = this.displayForReservationTypeConst(this.previousReservation.type);
      this.previousDateCheckin = this.formatDateIfNeeded(this.previousReservation.dateCheckin);
      this.previousDateCheckout = this.formatDateIfNeeded(this.previousReservation.dateCheckout);
      if (this.hasPreviousLitiges) {
        this.previousDateStartLitige = this.formatDateIfNeeded(this.previousReservation.dateStartLitige);
        this.previousDateEndLitige = this.formatDateIfNeeded(this.previousReservation.dateEndLitige);
      }
    }
    if (this.hasNextReservation) {
      this.nextReservation = this.currentValue.nextReservation;
      this.nextReservation.type = this.displayForReservationTypeConst(this.nextReservation.type);
      this.nextDateCheckin = this.formatDateIfNeeded(this.nextReservation.dateCheckin);
      this.nextDateCheckout = this.formatDateIfNeeded(this.nextReservation.dateCheckout);
    }
  },
  methods: {},
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
    },
    hasClient() {
      return this.currentValue.client;
    },
    hasPreviousReservation() {
      return this.currentValue.previousReservation && this.currentValue.previousReservation.id !== null;
    },
    hasNextReservation() {
      return this.currentValue.nextReservation && this.currentValue.nextReservation.id !== null;
    },
    hasPreviousLitiges() {
      return this.hasPreviousReservation && this.currentValue.previousReservation.litiges !== null;
    },
    hasPreviousComment() {
      return this.hasPreviousReservation && this.currentValue.previousReservation.comment !== null;
    },
  },
};
</script>

<template>
  <div class="prestataire-details">
    <!-- RESERVATION PRECEDENTE -->
    <div class="section" v-if="this.hasPreviousReservation">
      <h6 class="mt-2">{{ this.$t('planification.last_reservation') }}</h6>
      <div>
        <div class="input-item">
          <span>{{ this.$t('reservation.number') }}</span>
          <InputText v-model="previousReservation.reservationNum" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('reservation.dateCheckin') }}</span>
          <InputText v-model="previousDateCheckin" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('reservation.dateCheckout') }}</span>
          <InputText v-model="previousDateCheckout" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('reservation.type') }}</span>
          <InputText v-model="previousReservation.type" disabled />
        </div>
      </div>
      <div v-if="hasPreviousComment">
        <div class="input-item">
          <span>{{ this.$t('planification.last_comment_prestataire') }}</span>
          <TextArea auto-resize rows="4" v-model="previousReservation.comment" disabled />
        </div>
      </div>

      <!-- LITIGE RESERVATION -->

      <article v-if="hasPreviousLitiges">
        <div class="section-no-pad" v-for="(litige, idx) in previousReservation.litiges" :key="litige.id">
          <h6 class="mt-2">Litige n°{{ idx + 1 }}</h6>
          <div>
            <div class="input-item">
              <span>{{ this.$t('litige.status') }}</span>
              <Dropdown
                v-model="litige.status"
                :label="$t('maintenance.status')"
                :options="litigeStatusConst"
                option-label="label"
                option-value="value"
                class="form-control mr-sm-2 border-grey"
                :disabled="true" />
            </div>
          </div>
          <article class="input-item">
            <span>{{ this.$t('litige.description') }}</span>
            <TextArea auto-resize rows="4" v-model="litige.description" disabled />
          </article>
        </div>
      </article>
    </div>

    <!-- RESERVATION ACTUELLE-->

    <div class="section">
      <h6 class="mt-2">{{ this.$t('planification.reservation') }}</h6>
      <div>
        <div class="input-item">
          <span>{{ this.$t('mission_order.reservation') }}</span>
          <InputText v-model="currentValue.reservationNum" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('reservation.type') }}</span>
          <InputText v-model="currentValue.type" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('mission_order_details.planned_mission_duration') }}</span>
          <InputText v-model="currentValue.plannedMissionDuration" disabled />
        </div>
      </div>
      <div>
        <div class="input-item">
          <span>{{ this.$t('planification.date_created_reservation') }}</span>
          <InputText v-model="dateCreated" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('reservation.dateCheckin') }}</span>
          <InputText v-model="dateCheckin" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('reservation.dateCheckout') }}</span>
          <InputText v-model="dateCheckout" disabled />
        </div>
      </div>
      <div>
        <div class="input-item">
          <span>{{ this.$t('accommodation.name') }}</span>
          <InputText v-model="currentValue.accommodation.name" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('planification.owner') }}</span>
          <InputText v-model="ownerDisplay" disabled />
        </div>
        <div class="input-item" v-if="this.isGas()">
          <span>{{ this.$t('prospect.company') }}</span>
          <InputText v-model="currentValue.accommodation.companyName" disabled />
        </div>
      </div>
      <div>
        <div class="input-item">
          <span>{{ this.$t('planification.accommodation_address') }}</span>
          <InputText v-model="currentValue.accommodation.address" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('accommodation.postalCode') }}</span>
          <InputText v-model="currentValue.accommodation.postalCode" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('accommodation.city') }}</span>
          <InputText v-model="currentValue.accommodation.city" disabled />
        </div>
      </div>
      <div>
        <div class="input-item">
          <span>{{ this.$t('reservation.guestName') }}</span>
          <InputText v-model="clientDisplay" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('reservation.guestPhone') }}</span>
          <InputText v-model="clientPhoneDisplay" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('client.email') }}</span>
          <InputText v-model="clientEmailDisplay" disabled />
        </div>
      </div>
    </div>

    <!-- RESERVATION SUIVANTE -->

    <div class="section" v-if="this.hasNextReservation">
      <h6 class="mt-2">{{ this.$t('planification.next_reservation') }}</h6>
      <div>
        <div class="input-item">
          <span>{{ this.$t('reservation.number') }}</span>
          <InputText v-model="nextReservation.reservationNum" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('reservation.dateCheckin') }}</span>
          <InputText v-model="nextDateCheckin" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('reservation.dateCheckout') }}</span>
          <InputText v-model="nextDateCheckout" disabled />
        </div>
        <div class="input-item">
          <span>{{ this.$t('reservation.type') }}</span>
          <InputText v-model="nextReservation.type" disabled />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.prestataire-details {
  .section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    background-color: #ffffff;
    border-radius: 16px;
    margin-bottom: 24px;
    gap: 16px;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 40px;
    }
  }

  .input-item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 6px;

    > span {
      font-family: 'Nunito';
      font-weight: 600;
      font-size: 14px;
      color: #344054;
    }

    > div {
      display: flex;
      flex-direction: row;
      gap: 8px;

      > label {
        font-family: 'Nunito';
        font-weight: 600;
        font-size: 14px;
        color: #344054;
        margin-right: 20px;
      }
    }
  }

  .title {
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 16px;
    color: #344054;
  }

  .p-inputtext {
    width: auto !important;
    height: 40px;
  }

  .date-input {
    border: solid 1px #d0d5dd;
    border-radius: 8px;
    padding: 0 16px;
    box-sizing: border-box;
  }

  .border-grey {
    border-color: #d0d5dd;
  }

  .p-fileupload {
    width: 100%;
  }

  .btn-create {
    height: 44px;
    background-color: #0b3575;
    color: #ffffff;
  }

  .col-4 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .file-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start !important;
    align-items: center;
    gap: 15px !important;
  }

  .svg-container {
    width: fit-content;
  }

  .clickable {
    cursor: pointer;
  }
}
</style>
