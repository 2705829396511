<script>
import moment from 'moment/moment';

/* COMPOSANTS */
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Checkbox from 'primevue/checkbox';
import {getDayShort, getMonthShort} from '@utils/dateTime';
import PrestationBadge from '@/components/general/PrestationBadge';

/* MIXINS */
import prestationTypesConst from '@mixins/const/prestationTypesConst';
import prestationStatusFilterConst from '@mixins/const/prestationStatusConst';
import personnelTypeConst from '@mixins/const/personnelTypeConst';
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';
import missionMixin from '@mixins/missionMixin';

/* SERVICES */
import accommodationService from '@/services/accommodationService';
import companyService from '@/services/companyService';

export default {
  components: {
    InputText,
    DataTable,
    Column,
    Checkbox,
    PrestationBadge,
  },
  mixins: [
    prestationTypesConst,
    prestationStatusFilterConst,
    personnelTypeConst,
    permissionsMixin,
    utilsMixin,
    missionMixin,
    messageMixin,
  ],
  data() {
    return {
      loading: false,
      planninglist: [],
      selectedStatus: ['ACCEPTED', 'PROPOSED', 'CANCELED', 'COMPLETED', 'LITIGATION'],
      selectedPrestationTypes: [],
      selectedPersonnelType: [],
      filterSidebarOpen: false,
      dates: [],
      textSearch: null,
      companies: [],
      chosenCompanies: [],
      filtersChosen: null,
    };
  },
  metaInfo() {
    return {
      title: 'planning_accommodation.title',
    };
  },
  computed: {
    prestationTypesList() {
      return this.prestationTypesConst.slice(0, -2);
    },
    prestationStatusList() {
      return this.prestationStatusFilterConst.slice(0, -1);
    },
    nbActiveFilters() {
      return (
        this.prestationStatusList.length -
        this.selectedPersonnelType.length +
        this.selectedStatus.length +
        this.selectedPrestationTypes.length +
        (this.chosenCompanies.length && this.isGas())
      );
    },
    isFilterActive() {
      return this.nbActiveFilters > 0;
    },
  },
  mounted() {
    this.dates = this.getDatesStrOfTheWeek(moment().weekday(0));
    this.loadCompanies();
    this.refresh();
  },
  watch: {
    dates: function () {
      this.refresh();
    },
    selectedPrestationTypes: function () {
      this.refresh();
    },
    selectedPersonnelType() {
      this.refresh();
    },
    selectedStatus() {
      this.refresh();
    },
    chosenCompanies() {
      this.refresh();
    },
  },
  methods: {
    loadCompanies() {
      companyService
        .companies()
        .then((data) => {
          this.companies = data;
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        });
    },
    refresh() {
      const queryTypes = [...this.selectedPrestationTypes];
      this.openFilters = false;
      this.loading = true;
      this.filter = {
        dateStart: this.dateToUtc(this.dates[0]),
        dateEnd: this.dateToUtcEndOfDay(this.dates[6]),
        types: queryTypes,
        statusList: this.selectedStatus,
        query: this.textSearch,
        assignedStaffs: this.selectedPersonnelType,
      };
      if (this.isGas()) this.filter.companyIds = this.chosenCompanies;

      accommodationService
        .planning(this.filter)
        .then((data) => {
          this.planninglist = data;
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onPreviousWeekClick() {
      this.dates = this.getDatesStrOfTheWeek(this.addDays(moment(this.dates[0]), -7));
    },
    onNextWeekClick() {
      this.dates = this.getDatesStrOfTheWeek(this.addDays(moment(this.dates[0]), 7));
    },
    toggleFilterSidebar() {
      this.filterSidebarOpen = !this.filterSidebarOpen;
    },
    submitQuery() {
      this.refresh();
    },
    getDayShort,
    getMonthShort,
  },
};
</script>

<template>
  <div class="h-100 d-flex flex-column planning">
    <GaTitleMenu :with-bullet="false">
      <GaItemTitle :active="true" :title="$t('planning_accommodation.title')" />
    </GaTitleMenu>
    <div class="d-flex align-items-center mb-20 mt-2">
      <button type="button" class="gd-btn-white mr-1" aria-label="previous week" @click="onPreviousWeekClick">
        <div class="gd-btn-white__svg">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.33333 4.16669L2.5 9.99996M2.5 9.99996L8.33333 15.8333M2.5 9.99996H17.5"
              stroke="#2F384C"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </div>
        <span>{{ $t('planning_accommodation.last_week') }}</span>
      </button>
      <button type="button" class="gd-btn-white" aria-label="next week" @click="onNextWeekClick">
        <div class="gd-btn-white__svg">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.5 9.99996H17.5M17.5 9.99996L11.6667 4.16669M17.5 9.99996L11.6667 15.8333"
              stroke="#2F384C"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </div>
        <span>{{ $t('planning_accommodation.next_week') }}</span>
      </button>
      <form class="p-input-icon-left ml-auto" @submit.prevent="submitQuery">
        <i class="pi pi-search" />
        <InputText :placeholder="$t('planning_accommodation.search')" v-model="textSearch" />
      </form>
      <button type="button" class="gd-btn-filter" :class="isFilterActive && 'active'" @click="toggleFilterSidebar">
        <div class="gd-btn-filter__svg">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
              stroke="currentColor"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </div>
        <span v-if="isFilterActive">{{ nbActiveFilters }} filtres actifs</span>
        <span v-else>{{ $t('planning_accommodation.filter') }}</span>
      </button>
    </div>
    <Sidebar v-model:visible="filterSidebarOpen" position="right" class="gd-filter-sidebar">
      <h2>Filtres</h2>
      <p>Appliquer des filtres</p>
      <div class="filter-category">
        <h3>{{ $t('planning_accommodation.assigned_staff') }}</h3>
        <div>
          <div v-for="personnelType in personnelTypeConst" :key="personnelType.value" class="filter-item">
            <Checkbox
              v-model="selectedPersonnelType"
              :inputId="personnelType.value"
              name="personnelType"
              :value="personnelType.value" />
            <label :for="personnelType.value">{{ personnelType.label }}</label>
          </div>
        </div>
      </div>
      <div class="filter-category">
        <h3>{{ $t('planning_accommodation.status') }}</h3>
        <div>
          <div v-for="status in prestationStatusList" :key="status.value" class="filter-item">
            <Checkbox v-model="selectedStatus" :inputId="status.value" name="status" :value="status.value" />
            <label :for="status.value">{{ status.label }}</label>
          </div>
        </div>
      </div>
      <div class="filter-category" v-if="this.isGas()">
        <h3>{{ $t('planning_accommodation.company') }}</h3>
        <div>
          <div v-for="company in companies" :key="company.id" class="filter-item">
            <Checkbox v-model="chosenCompanies" :inputId="company.id" name="companies" :value="company.id" />
            <label :for="company.id">{{ company.name }}</label>
          </div>
        </div>
      </div>
      <div class="filter-category">
        <h3>{{ $t('planning_accommodation.mission_type') }}</h3>
        <div>
          <div v-for="prestationType in prestationTypesList" :key="prestationType.value" class="filter-item">
            <Checkbox
              v-model="selectedPrestationTypes"
              :inputId="prestationType.value"
              name="prestationType"
              :value="prestationType.value" />
            <PrestationBadge :type="prestationType.value" />
          </div>
        </div>
      </div>
    </Sidebar>
    <div class="content-list">
      <DataTable
        :value="planninglist"
        :paginator="true"
        :always-show-paginator="false"
        :rows="10"
        :rows-per-page-options="[10, 20, 50]"
        :loading="loading"
        class="table pointer gd-datatable"
        current-page-report-template="{first}-{last}/{totalRecords}"
        removable-sort
        responsive-layout="scroll"
        striped-rows>
        <Column field="name" :header="$t('planning_accommodation.accommodation_name')"></Column>

        <Column v-for="date in dates" :key="date.toString()" :field="date.toString()">
          <template #header>
            <div class="d-flex justify-content-around align-items-center w-100 px-1">
              <span v-text="getDayShort(date)"></span>
              <span class="date-badge bg-primary" v-text="new Date(date).getDate()"></span>
              <span v-text="getMonthShort(date)"></span>
            </div>
          </template>
          <template #body="slotProps">
            <a
              v-for="prestation in slotProps.data.dateMap[dateToIndex(date)]"
              :key="prestation.id"
              class="task"
              :class="getTaskBackground(prestation)"
              :href="`/mission-order/${prestation.id}`">
              <span>
                <i class="ti ti-id-badge f-22" v-if="isAssignedStaffPrestataire(prestation)" />
                <i class="ti ti-home f-22" v-if="isAssignedStaffHousekeeper(prestation)" />
              </span>
              <span v-text="displayPrestationTime(prestation)"></span>
              <span v-text="displayForPrestationTypeConst(prestation.type)"></span>
            </a>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.planning {
  th {
    padding: 13px 0;

    &:first-child {
      width: 133px !important;
      padding: 13px 24px;
    }
  }

  .p-datatable .p-datatable-tbody > tr > td {
    padding: 4px;
    font: 600 14px/20px 'Nunito Sans';
    color: #475467;

    &:first-child {
      text-align: center;
      padding: 8px;
    }

    &:not(:first-child) {
      vertical-align: top;
    }
  }

  .p-input-icon-left {
    display: flex;
    align-items: center;
  }

  .p-inputtext {
    padding-left: 42px;
  }

  .pi-search {
    left: 14px !important;
  }

  .date-badge {
    border-radius: 32px;
    padding: 5px 8px;
    font: 600 12px/18px 'Nunito Sans';
    color: white;
  }

  .task {
    padding: 8px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    > span {
      font: 400 14px/20px 'Nunito Sans';
      color: white;
    }
  }
}
</style>
