<script>
/* COMPOSANTS */
import PButton from 'primevue/button';

/* MIXINS */
import loaderMixin from '@mixins/loaderMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import filterMixin from '@mixins/filterMixin';
import reservationTypesConst from '@mixins/const/reservationTypesConst';

/* SERVICES */
import missionDashboardService from '@services/missionDashboardService';

export default {
  components: {
    PButton,
  },
  mixins: [filterMixin, loaderMixin, permissionsMixin, randomRef, reservationTypesConst, sidebarMixin, utilsMixin],
  metaInfo() {
    return {
      title: 'mission_dashboard.title',
    };
  },
  data() {
    return {
      loading: false,

      // LIST A TRAITER
      list1: [],
      totalRecords1: 0,
      page1: 1,
      limit1: 10,

      // LIST PLANIFIE
      list2: [],
      totalRecords2: 0,
      page2: 1,
      limit2: 10,

      filter: {
        dateStart: new Date(),
        dateEnd: new Date(),
      },
    };
  },
  mounted() {},
  computed: {
    first1() {
      return (this.page1 - 1) * this.limit1;
    },
    first2() {
      return (this.page2 - 1) * this.limit2;
    },
    filter1() {
      let f = {...this.filter};
      f.page = this.page1;
      f.limit = this.limit1;
      return f;
    },
    filter2() {
      let f = {...this.filter};
      f.page = this.page2;
      f.limit = this.limit2;
      return f;
    },
  },

  methods: {
    refresh() {
      this.loading = true;

      let p1 = missionDashboardService.paginationATraiter(this.filter1);
      p1.then((data) => {
        this.list1 = data.data;
        this.totalRecords1 = data.totalItems;
      }).catch((e) => {
        this.addError(e.response.data.error);
      });

      let p2 = missionDashboardService.paginationPlanifie(this.filter2);
      p2.then((data) => {
        this.list2 = data.data;
        this.totalRecords2 = data.totalItems;
      }).catch((e) => {
        this.addError(e.response.data.error);
      });

      Promise.all([p1, p2]).then(() => {
        this.loading = false;
      });
    },
    openFilterPanel() {
      this.toggleFilter('GaMissionDashboardFilter', this.filter, this.refresh, this.resetFilter);
    },
    resetFilter() {
      this.page1 = 1;
      this.page2 = 1;
      this.limit1 = 10;
      this.limit2 = 10;

      this.filter = {};
      this.toggleFilter('GaMissionDashboardFilter', this.filter, this.refresh, this.resetFilter);
    },
    onPage1(event) {
      this.page1 = event.page + 1;
      this.limit1 = event.rows;
      this.refresh();
    },
    onPage2(event) {
      this.page2 = event.page + 1;
      this.limit2 = event.rows;
      this.refresh();
    },
  },
  watch: {},
};
</script>

<template>
  <GaView :ref="ref" :title="$t('mission_dashboard.title')">
    <template #action>
      <div class="d-flex justify-content-end" style="gap: 16px">
        <PButton
          class="btn btn-inverse-primary btn-xs"
          :badge="this.countFilter(this.filter)"
          badgeClass="p-badge-primary"
          label="Filtrer"
          @click="openFilterPanel()">
        </PButton>
      </div>
    </template>

    <template #content>
      <h5>Reservations à traiter</h5>
      <DataTable
        :always-show-paginator="false"
        :paginator="true"
        :rows="10"
        :rows-per-page-options="[10, 20, 50]"
        :first="this.first1"
        :value="list1"
        lazy
        :loading="loading"
        :totalRecords="totalRecords1"
        class="table pointer"
        current-page-report-template="{first}-{last}/{totalRecords}"
        removable-sort
        responsive-layout="scroll"
        striped-rows
        @page="onPage1($event)">
        <template #empty>Aucune réservation</template>

        <Column header="Numéro réservation">
          <template #body="slotProps">
            <router-link
              :to="{
                name: 'accommodationReservationPage',
                params: {accommodationId: slotProps.data.accommodationId, reservationId: slotProps.data.id},
              }">
              <span class="text-break">{{ slotProps.data.reservationNum }}</span>
            </router-link>
          </template>
        </Column>

        <Column header="Nom du logement">
          <template #body="slotProps">
            {{ slotProps.data.accommodationDisplay }}
          </template>
        </Column>

        <Column header="Agence">
          <template #body="slotProps">
            {{ slotProps.data.companyDisplay }}
          </template>
        </Column>

        <Column header="Type">
          <template #body="slotProps">
            {{ displayForReservationTypeConst(slotProps.data.type) }}
          </template>
        </Column>

        <Column header="Date de début">
          <template #body="slotProps">
            {{ displayDate(slotProps.data.dateStart) }}
          </template>
        </Column>

        <Column header="Date de fin">
          <template #body="slotProps">
            {{ displayDate(slotProps.data.dateEnd) }}
          </template>
        </Column>

        <Column header="Nombre de missions manquantes">
          <template #body="slotProps">
            {{ slotProps.data.prestationNb }}
          </template>
        </Column>
      </DataTable>

      <h5 class="mt-3">Reservations planifiées</h5>
      <DataTable
        :always-show-paginator="false"
        :paginator="true"
        :rows="10"
        :rows-per-page-options="[10, 20, 50]"
        :first="this.first2"
        :value="list2"
        lazy
        :loading="loading"
        :totalRecords="totalRecords2"
        class="table pointer"
        current-page-report-template="{first}-{last}/{totalRecords}"
        removable-sort
        responsive-layout="scroll"
        striped-rows
        @page="onPage2($event)">
        <template #empty>Aucune réservation</template>

        <Column header="Numéro réservation">
          <template #body="slotProps">
            <router-link
              :to="{
                name: 'accommodationReservationPage',
                params: {accommodationId: slotProps.data.accommodationId, reservationId: slotProps.data.id},
              }">
              <span class="text-break">{{ slotProps.data.reservationNum }}</span>
            </router-link>
          </template>
        </Column>

        <Column header="Nom du logement">
          <template #body="slotProps">
            {{ slotProps.data.accommodationDisplay }}
          </template>
        </Column>

        <Column header="Agence">
          <template #body="slotProps">
            {{ slotProps.data.companyDisplay }}
          </template>
        </Column>

        <Column header="Type">
          <template #body="slotProps">
            {{ displayForReservationTypeConst(slotProps.data.type) }}
          </template>
        </Column>

        <Column header="Date de début">
          <template #body="slotProps">
            {{ displayDate(slotProps.data.dateStart) }}
          </template>
        </Column>

        <Column header="Date de fin">
          <template #body="slotProps">
            {{ displayDate(slotProps.data.dateEnd) }}
          </template>
        </Column>

        <Column header="Nombre de missions planifiées">
          <template #body="slotProps">
            {{ slotProps.data.prestationNb }}
          </template>
        </Column>
      </DataTable>
    </template>
  </GaView>
</template>
