<script>
import {helpers, required} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
/* COMPOSANTS */
import AkInputText from '@components/input/AkInputText.vue';
import GaView from '@components/layout/GaView.vue';
import AkDialog from '@components/general/AkDialog.vue';
import AkChips from '@components/input/AkChips.vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import stayRateService from '@services/stayRateService';
import AkDropdown from '@components/input/AkDropdown.vue';
import transfertFrequencyConst from '@mixins/const/transfertFrequencyConst';
import AkCheckboxButton from '@components/input/AkCheckboxButton.vue';
import permissionsMixin from '@mixins/permissionsMixin';
import {PermissionMap} from '@mixins/const/permissionConst';

const IBAN = require('iban');
const ibanValidator = (value) => !value || IBAN.isValid(value);

const BIC = require('bic');
const bicValidator = (value) => !value || BIC.isValid(value);

export default {
  components: {
    AkCheckboxButton,
    AkDropdown,
    AkChips,
    AkDialog,
    AkInputText,
    GaView,
  },
  mixins: [loaderMixin, messageMixin, randomRef, utilsMixin, permissionsMixin, transfertFrequencyConst],
  metaInfo() {
    return {
      title: 'accounting.stay_rate',
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      list: [],
      errors: [],
      loading: false,
      item: {},
      regionalConst: [
        {label: this.$t('stayrate.city'), value: false},
        {label: this.$t('stayrate.regional'), value: true},
      ],
      tmpAccount: '',
    };
  },
  validations() {
    return {
      item: {
        label: {required},
        codes: {required},
        regional: {required},
        account: {required},
        avantioLabel: {required},
        iban: {
          ibanValidator: helpers.withMessage('Vérifier le format du numéro IBAN', ibanValidator),
        },
        bic: {
          bicValidator: helpers.withMessage('Vérifier le format du numéro BIC', bicValidator),
        },
      },
    };
  },
  mounted() {
    this.loading = true;
    //this.$refs.loader.load();
    stayRateService.stayrates().then((data) => {
      this.list = data;
      this.loading = false;
      //this.$refs.loader.hide();
    });
  },
  methods: {
    create() {
      this.v$.$touch();
      if (this.v$.$error) return;

      this.errors = [];
      for (let stayrate of this.list) {
        if (stayrate.account === this.item.account) {
          this.errors.push(this.$t('account.already_exist'));
          //this.addError(this.$t('account.already_exist', true, true));
          return;
        }
      }

      this.loading = true;
      this.item.codes = this.item.codes.toString();

      stayRateService
        .create(this.item)
        .then((data) => {
          this.list.push(data);
          this.item = {};
          this.success(this.$t('stayrate.added'));
          this.$refs.dialogCreate.hide();
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 412) {
              this.addError(this.$t('account.already_exist'), true, true);
            } else {
              this.addError(this.$t('error.' + e.response.data.error));
            }
          }
        })
        .finally(() => {
          //this.hideLoader();
          this.loading = false;
        });
    },
    update() {
      this.v$.$touch();
      if (this.v$.$error) return;

      this.errors = [];
      if (this.tmpAccount !== this.item.account) {
        for (let stayrate of this.list) {
          if (stayrate.account === this.item.account) {
            this.errors.push(this.$t('account.already_exist'));
            //this.addError(this.$t('account.already_exist', true, true));
            return;
          }
        }
      }

      this.loading = true;
      //this.showTotalLoader();
      this.item.codes = this.item.codes.toString();

      stayRateService
        .update(this.item)
        .then((data) => {
          this.replaceInListById(this.list, data);
          this.success(this.$t('stayrate.updated'));
          this.$refs.dialogEdit.hide();
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 412) {
              this.addError(this.$t('account.already_exist'), true, true);
            } else {
              this.addError(this.$t('error.' + e.response.data.error));
            }
          }
        })
        .finally(() => {
          //this.hideLoader();
          this.loading = false;
        });
    },
    delete() {
      stayRateService
        .delete(this.item)
        .then((data) => {
          this.loading = true;
          this.removeInListById(this.list, data.id);
          this.success(this.item.label + ' a bien été supprimé');
        })
        .catch((e) => {
          let content = 'Une erreur est survenue durant la suppression';
          if (e.response) {
            content = 'Une erreur est survenue durant la suppression : ' + e.response.data.error;
          }
          this.addError(content);
        })
        .finally(() => {
          //this.hideLoader();
          this.loading = false;
        });
    },
    openCreateDialog() {
      this.errors = [];
      this.item = {};
      this.item.regional = false;
      this.$refs.dialogCreate.show();
    },
    openEditDialog(item) {
      this.errors = [];
      this.tmpAccount = item.account;
      this.item = {...item};
      if (this.item.codes) {
        this.item.codes = this.item.codes.split(',');
      }
      this.$refs.dialogEdit.show();
    },
    openDeleteDialog(item) {
      this.item = item;
      this.$refs.dialogDelete.show();
    },
    typeToDisplay(regional) {
      return regional ? this.$t('stayrate.regional') : this.$t('stayrate.city');
    },
  },
  computed: {
    hasManagePermission() {
      return this.hasPermission(PermissionMap.TDS_GROUP.MANAGE);
    },
  },
};
</script>

<template>
  <!--<AkLoader
      ref="loader"
      :message="$t('currently_loading')"
      :scale="1.5"
  >
    <template #content>-->
  <GaView :ref="ref" :title="$t('accounting.stay_rate')">
    <template #action>
      <button v-if="hasManagePermission" class="btn btn-primary float-right" @click="openCreateDialog()">
        <i class="gst-plus pr-1" />{{ $t('add') }}
      </button>
    </template>

    <template #content>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive">
                <DataTable
                  :always-show-paginator="false"
                  :paginator="true"
                  :rows="10"
                  :rows-per-page-options="[10, 20, 50]"
                  :loading="loading"
                  :value="list"
                  class="table"
                  current-page-report-template="{first}-{last}/{totalRecords}"
                  removable-sort
                  responsive-layout="scroll"
                  sort-field="position"
                  striped-rows>
                  <template #empty>
                    {{ $t('stayrate.empty') }}
                  </template>

                  <Column :header="$t('item_label.label')" field="label"></Column>
                  <Column :header="$t('stayrate.account')" field="account"></Column>
                  <Column :header="$t('stayrate.codes')" field="codes"></Column>
                  <Column :header="$t('stayrate.type')" field="regional">
                    <template #body="slotProps">
                      {{ typeToDisplay(slotProps.data.regional) }}
                    </template>
                  </Column>
                  <Column
                    v-if="hasManagePermission"
                    body-style="text-align: right; overflow: visible"
                    header-style=" width: 160px; text-align: btn">
                    <template #body="slotProps">
                      <div class="d-flex justify-content-end">
                        <span
                          class="btn btn-xs btn-inverse-primary mr-1 pointer"
                          @click="openEditDialog(slotProps.data)">
                          <i class="fe fe-edit" />
                        </span>
                        <span class="btn btn-xs btn-inverse-danger pointer" @click="openDeleteDialog(slotProps.data)">
                          <i class="fe fe-trash" />
                        </span>
                      </div>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #extra>
      <AkDialog
        ref="dialogCreate"
        :title="$t('stayrate.add')"
        :validate-label="$t('add')"
        width="500px"
        @validate="create()">
        <div class="form-row">
          <AkInputText
            v-model="item.label"
            :label="$t('stayrate.label')"
            :validator="v$.item.label"
            class-name="col-12" />
        </div>
        <div class="form-row">
          <AkChips
            v-model="item.codes"
            :placeholder="$t('stayrate.codes')"
            :label="$t('stayrate.codesChip')"
            separator=","
            :validator="v$.item.codes"
            class-name="col-12" />
          <AkCheckboxButton
            v-model="item.regional"
            :multiple="false"
            :options="regionalConst"
            :label="$t('stayrate.type')"
            :validator="v$.item.regional" />
        </div>
        <div class="form-row">
          <p v-if="errors.length">
            <b v-for="(error, index) in errors" :key="index" style="color: red">
              {{ error }}
            </b>
          </p>
          <AkInputText
            v-model="item.account"
            :label="$t('stayrate.account')"
            :validator="v$.item.account"
            class-name="col-12" />
          <AkInputText
            v-model="item.avantioLabel"
            :label="$t('stayrate.avantio_label')"
            :validator="v$.item.avantioLabel"
            class-name="col-12" />
          <AkDropdown
            v-model="item.transfertFrequency"
            :label="$t('stayrate.frequency')"
            :options="this.transfertFrequencyConst"
            :required="false"
            option-value="value"
            class-name="col-12" />
          <AkInputText v-model="item.iban" :label="$t('stayrate.iban')" :validator="v$.item.iban" class-name="col-12" />
          <AkInputText v-model="item.bic" :label="$t('stayrate.bic')" :validator="v$.item.bic" class-name="col-12" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogEdit"
        :title="$t('stayrate.update')"
        :validate-label="$t('update')"
        width="450px"
        @validate="update()">
        <div class="form-row">
          <AkInputText
            v-model="item.label"
            :label="$t('stayrate.label')"
            :validator="v$.item.label"
            class-name="col-12" />
        </div>
        <div class="form-row">
          <AkChips
            v-model="item.codes"
            :placeholder="$t('stayrate.codes')"
            :label="$t('stayrate.codesChip')"
            separator=","
            :validator="v$.item.codes"
            class-name="col-12" />
          <AkCheckboxButton
            v-model="item.regional"
            :multiple="false"
            :options="regionalConst"
            :label="$t('stayrate.type')"
            :validator="v$.item.regional" />
        </div>
        <div class="form-row">
          <p v-if="errors.length">
            <b v-for="(error, index) in errors" :key="index" style="color: red">
              {{ error }}
            </b>
          </p>
          <AkInputText v-model="item.account" :label="$t('stayrate.account')" class-name="col-12" />
          <AkInputText
            v-model="item.avantioLabel"
            :label="$t('stayrate.avantio_label')"
            :validator="v$.item.avantioLabel"
            class-name="col-12" />
          <AkDropdown
            v-model="item.transfertFrequency"
            :label="$t('stayrate.frequency')"
            :options="this.transfertFrequencyConst"
            :required="false"
            option-value="value"
            class-name="col-12" />
          <AkInputText v-model="item.iban" :label="$t('stayrate.iban')" :validator="v$.item.iban" class-name="col-12" />
          <AkInputText v-model="item.bic" :label="$t('stayrate.bic')" :validator="v$.item.bic" class-name="col-12" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogDelete"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.delete()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span>{{ $t('stayrate.confirm_delete') }}</span>
        </div>
      </AkDialog>
    </template>
  </GaView>
  <!--</template>
</AkLoader>-->
</template>
