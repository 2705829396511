<script>
import AkLabel from '@components/general/AkLabel';
import InputText from 'primevue/inputtext';

export default {
  components: {AkLabel, InputText},
  inject: {pSubmitted: {default: null}, pDisabled: {default: null}},
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    className: {
      type: String,
      required: false,
      default: 'col-md-4 col-6',
    },
    icon: {
      type: String,
      required: true,
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    hasValidator() {
      return this.validator !== undefined;
    },
    isInvalid() {
      return this.hasValidator && this.validator.$invalid;
    },

    isRequired() {
      return this.hasValidator && this.validator.required !== undefined;
    },
    isRequiredFailed() {
      return this.isRequired && this.validator.required.$invalid && this.alreadySubmitted;
    },

    isMinLength() {
      return this.hasValidator && this.validator.minLengthValue !== undefined;
    },
    isMinLengthFailed() {
      return this.isMinLength && this.validator.minLengthValue.$invalid && this.alreadySubmitted;
    },

    isMaxLength() {
      return this.hasValidator && this.validator.maxLengthValue !== undefined;
    },
    isMaxLengthFailed() {
      return this.isMaxLength && this.validator.maxLengthValue.$invalid && this.alreadySubmitted;
    },

    isMinMaxLength() {
      return this.isMinLength && this.isMaxLength;
    },
    isMinMaxLengthFailed() {
      return this.isMinMaxLength && (this.isMinLengthFailed || this.isMaxLengthFailed);
    },

    isEmail() {
      return this.hasValidator && this.validator.email !== undefined;
    },
    isEmailFailed() {
      return this.isEmail && this.validator.email.$invalid && this.alreadySubmitted;
    },

    isPhone() {
      return this.hasValidator && this.validator.phoneValidator !== undefined;
    },
    isPhoneFailed() {
      return this.isPhone && this.validator.phoneValidator.$invalid && this.alreadySubmitted;
    },

    isNotUsed() {
      return this.hasValidator && this.validator.notUsed !== undefined;
    },
    isNotUsedFailed() {
      return this.isNotUsed && this.validator.notUsed.$invalid && this.alreadySubmitted;
    },
  },
  methods: {},
};
</script>

<template>
  <div :class="className" class="form-group">
    <AkLabel :required="isRequired">
      {{ label }}
    </AkLabel>

    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i :class="icon" />
      </span>
      <InputText
        v-model="currentValue"
        :class="{'p-invalid': isInvalid && alreadySubmitted}"
        :disabled="isDisabled"
        :placeholder="getPlaceholder"
        class="form-control"
        type="text" />
    </div>

    <small v-if="isRequiredFailed" class="p-error">
      {{ $t('field_required', [this.label]) }}
    </small>
    <small v-if="isEmailFailed" class="p-error">
      {{ $t('bad_format', [this.label]) }}
    </small>
    <small v-if="isPhoneFailed" class="p-error">
      {{ $t('check_phone_number') }}
    </small>
    <small v-if="isNotUsedFailed" class="p-error">
      {{ $t('key_already_used') }}
    </small>
    <small v-if="isMinMaxLengthFailed" class="p-error">
      {{
        $t('min_max_length', [
          this.label,
          this.validator.minLengthValue.$params.min,
          this.validator.maxLengthValue.$params.max,
        ])
      }}
    </small>
    <small v-else-if="isMinLengthFailed" class="p-error">
      {{ $t('min_length', [this.label, this.validator.minLengthValue.$params.min]) }}
    </small>
    <small v-else-if="isMaxLengthFailed" class="p-error">
      {{ $t('max_length', [this.label, this.validator.maxLengthValue.$params.max]) }}
    </small>
  </div>
</template>
