<script>
import useVuelidate from '@vuelidate/core';
import {required, requiredIf} from '@vuelidate/validators';

/* COMPOSANTS */
import AkDropdown from '@components/input/AkDropdown.vue';
import AkCalendar from '@components/input/AkCalendar.vue';
import AkInputTextArea from '@components/input/AkInputTextArea.vue';
import AkTime from '@components/input/AkTime.vue';
import AkInputMoney from '@components/input/AkInputMoney.vue';
import AkPrestationOfferForm from '@views/operationnel/waitingorders/components/AkPrestationOfferForm.vue';
import AkUpsellForm from '@views/operationnel/waitingorders/components/AkUpsellForm.vue';

/* MIXINS */
import personnelTypeConst from '@mixins/const/personnelTypeConst';
import utilsMixin from '@mixins/utilsMixin';

export default {
  components: {AkPrestationOfferForm, AkInputMoney, AkTime, AkInputTextArea, AkCalendar, AkDropdown, AkUpsellForm},
  mixins: [personnelTypeConst, utilsMixin],
  props: {
    modelValue: {
      required: true,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    type: {
      type: String,
      required: true,
    },
    upsells: {
      type: Array,
      required: true,
      default: function () {
        return [];
      },
    },
    documentModelList: {
      type: Array,
      required: true,
      default: function () {
        return [];
      },
    },
    isAssignedToHousekeeper: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  emits: ['checked-upsell'],
  validations() {
    return {
      currentValue: {
        documentModelId: {required},
        assignedStaff: {required},
        date: {required},
        startHour: {required: requiredIf(this.isAssignedStaffPrestataire)},
        duration: {required},
        price: {required: requiredIf(this.isPriceInputAvailable)},
        description: {},
        numberOfPresta: {required: requiredIf(this.hasNumberOfPresta)},
      },
    };
  },
  mounted() {
    this.missionDocumentModelList = this.documentModelList.filter((el) => el.subType === this.currentValue.type);
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      prestataireNumberPossible: [
        {value: 1},
        {value: 2},
        {value: 3},
        {value: 4},
        {value: 5},
        {value: 6},
        {value: 7},
        {value: 8},
        {value: 9},
        {value: 10},
      ],
      missionDocumentModelList: [],
    };
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    isAssignedStaffPrestataire() {
      return this.currentValue.assignedStaff === 'PRESTATAIRE';
    },

    // TYPES
    isTypeCheckin() {
      return this.currentValue.type === 'CHECK_IN';
    },
    isTypeCheckout() {
      return this.currentValue.type === 'CHECK_OUT';
    },
    isTypeCleaning() {
      return this.currentValue.type === 'CLEANING';
    },
    isTypeCleaningBefore() {
      return this.currentValue.type === 'CLEANING_BEFORE';
    },
    isTypeCleaningAfter() {
      return this.currentValue.type === 'CLEANING_AFTER';
    },
    isTypeMaintenance() {
      return this.currentValue.type === 'MAINTENANCE';
    },
    isTypeRunning() {
      return this.currentValue.type === 'RUNNING';
    },
    isTypeQualityControl() {
      return this.currentValue.type === 'QUALITY_CONTROL';
    },

    // INPUT
    isPriceInputAvailable() {
      return (
        this.isAssignedStaffPrestataire && (this.isTypeCheckin || this.isTypeCheckout || this.isTypeQualityControl)
      );
    },
    hasNumberOfPresta() {
      return (
        this.isTypeCleaning ||
        this.isTypeCleaningAfter ||
        this.isTypeCleaningBefore ||
        this.isTypeMaintenance ||
        this.isTypeRunning
      );
    },

    personnelTypes() {
      if (!this.isAssignedToHousekeeper) return this.personnelTypeConst.slice(0, -1);
      return this.personnelTypeConst;
    },
  },
  watch: {
    submitted: function (newType) {
      this.pSubmitted = newType;
    },
    disabled: function (newType) {
      this.pDisabled = newType;
    },
    'currentValue.numberOfPresta'(newVal, oldVal) {
      let numberOfElement = newVal - oldVal;
      if (newVal > oldVal) {
        // Toujours au minimum une offre
        const price = this.currentValue.prestationOffers[0].priceHt;
        for (let i = numberOfElement; i--; i > 0) {
          this.currentValue.prestationOffers.push({priceHt: price});
        }
      } else if (newVal < oldVal) {
        this.currentValue.prestationOffers.splice(numberOfElement);
      }
    },
  },
  methods: {
    checkItem(upsell) {
      this.$emit('checked-upsell', upsell.id, upsell.missionType);
    },
    isChecked(upsell) {
      return upsell.missionType === this.currentValue.type;
    },
    getLabelForUpsell(upsell) {
      let dateCheckin = this.formatDateIfNeeded(upsell.dateCheckin);
      let dateCheckout = this.formatDateIfNeeded(upsell.dateCheckout);
      return upsell.name + ' (' + dateCheckin + ' - ' + dateCheckout + ')';
    },
  },
};
</script>

<template>
  <div class="form-row">
    <AkDropdown
      v-model="currentValue.documentModelId"
      :label="this.$t('mission_order.document_model')"
      :placeholder="this.$t('mission_order.document_model')"
      :options="missionDocumentModelList"
      option-label="name"
      option-value="id"
      :validator="v$.currentValue.documentModelId"
      className="col-md-6" />
  </div>
  <div class="form-row" v-if="this.upsells.length > 0">
    <label class="col-12">Extras</label>
    <AkUpsellForm
      v-for="upsell in this.upsells"
      :key="upsell.id"
      v-model="upsell.missionType"
      :label="getLabelForUpsell(upsell)"
      :missionType="currentValue.type"
      :checked="isChecked(upsell)"
      @checked-upsell="checkItem(upsell)" />
  </div>
  <div class="form-row">
    <AkDropdown
      v-model="currentValue.assignedStaff"
      :label="this.$t('mission_order.assigned_staff')"
      :placeholder="this.$t('mission_order.assigned_staff')"
      :options="personnelTypes"
      option-label="label"
      option-value="value"
      :validator="v$.currentValue.assignedStaff"
      class="col-md-6" />
    <AkCalendar
      v-model="currentValue.date"
      :label="this.$t('mission_order.date')"
      :placeholder="this.$t('mission_order.date')"
      :validator="v$.currentValue.date"
      date-format="dd/mm/yy"
      :min-date="new Date()"
      class="col-md-6" />
  </div>
  <div class="form-row">
    <AkTime
      v-if="isAssignedStaffPrestataire"
      v-model="currentValue.startHour"
      :label="this.$t('mission_order.start_hour')"
      :placeholder="this.$t('mission_order.start_hour')"
      :validator="v$.currentValue.startHour"
      class="col-md-6" />
    <AkTime
      v-model="currentValue.duration"
      :label="this.$t('mission_order.duration')"
      :placeholder="this.$t('mission_order.duration')"
      :validator="v$.currentValue.duration"
      class="col-md-6" />
  </div>
  <div class="form-row">
    <AkInputTextArea
      v-model="currentValue.comment"
      :label="$t('mission_order.comment')"
      :placeholder="$t('mission_order.comment')"
      :validator="v$.currentValue.description"
      class-name="col-md-12"
      :rows="4" />
  </div>
  <div class="form-row">
    <AkInputMoney
      v-if="isPriceInputAvailable"
      v-model="currentValue.price"
      :label="this.$t('mission_order.price')"
      :placeholder="this.$t('mission_order.price')"
      :min="0"
      :validator="v$.currentValue.price"
      class-name="col-md-6" />
  </div>
  <div class="form-row">
    <AkDropdown
      v-if="hasNumberOfPresta"
      v-model="currentValue.numberOfPresta"
      :label="this.$t('mission_order.number_staff')"
      :placeholder="this.$t('mission_order.number_staff')"
      :options="prestataireNumberPossible"
      option-label="value"
      option-value="value"
      :validator="v$.currentValue.numberOfPresta"
      class="col-md-12" />
  </div>
  <div class="form-row" v-for="(item, idx) in currentValue.prestationOffers" :key="item.id">
    <AkPrestationOfferForm
      v-if="hasNumberOfPresta"
      v-model="currentValue.prestationOffers[idx]"
      :index="idx"
      :showPriceInput="this.isAssignedStaffPrestataire" />
  </div>
</template>
