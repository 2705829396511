export default {
  data() {
    return {
      ownerChargeTypeConst: [
        {label: this.$t('facturation_hote.ACHAT_HOTE'), value: 'ACHAT_HOTE'},
        {label: this.$t('facturation_hote.FRAIS_DE_MAINTENANCE'), value: 'FRAIS_DE_MAINTENANCE'},
        {label: this.$t('operation.OUVERTURE_HIVERNAGE'), value: 'OUVERTURE_HIVERNAGE'},
        {label: this.$t('operation.FERMETURE_HIVERNAGE'), value: 'FERMETURE_HIVERNAGE'},
        {label: this.$t('operation.FRAIS_DE_MENAGE_HOTE'), value: 'FRAIS_DE_MENAGE_HOTE'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForOwnerChargeTypeConst(value) {
      let result = this.ownerChargeTypeConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
