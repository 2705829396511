<script>
/* COMPOSANTS */
import InputText from 'primevue/inputtext';
import AkMultiSelect from '@components/input/AkMultiSelect';

/* MIXINS */
import prestataireStatusConst from '@mixins/const/prestataireStatusConst';
import permissionsMixin from '@mixins/permissionsMixin';

/* SERVICES */
import prestataireService from '@services/prestataireService';
import companyService from '@services/companyService';

export default {
  components: {
    InputText,
    AkMultiSelect,
  },
  mixins: [permissionsMixin, prestataireStatusConst],
  metaInfo() {
    return {
      title: 'prestataire.title',
    };
  },
  data() {
    return {
      list: [],
      totalItems: 0,
      loading: true,
      companies: [],
      query: '',
      filter: {
        page: 1,
        limit: 10,
        sort: ['id:DESC'],
        query: '',
        companyIds: [],
      },
    };
  },
  mounted() {
    prestataireService.prestatairePagination(this.filter).then((data) => {
      this.list = data.data;
      this.totalItems = data.totalItems;
      this.loading = false;
    });

    if (this.isGas()) {
      companyService.companies().then((data) => {
        this.companies = data;
      });
    }
  },
  methods: {
    rowClick(event) {
      let data = event.data;
      this.$router.push({
        name: 'prestatairePage',
        params: {prestataireId: data.id},
      });
    },
    onPage(event) {
      this.filter.page = event.page + 1;
      this.filter.limit = event.rows;
      this.filter.query = this.query;
      this.search();
    },
    search() {
      this.loading = true;
      prestataireService.prestatairePagination(this.filter).then((data) => {
        this.list = data.data;
        this.totalItems = data.totalItems;
        this.loading = false;
      });
    },
    onSort(event) {
      this.filter.sort = [];
      for (let sort of event.multiSortMeta) {
        this.filter.sort.push('' + sort.field + ':' + (sort.order === -1 ? 'DESC' : 'ASC'));
      }
      if (this.filter.sort.length === 0) {
        this.filter.sort.push('id:DESC');
      }
      this.search();
    },
    submitForm() {
      this.onPage({page: 0, rows: 10});
    },
    getStatusClass(status) {
      switch (status) {
        case 'AVAILABLE':
          return 'text-success';
        case 'UNAVAILABLE':
          return 'text-danger';
        case 'IN_MISSION':
          return 'text-warning';
      }
    },
  },
};
</script>

<template>
  <GaView :title="$t('prestataire.title')" class="prestataire-list">
    <template #action>
      <div class="d-flex justify-content-end">
        <a type="button" class="btn btn-primary" href="prestataires/create">
          <i class="gst-plus pr-1" />{{ $t('add') }}
        </a>
      </div>
    </template>
    <template #search>
      <form class="form-inline search-form" @submit.prevent="submitForm">
        <InputText v-model="query" :placeholder="$t('prestataire.search')" class="form-control" type="text" />
        <AkMultiSelect
          v-if="this.isGas()"
          v-model="this.filter.companyIds"
          :label="$t('prestataire.filter')"
          :options="companies"
          option-label="name"
          option-value="id"
          :display-label="false" />
        <button class="btn btn-primary logo_index.svg" type="submit">
          {{ $t('search.search') }}
        </button>
      </form>
    </template>
    <template #content>
      <DataTable
        :always-show-paginator="false"
        :paginator="true"
        :rows="10"
        :rows-per-page-options="[10, 20, 50]"
        :value="list"
        lazy
        :loading="loading"
        :totalRecords="totalItems"
        class="table pointer"
        current-page-report-template="{first}-{last}/{totalRecords}"
        removable-sort
        responsive-layout="scroll"
        striped-rows
        @row-click="rowClick($event)"
        @page="onPage($event)"
        sort-mode="multiple"
        @sort="onSort($event)">
        <template #empty>
          {{ $t('employee.empty') }}
        </template>

        <Column v-if="this.isGas()" :header="$t('prestataire.company')" :sortable="true" sort-field="j_company_name">
          <template #body="slotProps">
            {{ slotProps.data.companyName }}
          </template>
        </Column>

        <Column :header="$t('prestataire.civility')" :sortable="true" sort-field="j_civility">
          <template #body="slotProps">
            {{ slotProps.data.contact.civility }}
          </template>
        </Column>

        <Column :header="$t('prestataire.lastName')" :sortable="true" sort-field="j_last_name">
          <template #body="slotProps">
            {{ slotProps.data.contact.lastName }}
          </template>
        </Column>

        <Column :header="$t('prestataire.firstName')" :sortable="true" sort-field="j_first_name">
          <template #body="slotProps">
            {{ slotProps.data.contact.firstName }}
          </template>
        </Column>

        <Column :header="$t('prestataire.email')" :sortable="true" sort-field="j_email">
          <template #body="slotProps">
            {{ slotProps.data.contact.email }}
          </template>
        </Column>

        <Column :header="$t('prestataire.phone')">
          <template #body="slotProps">
            {{ slotProps.data.contact.phone }}
          </template>
        </Column>

        <Column :header="$t('prestataire.availability')" :sortable="true" sort-field="status">
          <template #body="slotProps">
            <span :class="getStatusClass(slotProps.data.status)">{{
              displayForPrestataireStatusConst(slotProps.data.status)
            }}</span>
          </template>
        </Column>

        <Column :header="$t('prestataire.enabled')" :sortable="true" sort-field="j_enabled">
          <template #body="slotProps">
            <i v-if="slotProps.data.enabled" class="ti ti-check text-primary" />
            <i v-else class="ti ti-close" />
          </template>
        </Column>
      </DataTable>
    </template>
  </GaView>
</template>
