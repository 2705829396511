<script>
import {Icon} from '@iconify/vue/dist/iconify';

/* COMPOSANTS */
import MultiSelect from 'primevue/multiselect';
import AkCalendar from '@components/input/AkCalendar.vue';

/* MIXINS */
import sidebarMixin from '@mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';
import reservationSiteConst from '@mixins/const/reservationSiteConst';
import permissionsMixin from '@mixins/permissionsMixin';

/* SERVICES */
import companyService from '@services/companyService';
import accommodationService from '@services/accommodationService';
import AkOwnerAutocomplete from '@components/general/AkOwnerAutocomplete.vue';
import AkReservationAutocomplete from '@components/general/AkReservationAutocomplete.vue';

export default {
  components: {
    AkOwnerAutocomplete,
    AkReservationAutocomplete,
    AkCalendar,
    MultiSelect,
    Icon,
  },
  mixins: [utilsMixin, sidebarMixin, permissionsMixin, reservationSiteConst],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      companies: undefined,
      accommodations: undefined,
    };
  },
  mounted() {
    if (this.isGas()) this.initCompanies();
    this.initAccommodations();
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    loadingCompanies() {
      return this.companies === undefined;
    },
    loadingAccommodations() {
      return this.accommodations === undefined;
    },
    reservationSiteConstForFilter() {
      return this.reservationSiteConst.slice(0, -1);
    },
  },
  watch: {
    'currentValue.ownerId'(newVal, oldVal) {
      this.currentValue.accommodationIds = [];
      this.initAccommodations();
    },
    'currentValue.companyIds'(newVal, oldVal) {
      this.currentValue.accommodationIds = [];
      this.initAccommodations();
    },
    'currentValue.submittedAt'(newVal, oldVal) {
      this.currentValue.dateStart = this.currentValue.submittedAt ? this.currentValue.submittedAt[0] : undefined;
      this.currentValue.dateEnd = this.currentValue.submittedAt ? this.currentValue.submittedAt[1] : undefined;
    },
  },
  methods: {
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.ownerAutocomplete) this.$refs.ownerAutocomplete.reset();
      this.callback[1]();
    },
    initCompanies() {
      companyService.companies().then((data) => {
        this.companies = data;
      });
    },
    initAccommodations() {
      const filter = {
        companyIds: this.currentValue.companyIds,
        ownerId: this.currentValue.ownerId,
      };
      accommodationService.autocomplete(filter).then((data) => {
        this.accommodations = data;
      });
    },
  },
};
</script>

<template>
  <h2>Filtrer</h2>
  <form class="d-flex flex-column" style="row-gap: 16px" @submit.prevent="search">
    <MultiSelect
      v-if="this.isGas() && this.companies"
      v-model="currentValue.companyIds"
      :placeholder="$t('review.companies')"
      :options="companies"
      option-label="name"
      option-value="id"
      class="flex-1 form-control"
      inline />
    <AkOwnerAutocomplete
      ref="ownerAutocomplete"
      v-model="currentValue.ownerId"
      :label="$t('owner.ownerDisplay')"
      :placeholder="$t('owner.ownerDisplay')"
      inline
      class-name="flex-1 form-control" />

    <AkReservationAutocomplete
      useReservationId
      ref="reservationAutocomplete"
      v-model="currentValue.reservationId"
      :label="$t('reservation.number')"
      inline
      class-name="flex-1 form-control" />
    <MultiSelect
      v-model="currentValue.accommodationIds"
      :placeholder="$t('review.accommodations')"
      :options="accommodations"
      :loading="loadingAccommodations"
      option-label="name"
      option-value="id"
      class="flex-1 form-control"
      filter
      inline />
    <AkCalendar
      v-model="currentValue.submittedAt"
      inline
      :placeholder="$t('review.submitted_at')"
      selection="range"
      :maxDate="new Date()"
      class-name="flex-1 form-control" />
    <MultiSelect
      v-model="currentValue.reservationSites"
      :placeholder="$t('review.reservation_sites')"
      :options="reservationSiteConstForFilter"
      option-label="label"
      option-value="value"
      class="flex-1 form-control"
      inline />
    <div class="d-flex flex-row">
      <button class="btn btn-primary btn-search flex-1" type="submit">
        {{ this.$t('search.search') }}
      </button>
      <Icon
        v-on:click="this.reset()"
        class="pointer"
        icon="basil:cross-outline"
        :style="{fontSize: '50px', width: '50px !important'}" />
    </div>
  </form>
</template>
