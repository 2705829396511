export default {
  data() {
    return {
      motifConst: [
        {label: this.$t('employee.motif1'), value: 'HEALTH_REASON_1'},
        {label: this.$t('employee.motif2'), value: 'HEALTH_REASON_2'},
        {label: this.$t('employee.motif3'), value: 'HEALTH_REASON_3'},
        {label: this.$t('employee.motif4'), value: 'HEALTH_REASON_4'},
        {label: this.$t('employee.motif5'), value: 'HEALTH_REASON_5'},
        {label: this.$t('employee.motif6'), value: 'HEALTH_REASON_6'},
        {label: this.$t('employee.motif7'), value: 'HEALTH_REASON_7'},
        {label: this.$t('employee.motif8'), value: 'HEALTH_REASON_8'},
        {label: this.$t('employee.motif9'), value: 'HEALTH_REASON_9'},
      ],
    };
  },
};
