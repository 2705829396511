<script>
import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

/* COMPOSANTS */
import GaContentView from '@components/layout/GaContentView';
import AkCalendar from '@components/input/AkCalendar.vue';
import AkInputText from '@components/input/AkInputText.vue';
import AkInputMoney from '@components/input/AkInputMoney.vue';
import AkInputTextArea from '@components/input/AkInputTextArea.vue';
import AkDialog from '@components/general/AkDialog.vue';
import AkAutocomplete from '@components/general/AkAutocomplete.vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import messageMixin from '@mixins/messageMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import {PermissionMap} from '@mixins/const/permissionConst';

/* SERVICES */
import comptaAccountService from '@services/comptaAccountService';
import comptaRecurrenceService from '@services/comptaRecurrenceService';

export default {
  components: {
    AkAutocomplete,
    AkDialog,
    AkInputTextArea,
    AkInputMoney,
    AkInputText,
    AkCalendar,
    GaContentView,
  },
  mixins: [randomRef, utilsMixin, permissionsMixin, messageMixin, sidebarMixin, utilsMixin],
  data() {
    return {
      recurrenceConst: [
        {label: this.$t('payment.none'), value: 'none'},
        {label: this.$t('payment.monthly'), value: 'monthly'},
      ],
      loading: true,
      operations: [],
      comptaCharge: {},
      comptaChargeToDelete: {},
      accounts: [],
      displayDeleteDialog: false,
    };
  },
  validations() {
    return {
      comptaCharge: {
        type: {required},
        date: {required},
        label: {required},
        amount: {required},
        accountId: {required},
        accountId2: {required},
        recurrence: {required},
      },
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  computed: {
    numberOfResults() {
      return this.operations.length;
    },
    hasManagePermission() {
      return this.hasPermission(PermissionMap.OPERATION.MANAGE);
    },
  },
  mounted() {
    this.initRecurringCharges();
  },
  methods: {
    initRecurringCharges() {
      this.loading = true;
      this.operations = [];

      let p1 = comptaRecurrenceService.comptaRecurrences({});
      p1.then((data) => {
        this.operations = data;
      })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 412) {
              this.addError(this.$t('operation.empty'), true, true);
            } else {
              this.addError(this.$t('error.' + e.response.data.error));
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatCredit(amount) {
      if (amount < 0) return '';
      return this.formatCurrencyEUR(amount);
    },
    formatDebit(amount) {
      if (amount >= 0) return '';
      return this.formatCurrencyEUR(-amount);
    },
    displayComment(slotProp) {
      this.showComment(slotProp);
    },
    setRecurrencePause(comptaCharge) {
      this.loading = true;
      let isActive = true;
      if (comptaCharge.datePaused === undefined || comptaCharge.datePaused === null) {
        comptaCharge.datePaused = new Date();
        isActive = false;
      } else {
        comptaCharge.datePaused = null;
        isActive = true;
      }

      let p1 = comptaRecurrenceService.update(comptaCharge);
      p1.then((data) => {
        if (isActive) {
          this.success(this.$t('operation.compta_charge_actived'));
        } else {
          this.success(this.$t('operation.compta_charge_paused'));
        }
      })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 412) {
              this.addError(this.$t('operation.error_update'), true, true);
            } else {
              this.addError(this.$t('error.' + e.response.data.error));
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.initRecurringCharges();
        });
    },
    updateComptaCharge() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.comptaCharge.$invalid) return;

      this.loading = true;

      let p1 = comptaRecurrenceService.update(this.comptaCharge);
      p1.then((data) => {
        this.success(this.$t('operation.compta_charge_updated'));
      })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 412) {
              this.addError(this.$t('operation.error_update'), true, true);
            } else {
              this.addError(this.$t('error.' + e.response.data.error));
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.$refs.dialogUpdate.hide();
          this.initRecurringCharges();
        });
    },
    confirmDelete(data) {
      this.comptaChargeToDelete = data;
      this.displayDeleteDialog = true;
    },
    deleteComptaCharge() {
      this.loading = true;
      let p1 = comptaRecurrenceService.delete(this.comptaChargeToDelete);
      p1.then((data) => {
        this.success(this.$t('operation.compta_charge_deleted'));
        this.initRecurringCharges();
      })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.loading = false;
          this.displayDeleteDialog = false;
        });
    },
    openUpdateDialog(data) {
      this.comptaCharge = {...data};
      this.errors = [];
      this.$refs.dialogUpdate.show();
    },
    searchAccounts(event) {
      let search = {};
      search.query = event.query;
      comptaAccountService.comptaAccounts(search).then((data) => {
        this.accounts = data;
      });
    },
  },
};
</script>

<template>
  <GaContentView :ref="ref" :title="$t('operation.recurring_charges')">
    <template #extra>
      <AkDialog
        ref="dialogUpdate"
        :title="$t('operation.compta_charge_modify')"
        :validate-label="$t('update')"
        width="800px"
        @validate="updateComptaCharge()">
        <div v-if="this.comptaCharge">
          <div class="form-row">
            <AkAutocomplete
              v-model="comptaCharge.accountId"
              :items="accounts"
              :placeholder="comptaCharge.accountNum1"
              :label="$t('operation.account_from')"
              :validator="v$.comptaCharge.accountId"
              class-name="col-md-6"
              option-label="num"
              option-value="id"
              :disabled="true"
              @search="this.searchAccounts">
              <template #item="slotProps">
                <div>{{ slotProps.item.display }}</div>
              </template>
            </AkAutocomplete>

            <AkAutocomplete
              v-model="comptaCharge.accountId2"
              :items="accounts"
              :placeholder="comptaCharge.accountNum2"
              :label="$t('operation.account_to')"
              :validator="v$.comptaCharge.accountId2"
              class-name="col-md-6"
              option-label="num"
              option-value="id"
              :disabled="true"
              @search="this.searchAccounts">
              <template #item="slotProps">
                <div>{{ slotProps.item.display }}</div>
              </template>
            </AkAutocomplete>
            <AkCalendar
              v-model="comptaCharge.date"
              :label="$t('operation.date')"
              :validator="v$.comptaCharge.date"
              class-name="col-md-6" />
            <AkInputText
              v-model="comptaCharge.label"
              :label="$t('operation.label')"
              :validator="v$.comptaCharge.label"
              class-name="col-md-6" />
            <AkCalendar v-model="comptaCharge.dateEnd" :label="$t('operation.date_end')" class-name="col-md-6" />
            <AkCalendar v-model="comptaCharge.datePaused" :label="$t('operation.date_paused')" class-name="col-md-6" />
            <AkInputMoney
              v-model="comptaCharge.amount"
              :label="$t('operation.amount')"
              :validator="v$.comptaCharge.amount"
              class-name="col-md-6" />
            <AkInputTextArea v-model="comptaCharge.comment" :label="$t('operation.comment')" class-name="col-md-12" />
          </div>
        </div>
      </AkDialog>
    </template>

    <template #content>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive">
                <DataTable
                  :always-show-paginator="false"
                  :loading="loading"
                  :paginator="true"
                  :rows="50"
                  :rows-per-page-options="[10, 20, 50]"
                  :value="this.operations"
                  class="table"
                  current-page-report-template="{first}-{last}/{totalRecords}"
                  removable-sort
                  responsive-layout="scroll"
                  sort-field="position"
                  striped-rows>
                  <template #header>
                    <div class="d-flex align-items-center justify-content-start header-legend-result">
                      {{ this.$t('payment.number_results') }} {{ this.numberOfResults }}
                    </div>
                  </template>
                  <template #empty>
                    {{ $t('operation.empty') }}
                  </template>

                  <Column :header="this.$t('operation.date')" field="date" :sortable="true">
                    <template #body="slotProps">
                      {{ this.displayDate(slotProps.data.date) }}
                    </template>
                  </Column>

                  <Column :header="this.$t('operation.label')" :sortable="true" field="label">
                    <template #body="slotProps">
                      <div class="d-flex align-items-center">
                        {{ slotProps.data.label }}
                        <i
                          v-if="slotProps.data.comment"
                          class="fa fa-info-circle ml-1 pointer"
                          @click="displayComment(slotProps.data)"></i>
                      </div>
                    </template>
                  </Column>

                  <Column :header="this.$t('operation.account_from')" field="accountNum1" :sortable="true"> </Column>

                  <Column :header="this.$t('operation.account_to')" field="accountNum2" :sortable="true"> </Column>

                  <Column :header="this.$t('operation.date_end')" field="dateEnd" :sortable="true">
                    <template #body="slotProps">
                      {{ this.displayDate(slotProps.data.dateEnd) }}
                    </template>
                  </Column>

                  <Column :header="this.$t('operation.date_updated')" field="dateUpdated" :sortable="true">
                    <template #body="slotProps">
                      {{ this.displayDate(slotProps.data.dateUpdated) }}
                    </template>
                  </Column>

                  <Column :header="this.$t('operation.date_paused')" field="datePaused" :sortable="true">
                    <template #body="slotProps">
                      {{ this.displayDate(slotProps.data.datePaused) }}
                    </template>
                  </Column>

                  <Column :header="this.$t('operation.mandat')" field="mandatNum" :sortable="true">
                    <template #body="slotProps"> F{{ slotProps.data.mandatNum }} </template>
                  </Column>

                  <Column :header="this.$t('operation.recurrence_period')" field="recurrence" :sortable="true">
                    <template #body="slotProps">
                      {{ this.$t('operation.' + slotProps.data.recurrence) }}
                    </template>
                  </Column>

                  <Column :header="this.$t('operation.type')" field="type" :sortable="true">
                    <template #body="slotProps">
                      {{ this.$t('operation.' + slotProps.data.type) }}
                    </template>
                  </Column>

                  <Column :header="this.$t('accounting.amount')" :sortable="true" class="text-right" field="amount">
                    <template #body="slotProps">
                      {{ this.formatCurrencyEUR(slotProps.data.amount) }}
                    </template>
                  </Column>

                  <Column>
                    <template #header>
                      {{ $t('payment.action') }}
                    </template>
                    <template #body="slotProps" v-if="hasManagePermission">
                      <div class="form-inline">
                        <button
                          class="btn btn-inverse-primary btn-xs"
                          @click="setRecurrencePause(slotProps.data)"
                          style="margin: 5px">
                          <span v-if="!slotProps.data.datePaused">{{ $t('operation.pause_recurring') }}</span>
                          <span v-if="slotProps.data.datePaused">{{ $t('operation.pause_recurring_back') }}</span>
                        </button>

                        <button
                          class="btn btn-inverse-secondary btn-xs"
                          @click="openUpdateDialog(slotProps.data)"
                          style="margin: 5px">
                          {{ $t('operation.update') }}
                        </button>

                        <button
                          class="btn btn-inverse-danger btn-xs"
                          @click="confirmDelete(slotProps.data)"
                          style="margin: 5px">
                          {{ $t('operation.delete') }}
                        </button>
                      </div>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </GaContentView>
  <PDialog v-model:visible="displayDeleteDialog" :header="$t('delete_dialog')" :modal="true" :style="{width: '450px'}">
    <div class="confirmation-content">
      <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
      <span>{{ $t('operation.delete_warning_recurrence') }}</span>
    </div>
    <template #footer>
      <div class="d-flex justify-content-end">
        <span class="btn btn-outline-primary btn-xs mr-1" @click="displayDeleteDialog = false">{{
          this.$t('no')
        }}</span>
        <span class="btn btn-inverse-danger btn-xs" @click="deleteComptaCharge">{{ this.$t('yes') }}</span>
      </div>
    </template>
  </PDialog>
</template>
