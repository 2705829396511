<script>
/* COMPOSANTS */

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import documentModelTypeConst from '@mixins/const/documentModelTypeConst';

/* SERVICES */
import documentModelService from '@services/documentModelService';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';
import GaView from '@components/layout/GaView';

export default {
  components: {GaView},
  mixins: [randomRef, loaderMixin, messageMixin, utilsMixin, documentModelTypeConst],
  metaInfo() {
    return {
      title: 'settings.document_model',
    };
  },
  data() {
    return {
      list: [],
      item: {},
      loading: true,
      submitted: false,
      displayCreateDialog: false,
      displayEditDialog: false,
      displayDeleteDialog: false,
    };
  },
  computed: {},
  mounted() {
    documentModelService.list().then((data) => {
      this.list = data;
      this.loading = false;
    });
  },
  methods: {
    delete() {
      this.loading = true;
      documentModelService
        .delete(this.item)
        .then((item) => {
          this.list = this.list.filter((val) => val.id !== item.id);
          this.success(this.item.name + ' a bien été supprimé');
          this.displayDeleteDialog = false;
        })
        .catch((e) => {
          this.addError(e.response.data.error);
          this.displayDeleteDialog = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openDeleteDialog(item) {
      this.item = item;
      this.displayDeleteDialog = true;
    },
    closeDeleteDialog() {
      this.displayDeleteDialog = false;
    },
    downloadFic(data) {
      this.showTotalLoader();
      documentModelService
        .documentModelDownload(data.id)
        .catch(() => {
          this.addError('Une erreur est survenue lors du téléchargement');
        })
        .finally(() => {
          this.hideLoader();
        });
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :submitted="submitted" :title="$t('settings.document_model')">
    <template #action>
      <div class="d-flex justify-content-end">
        <router-link :to="{name: 'settingsDocumentModelCreate'}" class="btn btn-primary">
          <i class="gst-plus pr-1" />{{ $t('document_model.add') }}
        </router-link>
      </div>
    </template>
    <template #content>
      <DataTable
        :always-show-paginator="false"
        :loading="loading"
        :paginator="true"
        :rows="10"
        :rows-per-page-options="[10, 20, 50]"
        :value="list"
        class="table"
        current-page-report-template="{first}-{last}/{totalRecords}"
        removable-sort
        responsive-layout="scroll"
        sort-field="position"
        striped-rows>
        <template #empty>
          {{ $t('document_model.empty') }}
        </template>
        <Column :header="$t('document_model.name')">
          <template #body="slotProps">
            {{ slotProps.data.name }}
          </template>
        </Column>
        <Column :header="$t('document_model.type')">
          <template #body="slotProps">
            {{ displayTypeForDocumentModelTypeConst(slotProps.data.type) }}
          </template>
        </Column>
        <Column :header="$t('document_model.subtype')">
          <template #body="slotProps">
            {{ displaySubTypeForDocumentModelTypeConst(slotProps.data.type, slotProps.data.subType) }}
          </template>
        </Column>

        <Column body-style="text-align: right; overflow: visible" header-style=" width: 220px; text-align: btn">
          <template #body="slotProps">
            <div class="d-flex justify-content-end">
              <span
                v-if="slotProps.data.fic?.id"
                class="btn btn-xs btn-inverse-primary mr-1 pointer"
                @click="downloadFic(slotProps.data)">
                <i class="fe fe-download"
              /></span>
              <router-link
                :to="{name: 'settingsDocumentModelDetails', params: {documentModelId: slotProps.data.id}}"
                class="btn btn-xs btn-inverse-primary mr-1">
                <i class="fe fe-eye" />
              </router-link>
              <router-link
                :to="{name: 'settingsDocumentModelUpdate', params: {documentModelId: slotProps.data.id}}"
                class="btn btn-xs btn-inverse-primary mr-1">
                <i class="fe fe-edit" />
              </router-link>
              <span class="btn btn-xs btn-inverse-danger pointer" @click="openDeleteDialog(slotProps.data)">
                <i class="fe fe-trash"
              /></span>
            </div>
          </template>
        </Column>
      </DataTable>
    </template>

    <template #extra>
      <PDialog
        v-model:visible="displayDeleteDialog"
        :header="$t('document_model.delete')"
        :modal="true"
        :style="{width: '450px'}"
        @hide="displayDeleteDialog = false">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span>{{ $t('document_model.confirm_delete') }}</span>
        </div>
        <template #footer>
          <div class="d-flex justify-content-end">
            <span class="btn btn-outline-primary btn-xs mr-1" @click="closeDeleteDialog()">
              {{ $t('no') }}
            </span>
            <span class="btn btn-danger btn-xs" @click="this.delete()">
              {{ $t('yes') }}
            </span>
          </div>
        </template>
      </PDialog>
    </template>
  </GaView>
</template>
