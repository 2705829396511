<script>
import useVuelidate from '@vuelidate/core';
import {required, requiredIf} from '@vuelidate/validators';

/* COMPOSANTS */
import GaContentView from '@components/layout/GaContentView';
import AkCalendar from '@components/input/AkCalendar.vue';
import AkInputMoney from '@components/input/AkInputMoney.vue';
import AkInputTextArea from '@components/input/AkInputTextArea.vue';
import AkDialog from '@components/general/AkDialog.vue';
import PButton from 'primevue/button';
import AkFicList from '@components/layout/AkFicList.vue';
import AkFicItem from '@components/layout/AkFicItem.vue';
import AkInputText from '@components/input/AkInputText.vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import messageMixin from '@mixins/messageMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import {PermissionMap} from '@mixins/const/permissionConst';
import bigDecimal from 'js-big-decimal';
import ownerChargeTypeConst from '@mixins/const/ownerChargeTypeConst';

/* SERVICES */
import ownerChargeService from '@services/ownerChargeService';
import settingService from '@services/settingService';
import AkDropdown from '@components/input/AkDropdown.vue';
import AkOwnerAutocomplete from '@components/general/AkOwnerAutocomplete.vue';
import AkInputNumber from '@components/input/AkInputNumber.vue';
import labelODMixin from '@mixins/labelODMixin';
import mandatService from '@services/mandatService';
import itemLabelService from '@services/itemLabelService';
import ficService from '@services/ficService';
import comptaCommissionService from '@services/comptaCommissionService';

export default {
  components: {
    AkInputText,
    AkFicItem,
    AkFicList,
    AkCalendar,
    AkInputMoney,
    AkInputNumber,
    AkOwnerAutocomplete,
    AkDropdown,
    AkInputTextArea,
    AkDialog,
    GaContentView,
    PButton,
  },
  mixins: [
    randomRef,
    utilsMixin,
    permissionsMixin,
    messageMixin,
    sidebarMixin,
    utilsMixin,
    labelODMixin,
    ownerChargeTypeConst,
  ],
  data() {
    return {
      loading: true,
      files: [],
      items: [],
      item: {},
      search: {},
      mandats: [],
      raisonAchatList: [],
      motifDeplacementList: [],
      hourlyRateValue: null,
      comptaCommission: {},
    };
  },
  validations() {
    return {
      item: {
        ownerId: {required},
        mandatId: {required},
        motifIdAchatHote: {required: requiredIf(this.isAchatHote)},
        motifIdFraisDeMaintenance: {required: requiredIf(this.isFraisDeMaintenance)},
        type: {required},
        date: {required: requiredIf(this.isDateRequired)},
        dateTransaction: {required},
        duration: {required: requiredIf(this.isFraisDeMaintenance)},
        amount: {required: requiredIf(this.isAmountRequired)},
        label: {required: requiredIf(this.isLabelRequired)},
        comment: {required: requiredIf(this.isAchatHote)},
      },
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  computed: {
    hasManagePermission() {
      if (this.hasPermission(PermissionMap.FACTURATION_HOTE.MANAGE)) return true;
      return !!this.isPoleComptabiliteMandante();
    },
    buildLabelChoice() {
      if (this.isFraisDeMaintenance) return this.labelODfraisDeMaintenance;
      if (this.isAchatHote) return this.labelODachatHote;
      if (this.isOuvertureHivernage) return this.labelOdOuvertureHivernage;
      if (this.isFermetureHivernage) return this.labelOdFermetureHivernage;
      return null;
    },
    isFraisDeMaintenance() {
      if (!this.item) return false;
      return this.item.type === 'FRAIS_DE_MAINTENANCE';
    },
    isAchatHote() {
      if (!this.item) return false;
      return this.item.type === 'ACHAT_HOTE';
    },
    isOuvertureHivernage() {
      if (!this.item) return false;
      return this.item.type === 'OUVERTURE_HIVERNAGE';
    },
    isFermetureHivernage() {
      if (!this.item) return false;
      return this.item.type === 'FERMETURE_HIVERNAGE';
    },
    isForfaitHivernage() {
      return this.isOuvertureHivernage || this.isFermetureHivernage;
    },
    isFraisDeMenageHote() {
      if (!this.item) return false;
      return this.item.type === 'FRAIS_DE_MENAGE_HOTE';
    },
    isOwnerSelected() {
      if (!this.item) return false;
      return this.item.ownerId !== null && this.item.ownerId !== undefined;
    },
    isAmountRequired() {
      return this.isAchatHote || this.isForfaitHivernage || this.isFraisDeMenageHote;
    },
    isLabelRequired() {
      return this.isAchatHote || this.isForfaitHivernage || this.isFraisDeMenageHote;
    },
    isDateRequired() {
      if (!this.item) return false;
      return this.isAchatHote || this.isFraisDeMaintenance;
    },
    displayAmountDuration() {
      if (!this.item.duration || !this.hourlyRateValue) return null;
      let amount = new bigDecimal(0);
      amount = amount.add(new bigDecimal(this.item.duration));
      amount = amount.multiply(new bigDecimal(this.hourlyRateValue));
      return parseFloat(amount.round(2, bigDecimal.RoundingModes.HALF_EVEN).getValue());
    },
  },
  mounted() {
    this.refresh();
  },
  watch: {
    'item.ownerId'(newVal, oldVal) {
      if (this.item.ownerId) {
        this.getMandatList();
      }
    },
    'item.mandatId'(newVal, oldVal) {
      this.getHourlyRate();
    },
    'item.type'(newVal, oldVal) {
      this.errors = [];
      if (this.isAchatHote) {
        this.item.duration = null;
        this.item.motifIdFraisDeMaintenance = null;
      }
      if (this.isFraisDeMaintenance) {
        this.item.amount = null;
        this.item.label = null;
        this.item.comment = null;
        this.item.motifIdAchatHote = null;
        this.files = [];
      }
    },
  },
  methods: {
    refresh() {
      this.loading = true;

      this.search.status = 'IN_PROGRESS';
      let p1 = ownerChargeService.ownerCharges(this.search);
      p1.then((data) => {
        this.items = data;
      }).catch((error) => {
        this.$t('facturation_hote.error');
      });

      let p3 = itemLabelService.itemLabels({type: 'MOTIF_DEPLACEMENT', enabled: true});
      p3.then((data) => {
        this.motifDeplacementList = data;
      });

      let p4 = itemLabelService.itemLabels({type: 'RAISON_ACHAT_HOTE', enabled: true});
      p4.then((data) => {
        this.raisonAchatList = data;
      });

      let p5 = itemLabelService.itemLabels({type: 'ACHAT_HOTE', enabled: true});
      p5.then((data) => {
        this.achatHoteList = data;
        for (let achatHote of this.achatHoteList) {
          this.labelODachatHote.push(achatHote.label);
        }
      });

      Promise.all([p1, p3, p4, p5]).then(() => {
        this.loading = false;
      });
    },
    displayComment(slotProp) {
      this.showComment(slotProp);
    },
    getAmountDuration(duration) {
      if (!this.hourlyRateValue) return null;
      let amount = new bigDecimal(0);
      amount = amount.add(new bigDecimal(duration));
      amount = amount.multiply(new bigDecimal(this.hourlyRateValue));
      return parseFloat(amount.round(2, bigDecimal.RoundingModes.HALF_EVEN).getValue());
    },
    getMandatList() {
      mandatService.mandatsByOwner(this.item.ownerId).then((data) => {
        this.mandats = data;
      });
    },
    getHourlyRate() {
      if (!this.item || !this.item.mandatId) return;
      let filter = {
        typeCommission: 'FRAIS_DE_MAINTENANCE_TAUX',
        mandatId: this.item.mandatId,
        date: this.item.dateTransaction,
      };
      comptaCommissionService
        .comptaCommissionForDate(filter)
        .then((data) => {
          this.comptaCommission = data;
          this.hourlyRateValue = this.comptaCommission.fraisDeMaintenanceTaux / 60;
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {});
    },
    canValidate(status) {
      return status === 'IN_PROGRESS';
    },
    canDelete(status) {
      return status === 'IN_PROGRESS';
    },
    openValidateDialog(item) {
      this.item = {...item};
      this.$refs.dialogValidate.show();
    },
    openEditDialog(item) {
      this.item = {...item};
      if (this.item.fics === null || this.item.fics === undefined) {
        this.item.fics = [];
      }
      if (this.isAchatHote) this.item.motifIdAchatHote = this.item.motifId;
      if (this.isFraisDeMaintenance) this.item.motifIdFraisDeMaintenance = this.item.motifId;
      this.getMandatList();
      this.$refs.dialogEdit.show();
    },
    openDeleteDialog(item) {
      this.item = {...item};
      this.$refs.dialogDelete.show();
    },
    validate() {
      if (this.item.dateTransaction === null || this.item.dateTransaction === undefined) {
        this.addError(this.$t('owner_charge.error_empty_date_transaction'));
        return;
      }
      if (this.isFraisDeMaintenance) {
        this.item.amount = this.displayAmountDuration;
      }
      ownerChargeService
        .validate(this.item.id)
        .then((data) => {
          this.item = data;
          this.success(this.$t('facturation_hote.owner_charge_validated'));
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.refresh();
        });
    },
    updateAndValidate() {
      this.v$.$touch();

      if (this.v$.$error) return;
      this.loading = true;
      this.$refs.dialogEdit.hide();

      if (this.isAchatHote) this.item.motifId = this.item.motifIdAchatHote;
      if (this.isFraisDeMaintenance) {
        this.item.motifId = this.item.motifIdFraisDeMaintenance;
        this.item.amount = this.displayAmountDuration;
      }

      ownerChargeService
        .updateAndValidate(this.item, this.files)
        .then(() => {
          this.success(this.$t('facturation_hote.owner_charge_validated'));
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.loading = false;
          this.refresh();
        });
    },
    delete() {
      ownerChargeService
        .deleteOwnerCharge(this.item.id)
        .then((data) => {
          this.item = data;
          this.success(this.$t('facturation_hote.owner_charge_deleted'));
        })
        .catch((error) => {
          this.addError(this.$t('facturation_hote.error_delete'));
        })
        .finally(() => {
          this.refresh();
        });
    },
    resetFilter() {
      this.search = {};
      this.toggleOperationsOwnerChargeFilterList(this.search, this.refresh, this.resetFilter);
    },
    openFilterPanel() {
      this.toggleOperationsOwnerChargeFilterList(this.search, this.refresh, this.resetFilter);
    },
    downloadFile(file, isPreview) {
      ficService.download(file, isPreview).catch((e) => {
        if (e.response.status === 404) {
          this.addError(this.$t('facturation_hote.error_files_missing'));
        }
      });
    },
  },
};
</script>

<template>
  <GaContentView :ref="ref" :title="$t('operation.validate_owner_charge')">
    <template #action>
      <div class="d-flex justify-content-end" style="column-gap: 16px">
        <PButton
          class="btn btn-inverse-primary btn-xs"
          :badge="this.countFilter(this.search)"
          badgeClass="p-badge-primary"
          :label="this.$t('facturation_hote.filter')"
          @click="openFilterPanel()">
        </PButton>
      </div>
    </template>

    <template #content>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive">
                <DataTable
                  :always-show-paginator="false"
                  :loading="loading"
                  :paginator="true"
                  :rows="50"
                  :rows-per-page-options="[10, 20, 50]"
                  :value="this.items"
                  class="table"
                  current-page-report-template="{first}-{last}/{totalRecords}"
                  removable-sort
                  responsive-layout="scroll"
                  sort-field="position"
                  striped-rows>
                  <template #header>
                    <div class="d-flex align-items-center justify-content-start header-legend-result">
                      <span v-if="this.items && this.items.length > 0">
                        {{ this.$t('payment.number_results') }} {{ this.items.length }}
                      </span>
                    </div>
                  </template>
                  <template #empty>
                    {{ $t('facturation_hote.empty') }}
                  </template>

                  <Column :header="this.$t('operation.date')" field="date" :sortable="true" style="width: 5%">
                    <template #body="slotProps">
                      {{ this.displayDate(slotProps.data.date) }}
                    </template>
                  </Column>

                  <Column
                    :header="this.$t('accounting.date')"
                    field="dateTransaction"
                    :sortable="true"
                    style="width: 5%">
                    <template #body="slotProps">
                      {{ this.displayDate(slotProps.data.dateTransaction) }}
                    </template>
                  </Column>

                  <Column :header="this.$t('facturation_hote.type')" :sortable="true" field="type" style="width: 10%">
                    <template #body="slotProps">
                      <span v-if="slotProps.data.type">
                        {{ this.$t('facturation_hote.' + slotProps.data.type) }}
                      </span>
                    </template>
                  </Column>

                  <Column
                    :header="this.$t('facturation_hote.status')"
                    :sortable="true"
                    field="status"
                    style="width: 5%">
                    <template #body="slotProps">
                      <span v-if="slotProps.data.type">
                        {{ this.$t('facturation_hote.' + slotProps.data.status) }}
                      </span>
                    </template>
                  </Column>

                  <Column
                    :header="this.$t('owner.ownerDisplay')"
                    :sortable="true"
                    field="ownerDisplay"
                    style="width: 10%">
                    <template #body="slotProps">
                      {{ slotProps.data.ownerDisplay }}
                    </template>
                  </Column>

                  <Column
                    :header="this.$t('operation.mandat')"
                    :sortable="true"
                    field="mandatDisplay"
                    style="width: 15%">
                    <template #body="slotProps">
                      {{ slotProps.data.mandatDisplay }}
                    </template>
                  </Column>

                  <Column
                    :header="this.$t('facturation_hote.motif')"
                    :sortable="true"
                    field="motifDisplay"
                    style="width: 10%">
                    <template #body="slotProps">
                      {{ slotProps.data.motifDisplay }}
                    </template>
                  </Column>

                  <Column :header="this.$t('operation.label')" :sortable="true" field="label" style="width: 10%">
                    <template #body="slotProps">
                      <div class="d-flex align-items-center">
                        {{ slotProps.data.label }}
                        <i
                          v-if="slotProps.data.comment"
                          class="fa fa-info-circle ml-1 pointer"
                          @click="displayComment(slotProps.data)"></i>
                      </div>
                    </template>
                  </Column>

                  <Column
                    :header="this.$t('facturation_hote.amount')"
                    :sortable="true"
                    class="text-right"
                    field="amount"
                    style="width: 5%">
                    <template #body="slotProps">
                      <span v-if="slotProps.data.amount">
                        {{ this.formatCurrency(slotProps.data.amount, 'EUR') }}
                      </span>
                    </template>
                  </Column>

                  <Column
                    :header="this.$t('operation.minutes')"
                    :sortable="true"
                    class="text-right"
                    field="duration"
                    style="width: 5%">
                    <template #body="slotProps">
                      <span v-if="slotProps.data.duration"> {{ this.formatNumber(slotProps.data.duration) }} min </span>
                    </template>
                  </Column>

                  <Column :header="this.$t('files')" :sortable="true" field="fics" style="width: 10%">
                    <template #body="slotProps">
                      <div
                        v-for="(item, index) in slotProps.data.fics"
                        v-show="slotProps.data.fics.length"
                        :key="item.id">
                        <AkFicItem
                          v-model="slotProps.data.fics[index]"
                          :fic-preview="false"
                          :canDelete="false"
                          @download="downloadFile"
                          :title="slotProps.data.fics[index].fileName" />
                      </div>
                    </template>
                  </Column>

                  <Column
                    v-if="hasManagePermission"
                    body-style="text-align: right; overflow: visible"
                    header-style=" width: 160px; text-align: btn"
                    style="width: 10%">
                    <template #body="slotProps">
                      <div class="d-flex justify-content-end">
                        <span
                          v-if="canValidate(slotProps.data.status)"
                          class="btn btn-xs btn-inverse-primary mr-1"
                          @click="openValidateDialog(slotProps.data)"
                          :title="this.$t('facturation_hote.validate')">
                          <i class="fe fe-play" />
                        </span>
                        <span
                          v-if="canValidate(slotProps.data.status)"
                          class="btn btn-xs btn-inverse-secondary mr-1"
                          @click="openEditDialog(slotProps.data)"
                          :title="this.$t('facturation_hote.update')">
                          <i class="fe fe-edit" />
                        </span>
                        <span
                          v-if="canDelete(slotProps.data.status)"
                          class="btn btn-xs btn-inverse-danger mr-1"
                          @click="openDeleteDialog(slotProps.data)"
                          :title="this.$t('facturation_hote.delete')">
                          <i class="fe fe-trash" />
                        </span>
                      </div>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AkDialog
        ref="dialogValidate"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('facturation_hote.validate')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.validate()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span>{{ $t('facturation_hote.confirm_validate') }}</span>
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogEdit"
        :title="$t('facturation_hote.update')"
        :validate-label="$t('facturation_hote.update_validate')"
        width="900px"
        @validate="updateAndValidate()">
        <div class="form-row">
          <AkDropdown
            v-model="item.type"
            :label="$t('facturation_hote.type')"
            :options="ownerChargeTypeConst"
            :validator="v$.item.type"
            optionLabel="label"
            optionValue="value"
            class-name="col-md-6" />
        </div>
        <div class="form-row">
          <AkCalendar
            v-if="isFraisDeMaintenance || isAchatHote"
            v-model="item.date"
            :label="
              isFraisDeMaintenance ? $t('facturation_hote.date_intervention') : $t('facturation_hote.date_purchase')
            "
            :validator="v$.item.date"
            class-name="col-md-6" />
          <AkCalendar
            v-if="isFraisDeMaintenance || isAchatHote || isForfaitHivernage || isFraisDeMenageHote"
            v-model="item.dateTransaction"
            :label="this.$t('accounting.date')"
            :validator="v$.item.dateTransaction"
            class-name="col-md-6" />
        </div>
        <div class="form-row">
          <AkOwnerAutocomplete
            v-if="isFraisDeMaintenance || isAchatHote || isForfaitHivernage || isFraisDeMenageHote"
            ref="ownerAutocomplete"
            v-model="item.ownerId"
            :ownerStatus="'CUSTOMER'"
            :accommodationPublished="true"
            :label="$t('owner.ownerDisplay')"
            :placeholder="$t('owner.ownerDisplay')"
            :validator="v$.item.ownerId"
            class-name="col-md-6" />
          <AkDropdown
            v-if="isOwnerSelected && (isFraisDeMaintenance || isAchatHote || isForfaitHivernage || isFraisDeMenageHote)"
            ref="dropdownMandat"
            v-model="item.mandatId"
            :label="$t('operation.mandat')"
            :options="mandats"
            optionLabel="display"
            optionValue="id"
            :validator="v$.item.mandatId"
            class-name="col-md-6" />
          <AkInputNumber
            v-if="isFraisDeMaintenance"
            v-model="item.duration"
            :label="$t('operation.minutes')"
            :validator="v$.item.duration"
            :fraction-digits="0"
            class-name="col-md-6"
            :min="0"
            suffix=" minutes" />
          <AkInputMoney
            v-if="isFraisDeMaintenance && item.duration"
            v-model="displayAmountDuration"
            :label="$t('operation.amount')"
            :disabled="true"
            class-name="col-md-6" />
        </div>
        <div class="form-row">
          <AkDropdown
            v-if="isFraisDeMaintenance"
            v-model="item.motifIdFraisDeMaintenance"
            :label="$t('item_label.motif_deplacement')"
            :options="motifDeplacementList"
            optionLabel="label"
            optionValue="id"
            :validator="v$.item.motifIdFraisDeMaintenance"
            class-name="col-md-6" />
        </div>
        <div class="form-row">
          <AkInputMoney
            v-if="isAchatHote || isForfaitHivernage || isFraisDeMenageHote"
            v-model="item.amount"
            :label="$t('operation.amount')"
            :validator="v$.item.amount"
            class-name="col-md-6" />
          <AkDropdown
            v-if="buildLabelChoice && (isAchatHote || isForfaitHivernage)"
            v-model="item.label"
            :label="$t('operation.label')"
            :options="buildLabelChoice"
            :validator="v$.item.label"
            :optionLabel="(obj) => obj"
            :optionValue="(obj) => obj"
            class-name="col-md-6" />
          <AkInputText
            v-if="isFraisDeMenageHote"
            v-model="item.label"
            :label="$t('operation.label')"
            :placeholder="$t('operation.label')"
            :validator="v$.item.label"
            class-name="col-md-6" />
          <AkDropdown
            v-if="isAchatHote"
            v-model="item.motifIdAchatHote"
            :label="$t('item_label.raison_achat_hote')"
            :options="raisonAchatList"
            :validator="v$.item.motifIdAchatHote"
            optionLabel="label"
            optionValue="id"
            class-name="col-md-6" />
          <AkInputTextArea
            v-if="isAchatHote || isFraisDeMaintenance || isForfaitHivernage || isFraisDeMenageHote"
            v-model="item.comment"
            :label="isAchatHote ? $t('settings.description') : $t('operation.comment')"
            :validator="v$.item.comment"
            class-name="col-md-12" />
        </div>

        <AkFicList
          v-if="item !== undefined"
          v-model="item.fics"
          :display-title="false"
          :table-id="item.id"
          table-name="owner_charge"
          @added="refresh"
          @removed="refresh" />
      </AkDialog>

      <AkDialog
        ref="dialogDelete"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.delete()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span>{{ $t('facturation_hote.confirm_delete') }}</span>
        </div>
      </AkDialog>
    </template>
  </GaContentView>
</template>
