<script>
import prettyBytes from 'pretty-bytes';
import {getColorIconFromMIME, getFontAwesomeIconFromMIME} from '@utils/FontAwesomeMimeType';

/* COMPOSANTS */
import AkFicItem from '@components/layout/AkFicItem';
import AkLabel from '@components/general/AkLabel';
import FileUpload from 'primevue/fileupload';

/* MIXINS */
import envConst from '@mixins/const/envConst';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import ficService from '@services/ficService';

export default {
  components: {AkFicItem, AkLabel, FileUpload},
  mixins: [envConst, messageMixin],
  props: {
    modelValue: {
      required: true,
    },
    tableName: {
      type: String,
      required: true,
    },
    tableId: {
      type: [Number, String],
      required: true,
    },
    ficType: {
      type: String,
      required: false,
      default: undefined,
    },
    displayTitle: {
      type: Boolean,
      required: false,
      default: true,
    },
    addTitle: {
      type: String,
      required: false,
      default: 'add',
    },
    accept: {
      type: String,
      required: false,
      default: '',
    },
    ficPreview: {
      type: Boolean,
      required: false,
      default: false,
    },
    ficItemLabelId: {
      type: Number,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    canDelete: {
      type: Boolean,
      required: false,
      default: true,
    },
    canUpload: {
      type: Boolean,
      required: false,
      default: true,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    required: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    displayLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
    displayMessage: {
      type: Boolean,
      required: false,
      default: true,
    },
    uniqueFic: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update:modelValue', 'added', 'removed'],
  data() {
    return {
      fileAction: null,
      displayDeleteDialog: false,
    };
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  methods: {
    getFileIcon(fileName) {
      return getFontAwesomeIconFromMIME(fileName);
    },
    getColorIcon(fileName) {
      return getColorIconFromMIME(fileName);
    },
    getSizeReadable(size) {
      return prettyBytes(size);
    },
    downloadFile(file, isPreview) {
      ficService.download(file, isPreview).catch((e) => {
        if (e.response.status === 404) {
          this.addError("Le fichier n'existe pas");
        }
      });
    },
    selectFiles(event) {
      if (event.files) {
        let fic = {};
        fic.tableName = this.tableName;
        fic.tableId = this.tableId;
        if (this.ficType) {
          fic.ficType = this.ficType;
        }
        if (this.ficItemLabelId) {
          fic.ficItemLabelId = this.ficItemLabelId;
        }
        if (this.uniqueFic) {
          fic.uniqueFic = this.uniqueFic;
        }

        ficService.createItems(fic, event.files).then((e) => {
          Array.prototype.push.apply(this.currentValue, e);
          this.$refs.uploader.clear();
          this.$emit('added');
        });
      }
    },
    confirmDeleteFile(file) {
      this.fileAction = file;
      this.displayDeleteDialog = true;
    },
    deleteFile() {
      this.displayDeleteDialog = false;
      ficService
        .delete(this.fileAction)
        .then((e) => {
          this.currentValue = this.currentValue.filter((val) => val.id !== e.id);
          if (this.displayMessage) this.success('Le fichier a bien été supprimé');
          this.$emit('removed');
        })
        .catch(() => {
          this.addError('Une erreur est survenue, merci de réessayer plus tard !');
        });
    },
    uploadFile() {
      this.$refs.uploader.$refs.fileInput.click();
    },
  },
};
</script>

<template>
  <div v-show="displayTitle" class="header-fic-list">
    <h4>Fichiers attachés</h4>
    <FileUpload
      ref="uploader"
      :accept="accept"
      :auto="true"
      :cancel-label="$t('cancel_file')"
      :choose-label="$t('add_file')"
      :maxFileSize="getMaxFileUpload()"
      :invalidFileSizeMessage="$t('file.err_size')"
      :custom-upload="true"
      :multiple="true"
      :show-upload-button="false"
      mode="basic"
      name="uploader"
      @select="selectFiles"
      :disabled="disabled" />
  </div>

  <AkLabel v-if="label && displayLabel" :required="this.required === true">
    {{ label }}
  </AkLabel>

  <div class="form-row">
    <div
      v-for="(item, index) in currentValue"
      v-show="currentValue !== undefined && currentValue.length"
      :key="item.id"
      class="col-md-3">
      <AkFicItem
        v-model="currentValue[index]"
        :fic-preview="ficPreview"
        :canDelete="canDelete"
        @delete="confirmDeleteFile"
        @download="downloadFile" />
    </div>
    <div class="col-md-3" v-if="canUpload">
      <div class="fic-container flex-column fic-add-hover" style="border-style: dashed" @click="uploadFile">
        <div class="d-flex align-items-center justify-content-center full-width" style="height: 42px">
          <span><i class="fa fa-plus mr-1 f-18" />{{ $t(addTitle) }}</span>
        </div>
      </div>
    </div>
  </div>

  <PDialog v-model:visible="displayDeleteDialog" :header="$t('delete_dialog')" :modal="true" :style="{width: '450px'}">
    <div class="confirmation-content">
      <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
      <span v-if="fileAction">{{ $t('file_confirm_delete', {item: fileAction.fileName}) }}</span>
    </div>
    <div class="d-flex justify-content-end">
      <span class="btn btn-outline-primary btn-xs mr-1" @click="displayDeleteDialog = false">{{ $t('no') }}</span>
      <span class="btn btn-danger btn-xs" @click="deleteFile">{{ $t('yes') }}</span>
    </div>
  </PDialog>
</template>
