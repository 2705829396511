const fontAwesomeIconClasses = {
  // Media
  png: 'fa-file-image',
  jpg: 'fa-file-image',
  jpeg: 'fa-file-image',
  gif: 'fa-file-image',
  mp3: 'fa-file-audio',
  mpg: 'fa-file-video',
  mpeg: 'fa-file-video',
  mp4: 'fa-file-video',
  // Documents
  pdf: 'fa-file-pdf',
  pages: 'fa-file-word',
  doc: 'fa-file-word',
  docx: 'fa-file-word',
  odt: 'fa-file-word',
  xls: 'fa-file-excel',
  numbers: 'fa-file-excel',
  xlsx: 'fa-file-excel',
  ods: 'fa-file-excel',
  ppt: 'fa-file-powerpoint',
  pptx: 'fa-file-powerpoint',
  key: 'fa-file-powerpoint',
  odp: 'fa-file-powerpoint',
  txt: 'fa-file-text',
  htm: 'fa-file-code',
  html: 'fa-file-code',
  json: 'fa-file-code',
  // Archives
  gzip: 'fa-file-archive',
  zip: 'fa-file-archive',
};

const colorIconClasses = {
  // Media
  png: 'text-primary',
  jpg: 'text-primary',
  jpeg: 'text-primary',
  gif: 'text-primary',
  mp3: 'fa-file-audio',
  mpg: 'fa-file-video',
  mpeg: 'fa-file-video',
  mp4: 'fa-file-video',
  // Documents
  pdf: 'text-danger',
  pages: 'text-primary',
  doc: 'text-primary',
  docx: 'text-primary',
  odt: 'text-primary',
  xls: 'text-success',
  numbers: 'text-success',
  xlsx: 'text-success',
  ods: 'fa-file-excel',
  ppt: 'fa-file-powerpoint',
  pptx: 'fa-file-powerpoint',
  key: 'fa-file-powerpoint',
  odp: 'fa-file-powerpoint',
  txt: 'fa-file-text',
  htm: 'fa-file-code',
  html: 'fa-file-code',
  json: 'fa-file-code',
  // Archives
  gzip: 'fa-file-archive',
  zip: 'fa-file-archive',
};

function isValidString(o) {
  if (o === null) {
    return false;
  }
  if (typeof o == 'string' || (typeof o == 'object' && o.constructor === String)) {
    return o.length > 0;
  } else {
    return false;
  }
}

export function getFontAwesomeIconFromMIME(filename) {
  const extension = filename.split('.').pop();
  const result = fontAwesomeIconClasses[extension.toLowerCase()];
  if (isValidString(result)) {
    return result;
  } else {
    return 'fa-file';
  }
}

export function getColorIconFromMIME(filename) {
  const extension = filename.split('.').pop();
  const result = colorIconClasses[extension.toLowerCase()];
  if (isValidString(result)) {
    return result;
  } else {
    return '';
  }
}
