<script>
import useVuelidate from '@vuelidate/core';
import {required, requiredIf} from '@vuelidate/validators';

/* COMPOSANTS */
import AkClientAutocomplete from '@components/general/AkClientAutocomplete';
import AkDateTime from '@components/input/AkDateTime';
import AkDialog from '@components/general/AkDialog';
import AkDropdown from '@components/input/AkDropdown';
import AkEmployeeAutocomplete from '@components/general/AkEmployeeAutocomplete';
import AkFilesUpload from '@components/input/AkFilesUpload';
import AkOwnerAutocomplete from '@components/general/AkOwnerAutocomplete';
import AkPrestataireAutocomplete from '@components/general/AkPrestataireAutocomplete';
import AkInputEditor from '@components/input/AkInputEditor.vue';

/* MIXINS */
import activityContactWayConst from '@mixins/const/activityContactWayConst';
import activityInterlocutorTypeConst from '@mixins/const/activityInterlocutorTypeConst';
import activityMotifConst from '@mixins/const/activityMotifConst';
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import loaderMixin from '@mixins/loaderMixin';

/* SERVICES */
import accommodationService from '@services/accommodationService';
import activityService from '@services/activityService';
import reservationService from '@services/reservationService';
import employeeService from '@services/employeeService';

export default {
  components: {
    AkInputEditor,
    AkClientAutocomplete,
    AkDateTime,
    AkDialog,
    AkDropdown,
    AkEmployeeAutocomplete,
    AkFilesUpload,
    AkOwnerAutocomplete,
    AkPrestataireAutocomplete,
  },
  mixins: [
    activityContactWayConst,
    activityInterlocutorTypeConst,
    activityMotifConst,
    permissionsMixin,
    utilsMixin,
    loaderMixin,
  ],
  props: {
    displayIcon: {type: Boolean, required: false, default: false},
    interlocutorType: {type: String, default: undefined},
    accommodationId: {type: Number, default: undefined},
    reservationId: {type: Number, default: undefined},
    ownerId: {type: Number, default: undefined},
    prospectId: {type: Number, default: undefined},
    clientId: {type: Number, default: undefined},
    employeeId: {type: Number, default: undefined},
    prestataireId: {type: Number, default: undefined},
  },
  emits: ['added', 'failed'],
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      activity: {},
      files: [],
      employees: null,
      accommodations: null,
      reservations: null,
    };
  },
  validations() {
    return {
      activity: {
        interlocutorType: {required},
        interlocutorId: {required},
        date: {required},
        contactWay: {required},
        motif: {required},
        who: {},
        comment: {required},
        accommodationId: {required: requiredIf(this.isAccommodationIdRequired)},
        reservationId: {required: requiredIf(this.isReservationIdRequired)},
        ownerId: {required: requiredIf(this.isOwnerIdRequired)},
        prospectId: {required: requiredIf(this.isProspectIdRequired)},
        clientId: {required: requiredIf(this.isClientIdRequired)},
        employeeId: {required: requiredIf(this.isEmployeeIdRequired)},
        prestataireId: {required: requiredIf(this.isPrestataireIdRequired)},
      },
    };
  },
  mounted() {},
  methods: {
    fillInterlocutorId() {
      if (this.isInterlocutorTypeAccommodation) this.activity.interlocutorId = this.activity.accommodationId;
      else if (this.isInterlocutorTypeReservation) this.activity.interlocutorId = this.activity.reservationId;
      else if (this.isInterlocutorTypeProprietaire) this.activity.interlocutorId = this.activity.ownerId;
      else if (this.isInterlocutorTypeProspect) this.activity.interlocutorId = this.activity.prospectId;
      else if (this.isInterlocutorTypeVoyageur) this.activity.interlocutorId = this.activity.clientId;
      else if (this.isInterlocutorTypeSalarie) this.activity.interlocutorId = this.activity.employeeId;
      else if (this.isInterlocutorTypePrestataire) this.activity.interlocutorId = this.activity.prestataireId;
    },
    create() {
      this.fillInterlocutorId();

      this.v$.$touch();
      if (this.v$.$error) return;

      this.showTotalLoader();

      activityService
        .create(this.activity, this.files)
        .then(() => {
          this.$refs.dialog.hide();
          this.$emit('added');
          this.$store.commit('ACTIVITY_ADDED');
        })
        .catch(() => {
          this.$emit('failed');
        })
        .finally(() => {
          this.hideLoader();
          this.activity = {};
        });
    },
    initData() {
      this.activity = {
        date: new Date(),
        interlocutorId: null,
        contactWay: null,
        who: null,
        motif: null,
        comment: null,
        interlocutorType: this.interlocutorType,
        accommodationId: this.accommodationId,
        reservationId: this.reservationId,
        ownerId: this.ownerId,
        prospectId: this.prospectId,
        clientId: this.clientId,
        employeeId: this.employeeId,
        prestataireId: this.prestataireId,
      };
      this.employees = [];
      employeeService.employeesForNewActivity().then((data) => {
        this.employees = data;
        this.insertFirst(this.employees, {id: undefined, display: this.$t('undefined')});
      });
    },
    loadCandidats() {
      //LOAD CANDIDATS
    },
    loadPrestataires() {
      //LOAD PRESTATAIRES
    },
    loadAccommodationsForCustomer() {
      this.accommodations = null;
      let search = {ownerId: this.activity.ownerId};
      accommodationService.accommodations(search).then((data) => {
        this.accommodations = data;
      });
    },
    loadReservationsForVoyageur() {
      this.reservations = null;
      let search = {clientId: this.activity.clientId};
      reservationService.reservations(search).then((data) => {
        this.reservations = data;
      });
    },
    resetInterlocutorId() {
      !this.isInterlocutorTypeProspect ? (this.activity.prospectId = null) : '';
      !this.isInterlocutorTypeSalarie ? (this.activity.employeeId = null) : '';
      !this.isInterlocutorTypePrestataire ? (this.activity.prestataireId = null) : '';
      if (!this.isInterlocutorTypeAccommodation && this.isInterlocutorTypeVisible) {
        this.activity.ownerId = null;
        this.activity.accommodationId = null;
        this.accommodations = null;
      }
      if (!this.isInterlocutorTypeProprietaire && this.isInterlocutorTypeVisible) {
        this.activity.ownerId = null;
      }
      if (!this.isInterlocutorTypeReservation && this.isInterlocutorTypeVisible) {
        this.activity.clientId = null;
        this.activity.reservationId = null;
        this.reservations = null;
      }
      if (!this.isInterlocutorTypeVoyageur && this.isInterlocutorTypeVisible) {
        this.activity.clientId = null;
      }
    },
  },

  computed: {
    // INTERLOCUTOR TYPE

    isInterlocutorTypeProprietaire() {
      return this.activity.interlocutorType === 'PROPRIETAIRE';
    },
    isInterlocutorTypeProspect() {
      return this.activity.interlocutorType === 'PROSPECT';
    },
    isInterlocutorTypeReservation() {
      return this.activity.interlocutorType === 'RESERVATION';
    },
    isInterlocutorTypeAccommodation() {
      return this.activity.interlocutorType === 'ACCOMMODATION';
    },
    isInterlocutorTypeVoyageur() {
      return this.activity.interlocutorType === 'VOYAGEUR';
    },
    isInterlocutorTypePrestataire() {
      return this.activity.interlocutorType === 'PRESTATAIRE';
    },
    isInterlocutorTypeCandidat() {
      return this.activity.interlocutorType === 'CANDIDAT';
    },
    isInterlocutorTypeSalarie() {
      return this.activity.interlocutorType === 'SALARIE';
    },

    // REQUIRED

    isAccommodationIdRequired() {
      return this.isInterlocutorTypeAccommodation;
    },
    isReservationIdRequired() {
      return this.isInterlocutorTypeReservation;
    },
    isOwnerIdRequired() {
      return this.isInterlocutorTypeProprietaire || this.isInterlocutorTypeAccommodation;
    },
    isProspectIdRequired() {
      return this.isInterlocutorTypeProspect;
    },
    isClientIdRequired() {
      return this.isInterlocutorTypeVoyageur || this.isInterlocutorTypeReservation;
    },
    isEmployeeIdRequired() {
      return this.isInterlocutorTypeSalarie;
    },
    isPrestataireIdRequired() {
      return this.isInterlocutorTypePrestataire;
    },

    // VISIBLE

    isInterlocutorTypeVisible() {
      return this.interlocutorType === undefined;
    },

    // pour accommodation : ownerId* -> accommodationId*

    isOwnerAutocompleteVisibleForAccommodation() {
      return this.isInterlocutorTypeAccommodation && !this.ownerId && !this.accommodationId;
    },
    isAccommodationDropdownVisibleForAccommodation() {
      return this.isInterlocutorTypeAccommodation && !this.accommodationId && this.accommodations;
    },

    // pour reservation : clientId* -> reservationId*

    isClientAutocompleteVisibleForReservation() {
      return this.isInterlocutorTypeReservation && !this.clientId && !this.reservationId;
    },
    isReservationDropdownVisibleForReservation() {
      return this.isInterlocutorTypeReservation && !this.reservationId && this.reservations;
    },

    // pour customer : ownerId*

    isOwnerAutocompleteVisibleForCustomer() {
      return this.isInterlocutorTypeProprietaire && !this.ownerId;
    },

    // pour prospect : ownerId*

    isOwnerAutocompleteVisibleForProspect() {
      return this.isInterlocutorTypeProspect && !this.prospectId;
    },

    // pour voyageur : clientId*

    isClientAutocompleteVisibleForVoyageur() {
      return this.isInterlocutorTypeVoyageur && !this.clientId;
    },
    // pour salarie : employeeId*

    isEmployeeAutocompleteVisibleForSalarie() {
      return this.isInterlocutorTypeSalarie && !this.employeeId;
    },

    // pour prestataire : prestataireId*

    isPrestataireAutocompleteVisibleForPrestataire() {
      return this.isInterlocutorTypePrestataire && !this.prestataireId;
    },
  },

  watch: {
    'activity.ownerId'(newVal, oldVal) {
      if (newVal && this.isInterlocutorTypeAccommodation) {
        this.loadAccommodationsForCustomer();
      } else if (newVal === null) {
        this.activity.accommodationId = null;
        this.accommodations = null;
      }
    },
    'activity.clientId'(newVal, oldVal) {
      if (newVal && (this.isInterlocutorTypeReservation || this.isInterlocutorTypeVoyageur)) {
        this.loadReservationsForVoyageur();
      } else if (newVal === null) {
        this.activity.reservationId = null;
        this.reservations = null;
      }
    },
    'activity.interlocutorType'(newVal, oldVal) {
      this.resetInterlocutorId();
    },
  },
};
</script>

<template>
  <span v-if="displayIcon" class="fa fa-bell pointer mr-3 primary-hover f-22" @click="$refs.dialog.show()" />
  <button v-else class="btn btn-inverse-primary btn-xs" @click="$refs.dialog.show()">
    <i class="gst-plus pr-1" />{{ $t('activity.add') }}
  </button>

  <AkDialog
    ref="dialog"
    :title="$t('activity.new')"
    :validate-label="$t('create')"
    width="800px"
    @show="initData"
    @validate="create">
    <div class="form-row">
      <AkDropdown
        v-if="isInterlocutorTypeVisible"
        v-model="activity.interlocutorType"
        :validator="v$.activity.interlocutorType"
        :label="$t('activity.interlocutorType')"
        :options="activityInterlocutorTypeConst"
        class-name="col-md-4" />

      <AkOwnerAutocomplete
        v-if="isOwnerAutocompleteVisibleForAccommodation"
        v-model="activity.ownerId"
        :validator="v$.activity.ownerId"
        owner-status="CUSTOMER"
        :label="$t('owner.ownerDisplay')"
        :placeholder="$t('owner.ownerDisplay')"
        class-name="col-md-4" />
      <AkDropdown
        v-if="isAccommodationDropdownVisibleForAccommodation"
        v-model="activity.accommodationId"
        :validator="v$.activity.accommodationId"
        :label="$t('activity.accommodation')"
        :placeholder="$t('activity.accommodation')"
        :options="accommodations"
        option-label="name"
        option-value="id"
        class-name="col-md-4" />

      <AkClientAutocomplete
        v-if="isClientAutocompleteVisibleForReservation"
        v-model="activity.clientId"
        :validator="v$.activity.clientId"
        :label="$t('activity.voyageur')"
        :placeholder="$t('activity.voyageur')"
        class-name="col-md-4" />
      <AkDropdown
        v-if="isReservationDropdownVisibleForReservation"
        v-model="activity.reservationId"
        :validator="v$.activity.reservationId"
        :label="$t('activity.reservation')"
        :placeholder="$t('activity.reservation')"
        :options="reservations"
        option-label="display"
        option-value="id"
        class-name="col-md-4" />

      <AkOwnerAutocomplete
        v-if="isOwnerAutocompleteVisibleForCustomer"
        v-model="activity.ownerId"
        :validator="v$.activity.ownerId"
        owner-status="CUSTOMER"
        :label="$t('owner.ownerDisplay')"
        :placeholder="$t('owner.ownerDisplay')"
        class-name="col-md-4" />

      <AkOwnerAutocomplete
        v-if="isOwnerAutocompleteVisibleForProspect"
        v-model="activity.prospectId"
        :validator="v$.activity.prospectId"
        owner-status="PROSPECT"
        :label="$t('ticket.prospect_display')"
        :placeholder="$t('ticket.prospect_display')"
        class-name="col-md-4" />

      <AkClientAutocomplete
        v-if="isClientAutocompleteVisibleForVoyageur"
        v-model="activity.clientId"
        :validator="v$.activity.clientId"
        :label="$t('activity.voyageur')"
        :placeholder="$t('activity.voyageur')"
        class-name="col-md-4" />

      <AkEmployeeAutocomplete
        v-if="isEmployeeAutocompleteVisibleForSalarie"
        v-model="activity.employeeId"
        :validator="v$.activity.employeeId"
        :label="$t('activity.salarie')"
        :placeholder="$t('activity.salarie')"
        class-name="col-md-4" />

      <AkPrestataireAutocomplete
        v-if="isPrestataireAutocompleteVisibleForPrestataire"
        v-model="activity.prestataireId"
        :validator="v$.activity.prestataireId"
        :label="$t('activity.prestataire')"
        :placeholder="$t('activity.prestataire')"
        class-name="col-md-4" />
    </div>

    <div class="form-row">
      <div class="col-md-4">
        <div class="form-row">
          <AkDateTime
            v-model="activity.date"
            :label="$t('activity.dateHour')"
            :validator="v$.activity.date"
            class-name="col-md-12" />
        </div>
        <div class="form-row">
          <AkDropdown
            v-if="employees"
            v-model="activity.who"
            :label="$t('activity.who')"
            :options="employees"
            class-name="col-md-12"
            option-label="display"
            option-value="id" />
        </div>
        <div class="form-row">
          <AkDropdown
            v-model="activity.contactWay"
            :label="$t('activity.contactWay')"
            :options="activityContactWayConst"
            :validator="v$.activity.contactWay"
            class-name="col-md-12" />
        </div>
        <div class="form-row">
          <AkDropdown
            v-model="activity.motif"
            :label="$t('activity.motif')"
            :options="activityMotifConst"
            :validator="v$.activity.motif"
            class-name="col-md-12" />
        </div>
      </div>
      <div class="col-md-8">
        <div class="form-row">
          <AkInputEditor
            v-model="activity.comment"
            :label="$t('activity.comment')"
            :validator="v$.activity.comment"
            class-name="col-md-12"
            style="white-space: pre"
            :rows="13" />
        </div>
      </div>
    </div>

    <div class="form-row">
      <AkFilesUpload v-model="files" :label="$t('activity.files')" class-name="col-md-12" />
    </div>
  </AkDialog>
</template>
