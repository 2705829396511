<script>
import useVuelidate from '@vuelidate/core';

/* COMPOSANTS */
import LitigeItem from '@views/litige/components/LitigeItem.vue';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';

/* SERVICES */
import permissionService from '@services/permissionService';

export default {
  components: {LitigeItem},
  mixins: [permissionsMixin],
  props: {
    modelValue: {
      required: true,
    },
    submitted: {
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayMaintenanceFiles: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayMaintenanceDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    canDeleteFiles: {
      type: Boolean,
      required: false,
      default: true,
    },
    canUploadFiles: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {};
  },
  emits: ['removed', 'amount-changed'],
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    permissionsMixin() {
      return permissionsMixin;
    },
    permissionService() {
      return permissionService;
    },
  },
  methods: {
    removed() {
      this.$emit('removed');
    },
    amountChanged() {
      this.$emit('amount-changed');
    },
  },
};
</script>

<template>
  <LitigeItem
    v-for="(item, idx) in this.currentValue"
    ref="item"
    v-bind:key="item.id"
    v-model="this.currentValue[idx]"
    :submitted="this.submitted"
    :disabled="this.disabled"
    :index="idx"
    :displayMaintenanceFiles="displayMaintenanceFiles"
    :displayMaintenanceDate="displayMaintenanceDate"
    :canDeleteFiles="canDeleteFiles"
    :canUploadFiles="canUploadFiles"
    @amountChanged="amountChanged"
    @removed="removed" />
</template>
