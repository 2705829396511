import Api from '@/services/api';
import {createFormDataFile, dateToUtc, removeEmptyParams} from '@utils';

export default {
  mandats(filter) {
    return Api()
      .get('/mandats', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  mandatsByAccommodation(accommodationId) {
    return Api()
      .get('/mandats/accommodation/' + accommodationId)
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  mandatsByOwner(ownerId) {
    return Api()
      .get('/mandats/owner/' + ownerId)
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  mandat(id) {
    return Api()
      .get('/mandat/' + id)
      .then((res) => this.formatRes(res.data));
  },
  mandatByNumWithLastMandatData(num) {
    return Api()
      .get('/mandat/num/' + num)
      .then((res) => this.formatRes(res.data));
  },
  mandatByIdForResiliation(id) {
    return Api()
      .get('/mandat/resiliation/' + id)
      .then((res) => this.formatRes(res.data));
  },
  update(mandat) {
    return Api()
      .put('/mandat/' + mandat.id, mandat)
      .then((res) => this.formatRes(res.data));
  },
  delete(mandat) {
    return Api()
      .delete('/mandat/' + mandat.id)
      .then((res) => this.formatRes(res.data));
  },
  create(mandat, file) {
    let data = createFormDataFile(mandat, file);
    return Api()
      .post('/mandat', data)
      .then((res) => this.formatRes(res.data));
  },
  createAvenant(avenant) {
    return Api()
      .post('/mandat/avenant', avenant)
      .then((res) => this.formatRes(res.data));
  },
  avenantsNotSigned() {
    return Api()
      .get('/mandat/avenants/notsigned')
      .then((res) => this.formatRes(res.data));
  },
  avenantSigned(avenant) {
    return Api()
      .post('/mandat/avenant/signed', avenant)
      .then((res) => this.formatRes(res.data));
  },
  resiliate(mandat, file) {
    let data = createFormDataFile(this.formatSend(mandat), file);
    return Api()
      .put('/mandat/' + mandat.id + '/resiliate', data)
      .then((res) => this.formatRes(res.data));
  },
  signAvenant(mandat, file) {
    let data = createFormDataFile(this.formatSend(mandat), file);
    return Api()
      .put('/mandat/' + mandat.id + '/signavenant', data)
      .then((res) => this.formatRes(res.data));
  },
  formatRes(e) {
    return e;
  },
  formatSend(e) {
    return e;
  },
};
