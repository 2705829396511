<script>
/* COMPOSANTS */
import PButton from 'primevue/button';
import GaView from '@components/layout/GaView';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import randomRef from '@mixins/randomRef';
import permissionsMixin from '@mixins/permissionsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import reviewService from '@services/reviewService';

export default {
  components: {PButton, GaView},
  mixins: [utilsMixin, randomRef, sidebarMixin, loaderMixin, permissionsMixin, messageMixin],
  setup() {
    return {};
  },
  metaInfo() {
    return {
      title: 'review.title',
    };
  },
  data() {
    return {
      loading: true,
      filter: {
        page: 1,
        limit: 10,
        sort: ['submitted_at:DESC'],
      },
      list: [],
      totalItems: 0,
    };
  },
  mounted() {
    this.refresh();
  },
  computed: {
    isEmptyList() {
      return !this.list || this.list.length === 0;
    },
  },
  methods: {
    refresh() {
      reviewService
        .reviewsPagination(this.filter)
        .then((data) => {
          this.list = data.data;
          this.totalItems = data.items;
        })
        .finally(() => (this.loading = false));
    },
    onPage(event) {
      this.filter.page = event.page + 1;
      this.filter.limit = event.rows;
      this.refresh();
    },

    // FILTER

    openFilterPanel() {
      this.toggleFilter('GaReviewListFilter', this.filter, this.refresh, this.resetFilter);
    },
    resetFilter() {
      this.filter = {
        page: 1,
        limit: 10,
      };
      this.toggleFilter('GaReviewListFilter', this.filter, this.refresh, this.resetFilter);
    },

    // SIDEBAR

    rowClick(event) {
      let data = event.data;
      this.toggleSidebar('GaReviewSidebar', this.$t('review.details'), data.id);
    },

    // EXPORT

    exportList() {
      this.showTotalLoaderWithAfter(this.$t('review.list_creation_in_progress'));
      reviewService
        .exportList(this.filter)
        .then((data) => {})
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.hideLoader();
        });
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :title="$t('review.title')">
    <template #action>
      <div class="d-flex justify-content-end" style="gap: 16px">
        <PButton
          class="btn btn-primary"
          @click="openFilterPanel()"
          :badge="this.countFilter(this.filter)"
          badgeClass="p-badge-primary"
          :label="$t('review.filter')">
        </PButton>

        <button class="btn btn-primary" @click="exportList()" :disabled="this.isEmptyList">
          {{ this.$t('review.export') }}
        </button>
      </div>
    </template>

    <template #content>
      <DataTable
        ref="table"
        :always-show-paginator="false"
        :loading="loading"
        :paginator="true"
        :rows="10"
        :lazy="true"
        :totalRecords="this.totalItems"
        :rows-per-page-options="[10, 20, 50]"
        :value="list"
        class="table pointer"
        current-page-report-template="{first}-{last}/{totalRecords}"
        responsive-layout="scroll"
        striped-rows
        removable-sort
        @page="onPage($event)"
        @row-click="rowClick($event)">
        <template #empty>
          {{ $t('review.empty') }}
        </template>

        <Column :header="$t('review.company_name')" v-if="this.isGas()">
          <template #body="slotProps">
            <span>
              {{ slotProps.data.companyName }}
            </span>
          </template>
        </Column>

        <Column :header="$t('review.reservation_num')">
          <template #body="slotProps">
            <span>
              {{ slotProps.data.reservationNum }}
            </span>
          </template>
        </Column>

        <Column :header="$t('review.rating_ten')">
          <template #body="slotProps">
            <span>
              {{ this.formatAmount(slotProps.data.ratingTen) }}
            </span>
          </template>
        </Column>

        <Column :header="$t('review.review_title')">
          <template #body="slotProps">
            <span>
              {{ slotProps.data.title }}
            </span>
          </template>
        </Column>

        <Column :header="$t('review.reservation_site')">
          <template #body="slotProps">
            <span>
              {{ slotProps.data.reservationSite }}
            </span>
          </template>
        </Column>

        <Column :header="$t('review.accommodation_name')">
          <template #body="slotProps">
            <span>
              {{ slotProps.data.accommodationName }}
            </span>
          </template>
        </Column>

        <Column :header="$t('review.owner_name')">
          <template #body="slotProps">
            <span>
              {{ slotProps.data.ownerName }}
            </span>
          </template>
        </Column>

        <Column :header="$t('review.submitted_at')">
          <template #body="slotProps">
            <span>
              {{ this.formatDateIfNeeded(slotProps.data.submittedAt) }}
            </span>
          </template>
        </Column>

        <Column :header="$t('review.name')">
          <template #body="slotProps">
            <span>
              {{ slotProps.data.name }}
            </span>
          </template>
        </Column>

        <Column :header="$t('review.comment')">
          <template #body="slotProps">
            <span class="overflow-hidden text-overflow-ellipsis text-break">
              {{ slotProps.data.comment }}
            </span>
          </template>
        </Column>
      </DataTable>
    </template>
  </GaView>
</template>
