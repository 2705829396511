<script>
import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

/* COMPOSANTS */
import AkInputText from '@components/input/AkInputText';
import AkDropdown from '@components/input/AkDropdown';
import AkDialog from '@components/general/AkDialog';
import GaView from '@components/layout/GaView';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import zoneTypesConst from '@mixins/const/zoneTypesConst';

/* SERVICES */
import itemLabelService from '@services/itemLabelService';
import zoneService from '@services/zoneService';

export default {
  components: {GaView, AkDropdown, AkInputText, AkDialog},
  mixins: [randomRef, utilsMixin, zoneTypesConst],
  metaInfo() {
    return {
      title: 'settings.zones',
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      loading: true,
      list: [],
      item: {},
    };
  },
  validations() {
    return {
      item: {
        label: {required},
        type: {required},
      },
    };
  },
  computed: {
    listZones() {
      return this.list.filter((elem) => elem.type === 'ZONE');
    },
    listSubzones() {
      return this.list.filter((elem) => elem.type === 'SUBZONE');
    },
  },
  mounted() {
    zoneService.zones().then((data) => {
      this.list = data;
      this.loading = false;
    });
  },
  methods: {
    create() {
      this.v$.$touch();
      if (this.v$.$error) return;

      this.getRef().showTotalLoader();
      itemLabelService
        .create(this.item)
        .then((data) => {
          this.list.push(data);
          this.getRef().success(this.$t(data.type === 'ZONE' ? 'zone.added' : 'subzone.added'));
        })
        .finally(() => {
          this.getRef().hideLoader();
          this.$refs.dialogCreate.hide();
        });
    },
    update() {
      this.v$.$touch();
      if (this.v$.$error) return;

      this.getRef().showTotalLoader();
      itemLabelService
        .update(this.item)
        .then((data) => {
          this.replaceInListById(this.list, data);
          this.getRef().success(this.$t(data.type === 'ZONE' ? 'zone.updated' : 'subzone.updated'));
        })
        .finally(() => {
          this.getRef().hideLoader();
          this.$refs.dialogEdit.hide();
        });
    },
    delete() {
      itemLabelService
        .delete(this.item)
        .then((data) => {
          this.removeInListById(this.list, data.id);
          this.getRef().success(data.label + ' a bien été supprimé');
        })
        .catch((e) => {
          if (e.response.status === 406) {
            this.getRef().error("La zone ne peut être supprimée parce qu'elle est déjà utilisée");
          } else {
            this.getRef().error(this.$t('unknow_error'));
          }
        });
    },
    openCreateDialog() {
      this.item = {};
      this.$refs.dialogCreate.show();
    },
    openEditDialog(item) {
      this.item = {...item};
      this.$refs.dialogEdit.show();
    },
    openDeleteDialog(item) {
      this.item = item;
      this.$refs.dialogDelete.show();
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :title="$t('settings.zones')">
    <template #action>
      <div class="d-flex justify-content-end">
        <button class="btn btn-primary float-right" @click="openCreateDialog()">
          <i class="gst-plus pr-1" />{{ $t('add') }}
        </button>
      </div>
    </template>

    <template #content>
      <div class="row">
        <div class="col-lg-6">
          <h6>{{ $t('zone.zones') }}</h6>
          <DataTable
            :always-show-paginator="false"
            :loading="loading"
            :paginator="true"
            :rows="10"
            :rows-per-page-options="[10, 20, 50]"
            :value="listZones"
            class="table"
            current-page-report-template="{first}-{last}/{totalRecords}"
            removable-sort
            responsive-layout="scroll"
            sort-field="position"
            striped-rows>
            <template #empty>
              {{ $t('zone.empty') }}
            </template>
            <Column :header="$t('item_label.label')">
              <template #body="slotProps">
                {{ slotProps.data.label }}
              </template>
            </Column>
            <Column :header="$t('item_label.type')">
              <template #body="slotProps">
                {{ displayForZoneTypeConst(slotProps.data.type) }}
              </template>
            </Column>

            <Column body-style="text-align: right; overflow: visible" header-style=" width: 160px; text-align: btn">
              <template #body="slotProps">
                <div class="d-flex justify-content-end">
                  <span class="btn btn-xs btn-inverse-primary mr-1 pointer" @click="openEditDialog(slotProps.data)">
                    <i class="fe fe-edit" />
                  </span>
                  <span class="btn btn-xs btn-inverse-danger pointer" @click="openDeleteDialog(slotProps.data)">
                    <i class="fe fe-trash"
                  /></span>
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
        <div class="col-lg-6">
          <h6>{{ $t('zone.subzones') }}</h6>
          <DataTable
            :always-show-paginator="false"
            :loading="loading"
            :paginator="true"
            :rows="10"
            :rows-per-page-options="[10, 20, 50]"
            :value="listSubzones"
            class="table"
            current-page-report-template="{first}-{last}/{totalRecords}"
            removable-sort
            responsive-layout="scroll"
            sort-field="position"
            striped-rows>
            <template #empty>
              {{ $t('subzone.empty') }}
            </template>
            <Column :header="$t('item_label.label')">
              <template #body="slotProps">
                {{ slotProps.data.label }}
              </template>
            </Column>
            <Column :header="$t('item_label.type')">
              <template #body="slotProps">
                {{ displayForZoneTypeConst(slotProps.data.type) }}
              </template>
            </Column>

            <Column body-style="text-align: right; overflow: visible" header-style=" width: 160px; text-align: btn">
              <template #body="slotProps">
                <div class="d-flex justify-content-end">
                  <span class="btn btn-xs btn-inverse-primary mr-1 pointer" @click="openEditDialog(slotProps.data)">
                    <i class="fe fe-edit" />
                  </span>
                  <span class="btn btn-xs btn-inverse-danger pointer" @click="openDeleteDialog(slotProps.data)">
                    <i class="fe fe-trash" />
                  </span>
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </template>

    <template #extra>
      <AkDialog
        ref="dialogCreate"
        :title="$t('zone.add')"
        :validate-label="$t('add')"
        width="450px"
        @validate="create()">
        <div class="form-row">
          <AkDropdown
            v-model="item.type"
            :label="$t('zone.type')"
            :options="zoneTypesConst"
            :validator="v$.item.type"
            class-name="col-12" />

          <AkInputText v-model="item.label" :label="$t('zone.label')" :validator="v$.item.label" class-name="col-12" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogEdit"
        :title="$t('zone.update')"
        :validate-label="$t('update')"
        width="450px"
        @validate="update()">
        <div class="form-row">
          <AkDropdown
            v-model="item.type"
            :label="$t('zone.type')"
            :options="zoneTypesConst"
            :validator="v$.item.type"
            class-name="col-12" />

          <AkInputText v-model="item.label" :label="$t('zone.label')" :validator="v$.item.label" class-name="col-12" />
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogDelete"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.delete()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span v-if="this.item.type === 'ZONE'">{{ $t('zone.confirm_delete') }}</span>
          <span v-if="this.item.type === 'SUBZONE'">{{ $t('subzone.confirm_delete') }}</span>
        </div>
      </AkDialog>
    </template>
  </GaView>
</template>
