<script>
/* COMPOSANTS */
import AkAutocomplete from '@components/general/AkAutocomplete';

/* MIXINS */
import loaderMixin from '@mixins/loaderMixin';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */

export default {
  components: {
    AkAutocomplete,
  },
  mixins: [loaderMixin, utilsMixin],
  props: {
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    modelValue: {
      type: [String, Number],
      required: false,
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    dropdown: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    className: {
      type: String,
      required: false,
      default: 'col-md-4 col-6',
    },
    options: {
      type: Array,
      required: true,
    },
    optionLabel: {
      type: [String, Function],
      required: false,
      default: 'label',
    },
    optionValue: {
      type: [String, Function],
      required: false,
      default: 'id',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      items: [],
    };
  },
  mounted() {},
  methods: {
    search(event) {
      let query = event.query;
      this.items = this.options.filter((item) => this.isValidItem(item, query));
    },
    isValidItem(item, query) {
      return this.displayFor(item).includes(query);
    },
    displayFor(item) {
      let display = this.apply(item, this.optionLabel);
      return display ? display : '?';
    },
    valueFor(item) {
      let value = this.apply(item, this.optionValue);
      return value ? value : '?';
    },
    reset() {
      this.$refs.autocomplete.reset();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    currentItem() {
      if (!this.items) return null;
      return this.items.find((item) => this.valueFor(item) === this.currentValue);
    },
  },
  watch: {
    options(newVal, oldVal) {
      this.items = newVal;
      this.$refs.autocomplete.init(this.currentItem);
    },
  },
};
</script>

<template>
  <AkAutocomplete
    ref="autocomplete"
    v-model="currentValue"
    :inline="inline"
    :validator="validator"
    :submitted="submitted"
    :disabled="disabled"
    :label="label"
    :placeholder="placeholder"
    :className="className"
    :option-label="optionLabel"
    :option-value="optionValue"
    :items="items"
    :dropdown="dropdown"
    @search="search">
    <template #item="slotProps">
      <div>{{ this.displayFor(slotProps.item) }}</div>
    </template>
  </AkAutocomplete>
</template>
