<script>
/* COMPOSANTS */

/* MIXINS */

/* SERVICES */

export default {
  components: {},
  props: {
    modelValue: {required: true},
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <div v-if="currentValue">
    <h4>{{ this.$t('operation.comment') }}</h4>
    <h5>{{ currentValue.label }}</h5>
    <p style="white-space: pre">{{ currentValue.comment }}</p>
  </div>
</template>
