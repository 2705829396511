<script>
/* COMPOSANTS */
import AkLoader from '@components/general/AkLoader';
import EmployeeAbsences from '@views/employee/tabs/EmployeeAbsences';
import EmployeeActivity from '@views/employee/tabs/EmployeeActivity';
import EmployeeContracts from '@views/employee/tabs/EmployeeContracts';
import EmployeeDetails from '@views/employee/tabs/EmployeeDetails';
import EmployeeEpi from '@views/employee/tabs/EmployeeEpi';
import EmployeeFiles from '@views/employee/tabs/EmployeeFiles';
import EmployeeRestrictions from '@views/employee/tabs/EmployeeRestrictions';
import EmployeeUnavailability from '@views/employee/tabs/EmployeeUnavailability';
import GaTabView from '@components/layout/GaTabView';

/* MIXINS */
import backMixin from '@mixins/backMixin';
import employeeTabMixin from '@mixins/tab/employeeTabMixin';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import employeeService from '@services/employeeService';

export default {
  components: {
    AkLoader,
    EmployeeActivity,
    EmployeeAbsences,
    EmployeeDetails,
    EmployeeContracts,
    EmployeeFiles,
    EmployeeEpi,
    EmployeeRestrictions,
    EmployeeUnavailability,
    GaTabView,
  },
  mixins: [messageMixin, randomRef, loaderMixin, utilsMixin, employeeTabMixin, backMixin],
  metaInfo() {
    return {
      title: 'employee.details',
    };
  },
  data() {
    return {
      employeeId: undefined,
      employee: {},
      activeTab: undefined,
    };
  },
  mounted() {
    if (this.$route.hash) {
      this.activeTab = this.$route.hash.slice(1);
    }
    this.refresh();
  },
  methods: {
    refresh() {
      this.$refs.loader.load();
      this.employeeId = parseInt(this.$route.params.employeeId);

      let p1 = employeeService.employee(this.employeeId);
      p1.then((data) => {
        this.employee = data;

        this.employee.birthdate = this.displayUTCDate(data.birthdate);
        this.employee.idValidUntil = this.displayUTCDate(data.idValidUntil);
        this.employee.drivingPermitObtainingDate = this.displayUTCDate(data.drivingPermitObtainingDate);
        this.employee.drivingPermitValidityDate = this.displayUTCDate(data.drivingPermitValidityDate);
        this.employee.seniorityDate = this.displayUTCDate(data.seniorityDate);
        this.employee.entryDate = this.displayUTCDate(data.entryDate);
        this.employee.entryHour = this.displayUTCTime(data.entryHour);
        this.employee.lastMedicalVisitDate = this.displayUTCDate(data.lastMedicalVisitDate);
        this.employee.nextMedicalVisitDate = this.displayUTCDate(data.nextMedicalVisitDate);
        this.employee.validityStartDate = this.displayUTCDate(data.validityStartDate);
        this.employee.validityEndDate = this.displayUTCDate(data.validityEndDate);
        this.employee.renewalApplicationSubmissionDate = this.displayUTCDate(data.renewalApplicationSubmissionDate);
        this.employee.disabilityValidityStartDate = this.displayUTCDate(data.disabilityValidityStartDate);
        this.employee.pensionStartDate = this.displayUTCDate(data.pensionStartDate);
        this.employee.commitmentEndDate = this.displayUTCDate(data.commitmentEndDate);
      });

      Promise.all([p1]).then(() => {
        this.$refs.loader.hide();
      });
    },
  },
  computed: {},
};
</script>

<template>
  <AkLoader ref="loader" :message="$t('currently_loading')" :scale="1.5">
    <template #content>
      <GaTabView :firstTab="this.activeTab" :ref="ref" :tabs="tabs" :title="employee.display">
        <template #back>
          <a class="mb-1 d-block pointer" @click="goBack()">
            <i class="fe fe-arrow-left pr-1" />
            {{ $t('back') }}
          </a>
        </template>
        <template #employeeDetails>
          <EmployeeDetails v-model="this.employee" />
        </template>
        <template #employeeFiles>
          <EmployeeFiles v-model="this.employee" />
        </template>
        <template #employeeContracts>
          <EmployeeContracts v-model="this.employee" />
        </template>
        <template #employeeRestrictions>
          <EmployeeRestrictions v-model="this.employee" />
        </template>
        <template #employeeAbsences>
          <EmployeeAbsences v-model="this.employee" />
        </template>
        <template #employeeUnavailability>
          <EmployeeUnavailability v-model="this.employee" />
        </template>
        <template #employeeEpi>
          <EmployeeEpi v-model="this.employee" />
        </template>
        <template #employeeActivity>
          <EmployeeActivity v-model="this.employee" />
        </template>
      </GaTabView>
    </template>
  </AkLoader>
</template>
