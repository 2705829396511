<script>
/* COMPOSANTS */
import InputText from 'primevue/inputtext';
import PButton from 'primevue/button';
import GaDateIntervalGroup from '@components/ga/input/GaDateIntervalGroup';
import MissionWaitingOrdersDetails from '@views/operationnel/waitingorders/MissionWaitingOrdersDetails.vue';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';

/* SERVICES */
import missionWaitingOrderService from '@services/missionWaitingOrderService';

export default {
  components: {
    InputText,
    PButton,
    GaDateIntervalGroup,
    MissionWaitingOrdersDetails,
  },
  mixins: [permissionsMixin, utilsMixin, sidebarMixin],
  metaInfo() {
    return {
      title: 'waiting_orders.title',
    };
  },
  data() {
    return {
      list: [],
      loading: false,
      startingDate: new Date(), // Date du jour
      endingDate: new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()), // Date du jour + 1 an
      filter: {
        page: 0,
        limit: 10,
      },
      textSearch: '',
      chosenReservation: null,
      totalRecords: 0,
    };
  },
  watch: {
    startingDate: function () {
      this.filter.page = 0;
      this.onPage({page: this.filter.page, rows: this.filter.limit});
    },
    endingDate: function () {
      this.filter.page = 0;
      this.onPage({page: this.filter.page, rows: this.filter.limit});
    },
  },
  mounted() {
    this.onPage({page: this.filter.page, rows: this.filter.limit});
  },
  computed: {
    first() {
      return (this.filter.page - 1) * this.filter.limit;
    },
  },
  methods: {
    // FILTER

    openFilterPanel() {
      this.toggleFilter('GaWaitingOrdersFilter', this.filter, this.refresh, this.resetFilter);
    },
    resetFilter() {
      (this.filter = {
        page: 1,
        limit: 10,
      }),
        this.toggleFilter('GaWaitingOrdersFilter', this.filter, this.refresh, this.resetFilter);
    },

    refresh() {
      this.loading = true;

      this.filter.dateStart = this.startingDate;
      this.filter.dateEnd = this.endingDate;
      this.filter.query = this.textSearch;

      missionWaitingOrderService
        .pagination(this.filter)
        .then((data) => {
          this.totalRecords = data.totalItems;
          this.list = data.data;
        })
        .finally(() => (this.loading = false));
    },
    onPage(event) {
      this.filter.page = event.page + 1;
      this.filter.limit = event.rows;
      this.refresh();
    },
    onIntervalChange(startingDate, endingDate) {
      this.startingDate = startingDate;
      this.endingDate = endingDate;
    },
    submitForm() {
      this.filter.page = 0;
      this.onPage({page: this.filter.page, rows: this.filter.limit});
    },
    backToList() {
      this.chosenReservation = null;
    },
    onMouseDown() {
      this.mouseDownStart = Date.now();
    },
    rowClick(event) {
      if (Date.now() - this.mouseDownStart > 300) return;
      this.chosenReservation = event.data;
    },
  },
};
</script>

<template>
  <!-- Vision liste : consulter les réservations en attente d'ordre, filtrer et créer une nouvelle mission -->
  <div v-if="chosenReservation === null" class="h-100 d-flex flex-column no-mission-order-list">
    <GaTitleMenu :with-bullet="false">
      <GaItemTitle :active="true" :title="$t('waiting_orders.title')" />
      <a type="button" class="btn btn-create ml-auto mt-2" :href="`/orders/create/0`">{{ $t('waiting_orders.new') }}</a>
    </GaTitleMenu>

    <div class="d-flex align-items-center justify-content-between mb-20 gap-16 mt-2">
      <span>{{ $t('waiting_orders.period') }}</span>
      <GaDateIntervalGroup @interval-change="onIntervalChange" class="interval-group" />
      <form class="p-input-icon-left ml-auto form-query" @submit.prevent="submitForm">
        <i class="pi pi-search" />
        <InputText :placeholder="$t('search.search')" v-model="textSearch" />
      </form>
      <PButton
        class="btn btn-primary"
        :badge="this.countFilter(this.filter)"
        badgeClass="p-badge-primary"
        :label="$t('search.filter')"
        @click="openFilterPanel()">
      </PButton>
    </div>

    <!-- Liste des réservations en attente d'ordre -->
    <div class="content-list">
      <DataTable
        :always-show-paginator="false"
        :paginator="true"
        :rows="10"
        :rows-per-page-options="[10, 20, 50]"
        :first="this.first"
        :value="list"
        lazy
        :loading="loading"
        :totalRecords="totalRecords"
        class="table pointer gd-datatable"
        current-page-report-template="{first}-{last}/{totalRecords}"
        removable-sort
        responsive-layout="scroll"
        striped-rows
        @mousedown="onMouseDown"
        @row-click="rowClick($event)"
        @page="onPage($event)">
        <template #empty>{{ $t('waiting_orders.empty') }}</template>

        <Column :header="$t('waiting_orders.reservation')">
          <template #body="slotProps">
            <span class="text-break">{{ slotProps.data.avantioLocalizer }}</span>
          </template>
        </Column>

        <Column :header="$t('waiting_orders.company')" v-if="this.isGas()">
          <template #body="slotProps">
            {{ slotProps.data.companyDisplay }}
          </template>
        </Column>

        <Column :header="$t('waiting_orders.owner')">
          <template #body="slotProps">
            {{ slotProps.data.accommodation.ownerFirstName + ' ' + slotProps.data.accommodation.ownerLastName }}
          </template>
        </Column>

        <Column :header="$t('waiting_orders.accommodation')">
          <template #body="slotProps">
            {{ slotProps.data.accommodation.name }}
          </template>
        </Column>

        <Column :header="$t('waiting_orders.accommodation_address')">
          <template #body="slotProps">
            <div>
              {{ slotProps.data.accommodation.address }}
            </div>
            <div>
              {{ slotProps.data.accommodation.postalCode + ' ' + slotProps.data.accommodation.city }}
            </div>
          </template>
        </Column>

        <Column :header="$t('waiting_orders.date_created')">
          <template #body="slotProps">
            {{ slotProps.data.dateCreated ? new Date(slotProps.data.dateCreated).toLocaleDateString() : '' }}
          </template>
        </Column>

        <Column :header="$t('waiting_orders.date_start')">
          <template #body="slotProps">
            {{ slotProps.data.dateCheckin ? new Date(slotProps.data.dateCheckin).toLocaleDateString() : '' }}
          </template>
        </Column>

        <Column :header="$t('waiting_orders.date_end')">
          <template #body="slotProps">
            {{ slotProps.data.dateCheckout ? new Date(slotProps.data.dateCheckout).toLocaleDateString() : '' }}
          </template>
        </Column>

        <Column :header="$t('waiting_orders.client_infos')">
          <template #body="slotProps">
            <div>
              {{ slotProps.data.client.display }}
            </div>
            <div>
              {{ slotProps.data.client.phone }}
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>

  <!--- TODO: mettre dans un composant séparé -->
  <!-- Vision détaillée : Détails d'une réservation en attente d'ordre pour créer des missions -->
  <div v-else class="h-100 d-flex flex-column no-mission-order-list">
    <GaTitleMenu :with-bullet="false" class="justify-content-between">
      <div class="title-container">
        <div style="cursor: pointer" @click="backToList">
          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7 1L1 7L7 13"
              stroke="#2F384C"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </div>
        <GaItemTitle :active="true" :title="$t('waiting_orders.title')" />
      </div>
      <a
        type="button"
        class="btn btn-create m-2"
        :href="`/orders/create/${chosenReservation.accommodation.id}?reservationId=${chosenReservation.id}`"
        >{{ $t('waiting_orders.create') }}</a
      >
    </GaTitleMenu>
    <div class="content-list">
      <MissionWaitingOrdersDetails :modelValue="chosenReservation" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.no-mission-order-list {
  .title-container {
    display: flex;
    flex-direction: row;
    gap: 26px;
    align-items: center;
  }

  .interval-group {
    gap: 20px;
    margin-bottom: 0;
  }

  .calendar {
    width: 200px;
  }

  .p-input-icon-left {
    display: flex;
    align-items: center;
  }

  .p-inputtext {
    width: 400px;
  }

  .pi-search {
    left: 14px !important;
  }

  .form-query {
    max-width: 250px;
  }

  .status-badge {
    width: fit-content;
    padding: 2px 8px;
    background-color: #ecfdf3;
    color: #027a48;
    border-radius: 16px;
  }

  .date-badge {
    border-radius: 32px;
    padding: 5px 8px;
    font: 600 12px/18px 'Nunito Sans';
    color: white;
  }

  .task {
    padding: 8px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    > span {
      font: 400 14px/20px 'Nunito Sans';
      color: white;
    }
  }

  .gd-btn-filter {
    margin: 0;
  }

  .btn-create {
    height: 44px;
    background-color: #0b3575;
    color: #ffffff;
  }

  .gap-16 {
    gap: 16px;
  }

  .btn-filter {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }

  .btn-create {
    height: 44px;
    background-color: #0b3575;
    color: #ffffff;
  }

  .filter {
    margin-bottom: 0;
    max-width: 200px;
  }
}
</style>
