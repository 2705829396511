export default {
  data() {
    return {
      yesNoConst: [
        {label: 'Oui', value: true},
        {label: 'Non', value: false},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
};
