<script>
import {Icon} from '@iconify/vue/dist/iconify';

/* COMPOSANTS */
import MultiSelect from 'primevue/multiselect';
import AkAccommodationAutocomplete from '@components/general/AkAccommodationAutocomplete.vue';
import AkCalendar from '@components/input/AkCalendar.vue';
import AkDropdown from '@components/input/AkDropdown.vue';

/* MIXINS */
import sidebarMixin from '@mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';
import maintenanceStatusConst from '@mixins/const/maintenanceStatusConst';
import maintenanceTagConst from '@mixins/const/maintenanceTagConst';
import permissionsMixin from '@mixins/permissionsMixin';

/* SERVICES */
import companyService from '@services/companyService';
import AkOwnerAutocomplete from '@components/general/AkOwnerAutocomplete.vue';
import upsellStatusConst from '@mixins/const/upsellStatusConst';
import AkReservationAutocomplete from '@components/general/AkReservationAutocomplete.vue';
import AkInputText from '@components/input/AkInputText.vue';

export default {
  components: {
    AkInputText,
    AkReservationAutocomplete,
    AkOwnerAutocomplete,
    AkCalendar,
    AkAccommodationAutocomplete,
    MultiSelect,
    Icon,
  },
  mixins: [utilsMixin, sidebarMixin, permissionsMixin, upsellStatusConst],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      companies: undefined,
      statuses: [],
      companyId: undefined,
    };
  },
  mounted() {
    this.initStatuses();
    if (this.isGav()) this.companyId = this.myCompanyId();
    this.initCompanies();
  },
  methods: {
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.accommodationAutocomplete) this.$refs.accommodationAutocomplete.reset();
      if (this.$refs.ownerAutocomplete) this.$refs.ownerAutocomplete.reset();
      if (this.$refs.reservationAutocomplete) this.$refs.reservationAutocomplete.reset();
      this.callback[1]();
    },
    initCompanies() {
      companyService.companies().then((data) => {
        this.companies = data;
      });
    },
    initStatuses() {
      this.statuses = this.upsellStatusConstForFilter();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    loadingCompanies() {
      return this.companies === undefined;
    },
  },
};
</script>

<template>
  <h2>Filtrer</h2>
  <form class="d-flex flex-column" style="row-gap: 16px" @submit.prevent="search">
    <MultiSelect
      v-if="this.isGas()"
      v-model="currentValue.companyIds"
      :options="this.companies"
      :loading="this.loadingCompanies"
      :placeholder="$t('employee.company')"
      class="flex-1 form-control"
      option-label="name"
      option-value="id" />

    <AkAccommodationAutocomplete
      ref="accommodationAutocomplete"
      v-model="currentValue.accommodationId"
      inline
      :placeholder="$t('activity.accommodation')"
      class-name="flex-1 form-control" />
    <AkOwnerAutocomplete
      v-if="!this.isOwner()"
      ref="ownerAutocomplete"
      v-model="currentValue.ownerId"
      :label="$t('owner.ownerDisplay')"
      :placeholder="$t('owner.ownerDisplay')"
      :companyId="companyId"
      inline
      class-name="flex-1 form-control" />
    <AkReservationAutocomplete
      ref="reservationAutocomplete"
      v-if="!this.isOwner()"
      v-model="currentValue.reservationLocalizer"
      :inline="true"
      :placeholder="$t('reservation.number')"
      class-name="flex-1 form-control" />

    <AkCalendar
      v-model="currentValue.dateCheckin"
      className="flex-1 form-control"
      :inline="true"
      :placeholder="$t('reservation.dateCheckin')"
      date-format="dd/mm/yy"
      selection-mode="single" />

    <AkCalendar
      v-model="currentValue.dateCheckout"
      className="flex-1 form-control"
      :inline="true"
      :placeholder="$t('reservation.dateCheckout')"
      date-format="dd/mm/yy"
      selection-mode="single" />
    <AkInputText
      v-model="currentValue.name"
      inline
      :display-label="false"
      :placeholder="$t('upsell.name')"
      class-name="" />
    <AkCalendar
      v-model="currentValue.dateBuy"
      className="flex-1 form-control"
      :inline="true"
      :placeholder="$t('upsell.buyDate')"
      date-format="dd/mm/yy"
      selection-mode="single" />

    <MultiSelect
      v-model="currentValue.statuses"
      :placeholder="$t('upsell.status')"
      :options="statuses"
      option-label="label"
      option-value="value"
      class="flex-1 form-control"
      inline />

    <div class="d-flex flex-row">
      <button class="btn btn-primary btn-search flex-1" type="submit">
        {{ this.$t('upsell.filter') }}
      </button>
      <Icon
        v-on:click="this.reset()"
        class="pointer"
        icon="basil:cross-outline"
        :style="{fontSize: '50px', width: '50px !important'}" />
    </div>
  </form>
</template>
