import {mapActions} from 'pinia';
import {useSidebarStore} from '../store/sidebarStore';

export default {
  methods: {
    ...mapActions(useSidebarStore, [
      'hideSidebar',
      'addContact',
      'addReservation',
      'showComment',
      'toggleFilter',
      'toggleReservationFilterPlanning',
      'toggleReservationFilterList',
      'toggleFacturationHoteFilterList',
      'toggleOperationsOwnerChargeFilterList',
      'toggleSidebar',
    ]),
  },
};
