/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import {PermissionMap} from '@mixins/const/permissionConst';

export default {
  mixins: [permissionsMixin],
  mounted() {},
  data() {
    return {
      tabs: [
        {
          name: this.$t('operation.validate_owner_charge'),
          id: 'operationsOwnerCharge',
          icon: 'list',
          hidden: this.isTabHidden(),
        },
        {
          name: this.$t('operation.consultation'),
          id: 'operationsConsultation',
          icon: 'search',
        },
        {
          name: this.$t('operation.recurring_charges'),
          id: 'operationsRecurringCharges',
          icon: 'dollar-sign',
        },
      ],
    };
  },
  methods: {
    isTabHidden() {
      if (this.hasPermission(PermissionMap.OPERATION_OWNER_CHARGE.VIEW)) {
        return false;
      }
      if (this.isPoleComptabiliteMandante()) {
        return false;
      }
      return true;
    },
  },
};
