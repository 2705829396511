import {h} from 'vue';

export default {
  setup() {
    return {loader: undefined};
  },
  methods: {
    showLoader(ref, canCancel = false, backgroundColor = '#FFF', color = '#064CDB') {
      this.loader = this.$loading.show({
        container: ref,
        canCancel: canCancel,
        backgroundColor: backgroundColor,
        color: color,
        isFullPage: false,
      });
    },
    showTotalLoader(canCancel = false, backgroundColor = '#FFF', color = '#064CDB', after = '') {
      this.loader = this.$loading.show(
        {
          canCancel: canCancel,
          backgroundColor: backgroundColor,
          color: color,
          isFullPage: true,
        },
        {
          // Pass slots by their names
          after: after !== '' ? h('span', {class: 'loader-message'}, after) : undefined,
        },
      );
    },

    showTotalLoaderWithAfter(after, canCancel = false, backgroundColor = '#FFF', color = '#064CDB') {
      this.loader = this.$loading.show(
        {
          canCancel: canCancel,
          backgroundColor: backgroundColor,
          color: color,
          isFullPage: true,
        },
        {
          after: after !== '' ? () => h('span', {class: 'loader-message'}, after) : undefined,
        },
      );
    },
    hideLoader() {
      if (this.loader) {
        this.loader.hide();
        this.loader = undefined;
      }
    },
  },
  unmounted() {
    if (this.loader) {
      this.loader.hide();
      this.loader = undefined;
    }
  },
};
