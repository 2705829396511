export default {
  data() {
    return {
      absenceConst: [
        {label: this.$t('absence.abs1'), value: 'REASON_1'},
        {label: this.$t('absence.abs2'), value: 'REASON_2'},
        {label: this.$t('absence.abs3'), value: 'REASON_3'},
        {label: this.$t('absence.abs4'), value: 'REASON_4'},
        {label: this.$t('absence.abs5'), value: 'REASON_5'},
        {label: this.$t('absence.abs6'), value: 'REASON_6'},
        {label: this.$t('absence.abs7'), value: 'REASON_7'},
        {label: this.$t('absence.abs8'), value: 'REASON_8'},
        {label: this.$t('absence.abs9'), value: 'REASON_9'},
        {label: this.$t('absence.abs10'), value: 'REASON_10'},
        {label: this.$t('absence.abs11'), value: 'REASON_11'},
        {label: this.$t('absence.abs12'), value: 'REASON_12'},
        {label: this.$t('absence.abs13'), value: 'REASON_13'},
        {label: this.$t('absence.abs14'), value: 'REASON_14'},
        {label: this.$t('absence.abs15'), value: 'REASON_15'},
        {label: this.$t('absence.abs16'), value: 'REASON_16'},
        {label: this.$t('absence.abs17'), value: 'REASON_17'},
        {label: this.$t('absence.abs18'), value: 'REASON_18'},
        {label: this.$t('absence.abs19'), value: 'REASON_19'},
        {label: this.$t('absence.abs20'), value: 'REASON_20'},
        {label: this.$t('absence.abs21'), value: 'REASON_21'},
        {label: this.$t('absence.abs22'), value: 'REASON_22'},
        {label: this.$t('absence.abs23'), value: 'REASON_23'},
        {label: this.$t('absence.abs24'), value: 'REASON_24'},
        {label: this.$t('absence.abs25'), value: 'REASON_25'},
        {label: this.$t('absence.abs26'), value: 'REASON_26'},
        {label: this.$t('absence.abs27'), value: 'REASON_27'},
        {label: this.$t('absence.abs28'), value: 'REASON_28'},
        {label: this.$t('absence.abs29'), value: 'REASON_29'},
        {label: this.$t('absence.abs30'), value: 'REASON_30'},
        {label: this.$t('absence.abs31'), value: 'REASON_31'},
        {label: this.$t('absence.abs32'), value: 'REASON_32'},
        {label: this.$t('absence.abs33'), value: 'REASON_33'},
        {label: this.$t('absence.abs34'), value: 'REASON_34'},
        {label: this.$t('absence.abs35'), value: 'REASON_35'},
        {label: this.$t('absence.abs36'), value: 'REASON_36'},
        {label: this.$t('absence.abs37'), value: 'REASON_37'},
        {label: this.$t('absence.abs38'), value: 'REASON_38'},
        {label: this.$t('absence.abs39'), value: 'REASON_39'},
        {label: this.$t('absence.abs40'), value: 'REASON_40'},
        {label: this.$t('absence.abs41'), value: 'REASON_41'},
        {label: this.$t('absence.abs42'), value: 'REASON_42'},
        {label: this.$t('absence.abs43'), value: 'REASON_43'},
        {label: this.$t('absence.abs44'), value: 'REASON_44'},
        {label: this.$t('absence.abs45'), value: 'REASON_45'},
        {label: this.$t('absence.abs46'), value: 'REASON_46'},
        {label: this.$t('absence.abs47'), value: 'REASON_47'},
        {label: this.$t('absence.abs48'), value: 'REASON_48'},
        {label: this.$t('absence.abs49'), value: 'REASON_49'},
        {label: this.$t('absence.abs50'), value: 'REASON_50'},
        {label: this.$t('absence.abs51'), value: 'REASON_51'},
        {label: this.$t('absence.abs52'), value: 'REASON_52'},
        {label: this.$t('absence.abs53'), value: 'REASON_53'},
        {label: this.$t('absence.abs54'), value: 'REASON_54'},
        {label: this.$t('absence.abs55'), value: 'REASON_55'},
        {label: this.$t('absence.abs56'), value: 'REASON_56'},
        {label: this.$t('absence.abs57'), value: 'REASON_57'},
        {label: this.$t('absence.abs58'), value: 'REASON_58'},
        {label: this.$t('absence.abs59'), value: 'REASON_59'},
        {label: this.$t('absence.abs60'), value: 'REASON_60'},
        {label: this.$t('absence.abs61'), value: 'REASON_61'},
        {label: this.$t('absence.abs62'), value: 'REASON_62'},
        {label: this.$t('absence.abs63'), value: 'REASON_63'},
        {label: this.$t('absence.abs64'), value: 'REASON_64'},
        {label: this.$t('absence.abs65'), value: 'REASON_65'},
        {label: this.$t('absence.abs66'), value: 'REASON_66'},
        {label: this.$t('absence.abs67'), value: 'REASON_67'},
        {label: this.$t('absence.abs68'), value: 'REASON_68'},
        {label: this.$t('absence.abs69'), value: 'REASON_69'},
        {label: this.$t('absence.abs70'), value: 'REASON_70'},
        {label: this.$t('absence.abs71'), value: 'REASON_71'},
        {label: this.$t('absence.abs72'), value: 'REASON_72'},
        {label: this.$t('absence.abs73'), value: 'REASON_73'},
        {label: this.$t('absence.abs74'), value: 'REASON_74'},
        {label: this.$t('absence.abs75'), value: 'REASON_75'},
        {label: this.$t('absence.abs76'), value: 'REASON_76'},
        {label: this.$t('absence.abs77'), value: 'REASON_77'},
        {label: this.$t('absence.abs78'), value: 'REASON_78'},
        {label: this.$t('absence.abs79'), value: 'REASON_79'},
        {label: this.$t('absence.abs80'), value: 'REASON_80'},
        {label: this.$t('absence.abs81'), value: 'REASON_81'},
        {label: this.$t('absence.abs82'), value: 'REASON_82'},
        {label: this.$t('absence.abs83'), value: 'REASON_83'},
        {label: this.$t('absence.abs84'), value: 'REASON_84'},
        {label: this.$t('absence.abs85'), value: 'REASON_85'},
        {label: this.$t('absence.abs86'), value: 'REASON_86'},
        {label: this.$t('absence.abs87'), value: 'REASON_87'},
        {label: this.$t('absence.abs88'), value: 'REASON_88'},
        {label: this.$t('absence.abs89'), value: 'REASON_89'},
        {label: this.$t('absence.abs90'), value: 'REASON_90'},
        {label: this.$t('absence.abs91'), value: 'REASON_91'},
        {label: this.$t('absence.abs92'), value: 'REASON_92'},
        {label: this.$t('absence.abs93'), value: 'REASON_93'},
        {label: this.$t('absence.abs94'), value: 'REASON_94'},
      ],
    };
  },
  methods: {
    displayForAbsenceReasonConst(value) {
      let result = this.absenceConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
