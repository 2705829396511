<script>
/* COMPOSANTS */
import {Icon} from '@iconify/vue/dist/iconify';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import randomRef from '@mixins/randomRef';
import permissionsMixin from '@mixins/permissionsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import loaderMixin from '@mixins/loaderMixin';

/* SERVICES */
import reviewService from '@services/reviewService';

export default {
  components: {},
  mixins: [utilsMixin, randomRef, sidebarMixin, loaderMixin, permissionsMixin],
  setup() {
    return {};
  },
  data() {
    return {
      review: undefined,
    };
  },
  props: {
    modelValue: {required: true},
  },
  mounted() {
    reviewService.reviewSidebar(this.currentValue).then((data) => {
      this.review = data;
    });
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    iconReservation() {
      if (!this.review.reservationSite) {
        return 'cancel';
      }
      if (this.review.reservationSite.includes('Airbnb')) {
        return 'airbnb';
      }
      if (this.review.reservationSite.includes('Booking')) {
        return 'booking';
      }
      if (this.review.reservationSite.includes('Vrbo')) {
        return 'vrbo';
      }
      if (this.review.reservationSite.includes('homeaway') || this.review.reservationSite.includes('Abritel')) {
        return 'homeaway';
      }
      return 'guestadom';
    },
  },
};
</script>

<template>
  <div>
    <div v-if="this.review">
      <div class="d-flex justify-content-between align-items-center" v-if="!this.isOwner()">
        <h4>Avis n° {{ review.id }}</h4>
      </div>

      <section id="review-data">
        <h5>{{ $t('review.data') }}</h5>
        <div ref="reviewData">
          <p>
            <b>{{ $t('review.review_title') }} :</b>
            {{ review.title }}
          </p>
          <p>
            <b>{{ $t('review.reservation_num') }} :</b>
            {{ review.reservationNum }}
          </p>
          <p>
            <b>{{ $t('review.rating_ten') }} :</b>
            {{ this.formatAmount(review.ratingTen) }}
          </p>

          <p v-if="this.isGas()">
            <b>{{ $t('review.company_name') }} :</b>
            {{ review.companyName }}
          </p>
          <p>
            <b>{{ $t('review.reservation_site') }} :</b>
            {{ review.reservationSite }}
            <span class="logo-site" :class="iconReservation" />
          </p>
          <p>
            <b>{{ $t('review.accommodation_name') }} :</b>
            {{ review.accommodationName }}
          </p>
          <p>
            <b>{{ $t('review.owner_name') }} :</b>
            {{ review.ownerName }}
          </p>
          <p>
            <b>{{ $t('review.name') }} :</b>
            {{ review.name }}
          </p>
          <p>
            <b>{{ $t('review.submitted_at') }} :</b>
            {{ this.formatDateIfNeeded(review.submittedAt) }}
          </p>
        </div>
      </section>

      <section id="review-details" class="mt-1">
        <h5>{{ $t('review.details') }}</h5>
        <p class="text-break">
          <b>{{ $t('review.public_comment') }} :</b>
          {{ review.comment }}
        </p>
        <p class="text-break">
          <b>{{ $t('review.private_comment') }} :</b>
          {{ review.privateComment }}
        </p>
      </section>

      <!-- CRITERIAS -->

      <section id="review-criterias" class="mt-1">
        <h5>{{ $t('review.criterias') }}</h5>
        <div ref="reviewCriterias">
          <div class="d-flex justify-content-between">
            <h6 class="col-4">{{ $t('review.criteria.cleanliness') }}</h6>
            <div class="col-8">
              <p>
                <b>{{ $t('review.rating') }} :</b>
                {{ review.criteriaCleanlinessRating }}
              </p>
              <p>
                <b>{{ $t('review.comment') }} :</b>
                {{ review.criteriaCleanlinessComment }}
              </p>
            </div>
          </div>

          <hr />

          <div class="d-flex justify-content-between">
            <h6 class="col-4">{{ $t('review.criteria.checkin') }}</h6>
            <div class="col-8">
              <p>
                <b>{{ $t('review.rating') }} :</b>
                {{ review.criteriaCheckinRating }}
              </p>
              <p>
                <b>{{ $t('review.comment') }} :</b>
                {{ review.criteriaCheckinComment }}
              </p>
            </div>
          </div>

          <hr />

          <div class="d-flex justify-content-between">
            <h6 class="col-4">{{ $t('review.criteria.communication') }}</h6>
            <div class="col-8">
              <p>
                <b>{{ $t('review.rating') }} :</b>
                {{ review.criteriaCommunicationRating }}
              </p>
              <p>
                <b>{{ $t('review.comment') }} :</b>
                {{ review.criteriaCommunicationComment }}
              </p>
            </div>
          </div>

          <hr />

          <div class="d-flex justify-content-between">
            <h6 class="col-4">{{ $t('review.criteria.location') }}</h6>
            <div class="col-8">
              <p>
                <b>{{ $t('review.rating') }} :</b>
                {{ review.criteriaLocationRating }}
              </p>
              <p>
                <b>{{ $t('review.comment') }} :</b>
                {{ review.criteriaLocationComment }}
              </p>
            </div>
          </div>

          <hr />

          <div class="d-flex justify-content-between">
            <h6 class="col-4">{{ $t('review.criteria.facilities') }}</h6>
            <div class="col-8">
              <p>
                <b>{{ $t('review.rating') }} :</b>
                {{ review.criteriaFacilitiesRating }}
              </p>
              <p>
                <b>{{ $t('review.comment') }} :</b>
                {{ review.criteriaFacilitiesComment }}
              </p>
            </div>
          </div>

          <hr />

          <div class="d-flex justify-content-between">
            <h6 class="col-4">{{ $t('review.criteria.comfort') }}</h6>
            <div class="col-8">
              <p>
                <b>{{ $t('review.rating') }} :</b>
                {{ review.criteriaComfortRating }}
              </p>
              <p>
                <b>{{ $t('review.comment') }} :</b>
                {{ review.criteriaComfortComment }}
              </p>
            </div>
          </div>

          <hr />

          <div class="d-flex justify-content-between">
            <h6 class="col-4">{{ $t('review.criteria.staff') }}</h6>
            <div class="col-8">
              <p>
                <b>{{ $t('review.rating') }} :</b>
                {{ review.criteriaStaffRating }}
              </p>
              <p>
                <b>{{ $t('review.comment') }} :</b>
                {{ review.criteriaStaffComment }}
              </p>
            </div>
          </div>

          <hr />

          <div class="d-flex justify-content-between">
            <h6 class="col-4">{{ $t('review.criteria.value_for_money') }}</h6>
            <div class="col-8">
              <p>
                <b>{{ $t('review.rating') }} :</b>
                {{ review.criteriaValueForMoneyRating }}
              </p>
              <p>
                <b>{{ $t('review.comment') }} :</b>
                {{ review.criteriaValueForMoneyComment }}
              </p>
            </div>
          </div>

          <hr />

          <div class="d-flex justify-content-between">
            <h6 class="col-4">{{ $t('review.criteria.accuracy') }}</h6>
            <div class="col-8">
              <p>
                <b>{{ $t('review.rating') }} :</b>
                {{ review.criteriaAccuracyRating }}
              </p>
              <p>
                <b>{{ $t('review.comment') }} :</b>
                {{ review.criteriaAccuracyComment }}
              </p>
            </div>
          </div>

          <hr />

          <div class="d-flex justify-content-between">
            <h6 class="col-4">{{ $t('review.criteria.property_condition') }}</h6>
            <div class="col-8">
              <p>
                <b>{{ $t('review.rating') }} :</b>
                {{ review.criteriaPropertyConditionRating }}
              </p>
              <p>
                <b>{{ $t('review.comment') }} :</b>
                {{ review.criteriaPropertyConditionComment }}
              </p>
            </div>
          </div>

          <hr />

          <div class="d-flex justify-content-between">
            <h6 class="col-4">{{ $t('review.criteria.eco_friendliness') }}</h6>
            <div class="col-8">
              <p>
                <b>{{ $t('review.rating') }} :</b>
                {{ review.criteriaEcoFriendlinessRating }}
              </p>
              <p>
                <b>{{ $t('review.comment') }} :</b>
                {{ review.criteriaEcoFriendlinessComment }}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-else class="flex flex-col items-center gap-2 pt-4">
      <span class="spinner primary xl"></span>
      <span class="text-gray font-normal font-inter">{{ $t('review.fetching') }}</span>
    </div>
  </div>
</template>
