<script>
import {required, requiredIf} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

/* COMPOSANTS */
import AkDialog from '@components/general/AkDialog';
import AkDialogConfirm from '@components/general/AkDialogConfirm';
import AkCalendar from '@components/input/AkCalendar';
import AkDropdown from '@components/input/AkDropdown';
import AkInputText from '@components/input/AkInputText';
import AkInputMoney from '@components/input/AkInputMoney';
import AkInputTextArea from '@components/input/AkInputTextArea';
import PButton from 'primevue/button';

/* MIXINS */
import messageMixin from '@mixins/messageMixin';
import paymentSourceConst from '@mixins/const/paymentSourceConst';
import reservationTypesConst from '@mixins/const/reservationTypesConst';
import permissionConst, {PermissionMap} from '@mixins/const/permissionConst';
import loaderMixin from '@mixins/loaderMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import reservationPaymentService from '@services/reservationPaymentService';
import companyService from '@services/companyService';

export default {
  components: {
    PButton,
    AkInputTextArea,
    AkInputMoney,
    AkInputText,
    AkDropdown,
    AkCalendar,
    AkDialog,
    AkDialogConfirm,
  },
  mixins: [
    loaderMixin,
    messageMixin,
    paymentSourceConst,
    permissionConst,
    permissionsMixin,
    reservationTypesConst,
    sidebarMixin,
    utilsMixin,
  ],
  metaInfo() {
    return {
      title: 'accounting.payment',
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      controller: undefined,
      loading: true,
      cancelLoading: true,
      list: [],
      item: {},
      rejectedPayment: {},
      newPayment: {},
      downloadedReservations: {},
      disclaimerRejected: false,
      companies: [],
      totalItems: 0,
      filter: {
        page: 1,
        limit: 10,
        sort: ['id:DESC'],
      },
    };
  },
  validations() {
    return {
      newPayment: {
        reservationId: {required},
        amount: {required},
        date: {required},
        source: {required},
        totalCommission: {required: requiredIf(this.isItemVrbo)},
      },
      rejectedPayment: {
        reservationId: {required},
        motif: {required},
        date: {required},
        amount: {required},
      },
    };
  },
  mounted() {
    this.search();
  },
  computed: {
    numberOfResults() {
      return this.list.length;
    },
    hasManagePermission() {
      return this.hasPermission(PermissionMap.RESERVATION_REGLEMENT.MANAGE);
    },
    disclaimerRejectedAmount() {
      return this.disclaimerRejected;
    },
    isItemAirBnb() {
      return this.item && this.item.reservationSite === 'Airbnb.com';
    },
    isItemVrbo() {
      return this.item && this.item.reservationSite === 'Vrbo';
    },
  },
  watch: {
    'newPayment.totalHousework'(newValue, oldValue) {
      if (this.item && this.item.totalHousework !== newValue) {
        let diffHousework = newValue - this.item.totalHousework;
        this.newPayment.amount = this.item.total - this.item.paid + diffHousework;
      }
    },
  },
  methods: {
    isSiteVga(item) {
      return (
        item.reservationSite &&
        (item.reservationSite.includes('happystay') || item.reservationSite.includes('guest-adom'))
      );
    },
    clickSearch() {
      this.filter.page = 1;
      this.search();
    },
    search() {
      this.cancelLoading = true;
      if (this.controller) {
        this.controller.abort();
        this.controller = new AbortController();
      } else {
        this.controller = new AbortController();
      }
      const signal = this.controller.signal;
      this.loading = true;
      let p1 = reservationPaymentService.reservationsPaymentPagination(this.filter, signal);
      p1.then((data) => {
        this.cancelLoading = true;
        this.list = data.data;
        this.totalItems = data.totalItems;
      })
        .catch((e) => {
          if (e.message === 'canceled') {
            this.cancelLoading = false;
          }
        })
        .finally(() => {
          if (this.cancelLoading) this.loading = false;
        });

      let p2 = companyService.companies();
      p2.then((data) => {
        this.companies = data;
      }).catch((e) => {
        this.addError(this.$t('unknow_error'));
      });

      Promise.all([p1, p2]).then(() => {
        this.loading = false;
      });
    },
    onPage(event) {
      this.filter.page = event.page + 1;
      this.filter.limit = event.rows;
      this.search();
    },
    onSort(event) {
      this.filter.sort = [];
      for (let sort of event.multiSortMeta) {
        this.filter.sort.push('' + sort.field + ':' + (sort.order === -1 ? 'DESC' : 'ASC'));
      }
      if (this.filter.sort.length === 0) {
        this.filter.sort.push('id:DESC');
      }
      this.search();
    },

    // OPEN

    openNotPayDialog(data) {
      this.item = data;
      this.$refs.dialogNotPay.show();
    },
    openRejectPaymentDialog(data) {
      this.item = data;

      this.rejectedPayment = {};
      this.rejectedPayment.paidAmount = data.paid;
      this.rejectedPayment.reservationId = data.id;
      this.$refs.dialogRejectPayment.show();
    },
    openNewPaymentDialog(data) {
      this.item = data;

      this.newPayment = {};
      this.newPayment.date = new Date();
      this.newPayment.reservationId = data.id;
      this.newPayment.amount = this.addNumbers([data.total, -data.paid]);
      this.newPayment.totalHousework = data.totalHousework;
      this.newPayment.totalCommission = data.totalCommission;
      this.$refs.dialogNewPayment.show();
    },

    // HANDLE

    handleNotPay() {
      reservationPaymentService.ignore(this.item.id).then((data) => {
        this.item = {};
        this.search();
      });
    },

    handleNewPayment() {
      this.v$.$touch();
      if (this.v$.newPayment.$error) return;

      this.showTotalLoaderWithAfter(this.$t('reservation.payment_creation_in_progress'));
      reservationPaymentService
        .createPayment(this.newPayment)
        .then((data) => {
          if (!data.ok) this.$refs.dialogNewPayment.error(data.message);
          else {
            this.$refs.dialogNewPayment.hide();
            this.success(this.$t('payment.added'));
            this.newPayment = {};
            this.item = {};
            this.search();
          }
        })
        .catch((e) => {
          if (e.response) {
            this.$refs.dialogNewPayment.error(this.$t('error.' + e.response.data.error));
          }
        })
        .finally(() => {
          this.hideLoader();
        });
    },

    //    handleRejectPayment() {
    //      this.v$.$touch();
    //      if (this.v$.rejectedPayment.$error) return;
    //
    //      //Le rejet ne peux etre supérieur au montant réglé
    //      if (this.rejectedPayment.paidAmount < this.rejectedPayment.amount) {
    //        this.addError(this.$t('operation.error_reject_more_paid'));
    //        return;
    //      }
    //      this.$refs.dialogRejectPayment.hide();
    //      reservationPaymentService
    //        .rejectPayment(this.rejectedPayment)
    //        .then(() => {
    //          this.success(this.$t('payment.rejected_payment'));
    //          this.rejectedPayment = {};
    //          this.item = {};
    //          this.search();
    //        })
    //        .catch((e) => {
    //          if (e.response) {
    //            if (e.response.status === 412) {
    //              this.addError(this.$t('payment.error_no_reservation'), true, true);
    //            } else {
    //              this.addError(this.$t('error.' + e.response.data.error));
    //            }
    //          }
    //        });
    //    },

    checkRejectAmount() {
      this.disclaimerRejected = this.rejectedPayment.paidAmount !== this.rejectedPayment.amount;
    },

    //    rouge = réservation non payée
    //    orange = le montant du loyer n’a pas été payé en totalité
    //    jaune foncé = le montant du loyer a été payé en totalité mais la TDS est partiellement ou non payée.
    //    jaune clair = le montant du loyer a été payé en totalité et la TDS aussi , on peut alors passer cette réservation en génération de CRG
    //    vert = payé en totalité

    getPaymentColorClass(data) {
      if (data.paid === 0) return 'bg-danger text-white p-1';
      if (data.paymentDone) return 'bg-success text-white p-1';
      return 'bg-yellow text-white p-1';
    },

    exportFile() {
      let p1 = reservationPaymentService.exportFile(this.filter);
      p1.then((data) => {
        this.downloadedReservations = data;
      }).catch((e) => {
        if (e.response) {
          if (e.response.status === 412) this.addError(this.$t('payment.error_no_reservation'), true, true);
          else this.addError(this.$t('error.' + e.response.data.error));
        }
      });
    },
    createLinkAvantio(bookingCode) {
      return process.env.VUE_APP_AVANTIO_LINK + '' + bookingCode;
    },

    // FILTER

    openFilterPanel() {
      this.toggleFilter('GaPaymentFilterList', this.filter, this.clickSearch, this.resetFilter);
    },
    resetFilter() {
      this.filter = {
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      };
      this.toggleFilter('GaPaymentFilterList', this.filter, this.search, this.resetFilter);
    },
  },
};
</script>
<template>
  <GaView :title="$t('reservation.payment')">
    <template #action>
      <div class="d-flex justify-content-end" style="gap: 16px">
        <PButton
          :badge="this.countFilter(this.filter)"
          badgeClass="p-badge-primary"
          class="btn btn-primary"
          label="Filtrer"
          @click="openFilterPanel()" />
        <button class="btn btn-primary" @click="exportFile()">
          {{ this.$t('payment.download') }}
        </button>
      </div>
    </template>

    <!--------------------------------------------------------------------------------------->

    <template #content>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive">
                <DataTable
                  :always-show-paginator="false"
                  :loading="loading"
                  :paginator="true"
                  :rows="10"
                  :rows-per-page-options="[10, 20, 50]"
                  :value="list"
                  class="table"
                  current-page-report-template="{first}-{last}/{totalRecords}"
                  removable-sort
                  responsive-layout="scroll"
                  sort-field="position"
                  striped-rows
                  :lazy="true"
                  :totalRecords="totalItems"
                  sort-mode="multiple"
                  @page="onPage($event)">
                  <template #header>
                    <div class="d-flex align-items-center justify-content-start header-legend-result">
                      {{ this.$t('payment.number_results') }} {{ this.numberOfResults }}
                    </div>
                  </template>
                  <template #empty>
                    {{ $t('reservation.empty') }}
                  </template>

                  <Column>
                    <template #header>
                      {{ this.$t('reservation.number') }}
                    </template>
                    <template #body="slotProps">
                      <router-link
                        :to="{
                          name: 'reservationsPaymentDetails',
                          params: {reservationId: slotProps.data.id},
                        }">
                        {{ slotProps.data.reservationNum }}
                      </router-link>
                      <a
                        v-if="slotProps.data.bookingCode"
                        :href="createLinkAvantio(slotProps.data.bookingCode)"
                        class="color-gray"
                        target="_blank">
                        <i class="ml-2 fe fe-external-link"></i>
                      </a>
                    </template>
                  </Column>
                  <Column :header="$t('reservation.reservation_site')">
                    <template #body="slotProps">
                      <span v-tooltip.bottom="slotProps.data.reservationSite">
                        {{ reservationSiteDisplay(slotProps.data.reservationSite) }}
                      </span>
                    </template>
                  </Column>
                  <Column :header="$t('reservation.type')">
                    <template #body="slotProps">
                      <span>
                        {{ displayForReservationTypeConst(slotProps.data.type) }}
                      </span>
                    </template>
                  </Column>
                  <Column :header="$t('reservation.guestName')">
                    <template #body="slotProps">
                      <span v-tooltip.bottom="slotProps.data.guestEmail">{{ slotProps.data.guestLastName }}</span>
                    </template>
                  </Column>
                  <Column :header="$t('reservation.accommodationId')" field="accommodationName" class="text-right">
                  </Column>

                  <Column :header="$t('payment.days_left_before_start')" field="daysLeftBeforeStart"></Column>

                  <Column :header="$t('payment.date_start_end')">
                    <template #body="slotProps">
                      {{ this.displayDateYearWithTwoNumbers(slotProps.data.dateStart) }} -
                      {{ this.displayDateYearWithTwoNumbers(slotProps.data.dateEnd) }}
                    </template>
                  </Column>

                  <Column header="TDS" class="text-right">
                    <template #body="slotProps">
                      {{ formatCurrencyEUR(formatAmount(slotProps.data.totalTds)) }}
                      <span v-if="slotProps.data.totalTdsForAirbnb">
                        ({{ formatCurrencyEUR(formatAmount(slotProps.data.totalTdsForAirbnb)) }})
                      </span>
                    </template>
                  </Column>

                  <Column header="Commission (OTA)" class="text-right">
                    <template #body="slotProps">
                      {{ formatCurrencyEUR(formatAmount(slotProps.data.totalCommission)) }}
                      <span v-if="isSiteVga(slotProps.data)">
                        ({{
                          formatCurrencyEUR(
                            formatAmount(slotProps.data.totalService ? slotProps.data.totalService : 0),
                          )
                        }})</span
                      >
                    </template>
                  </Column>

                  <Column header="Ménage" class="text-right">
                    <template #body="slotProps">
                      {{ formatCurrencyEUR(formatAmount(slotProps.data.totalHousework)) }}
                    </template>
                  </Column>

                  <Column header="Nuit" class="text-right">
                    <template #body="slotProps">
                      {{ formatCurrencyEUR(formatAmount(slotProps.data.totalNight)) }}
                    </template>
                  </Column>

                  <Column :header="$t('payment.total')" class="text-right">
                    <template #body="slotProps">
                      {{ formatCurrencyEUR(formatAmount(slotProps.data.total)) }}
                    </template>
                  </Column>
                  <Column :header="$t('reservation.paymentDone')" class="text-right">
                    <template #body="slotProps">
                      <span :class="getPaymentColorClass(slotProps.data)">
                        {{ formatCurrencyEUR(formatAmount(slotProps.data.donePaymentAmount)) }}
                      </span>
                    </template>
                  </Column>
                  <Column :header="$t('payment.remaining_payment')" class="text-right">
                    <template #body="slotProps">
                      {{ formatCurrencyEUR(formatAmount(slotProps.data.remainingPaymentAmount)) }}
                    </template>
                  </Column>
                  <Column v-if="hasManagePermission" :header="$t('payment.actions')">
                    <template #body="slotProps">
                      <div class="form-inline">
                        <button
                          class="btn btn-inverse-primary btn-xs"
                          style="margin: 5px"
                          @click="openNewPaymentDialog(slotProps.data)">
                          {{ $t('payment.payment') }}
                        </button>

                        <!--                        <button
                          class="btn btn-inverse-danger btn-xs"
                          style="margin: 5px"
                          @click="openRejectPaymentDialog(slotProps.data)">
                          {{ $t('payment.reject') }}
                        </button>-->

                        <button
                          class="btn btn-inverse-danger btn-xs"
                          style="margin: 5px"
                          @click="openNotPayDialog(slotProps.data)">
                          {{ $t('payment.notPay') }}
                        </button>
                      </div>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!----------------------------------------------------------------------------------------->
    <template #extra>
      <AkDialog
        ref="dialogNewPayment"
        :title="$t('payment.add_payment')"
        :validate-label="$t('payment.add')"
        width="800px"
        @validate="handleNewPayment()">
        <div class="form-row" v-if="this.isItemAirBnb">
          <Message severity="warn">
            Cette réservation AirBnb est sensée être réglée automatiquement lorsque celle-ci est confirmée.
          </Message>
        </div>
        <div class="form-row">
          <AkCalendar
            v-model="newPayment.date"
            :label="$t('payment.date')"
            :validator="v$.newPayment.date"
            class-name="col-md-6">
          </AkCalendar>
          <AkInputMoney
            v-model="newPayment.amount"
            :label="$t('payment.amount')"
            :validator="v$.newPayment.amount"
            class-name="col-md-6" />
        </div>
        <div class="form-row">
          <AkDropdown
            v-model="newPayment.source"
            :label="$t('payment.method')"
            :options="paymentSourceConst"
            :validator="v$.newPayment.source"
            class-name="col-md-6" />
          <AkInputMoney v-model="newPayment.stripeFee" :label="$t('payment.stripe_fee')" class-name="col-md-6" />
        </div>
        <div class="form-row">
          <AkInputMoney
            v-model="item.totalTds"
            :disabled="true"
            :label="$t('payment.amount_tds_total')"
            class-name="col-md-6" />
          <AkInputMoney
            v-if="isItemVrbo"
            v-model="newPayment.totalCommission"
            :label="$t('payment.amount_commission')"
            :validator="v$.newPayment.totalCommission"
            :min="item.paidCommission"
            class-name="col-md-6" />
          <AkInputMoney
            v-else
            v-model="item.totalCommission"
            :disabled="true"
            :label="$t('payment.amount_commission')"
            class-name="col-md-6" />
        </div>
        <div class="form-row">
          <AkInputMoney
            v-model="item.paidTdsOwner"
            :disabled="true"
            :label="$t('payment.amount_owner_tds_paid')"
            class-name="col-md-4" />
          <AkInputMoney
            v-model="item.paidTdsGuest"
            :disabled="true"
            :label="$t('payment.amount_guest_tds_paid')"
            class-name="col-md-4" />
          <AkInputMoney
            v-model="item.paidTdsGuestAdom"
            :disabled="true"
            :label="$t('payment.amount_guest_adom_tds_paid')"
            class-name="col-md-4" />
        </div>
        <div class="form-row">
          <AkInputMoney
            v-model="newPayment.amountOwnerTds"
            :label="$t('payment.amount_owner_tds')"
            class-name="col-md-4" />
          <AkInputMoney
            v-model="newPayment.amountGuestTds"
            :label="$t('payment.amount_guest_tds')"
            class-name="col-md-4" />
          <AkInputMoney
            v-model="newPayment.amountGuestAdomTds"
            :label="$t('payment.amount_guest_adom_tds')"
            class-name="col-md-4" />
        </div>
        <div class="form-row">
          <AkInputText v-model="newPayment.transferNum" :label="$t('payment.transfer_num')" class-name="col-md-4" />
          <AkInputMoney
            v-model="newPayment.totalHousework"
            :label="$t('payment.amount_housework')"
            class-name="col-md-4" />
        </div>
        <div class="form-row">
          <AkInputTextArea v-model="newPayment.comment" :label="$t('operation.comment')" class-name="col-md-12" />
        </div>
      </AkDialog>
      <!-- ----------------------------------------------------------------------- -->
      <AkDialogConfirm
        ref="dialogNotPay"
        :title="$t('payment.not_pay_confirm_title')"
        :message="$t('payment.not_pay_confirm_message')"
        @validate="handleNotPay()" />
      <!-- ----------------------------------------------------------------------- -->
      <AkDialog
        ref="dialogRejectPayment"
        :title="$t('payment.reject_payment')"
        :validate-label="$t('payment.reject')"
        width="800px"
        @validate="handleRejectPayment()">
        <div class="form-row">
          <AkInputText
            v-model="rejectedPayment.motif"
            :label="$t('payment.rejected_reason')"
            :validator="v$.rejectedPayment.motif"
            class-name="col-md-4" />
          <AkCalendar
            v-model="rejectedPayment.date"
            :label="$t('payment.rejected_date')"
            :validator="v$.rejectedPayment.date"
            class-name="col-md-4">
          </AkCalendar>
          <AkInputMoney
            v-model="rejectedPayment.amount"
            :label="$t('payment.rejected_amount')"
            :validator="v$.rejectedPayment.amount"
            class-name="col-md-4"
            @update:modelValue="checkRejectAmount()" />
        </div>
        <p v-if="disclaimerRejectedAmount" style="color: red">
          {{ this.$t('payment.reject_disclaimer1') }} :
          {{ formatCurrencyEUR(formatAmount(this.rejectedPayment.paidAmount)) }}
        </p>
        <p v-if="disclaimerRejectedAmount" style="color: red">
          {{ this.$t('payment.reject_disclaimer2') }}
        </p>
        <p v-if="!disclaimerRejectedAmount">
          {{ this.$t('payment.reject_disclaimer1') }} :
          {{ formatCurrencyEUR(formatAmount(this.rejectedPayment.paidAmount)) }}
        </p>
        <p v-if="!disclaimerRejectedAmount">
          {{ this.$t('payment.reject_disclaimer2') }}
        </p>
      </AkDialog>
    </template>
  </GaView>
</template>
<style>
aside {
  color: white;
}
</style>
