<script>
import {getDayShort, getMonthShort} from '@utils/dateTime';

/* COMPOSANTS */
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import missionMixin from '@mixins/missionMixin';
import prestationTypesConst from '@mixins/const/prestationTypesConst';
import personnelTypeConst from '@mixins/const/personnelTypeConst';

/* SERVICES */

export default {
  components: {DataTable, Column},
  mixins: [utilsMixin, missionMixin, prestationTypesConst, personnelTypeConst],
  props: {
    modelValue: {
      required: true,
    },
    dates: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {};
  },
  setup() {
    return {};
  },
  watch: {},
  mounted() {},
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  methods: {
    getDayShort,
    getMonthShort,
  },
};
</script>

<template>
  <div class="participants-planning">
    <DataTable
      :value="currentValue"
      :paginator="true"
      :always-show-paginator="false"
      :rows="10"
      :rows-per-page-options="[10, 20, 50]"
      :loading="this.loading"
      class="table pointer gd-datatable"
      current-page-report-template="{first}-{last}/{totalRecords}"
      removable-sort
      responsive-layout="scroll"
      striped-rows>
      <Column field="name" :header="this.$t('planning_intervenant.participant')">
        <template #body="slotProps">
          <span>{{ slotProps.data.name }} ({{ this.displayForPersonnelTypeConst(slotProps.data.assignedStaff) }})</span>
        </template>
      </Column>

      <Column v-for="date in dates" :key="date.toString()" :field="date.toString()">
        <template #header>
          <div class="d-flex justify-content-around align-items-center w-100 px-1">
            <span v-text="getDayShort(date)"></span>
            <span class="date-badge bg-primary" v-text="new Date(date).getDate()"></span>
            <span v-text="getMonthShort(date)"></span>
          </div>
        </template>
        <template #body="slotProps">
          <div :class="getDayBackground(slotProps.data.dateMap[dateToIndex(date)])" class="day">
            <template v-for="mission in slotProps.data.dateMap[dateToIndex(date)]" :key="mission.id">
              <a
                v-if="mission.id !== null"
                class="task"
                :class="getTaskBackground(mission)"
                :href="`/mission-order/${mission.id}`"
                v-tooltip.bottom="mission.addressDisplay">
                <span v-text="mission.accommodationName"></span>
                <span v-text="displayForPrestationTypeConst(mission.type)" class="mt-1"></span>
                <span v-text="displayPrestationTime(mission)"></span>
              </a>
            </template>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<style lang="scss" scoped>
.participants-planning {
  th {
    padding: 13px 0;

    &:first-child {
      width: 133px !important;
      padding: 13px 24px;
    }
  }

  .p-datatable .p-datatable-tbody > tr > td {
    padding: 4px;
    font: 600 14px/20px 'Nunito Sans';
    color: #475467;

    &:first-child {
      text-align: center;
      padding: 8px;
    }

    &:not(:first-child) {
      vertical-align: top;
    }
  }

  .date-badge {
    border-radius: 32px;
    padding: 5px 8px;
    font: 600 12px/18px 'Nunito Sans';
    color: white;
  }

  td {
    .day {
      display: flex;
      flex-direction: column;
      margin: 8px;

      &.unavailability {
        border-radius: 4px;
        padding: 5%;
        background-image: linear-gradient(
          45deg,
          #cccccc 25%,
          #ffffff 25%,
          #ffffff 50%,
          #cccccc 50%,
          #cccccc 75%,
          #ffffff 75%,
          #ffffff 100%
        );
        background-size: 40px 40px;
        width: 100%;
        height: 100%;
        min-height: 100px;
      }

      > .task {
        padding: 8px;
        display: flex;
        flex-direction: column;
        border-radius: 4px;

        &:not(:last-child) {
          margin-bottom: 8px;
        }

        > span {
          font: 400 14px/20px 'Nunito Sans';
          color: white;
        }
      }
    }
  }
}
</style>
