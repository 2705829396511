export default {
  data() {
    return {
      residenceTypeConst: [
        {label: this.$t('accommodation.mainResidence'), value: 'primary_residence'},
        {label: this.$t('accommodation.secondResidence'), value: 'secondary_residence'},
        {label: this.$t('accommodation.rentalInvestment'), value: 'non_residential'},
        {label: this.$t('undefined'), value: undefined},
      ],
      residenceTypeConstForProspect: [
        {label: this.$t('accommodation.mainResidence'), value: 'primary_residence'},
        {label: this.$t('accommodation.secondResidence'), value: 'secondary_residence'},
        {label: this.$t('accommodation.rentalInvestment'), value: 'non_residential'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForResidenceTypeConst(value) {
      let result = this.residenceTypeConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
    getResidenceTypeConstForProspect() {
      return this.residenceTypeConstForProspect;
    },
    getResidenceTypeConstOrder() {
      return this.residenceTypeConst.sort((a, b) => {
        if (a.value === null) return -1;
        if (b.value === null) return 1;
        return a.label.localeCompare(b.label);
      }); // b - a for reverse sort
    },
  },
};
