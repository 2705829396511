<script>
import $ from 'jquery';

/* COMPOSANTS */
import AkDialog from '@components/general/AkDialog';

/* MIXINS */
/* SERVICES */

export default {
  components: {AkDialog},
  props: {
    title: {type: String, required: true},
    message: {type: String, required: true},
  },
  emits: ['cancel', 'validate'],
  data() {
    return {
      display: false,
      submitted: false,
    };
  },
  mounted() {},
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    validate() {
      this.$emit('validate');
    },
    show() {
      this.$refs.dialogConfirm.show();
    },
    hide() {
      this.$refs.dialogConfirm.hide();
    },
  },
};
</script>

<template>
  <AkDialog
    ref="dialogConfirm"
    :auto-hide-on-validate="true"
    :cancel-label="$t('no')"
    :validate-label="$t('yes')"
    :title="title"
    width="600px"
    @cancel="cancel"
    @validate="validate">
    <div class="confirmation-content">
      <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
      <span>{{ message }}</span>
    </div>
  </AkDialog>
</template>
