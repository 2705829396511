export default {
  data() {
    return {
      missionMonitoringStatusConst: [
        {label: this.$t('mission_monitoring.todo'), value: 'TO_DO'},
        {label: this.$t('mission_monitoring.finish'), value: 'FINISH'},
        {label: this.$t('mission_monitoring.canceled'), value: 'CANCELED'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForMissionMonitoringStatusConst(value) {
      let result = this.missionMonitoringStatusConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
