import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  housekeeperAccommodations(filter) {
    return Api()
      .get('/housekeeperaccommodations', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  count(filter) {
    return Api()
      .get('/housekeeperaccommodations/count', {params: removeEmptyParams(filter)})
      .then((res) => res.data);
  },
  housekeeperAccommodation(id) {
    return Api()
      .get('/housekeeperaccommodations/' + id)
      .then((res) => this.formatRes(res.data));
  },
  formatRes(e) {
    return e;
  },
};
