<script>
import useVuelidate from '@vuelidate/core';
import {helpers, required, requiredIf} from '@vuelidate/validators';

/* COMPOSANTS */
import AkInputTextArea from '@components/input/AkInputTextArea';
import AkCalendar from '@components/input/AkCalendar';
import GaView from '@components/layout/GaView';
import AkFormSubmitted from '@components/general/AkFormSubmitted.vue';
import AkInputText from '@components/input/AkInputText.vue';
import AkInputMoney from '@components/input/AkInputMoney.vue';
import AkDialog from '@components/general/AkDialog.vue';
import AkLoader from '@components/general/AkLoader.vue';
import AkDropdown from '@components/input/AkDropdown.vue';
import LitigeItemList from '@views/litige/components/LitigeItemList.vue';

/* MIXINS */
import backMixin from '@mixins/backMixin';
import messageMixin from '@mixins/messageMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import loaderMixin from '@mixins/loaderMixin';
import litigeStatusConst from '@mixins/const/litigeStatusConst';
import litigeResolutionConst from '@mixins/const/litigeResolutionConst';
import litigeCompleteStatusConst from '@mixins/const/litigeCompleteStatusConst';

/* SERVICES */
import litigeService from '@services/litigeService';

export default {
  components: {
    AkFormSubmitted,
    LitigeItemList,
    AkLoader,
    AkDialog,
    AkInputMoney,
    AkDropdown,
    AkInputText,
    AkInputTextArea,
    AkCalendar,
    GaView,
  },
  mixins: [
    backMixin,
    loaderMixin,
    messageMixin,
    permissionsMixin,
    randomRef,
    utilsMixin,
    litigeStatusConst,
    litigeResolutionConst,
    litigeCompleteStatusConst,
  ],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'litige.details',
    };
  },
  validations() {
    return {
      litigeUpdate: {
        amountEstimatedTtc: {required},
        litigeItemList: {
          $each: helpers.forEach({
            amountEstimatedTtc: {},
          }),
        },
        reason: {required: requiredIf(this.isReasonRequired)},
        dateDecision: {required: requiredIf(this.isDateDecisionRequired)},
        amountTtc: {required: requiredIf(this.isAmountTtcRequired)},
        amountGas: {required: requiredIf(this.isAmountGasRequired)},
        amountGav: {required: requiredIf(this.isAmountGavRequired)},
        amountOwner: {required: requiredIf(this.isAmountOwnerRequired)},
      },
      litigeUpdateComplete: {
        refusalComment: {required: requiredIf(this.isRefusalCommentRequired)},
      },
    };
  },
  data() {
    return {
      litigeId: undefined,
      litige: {},
      litigeUpdate: {},
      litigeUpdateComplete: {},
      submitted: false,
    };
  },
  computed: {
    isLitigeLoaded() {
      return !!this.litigeId;
    },
    isReasonRequired() {
      return this.isStatusEnCours && this.isUpdateResolutionPerdu;
    },
    isDateDecisionRequired() {
      return this.isStatusEnCours && (this.isUpdateResolutionPerdu || this.isUpdateResolutionGagne);
    },
    isAmountTtcRequired() {
      return this.isStatusEnCours && this.isUpdateResolutionGagne;
    },
    isAmountGasRequired() {
      return this.isStatusEnCours && this.isUpdateResolutionGagne;
    },
    isAmountGavRequired() {
      return this.isStatusEnCours && this.isUpdateResolutionGagne;
    },
    isAmountOwnerRequired() {
      return this.isStatusEnCours && this.isUpdateResolutionGagne;
    },
    isRefusalCommentRequired() {
      return this.litigeUpdateComplete.completeStatus === 'REFUSED';
    },
    isUpdateResolutionGagne() {
      return this.litigeUpdate.resolution === 'GAGNE';
    },
    isUpdateResolutionPerdu() {
      return this.litigeUpdate.resolution === 'PERDU';
    },
    isResolutionGagne() {
      return this.litige.resolution === 'GAGNE';
    },
    isResolutionPerdu() {
      return this.litige.resolution === 'PERDU';
    },
    isStatusATraiter() {
      return this.litige.status === 'A_TRAITER';
    },
    isStatusEnCours() {
      return this.litige.status === 'EN_COURS';
    },
    isStatusResolu() {
      return this.litige.status === 'RESOLU';
    },
    isCompleteTrue() {
      return this.litige.complete === true;
    },
    isCompleteStatusValidated() {
      return this.litige.completeStatus === 'VALIDATED';
    },
    isCompleteStatusRefused() {
      return this.litige.completeStatus === 'REFUSED';
    },
    hasCompleteStatus() {
      return !!this.litige.completeStatus;
    },
    hasRefusalComment() {
      return !!this.litige.refusalComment;
    },
    totalAmountEstimatedTtc() {
      if (!this.litigeUpdate) return null;
      let total = 0;
      for (let item of this.litigeUpdate.litigeItemList) {
        total = this.addNumbers([total, item.amountEstimatedTtc]);
      }
      return total;
    },
    linkReservation() {
      if (!this.litige.reservationId) return;
      return {
        route: 'accommodationReservationPage',
        params: {accommodationId: this.litige.accommodationId, reservationId: this.litige.reservationId},
        icon: 'fe fe-eye color-label',
      };
    },
  },
  watch: {},
  mounted() {
    this.litigeId = this.$route.params.litigeId;
    this.refresh();
  },
  methods: {
    refresh() {
      this.$refs.loader.load();
      litigeService
        .litige(this.litigeId)
        .then((data) => {
          this.litige = data;
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.$refs.loader.hide();
        });
    },
    traitLitige() {
      this.submitted = true;
      this.litigeUpdate.amountEstimatedTtc = this.totalAmountEstimatedTtc;
      this.v$.$touch();
      if (this.v$.litigeUpdate.$error) return;

      this.$refs.dialogTrait.hide();
      this.$refs.loader.load();

      litigeService
        .stepTrait(this.litigeUpdate, [])
        .then((data) => {
          this.success(this.$t('litige.traited'));
          this.refresh();
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.$refs.loader.hide();
        });
    },
    resolveLitige() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.litigeUpdate.$error) return;

      if (this.isUpdateResolutionPerdu) this.$refs.dialogPerdu.hide();
      if (this.isUpdateResolutionGagne) {
        if (
          this.addNumbers([this.litigeUpdate.amountGas, this.litigeUpdate.amountGav, this.litigeUpdate.amountOwner]) !==
          this.litigeUpdate.amountTtc
        ) {
          this.$refs.dialogGagne.error(this.$t('maintenance.error_total_amount'));
          return;
        }
        this.$refs.dialogGagne.hide();
      }

      this.$refs.loader.load();

      litigeService
        .stepResolve(this.litigeUpdate)
        .then((data) => {
          this.success(this.$t('litige.resolved'));
          this.refresh();
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.$refs.loader.hide();
        });
    },
    openTraitDialog() {
      this.litigeUpdate = {...this.litige};
      this.$refs.dialogTrait.show();
    },
    openGagneDialog() {
      this.litigeUpdate = {...this.litige};
      this.litigeUpdate.resolution = 'GAGNE';
      if (!this.litigeUpdate.amountGas) this.litigeUpdate.amountGas = 0;
      if (!this.litigeUpdate.amountGav) this.litigeUpdate.amountGav = 0;
      if (!this.litigeUpdate.amountOwner) this.litigeUpdate.amountOwner = 0;
      this.$refs.dialogGagne.show();
    },
    openPerduDialog() {
      this.litigeUpdate = {...this.litige};
      this.litigeUpdate.resolution = 'PERDU';
      this.$refs.dialogPerdu.show();
    },
    displayRemovedMessage() {
      this.$refs.dialogTrait.success(this.$t('facturation_hote.files_deleted'));
    },
    litigeCompleted() {
      litigeService
        .litigeCompleted(this.litigeId)
        .then((data) => {
          this.litige.complete = true;
          this.success(this.$t('litige.step_validated'));
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {});
    },
    validateOrRefuseComplete(status) {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.litigeUpdateComplete.$error) return;

      if (status === 'VALIDATED') this.litigeUpdateComplete = {...this.litige};
      this.litigeUpdateComplete.completeStatus = status;

      litigeService
        .update(this.litigeUpdateComplete)
        .then((data) => {
          status === 'VALIDATED'
            ? this.success(this.$t('litige.complete_validated'))
            : this.success(this.$t('litige.complete_refused'));
          this.submitted = false;
          this.refresh();
        })
        .catch((e) => {
          this.addError(this.$t('error.' + e.response.data.error));
          this.submitted = false;
        })
        .finally(() => {
          if (this.$refs.dialogRefuse) this.$refs.dialogRefuse.hide();
        });
    },
    openRefuseDialog() {
      this.litigeUpdateComplete = {...this.litige};
      this.litigeUpdateComplete.completeStatus = 'REFUSED';
      this.$refs.dialogRefuse.show();
    },
  },
};
</script>

<template>
  <AkLoader ref="loader" :message="$t('currently_loading')" :scale="1.5" color="black">
    <template #content>
      <GaView :ref="ref" :title="$t('litige.details')">
        <template #back>
          <a class="mb-1 d-block pointer" @click="goBack()"><i class="fe fe-arrow-left pr-1" />{{ $t('back') }}</a>
        </template>

        <template #action>
          <div class="float-right" v-if="this.isGasGav()">
            <div class="d-flex">
              <button
                v-if="this.isLitigeLoaded && this.isGav() && this.isStatusEnCours && !this.isCompleteTrue"
                class="btn btn-secondary"
                style="margin-right: 16px"
                @click="litigeCompleted()">
                {{ $t('litige.litige_complete') }}
              </button>
              <button
                v-if="this.isLitigeLoaded && this.isGas() && this.isCompleteTrue && !this.hasCompleteStatus"
                class="btn btn-success"
                style="margin-right: 16px"
                @click="validateOrRefuseComplete('VALIDATED')">
                {{ $t('litige.validate_complete') }}
              </button>
              <button
                v-if="this.isLitigeLoaded && this.isGas() && this.isCompleteTrue && !this.hasCompleteStatus"
                class="btn btn-danger"
                style="margin-right: 16px"
                @click="openRefuseDialog()">
                {{ $t('litige.refuse_complete') }}
              </button>
              <button v-if="isStatusATraiter" class="btn btn-inverse-secondary" @click="openTraitDialog()">
                {{ $t('maintenance.a_traiter') }}
              </button>
              <button
                v-if="isStatusEnCours && isCompleteStatusValidated"
                class="btn btn-success"
                style="margin-right: 16px"
                @click="openGagneDialog()">
                {{ $t('litige.gagne') }}
              </button>
              <button
                v-if="isStatusEnCours && isCompleteStatusValidated"
                class="btn btn-danger"
                @click="openPerduDialog()">
                {{ $t('litige.perdu') }}
              </button>
              <router-link
                v-if="isLitigeLoaded && !isStatusResolu"
                :to="{name: 'litigeUpdate', params: {litigeId: this.litigeId}}"
                class="btn btn-inverse-primary ml-1">
                <i class="fe fe-edit pr-1" /> {{ $t('maintenance.updateThis') }}
              </router-link>
            </div>
          </div>
        </template>

        <template #content>
          <span
            v-if="this.isGas() && isStatusEnCours && !isCompleteTrue"
            class="waiting-validation bg-info text-white br-5">
            {{ this.$t('litige.waiting_gav_completion') }}
          </span>
          <span
            v-if="this.isGav() && isStatusEnCours && isCompleteTrue && !hasCompleteStatus"
            class="waiting-validation bg-info text-white br-5">
            {{ this.$t('litige.waiting_gas_validation') }}
          </span>
          <AkFormSubmitted :disabled="true" reference="form">
            <!-- Litige -->

            <section id="section-litige">
              <h5>Litige</h5>

              <div class="form-row">
                <AkDropdown
                  v-model="litige.status"
                  :label="$t('litige.status')"
                  :options="litigeStatusConst"
                  option-label="label"
                  option-value="value"
                  class-name="col-6" />
                <AkInputText
                  v-model="litige.avantioLocalizer"
                  :label="$t('reservation.number')"
                  :placeholder="$t('reservation.number')"
                  class-name="col-6"
                  :link="linkReservation" />
              </div>
              <div class="form-row">
                <AkInputText
                  v-if="this.isGas()"
                  v-model="litige.companyName"
                  :label="$t('employee.company')"
                  :placeholder="$t('employee.company')"
                  class-name="col-4" />
                <AkInputText
                  v-model="litige.accommodationName"
                  :label="$t('accommodation.name')"
                  :placeholder="$t('accommodation.name')"
                  class-name="col-4" />
                <AkInputText
                  v-if="!this.isOwner()"
                  v-model="litige.ownerName"
                  :label="$t('accommodation.ownerName')"
                  :placeholder="$t('accommodation.ownerName')"
                  class-name="col-4" />
              </div>
              <div class="form-row" v-if="(!isStatusATraiter && this.hasCompleteStatus) || this.isStatusResolu">
                <AkDropdown
                  v-model="litige.completeStatus"
                  :label="$t('litige.complete_status')"
                  :options="litigeCompleteStatusConst"
                  option-label="label"
                  option-value="value"
                  class-name="col-4" />
                <!-- Date de revue du dossier -->
                <AkCalendar
                  v-model="litige.date"
                  :label="$t('litige.complete_date')"
                  :placeholder="$t('litige.complete_date')"
                  date-format="dd/mm/yy"
                  selection-mode="single"
                  class-name="col-4" />
                <AkInputTextArea
                  v-if="hasRefusalComment"
                  v-model="litige.refusalComment"
                  :label="$t('litige.refused_comment')"
                  :placeholder="$t('litige.refused_comment')"
                  class-name="col-4" />
              </div>
              <!-- <div class="form-row">
                <AkInputTextArea
                  v-model="litige.description"
                  :label="$t('litige.description')"
                  :placeholder="$t('litige.description')"
                  class-name="col-12" />
              </div> -->
            </section>

            <!-- Litige items (Articles) -->

            <section id="litige-items" v-if="litige.litigeItemList?.length > 0">
              <LitigeItemList
                ref="equipments"
                v-model="litige.litigeItemList"
                :submitted="this.submitted"
                :displayMaintenanceFiles="true"
                :displayMaintenanceDate="true"
                :canDeleteFiles="false"
                :canUploadFiles="false"
                :disabled="true" />
            </section>

            <!-- Dossier litige -->

            <section id="section-dossier-litige" v-if="!this.isStatusATraiter">
              <h5>Dossier litige</h5>

              <div class="form-row">
                <AkInputMoney
                  v-model="litige.amountEstimatedTtcValue"
                  :label="$t('litige.total_amount_estimated_ttc')"
                  :placeholder="$t('litige.total_amount_estimated_ttc')"
                  class-name="col-4" />
              </div>
              <div class="form-row" v-if="this.isStatusResolu">
                <AkDropdown
                  v-model="litige.resolution"
                  :label="$t('litige.resolution')"
                  :options="litigeResolutionConst"
                  option-label="label"
                  option-value="value"
                  class-name="col-4" />
                <AkInputTextArea
                  v-if="isResolutionPerdu"
                  v-model="litige.reason"
                  :label="$t('litige.reason')"
                  :placeholder="$t('litige.reason')"
                  class-name="col-4" />
                <AkCalendar
                  v-model="litige.dateDecision"
                  :label="$t('litige.date_decision')"
                  :placeholder="$t('litige.date_decision')"
                  date-format="dd/mm/yy"
                  selection-mode="single"
                  class-name="col-4" />
              </div>

              <section id="dossier-litige-gagne" v-if="isResolutionGagne">
                <div class="form-row">
                  <AkInputMoney
                    v-model="litige.amountTtcValue"
                    :label="$t('litige.amount_ttc')"
                    :placeholder="$t('litige.amount_ttc')"
                    class-name="col-4" />
                </div>
                <div class="form-row">
                  <AkInputMoney
                    v-model="litige.amountGasValue"
                    :label="$t('litige.amount_gas')"
                    :placeholder="$t('litige.amount_gas')"
                    class-name="col-4" />
                  <AkInputMoney
                    v-model="litige.amountGavValue"
                    :label="$t('litige.amount_gav')"
                    :placeholder="$t('litige.amount_gav')"
                    class-name="col-4" />
                  <AkInputMoney
                    v-model="litige.amountOwnerValue"
                    :label="$t('litige.amount_owner')"
                    :placeholder="$t('litige.amount_owner')"
                    class-name="col-4" />
                </div>
              </section>
            </section>
          </AkFormSubmitted>
        </template>

        <!-- DIALOGS -->

        <template #extra>
          <!-- STEP / TRAIT-->

          <AkDialog
            ref="dialogTrait"
            :title="$t('litige.trait_litige')"
            :validate-label="$t('validate')"
            width="800px"
            @validate="traitLitige()">
            <div class="col-12">
              <LitigeItemList
                ref="equipments"
                v-model="litigeUpdate.litigeItemList"
                :submitted="this.submitted"
                :displayMaintenanceFiles="false"
                :displayMaintenanceDate="true"
                @removed="displayRemovedMessage" />
            </div>
            <div class="form-row">
              <AkInputMoney
                v-model="totalAmountEstimatedTtc"
                :label="$t('litige.total_amount_estimated_ttc')"
                :validator="v$.litigeUpdate.amountEstimatedTtc"
                :disabled="true"
                class-name="col-6" />
            </div>
          </AkDialog>

          <!-- STEP / RESOLVE-->

          <AkDialog
            ref="dialogGagne"
            :title="$t('litige.resolve')"
            :validate-label="$t('validate')"
            width="800px"
            @validate="resolveLitige()">
            <div class="form-row">
              <AkCalendar
                v-model="litigeUpdate.dateDecision"
                :validator="v$.litigeUpdate.dateDecision"
                :label="$t('litige.date_decision')"
                :maxDate="new Date()"
                class-name="col-12" />
            </div>
            <div class="form-row">
              <AkInputMoney
                v-model="litigeUpdate.amountEstimatedTtc"
                :label="$t('reservation.amountLitigeEstimated')"
                :disabled="true"
                class-name="col-12" />
            </div>
            <div class="form-row">
              <AkInputMoney
                v-model="litigeUpdate.amountTtc"
                :label="$t('reservation.amountLitigeReported')"
                :validator="v$.litigeUpdate.amountTtc"
                class-name="col-12" />
            </div>
            <div class="form-row">
              <AkInputMoney
                v-model="litigeUpdate.amountGas"
                :label="$t('reservation.amountLitigeGas')"
                :validator="v$.litigeUpdate.amountGas"
                :max="litigeUpdate.amountTtc"
                class-name="col-4" />
              <AkInputMoney
                v-model="litigeUpdate.amountGav"
                :label="$t('reservation.amountLitigeGav')"
                :validator="v$.litigeUpdate.amountGav"
                :max="litigeUpdate.amountTtc"
                class-name="col-4" />
              <AkInputMoney
                v-model="litigeUpdate.amountOwner"
                :label="$t('reservation.amountLitigeOwner')"
                :validator="v$.litigeUpdate.amountOwner"
                :max="litigeUpdate.amountTtc"
                class-name="col-4" />
            </div>
          </AkDialog>

          <AkDialog
            ref="dialogPerdu"
            :title="$t('litige.resolve')"
            :validate-label="$t('validate')"
            width="800px"
            @validate="resolveLitige()">
            <div class="form-row">
              <AkInputText
                v-model="litigeUpdate.reason"
                :label="$t('litige.reason')"
                :placeholder="$t('litige.reason')"
                :validator="v$.litigeUpdate.reason"
                class-name="col-12" />
            </div>
            <div class="form-row">
              <AkCalendar
                v-model="litigeUpdate.dateDecision"
                :label="$t('litige.date_decision')"
                :placeholder="$t('litige.date_decision')"
                date-format="dd/mm/yy"
                selection-mode="single"
                :maxDate="new Date()"
                class-name="col-6"
                :validator="v$.litigeUpdate.dateDecision" />
            </div>
          </AkDialog>

          <AkDialog
            ref="dialogRefuse"
            :title="$t('litige.refuse_complete')"
            :validate-label="$t('litige.refuse_complete')"
            width="800px"
            @validate="validateOrRefuseComplete('REFUSED')">
            <div class="form-row">
              <AkInputTextArea
                v-model="litigeUpdateComplete.refusalComment"
                :label="$t('litige.reason')"
                :placeholder="$t('litige.reason')"
                :validator="v$.litigeUpdateComplete.refusalComment"
                class-name="col-12" />
            </div>
          </AkDialog>
        </template>
      </GaView>
    </template>
  </AkLoader>
</template>
