export default {
  data() {
    return {
      civilitiesConst: [
        {label: 'Monsieur', value: 'M'},
        {label: 'Madame', value: 'MME'},
        {label: 'Madame et Monsieur', value: 'MME_M'},
        {label: 'Madame et Madame', value: 'MME_MME'},
        {label: 'Monsieur et Monsieur', value: 'M_M'},
        {label: this.$t('undefined'), value: undefined},
      ],
      civilitiesConstForProspect: [
        {label: 'Monsieur', value: 'M'},
        {label: 'Madame', value: 'MME'},
        {label: 'Madame et Monsieur', value: 'MME_M'},
        {label: 'Madame et Madame', value: 'MME_MME'},
        {label: 'Monsieur et Monsieur', value: 'M_M'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForCivilityConst(value) {
      let result = this.civilitiesConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
    getCivilitiesConstForProspect() {
      return this.civilitiesConstForProspect;
    },
  },
};
