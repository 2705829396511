export default {
  data() {
    return {
      typologyConst: [
        {label: this.$t('employee.typology1'), value: 'ACCIDENT_AT_WORK/OCCUPATIONAL_DISEASE'},
        {label: this.$t('employee.typology2'), value: 'INVALID_PENSIONER'},
      ],
    };
  },
};
