<script>
export default {
  props: {
    md5: {
      type: String,
      required: false,
    },
    height: {
      type: [Number, String],
      required: false,
      default: undefined,
    },
    radius: {
      type: [Number, String],
      required: false,
      default: 5,
    },
  },
  computed: {
    urlPicture() {
      if (this.md5) {
        return {
          background: 'url(' + process.env.VUE_APP_PUBLIC_API_URL + '/picture/' + this.md5 + ')',
          'border-radius': this.radius + 'px',
        };
      }
      return {background: 'url(' + require('@/assets/avatar.jpg') + ')', 'border-radius': this.radius + 'px'};
    },
    heightStyle() {
      if (this.height) {
        return {height: this.height + 'px', 'border-radius': this.radius + 'px'};
      } else {
        return {height: '100%', 'border-radius': this.radius + 'px'};
      }
    },
  },
};
</script>

<template>
  <div class="picture-accommodation" v-bind:style="heightStyle">
    <img
      class="pictures-acc"
      src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA"
      v-bind:style="urlPicture" />
  </div>
</template>
