<script>
import useVuelidate from '@vuelidate/core';
import {Icon} from '@iconify/vue';
import moment from 'moment/moment';
import {required, requiredIf} from '@vuelidate/validators';

/* COMPOSANTS */
import AkPlanning from '@components/general/AkPlanning';
import AkDialog from '@components/general/AkDialog';
import AkCalendar from '@components/input/AkCalendar';
import AkDropdown from '@components/input/AkDropdown';
import GaMessages from '@components/ga/layout/GaMessages';
import AkPlanningYear from '@components/general/AkPlanningYear';
import AkTime from '@components/input/AkTime';
import MultiSelect from 'primevue/multiselect';
import PButton from 'primevue/button';

/* MIXINS */
import accommodationTypeConst from '@mixins/const/accommodationTypeConst';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import randomRef from '@mixins/randomRef';
import residenceTypeConst from '@mixins/const/residenceTypeConst';
import sidebarMixin from '@mixins/sidebarMixin';
import smilyStatusConst from '@mixins/const/smilyStatusConst';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import accommodationService from '@services/accommodationService';
import reservationPlanningService from '@services/reservationPlanningService';
import ticketService from '@services/ticketService';
import AkInputTextArea from '@components/input/AkInputTextArea.vue';

export default {
  components: {
    AkInputTextArea,
    AkPlanningYear,
    AkCalendar,
    AkDialog,
    PButton,
    AkDropdown,
    AkPlanning,
    GaMessages,
    Icon,
    AkTime,
    MultiSelect,
  },
  mixins: [
    accommodationTypeConst,
    loaderMixin,
    messageMixin,
    permissionsMixin,
    randomRef,
    residenceTypeConst,
    sidebarMixin,
    smilyStatusConst,
    utilsMixin,
  ],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'reservation.planning',
    };
  },
  data() {
    return {
      datePlanning: moment(),
      companies: [],
      canHideLoader: false,
      loader: undefined,
      width: 0,
      filter: {date: moment().toDate()},
      planning: [],
      displayYear: false,
      accommodations: [],
      accommodation: {},
      reservationHote: {},
      durationSelectedId: 0,
      nbDays: 15,
      disableResaHoteAccommodation: false,
      durationOptions: [
        {label: '2 semaines', value: 15},
        {label: '30 jours', value: 30},
      ],
    };
  },
  validations() {
    return {
      reservationHote: {
        accommodationId: {required},
        dateStart: {required},
        dateEnd: {required},
        hourCheckin: {required},
        hourCheckout: {required},
      },
    };
  },
  mounted() {
    this.refresh();
  },

  computed: {
    canDisplayYearly() {
      return (
        this.$route.query.accommodationId ||
        (this.isOwner() && this.hasSingleAccommodation) ||
        (this.planning != undefined &&
          (this.planning.length > 0 || typeof this.planning === 'object') &&
          this.filter.accommodationId != undefined &&
          this.filter.ownerId == undefined &&
          (this.filter.companyIds == undefined || this.filter.companyIds.length === 0) &&
          (this.filter.clusters == undefined || this.filter.clusters.length === 0) &&
          (this.filter.typologies == undefined || this.filter.typologies.length === 0) &&
          (this.filter.physicalReception == undefined || this.filter.physicalReception === false) &&
          (this.filter.laundry == undefined || this.filter.laundry === false))
      );
    },
    getDurationsOptions() {
      let durations = [...this.durationOptions];
      if (this.canDisplayYearly) {
        durations.push({label: 'Annuel', value: 0});
      }
      return durations;
    },
    hasSingleAccommodation() {
      return this.accommodations.length === 1;
    },
    hasManyAccommodations() {
      return this.accommodations.length > 1;
    },
    singleAccommodationId() {
      return this.accommodations[0].id;
    },
    minDateEnd() {
      if (this.reservationHote.dateStart) {
        return moment(this.reservationHote.dateStart).add(1, 'd').toDate();
      }
      return moment().add(1, 'd').toDate();
    },
  },

  methods: {
    refresh(forceRefresh = false) {
      if (this.isOwner()) {
        accommodationService.accommodations({userId: this.myUserId()}).then((data) => {
          this.accommodations = data;
          if (this.accommodations.length === 1) {
            this.filter.accommodationId = this.singleAccommodationId;
            this.selectDuration(2, true);
          } else if (this.filter.accommodationId) {
            this.selectDuration(2, true);
          } else if (forceRefresh) {
            this.selectDuration(this.durationSelectedId, true);
          }
        });
      }

      if (
        this.isGas() ||
        this.isGav() ||
        ((this.isPartenaire() || this.isHousekeeper()) && this.$route.query.accommodationId)
      ) {
        if (this.$route.query.accommodationId) this.filter.accommodationId = this.$route.query.accommodationId;
        this.selectDuration(2, true);
      } else if (this.isGav() || this.isPartenaire() || this.isHousekeeper()) {
        this.loader = this.$loading.show({
          canCancel: false,
          backgroundColor: '#FFF',
          color: '#064CDB',
          isFullPage: true,
        });
        reservationPlanningService.planning(this.filter).then((data) => {
          this.planning = data;
          this.removeDuplicate();
          this.canHideLoader = true;
        });
      }
    },
    deleteReservationHote(success) {
      if (success) {
        this.$refs.messages.success(this.$t('unavailability.deleted'));
        this.refresh(true);
      } else {
        this.$refs.messages.error(this.$t('unavailability.impossible_to_cancel'));
      }
    },
    resetFilter() {
      this.filter = {date: moment().toDate()};
      this.toggleReservationFilterPlanning(this.filter, this.search, this.resetFilter);
    },
    selectDuration(index, forceSelect = false) {
      if (
        (index === this.durationSelectedId && !forceSelect) ||
        (this.isOwner() && !forceSelect && !this.filter.accommodationId)
      ) {
        return;
      }

      this.durationSelectedId = index;
      if (this.getDurationsOptions.length > index && !this.getDurationsOptions[index].value) {
        this.getPlanningYearly();
      } else if (index !== 2) {
        this.nbDays = this.getDurationsOptions[index].value;
        this.getPlanning();
      }
    },
    nextDays() {
      let newDatePlanning = moment(this.datePlanning).add(10, 'd');
      this.filter.date = newDatePlanning.toDate();
      this.getPlanning();
    },
    prevDays() {
      let newDatePlanning = moment(this.datePlanning).add(-10, 'd');
      this.filter.date = newDatePlanning.toDate();
      this.getPlanning();
    },
    nextYear() {
      let newDatePlanning = moment(this.datePlanning).add(1, 'y');
      this.filter.date = newDatePlanning.toDate();
      this.getPlanningYearly();
    },
    prevYear() {
      let newDatePlanning = moment(this.datePlanning).add(-1, 'y');
      this.filter.date = newDatePlanning.toDate();
      this.getPlanningYearly();
    },
    today() {
      let newDatePlanning = moment();
      this.filter.date = newDatePlanning.toDate();
      if (this.displayYear) this.getPlanningYearly();
      else this.getPlanning();
    },
    hideLoader() {
      if (this.loader && this.canHideLoader) {
        this.loader.hide();
        this.loader = undefined;
      }
    },
    search() {
      if ((this.displayYear && this.canDisplayYearly) || this.isOwner()) {
        this.selectDuration(2, true);
      } else {
        this.selectDuration(this.durationSelectedId === 2 ? 0 : this.durationSelectedId, true);
      }
    },
    getPlanning() {
      if (!this.loader)
        this.loader = this.$loading.show({
          canCancel: false,
          backgroundColor: '#FFF',
          color: '#064CDB',
          isFullPage: true,
        });
      this.filter.dateYearly = undefined;
      reservationPlanningService
        .planning(this.filter)
        .then((data) => {
          this.displayYear = false;

          this.planning = data;
          this.removeDuplicate();
          this.canHideLoader = true;
          this.datePlanning = moment(this.filter.date);
        })
        .catch((e) => {});
    },
    getPlanningYearly() {
      if (!this.loader)
        this.loader = this.$loading.show({
          canCancel: false,
          backgroundColor: '#FFF',
          color: '#064CDB',
          isFullPage: true,
        });
      this.filter.dateYearly = this.filter.date;

      reservationPlanningService
        .planningYearly(this.filter)
        .then((data) => {
          this.planning = data;
          this.canHideLoader = true;
          this.datePlanning = moment(this.filter.date);
          this.displayYear = true;
        })
        .catch((e) => {});
    },
    openFilterPanel() {
      this.toggleReservationFilterPlanning(this.filter, this.search, this.resetFilter);
    },
    openAddDialog() {
      this.reservationHote = {
        id: undefined,
        accommodationId: this.filter.accommodationId,
      };
      if (this.filter.accommodationId) {
        this.disableResaHoteAccommodation = true;
      } else {
        this.disableResaHoteAccommodation = false;
      }
      this.$refs.dialogAdd.show();
    },
    addReservation(year, month, day) {
      let start = moment().set({year: year, month: month.number, date: day.number});
      if (start.isAfter(moment())) {
        this.reservationHote = {
          id: undefined,
          accommodationId: this.filter.accommodationId,
          dateStart: start.toDate(),
        };
        if (this.filter.accommodationId) {
          this.disableResaHoteAccommodation = true;
        } else {
          this.disableResaHoteAccommodation = false;
        }
        this.$refs.dialogAdd.show();
      }
    },
    editReservation(reservation) {
      this.reservationHote = {
        id: reservation.id,
        accommodationId: reservation.accommodationId,
        dateStart: moment(reservation.dateStart).toDate(),
        dateEnd: moment(reservation.dateEnd).toDate(),
        hourCheckin: moment(reservation.dateCheckin).toDate(),
        hourCheckout: moment(reservation.dateCheckout).toDate(),
        comment: reservation.comments,
      };
      this.disableResaHoteAccommodation = true;
      this.$refs.dialogAdd.show();
    },
    addReservationHote() {
      if (this.hasSingleAccommodation && !this.reservationHote.id) {
        this.reservationHote.accommodationId = this.singleAccommodationId;
      }
      this.v$.$touch();
      if (this.v$.$error) return;

      if (moment().isAfter(moment(this.reservationHote.hourCheckin))) {
        this.$refs.messages.error('La date de check-in doit être dans le futur', true, true);
        this.$refs.dialogAdd.hide();
        return;
      }

      this.$refs.dialogAdd.hide();
      this.showTotalLoaderWithAfter(this.$t('unavailability.in_progress'));
      reservationPlanningService
        .createReservationHote(this.reservationHote)
        .then((data) => {
          if (this.reservationHote.id) {
            this.$refs.messages.success(this.$t('unavailability.edit_success'), true, true);
          } else {
            this.$refs.messages.success(this.$t('unavailability.add_success'), true, true);
          }
          this.refresh(true);
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.$refs.messages.error(message, true, true);
        })
        .finally(() => {
          this.hideLoader();
        });
    },
    removeDuplicate() {
      this.planning.sort(function compare(a, b) {
        if (a.ownerDisplay.toLowerCase() < b.ownerDisplay.toLowerCase()) return -1;
        if (a.ownerDisplay.toLowerCase() > b.ownerDisplay.toLowerCase()) return 1;
        return 0;
      });
    },
  },
  watch: {
    'reservationHote.dateStart'(newVal) {
      if (moment(this.reservationHote.dateStart).isAfter(moment(this.reservationHote.dateEnd))) {
        this.reservationHote.dateEnd = undefined;
      }
      if (this.reservationHote.hourCheckin) {
        let oldHour = moment(this.reservationHote.hourCheckin).hour();
        let oldMin = moment(this.reservationHote.hourCheckin).minute();
        this.reservationHote.hourCheckin = moment(this.reservationHote.dateStart).hour(oldHour).minute(oldMin).toDate();
      } else {
        this.reservationHote.hourCheckin = moment(this.reservationHote.dateStart).hour(16).minute(0).toDate();
      }
    },
    'reservationHote.dateEnd'(newVal) {
      if (this.reservationHote.hourCheckout) {
        let oldHour = moment(this.reservationHote.hourCheckout).hour();
        let oldMin = moment(this.reservationHote.hourCheckout).minute();
        this.reservationHote.hourCheckout = moment(this.reservationHote.dateEnd).hour(oldHour).minute(oldMin).toDate();
      } else {
        this.reservationHote.hourCheckout = moment(this.reservationHote.dateEnd).hour(10).minute(0).toDate();
      }
    },
  },
};
</script>

<template>
  <GaMessages ref="messages" />

  <div class="h-100 d-flex flex-column">
    <GaTitleMenu :with-bullet="false">
      <slot name="title">
        <GaItemTitle :active="true" :title="$t('reservation.planning')" />
      </slot>
    </GaTitleMenu>
    <div class="d-flex justify-content-between mt-20 mb-1">
      <div v-if="this.isOwner()" class="d-flex" style="column-gap: 16px">
        <AkDropdown
          v-if="this.hasManyAccommodations"
          v-model="filter.accommodationId"
          :inline="true"
          :label="$t('accommodation.name')"
          :options="this.accommodations"
          :placeholder="$t('accommodation.name')"
          class-name="flex-1"
          optionLabel="name"
          optionValue="id" />
        <MultiSelect
          v-model="filter.types"
          :options="this.getPlanningSmily(this.isOwner(), this.isGav(), true)"
          :placeholder="$t('accommodation.status')"
          class="form-control flex-1"
          label=""
          option-label="label"
          option-value="value" />
        <AkCalendar
          v-model="filter.date"
          :inline="true"
          class-name="form-control flex-1"
          date-format="dd/mm/yy"
          selection-mode="single" />
        <button class="btn btn-primary btn-search flex-1" type="submit" @click="this.search">Rechercher</button>
      </div>
      <div v-else class="d-flex" />
      <div class="d-flex flex-row-reverse" style="column-gap: 16px">
        <button v-if="this.isOwner()" class="btn btn-primary" style="margin-left: 20px" @click="openAddDialog()">
          {{ this.$t('unavailability.add') }}
        </button>
        <a class="btn btn-primary a-flex-center" @click="today()">
          <Icon class="pr-1 icon-svg" icon="bi:calendar-range" />
          <span>Aujourd'hui</span>
        </a>
        <PButton
          v-if="!this.isOwner()"
          :badge="this.countFilter(this.filter)"
          badgeClass="p-badge-primary"
          class="btn btn-primary"
          label="Filtrer"
          @click="openFilterPanel()">
        </PButton>
      </div>
    </div>
    <div class="d-flex mb-2 mt-1 justify-content-between align-items-center">
      <div class="d-flex" style="column-gap: 16px; margin-left: 5px">
        <div
          v-for="s in this.getPlanningSmily(
            this.isOwner(),
            this.isGav() || this.isPartenaire() || this.isHousekeeper(),
          )"
          :key="s"
          class="d-flex align-items-center"
          style="column-gap: 8px">
          <span :class="s.class.toLowerCase()" class="losange"></span>
          <span>{{ s.label }}</span>
        </div>
        <div v-if="!this.isOwner()" class="d-flex align-items-center" style="column-gap: 8px">
          <Icon color="black" height="20px" icon="mdi:human-greeting" style="width: 20px !important" />
          <span>{{ this.$t('physical_arivee') }}</span>
        </div>
        <div v-if="!this.isOwner()" class="d-flex align-items-center" style="column-gap: 8px">
          <Icon color="black" height="20px" icon="iconoir:bed-ready" style="width: 20px !important" />
          <span>{{ this.$t('mandat.gestionLinge') }}</span>
        </div>
      </div>
      <div>
        <div
          aria-label="duration"
          class="gd-btn-group"
          role="group"
          v-if="!this.isOwner() || (this.isOwner() && this.filter.accommodationId)">
          <button
            v-for="({label}, index) in this.getDurationsOptions"
            :key="label"
            :class="durationSelectedId === index && 'selected'"
            type="button"
            @click="this.selectDuration(index)"
            v-text="label"></button>
        </div>
      </div>
    </div>

    <AkPlanning
      v-if="planning && !displayYear"
      ref="planning"
      v-model="planning"
      :date-ref="datePlanning"
      :nb-days-display="nbDays"
      @next="nextDays"
      @need-reload="deleteReservationHote"
      @edit-reservation="editReservation"
      @prev="prevDays"
      @hide-loader="
        () => {
          hideLoader();
        }
      " />

    <AkPlanningYear
      v-if="planning && displayYear"
      v-model="planning"
      @need-reload="deleteReservationHote"
      :date-ref="datePlanning"
      @next="nextYear"
      @prev="prevYear"
      @hide-loader="
        () => {
          hideLoader();
        }
      "
      @edit-reservation="editReservation"
      @add-reservation="addReservation" />
  </div>

  <AkDialog
    ref="dialogAdd"
    :title="this.reservationHote.id ? $t('unavailability.edit') : $t('unavailability.add')"
    :validate-label="this.reservationHote.id ? $t('update') : $t('add')"
    width="800px"
    @validate="addReservationHote()">
    <div class="form-row">
      <AkDropdown
        v-if="this.hasManyAccommodations"
        v-model="reservationHote.accommodationId"
        :label="$t('accommodation.name')"
        :options="this.accommodations"
        :validator="v$.reservationHote.accommodationId"
        class-name="col-md-6"
        option-label="display"
        :disabled="this.disableResaHoteAccommodation"
        option-value="id" />
    </div>
    <div class="form-row">
      <AkCalendar
        v-model="reservationHote.dateStart"
        :label="this.$t('unavailability.dateStart')"
        :validator="v$.reservationHote.dateStart"
        :min-date="new Date()"
        class-name="col-md-6" />
      <AkCalendar
        v-model="reservationHote.dateEnd"
        :label="this.$t('unavailability.dateEnd')"
        :min-date="minDateEnd"
        :validator="v$.reservationHote.dateEnd"
        class-name="col-md-6" />
    </div>
    <div class="form-row">
      <AkTime
        v-model="reservationHote.hourCheckin"
        :label="this.$t('unavailability.hourCheckin')"
        :validator="v$.reservationHote.hourCheckin"
        class-name="col-md-6" />
      <AkTime
        v-model="reservationHote.hourCheckout"
        :label="this.$t('unavailability.hourCheckout')"
        :validator="v$.reservationHote.hourCheckout"
        class-name="col-md-6" />
    </div>
    <div class="form-row">
      <AkInputTextArea
        v-model="reservationHote.comment"
        :label="this.$t('unavailability.comment')"
        class-name="col-12" />
    </div>
  </AkDialog>
</template>
