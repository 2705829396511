<script>
import {Icon} from '@iconify/vue/dist/iconify';

/* COMPOSANTS */
import AkCalendar from '@components/input/AkCalendar';
import AkAccommodationAutocomplete from '@components/general/AkAccommodationAutocomplete';
import MultiSelect from 'primevue/multiselect';
import AkDropdown from '@components/input/AkDropdown';

/* MIXINS */
import sidebarMixin from '@mixins/sidebarMixin';
import utilsMixin from '@mixins/utilsMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import reservationTypesConst from '@mixins/const/reservationTypesConst';

/* SERVICES */
import companyService from '@services/companyService';

export default {
  components: {
    AkCalendar,
    MultiSelect,
    AkAccommodationAutocomplete,
    AkDropdown,
    Icon,
  },
  mixins: [permissionsMixin, sidebarMixin, utilsMixin, reservationTypesConst],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      companies: undefined,
      companyId: undefined,
    };
  },
  mounted() {
    if (this.isGav()) this.companyId = this.myCompanyId();
    this.initCompanies();
  },
  methods: {
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.accommodationAutocomplete) this.$refs.accommodationAutocomplete.reset();
      this.callback[1]();
    },
    initCompanies() {
      companyService
        .companies()
        .then((data) => {
          this.companies = data;
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        });
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    loadingCompanies() {
      return this.companies === undefined;
    },
  },
};
</script>

<template>
  <h2>Filtrer</h2>
  <form class="d-flex flex-column" style="row-gap: 16px" @submit.prevent="search">
    <MultiSelect
      v-if="!this.isGav()"
      v-model="currentValue.companyIds"
      :placeholder="$t('employee.company')"
      :options="this.companies"
      :loading="this.loadingCompanies"
      option-label="name"
      option-value="id"
      class="flex-1 form-control"
      :display-label="false" />

    <AkAccommodationAutocomplete
      ref="accommodationAutocomplete"
      v-model="currentValue.accommodationId"
      :placeholder="$t('accommodation_title')"
      class-name="flex-1 form-control"
      inline />

    <AkDropdown
      v-model="currentValue.reservationType"
      :label="$t('reservation.type')"
      :options="this.reservationTypesConstForMissionDashboard"
      class-name="flex-1 form-control"
      option-label="label"
      option-value="value"
      inline />

    <AkCalendar
      v-model="currentValue.dateStart"
      :label="$t('date_start')"
      class-name="form-control flex-1"
      date-format="dd/mm/yy"
      :inline="true"
      selection-mode="single" />

    <AkCalendar
      v-model="currentValue.dateEnd"
      :label="$t('date_end')"
      class-name="form-control flex-1"
      date-format="dd/mm/yy"
      :inline="true"
      selection-mode="single" />

    <!-- Recherche -->
    <div class="d-flex flex-row">
      <button class="btn btn-primary btn-search flex-1" type="submit">
        {{ this.$t('visitor_tax.filter') }}
      </button>
      <Icon
        :style="{fontSize: '50px', width: '50px !important'}"
        class="pointer"
        icon="basil:cross-outline"
        v-on:click="this.reset()" />
    </div>
  </form>
</template>
