export default {
  data() {
    return {
      reservationStateReversedConst: [
        {label: this.$t('reservation.state_reversed'), value: true},
        {label: this.$t('reservation.state_not_reversed'), value: false},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForReservationStateReversedConst(value) {
      let result = this.reservationStateReversedConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
