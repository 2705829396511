<script>
/* COMPOSANTS */
import AkCheckbox from '@components/input/AkCheckbox.vue';

/* MIXINS */

export default {
  components: {AkCheckbox},
  props: {
    modelValue: {
      required: true,
    },
    label: {
      type: String,
      required: true,
      default: '',
    },
    checked: {
      type: Boolean,
      required: false,
      default: false,
    },
    missionType: {
      type: String,
      required: true,
      default: '',
    },
  },
  mixins: [],
  emits: ['update:modelValue', 'checked-upsell'],
  validations() {
    return {};
  },
  mounted() {},
  setup() {
    return {};
  },
  data() {
    return {
      value: this.checked,
    };
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  watch: {
    checked(newVal, oldVal) {
      this.value = newVal;
    },
    value(newVal, oldVal) {
      // N'émettre l'évenement que si la case est cochée par l'utilisateur
      if (newVal) {
        this.currentValue = this.missionType;
        this.$emit('checked-upsell', this.currentValue);
      }
    },
  },
  methods: {},
};
</script>

<template>
  <AkCheckbox v-model="value" :label="this.label" :checked="this.checked" className="col-6" />
</template>
