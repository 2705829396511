/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import {PermissionMap} from '@mixins/const/permissionConst';

export default {
  mixins: [permissionsMixin],
  data() {
    return {
      tabs: [
        {
          name: this.$t('visitor_tax.tds'),
          id: 'stayRateConsultation',
          icon: 'list',
          active: true,
          hidden: !this.hasPermission(PermissionMap.TDS.VIEW),
        },
        {
          name: this.$t('visitor_tax.transferts'),
          id: 'stayRateTransfert',
          icon: 'dollar-sign',
          hidden: !this.hasPermission(PermissionMap.TDS.VIEW),
        },
      ],
    };
  },
  methods: {},
};
