<script>
import useVuelidate from '@vuelidate/core';

/* COMPOSANTS */
import AkFormSubmitted from '@components/general/AkFormSubmitted';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import societeService from '@services/societeService';
import GaView from '@components/layout/GaView';
import AkInputText from '@components/input/AkInputText';

export default {
  components: {AkInputText, GaView, AkFormSubmitted},
  mixins: [randomRef, utilsMixin],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'settings.societe_guestadom',
    };
  },
  data() {
    return {
      loading: true,
      submitted: false,
      societe: {},
    };
  },
  validations() {
    return {};
  },
  mounted() {
    societeService.societeGuestadom().then((data) => {
      this.societe = data;
      this.loading = false;
    });
  },
  methods: {
    update() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$error) return;

      this.getRef().showTotalLoader();
      societeService
        .update(this.societe)
        .then((data) => {
          this.societe = data;
          this.getRef().success(this.$t('updated'));
        })
        .finally(() => {
          this.getRef().hideLoader();
          this.submitted = false;
        });
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :title="$t('settings.societe_guestadom')">
    <template #content>
      <AkFormSubmitted :submitted="submitted" reference="form" @submit="update()">
        <div class="row">
          <AkInputText v-model="societe.formeJuridiqueDeLaSociete" :label="$t('societe.formeJuridiqueDeLaSociete')" />
          <AkInputText v-model="societe.raisonSocialeDeLaSociete" :label="$t('societe.raisonSocialeDeLaSociete')" />
          <AkInputText v-model="societe.capital" :label="$t('societe.capital')" />
          <AkInputText v-model="societe.adresseDuSiegeSocial" :label="$t('societe.adresseDuSiegeSocial')" />
          <AkInputText
            v-model="societe.complementDAdresseSiegeSocial"
            :label="$t('societe.complementDAdresseSiegeSocial')" />
          <AkInputText v-model="societe.cpDuSiegeSocial" :label="$t('societe.cpDuSiegeSocial')" />
          <AkInputText v-model="societe.villeDuSiegeSocial" :label="$t('societe.villeDuSiegeSocial')" />
          <AkInputText v-model="societe.lieuDuRcs" :label="$t('societe.lieuDuRcs')" />
          <AkInputText v-model="societe.numeroDuRcs" :label="$t('societe.numeroDuRcs')" />
          <AkInputText v-model="societe.nomDuGerant" :label="$t('societe.nomDuGerant')" />
          <AkInputText v-model="societe.prenomDuGerant" :label="$t('societe.prenomDuGerant')" />
          <AkInputText
            v-model="societe.fonctionDuRepresentantDeLaSociete"
            :label="$t('societe.fonctionDuRepresentantDeLaSociete')" />
          <AkInputText
            v-model="societe.numeroCarteProfessionnelImmobiliere"
            :label="$t('societe.numeroCarteProfessionnelImmobiliere')" />
          <AkInputText
            v-model="societe.dateDeDelivranceCarteProfessionnelImmobiliere"
            :label="$t('societe.dateDeDelivranceCarteProfessionnelImmobiliere')" />
          <AkInputText
            v-model="societe.dateDeValiditeCarteProfessionnelImmobiliere"
            :label="$t('societe.dateDeValiditeCarteProfessionnelImmobiliere')" />
          <AkInputText
            v-model="societe.organismeDelivranceCarteProfessionnelImmobiliere"
            :label="$t('societe.organismeDelivranceCarteProfessionnelImmobiliere')" />
          <AkInputText v-model="societe.nomCaisseDeGarantie" :label="$t('societe.nomCaisseDeGarantie')" />
          <AkInputText v-model="societe.adresseCaisseDeGarantie" :label="$t('societe.adresseCaisseDeGarantie')" />
          <AkInputText
            v-model="societe.complementDAdresseCaisseDeGarantie"
            :label="$t('societe.complementDAdresseCaisseDeGarantie')" />
          <AkInputText v-model="societe.cpCaisseDeGarantie" :label="$t('societe.cpCaisseDeGarantie')" />
          <AkInputText v-model="societe.villeCaisseDeGarantie" :label="$t('societe.villeCaisseDeGarantie')" />
          <AkInputText v-model="societe.montantCaisseDeGarantie" :label="$t('societe.montantCaisseDeGarantie')" />
          <AkInputText
            v-model="societe.zoneGeographiqueCouvertureCaisseDeGarantie"
            :label="$t('societe.zoneGeographiqueCouvertureCaisseDeGarantie')" />
          <AkInputText v-model="societe.nomAssuranceRc" :label="$t('societe.nomAssuranceRc')" />
          <AkInputText v-model="societe.adresseAssuranceRc" :label="$t('societe.adresseAssuranceRc')" />
          <AkInputText
            v-model="societe.complementDAdresseAssuranceRc"
            :label="$t('societe.complementDAdresseAssuranceRc')" />
          <AkInputText v-model="societe.cpAssuranceRc" :label="$t('societe.cpAssuranceRc')" />
          <AkInputText v-model="societe.villeAssuranceRc" :label="$t('societe.villeAssuranceRc')" />
          <AkInputText v-model="societe.numeroDePoliceAssuranceRc" :label="$t('societe.numeroDePoliceAssuranceRc')" />
          <AkInputText
            v-model="societe.zoneDeGeographieCouvertureAssuranceRc"
            :label="$t('societe.zoneDeGeographieCouvertureAssuranceRc')" />
          <AkInputText
            v-model="societe.numeroTvaIntracommunautaire"
            :label="$t('societe.numeroTvaIntracommunautaire')" />
          <AkInputText v-model="societe.civiliteResponsableRgpd" :label="$t('societe.civiliteResponsableRgpd')" />
          <AkInputText v-model="societe.prenomResponsableRgpd" :label="$t('societe.prenomResponsableRgpd')" />
          <AkInputText v-model="societe.nomResponsableRgpd" :label="$t('societe.nomResponsableRgpd')" />
          <AkInputText v-model="societe.siret" :label="$t('societe.siret')" />
          <AkInputText v-model="societe.siren" :label="$t('societe.siren')" />
          <AkInputText v-model="societe.ape" :label="$t('societe.ape')" />

          <AkInputText v-model="societe.numeroGarantie" :label="$t('societe.numero_garantie')" />
          <AkInputText v-model="societe.numeroCompteSequestre" :label="$t('societe.numero_compte_sequestre')" />
          <AkInputText v-model="societe.nomBanqueCompteSequestre" :label="$t('societe.nom_banque_compte_sequestre')" />
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-content-end">
            <button class="btn btn-primary float-right">
              {{ $t('update') }}
            </button>
          </div>
        </div>
      </AkFormSubmitted>
    </template>
  </GaView>
</template>
