import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  itemLabels(filter) {
    return Api(true, false)
      .get('/itemLabels', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  itemLabel(itemLabelId) {
    return Api()
      .get('/itemLabel/' + itemLabelId)
      .then((res) => this.formatRes(res.data));
  },
  create(itemLabel) {
    return Api()
      .post('/itemLabel', itemLabel)
      .then((res) => this.formatRes(res.data));
  },
  update(itemLabel) {
    return Api()
      .put('/itemLabel/' + itemLabel.id, itemLabel)
      .then((res) => this.formatRes(res.data));
  },
  delete(itemLabel) {
    return Api()
      .delete('/itemLabel/' + itemLabel.id)
      .then(() => itemLabel);
  },
  increasePosition(itemLabel) {
    return Api()
      .put('/itemLabel/increase/' + itemLabel.id)
      .then(() => itemLabel);
  },
  decreasePosition(itemLabel) {
    return Api()
      .put('/itemLabel/decrease/' + itemLabel.id)
      .then(() => itemLabel);
  },
  formatRes(e) {
    e.disabled = !e.enabled;
    return e;
  },
};
