<script>
import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

/* COMPOSANTS */
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import AkInputText from '@components/input/AkInputText';
import AkDropdown from '@components/input/AkDropdown';
import AkFileUpload from '@components/input/AkFileUpload';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import documentModelTypeConst from '@mixins/const/documentModelTypeConst';

/* SERVICES */
import documentModelService from '@services/documentModelService';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';
import GaView from '@components/layout/GaView';
import backMixin from '@mixins/backMixin';

export default {
  components: {GaView, AkDropdown, AkFormSubmitted, AkInputText, AkFileUpload},
  mixins: [randomRef, loaderMixin, backMixin, messageMixin, documentModelTypeConst],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'document_model.add',
    };
  },
  data() {
    return {
      submitted: false,
      file: undefined,
      documentModel: {},
    };
  },
  validations() {
    return {
      documentModel: {
        type: {required},
        name: {required},
        subType: {required},
      },
    };
  },
  computed: {
    getSubType() {
      if (this.documentModel.type) return this.childrenForDocumentModelType(this.documentModel.type);
      return [];
    },
  },
  watch: {
    'documentModel.type': function () {
      this.documentModel.subType = undefined;
    },
  },
  mounted() {},
  methods: {
    create() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$error) return;
      if (this.file == undefined) {
        this.addError('Le fichier word est obligatoire');
        return;
      }

      this.showLoader(this.$refs.form);
      documentModelService
        .create(this.documentModel, this.file)
        .then(() => {
          this.hideLoader();
          this.success(this.$t('document_model.added'));
          this.submitted = false;
          this.file = undefined;
          this.documentModel = {};
        })
        .catch((e) => {
          this.hideLoader();
          this.addError(this.$t('error.' + e.response.data.error));
          this.submitted = false;
        });
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :title="$t('document_model.add')">
    <template #back>
      <a class="mb-1 d-block pointer" @click="goBack()"><i class="fe fe-arrow-left pr-1" />{{ $t('back') }}</a>
    </template>
    <template #content>
      <AkFormSubmitted :submitted="submitted" reference="form" @submit="create()">
        <div class="form-row">
          <AkInputText
            v-model="documentModel.name"
            :label="$t('document_model.name')"
            :validator="v$.documentModel.name"
            class-name="col-md-4" />
          <AkDropdown
            v-model="documentModel.type"
            :label="$t('document_model.type')"
            :options="documentModelTypeConst"
            :validator="v$.documentModel.type" />
          <AkDropdown
            v-if="documentModel.type"
            v-model="documentModel.subType"
            :label="$t('document_model.subtype')"
            :options="getSubType"
            :validator="v$.documentModel.subType" />
        </div>
        <div class="form-row">
          <AkFileUpload
            v-model="file"
            :label="$t('document_model.file')"
            accept=".docx,application/pdf"
            class="col-md-4" />
        </div>

        <button class="btn btn-primary float-right">
          {{ $t('add') }}
        </button>
      </AkFormSubmitted>
    </template>
  </GaView>
</template>
