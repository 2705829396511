export default {
  data() {
    return {
      accommodationContactConst: [
        {label: this.$t('accommodationContact.syndic'), value: 'SYNDIC'},
        {label: this.$t('accommodationContact.gardien'), value: 'GARDIEN'},
        {label: this.$t('accommodationContact.voisin'), value: 'VOISIN'},
        {label: this.$t('accommodationContact.urgence'), value: 'URGENCE'},
        {label: this.$t('accommodationContact.plombier'), value: 'PLOMBIER'},
        {label: this.$t('accommodationContact.electricien'), value: 'ELECTRICIEN'},
        {label: this.$t('accommodationContact.chauffagiste'), value: 'CHAUFFAGISTE'},
        {label: this.$t('accommodationContact.pisciniste'), value: 'PISCINISTE'},
        {label: this.$t('accommodationContact.serrurier'), value: 'SERRURIER'},
        {label: this.$t('accommodationContact.jardinier'), value: 'JARDINIER'},
        {label: this.$t('accommodationContact.other'), value: 'AUTRE'},

        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForAccommodationContactConst(value) {
      let result = this.accommodationContactConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
