<script>
import useVuelidate from '@vuelidate/core';

/* COMPOSANTS */

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import StayRateConsultation from '@views/accounting/stayrates/tabs/StayRateConsultation.vue';
import StayRateTransfert from '@views/accounting/stayrates/tabs/StayRateTransfert.vue';
import stayRatesTabMixin from '@mixins/tab/stayRatesTabMixin';
import sidebarMixin from '@mixins/sidebarMixin';

export default {
  components: {
    StayRateTransfert,
    StayRateConsultation,
  },
  mixins: [utilsMixin, loaderMixin, messageMixin, stayRatesTabMixin, sidebarMixin],
  metaInfo() {
    return {
      title: 'accounting.visitor_tax',
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      activeTab: undefined,
    };
  },
  mounted() {
    if (this.$route.hash) {
      this.activeTab = this.$route.hash.slice(1);
    }
  },
  computed: {},
  methods: {},
};
</script>
<template>
  <GaTabView :firstTab="this.activeTab" :title="$t('visitor_tax.tds')" :tabs="tabs">
    <template #stayRateConsultation>
      <StayRateConsultation />
    </template>
    <template #stayRateTransfert>
      <StayRateTransfert />
    </template>
  </GaTabView>
</template>
