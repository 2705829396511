<script>
/* COMPOSANTS */
import GaContentView from '@components/layout/GaContentView';
import AkDialog from '@components/general/AkDialog';

/* MIXINS */
import absenceConst from '@mixins/const/absenceConst';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import quantityConst from '@mixins/const/quantityConst';
import randomRef from '@mixins/randomRef';
import receptionTypeConst from '@mixins/const/receptionTypeConst';
import residenceTypeConst from '@mixins/const/residenceTypeConst';
import utilsMixin from '@mixins/utilsMixin';
import yesNoConst from '@mixins/const/yesNoConst';

/* SERVICES */
import absenceService from '@services/absenceService';

export default {
  components: {
    GaContentView,
    AkDialog,
  },
  mixins: [
    absenceConst,
    loaderMixin,
    messageMixin,
    permissionsMixin,
    quantityConst,
    randomRef,
    receptionTypeConst,
    residenceTypeConst,
    utilsMixin,
    yesNoConst,
  ],
  props: {
    modelValue: {required: true},
  },
  data() {
    return {
      absence: {},
      absences: [],
      absenceId: undefined,
      loading: true,
      item: {},
    };
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    employeeId() {
      return this.currentValue.id;
    },
  },
  created() {},
  mounted() {
    this.getRef().showLoader(this.$refs.form);

    let p1 = absenceService.absencesByEmployeeId(this.employeeId);
    p1.then((data) => {
      this.absences = data;
    });

    Promise.all([p1]).then(() => {
      this.getRef().hideLoader();
      this.loading = false;
    });
  },
  methods: {
    openDeleteDialog(item) {
      this.item = item;
      this.$refs.dialogDelete.show();
    },
    delete() {
      absenceService
        .delete(this.item)
        .then((data) => {
          this.removeInListById(this.absences, data.id);
          this.getRef().success(this.$t('absence.deleted'));
        })
        .catch((e) => {
          let content = 'Une erreur est survenue durant la suppression';
          if (e.response) content = 'Une erreur est survenue durant la suppression : ' + e.response.data.error;
          this.getRef().error(content);
        });
    },
    rowClick(event) {
      let data = event.data;
      this.$router.push({name: 'absenceDetails', params: {employeeId: this.employeeId, absenceId: data.id}});
    },
  },
};
</script>

<template>
  <GaContentView :ref="ref" :title="$t('absences')">
    <template #action>
      <router-link
        :to="{name: 'absenceCreate', params: {employeeId: this.employeeId}}"
        class="btn btn-inverse-primary btn-xs">
        <i class="gst-plus pr-1" />{{ $t('absence.add') }}
      </router-link>
    </template>

    <template #content>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive">
                <DataTable
                  :always-show-paginator="false"
                  :loading="loading"
                  :paginator="true"
                  :rows="10"
                  :rows-per-page-options="[10, 20, 50]"
                  :value="absences"
                  class="table pointer"
                  current-page-report-template="{first}-{last}/{totalRecords}"
                  removable-sort
                  responsive-layout="scroll"
                  @row-click="rowClick($event)"
                  striped-rows>
                  <template #empty>
                    {{ $t('absence.empty') }}
                  </template>

                  <Column :header="$t('absence.reason')" :sortable="true" field="reason">
                    <template #body="slotProps">
                      {{ displayForAbsenceReasonConst(slotProps.data.reason) }}
                    </template>
                  </Column>
                  <Column :header="$t('absence.startDate')" :sortable="true" field="startDate">
                    <template #body="slotProps">
                      <span v-if="slotProps.data.startDate">
                        {{ displayDate(displayUTCDate(slotProps.data.startDate)) }}
                      </span>
                    </template>
                  </Column>
                  <Column :header="$t('absence.endDate')" :sortable="true" field="endDate">
                    <template #body="slotProps">
                      <span v-if="slotProps.data.endDate">
                        {{ displayDate(displayUTCDate(slotProps.data.endDate)) }}
                      </span>
                    </template>
                  </Column>
                  <Column :header="$t('absence.comment')" :sortable="true" field="comment">
                    <template #body="slotProps">
                      <span v-if="slotProps.data.comment">
                        {{ slotProps.data.comment }}
                      </span>
                    </template>
                  </Column>
                  <Column :header="$t('filesNbr')" :sortable="true">
                    <template #body="slotProps">
                      <span v-if="slotProps.data.ficList">
                        {{ slotProps.data.ficList.length }}
                      </span>
                    </template>
                  </Column>
                  <Column
                    body-style="text-align: center; overflow: visible"
                    header-style=" width: 200px; text-align: center">
                    <template #body="slotProps">
                      <div class="d-flex justify-content-end align-items-center">
                        <router-link
                          :to="{
                            name: 'absenceDetails',
                            params: {employeeId: this.employeeId, absenceId: slotProps.data.id},
                          }"
                          class="btn btn-xs btn-inverse-primary mr-1">
                          <i class="fe fe-eye" />
                        </router-link>
                        <router-link
                          :to="{
                            name: 'absenceUpdate',
                            params: {employeeId: this.employeeId, absenceId: slotProps.data.id},
                          }"
                          class="btn btn-xs btn-inverse-primary mr-1">
                          <i class="fe fe-edit" />
                        </router-link>
                        <span class="btn btn-xs btn-inverse-danger" @click.stop="openDeleteDialog(slotProps.data)">
                          <i class="fe fe-trash" />
                        </span>
                      </div>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #extra>
      <AkDialog
        ref="dialogDelete"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.delete()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span>{{ $t('absence.confirm_delete') }}</span>
        </div>
      </AkDialog>
    </template>
  </GaContentView>
</template>
