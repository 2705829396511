<script>
/* COMPOSANTS */
import AkDropdown from '@components/input/AkDropdown';
import InputText from 'primevue/inputtext';
import AkCalendar from '@components/input/AkCalendar.vue';
import AkFilesUpload from '@components/input/AkFilesUpload';
import AkFicList from '@components/layout/AkFicList.vue';

import prestationTypesConst from '@mixins/const/prestationTypesConst';
import permissionsMixin from '@mixins/permissionsMixin';

/* SERVICES */
import companyService from '@services/companyService';

export default {
  components: {
    AkDropdown,
    InputText,
    AkCalendar,
    AkFilesUpload,
    AkFicList,
  },
  mixins: [permissionsMixin, prestationTypesConst],
  props: {
    modelValue: {
      required: true,
    },
    editable: {
      required: true,
    },
  },
  data() {
    return {
      prestataire: {},
      companies: [],
    };
  },
  mounted() {
    let p1 = companyService.companies();
    p1.then((data) => {
      this.companies = data;
    });
  },
  methods: {},
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
    },
    editPossible: {
      get: function () {
        return this.editable;
      },
    },
  },
};
</script>

<template>
  <div class="prestataire-details">
    <div class="section">
      <span class="title">Information</span>
      <div>
        <div class="input-item">
          <span>Civilité</span>
          <div>
            <input
              v-if="currentValue.contact.civility === 'M' || editPossible"
              type="radio"
              :checked="currentValue.contact.civility === 'M'"
              @click="($event) => (currentValue.contact.civility = 'M')" />
            <label v-if="currentValue.contact.civility === 'M' || editPossible">Mr</label>
            <input
              v-if="currentValue.contact.civility === 'Mme' || editPossible"
              type="radio"
              :checked="currentValue.contact.civility === 'Mme'"
              @click="($event) => (currentValue.contact.civility = 'Mme')" />
            <label v-if="currentValue.contact.civility === 'Mme' || editPossible">Mme</label>
            <span v-if="!currentValue.contact.civility && !editPossible">Non renseignée</span>
          </div>
        </div>
        <div class="input-item">
          <span>Nom *</span>
          <InputText v-model="currentValue.contact.lastName" :disabled="!editPossible" />
        </div>
        <div class="input-item">
          <span>Prénom *</span>
          <InputText v-model="currentValue.contact.firstName" :disabled="!editPossible" />
        </div>
        <div class="input-item">
          <span>Date de naissance</span>
          <AkCalendar
            class="border-grey"
            :class-name="!editPossible ? 'disabled' : ''"
            :displayLabel="false"
            :inline="true"
            v-model="currentValue.contact.birthDate"
            :date-format="'dd/mm/yy'"
            :maxDate="new Date()"
            :disabled="!editPossible" />
        </div>
      </div>
    </div>
    <div class="section">
      <span class="title">Coordonnées</span>
      <div>
        <div class="input-item">
          <span>Email *</span>
          <InputText v-model="currentValue.contact.email" disabled />
        </div>
        <div class="input-item">
          <span>Portable *</span>
          <InputText v-model="currentValue.contact.phone" :disabled="!editPossible" />
        </div>
        <div class="input-item">
          <span>Fixe</span>
          <InputText v-model="currentValue.contact.homePhone" :disabled="!editPossible" />
        </div>
      </div>
    </div>
    <div class="section" v-if="isGas()">
      <span class="title">Informations complémentaires</span>
      <div>
        <div class="input-item">
          <span>Conciergerie locale *</span>
          <AkDropdown
            :label="''"
            :disabled="!editPossible"
            :class-name="!editPossible ? 'disabled col-md-4 col-6' : 'col-md-4 col-6'"
            :inline="true"
            class="border-grey"
            v-model="currentValue.companyId"
            :options="companies"
            option-label="name"
            option-value="id" />
        </div>
      </div>
    </div>
    <div class="section">
      <span class="title">Pièces jointes</span>
      <AkFicList
        v-if="currentValue.id"
        v-model="currentValue.files"
        :table-id="currentValue.id"
        accept=".png, .jpeg, .jpg"
        table-name="prestataire"
        :display-title="false"
        :fic-preview="true"
        :disabled="true"
        :canDelete="editPossible"
        :canUpload="false"
        :label="''" />
      <!-- UPDATE -->
      <AkFilesUpload
        v-if="editPossible"
        v-model="currentValue.newFiles"
        :label="'Ajouter des pièces-jointes'"
        :inline="true" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.prestataire-details {
  .section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    background-color: #ffffff;
    border-radius: 16px;
    margin-bottom: 24px;
    gap: 16px;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 40px;
    }
  }

  .input-item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 6px;

    > span {
      font-family: 'Nunito';
      font-weight: 600;
      font-size: 14px;
      color: #344054;
    }

    > div {
      display: flex;
      flex-direction: row;
      gap: 8px;

      > label {
        font-family: 'Nunito';
        font-weight: 600;
        font-size: 14px;
        color: #344054;
        margin-right: 20px;
      }
    }
  }

  .title {
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 16px;
    color: #344054;
  }

  .p-inputtext {
    width: auto !important;
    height: 40px;
  }

  .border-grey {
    border-color: #d0d5dd;
  }

  .disabled {
    background: #e9ecef;
  }

  .col-4 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .clickable {
    cursor: pointer;
  }
}
</style>
