<script>
import AkLabel from '@components/general/AkLabel';
import FileUpload from 'primevue/fileupload';
import envConst from '@mixins/const/envConst';

export default {
  components: {AkLabel, FileUpload},
  mixins: [envConst],
  inject: {pSubmitted: {default: null}, pDisabled: {default: null}},
  props: {
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      required: true,
    },
    className: {
      type: String,
      required: false,
      default: 'col-md-4 col-6',
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    accept: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    displayLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isInline() {
      return this.inline;
    },
    hasValidator() {
      return this.validator !== undefined;
    },
    isInvalid() {
      return this.hasValidator && this.validator.$invalid;
    },
    isRequired() {
      return (
        this.hasValidator &&
        this.validator.required !== undefined &&
        (this.validator.required.$params.type === 'required' ||
          (this.validator.required.$params.type === 'requiredIf' && this.validator.required.$params.prop === true))
      );
    },
    isRequiredFailed() {
      return this.isRequired && this.validator.required.$invalid && this.alreadySubmitted;
    },
  },
  methods: {
    selectFile(event) {
      this.currentValue = event.files[0];
    },
    clearFile() {
      this.currentValue = undefined;
    },
    clear() {
      this.$refs.uploader.clear();
    },
  },
};
</script>

<template>
  <div :class="[className, {'form-group': !isInline, 'form-control': isInline}]">
    <AkLabel v-if="label && !inline && displayLabel" :required="this.isRequired">
      {{ label }}
    </AkLabel>
    <FileUpload
      v-if="!currentValue"
      ref="uploader"
      :accept="accept"
      :auto="true"
      :choose-label="$t('add_file')"
      :custom-upload="true"
      :invalidFileSizeMessage="$t('file.err_size')"
      :maxFileSize="getMaxFileUpload()"
      :multiple="false"
      :show-upload-button="false"
      :class="[{'p-invalid': isInvalid && alreadySubmitted, 'form-control': !isInline}]"
      mode="basic"
      name="uploader"
      @select="selectFile" />
    <div v-if="currentValue" class="file-container">
      <span class="file-name">{{ currentValue.name }}</span>
      <i class="fa fa-times" @click="clearFile()" />
    </div>
    <div v-if="!isInline" class="d-flex flex-column flex-1">
      <small v-if="isRequiredFailed" class="p-error">
        {{ $t('field_required', [this.label]) }}
      </small>
    </div>
  </div>
</template>
