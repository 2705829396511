<script>
/* COMPOSANTS */
import {Icon} from '@iconify/vue/dist/iconify';
import AkDropdown from '@components/input/AkDropdown.vue';
import AkAccommodationAutocomplete from '@components/general/AkAccommodationAutocomplete.vue';
import AkInputText from '@components/input/AkInputText.vue';

/* MIXINS */
import sidebarMixin from '@/mixins/sidebarMixin';
import permissionsMixin from '@mixins/permissionsMixin';

/* SERVICES */
import companyService from '@services/companyService';

export default {
  components: {
    AkInputText,
    Icon,
    AkAccommodationAutocomplete,
    AkDropdown,
  },
  mixins: [sidebarMixin, permissionsMixin],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      companies: [],
    };
  },
  mounted() {
    this.initCompanies();
  },
  methods: {
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.companyAutocomplete) this.$refs.companyAutocomplete.reset();
      if (this.$refs.accommodationAutocomplete) this.$refs.accommodationAutocomplete.reset();
      this.callback[1]();
    },
    initCompanies() {
      companyService.companies().then((data) => {
        this.companies = data;
      });
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <h2>Filtrer</h2>
  <form class="d-flex flex-column" style="row-gap: 16px" @submit.prevent="search">
    <AkInputText
      v-model="currentValue.contactName"
      :placeholder="$t('partenaire.representantLastName')"
      class-name="flex-1 form-control"
      inline />
    <AkInputText
      v-model="currentValue.contactCompanyName"
      :placeholder="$t('contact.companyName')"
      class-name="flex-1 form-control"
      inline />
    <AkAccommodationAutocomplete
      ref="accommodationAutocomplete"
      v-model="currentValue.accommodationId"
      :placeholder="$t('partenaire.accommodation')"
      class-name="flex-1 form-control"
      inline />
    <AkDropdown
      v-model="currentValue.userEnabled"
      :label="$t('partenaire.enabledState')"
      :options="[
        {label: $t('enabled'), value: true},
        {label: $t('disabled'), value: false},
        {label: this.$t('undefined'), value: null},
      ]"
      class-name="flex-1 form-control"
      inline
      option-label="label"
      option-value="value" />
    <AkDropdown
      v-if="isGas() || this.isAdmin()"
      v-model="currentValue.companyId"
      :placeholder="$t('employee.company')"
      :label="$t('employee.company')"
      :options="this.companies"
      inline
      class-name="flex-1 form-control"
      option-label="name"
      option-value="id" />
    <div class="d-flex flex-row">
      <button class="btn btn-primary btn-search flex-1" type="submit">Rechercher</button>
      <Icon
        :style="{fontSize: '50px', width: '50px !important'}"
        class="pointer"
        icon="basil:cross-outline"
        v-on:click="this.reset()" />
    </div>
  </form>
</template>
