export default {
  data() {
    return {
      languages: [
        {label: this.$t('languages.ab'), value: 'ab'},
        {label: this.$t('languages.aa'), value: 'aa'},
        {label: this.$t('languages.af'), value: 'af'},
        {label: this.$t('languages.ak'), value: 'ak'},
        {label: this.$t('languages.sq'), value: 'sq'},
        {label: this.$t('languages.de'), value: 'de'},
        {label: this.$t('languages.am'), value: 'am'},
        {label: this.$t('languages.en'), value: 'en'},
        {label: this.$t('languages.ar'), value: 'ar'},
        {label: this.$t('languages.an'), value: 'an'},
        {label: this.$t('languages.hy'), value: 'hy'},
        {label: this.$t('languages.as'), value: 'as'},
        {label: this.$t('languages.av'), value: 'av'},
        {label: this.$t('languages.ae'), value: 'ae'},
        {label: this.$t('languages.ay'), value: 'ay'},
        {label: this.$t('languages.az'), value: 'az'},
        {label: this.$t('languages.ba'), value: 'ba'},
        {label: this.$t('languages.bm'), value: 'bm'},
        {label: this.$t('languages.eu'), value: 'eu'},
        {label: this.$t('languages.bn'), value: 'bn'},
        {label: this.$t('languages.bi'), value: 'bi'},
        {label: this.$t('languages.bh'), value: 'bh'},
        {label: this.$t('languages.my'), value: 'my'},
        {label: this.$t('languages.be'), value: 'be'},
        {label: this.$t('languages.bs'), value: 'bs'},
        {label: this.$t('languages.br'), value: 'br'},
        {label: this.$t('languages.bg'), value: 'bg'},
        {label: this.$t('languages.ks'), value: 'ks'},
        {label: this.$t('languages.ca'), value: 'ca'},
        {label: this.$t('languages.ch'), value: 'ch'},
        {label: this.$t('languages.ny'), value: 'ny'},
        {label: this.$t('languages.zh'), value: 'zh'},
        {label: this.$t('languages.si'), value: 'si'},
        {label: this.$t('languages.kw'), value: 'kw'},
        {label: this.$t('languages.co'), value: 'co'},
        {label: this.$t('languages.ko'), value: 'ko'},
        {label: this.$t('languages.cr'), value: 'cr'},
        {label: this.$t('languages.hr'), value: 'hr'},
        {label: this.$t('languages.ht'), value: 'ht'},
        {label: this.$t('languages.da'), value: 'da'},
        {label: this.$t('languages.dz'), value: 'dz'},
        {label: this.$t('languages.es'), value: 'es'},
        {label: this.$t('languages.eo'), value: 'eo'},
        {label: this.$t('languages.et'), value: 'et'},
        {label: this.$t('languages.fj'), value: 'fj'},
        {label: this.$t('languages.fi'), value: 'fi'},
        {label: this.$t('languages.fr'), value: 'fr'},
        {label: this.$t('languages.fy'), value: 'fy'},
        {label: this.$t('languages.fo'), value: 'fo'},
        {label: this.$t('languages.gl'), value: 'gl'},
        {label: this.$t('languages.cy'), value: 'cy'},
        {label: this.$t('languages.lg'), value: 'lg'},
        {label: this.$t('languages.gd'), value: 'gd'},
        {label: this.$t('languages.gu'), value: 'gu'},
        {label: this.$t('languages.el'), value: 'el'},
        {label: this.$t('languages.kl'), value: 'kl'},
        {label: this.$t('languages.gn'), value: 'gn'},
        {label: this.$t('languages.ka'), value: 'ka'},
        {label: this.$t('languages.ha'), value: 'ha'},
        {label: this.$t('languages.hi'), value: 'hi'},
        {label: this.$t('languages.ho'), value: 'ho'},
        {label: this.$t('languages.hu'), value: 'hu'},
        {label: this.$t('languages.iw'), value: 'iw'},
        {label: this.$t('languages.he'), value: 'he'},
        {label: this.$t('languages.hz'), value: 'hz'},
        {label: this.$t('languages.io'), value: 'io'},
        {label: this.$t('languages.ig'), value: 'ig'},
        {label: this.$t('languages.id'), value: 'id'},
        {label: this.$t('languages.in'), value: 'in'},
        {label: this.$t('languages.ia'), value: 'ia'},
        {label: this.$t('languages.ie'), value: 'ie'},
        {label: this.$t('languages.iu'), value: 'iu'},
        {label: this.$t('languages.ik'), value: 'ik'},
        {label: this.$t('languages.ga'), value: 'ga'},
        {label: this.$t('languages.is'), value: 'is'},
        {label: this.$t('languages.it'), value: 'it'},
        {label: this.$t('languages.ja'), value: 'ja'},
        {label: this.$t('languages.jv'), value: 'jv'},
        {label: this.$t('languages.kn'), value: 'kn'},
        {label: this.$t('languages.kr'), value: 'kr'},
        {label: this.$t('languages.kk'), value: 'kk'},
        {label: this.$t('languages.km'), value: 'km'},
        {label: this.$t('languages.kg'), value: 'kg'},
        {label: this.$t('languages.ki'), value: 'ki'},
        {label: this.$t('languages.rw'), value: 'rw'},
        {label: this.$t('languages.ky'), value: 'ky'},
        {label: this.$t('languages.kv'), value: 'kv'},
        {label: this.$t('languages.kj'), value: 'kj'},
        {label: this.$t('languages.ku'), value: 'ku'},
        {label: this.$t('languages.lo'), value: 'lo'},
        {label: this.$t('languages.la'), value: 'la'},
        {label: this.$t('languages.lv'), value: 'lv'},
        {label: this.$t('languages.li'), value: 'li'},
        {label: this.$t('languages.ln'), value: 'ln'},
        {label: this.$t('languages.lt'), value: 'lt'},
        {label: this.$t('languages.lu'), value: 'lu'},
        {label: this.$t('languages.lb'), value: 'lb'},
        {label: this.$t('languages.mk'), value: 'mk'},
        {label: this.$t('languages.ms'), value: 'ms'},
        {label: this.$t('languages.ml'), value: 'ml'},
        {label: this.$t('languages.dv'), value: 'dv'},
        {label: this.$t('languages.mg'), value: 'mg'},
        {label: this.$t('languages.mt'), value: 'mt'},
        {label: this.$t('languages.gv'), value: 'gv'},
        {label: this.$t('languages.mi'), value: 'mi'},
        {label: this.$t('languages.mr'), value: 'mr'},
        {label: this.$t('languages.mh'), value: 'mh'},
        {label: this.$t('languages.mo'), value: 'mo'},
        {label: this.$t('languages.mn'), value: 'mn'},
        {label: this.$t('languages.na'), value: 'na'},
        {label: this.$t('languages.nv'), value: 'nv'},
        {label: this.$t('languages.ng'), value: 'ng'},
        {label: this.$t('languages.nd'), value: 'nd'},
        {label: this.$t('languages.nr'), value: 'nr'},
        {label: this.$t('languages.no'), value: 'no'},
        {label: this.$t('languages.nb'), value: 'nb'},
        {label: this.$t('languages.nn'), value: 'nn'},
        {label: this.$t('languages.nl'), value: 'nl'},
        {label: this.$t('languages.ne'), value: 'ne'},
        {label: this.$t('languages.oc'), value: 'oc'},
        {label: this.$t('languages.or'), value: 'or'},
        {label: this.$t('languages.oj'), value: 'oj'},
        {label: this.$t('languages.om'), value: 'om'},
        {label: this.$t('languages.os'), value: 'os'},
        {label: this.$t('languages.ur'), value: 'ur'},
        {label: this.$t('languages.uz'), value: 'uz'},
        {label: this.$t('languages.ug'), value: 'ug'},
        {label: this.$t('languages.ps'), value: 'ps'},
        {label: this.$t('languages.pi'), value: 'pi'},
        {label: this.$t('languages.pa'), value: 'pa'},
        {label: this.$t('languages.fa'), value: 'fa'},
        {label: this.$t('languages.ff'), value: 'ff'},
        {label: this.$t('languages.pl'), value: 'pl'},
        {label: this.$t('languages.pt'), value: 'pt'},
        {label: this.$t('languages.qu'), value: 'qu'},
        {label: this.$t('languages.rm'), value: 'rm'},
        {label: this.$t('languages.ro'), value: 'ro'},
        {label: this.$t('languages.rn'), value: 'rn'},
        {label: this.$t('languages.ru'), value: 'ru'},
        {label: this.$t('languages.se'), value: 'se'},
        {label: this.$t('languages.sm'), value: 'sm'},
        {label: this.$t('languages.sg'), value: 'sg'},
        {label: this.$t('languages.sa'), value: 'sa'},
        {label: this.$t('languages.sc'), value: 'sc'},
        {label: this.$t('languages.sr'), value: 'sr'},
        {label: this.$t('languages.sn'), value: 'sn'},
        {label: this.$t('languages.sd'), value: 'sd'},
        {label: this.$t('languages.cu'), value: 'cu'},
        {label: this.$t('languages.sk'), value: 'sk'},
        {label: this.$t('languages.sl'), value: 'sl'},
        {label: this.$t('languages.so'), value: 'so'},
        {label: this.$t('languages.st'), value: 'st'},
        {label: this.$t('languages.su'), value: 'su'},
        {label: this.$t('languages.sv'), value: 'sv'},
        {label: this.$t('languages.sw'), value: 'sw'},
        {label: this.$t('languages.ss'), value: 'ss'},
        {label: this.$t('languages.tg'), value: 'tg'},
        {label: this.$t('languages.tl'), value: 'tl'},
        {label: this.$t('languages.ty'), value: 'ty'},
        {label: this.$t('languages.ta'), value: 'ta'},
        {label: this.$t('languages.tt'), value: 'tt'},
        {label: this.$t('languages.cv'), value: 'cv'},
        {label: this.$t('languages.cs'), value: 'cs'},
        {label: this.$t('languages.ce'), value: 'ce'},
        {label: this.$t('languages.th'), value: 'th'},
        {label: this.$t('languages.bo'), value: 'bo'},
        {label: this.$t('languages.ti'), value: 'ti'},
        {label: this.$t('languages.to'), value: 'to'},
        {label: this.$t('languages.ts'), value: 'ts'},
        {label: this.$t('languages.tn'), value: 'tn'},
        {label: this.$t('languages.tr'), value: 'tr'},
        {label: this.$t('languages.tk'), value: 'tk'},
        {label: this.$t('languages.tw'), value: 'tw'},
        {label: this.$t('languages.te'), value: 'te'},
        {label: this.$t('languages.uk'), value: 'uk'},
        {label: this.$t('languages.ve'), value: 've'},
        {label: this.$t('languages.vi'), value: 'vi'},
        {label: this.$t('languages.vo'), value: 'vo'},
        {label: this.$t('languages.wa'), value: 'wa'},
        {label: this.$t('languages.wo'), value: 'wo'},
        {label: this.$t('languages.xh'), value: 'xh'},
        {label: this.$t('languages.ii'), value: 'ii'},
        {label: this.$t('languages.yi'), value: 'yi'},
        {label: this.$t('languages.ji'), value: 'ji'},
        {label: this.$t('languages.yo'), value: 'yo'},
        {label: this.$t('languages.za'), value: 'za'},
        {label: this.$t('languages.zu'), value: 'zu'},
        {label: this.$t('languages.ee'), value: 'ee'},
      ],
    };
  },
};
