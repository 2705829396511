export default {
  data() {
    return {
      reservationSiteConst: [
        {label: 'Airbnb', value: 'AIRBNB'},
        {label: 'Booking', value: 'BOOKING'},
        {label: 'Abritel', value: 'ABRITEL'},
        {label: 'VGA', value: 'VGA'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForReservationSiteConst(value) {
      let result = this.reservationSiteConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
