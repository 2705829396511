<script>
/* COMPOSANTS */
import GaView from '@components/layout/GaView';
import InputSwitch from 'primevue/inputswitch';
import PButton from 'primevue/button';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import ownerTypesConst from '@mixins/const/ownerTypesConst';
import permissionsMixin from '@mixins/permissionsMixin';
import {PermissionMap} from '@mixins/const/permissionConst';

/* SERVICES */
import ownerService from '@services/ownerService';
import sidebarMixin from '@mixins/sidebarMixin';

export default {
  components: {
    GaView,
    InputSwitch,
    PButton,
  },
  mixins: [ownerTypesConst, randomRef, utilsMixin, permissionsMixin, sidebarMixin],
  metaInfo() {
    return {
      title: 'owner.list',
    };
  },
  data() {
    return {
      loading: true,
      list: [],
      totalItems: 0,
      item: {},
      filter: {
        page: 1,
        limit: 10,
        userEnabled: true,
        sort: ['id:DESC'],
        ownerStatus: 'CUSTOMER',
      },
    };
  },
  mounted() {
    this.search();
  },
  computed: {
    hasManagePermission() {
      return this.hasPermission(PermissionMap.OWNER.MANAGE);
    },
  },
  methods: {
    search() {
      this.loading = true;
      ownerService.owners(this.filter).then((data) => {
        this.list = data;
        this.loading = false;
      });
    },
    submitForm() {
      this.onPage({page: 0, rows: 10});
    },
    classForAccess(data) {
      return data.userId ? 'ti ti-check text-primary' : 'ti ti-close';
    },
    changeUserEnabled(data) {
      if (!this.hasManagePermission) return;
      if (data.userEnabled) this.enableUser(data);
      else this.disableUser(data);
    },
    disableUser(data) {
      ownerService.disable(data).then((data) => {
        //this.search();
      });
    },
    enableUser(data) {
      ownerService.enable(data).then((data) => {
        //this.search();
      });
    },
    resetFilter() {
      this.filter = {
        page: 1,
        limit: 10,
        sort: ['id:DESC'],
        ownerStatus: 'CUSTOMER',
      };
      this.toggleFilter('GaOwnerListFilter', this.filter, this.search, this.resetFilter);
    },
    openFilterPanel() {
      this.toggleFilter('GaOwnerListFilter', this.filter, this.search, this.resetFilter);
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :title="$t('owner.list')">
    <template #action>
      <div class="d-flex justify-content-end" style="gap: 16px">
        <PButton
          :badge="this.countFilter(this.filter)"
          badgeClass="p-badge-primary"
          class="btn btn-primary"
          label="Filtrer"
          @click="openFilterPanel()" />
      </div>
    </template>
    <template #content>
      <DataTable
        :always-show-paginator="false"
        :loading="loading"
        :paginator="true"
        :rows="10"
        :rows-per-page-options="[10, 20, 50]"
        :value="list"
        class="table"
        current-page-report-template="Showing {first} to {last} of {totalRecords}"
        paginator-template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        responsive-layout="scroll"
        removable-sort
        sort-mode="multiple">
        <template #empty>
          {{ $t('owner.empty') }}
        </template>
        <Column :header="$t('owner.ownerType')" :sortable="true" field="ownerType" style="width: 15%">
          <template #body="slotProps">
            <router-link :to="{name: 'ownerPage', params: {ownerId: slotProps.data.id}}">
              {{ displayForOwnerTypeConst(slotProps.data.ownerType) }}
            </router-link>
          </template>
        </Column>
        <Column :header="$t('owner.ownerName')" :sortable="true" field="display" style="width: 20%">
          <template #body="slotProps">
            <router-link :to="{name: 'ownerPage', params: {ownerId: slotProps.data.id}}">
              {{ slotProps.data.display }}
            </router-link>
          </template>
        </Column>
        <Column :header="$t('contact.email')" :sortable="true" field="contactEmail" style="width: 20%">
          <template #body="slotProps">
            <router-link :to="{name: 'ownerPage', params: {ownerId: slotProps.data.id}}">
              {{ slotProps.data.contactEmail }}
            </router-link>
          </template>
        </Column>
        <Column :header="$t('contact.phone')" :sortable="true" field="contactPhone" style="width: 20%">
          <template #body="slotProps">
            <router-link :to="{name: 'ownerPage', params: {ownerId: slotProps.data.id}}">
              {{ slotProps.data.contactPhone }}
            </router-link>
          </template>
        </Column>
        <Column
          :header="$t('user.access')"
          :sortable="true"
          body-style="text-align: center; overflow: visible"
          field="userId"
          header-style="text-align: center"
          style="width: 15%">
          <template #body="slotProps">
            <router-link :to="{name: 'ownerPage', params: {ownerId: slotProps.data.id}}">
              <i :class="classForAccess(slotProps.data)" />
            </router-link>
          </template>
        </Column>
        <Column :header="$t('user.enabled')" :sortable="true" field="userEnabled" style="width: 5%">
          <template #body="slotProps">
            <InputSwitch v-model="slotProps.data.userEnabled" @change="changeUserEnabled(slotProps.data)" />
          </template>
        </Column>
        <Column body-style="text-align: center; overflow: visible" header-style="text-align: center" style="width: 5%">
          <template #body="slotProps">
            <div class="d-flex justify-content-end align-items-center">
              <router-link
                :to="{name: 'ownerPage', params: {ownerId: slotProps.data.id}}"
                class="btn btn-xs btn-inverse-primary mr-1">
                <i class="fe fe-eye" />
              </router-link>
              <router-link
                v-if="hasManagePermission"
                :to="{name: 'ownerUpdate', params: {ownerId: slotProps.data.id}}"
                class="btn btn-xs btn-inverse-primary">
                <i class="fe fe-edit" />
              </router-link>
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
  </GaView>
</template>
