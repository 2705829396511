<script>
/* COMPOSANTS */
import {Icon} from '@iconify/vue/dist/iconify';
import MultiSelect from 'primevue/multiselect';
import AkCalendar from '@components/input/AkCalendar';
import AkAccommodationAutocomplete from '@components/general/AkAccommodationAutocomplete';
import AkMandatAutocomplete from '@components/general/AkMandatAutocomplete';
import AkDropdown from '@components/input/AkDropdown';
import AkOwnerAutocomplete from '@components/general/AkOwnerAutocomplete';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import accommodationTypeConst from '@mixins/const/accommodationTypeConst';
import residenceTypeConst from '@mixins/const/residenceTypeConst';

/* SERVICES */
import companyService from '@services/companyService';

export default {
  components: {
    AkDropdown,
    AkOwnerAutocomplete,
    AkAccommodationAutocomplete,
    AkCalendar,
    MultiSelect,
    Icon,
    AkMandatAutocomplete,
  },
  mixins: [permissionsMixin, utilsMixin, sidebarMixin, accommodationTypeConst, residenceTypeConst],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      companies: undefined,
      enabledStateConst: [
        {label: this.$t('account.all_third_party'), value: null},
        {label: this.$t('account.enabled_only_third_party'), value: false},
        {label: this.$t('account.disabled_only_third_party'), value: true},
      ],
    };
  },
  mounted() {
    companyService.companies().then((data) => {
      this.companies = data;
    });
  },
  methods: {
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      this.$refs.accommodationAutocomplete.reset();
      this.$refs.mandatAutocomplete.reset();
      this.$refs.ownerAutocomplete.reset();
      this.callback[1]();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <h2>Filtrer</h2>
  <form class="d-flex flex-column" style="row-gap: 16px" @submit.prevent="search">
    <MultiSelect
      v-model="currentValue.companyIds"
      :placeholder="$t('employee.company')"
      :options="this.companies"
      class="flex-1 form-control"
      option-label="name"
      option-value="id" />
    <AkAccommodationAutocomplete
      ref="accommodationAutocomplete"
      v-model="currentValue.accommodationId"
      :placeholder="$t('activity.accommodation')"
      inline
      class-name="flex-1 form-control" />
    <AkOwnerAutocomplete
      ref="ownerAutocomplete"
      v-model="currentValue.ownerId"
      optionValue="id"
      optionLabel="display"
      :placeholder="$t('owner.ownerDisplay')"
      inline
      class-name="flex-1 form-control" />
    <AkCalendar
      ref="inputSearchFrom"
      v-model="currentValue.date"
      inline
      :placeholder="$t('operation.date')"
      class-name="flex-1 form-control" />
    <AkMandatAutocomplete
      v-model="currentValue.mandatId"
      ref="mandatAutocomplete"
      inline
      class-name="flex-1 form-control"
      :placeholder="$t('settings.mandat_num')"
      option-label="num"
      option-value="id" />
    <AkDropdown
      v-model="currentValue.mandatResiliated"
      inline
      :label="$t('account.all_third_party')"
      :options="enabledStateConst"
      class-name="flex-1 form-control"
      option-label="label"
      option-value="value" />
    <div class="d-flex flex-row">
      <button class="btn btn-primary btn-search flex-1" type="submit">Rechercher</button>
      <Icon
        v-on:click="this.reset()"
        class="pointer"
        icon="basil:cross-outline"
        :style="{fontSize: '50px', width: '50px !important'}" />
    </div>
  </form>
</template>
