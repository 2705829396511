export default {
  data() {
    return {
      roleKeysConst: [
        {label: this.$t('role.owner'), value: 'OWNER'},
        {label: this.$t('role.photographer'), value: 'PHOTOGRAPHER'},
        {label: this.$t('role.administrator'), value: 'ADMINISTRATOR'},
        {label: this.$t('role.prestataire'), value: 'PRESTATAIRE'},

        {label: this.$t('role.ceo'), value: 'CEO'},
        {label: this.$t('role.relation_manager'), value: 'GUEST_RELATION_MANAGER'},
        {label: this.$t('role.business_developer'), value: 'BUSINESS_DEVELOPER'},
        {label: this.$t('role.head_of_sales'), value: 'HEAD_OF_SALES'},
        {label: this.$t('role.onboarding_manager'), value: 'ONBOARDING_MANAGER'},
        {label: this.$t('role.city_manager'), value: 'CITY_MANAGER'},
        {label: this.$t('role.assistant_city_manager'), value: 'ASSISTANT_CITY_MANAGER'},
        {label: this.$t('role.cleaner'), value: 'CLEANER'},
        {label: this.$t('role.administrative_and_accounting_manager'), value: 'ADMINISTRATIVE_AND_ACCOUNTING_MANAGER'},
        {label: this.$t('role.relation_manager'), value: 'RELATION_MANAGER'},
        {label: this.$t('role.accountant'), value: 'ACCOUNTANT'},
        {label: this.$t('role.owner_relation_manager'), value: 'OWNER_RELATION_MANAGER'},
        {label: this.$t('role.income_manager'), value: 'INCOME_MANAGER'},
        {label: this.$t('role.assistant_income_manager'), value: 'ASSISTANT_INCOME_MANAGER'},
        {label: this.$t('role.administrative_manager'), value: 'ADMINISTRATIVE_MANAGER'},
        {label: this.$t('role.executive_assistant'), value: 'EXECUTIVE_ASSISTANT'},
        {label: this.$t('role.administrative_assistant'), value: 'ADMINISTRATIVE_ASSISTANT'},
        {label: this.$t('role.accountant_assistant'), value: 'ACCOUNTING_ASSISTANT'},
        {label: this.$t('role.accountant_mandant'), value: 'ACCOUNTANT_MANDANT'},
        {label: this.$t('role.accountant_assistant_mandant'), value: 'ACCOUNTANT_ASSISTANT_MANDANT'},
        {label: this.$t('role.sales_development_representative'), value: 'SALES_DEVELOPMENT_REPRESENTATIVE'},
        {label: this.$t('role.sales_operations'), value: 'SALES_OPERATIONS'},
        {label: this.$t('role.marketing_and_communication_manager'), value: 'MARKETING_AND_COMMUNICATION_MANAGER'},
        {label: this.$t('role.communication_manager'), value: 'COMMUNICATION_MANAGER'},
        {label: this.$t('role.communication_assistant'), value: 'COMMUNICATION_ASSISTANT'},
        {label: this.$t('role.coo'), value: 'COO'},
        {label: this.$t('role.country_manager'), value: 'COUNTRY_MANAGER'},
        {label: this.$t('role.project_manager'), value: 'PROJECT_MANAGER'},
        {label: this.$t('role.digital_project_manager'), value: 'DIGITAL_PROJECT_MANAGER'},

        {label: this.$t('undefined'), value: undefined},
      ],
      roleKeysForGas: [
        {
          label: this.$t('role.traveller'),
          code: 'TRAVELLER',
          items: [
            {label: this.$t('role.relation_manager'), value: 'RELATION_MANAGER'},
            {label: this.$t('role.accountant'), value: 'ACCOUNTANT'},
          ],
        },
        {
          label: this.$t('role.owner'),
          code: 'OWNER',
          items: [{label: this.$t('role.owner_relation_manager'), value: 'OWNER_RELATION_MANAGER'}],
        },
        {
          label: this.$t('role.income_management'),
          code: 'INCOME_MANAGEMENT',
          items: [
            {label: this.$t('role.income_manager'), value: 'INCOME_MANAGER'},
            {label: this.$t('role.assistant_income_manager'), value: 'ASSISTANT_INCOME_MANAGER'},
          ],
        },
        {
          label: this.$t('role.general_administration_and_accounting'),
          code: 'GENERAL_ADMINISTRATION_AND_ACCOUNTING',
          items: [
            {label: this.$t('role.administrative_manager'), value: 'ADMINISTRATIVE_MANAGER'},
            {label: this.$t('role.executive_assistant'), value: 'EXECUTIVE_ASSISTANT'},
            {label: this.$t('role.administrative_assistant'), value: 'ADMINISTRATIVE_ASSISTANT'},
            {label: this.$t('role.accountant_assistant'), value: 'ACCOUNTING_ASSISTANT'},
          ],
        },
        {
          label: this.$t('role.mandant_accounting'),
          code: 'MANDANT_ACCOUNTING',
          items: [
            {label: this.$t('role.accountant_mandant'), value: 'ACCOUNTANT_MANDANT'},
            {label: this.$t('role.accountant_assistant_mandant'), value: 'ACCOUNTANT_ASSISTANT_MANDANT'},
          ],
        },
        {
          label: this.$t('role.sales'),
          code: 'SALES',
          items: [
            {label: this.$t('role.head_of_sales'), value: 'HEAD_OF_SALES'},
            {label: this.$t('role.sales_development_representative'), value: 'SALES_DEVELOPMENT_REPRESENTATIVE'},
            {label: this.$t('role.sales_operations'), value: 'SALES_OPERATIONS'},
          ],
        },
        {
          label: this.$t('role.marketing_and_communication'),
          code: 'MARKETING_AND_COMMUNICATION',
          items: [
            {label: this.$t('role.marketing_and_communication_manager'), value: 'MARKETING_AND_COMMUNICATION_MANAGER'},
            {label: this.$t('role.communication_manager'), value: 'COMMUNICATION_MANAGER'},
            {label: this.$t('role.communication_assistant'), value: 'COMMUNICATION_ASSISTANT'},
          ],
        },
        {
          label: this.$t('role.top_management'),
          code: 'TOP_MANAGEMENT',
          items: [
            {label: this.$t('role.ceo'), value: 'CEO'},
            {label: this.$t('role.coo'), value: 'COO'},
            {label: this.$t('role.country_manager'), value: 'COUNTRY_MANAGER'},
            {label: this.$t('role.project_manager'), value: 'PROJECT_MANAGER'},
          ],
        },
        {
          label: this.$t('role.technical_division'),
          code: 'TECHNICAL_DIVISION',
          items: [{label: this.$t('role.digital_project_manager'), value: 'DIGITAL_PROJECT_MANAGER'}],
        },
        {
          label: this.$t('role.other'),
          code: 'OTHER',
          items: [{label: this.$t('undefined'), value: undefined}],
        },
      ],
      roleKeysForGav: [
        {label: this.$t('role.city_manager'), value: 'CITY_MANAGER'},
        {label: this.$t('role.assistant_city_manager'), value: 'ASSISTANT_CITY_MANAGER'},
        {label: this.$t('role.business_developer'), value: 'BUSINESS_DEVELOPER'},
        {label: this.$t('role.cleaner'), value: 'CLEANER'},
        {label: this.$t('role.welcomer'), value: 'WELCOMER'},
        {label: this.$t('role.household_assistant'), value: 'HOUSEHOLD_ASSISTANT'},
        {label: this.$t('role.administrative_assistant'), value: 'ADMINISTRATIVE_ASSISTANT'},
        {label: this.$t('undefined'), value: undefined},
      ],
    };
  },
  methods: {
    displayForRoleKeysConst(value) {
      let result = this.roleKeysConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
