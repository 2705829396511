<script>
/* COMPOSANTS */
import PButton from 'primevue/button';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import loaderMixin from '@mixins/loaderMixin';

/* SERVICES */
import litigeService from '@services/litigeService';
import litigeResolutionConst from '@mixins/const/litigeResolutionConst';
import litigeStatusConst from '@mixins/const/litigeStatusConst';

export default {
  components: {
    PButton,
  },
  mixins: [
    randomRef,
    utilsMixin,
    messageMixin,
    permissionsMixin,
    sidebarMixin,
    loaderMixin,
    litigeResolutionConst,
    litigeStatusConst,
  ],
  metaInfo() {
    return {
      title: 'litige.list',
    };
  },
  data() {
    return {
      loading: false,
      filter: {
        page: 1,
        limit: 10,
        sort: ['id:DESC'],
      },
      litiges: [],
      submitted: false,
    };
  },
  watch: {},
  mounted() {
    this.refresh();
  },
  computed: {},
  methods: {
    refresh() {
      this.loading = true;

      litigeService
        .litigesOwner(this.filter)
        .then((data) => {
          this.litiges = data;
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openFilterPanel() {
      this.toggleFilter('GaLitigeListFilter', this.filter, this.refresh, this.resetFilter);
    },
    resetFilter() {
      this.filter = {};
      this.toggleFilter('GaLitigeListFilter', this.filter, this.refresh, this.resetFilter);
    },

    rowClick(event) {
      // let data = event.data;
      // this.$router.push({
      //   name: 'litigeDetails',
      //   params: {litigeId: data.id},
      // });
    },

    isStatusResolu(status) {
      return status === 'RESOLU';
    },
    setClassNameForStatus(status) {
      if (status === 'VALIDATED') return 'color-green';
      if (status === 'REFUSED') return 'color-red';

      return '';
    },
  },
};
</script>

<template>
  <GaContentView :ref="ref" :title="$t('litige.list')">
    <template #action>
      <div class="d-flex justify-content-end" style="column-gap: 16px">
        <PButton
          class="btn btn-inverse-primary btn-xs"
          :badge="this.countFilter(this.filter)"
          badgeClass="p-badge-primary"
          label="Filtrer"
          @click="openFilterPanel()">
        </PButton>
      </div>
    </template>
    <template #content>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive">
                <DataTable
                  :always-show-paginator="false"
                  :loading="loading"
                  :paginator="true"
                  :rows="10"
                  :rows-per-page-options="[10, 20, 50]"
                  :value="litiges"
                  class="table"
                  current-page-report-template="{first}-{last}/{totalRecords}"
                  responsive-layout="scroll"
                  striped-rows
                  removable-sort
                  @row-click="rowClick($event)">
                  <template #empty>
                    {{ $t('litige.empty') }}
                  </template>
                  <Column :header="$t('maintenance.accommodationId')" :sortable="true" sort-field="accommodationName">
                    <template #body="slotProps">
                      {{ slotProps.data.accommodationName }}
                    </template>
                  </Column>
                  <Column :header="$t('reservation.number')" :sortable="true" sort-field="reservationNum">
                    <template #body="slotProps">
                      {{ slotProps.data.reservationNum }}
                    </template>
                  </Column>
                  <Column :header="$t('litige.date')" :sortable="true" field="date">
                    <template #body="slotProps">
                      {{ this.displayDate(slotProps.data.date) }}
                    </template>
                  </Column>
                  <Column :header="$t('maintenance.status')" :sortable="true" sort-field="displayStatus">
                    <template #body="slotProps">
                      <span
                        v-if="!isStatusResolu(slotProps.data.status)"
                        :className="this.setClassNameForStatus(slotProps.data.displayStatus)">
                        {{ displayForLitigeStatusConst(slotProps.data.displayStatus) }}
                      </span>
                      <span v-else-if="slotProps.data.resolution">
                        {{ displayForLitigeResolutionConst(slotProps.data.resolution) }}
                      </span>
                    </template>
                  </Column>
                  <!--<Column
                    body-style="text-align: center; overflow: visible"
                    header-style=" width: 180px; text-align: center">
                    <template #body="slotProps">
                      <div class="d-flex justify-content-end align-items-center">
                        <router-link
                          :to="{name: 'litigeDetails', params: {litigeId: slotProps.data.id}}"
                          class="btn btn-xs btn-inverse-primary mr-1">
                          <i class="fe fe-eye" />
                        </router-link>
                      </div>
                    </template>
                  </Column>-->
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </GaContentView>
</template>
