import {goBackTo} from '@utils';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';

export default {
  mixins: [permissionsMixin],
  data() {
    return {
      tabs: [
        {
          name: this.$t('details'),
          id: 'partenaireDetails',
          icon: 'home',
          active: true,
        },
      ],
    };
  },
  methods: {
    goBack() {
      goBackTo(this.$router, 'partenaires');
    },
  },
};
