<script>
/* COMPOSANTS */
import AkLabel from '@components/general/AkLabel';
import FileUpload from 'primevue/fileupload';

/* MIXINS */
import envConst from '@mixins/const/envConst';

export default {
  components: {AkLabel, FileUpload},
  mixins: [envConst],
  inject: {pSubmitted: {default: null}, pDisabled: {default: null}},
  props: {
    modelValue: {
      required: true,
    },
    className: {
      type: String,
      required: false,
      default: 'col-md-4 col-6',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    showCancelButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    accept: {
      type: String,
      required: false,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    displayLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    isInline() {
      return this.inline;
    },
  },
  methods: {
    selectFiles(event) {
      this.currentValue = event.files;
    },
    removeFile(event) {
      this.currentValue = event.files;
    },
    clearFiles() {
      this.currentValue = [];
    },
    clear() {
      this.$refs.uploader.clear();
    },
  },
};
</script>

<template>
  <div :class="[className, {'form-group': !isInline, 'form-control': isInline}]">
    <AkLabel v-if="label && displayLabel" :required="this.required === true">
      {{ label }}
    </AkLabel>
    <FileUpload
      ref="uploader"
      :cancel-label="$t('cancel_file')"
      :choose-label="$t('choose_file')"
      :showCancelButton="showCancelButton"
      :maxFileSize="getMaxFileUpload()"
      :invalidFileSizeMessage="$t('file.err_size')"
      :custom-upload="true"
      :disabled="isDisabled"
      :multiple="true"
      :show-upload-button="false"
      name="uploader"
      :accept="accept"
      @clear="clearFiles"
      @remove="removeFile"
      @select="selectFiles">
      <template #empty>
        <p>{{ $t('drag_files') }}</p>
      </template>
    </FileUpload>
  </div>
</template>
