<script>
import {useMessageStore} from '@store/messagesStore';
import {mapState} from 'pinia/dist/pinia';

/* COMPOSANTS */
import GaItemTitle from '@components/ga/general/GaItemTitle';
import GaTitleMenu from '@components/ga/general/GaTitleMenu';

/* MIXINS */
import messageMixin from '@mixins/messageMixin';
import loaderMixin from '@mixins/loaderMixin';

export default {
  components: {GaTitleMenu, GaItemTitle},
  mixins: [messageMixin, loaderMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    classTitle: {
      type: String,
      required: false,
      default: 'col-lg-6 col-8',
    },
    classAction: {
      type: String,
      required: false,
      default: 'col-lg-6 col-4',
    },
  },
  computed: {
    ...mapState(useMessageStore, ['messages']),
  },
};
</script>

<template>
  <div class="h-100 d-flex flex-column">
    <Message v-for="msg of this.messages" :key="msg.content" :closable="msg.closable" :severity="msg.severity">
      {{ msg.content }}
    </Message>
    <slot name="tabs" />
    <GaTitleMenu :with-bullet="false">
      <slot name="title">
        <GaItemTitle :active="true" :title="title" />
      </slot>
    </GaTitleMenu>
    <div class="row align-items-start mb-20">
      <div :class="classTitle">
        <slot name="search" />
      </div>
      <div :class="classAction" class="d-flex justify-content-end">
        <slot name="action" />
      </div>
    </div>
    <perfect-scrollbar class="content-list h-100">
      <slot name="list" />
      <slot name="empty" />
    </perfect-scrollbar>

    <slot name="extra" />
  </div>
</template>
<style>
.mb-56 {
  margin-bottom: 56px;
}
</style>
