<script>
/* COMPONENTS */
import HousekeeperDetails from './tabs/HousekeeperDetails.vue';
import AkLoader from '@components/general/AkLoader.vue';
import GaTabView from '@components/layout/GaTabView.vue';

/* MIXINS */
import housekeeperTabMixin from '@mixins/tab/housekeeperTabMixin';
import randomRef from '@mixins/randomRef';

/* SERVICES */
import housekeeperService from '../../services/housekeeperService';
import housekeeperAccommodationService from '@services/housekeeperAccommodationService';

export default {
  components: {GaTabView, AkLoader, HousekeeperDetails},
  mixins: [randomRef, housekeeperTabMixin],
  data() {
    return {
      housekeeper: {
        companyId: null,
        accommodationIds: [],
        contact: {
          companyName: null,
          formeJuridiqueId: null,
          raisonSociale: null,
          capital: null,
          address: null,
          complement: null,
          postalCode: null,
          city: null,
          state: null,
          country: null,
          email: null,
          phone: null,
          villeRcs: null,
          siret: null,
          civility: null,
          lastName: null,
          firstName: null,
          function: null,
        },
      },
      initialHousekeeper: null,
      activeTab: undefined,
      editable: false,
    };
  },
  mounted() {
    if (this.$route.hash) {
      this.activeTab = this.$route.hash.slice(1);
    }
    this.getHousekeeper();
  },
  methods: {
    getHousekeeper() {
      this.$refs.loader.load();
      housekeeperService.housekeeper(this.$route.params.housekeeperId).then((result) => {
        this.housekeeper = {...result};
        housekeeperAccommodationService
          .housekeeperAccommodations({housekeeperId: this.housekeeper.id})
          .then((accommodations) => {
            this.housekeeper.accommodationIds = accommodations.map((a) => a.accommodationId).filter((a) => a);
          });
        this.initialHousekeeper = JSON.parse(JSON.stringify(result));
        this.$refs.loader.hide();
      });
    },
  },
};
</script>

<template>
  <AkLoader ref="loader" :message="$t('currently_loading')" :scale="1.5">
    <template #content>
      <GaTabView :ref="ref" :firstTab="this.activeTab" :tabs="tabs" :title="housekeeper.contact.display">
        <template #back>
          <a class="mb-1 d-block pointer" @click="goBack()">
            <i class="fe fe-arrow-left pr-1" />
            {{ $t('back') }}
          </a>
        </template>
        <template #housekeeperDetails>
          <HousekeeperDetails v-if="housekeeper.companyId" :model-value="housekeeper" />
        </template>
      </GaTabView>
    </template>
  </AkLoader>
</template>
