<script>
/* COMPOSANTS */
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import AkInputText from '@components/input/AkInputText';
import AkFicCard from '@components/input/AkFicCard';
import AkDropdown from '@components/input/AkDropdown';
import AkLoader from '@components/general/AkLoader';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import documentModelTypeConst from '@mixins/const/documentModelTypeConst';

/* SERVICES */
import documentModelService from '@services/documentModelService';
import GaView from '@components/layout/GaView';
import backMixin from '@mixins/backMixin';

export default {
  components: {
    AkDropdown,
    AkFicCard,
    AkFormSubmitted,
    AkInputText,
    AkLoader,
    GaView,
  },
  mixins: [backMixin, documentModelTypeConst, randomRef],
  metaInfo() {
    return {
      title: 'document_model.item',
    };
  },
  data() {
    return {
      documentModel: undefined,
    };
  },
  computed: {
    getSubType() {
      if (this.documentModel.type) return this.childrenForDocumentModelType(this.documentModel.type);
      return [];
    },
  },
  mounted() {
    this.$refs.loader.load();
    documentModelService
      .documentModelDto(this.$route.params.documentModelId)
      .then((data) => {
        this.documentModel = data;
      })
      .catch(() => {})
      .finally(() => {
        this.$refs.loader.hide();
      });
  },
  methods: {},
};
</script>

<template>
  <AkLoader ref="loader" :message="$t('currently_loading')" :scale="1.5" color="black">
    <template #content>
      <GaView :ref="ref" :title="$t('document_model.item')">
        <template #back>
          <a class="mb-1 d-block pointer" @click="goBack()"><i class="fe fe-arrow-left pr-1" />{{ $t('back') }}</a>
        </template>
        <template #action>
          <div class="d-flex justify-content-end">
            <router-link
              v-if="documentModel !== undefined"
              :to="{name: 'settingsDocumentModelUpdate', params: {documentModelId: documentModel.id}}"
              class="btn btn-primary">
              <i class="fe fe-edit pr-1" />{{ $t('document_model.updateThis') }}
            </router-link>
          </div>
        </template>
        <template #content>
          <AkFormSubmitted v-if="documentModel" :disabled="true" reference="form">
            <div class="form-row">
              <AkInputText v-model="documentModel.name" :label="$t('document_model.name')" class-name="col-md-4" />
              <AkDropdown
                v-model="documentModel.type"
                :label="$t('document_model.type')"
                :options="documentModelTypeConst" />
              <AkDropdown v-model="documentModel.subType" :label="$t('document_model.subtype')" :options="getSubType" />
            </div>
            <div class="form-row">
              <AkFicCard
                v-model="documentModel.fic"
                :reference="getRef()"
                :table-id="documentModel.id"
                :title="$t('document_model.file')"
                accept=".docx"
                class="col-md-4"
                :disabled="true"
                table-name="document_model" />
            </div>
          </AkFormSubmitted>
        </template>
      </GaView>
    </template>
  </AkLoader>
</template>
