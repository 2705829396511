<script>
import GaView from '@components/layout/GaView.vue';
import GaBloc from '@components/ga/general/GaBloc';
import router from '@router';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';
import UtilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import mandatService from '@services/mandatService';

export default {
  name: 'AvenantSign',
  components: {GaBloc, GaView},
  mixins: [randomRef, loaderMixin, messageMixin, UtilsMixin],
  data() {
    return {
      avenantId: undefined,
      avenant: undefined,
      parentMandat: undefined,
      yousign: undefined,
    };
  },
  mounted() {
    this.avenantId = this.$route.params.avenantId;
    mandatService.mandat(this.avenantId).then((data) => {
      this.avenant = data;

      mandatService.mandat(this.avenant.parentId).then((data) => {
        this.parentMandat = data;
        this.initV3();
      });
    });
  },
  methods: {
    router() {
      return router;
    },
    initV3() {
      if (this.isV3Yousign) {
        let that = this;
        if (this.yousign) {
          this.yousign.removeMessageListener();
        }
        setTimeout(() => {
          this.yousign = new window.Yousign({
            signatureLink: this.nextSignUrl,
            iframeContainerId: 'iframe-container',
            isSandbox: process.env.VUE_APP_YOUSIGN_PLATFORM !== 'prod',
          });
          this.yousign.onSuccess((data) => {
            that.avenantSigned();
          });
        }, 100);
      }
    },
    avenantSigned() {
      this.yousign.removeMessageListener();
      this.success('Signature validée, vous serez rédirigé dans 5 secondes');
      setTimeout(() => {
        mandatService
          .avenantSigned(this.avenant)
          .then((data) => {
            if (data.yousign === null && data.signDate !== null) {
              this.success(this.$t('mandat.avenant_signed'));
              this.goToDashboard();
            } else if (data.yousign !== this.avenant.yousign) {
              this.avenant = data;
            } else {
              throw '';
            }
          })
          .then(() => {
            if (this.avenant.yousign) this.initV3();
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 503) {
                this.addWarning(this.$t('mandat.error_avenant_not_signed'));
              } else {
                this.addError(this.$t('error.undefined'));
              }
            } else {
              this.addError(this.$t('error.undefined'));
            }
          });
      }, 5000);
    },
    goToDashboard() {
      this.router().push({name: 'home', hash: '#avenantSign'});
    },
  },
  computed: {
    isV3Yousign() {
      return this.avenant.yousignVersion === 'v3';
    },
    nextSignUrl() {
      let production = process.env.VUE_APP_YOUSIGN_PLATFORM !== 'prod' ? '&disable_domain_validation=true' : '';
      return this.avenant.nextSignUrl.concat(production);
    },
  },
};
</script>

<template>
  <GaView v-if="avenant !== undefined && parentMandat !== undefined" :ref="ref" title="">
    <template v-if="avenant.yousign !== null" #content>
      <div class="row h-100">
        <div class="col h-100">
          <GaBloc
            :little="true"
            :title="
              $t('mandat.avenant_sign_title', {
                dateStart: displayDate(avenant.dateStart),
                parentMandatNum: parentMandat.num,
              })
            "
            class="h-100 iframe-yousign">
            <template #content>
              <iframe
                v-if="!this.isV3Yousign"
                :key="avenant.yousign"
                :src="avenant.nextSignUrl"
                height="800px"
                width="100%" />
              <div v-else id="iframe-container" class="iframe-yousign"></div>
            </template>
          </GaBloc>
        </div>
      </div>
    </template>
  </GaView>
</template>
<style>
.iframe-yousign {
  height: 100%;
}

.iframe-yousign .bloc-content {
  flex: 1;
}

.iframe-yousign iframe {
  width: 100%;
  height: 100%;
}
</style>
