<script>
/* MIXINS */
import prestationTypesConst from '@mixins/const/prestationTypesConst';

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      typeDisplayed: '',
    };
  },
  mixins: [prestationTypesConst],
  mounted() {
    this.typeDisplayed = this.type;
  },
  computed: {
    typeClass() {
      if (this.typeDisplayed) {
        return this.typeDisplayed.toLowerCase().replace('_', '-');
      } else {
        return '';
      }
    },
    isTypeCleaning() {
      return (
        this.typeDisplayed === 'CLEANING' ||
        this.typeDisplayed === 'CLEANING_AFTER' ||
        this.typeDisplayed === 'CLEANING_BEFORE'
      );
    },
    isTypeCheckinOrCheckout() {
      return this.typeDisplayed === 'CHECK_IN' || this.typeDisplayed === 'CHECK_OUT';
    },
    isTypeMaintenanceOrRunning() {
      return this.typeDisplayed === 'RUNNING' || this.typeDisplayed === 'MAINTENANCE';
    },
    isTypeQualityControl() {
      return this.typeDisplayed === 'QUALITY_CONTROL';
    },
  },
};
</script>

<template>
  <div class="prestation-badge" :class="`--${typeClass}`">
    <div class="badge-icon">
      <svg
        v-if="this.isTypeCleaning"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1692_14414)">
          <path
            d="M3.66671 7H2.33337C1.80294 7 1.29423 7.21071 0.91916 7.58579C0.544088 7.96086 0.333374 8.46957 0.333374 9V15C0.333374 15.1768 0.403612 15.3464 0.528636 15.4714C0.65366 15.5964 0.82323 15.6667 1.00004 15.6667H5.00004C5.17685 15.6667 5.34642 15.5964 5.47144 15.4714C5.59647 15.3464 5.66671 15.1768 5.66671 15V9C5.66671 8.46957 5.45599 7.96086 5.08092 7.58579C4.70585 7.21071 4.19714 7 3.66671 7Z"
            stroke="#00C5C5"
            stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M9.42532 14.404C9.53603 14.1825 9.70625 13.9961 9.9169 13.8659C10.1276 13.7357 10.3703 13.6667 10.618 13.6667H14.0487C14.2963 13.6667 14.5391 13.7357 14.7497 13.8659C14.9604 13.9961 15.1306 14.1825 15.2413 14.404L15.6313 15.184C15.6568 15.2348 15.6688 15.2913 15.6663 15.3481C15.6638 15.4049 15.6468 15.4601 15.6169 15.5085C15.5871 15.5568 15.5453 15.5968 15.4957 15.6245C15.4461 15.6521 15.3902 15.6667 15.3333 15.6667H9.33332C9.27648 15.6667 9.22058 15.6521 9.17094 15.6245C9.1213 15.5968 9.07957 15.5568 9.0497 15.5085C9.01984 15.4601 9.00284 15.4049 9.00033 15.3481C8.99781 15.2913 9.00986 15.2348 9.03532 15.184L9.42532 14.404Z"
            stroke="#00C5C5"
            stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M12 12H12.6666C12.755 12 12.8398 12.0351 12.9023 12.0976C12.9648 12.1601 13 12.2449 13 12.3333V13.6667H11.6666V12.3333C11.6666 12.2449 11.7017 12.1601 11.7643 12.0976C11.8268 12.0351 11.9116 12 12 12Z"
            stroke="#00C5C5"
            stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M7.66663 10.6666C7.66663 10.9319 7.77198 11.1862 7.95952 11.3738C8.14706 11.5613 8.40141 11.6666 8.66663 11.6666C8.93184 11.6666 9.1862 11.5613 9.37373 11.3738C9.56127 11.1862 9.66663 10.9319 9.66663 10.6666V1.66665C9.66663 1.31302 9.8071 0.973886 10.0572 0.723837C10.3072 0.473789 10.6463 0.333313 11 0.333313C11.3536 0.333313 11.6927 0.473789 11.9428 0.723837C12.1928 0.973886 12.3333 1.31302 12.3333 1.66665V12"
            stroke="#00C5C5"
            stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M3 7.00002V5.00002C3 4.38118 3.24583 3.78769 3.68342 3.3501C4.121 2.91252 4.71449 2.66669 5.33333 2.66669C5.95217 2.66669 6.54566 2.91252 6.98325 3.3501C7.42083 3.78769 7.66667 4.38118 7.66667 5.00002V10.6667"
            stroke="#00C5C5"
            stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M0.333374 13H5.66671" stroke="#00C5C5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M3.66663 14.3333H4.33329" stroke="#00C5C5" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M3 10.3333C3.55228 10.3333 4 9.8856 4 9.33331C4 8.78103 3.55228 8.33331 3 8.33331C2.44772 8.33331 2 8.78103 2 9.33331C2 9.8856 2.44772 10.3333 3 10.3333Z"
            stroke="#00C5C5"
            stroke-linecap="round"
            stroke-linejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_1692_14414">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <svg
        v-if="this.isTypeCheckinOrCheckout"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1692_14426)">
          <path
            d="M11.3334 12.3333V13C11.3334 13.3536 11.1929 13.6927 10.9428 13.9428C10.6928 14.1928 10.3537 14.3333 10 14.3333H9.33337"
            stroke="#0500FF"
            stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M1.33337 10.3333V3.66665C1.33337 3.31302 1.47385 2.97389 1.7239 2.72384C1.97395 2.47379 2.31309 2.33331 2.66671 2.33331H4.00004"
            stroke="#0500FF"
            stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M8.66663 2.33331H9.99996C10.3536 2.33331 10.6927 2.47379 10.9428 2.72384C11.1928 2.97389 11.3333 3.31302 11.3333 3.66665V8.33331"
            stroke="#0500FF"
            stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M7.488 0.999982C7.37097 0.797306 7.20266 0.629003 6.99997 0.51199C6.79729 0.394976 6.56737 0.333374 6.33333 0.333374C6.0993 0.333374 5.86938 0.394976 5.6667 0.51199C5.46401 0.629003 5.2957 0.797306 5.17867 0.999982H4.66667C4.48986 0.999982 4.32029 1.07022 4.19526 1.19524C4.07024 1.32027 4 1.48984 4 1.66665V2.99998C4 3.17679 4.07024 3.34636 4.19526 3.47139C4.32029 3.59641 4.48986 3.66665 4.66667 3.66665H8C8.17681 3.66665 8.34638 3.59641 8.4714 3.47139C8.59643 3.34636 8.66667 3.17679 8.66667 2.99998V1.66665C8.66667 1.48984 8.59643 1.32027 8.4714 1.19524C8.34638 1.07022 8.17681 0.999982 8 0.999982H7.488Z"
            stroke="#0500FF"
            stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M0.666626 15.6667V13.888C0.666803 13.2988 0.900984 12.7338 1.31767 12.3173C1.73436 11.9007 2.29943 11.6667 2.88863 11.6667H3.99996V8.66669C3.99996 8.40147 4.10532 8.14712 4.29285 7.95958C4.48039 7.77204 4.73474 7.66669 4.99996 7.66669C5.26518 7.66669 5.51953 7.77204 5.70707 7.95958C5.8946 8.14712 5.99996 8.40147 5.99996 8.66669V13.3334L6.85929 12.614C6.92933 12.5299 7.01525 12.4604 7.11216 12.4094C7.20906 12.3585 7.31505 12.3272 7.42406 12.3172C7.53308 12.3072 7.64299 12.3188 7.74752 12.3514C7.85205 12.3839 7.94916 12.4366 8.03329 12.5067C8.11743 12.5767 8.18694 12.6626 8.23787 12.7596C8.28879 12.8565 8.32014 12.9624 8.3301 13.0715C8.34007 13.1805 8.32847 13.2904 8.29596 13.3949C8.26344 13.4994 8.21066 13.5966 8.14063 13.6807L6.48796 15.6667"
            stroke="#0500FF"
            stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M13.4714 10.8614L12.4714 9.86136C12.4099 9.79769 12.3363 9.7469 12.255 9.71196C12.1737 9.67702 12.0862 9.65863 11.9977 9.65786C11.9091 9.65709 11.8213 9.67396 11.7394 9.70748C11.6575 9.741 11.5831 9.7905 11.5205 9.8531C11.4579 9.91569 11.4084 9.99013 11.3748 10.0721C11.3413 10.154 11.3245 10.2418 11.3252 10.3303C11.326 10.4188 11.3444 10.5063 11.3793 10.5876C11.4143 10.669 11.465 10.7425 11.5287 10.804L12.6667 11.9414V13.3334C12.6667 13.7604 12.8362 14.17 13.1381 14.472L14.3334 15.6667"
            stroke="#0500FF"
            stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M11.3334 6.66669L14.1954 9.52869C14.3448 9.67813 14.4634 9.85555 14.5442 10.0508C14.6251 10.2461 14.6667 10.4553 14.6667 10.6667V12C14.6666 12.2114 14.7081 12.4207 14.789 12.616C14.8699 12.8113 14.9885 12.9887 15.138 13.138L15.3334 13.3334"
            stroke="#0500FF"
            stroke-linecap="round"
            stroke-linejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_1692_14426">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <svg
        v-if="this.isTypeMaintenanceOrRunning"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5004 7.33734H10.4994" stroke="#00C572" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M10.776 12.8667C10.6894 12.9099 10.5945 12.9338 10.4978 12.9367C10.4011 12.9396 10.3049 12.9214 10.216 12.8833L5.57196 10.9013C5.45171 10.8501 5.34918 10.7646 5.27711 10.6556C5.20504 10.5465 5.16662 10.4187 5.16663 10.288V5.762C5.16664 5.63516 5.20284 5.51095 5.27098 5.40397C5.33912 5.29698 5.43636 5.21165 5.55129 5.158L9.90529 3.126C9.98784 3.08753 10.0774 3.06638 10.1684 3.06385C10.2595 3.06133 10.3501 3.07749 10.4346 3.11133L14.7473 4.83667C14.871 4.88609 14.9771 4.97144 15.0518 5.08171C15.1265 5.19198 15.1665 5.32212 15.1666 5.45533V10.2587C15.1666 10.3824 15.132 10.5038 15.0669 10.609C15.0018 10.7143 14.9087 10.7993 14.798 10.8547L10.776 12.8667Z"
          stroke="#00C572"
          stroke-linecap="round"
          stroke-linejoin="round" />
        <path d="M10.5 7.33732L15.0413 5.06665" stroke="#00C572" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M5.28528 5.38202L10.4993 7.33735V12.9354"
          stroke="#00C572"
          stroke-linecap="round"
          stroke-linejoin="round" />
        <path d="M12.654 6.26002L7.73132 4.14069" stroke="#00C572" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M0.833374 8.58398L0.864707 8.59798L3.50004 9.81532"
          stroke="#00C572"
          stroke-linecap="round"
          stroke-linejoin="round" />
        <path d="M1.92139 6.75336L3.50005 7.48202" stroke="#00C572" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M2.74866 4.802L3.49999 5.14867" stroke="#00C572" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <svg
        v-if="this.isTypeQualityControl"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1692_14463)">
          <path
            d="M6.00008 13.3334L14.6667 13.3334M6.00008 8.0001L14.6667 8.0001M6.00008 2.66677L14.6667 2.66677M2.33341 2.66677C2.33341 2.85086 2.18418 3.0001 2.00008 3.0001C1.81599 3.0001 1.66675 2.85086 1.66675 2.66677M2.33341 2.66677C2.33341 2.48267 2.18418 2.33344 2.00008 2.33344C1.81599 2.33344 1.66675 2.48267 1.66675 2.66677M2.33341 2.66677L1.66675 2.66677M2.33341 8.0001C2.33341 8.1842 2.18418 8.33343 2.00008 8.33343C1.81599 8.33343 1.66675 8.1842 1.66675 8.0001M2.33341 8.0001C2.33341 7.81601 2.18418 7.66677 2.00008 7.66677C1.81599 7.66677 1.66675 7.81601 1.66675 8.0001M2.33341 8.0001L1.66675 8.0001M2.33341 13.3334C2.33341 13.5175 2.18418 13.6668 2.00008 13.6668C1.81599 13.6668 1.66675 13.5175 1.66675 13.3334M2.33341 13.3334C2.33341 13.1493 2.18418 13.0001 2.00008 13.0001C1.81599 13.0001 1.66675 13.1493 1.66675 13.3334M2.33341 13.3334L1.66675 13.3334"
            stroke="#FBD300"
            stroke-linecap="round"
            stroke-linejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_1692_14463">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
    <span>
      {{ displayForPrestationTypeConst(typeDisplayed) }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
.prestation-badge {
  padding: 5px 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  border: 1px solid;
  border-radius: 32px;
  width: fit-content;

  > span {
    font: 500 12px/18px 'Poppins';
  }

  &.--cleaning {
    background: #e7ffff;
    color: #00c5c5;
    border-color: #cfffff;
  }

  &.--cleaning-after {
    background: #e7ffff;
    color: #00c5c5;
    border-color: #cfffff;
  }

  &.--cleaning-before {
    background: #e7ffff;
    color: #00c5c5;
    border-color: #cfffff;
  }

  &.--check-in {
    background: #ececff;
    color: #0500ff;
    border-color: #d9d9ff;
  }

  &.--check-out {
    background: #ececff;
    color: #8f00ff;
    border-color: #ebd3ff;
  }

  &.--running {
    background: #dbfff0;
    color: #00c572;
    border-color: #b7ffe1;
  }

  &.--booking-management {
    background: #fff7ec;
    color: #fbd300;
    border-color: #ffefd9;
  }

  &.--quality-control {
    background: #fff7ec;
    color: #fbd300;
    border-color: #ffefd9;
  }

  &.--maintenance {
    background: #dbfff0;
    color: #00c572;
    border-color: #b7ffe1;
  }
}
</style>
