<script>
import {maxLength, required, requiredIf} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

/* COMPOSANTS */
import AkDropdown from '@components/input/AkDropdown.vue';
import AkDialog from '@components/general/AkDialog.vue';
import AkInputTextArea from '@components/input/AkInputTextArea.vue';
import AkCalendar from '@components/input/AkCalendar.vue';
import AkInputText from '@components/input/AkInputText.vue';
import PButton from 'primevue/button';
import AkFilesUpload from '@components/input/AkFilesUpload.vue';
import AkClientAutocomplete from '@components/general/AkClientAutocomplete';
import AkOwnerAutocomplete from '@components/general/AkOwnerAutocomplete';
import AkPrestataireAutocomplete from '@components/general/AkPrestataireAutocomplete';
import AkEmployeeAutocomplete from '@components/general/AkEmployeeAutocomplete';
import AkPartenaireAutocomplete from '@components/general/AkPartenaireAutocomplete';
import AkHousekeeperAutocomplete from '@components/general/AkHousekeeperAutocomplete';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import loaderMixin from '@mixins/loaderMixin';
import maintenanceInterlocutorTypeConst from '@mixins/const/maintenanceInterlocutorTypeConst';
import maintenanceStatusConst from '@mixins/const/maintenanceStatusConst';

/* SERVICES */
import maintenanceService from '@services/maintenanceService';
import companyService from '@services/companyService';
import accommodationService from '@services/accommodationService';

export default {
  components: {
    AkFilesUpload,
    AkInputText,
    AkCalendar,
    AkInputTextArea,
    AkDialog,
    AkDropdown,
    PButton,
    AkClientAutocomplete,
    AkOwnerAutocomplete,
    AkPrestataireAutocomplete,
    AkEmployeeAutocomplete,
    AkPartenaireAutocomplete,
    AkHousekeeperAutocomplete,
  },
  mixins: [
    randomRef,
    utilsMixin,
    messageMixin,
    permissionsMixin,
    sidebarMixin,
    loaderMixin,
    maintenanceInterlocutorTypeConst,
    maintenanceStatusConst,
  ],
  metaInfo() {
    return {
      title: 'maintenance.list',
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  validations() {
    return {
      maintenanceCreate: {
        companyId: {required: requiredIf(this.isGas())},
        accommodationId: {required},
        interlocutorTypes: {required},
        ownerId: {required: requiredIf(this.isOwnerRequired)},
        clientId: {required: requiredIf(this.isClientRequired)},
        prestataireId: {required: requiredIf(this.isPrestataireRequired)},
        employeeId: {required: requiredIf(this.isEmployeeRequired)},
        partenaireId: {required: requiredIf(this.isPartenaireRequired)},
        housekeeperId: {required: requiredIf(this.isHousekeeperRequired)},
        dateMaintenance: {required},
        problem: {required, maxLengthValue: maxLength(500)},
        description: {required},
        reference: {maxLengthValue: maxLength(500)},
      },
      ownerId: {required},
    };
  },
  data() {
    return {
      loading: false,
      filter: {
        page: 1,
        limit: 10,
        sort: ['id:DESC'],
      },
      maintenances: [],
      maintenanceCreate: {
        interlocutorTypes: [],
      },
      ownerId: undefined,
      accommodations: [],
      companies: [],
      files: [],
      submitted: false,
    };
  },
  watch: {
    ownerId(newVal, oldVal) {
      // Saisie effacée
      if (!newVal) {
        if (this.isInterlocuteurProprietaireSelected) {
          this.maintenanceCreate.ownerId = undefined;
          this.maintenanceCreate.interlocutorTypes = '';
        }
        this.accommodations = [];
        return;
      }
      // Récupérer les logements + définir l'hôte par défaut
      const filter = {
        ownerId: this.ownerId,
        statuses: ['PUBLISHED', 'PREPUBLISHED'],
      };
      accommodationService.accommodations(filter).then((data) => {
        this.accommodations = data;
      });
      this.maintenanceCreate.ownerId = this.ownerId;
    },
    'maintenanceCreate.companyId'(newVal, oldVal) {
      this.maintenanceCreate.accommodationId = undefined;
      this.ownerId = undefined;
      if (this.$refs.ownerAutocomplete) this.$refs.ownerAutocomplete.reset();
      if (this.$refs.clientAutocomplete) this.$refs.clientAutocomplete.reset();
      if (this.$refs.prestataireAutocomplete) this.$refs.prestataireAutocomplete.reset();
      if (this.$refs.employeeAutocomplete) this.$refs.employeeAutocomplete.reset();
      if (this.$refs.partenaireAutocomplete) this.$refs.partenaireAutocomplete.reset();
    },
    'maintenanceCreate.interlocutorTypes'(newVal, oldVal) {
      if (!this.isInterlocuteurVoyageurSelected) this.maintenanceCreate.clientId = undefined;
      if (!this.isInterlocuteurPrestataireSelected) this.maintenanceCreate.prestataireId = undefined;
      if (!this.isInterlocuteurSalarieSelected) this.maintenanceCreate.employeeId = undefined;
      if (!this.isInterlocuteurPartenaireSelected) this.maintenanceCreate.partenaireId = undefined;
    },
  },
  mounted() {
    this.loadStatuses();
    this.loadCompanies();
    this.refresh();
  },
  computed: {
    // INTERLOCUTEURS SELECTIONNES

    isInterlocuteurProprietaireSelected() {
      return this.maintenanceCreate.interlocutorTypes === 'PROPRIETAIRE';
    },
    isInterlocuteurVoyageurSelected() {
      return this.maintenanceCreate.interlocutorTypes === 'VOYAGEUR';
    },
    isInterlocuteurPrestataireSelected() {
      return this.maintenanceCreate.interlocutorTypes === 'PRESTATAIRE';
    },
    isInterlocuteurSalarieSelected() {
      return this.maintenanceCreate.interlocutorTypes === 'SALARIE';
    },
    isInterlocuteurPartenaireSelected() {
      return this.maintenanceCreate.interlocutorTypes === 'PARTENAIRE';
    },
    isInterlocuteurHousekeeperSelected() {
      return this.maintenanceCreate.interlocutorTypes === 'HOUSEKEEPER';
    },

    // INPUT SELECTED

    isOwnerAccommodationInputAvailable() {
      return this.maintenanceCreate.companyId;
    },

    isOwnerInputAvailable() {
      return this.isInterlocuteurProprietaireSelected;
    },
    isClientInputAvailable() {
      return this.isInterlocuteurVoyageurSelected;
    },
    isPrestataireInputAvailable() {
      return this.isInterlocuteurPrestataireSelected;
    },
    isEmployeeInputAvailable() {
      return this.isInterlocuteurSalarieSelected;
    },
    isPartenaireInputAvailable() {
      return this.isInterlocuteurPartenaireSelected;
    },
    isHousekeeperInputAvailable() {
      return this.isInterlocuteurHousekeeperSelected;
    },

    // FIELDS REQUIRED

    isOwnerRequired() {
      return this.isOwnerInputAvailable;
    },
    isClientRequired() {
      return this.isClientInputAvailable;
    },
    isPrestataireRequired() {
      return this.isPrestataireInputAvailable;
    },
    isEmployeeRequired() {
      return this.isEmployeeInputAvailable;
    },
    isPartenaireRequired() {
      return this.isPartenaireInputAvailable;
    },
    isHousekeeperRequired() {
      return this.isHousekeeperInputAvailable;
    },
  },
  methods: {
    loadStatuses() {
      this.filter.statuses = this.maintenanceStatusConstNotResolueForFilterOnlyValues();
    },
    loadCompanies() {
      companyService
        .companies()
        .then((data) => {
          this.companies = data;
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        });
    },
    refresh() {
      this.loading = true;
      maintenanceService
        .maintenances(this.filter)
        .then((data) => {
          this.maintenances = data;
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // CREATE

    openCreateDialog() {
      this.maintenanceCreate = {};
      this.ownerId = undefined;
      if (this.isGav() || this.isPartenaire()) {
        this.maintenanceCreate.companyId = this.myCompanyId();
      }

      if (this.isHousekeeper()) {
        this.maintenanceCreate.companyId = this.myCompanyId();
        this.maintenanceCreate.housekeeperId = this.myHousekeeperId();
      }

      this.$refs.dialogCreate.show();
    },
    createMaintenance() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.maintenanceCreate.$error) return;

      if (!this.isOwnerInputAvailable) this.maintenanceCreate.ownerId = null;
      if (!this.isClientInputAvailable) this.maintenanceCreate.clientId = null;
      if (!this.isEmployeeInputAvailable) this.maintenanceCreate.employeeId = null;
      if (!this.isPrestataireInputAvailable) this.maintenanceCreate.prestataireId = null;
      if (!this.isPartenaireInputAvailable) this.maintenanceCreate.partenaireId = null;
      if (!this.isHousekeeperInputAvailable) this.maintenanceCreate.housekeeperId = null;

      this.$refs.dialogCreate.hide();

      maintenanceService
        .create(this.maintenanceCreate, this.files)
        .then((data) => {
          this.success(this.$t('maintenance.added'));
          this.files = undefined;
          this.refresh();
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.submitted = false;
        });
    },

    // FILTER

    openFilterPanel() {
      this.toggleFilter('GaMaintenanceListFilter', this.filter, this.refresh, this.resetFilter);
    },
    resetFilter() {
      this.filter = {};
      this.toggleFilter('GaMaintenanceListFilter', this.filter, this.refresh, this.resetFilter);
    },
    exportMaintenances() {
      this.showTotalLoaderWithAfter(this.$t('litige.list_creation_in_progress'));
      maintenanceService.exportMaintenances(this.filter).then((data) => {
        this.hideLoader();
      });
    },
    rowClick(event) {
      let data = event.data;
      this.$router.push({
        name: 'maintenanceDetails',
        params: {maintenanceId: data.id},
      });
    },

    isStatusDecisionPrise(status) {
      return status === 'DECISION_PRISE';
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :title="$t('maintenance.list')">
    <template #action>
      <div class="d-flex justify-content-end" style="column-gap: 16px">
        <PButton
          class="btn btn-primary"
          :badge="this.countFilter(this.filter)"
          badgeClass="p-badge-primary"
          label="Filtrer"
          @click="openFilterPanel()">
        </PButton>
        <button class="btn btn-primary" @click="exportMaintenances()">
          {{ this.$t('payment.download') }}
        </button>
        <button v-if="!this.isOwner()" class="btn btn-primary" @click="openCreateDialog()">
          <i class="gst-plus pr-1" />
          {{ $t('maintenance.add') }}
        </button>
      </div>
    </template>
    <template #content>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive">
                <DataTable
                  :always-show-paginator="false"
                  :loading="loading"
                  :paginator="true"
                  :rows="10"
                  :rows-per-page-options="[10, 20, 50]"
                  :value="maintenances"
                  class="pointer"
                  current-page-report-template="{first}-{last}/{totalRecords}"
                  responsive-layout="scroll"
                  striped-rows
                  removable-sort
                  sortField="displayPriorityOrder"
                  :sortOrder="1"
                  @row-click="rowClick($event)">
                  <template #empty>
                    {{ $t('maintenance.empty') }}
                  </template>
                  <Column
                    v-if="this.isGas()"
                    :header="$t('employee.company')"
                    :sortable="true"
                    sort-field="companyName">
                    <template #body="slotProps">
                      {{ slotProps.data.companyName }}
                    </template>
                  </Column>
                  <Column :header="$t('litige.accommodation')" :sortable="true" sort-field="accommodationName">
                    <template #body="slotProps">
                      {{ slotProps.data.accommodationName }}
                    </template>
                  </Column>
                  <Column :header="$t('accommodation.ownerName')" :sortable="true" sort-field="ownerName">
                    <template #body="slotProps">
                      {{ slotProps.data.ownerName }}
                    </template>
                  </Column>
                  <Column :header="$t('maintenance.date_maintenance')" :sortable="true" sort-field="dateMaintenance">
                    <template #body="slotProps">
                      {{ this.displayDate(slotProps.data.dateMaintenance) }}
                    </template>
                  </Column>
                  <Column :header="$t('maintenance.tag')" :sortable="true" sort-field="displayTag">
                    <template #body="slotProps">
                      {{ slotProps.data.displayTag }}
                    </template>
                  </Column>
                  <Column :header="$t('maintenance.problem')" :sortable="true" sort-field="problem">
                    <template #body="slotProps">
                      {{ slotProps.data.problem }}
                    </template>
                  </Column>
                  <Column :header="$t('maintenance.status')" :sortable="true" sort-field="displayStatusOrder">
                    <template #body="slotProps">
                      <span v-if="isStatusDecisionPrise(slotProps.data.status)">
                        {{ slotProps.data.displayOwnerChoice }}
                      </span>
                      <span v-else>
                        {{ slotProps.data.displayStatus }}
                      </span>
                    </template>
                  </Column>
                  <Column :header="$t('maintenance.priority')" :sortable="true" sort-field="displayPriorityOrder">
                    <template #body="slotProps">
                      {{ slotProps.data.displayPriority }}
                    </template>
                  </Column>
                  <Column :header="$t('maintenance.comment')" :sortable="false">
                    <template #body="slotProps">
                      {{ slotProps.data.comment }}
                    </template>
                  </Column>
                  <Column
                    body-style="text-align: center; overflow: visible"
                    header-style=" width: 180px; text-align: center">
                    <template #body="slotProps">
                      <div class="d-flex justify-content-end align-items-center">
                        <router-link
                          :to="{name: 'maintenanceDetails', params: {maintenanceId: slotProps.data.id}}"
                          class="btn btn-xs btn-inverse-primary mr-1">
                          <i class="fe fe-eye" />
                        </router-link>
                      </div>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- DIALOG CREATE -->

    <template #extra>
      <AkDialog
        ref="dialogCreate"
        :title="$t('maintenance.add')"
        :validate-label="$t('add')"
        width="800px"
        @validate="createMaintenance()">
        <div class="form-row">
          <AkDropdown
            v-if="this.isGas()"
            v-model="maintenanceCreate.companyId"
            :label="$t('employee.company')"
            :options="companies"
            option-label="name"
            option-value="id"
            :validator="v$.maintenanceCreate.companyId"
            class-name="col-6" />
        </div>
        <!-- Recherche l'hôte + logement en dropdown -->
        <div class="form-row">
          <AkOwnerAutocomplete
            ref="ownerAccommodationAutocomplete"
            v-model="ownerId"
            :companyId="maintenanceCreate.companyId"
            :label="$t('maintenance.owner')"
            :placeholder="$t('maintenance.owner')"
            :validator="v$.ownerId"
            :required="true"
            :disabled="!this.isOwnerAccommodationInputAvailable"
            :userEnabled="true"
            class-name="col-6" />
          <AkDropdown
            v-model="maintenanceCreate.accommodationId"
            :label="$t('maintenance.accommodationId')"
            :options="accommodations"
            option-label="name"
            option-value="id"
            :validator="v$.maintenanceCreate.accommodationId"
            :disabled="!ownerId"
            class-name="col-6" />
        </div>
        <div class="form-row">
          <AkDropdown
            v-model="maintenanceCreate.interlocutorTypes"
            :label="$t('interlocutor.interlocutorType')"
            :options="maintenanceInterlocutorTypeConst"
            optionLabel="label"
            optionValue="value"
            :validator="v$.maintenanceCreate.interlocutorTypes"
            :required="true"
            class-name="col-4" />
          <AkOwnerAutocomplete
            v-if="isOwnerInputAvailable"
            ref="ownerAutocomplete"
            v-model="maintenanceCreate.ownerId"
            :companyId="maintenanceCreate.companyId"
            :accommodationId="maintenanceCreate.accommodationId"
            :label="$t('maintenance.discovery_person')"
            :placeholder="$t('maintenance.discovery_person')"
            :validator="v$.maintenanceCreate.ownerId"
            :required="true"
            :disabled="true"
            :userEnabled="true"
            class-name="col-8" />
          <AkClientAutocomplete
            v-if="isClientInputAvailable"
            ref="clientAutocomplete"
            v-model="maintenanceCreate.clientId"
            :companyId="maintenanceCreate.companyId"
            :label="$t('maintenance.discovery_person')"
            :placeholder="$t('maintenance.discovery_person')"
            :validator="v$.maintenanceCreate.clientId"
            :required="true"
            class-name="col-8" />
          <AkPrestataireAutocomplete
            v-if="isPrestataireInputAvailable"
            ref="prestataireAutocomplete"
            v-model="maintenanceCreate.prestataireId"
            :companyId="maintenanceCreate.companyId"
            :label="$t('maintenance.discovery_person')"
            :placeholder="$t('maintenance.discovery_person')"
            :validator="v$.maintenanceCreate.prestataireId"
            :required="true"
            class-name="col-8" />
          <AkEmployeeAutocomplete
            v-if="isEmployeeInputAvailable"
            ref="employeeAutocomplete"
            v-model="maintenanceCreate.employeeId"
            :companyId="maintenanceCreate.companyId"
            :label="$t('maintenance.discovery_person')"
            :placeholder="$t('maintenance.discovery_person')"
            :validator="v$.maintenanceCreate.employeeId"
            :required="true"
            class-name="col-8" />
          <AkPartenaireAutocomplete
            v-if="isPartenaireInputAvailable"
            ref="partenaireAutocomplete"
            v-model="maintenanceCreate.partenaireId"
            :companyId="maintenanceCreate.companyId"
            :label="$t('maintenance.discovery_person')"
            :placeholder="$t('maintenance.discovery_person')"
            :validator="v$.maintenanceCreate.partenaireId"
            class-name="col-8" />
          <AkHousekeeperAutocomplete
            v-if="isHousekeeperInputAvailable"
            ref="housekeeperAutocomplete"
            v-model="maintenanceCreate.housekeeperId"
            :companyId="maintenanceCreate.companyId"
            :label="$t('maintenance.discovery_person')"
            :placeholder="$t('maintenance.discovery_person')"
            :validator="v$.maintenanceCreate.housekeeperId"
            :disabled="this.isHousekeeper()"
            class-name="col-8" />
        </div>
        <div class="form-row">
          <AkCalendar
            v-model="maintenanceCreate.dateMaintenance"
            :label="$t('maintenance.date_maintenance')"
            :placeholder="$t('maintenance.date_maintenance')"
            date-format="dd/mm/yy"
            selection-mode="single"
            :validator="v$.maintenanceCreate.dateMaintenance"
            :maxDate="new Date()"
            class-name="col-6" />
        </div>
        <div class="form-row">
          <AkInputText
            v-model="maintenanceCreate.problem"
            :label="$t('maintenance.problem')"
            :placeholder="$t('maintenance.problem')"
            :validator="v$.maintenanceCreate.problem"
            class-name="col-12" />
        </div>
        <div class="form-row">
          <AkInputTextArea
            v-model="maintenanceCreate.description"
            :label="$t('maintenance.description')"
            :placeholder="$t('maintenance.description')"
            :validator="v$.maintenanceCreate.description"
            class-name="col-12" />
        </div>
        <div class="form-row">
          <AkFilesUpload
            v-model="files"
            :label="$t('maintenance.photos')"
            class-name="col-md-12"
            accept=".png, .jpeg, .jpg"
            style="margin-bottom: 15px" />
        </div>
        <div class="form-row">
          <AkInputTextArea
            v-model="maintenanceCreate.reference"
            :label="$t('maintenance.reference')"
            :placeholder="$t('maintenance.reference')"
            :validator="v$.maintenanceCreate.reference"
            class-name="col-12" />
        </div>
      </AkDialog>
    </template>
  </GaView>
</template>
