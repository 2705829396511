<script>
import $ from 'jquery';

/* COMPOSANTS */
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import GaMessages from '@components/ga/layout/GaMessages';

/* MIXINS */
/* SERVICES */

export default {
  components: {AkFormSubmitted, GaMessages},
  props: {
    title: {type: String, required: true},
    width: {type: String, default: '600px'},
    cancelLabel: {type: String, default: undefined},
    validateLabel: {type: String, default: undefined},
    autoHideOnValidate: {type: Boolean, default: false},
    autoHideOnCancel: {type: Boolean, default: true},
    showValidateButton: {type: Boolean, default: true},
    showCancelButton: {type: Boolean, default: true},
  },
  emits: ['show', 'hide', 'cancel', 'validate'],
  data() {
    return {
      display: false,
      submitted: false,
    };
  },
  computed: {
    buildCancelLabel() {
      return this.cancelLabel ? this.cancelLabel : this.$t('cancel');
    },
    buildValidateLabel() {
      return this.validateLabel ? this.validateLabel : this.$t('validate');
    },
  },
  mounted() {},
  methods: {
    show() {
      this.submitted = false;
      this.setDisplay(true);
      this.$emit('show');
    },
    hide() {
      this.setDisplay(false);
      this.$emit('hide');
    },
    cancel() {
      if (this.autoHideOnCancel) this.setDisplay(false);
      this.$emit('cancel');
    },
    validate() {
      this.submitted = true;
      if (this.autoHideOnValidate) this.setDisplay(false);
      this.$emit('validate');
    },
    setDisplay(value) {
      this.display = value;
    },
    success(msg) {
      this.$refs.messages.success(msg);
      this.scrollTop();
    },
    error(msg) {
      this.$refs.messages.error(msg);
      this.scrollTop();
    },
    resetMessages() {
      this.$refs.messages.resetMessages();
    },
    scrollTop() {
      $('.p-dialog-content').scrollTop(0);
    },
  },
};
</script>

<template>
  <PDialog v-model:visible="display" :header="title" :modal="true" :style="{width: width}" @hide="cancel">
    <GaMessages ref="messages" />
    <AkFormSubmitted :submitted="submitted" reference="form">
      <slot />
    </AkFormSubmitted>

    <template #footer>
      <div class="d-flex justify-content-end">
        <span v-if="this.showCancelButton" class="btn btn-outline-primary btn-xs" @click="cancel">
          {{ buildCancelLabel }}
        </span>
        <span v-if="this.showValidateButton" class="btn btn-primary btn-xs ml-1" @click="validate">
          {{ buildValidateLabel }}
        </span>
      </div>
    </template>
  </PDialog>
</template>
