import Api from '@/services/api';
import {createFormDataFiles, removeEmptyParams} from '@utils';
import moment from 'moment';

export default {
  ownerCharges(filter) {
    return Api()
      .get('/ownercharges', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  ownerCharge(id) {
    return Api()
      .get('/ownercharge/' + id)
      .then((res) => {
        return this.formatRes(res.data);
      });
  },

  create(ownerCharge, files) {
    let data = createFormDataFiles(ownerCharge, files);
    return Api()
      .post('/ownercharge', data, {
        transformRequest: (data) => {
          return data;
        },
      })
      .then((res) => this.formatRes(res.data));
  },
  validate(id) {
    return Api()
      .get('/ownercharge/validate/' + id)
      .then((res) => {
        return this.formatRes(res.data);
      });
  },
  updateAndValidate(ownerCharge, files) {
    let data = createFormDataFiles(ownerCharge, files);
    return Api()
      .put('/ownercharge/update/' + ownerCharge.id, data, {
        transformRequest: (data) => {
          return data;
        },
      })
      .then((res) => this.formatRes(res.data));
  },
  deleteOwnerCharge(id) {
    return Api()
      .get('/ownercharge/delete/' + id)
      .then((res) => {
        return this.formatRes(res.data);
      });
  },
  update(ownerCharge) {
    return Api()
      .put('/ownercharge/' + ownerCharge.id, ownerCharge)
      .then((res) => this.formatRes(res.data));
  },
  delete(ownerCharge) {
    return Api()
      .delete('/ownercharge/' + ownerCharge.id)
      .then(() => ownerCharge);
  },
  formatRes(e) {
    if (e.date) {
      e.date = moment(e.date).utc(true).toDate();
    }
    return e;
  },
};
