export default {
  data() {
    return {
      labelODachatHote: [],

      labelODdeepCleaning: ['Deep cleaning'],

      labelODforfaitVerificationMenageHote: ['Forfait vérification ménage après départ hôte'],

      labelODfraisAnnulationHote: [
        "Forfait d'annulation à l'initiative de l'hôte et commission d'une réservation annulée",
      ],

      labelODfraisDeMaintenance: [
        "Déplacement pour l'intervention d'un plombier",
        "Déplacement pour l'intervention d'un éléctricien",
        "Déplacement pour l'intervention d'un artisan",
        "Déplacement pour l'intervention d'un installateur fibre optique",
        "Déplacement pour la réception d'un colis au logement",
      ],

      labelODfraisDeMenageHote: ["Ménage réalisé par Guestadom au départ de l'hôte "],

      labelODnuisanceSonore: ['Abonnement mensuel nuisance sonore '],

      labelODremboursementHote: ['Remboursement litige', 'Geste commercial', 'Avoir', 'Trop perçu'],

      labelODremboursementVoyageur: ['Relocation du voyageur', 'Réclamation du voyageur'],

      labelODfraisDeMaintenanceOld: ['Frais de maintenance (ancien)'],

      labelODforfaitHivernage: ['Forfait ouverture hivernage', 'Forfait fermeture hivernage'],
      labelOdOuvertureHivernage: ['Forfait ouverture hivernage'],
      labelOdFermetureHivernage: ['Forfait fermeture hivernage'],
    };
  },
};
