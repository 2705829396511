import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  paginationATraiter(filter) {
    return Api()
      .get('/mission-dashboard/pagination/atraiter', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach(this.formatRes);
        let response = {};
        response.data = res.data;
        response.totalItems = parseInt(res.headers['x-pagination-total']);
        return response;
      });
  },
  paginationPlanifie(filter) {
    return Api()
      .get('/mission-dashboard/pagination/planifie', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach(this.formatRes);
        let response = {};
        response.data = res.data;
        response.totalItems = parseInt(res.headers['x-pagination-total']);
        return response;
      });
  },
  formatRes(e) {
    return e;
  },
};
