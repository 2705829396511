export const en = {
  app_name: 'GuestAdom',
  welcome: 'Welcome !',
  'welcome-title': 'Register your password now to discover your host portal and estimate your rental income.',
  home: 'Dashboard',
  back: 'Retour',
  start: 'Démarrer',
  export: 'Exporter',
  export_done: 'Export terminé',
  exporting: "En cours d'export",
  currently_loading: 'En cours de chargement',
  delete_dialog: 'Confirmation de suppression',
  delete_confirm: 'Etes-vous sur de vouloir supprimer {item} ?',
  field_required: 'Le champ {0} est requis',
  error_min_date: 'Le champ {0} dépasse la date minimale autorisée',
  error_max_date: 'Le champ {0} dépasse la date maximale autorisée',
  bad_format: "Le format du champ {0} n'est pas correct",
  check_phone_number: 'Merci de vérifier le format du numéro de téléphone',
  check_iban_number: "Merci de vérifier le format du numéro d'iban",
  check_bic_number: 'Merci de vérifier le format du numéro de bic',
  key_already_used: 'Ce champ est déjà utilisé',
  max_length: 'Le champ {0} doit faire au maximum {1} caractères',
  min_length: 'Le champ {0} doit faire au minimum {1} caractères',
  min_max_length: 'Le champ {0} doit faire entre {1} et {2} caracteres',
  login_not_found: 'Utilisateur inconnu, merci de vérifier vos identifiants',
  bad_pwd: 'Merci de vérifier votre mot de passe',
  reset_pwd_err: 'Les deux mots de passe doivent être identiques',
  jwt_expire: 'Délai dépassé, merci de recommencer',
  unknow_error: 'Une erreur est survenue',
  date_error: 'Please check the start and end dates',
  sign_in: 'Se connecter',
  contracts: 'Contracts/Amendments',
  restrictions: 'Restrictions',
  unauthorized_cleaner: "'Cleaner' profile unauthorized",
  epi: 'PPE',
  absences: 'Absences',
  activities: 'Activities',
  unavailabilities: 'Fixed unavailabilities',
  notification: 'Notifications',
  files: 'Attachments',
  filesNbr: 'Files number',
  pwd_forget: 'Mot de passe oublié ?',
  accommodation_title: 'Accommodation',
  date_start: 'Start date',
  date_end: 'End date',
  dashboard: {
    accommodation_status: 'Statuts de vos logements',
    avenants_to_sign: 'Avenant au contrat à signer',
    accommodation_reviews: 'Avis de vos logements',
  },
  user: {
    list: 'Utilisateurs',
    details: 'Fiche utilisateur',
    one: 'Utilisateur',
    empty: 'Aucun utilisateur',
    name: 'Nom',
    firstname: 'Prénom',
    lastname: 'Nom',
    maidenName: 'Maiden Name',
    password: 'Mot de passe',
    email: 'E-mail',
    roleId: 'Rôle',
    enabled: 'Actif',
    add: 'Ajouter un utilisateur',
    update: 'Modifier un utilisateur',
  },
  company: {
    list: 'Agences',
    details: 'Fiche agence',
    one: 'Agence',
    empty: 'Aucune agence',
    name: 'Raison sociale',
    email: 'E-mail',
    address1: 'Adresse',
    address2: 'Complément',
    postalCode: 'Code postal',
    city: 'Ville',
    country: 'Pays',
    enabled: 'Active',
    siret: 'Siret',
    rcs: 'RCS',
    rcsCity: 'Ville RCS',
    ape: 'Code APE',
    urssaf: 'URSSAF',
    urssafZone: 'Région URSSAF',
    juridique: 'Forme juridique',
    capital: 'Capital',
    numTva: 'N° TVA Intracommunautaire',
    numAgrement: 'N° Agrément',
    dateAgrement: "Date d'agrément",
    numDeclaration: 'N° de déclaration',
    dateDeclaration: 'Date de déclaration',
    civility: 'Civilité',
    ownerLastName: 'Nom',
    ownerFirstName: 'Prénom',
    phone1: 'Téléphone',
    phone2: 'Fax',
    add: 'Ajouter une agence',
    update: 'Modifier une agence',
    updateThis: 'Modifier',
  },
  role: {
    //SUPPORT
    traveller: 'Voyageur',
    relation_manager: 'Chargé de clientèle voyageur',
    accountant: 'Comptable voyageur',
    owner: 'Hôte',
    owner_relation_manager: 'Chargé de clientèle propriétaire',
    income_management: 'Revenue management',
    income_manager: 'Revenue Manager',
    assistant_income_manager: 'Assistant revenu manager',
    general_administration_and_accounting: 'Admin / Compta Générale',
    administrative_manager: 'Responsable administratif',
    executive_assistant: 'Assistant de direction',
    administrative_assistant: 'Assistant administratif',
    accountant_assistant: 'Assistant comptable',
    mandant_accounting: 'Comptabilité Mandante',
    accountant_assistant_mandant: 'Assistant comptable mandant',
    accountant_mandant: 'Comptable mandant',
    sales: 'Sales',
    head_of_sales: 'Head of Sales',
    sales_development_representative: 'Sales Development Representative',
    sales_operations: 'Sales Operations',
    marketing_and_communication: 'Com / Marketing',
    communication_manager: 'Chargé de communication',
    marketing_and_communication_manager: 'Responsable Marketing / Communication',
    communication_assistant: 'Assistant communication',
    top_management: 'Top management',
    ceo: 'CEO',
    coo: 'COO',
    country_manager: 'Country Manager',
    project_manager: 'Chef de projet',
    technical_division: 'Pôle Tech',
    digital_project_manager: 'Digital Project Manager',
    //VILLE
    poste: 'Poste',
    city_manager: 'City Manager',
    assistant_city_manager: 'Assistant city manager',
    business_developer: 'Business Developer',
    cleaner: 'Agent polyvalent',
    welcomer: 'Welcomer',
    household_assistant: 'Assistant ménager',

    other: 'Other',
    administrator: 'Administrator',
    photographer: 'Photographer',
    onboarding_manager: 'Onboarding Manager',
    administrative_and_accounting_manager: 'Administrative and Accounting Manager',
  },
  management_type: {
    professional: 'Professional',
    non_professional: 'Non-professional',
  },
  accommodation: {
    list: 'Logements',
    add: 'Ajouter un logement',
    empty: 'Aucun logement',
    description: 'Description',
    status: 'Status',
    available: 'Available',
    maintenance: 'Under maintenance',
    hostReservation: 'Host reservation',
    rented: 'Rented',
    accommodation_creation_in_progress: 'File creation in progress',
    not_send_to_smily: 'Ne pas envoyer sur Smily',
    all: 'Tous les logements',
    enabled_only: 'Uniquement les logements actifs',
    disabled_only: 'Uniquement les logements inactifs',
    advert: {
      reviews: 'Avis de votre logement',
      reviews_global_rating: 'Note globale',
      reviews_nb_total: "Nombre total d'avis déposés",
      reviews_all: 'Consulter tous les avis',
    },
  },
  prospect: {
    errorNoId: "This prospect doesn't have a user account",
    checkin_person: 'Personne en charge des accueils',
    checkout_person: 'Personne en charge des départs',
    prestataire_name: 'Nom du prestataire',
    all_prospect: 'Tous les prospects',
    enabled_only: 'Uniquement les prospects actifs',
    disabled_only: 'Uniquement les prospects inactifs',
  },
  owner: {
    all: 'Tous les hôtes',
    enabled_only: 'Uniquement les hôtes actifs',
    disabled_only: 'Uniquement les hôtes inactifs',
  },
  employee: {
    files: 'Attachments',
    add_file: 'Add an attachment',
    file_added: 'Added attachment',
    file_deleted: 'Deleted attachment',
    file_confirm_delete: 'Are you sure you want to delete this attachment ?',
    activity: 'Activity',
    contracts: 'Contracts/Amendments',
    civility: 'Civility',
    firstName: 'First name',
    lastName: 'Last name',
    type: 'Type',
    maidenName: 'Maiden name',
    birthdate: 'Birthdate',
    birthPlace: 'Birth place',
    birthDepartment: 'Birth department',
    birthCountry: 'Birth country',
    maritalStatus: 'Marital status',
    childrenNumber: 'Children number',
    source: 'Knowledge source',
    address: 'Address',
    additionalAddress: 'Additional address',
    postalCode: 'Postal code',
    country: 'Country',
    city: 'City',
    phoneNumber: 'Phone number',
    mobileNumber: 'Mobile number',
    personalInformation: 'Personal information',
    contractInformation: 'Contract/Addendum information',
    contact: 'Contact information',
    identityDocument: 'Identity document',
    idNumber: 'ID number',
    idIssuedAt: 'Issued at',
    idValidUntil: 'Valid until',
    nationality: 'Nationality',
    socialSecurityNumber: 'Social security number',
    drivingPermit: 'Driving permit',
    drivingPermitNumber: 'Driving permit number',
    drivingPermitObtainingDate: 'Driving permit obtaining date',
    drivingPermitValidityDate: 'Driving permit validity date',
    registeredEmploymentCenter: 'Registered with the employment center',
    employmentCenterMembershipNumber: 'Employment center membership number',
    professionalInformation: 'Professional Information',
    seniorityDate: 'Seniority date',
    entryDate: 'Entry date',
    entryHour: 'Entry hour',
    dpaeNumber: 'DPAE number',
    lastMedicalVisitDate: 'Last medical visit date',
    nextMedicalVisitDate: 'Next medical visit date',
    usualTransportMode: 'Usual transport mode',
    personalVehicle: 'Personal vehicle',
    companyVehicle: 'Company vehicle',
    bicycle: 'Bicycle',
    onFoot: 'On foot',
    publicTransport: 'Public transport',
    subscribedToComplementaryHealth: 'Subscribed to complementary health',
    motif: 'Motif',
    iban: 'IBAN',
    bic: 'BIC',
    handicappedWorkerRecognition: 'Handicapped worker recognition',
    recognitionGrantedByTheCDAPH: 'Recognition granted by the CDAPH',
    rqth: 'RQTH',
    aah: 'AAH',
    disabilityCard: 'Disability card (min. 80%)',
    validityStartDate: 'Validity start date',
    validityEndDate: 'Validity end date',
    renewalApplicationSubmissionDate: 'Renewal application submission date',
    disabilityRate: 'Disability rate',
    disabilityCauses: 'Victim of work accident, occupational disease or disabled pensioner',
    typology: 'Typology',
    disabilityValidityStartDate: 'Validity start date',
    IPPRate: 'IPP rate',
    category: 'Category',
    otherCategories: 'Other categories',
    otherCategory: 'Other category',
    pensionStartDate: 'Pension start date',
    skills: 'Skills',
    languages: 'Languages',
    graduate: 'Graduate',
    diplomas: 'Diplomas',
    married: 'Married',
    divorced: 'Divorced',
    widower: 'Widower',
    single: 'Single',
    maritalLife: 'Marital life',
    pacs: 'Pacs',
    separated: 'Separated',
    typology1: 'Accident at work / Occupational disease',
    typology2: 'Invalid pensioner',
    otherCategory1: 'War disabled',
    otherCategory2: 'Assimilated war disabled',
    otherCategory3: 'Volunteer firefighter',
    motif1: 'You already have mutual insurance as a beneficiary (collective coverage)',
    motif2: 'You already have a mutual (individual complementary)',
    motif3: 'You are already in the company when setting up the system',
    motif4: 'You benefit from CMUC or ACS',
    motif5: 'You are a very part-time employee',
    motif6: 'You are on fixed-term contract or on a mission contract (up to 3 months)',
    motif7: 'You are on fixed-term contract or on a mission contract (between 3 months and 1 year)',
    motif8: 'You are on fixed-term contract or on a mission contract of 1 year or more',
    motif9: 'You are an apprentice',
    category1: 'First category',
    category2: 'Second category',
    category3: 'Third category',
    job: 'Job',
    multiEmployer: 'Multi-employer',
    employer1: 'Employer 1',
    employer2: 'Employer 2',
    employer3: 'Employer 3',
    employer4: 'Employer 4',
    employer5: 'Employer 5',
    duration: 'Duration',
    geosector: 'Geographical sectors of intervention',
    filetype: 'File type',
    commitmentEndDate: 'Commitment end date',
    bankingInformation: 'Banking information',
    unavailability_add: 'Ajouter une indisponibilité',
  },
  reservation: {
    number: 'Numéro de réservation',
    state_reversed: 'Reservations paid',
    state_not_reversed: 'Reservations unpaid',
    payment: 'Payment',
    payment_details: "Payment's details",
    reservation_site: 'OTA',
    pending_refund: 'Pending refund',
    cancelled: 'Cancelled',
    cancelled_creation_in_progress: 'Cancelled creation in progress',
    error_export: 'An error occurs while exporting',
    error_no_cancelled: 'No cancellation to export',
  },
  accounting: {
    informations: 'Informations',
    stay_rate: 'Groupe de taxe de séjour',
    operations: 'Operations',
    account: 'Comptes',
    balance: 'Balance',
    payment: 'Payment',
    visitor_tax: 'Visitor tax',
    third_party_account: 'Third-party accounts',
    third_party_details: 'Third party account details',
    bank_reconciliation: 'Bank reconciliation',
    bank_reconciliation_details: 'Bank reconciliation details',
    date: 'Operation date',
    label: 'Label',
    debit: 'Flow',
    credit: 'Credit',
    progressive_balance: 'Progressive balance',
    empty: "The account's list is empty",
    total: 'Total',
    number: 'Account number',
    type: 'Type',
    consultation: 'Consultation',
    free_od: 'Saisie OD libre',
    error_not_mandat: 'The account must be a mandat one',
    error_not_resa: 'The account must be a reservation one',
    error_not_for_online: 'Cette transaction ne peut être facturée sur une offre Online',
  },
  balance: {
    balance_creation_in_progress: "Balance's creation in progress",
    show_resa_account: "Reservation's accounts balance null",
    show_mandats_terminated: "Mandat terminated's accounts ",
    unknown_template: 'Missing template for exportation',
  },
  operation: {
    consultation: 'Consultation',
    recurring_charges: 'Recurring charges',
    validate_owner_charge: 'Opérations à valider',
    account_from: 'Account from',
    account_to: 'Account to',
    date_created: 'Date created',
    date_end: 'Date end',
    date_paused: 'Date paused',
    date_updated: 'Date updated',
    date_validated: 'Date validated',
    mandat: 'Mandat',
    monthly: 'Monthly',
    none: 'None',
    add: 'Add an OD',
    add_line: 'Add new line',
    delete_line: 'Delete line',
    delete_line_warning: 'Delete line?',
    limit_size_line: "Can't delete, any transaction is at least two lines long",
    type: 'Type',
    number: 'Account number',
    added: 'Operation added',
    added_free_od: 'Free OD added',
    added_bank_reconciliation: 'Bank reconciliation added',
    date: 'Date',
    label: 'Label',
    amount: 'Amount',
    amount_GAS: 'Amount GAS',
    amount_GAV: 'Amount GAV',
    amount_owner: 'Amount owner',
    amount_commission: 'Amount commission',
    amount_annulation: 'Amount annulation',
    comment: 'Comment',
    minutes: 'Travel time',
    minute: 'minutes',
    ota: 'OTA',
    empty: 'No operation to display',
    empty_bank_reconciliation: 'Aucun rapprochement bancaire à afficher',
    empty_date: "Operation's date must be filled in",
    empty_account_num: "Account's number must be filled in",
    empty_label: 'Label must be filled in',
    empty_amount: 'An amount is expected, either in credit or debit',
    od1: 'Frais de lancement',
    od2: 'Commission',
    od3: 'Frais de ménage voyageur',
    od4: "Frais d'accueil",
    od5: 'Extra voyageurs',
    od6: 'Frais de ménage hôte',
    od7: 'Vérification ménage hôte',
    od8: 'Frais de déplacement',
    od9: 'Achat hôte',
    od10: "Forfait - Frais d'annulation hôte",
    od11: "Commission - Frais d'annulation hôte",
    od12: "Commission - Frais d'annulation voyageur",
    od13: 'Frais de service GA',
    od14: 'Frais de maintenance',
    od15: 'Acompte',
    od16: 'Avoir',
    od17: 'Trop perçu',
    od18: 'Frais de blanchisserie',
    od19: 'Virement OTA',
    VIREMENT_TDS: 'TDS transfert',
    VIREMENT_CRG: "CRG's transfert",
    od20: "D'un compte à un compte",
    od21: "Caution (OD d'encaissement)",
    od22: "OD d'encaissement remboursement champs libre",
    od23: "Forfait - 'vérification' ménage hôte",
    od24: 'Nuisance sonore',
    od25: 'Deep cleaning',
    od26: 'Remboursement voyageur',
    od27: 'Remboursement hôte',
    od28: 'Litigation',
    od29: 'Claim',
    CANCEL_FEE: "Frais d'annulation",
    CANCEL: 'Annulation',
    MAINTENANCE: 'Maintenance',
    revert: 'Reject payment',
    payment: 'Payment',
    tds: 'Stayrate',
    fstripe: 'Stripe fee',
    free_od: 'Free OD',
    rebalance: 'Rebalance',
    recurrence_period: 'Recurrence',
    recurrence: 'Recurrence',
    reservation: 'Reservation',
    update: 'Update',
    delete: 'Delete',
    pause_recurring: 'Pause',
    pause_recurring_back: 'Active',
    compta_charge_paused: 'Recurrence paused',
    compta_charge_actived: 'Recurrence actived',
    compta_charge_deleted: 'Recurrence deleted',
    compta_charge_modify: 'Modify recurring charge',
    compta_charge_updated: 'Recurring charge updated',
    create_bank_reconciliation: 'Add a bank reconciliation',
    transaction_free_od: ' FREE OD TRANSACTION',
    error_amount: 'Le montant ne peux contenir que des chiffres, virgules ou points',
    error_transaction: "Transaction's amount is not valid, debit and credit must match each other",
    error_reject_more_paid: "Rejected amount can't be higher than paid amount",
    error_price_less: "Paid amount can't be higher than price",
    error_already_payed: 'Payment has already been done',
    error_no_minutes: 'Travel time must be more than 0',
    error_update: 'A problem occurs during update',
    error_delete: 'A problem occurs during delete',
    error_add_bank_reconciliation: 'A problem occurs while creating reconciliation',
    undefined: 'Undefined',
    delete_warning_recurrence: 'Delete this recurring charge?',
  },
  owner_cancel: {
    item: 'Annulation hôte',
    empty: 'Aucune annulation hôte à afficher',
    date_cancel: "Date d'annulation",
    details: "Détails de l'annulation",
    guest_contacted: 'Guest contacté',
    guest_refunded: 'Remboursement voyageur fait',
    status_updated: 'Le statut a été mis à jour',
    GUEST_TO_CONTACT: 'Guest à contacter',
    GUEST_TO_REFUND: 'Remboursement voyageur à faire',
    DONE: 'Annulation traitée',
  },
  facturation_hote: {
    filter: 'Filtrer',
    facturation_hote: 'Facturation hôte',
    owner_charge: 'Charge hôte',
    type: 'Type de facturation',
    ACHAT_HOTE: 'Achat hôte',
    FRAIS_DE_MAINTENANCE: 'Frais de maintenance',
    FORFAIT_HIVERNAGE: 'Forfait hivernage',
    OUVERTURE_HIVERNAGE: 'Forfait ouverture hivernage',
    FERMETURE_HIVERNAGE: 'Forfait fermeture hivernage',
    FRAIS_DE_MENAGE_HOTE: 'Frais de ménage hôte',
    status: 'Statut',
    IN_PROGRESS: 'En cours',
    VALIDATED: 'Validée',
    DELETED: 'Supprimée',
    amount: 'Montant',
    motif: 'Motif',
    deplacement_minutes: 'Temps de déplacement (minutes)',
    date_created: 'Date de création',
    date_transaction: "Date d'opération",
    date_intervention: "Date d'intervention",
    date_purchase: "Date d'achat hôte",
    type_operation: "Type d'opération",
    add_owner_charge: 'Ajouter une charge hôte',
    empty: 'Aucune charge hôte à afficher',
    owner_charge_added: 'Charge hôte ajoutée',
    owner_charge_validated: 'Charge hôte validée',
    owner_charge_deleted: 'Charge hôte supprimée',
    validate: 'Valider une charge hôte',
    update: 'Modifier une charge hôte',
    update_validate: 'Modifier et valider',
    delete: 'Supprimer une charge hôte',
    files_deleted: 'Le fichier a bien été supprimé',
    confirm_validate: 'Êtes-vous sûr de vouloir valider cette charge hôte?',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer cette charge hôte?',
    confirm_delete_file: 'Êtes-vous sûr de vouloir supprimer ce fichier?',
    error: 'Une erreur est survenue',
    error_already_validated: 'Cette charge hôte est déjà validée',
    error_add: "Une erreur est survenue lors de l'ajout de charge hôte",
    error_delete: 'Une erreur est survenue lors de la suppression',
    error_validate: 'Une erreur est survenue lors de la validation',
    error_files_missing: "Le fichier n'existe pas",
    empty_motif_deplacement: "Les motifs de déplacement doivent être parametrés par l'admin",
    empty_raison_achat_hote: "Les raisons d'achat hôte doivent être parametrées par l'admin",
  },
  owner_charge: {
    list: 'Achats hôtes',
    empty: 'Aucun achat hôte validé',
    IN_PROGRESS: 'En cours',
    VALIDATED: 'Validé',
    DELETED: 'Supprimé',
    error_empty_date_transaction: "Impossible de valider une charge hôte sans date d'opération",
  },
  account: {
    mandant: 'Principal accounting',
    number: 'Account number',
    empty: 'No operation found on this account',
    progressive_balance: 'Progressive balance',
    consultation: 'Consultation',
    no_exist: 'This account number does not exist',
    no_cancel_fee: 'No cancel fee define for this mandat',
    no_operations: 'No operations founds for this account',
    no_mandat: 'A mandat does not exist',
    already_exist: 'This account already exist',
    balance: 'Balance',
    third_party_creation_in_progress: "Third party accounts's creation in progress",
    fic_mandat_creation_in_progress: "Mandat's register's creation in progress",
    fic_avenant_creation_in_progress: 'Registre des avenants en cours de création',
    account_creation_in_progress: 'Consultation en cours de création',
    all_third_party: 'Tous les comptes de tiers',
    enabled_only_third_party: 'Uniquement les comptes de tiers actifs',
    disabled_only_third_party: 'Uniquement les comptes de tiers inactifs',
    mandat_resiliated: 'Mandats résiliés',
  },
  visitor_tax: {
    visitor_tax: 'Stayrate',
    tds: 'TDS',
    date: "Reservation's date",
    number: "Reservation's number",
    group: 'Stayrate group',
    amount: 'Stayrate amount',
    regional_amount: 'Stayrate regional amount',
    total: 'Total stayrate',
    status: 'Status',
    transfert_num: "Transfert's number",
    update: "TDS's transfert",
    update_transfert_num: 'Num Virement',
    filter_group: 'Search by stayrate group',
    filter_region: 'Search by ragional stayrate',
    filter_date: 'Search by date',
    filter_status: 'Search by status',
    date_from: 'From',
    date_to: 'To',
    date_created: 'Date created',
    filter: 'Search',
    total_reversed: 'Total reversed',
    total_not_reversed: 'Total not reversed',
    state_reversed: 'Reversed',
    state_not_reversed: 'Not reversed',
    select_all: 'Select all',
    submit: 'Generate transfert',
    transfert: "Stayrate's transfert",
    transferts: 'Transferts',
    total_not_selected: 'Total',
    total_selected: 'Total selected',
    empty_stayrate_group: "Stayrate group doesn't have an account",
    error_stayrate_account: 'Le compte de la TDS ne corresponds pas à celui du groupe de TDS',
    error_already_reversed: 'Une TDS selectionnée à déjà été reversée',
    error_empty_selection: 'Aucune TDS selectionnée dans ce lot',
    type: 'Type de TDS',
    details: "Stayrate transfert's details",
    date_start: 'Date start',
    date_end: 'Date end',
    date_checkin: 'Checkin',
    date_checkout: 'Checkout',
    period: 'Period of time',
    to: 'to',
    stayrate_group_added: 'Stayrate group saved',
    error_not_same_group: 'Le lot selectionné appartient à plusieurs groupes de TDS différent',
    stayrate_export_in_progress: 'Export des TDS en cours',
    undefined: 'Undefined',
  },
  refund: {
    date: 'Date',
    refund: 'Rembourser',
    add: 'Rembourser',
    added: 'Remboursement ajouté',
    amountGuest: 'Montant remboursé au voyageur',
    amountGuestAdom: 'Montant conservé par Guestadom',
    change_amount: 'Le montant du remboursement à été modifié',
    change_amount_confirm: 'Le montant à rembourser à été modifié. Confirmer le remboursement?',
  },
  payment: {
    euros: '€',
    statut: 'Statut',
    filter: 'Search',
    filter_accommodation: 'Search by accommodation',
    filter_avantio_localizer: 'Search by reservation number',
    filter_owner: 'Search by host',
    filter_mandat: 'Search by mandat',
    filter_state_reversed: 'Search by state',
    reset_filter: 'Reset filters',
    total: 'Total tax',
    remaining_payment: 'Remaining payment',
    no_payment: 'No payment yet',
    next_operations: 'Payments to come',
    past_operations: 'Payments done',
    fully_paid: 'Payment complete',
    action: 'Action',
    payment: 'Pay',
    add_payment: 'New payment',
    reject_payment: 'Reject payment',
    reject: 'Reject',
    reject_disclaimer1: 'Attention, rejected amount',
    reject_disclaimer2: 'For partial rejection, please create a free OD.',
    rejected_payment: 'Reject payment added',
    rejected_reason: "Reject's reason",
    rejected_amount: "Reject's amount",
    rejected_date: "Reject's date",
    add: 'Add',
    owner_tds: 'Owner TDS',
    guest_tds: 'Guest TDS',
    guest_adom_tds: 'Guest Adom TDS',
    commission_ota: 'OTA commission',
    housework: 'Housework',
    amount: 'Amount',
    amount_to_refund: 'Montant à rembourser',
    amount_fee: 'Fee amount',
    amount_by_night: 'Montant de la nuit',
    amount_by_night_percent: '30% montant de la nuit',
    amount_stayrate: 'Montant TDS',
    amount_owner_tds: 'TDS owner',
    amount_guest_tds: 'TDS guest',
    amount_guest_adom_tds: 'TDS GUEST ADOM',
    amount_tds_paid: 'Montant TDS payé',
    amount_owner_tds_paid: 'Montant TDS payé hôte',
    amount_guest_tds_paid: 'Montant TDS payé voyageur',
    amount_guest_adom_tds_paid: 'Montant TDS payé Guest Adom',
    amount_tds_total: 'Montant TDS total',
    amount_commission: 'Montant de la commission OTA',
    extra: 'Extra',
    stripe_fee: 'Frais STRIPE',
    who: 'Stayrate paid by',
    extra_paid: 'Extra already paid?',
    recurrence: 'Recurrence',
    monthly: 'Monthly',
    none: 'None',
    date: 'Date',
    days_left_before_start: 'Days left before start',
    date_start_end: 'Date start - Date end',
    source: 'Source',
    method: 'Payment method',
    transfer_num: 'Transfert number',
    added: 'Payment added',
    vacation_check: 'Vacation check',
    credit_card: 'Credit card',
    virtual_card: 'Virtual card',
    transfert: 'Transfert',
    check: 'Check',
    stripe: 'Stripe',
    other: 'Other',
    download: 'Download',
    download_pdf: 'Download PDF',
    download_excel: 'Excel',
    download_transfert: 'Export transfert',
    number_results: 'Number of results: ',
    state_reversed: 'State',
    success_update: 'Transaction updated',
    error_no_payment_reservation: 'No payment to reject for this reservation',
    error_no_reservation: 'No reservation found',
    error_transaction_closed: 'Transaction closed',
  },
  fee: {
    update: 'Modifier',
    period: 'Quinzaine',
    period_start: 'Début de quinzaine',
    period_end: 'Fin de quinzaine',
    reservationNb: 'Nb de réservations',
    empty: 'Aucune commission',
    stayTax: 'Taxe de séjour',
    plateformFee: 'Frais de plateforme',
    amountStay: 'Montant de séjour',
    gain: 'Gain',
    gainGa: 'Gain de Guestadom',
    gainGas: 'Gain de Guestadom Support',
    gainGav: 'Gain de Guestadom Ville',
    gainOwner: "Gain de l'hôte",
    feeStart: 'Frais de lancement',
    feeStartGas: 'Frais de lancement (GAS)',
    feeStartGav: 'Frais de lancement (GAV)',
    feeWelcome: "Frais d'accueil",
    fee_operationel_owner: 'Frais opérationnel hôte',
    fee_housework_interlocatif: 'Frais de ménage interlocatif',
    fee_housework_interlocatif_owner: "Pris en charge par l'hôte",
    fee_housework_interlocatif_voyageur: 'Pris en charge par le voyageur',
    feeHousework: 'Frais de ménage voyageur',
    feeHouseworkOwner: 'Forfait ménage départ hôte',
    feeService: 'Frais de service',
    feeCancel: "Frais d'annulation GAS",
    feeLaundry: 'Frais de blanchisserie',
    fee_assurance: "Frais d'assurance",
    fee_wintering_open: 'Frais ouverture hivernage',
    fee_wintering_close: 'Frais fermeture hivernage',
    clientToOwner: "A verser à l'hôte",
    clientToGav: 'A verser à GAV (voyageur)',
    clientToGas: 'A verser à GAS (voyageur)',
    ownerToGav: 'A verser à GAV (hôte)',
    ownerToGas: 'A verser à GAS (hôte)',
    startDate: 'Date de début',
    endDate: 'Date de fin',
  },
  bank_reconciliation: {
    error_cant_create: "Can't create a new reconciliation until last one is validate",
    empty_operation: 'No operations in this bank reconciliation',
    empty_reconciliated: 'No operations reconciliated',
    empty_not_reconciliated: 'No operations to reconciliate',
    operations_flagged: 'Operations added',
    operations_to_reconciliate: 'Operations to reconciliate',
    operations_reconciliated: 'Reconciliated operations',
    validate: 'Validate',
    details: 'Details',
    difference: 'Difference',
    update_reconciliation: 'Update',
    add_operation: 'Add',
    add_operations: 'Add operations',
    save_operations: 'Save',
    amount: "Bank reconciliation's amount",
    amount_reconciliated: 'Montant déjà rapproché',
    remove_operation: 'Remove operation',
    delete_warning: 'Delete reconciliation?',
    delete_reconciliation: 'Delete bank reconciliation',
    success_update: 'Bank reconciliation updated',
    success_delete: 'Bank reconciliation deleted',
    success_remove_operation: 'Operation removed from bank reconciliation',
    success_remove_operations: 'Operations removed from bank reconciliation',
    cant_delete: "Can't delete a reconciliation which have been validated",
    success_validate: 'Bank reconciliation validated',
    error_update: 'A problem occurs while updating',
    error_details: 'A probleme occurs while fetching operations',
    error_wrong_amount: "Reconciliation's amount dont match operations's total",
    error_remove_operations: 'A problem occurs while removing operations',
    error_no_operations: "Can't validate a reconciliation without operations",
    error_validate: 'A problem occurs while validating',
    error_delete: 'A problem occurs while deleting',
    error_empty_selected: 'No operations selected',
    error_add: 'A problem occurs while adding operations',
  },
  recapitulatif_facturation: {
    recapitulatif: 'Récapitulatif de facturation',
    partenaire: 'Partenaire',
    partenaire_GAV_HT: 'Partenaire GAV HT',
    partenaire_GAV_TTC: 'Partenaire GAV TTC',
    type: 'Type',
    gas_ht: 'GAS HT',
    gas_ttc: 'GAS TTC',
    total_ht: 'Total HT',
    total_ttc: 'Total TTC',
    display_not_closed: 'OD non clôturées',
    offer_all_inclusive: 'Offre Premium',
    offer_online: 'Offre Essentielle',
    label: 'Libellé',
    total_amount: 'Montant total',
    guest_adom: 'Guest adom',
    date: 'Date',
    add: 'Nouvelle grille tarifaire',
    empty: 'Aucune donnée à afficher',

    achat_hote_type: 'Achat hôte (Type)',
    achat_hote_gas: 'Achat hôte (Guest adom)',
    achat_hote_gav: 'Achat hôte (Partenaire)',

    commission_type: 'Commission (Type)',
    commission_gas: 'Commission (Guest adom)',
    commission_gav: 'Commission (Partenaire)',

    commission_frais_annulation_hote_type: "Commission frais d'annulation hôte (Type)",
    commission_frais_annulation_hote_gas: "Commission frais d'annulation hôte (Guest adom)",
    commission_frais_annulation_hote_gav: "Commission frais d'annulation hôte (Partenaire)",

    commission_frais_annulation_voyageur_type: "Commission frais d'annulation voyageur (Type)",
    commission_frais_annulation_voyageur_gas: "Commission frais d'annulation voyageur (Guest adom)",
    commission_frais_annulation_voyageur_gav: "Commission frais d'annulation voyageur (Partenaire)",

    deep_cleaning_type: 'Deep cleaning (Type)',
    deep_cleaning_gas: 'Deep cleaning (Guest adom)',
    deep_cleaning_gav: 'Deep cleaning (Partenaire)',

    fermeture_hivernage_type: 'Fermeture hivernage (Type)',
    fermeture_hivernage_gas: 'Fermeture hivernage (Guest adom)',
    fermeture_hivernage_gav: 'Fermeture hivernage (Partenaire)',

    forfait_frais_annulation_hote_type: "Forfait frais d'annulation hote (Type)",
    forfait_frais_annulation_hote_gas: "Forfait frais d'annulation hote (Guest adom)",
    forfait_frais_annulation_hote_gav: "Forfait frais d'annulation hote (Partenaire)",

    forfait_verification_menage_hote_type: 'Forfait vérification ménage hôte (Type)',
    forfait_verification_menage_hote_gas: 'Forfait vérification ménage hôte (Guest adom)',
    forfait_verification_menage_hote_gav: 'Forfait vérification ménage hôte (Partenaire)',

    frais_accueil_type: "Frais d'accueil (Type)",
    frais_accueil_gas: "Frais d'accueil (Guest adom)",
    frais_accueil_gav: "Frais d'accueil (Partenaire)",

    frais_assurance_type: "Frais d'assurance (Type)",
    frais_assurance_gas: "Frais d'assurance (Guest adom)",
    frais_assurance_gav: "Frais d'assurance (Partenaire)",

    frais_blanchisserie_type: 'Frais de blanchisserie (Type)',
    frais_blanchisserie_gas: 'Frais de blanchisserie (Guest adom)',
    frais_blanchisserie_gav: 'Frais de blanchisserie (Partenaire)',

    frais_deplacement_type: 'Frais de déplacement (Type)',
    frais_deplacement_gas: 'Frais de déplacement (Guest adom)',
    frais_deplacement_gav: 'Frais de déplacement (Partenaire)',

    frais_lancement_type: 'Frais de lancement (Type)',
    frais_lancement_gas: 'Frais de lancement (Guest adom)',
    frais_lancement_gav: 'Frais de lancement (Partenaire)',

    frais_maintenance_type: 'Frais de maintenance (Type)',
    frais_maintenance_gas: 'Frais de maintenance (Guest adom)',
    frais_maintenance_gav: 'Frais de maintenance (Partenaire)',

    frais_menage_hote_type: 'Frais de ménage hôte (Type)',
    frais_menage_hote_gas: 'Frais de ménage hôte (Guest adom)',
    frais_menage_hote_gav: 'Frais de ménage hôte (Partenaire)',

    frais_menage_voyageur_type: 'Frais de ménage voyageur (Type)',
    frais_menage_voyageur_gas: 'Frais de ménage voyageur (Guest adom)',
    frais_menage_voyageur_gav: 'Frais de ménage voyageur (Partenaire)',

    frais_service_ga_type: 'Frais de service GA (Type)',
    frais_service_ga_gas: 'Frais de service GA (Guest adom)',
    frais_service_ga_gav: 'Frais de service GA (Partenaire)',

    frais_operationnel_type: 'Frais opérationnel (Type)',
    frais_operationnel_gas: 'Frais opérationnel (Guest adom)',
    frais_operationnel_gav: 'Frais opérationnel (Partenaire)',

    nuisance_sonore_type: 'Nuisance sonore (Type)',
    nuisance_sonore_gas: 'Nuisance sonore (Guest adom)',
    nuisance_sonore_gav: 'Nuisance sonore (Partenaire)',

    ouverture_hivernage_type: 'Ouverture hivernage (Type)',
    ouverture_hivernage_gas: 'Ouverture hivernage (Guest adom)',
    ouverture_hivernage_gav: 'Ouverture hivernage (Partenaire)',

    status: 'Statut',
    success_add: 'Grille tarifaire ajoutée',
    error_empty_filter: 'La période doit être renseigné',
    error_empty_filter_param: "L'agence doit être renseignée",
  },
  crg: {
    crg: 'CRG',
    consultation: 'Consultation',
    generation: 'Generation',
    payment: 'Virements',
    historique: 'Historical',
    payment_crg: "CRG's transfert",
    date: 'Date',
    date_resa_end: 'Date du crg',
    date_generated: 'Date de génération',
    date_closed: 'Date de clôture',
    number: 'Nombre de CRGs',
    label: 'Libellé',
    success_update_num_virement: "Transfert's number updated",

    generate_one: 'Générer',
    regenerate_one: 'Regénérer',
    close_one: 'Clôturer',

    generate_many: 'Générer les CRGs',
    regenerate_many: 'Regénérer les CRGs',
    close_many: 'Clôturer les CRGs',

    confirm_generate_one: 'Êtes-vous sûr de vouloir générer un nouveau CRG ?',
    confirm_regenerate_one: 'Êtes-vous sûr de vouloir regénérer ce CRG ?',
    confirm_close_one: 'Êtes-vous sûr de vouloir clotûrer ce CRG ?',

    confirm_generate_many: 'Êtes-vous sûr de vouloir générer de nouveaux CRGs ?',
    confirm_regenerate_many: 'Êtes-vous sûr de vouloir regénérer ces CRGs ?',
    confirm_close_many: 'Êtes-vous sûr de vouloir clotûrer ces CRGs ?',

    generated_one: 'CRG généré',
    regenerated_one: 'CRG regénéré',
    closed_one: 'CRG clôturé',

    generated_many: 'CRGs générés',
    regenerated_many: 'CRGs regénérés',
    closed_many: 'CRGs clôturés',

    error_missing_iban: "Clôture impossible. L'IBAN est manquant sur le mandat ",
  },
  stayrate: {
    frequency: 'Périodicité de versement',
    frequency_month_6: '6 mois',
    frequency_year_1: '1 an',
    type: 'Kind of stayrate',
    regional: 'Regional',
    city: 'City',
    avantio_label: 'Avantio label',
  },
  search: {
    results: 'Résultats de la recherche',
    here: 'Rechercher ici',
    search: 'Rechercher',
    filter: 'Filtrer',
  },
  ticket: {
    prospect_display: 'Nom du prospect',
    client_display: 'Nom du client',
    category: 'Catégorie de la demande',
    reason: 'Raison',
    amount_ttc: 'Montant TTC',
    commission_ga: 'Commission GA',
    attributed_to_me: 'me sont attribués',
    validate_resolution: 'Valider la résolution',
    refuse_resolution: 'Refuser la résolution',
    resolution_refused: 'Résolution du ticket refusée',
    resolution_validated: 'Résolution validée',
    validate: 'Valider',
    refusal_comment: 'Commentaire de refus',
    confirm_validate_resolution: 'Êtes-vous sûr de vouloir valider la résolution de ce ticket ?',
    error: 'Une erreur est survenue',
    error_empty_files: 'Le champ Fichier est requis',
    error_no_owner_pole: "Une erreur est survenue lors de la création d'indisponibilité",
    error_empty_refusal_comment: 'Le commentaire de refus est requis',
    error_validate_not_closed: 'Impossible de valider un ticket non fermé',
  },
  mandat: {
    add: 'Créer un mandat',
    details: 'Mandat',
    fee_owner_cancel: "Frais d'annulation",
    update: 'Modifier un mandat',
    update_bank_info: 'Modifier les infos bancaires',
    bank_info_updated: 'Les infos bancaires ont bien été modifiées',
    updated: 'Mandat modifié',
    empty: 'Aucun mandat',
    added: 'Mandat ajouté',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer ce mandat ?',
    documentModel: 'Modèle de document',
    num: 'Numéro de mandat',
    type: 'Type de mandat',
    duration: 'Durée de mandat',
    renew: 'Tacite reconduction',
    dateStart: 'Début de mandat',
    datePeriodNew: 'Début de la nouvelle période',
    datePeriod: 'Période depuis le',
    dateEnd: 'Fin de mandat',
    dateReception: 'Réception de la lettre',
    datePreavis: 'Date de début de préavis',
    type_courte_duree: 'Courte durée',
    type_moyenne_duree: 'Moyenne durée',
    type_longue_duree: 'Longue durée',
    type_gestion_en_ligne: 'Gestion en ligne',
    COURTE_DUREE: 'Courte durée',
    MOYENNE_DUREE: 'Moyenne durée',
    LONGUE_DUREE: 'Longue durée',
    GESTION_EN_LIGNE: 'Gestion en ligne',
    duration_year1: '1 an',
    duration_year3: '3 ans',
    duration_year5: '5 ans',
    amountThreshold: 'Minimum de facturation',
    comGa: 'Commission totale (%)',
    comGas: 'Commission GAS (%)',
    comGav: 'Commission GAV (%)',
    file_signed: 'Fichier PDF signé',
    iban: 'IBAN',
    bic: 'BIC',
    gestionLinge: 'Gestion du linge',
    reception_type_physical: 'Gestion des accueils physiques',
    resiliation: 'Résiliation du mandat',
    resilation_action: 'Résilier',
    lettreResiliation: 'Courrier de résiliation',
    feeHouseworkOwnerForfait: 'Forfait ménage départ hote ',
    new_mandat: 'Nouveau mandat',
    prixMinimumNuit: 'Prix minimum à la nuit',
    depotGarantie: 'Caution',
    caution: 'Caution',
    dureeSejourMinimum: 'Durée de séjour minimum',
    status: 'Statut',
    num_avenant: "Numéro d'avenant",
    date_start_avenant: "Début de l'avenant",
    date_end_avenant: "Fin de l'avenant",
    duration_avenant: "Durée de l'avenant",
    add_avenant: 'Créer un avenant',
    avenant_added: 'Avenant ajouté',
    avenant_date_start: "Date de début d'avenant",
    mandat_list: 'Mandats',
    avenant_list: 'Avenants',
    RESILIE: 'Résilié',
    EN_LIGNE: 'En ligne',
    EN_COURS_DE_SIGNATURE: 'En cours de signature',
    housework_person: 'Personne en charge du ménage',
    all_avenants: 'Tous les avenants',
    all: 'Tous les mandats',
    enabled_only: 'Uniquement les mandats actifs',
    disabled_only: 'Uniquement les mandats inactifs',
    resiliation_pending: 'Uniquement les mandats en cours de résiliation',
    en_cours: 'Uniquement les mandats en cours',
    enabled_only_avenant: 'Uniquement les avenants actifs',
    disabled_only_avenant: 'Uniquement les avenants inactifs',
    resiliation_pending_avenant: 'Uniquement les avenants en cours de résiliation',
    en_cours_de_signature_avenant: 'Uniquement les avenants en cours de signature',
    last_crg_amount: 'Solde du dernier CRG',
    alert_last_crg_amount: 'Le solde du dernier crg est négatif',
    add_resiliation_bloc: 'Ajouter une période de blocage',
  },
  maintenance: {
    list: 'Maintenances',
    details: 'Fiche maintenance',
    one: 'Maintenance',
    empty: 'Aucune maintenance',
    add: 'Nouvelle maintenance',
    added: 'La maintenance a bien été ajoutée',
    update: 'Modifier une maintenance',
    updated: 'La maintenance a bien été modifiée',
    updateThis: 'Modifier',
    confirm_delete: 'Etes-vous sûr de vouloir supprimer cette maintenance ?',
    responsable: 'Responsable',
    repairer: 'Réparateur',
    repairer_artisan: 'Artisan',
    repairer_gav: 'GAV',
    cost: 'Coût',
    responsable_guest: 'Client',
    responsable_usure: 'Usure',
    responsable_salary: 'Salarié',
    accommodationId: 'Logement associé',
    companyName: 'GA Ville',
    date_maintenance: 'Date de la découverte',
    date_resolution: 'Date de la résolution',
    tag: 'Tag',
    status: 'Statut',
    ownerChoice: "Choix de l'hôte",
    action: 'Action',
    resolve: 'Résoudre',
    acknowledged: 'La maintenance a bien été classifiée',
    resolved: 'La maintenance a bien été résolue',
    resolve_maintenance: 'Résoudre la maintenance',
    trait: 'Traiter la maintenance',
    trait_maintenance: 'Traiter la maintenance',
    traited: 'La maintenance a bien été traitée',
    refuse_maintenance: "Maintenance gérée par l'hôte",
    accept_maintenance: 'Maintenance gérée par Guest Adom',
    refus_comment: 'Raison du refus',
    refus: 'Refusée',
    refused: "La maintenance sera gérée par l'hôte",
    accept: 'Acceptée',
    accepted: 'La maintenance sera gérée par Guest Adom',
    managed_by: 'Maintenance gérée par :',
    start: 'Démarrer la maintenance',
    started: 'La maintenance a bien été démarrée',
    usure: 'Usure',
    litige: 'Litige',
    discovery_person: 'Personne qui a fait la découverte',
    problem: 'Problème',
    description: 'Description',
    photos: 'Photos',
    error_empty_photos: 'Le champ Photos est requis',
    reference: 'Marque / référence équipement',
    comment: 'Commentaire',
    comment_treatment: 'Commentaire sur le traitement',
    priority: 'Priorité',
    priority_urgent: 'Urgent',
    priority_high: 'Haut',
    priority_medium: 'Normal',
    priority_low: 'Bas',
    a_resoudre: 'A résoudre',
    a_traiter: 'A traiter',
    en_attente: 'En attente',
    decision_prise: 'Decision prise',
    en_cours: 'En cours',
    resolue: 'Résolue',
    amount_estimated: 'Montant estimé',
    amount_ttc: 'Montant TTC',
    link_url: 'Lien URL',
    silent: "Mode silencieux (ne pas notifier l'hôte)",
    file: 'Pièce jointe',
    error_empty_files: 'Le champ Fichier est requis',
    description_resolution: 'Description de la résolution',
    amount_ht: 'Montant HT',
    amount_gas: 'Imputé à GAS',
    amount_gav: 'Imputé à GAV',
    amount_owner: "Imputé à l'hôte",
    error_total_amount: 'La somme des montants saisis ne correspond pas au montant total TTC renseigné',
  },
  assigned_staff: {
    prestataire: 'Prestataire',
    intern: 'Interne',
    housekeeper: 'Concierge',
    partner: 'Partenaire',
  },
  planification: {
    owner: "Nom de l'hôte",
    reservation: 'Réservation actuelle',
    next_reservation: 'Réservation suivante',
    last_reservation: 'Réservation précédente du logement',
    last_comment_prestataire: 'Commentaire intervenant',
    accommodation_address: 'Adresse du logement',
    date_created_reservation: 'Date création réservation',
    laundry_included: 'Linge inclus',
    checkin_autonome: 'Arrivée autonome',
    checkout_autonome: 'Départ autonome',
    extras: 'Extras commandés',
    extra: 'Extra',
    personnel_affected: 'Personel affecté',
    prestataire: 'Prestataire',
    intern: 'Interne (salariés GA)',
    housekeeper: 'Concierge',
    start_hour: 'Heure de début',
    duration: 'Durée',
    comment: 'Commentaire',
    price: 'Tarif',
    number_staff: 'Nombre de personne affecté',
  },
  mission_order: {
    title: 'Ordres de mission',
    title_create: 'Créer des ordres de mission',
    create: "Créer l'ordre de mission",
    empty: 'Aucun ordre de mission',
    accommodation: 'Logement',
    reservation: 'Numéro de réservation',
    document_model: 'Modèle de contrat',
    assigned_staff: 'Personnel affecté',
    date: 'Date prévue',
    start_hour: 'Heure de début',
    duration: 'Durée prévue',
    comment: 'Commentaire',
    price: 'Tarif',
    number_staff: 'Nombre de personne(s) affectée(s)',
    offer_price: 'Tarif de la prestation n°',
    comment_on_offer: 'Commentaire de la prestation n°',
    error_missing_fields: 'Un ou plusieurs champs obligatoires ne sont pas renseignés.',
    error_no_reservation:
      'Vous devez choisir une réservation pour pouvoir créer des missions "Check-in" et/ou "Check-out"',
    tabs: {
      all: 'Tous les ordres',
      proposed: 'Proposée',
      accepted: 'Acceptée',
      completed: 'Terminée',
      litigation: 'Litige',
      canceled: 'Annulée',
    },
    mission_status: 'Statut de la mission',
    nb_participants: "Nombre d'intervenants",
    date_created: "Date de l'ordre",
    date_start: 'Date de début de la prestation',
    date_cancel: "Date d'annulation",
    canceller: 'Personne qui a annulé la mission',
    type: 'Type de mission',
  },
  mission_order_details: {
    planned_mission_duration: 'Durée de mission prévue',
    edit_success: 'La mission a bien été modifiée',
    cancel_success: 'La mission a bien été annulée',
    repropose_success: 'La mission a bien été remise en ligne',
  },
  waiting_orders: {
    title: "Réservation en attente d'ordre",
    period: 'Période',
    new: 'Nouvelle mission',
    empty: "Aucune réservation en attente d'ordre",
    company: 'Agence',
    accommodation: 'Logement',
    reservation: 'Numéro de réservation',
    client: 'Nom du voyageur',
    owner: 'Propriétaire',
    accommodation_address: 'Adresse du logement',
    date_created: 'Date création réservation',
    date_start: 'Date de début',
    date_end: 'Date de fin',
    client_infos: 'Informations voyageur',
    create: 'Créer des missions',
  },
  settings: {
    jobs: 'Jobs',
    knowledgesource: 'Knowledge sources',
    geosector: 'Geographical sectors of intervention',
    filetype: 'File types',
    contracts: 'Contracts',
    epitype: 'EPI types',
    travel_expenses: 'Travel expenses',
    document_balance: 'Balance',
    recapitulatif_facturation: 'Récapitulatif de facturation',
    achat_hote_types: "Types d'achat hôte",
    raison_achat_hote: "Raison d'achat hôte",
    motif_deplacement: 'Motif de déplacement',
    mandat_cluster_rm: 'Cluster RM',
    resiliation_reason: 'Raisons de résiliation',
    cancel_rule: "Règle d'annulation",
    cancel_rule_added: "Règle d'annulation ajoutée",
    cancel_rule_empty: "Aucune règle d'annulation",
    cancel_rule_updated: "La règle d'annulation à bien été modifiée",
    confirm_delete_cancel_rule: "Êtes-vous sûr de vouloir supprimer cette règle d'annulation?",
    ticket_category: 'Catégorie de ticket',
    ticket_category_added: 'Catégorie de ticket ajoutée',
    ticket_category_empty: 'Aucune catégorie de ticket',
    ticket_category_updated: 'La catégorie de ticket à bien été modifiée',
    add_ticket_category: 'Ajouter une catégorie de ticket',
    update_ticket_category: 'Modifier une catégorie de ticket',
    delete_ticket_category: 'Effacer une catégorie de ticket',
    confirm_delete_ticket_category: 'Êtes-vous sûr de vouloir supprimer cette catégorie de ticket ?',
    error_empty_field: 'Tous les champs sont requis',
    description: 'Description',
    mandats_empty: 'Aucun mandats de gestion',
    avenants_empty: 'Aucun avenants',
  },
  sub_category: {
    create: 'Ajouter une sous-catégorie',
    sub_category: 'Sous-catégorie',
    added: 'Sous-catégorie ajoutée',
    updated: 'Sous-catégorie modifiée',
    delete_sub_category: 'Effacer une sous-catégorie',
    confirm_delete_sub_category: 'Êtes-vous sûr de vouloir supprimer cette sous-catégorie ?',
  },
  travel_expenses: {
    amount: 'Hourly rate',
  },
  onboardingsuivi: {
    waiting_validation_guestadom: 'Etape en attente de validation par le support Guestadom',
    add_unavailability: 'Ajouter une indisponibilité',
    error_document_required: "Tout les documents sont requis pour passer à l'étape suivante",
    error_no_mandat: "L'onboarding n'a pas de mandat",
    error_no_mandat_num: "Le mandat relié n'a pas de numéro de mandat",
    error_mandat_num_duplicated: 'Ce numéro de mandat existe déjà',
    success_add_unavailability:
      'Votre indisponibilité à bien été prise en compte, le pôle hôte va traiter votre demande. Vous pourrez voir votre indisponibilité une fois la demande traitée dans votre calendrier',
  },
  amenity: {
    antique: 'Antique',
    art_deco: 'Art Déco',
    atypique: 'Atypique',
    baroque: 'Baroque',
    bois: 'Bois',
    contemporain: 'Contemporain',
    ecologique: 'Ecologique',
    empire: 'Empire',
    historique: 'Historique',
    hi_tech: 'Hi-tech',
    minimaliste: 'Minimaliste',
    romantique: 'Romantique',
    victorien: 'Victorien',

    bouteille_eau: "Bouteilles d'eaux",
    cave: 'Cave',
    cuisine_equipee: 'Cuisine équipée',
    cuisiniere: 'Cuisinière',
    eau_chaude: 'Eau chaude',
    eau_filtree: 'Eau filtrée',
    fontaine_a_eau: 'Fontaine à eau',
    kitchenette: 'Kitchenette',
    machine_a_glace: 'Machine à glace',
    mixeur: 'Mixeur',
    toaster: 'Toaster',
    verres_a_vin: 'Verres à vin',

    adaptee_aux_enfants: 'Adapté aux enfants',
    babyphone: 'Babyphone',
    baignoire_bebe: 'Baignoire bébé',
    balancoire: 'Balançoire',
    chaise_haute: 'Chaise haute',
    convient_aux_bebes: 'Convient aux bébés (moins de 2 ans)',
    convient_aux_enfants: 'Convient aux enfants (2 ans ou plus)',
    garde_robe: 'Garde robe',
    jeux_pour_enfants: 'Jeux pour enfants',
    jeux_pour_tous_ages: 'Jeux pour tous âges',
    lit_bebe: 'Lit bébé',
    lit_parapluie: 'Lit parapluie',
    livres_pour_enfants: 'Livres pour enfants',
    mariage: 'Mariage',
    piscine_enfants: 'Piscine enfants',
    recommandation_de_babysitter: 'Recommandation de babysitter',
    salle_de_jeux: 'Salle de jeux',
    table_a_langer: 'Table à langer',
    vaiselle_pour_enfants: 'Vaisselle pour enfants',

    espace_de_travail: 'Espace de travail pour ordinateur portable',
    bureau_de_travail: 'Bureau de travail',
    chargeur_ev: 'Chargeur EV',
    ordinateur_disponible: 'Ordinateur disponible',
    reveil: 'Réveil',
    satellite_ou_cable: 'Satellite ou cable',
    systeme_stereo: 'Système stéréo',
    telephone: 'Téléphone',
    wifi_portable: 'Wi-fi portable',

    air_conditionne: 'Air conditionné',
    bar_prive: 'Bar privé',
    bowling_prive: 'Bowling privé',
    chauffeur: 'Chauffeur',
    chef_cuisinier_particulier: 'Chef cuisinier particulier',
    cinema: 'Cinéma',
    concierge: 'Concierge',
    depot_de_bagage: 'Dépôt de bagages autorisé',
    draps_et_serviettes: 'Draps et serviettes',
    helipad: 'Helipad',
    linge_de_luxe: 'Le linge de luxe',
    location_longue_duree: 'Location longue durée possible',
    majordome: 'Majordome',
    menage_inclus: 'Ménage inclus',
    menage_professionnel: 'Ménage professionnel',
    personnel_avec_equipement: 'Personnel avec équipement de protection',
    petit_dejeuner_inclus: 'Petit-déjeuner inclus',
    service_de_menage_quotidien: 'Service de ménage quotidien',
    ventilateurs_de_plafond: 'Ventilateurs de plafond',
    voiture_incluse: 'Voiture incluse',

    barriere_de_securite: 'Barrière de sécurité pour escalier',
    caches_prises: 'Caches-prises',
    consignes_de_securite: 'Consignes de sécurité',
    detecteur_de_monoxyde: 'Détecteur de monoxyde de carbone',
    distanciation_physique: 'Distanciation physique',
    extincteur: 'Extincteur',
    kit_de_premier_secours: 'Kit de premier secours',
    pare_feu: 'Pare-feu pour la cheminée',
    porte_chambre_avec_verrou: 'Porte de la chambre avec verrou',
    protections_coins_de_table: 'Protections sur les coins de tables',
    protections_fenetres: 'Protections sur les fenêtres',
    systeme_de_securite: 'Système de sécurité',

    affichage_pratiques_eco_responsables: 'Affichage des pratiques éco-responsables',
    aliments_et_boissons_bio: 'Aliments et boissons bios',
    appareils_basse_consommation: 'Appareils à basse consommation',
    changement_serviettes_sur_demande: 'Changement de serviettes sur demande',
    collecte_des_eaux_pluviales: 'Collecte des eaux pluviales',
    eco_construction: 'Eco-construction',
    eco_tourisme: 'Eco-tourisme',
    pas_d_article_de_toilette_individuel: "Pas d'article de toilette individuel",
    pas_de_plastique_a_usage_unique: 'Pas de plastique à usage unique',
    reduction_du_papier: 'Réduction du papier/papier recyclé',
    soutien_a_des_projets_durables: 'Soutien à des projets durables',
    systeme_energie_renouvelable: "Système d'énergie renouvelable",
    tri_des_dechets: 'Tri des déchets',

    acces_entree_bien_eclaire: "Accès à l'entrée bien éclairé",
    acces_plain_pied_chambre: 'Accès de plain-pied à la chambre',
    acces_plain_pied_salle_de_bain: 'Accès de plain-pied à la salle de bain',
    acces_plain_pied_espaces_communs: 'Accès de plain-pied aux espaces communs',
    acces_large_douche_et_wc: 'Accès large à la douche et au WC',
    acces_large_lit: 'Accès large au lit',
    acces_plat_porte_entree: "Accès plat à la porte d'entrée",
    baie: 'Baie',
    baignoire_avec_siege_de_bain: 'Baignoire avec siège de bain',
    barres_appui_douche: "Barres d'appui pour douche",
    barres_appui_wc: "Barre d'appui pour WC",
    couloir_large: 'Couloir large',
    douche_plain_pied: 'Douche de plain-pied',
    entree_large_salle_de_bain: 'Entrée large à la salle de bain',
    entree_large_espaces_communs: 'Entrée large aux espaces communs',
    leve_personne_mobile: 'Lève-personne mobile',
    leve_personne_plafonnier: 'Lève-personne plafonnier',
    lit_a_hauteur_accessible: 'Lit à hauteur accessible',
    lit_ajustable_en_hauteur: 'Lit ajustable en hauteur',
    piscine_avec_leve_personne: 'Piscine avec lève-personne',
    place_de_parking_handicapee: 'Place de parking handicapée',
    pomme_de_douche_amovible: 'Pomme de douche amovible',
    porte_large: 'Porte large',
    porte_large_chambre: 'Porte large pour la chambre',
    siege_de_douche: 'Siège de douche',
    toilettes_ajustables_en_hauteur: 'Toilettes ajustables en hauteur',

    gants_disponibles: 'Gants disponibles',
    gel_hydroalcoolique: 'Gel hydroalcoolique',
    laverie_automatique: 'Laverie automatique',
    menage_fait_avant_le_depart: 'Le ménage doit être fait avant le départ',
    masques_disponibles: 'Masques disponibles',
    materiel_de_repassage: 'Matériel de repassage',
    menage_et_desinfection: 'Ménage et désinfection',
    produits_de_base: 'Produits de base',
    produit_de_nettoyage: 'Produits de nettoyage',

    balcon: 'Balcon',
    cloture_entourant_piscine: 'Cloture entourant piscine',
    couverture_de_piscine: 'Couverture de piscine',
    entree_privee: 'Entrée privée',
    patio: 'Patio',
    piscine_a_debordement: 'Piscine à débordement',
    piscine_chauffee: 'Piscine chauffée',
    piscine_commune: 'Piscine commune',
    piscine_en_eau_salée: 'Piscine en eau salée',
    piscine_exterieure_saisonnier: 'Piscine extérieure (saisonnier)',
    piscine_interieure_saisonnier: 'Piscine intérieure (saisonnier)',
    piscine_peu_profonde: 'Piscine peu profonde',
    piscine_privative: 'Piscine privative',
    propriete_cloturee: 'Propriété cloturée',
    quai_a_bateau: 'Quai à bateau',
    residence_fermee: 'Résidence fermée',
    terrasse: 'Terrasse',

    proximite_terrain_de_golf: 'A proximité du terrain de golf',
    bord_de_lac: 'Bord de lac',
    bord_de_mer: 'Bord de mer',
    bord_de_plage: 'Bord de plage',
    campagne: 'Campagne',
    centre_ville: 'Centre-ville',
    domaine_viticole: 'Domaine viticole',
    fleuve_riviere: 'Fleuve/rivière',
    lac: 'Lac',
    montagne: 'Montagne',
    plage: 'Plage',
    proche_mer: 'Proche de la mer',
    ski_in: 'Ski In',
    ski_out: 'Ski Out',
    village: 'Village',
    village_vacances_tout_compris: 'Village vacances tout compris',
    ville: 'Ville',
    vue_mer: 'Vue mer',
    vue_montagne: 'Vue sur la montagne',
    vue_plage: 'Vue sur la plage',
    vue_lac: 'Vue sur le lac',
    vue_terrain_de_golf: 'Vue sur le terrain de golf',

    baignade: 'Baignade',
    balades_en_bateau: 'Balades en bateau',
    bateau: 'Bateau',
    bouee_tractee: 'Bouée tractée',
    canoe: 'Canoé',
    jet_ski: 'Jet-ski',
    kayak: 'Kayak',
    marina: 'Marina',
    paddle: 'Paddle',
    planche_a_voile: 'Planche à voile',
    plongee: 'Plongée',
    plongee_avec_tuba: 'Plongée avec tuba',
    sports_aquatiques: 'Sports aquatiques',
    surf: 'Surf',
    voile: 'Voile',
    produits_indipensables_plage: 'Produits indispensables pour la plage',

    alpinisme: 'Alpinisme',
    casier_a_skis: 'Casier à skis',
    luge: 'Luge',
    motoneige: 'Motoneige',
    patinage_sur_glace: 'Patinage sur glace',
    ski: 'Ski',
    ski_de_fond: 'Ski de fond',
    ski_nautique: 'Ski nautique',
    snowboard: 'Snowboard',

    chasse: 'Chasse',
    cours_de_tennis: 'Cours de tennis',
    equipement_fitness: 'Equipement fitness',
    salle_de_gym: 'Salle de gym',
    equitation: 'Equitation',
    escalade: 'Escalade',
    mountain_climbing: 'Escalade en montagne',
    rock_climbing: 'Escalade',

    golf: 'Golf',
    peche: 'Pêche',
    ping_pong: 'Ping Pong',
    rafting: 'Rafting',
    randonnee: 'Randonnée',
    table_de_billard: 'Table de billard',
    terrain_de_basketball: 'Terrain de basketball',
    terrain_de_petanque: 'Terrain de pétanque',
    cyclisme: 'Cyclisme',
    velo: 'Vélo',
    vtt: 'VTT',

    massage: 'Massage',
    hammam: 'Hammam',
    massage_sur_demande: 'Massage sur demande',
    salle_de_massage: 'Salle de massage',
    sauna: 'Sauna',
    solarium: 'Solarium',
    spa_a_proximite: 'Spa à proximitée',

    casino: 'Casino',
    magasins_antiquites: "Magasins d'antiquités",
    magasins_usine: "Magasins d'usine",
    magasins_duty_free: 'Magasins duty-free',
    musees: 'Musées',
    observation_faune_flore: 'Observation de la faune et de la flore',
    observation_des_baleines: 'Observation des baleines',
    observation_des_oiseaux: 'Observation des oiseaux',
    parachute_ascensionnel: 'Parachute ascensionnel',
    parc_aquatique: 'Parc aquatique',
    parc_attraction: "Parc d'attraction",
    shopping: 'Shopping',
    speleologie: 'Spéléologie',
    visites_de_vignobles: 'Visites de vignobles',

    bibliotheque_musicale: 'Bibliothèque musicale',
    videotheque: 'Vidéothèque',
  },
  notifications: {
    document_validated_for_gav: 'Les documents de {0} ont été approuvés',
    notif_ticket_to_be_validate: '{0} est en attente de validation de votre part pour le ticket {1}',
    notif_ticket_validated: 'Votre ticket {0} a été validé par {1}',
    notif_ticket_refused: 'Votre ticket {0} a été refusé par {1}',
    notif_litige_completed: 'Le dossier de litige de la réservation {0} est complet',
    notif_litige_complete_validated: 'Le dossier de litige de la réservation {0} a été validé',
    notif_litige_complete_refused: 'Le dossier de litige de la réservation {0} a été refusé',
  },
  pole: {
    item: 'Pole',
    list: 'Poles',
    name: 'Nom',
    technicalKey: 'Clé technique',
    empty: 'Aucun pole',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer ce pole ?',
    update: 'Modifier un pole',
    updated: 'Le pole a bien été modifié',
    add: 'Ajouter un pole',
    added: 'Le pole a bien été ajouté',
    delete: 'Supprimer un pole',
    error_pole_owner: 'An error occur while retrieving pole',
  },
  job: {
    job: 'Job',
    label: 'Label',
    empty: 'No jobs',
    add: 'Add a job',
    added: 'Successfully added job',
    update: 'Update a job',
    updated: 'Successfully updated job',
    confirm_delete: 'Are you sure you want to delete this job ?',
  },
  restriction: {
    restriction: 'Restriction',
    file: 'File',
    restrictionType: 'Type',
    empty: 'No restrictions',
    add: 'Add a restriction',
    added: 'Successfully added restriction',
    update: 'Update a restriction',
    updated: 'Successfully updated restriction',
    deleted: 'Successfully deleted restriction',
    confirm_delete: 'Are you sure you want to delete this restriction ?',
    startDate: 'Start date',
    endDate: 'End date',
    details: 'Specify',
    value: 'Value',
    restriction1: 'Maximum number of hours',
    restriction2: 'Halftime',
    restriction3: 'No carrying loads',
    restriction4: 'No stairs',
    restriction5: 'No contact with cleaning products',
    restriction6: 'No use of machines (mono brush type)',
    restriction7: 'No work on weekends and public holidays',
    restriction8: 'Mandatory break',
    restriction9: 'Workstation layout',
    restriction10: 'PPE (Personal Protective Equipment)',
    restriction11: 'Other',
  },
  absence: {
    absence: 'Absence',
    file: 'File',
    reason: 'Reason',
    empty: 'No absences',
    add: 'Add a absence',
    added: 'Successfully added absence',
    update: 'Update a absence',
    updated: 'Successfully updated absence',
    deleted: 'Successfully deleted absence',
    confirm_delete: 'Are you sure you want to delete this absence ?',
    startDate: 'Start date',
    startTime: 'Start time',
    endDate: 'End date',
    endTime: 'End time',
    replace: 'Replace?',
    substitute: 'Substitute',
    comment: 'Comment',
    abs1: 'Work accident',
    abs2: 'Non-occupational disease',
    abs3: 'Bereavement leave',
    abs4: 'Child disease',
    abs5: 'Serious illness child',
    abs6: 'Maternity',
    abs7: 'Paid Leave',
    abs8: 'Legal absence authorized without deduction',
    abs9: 'Other legal absence with deduction',
    abs10: 'Unpaid absence (authorized)',
    abs11: 'Unpaid absence (unauthorized)',
    abs12: 'Paid absence',
    abs13: 'Site closing',
    abs14: 'Travel accident',
    abs15: 'Work accident',
    abs16: 'Postnatal pathological leave',
    abs17: 'Prenatal pathological leave (14 days)',
    abs18: 'Pregnant woman exempted from work',
    abs19: 'Hospitalization Commuting accident',
    abs20: 'AT hospitalization',
    abs21: 'Hospitalization Non-occupational illness',
    abs22: 'Invalidity category 1',
    abs23: 'Disability category 2',
    abs24: 'Disability category 3',
    abs25: 'Long illness (public sector)',
    abs26: 'Long-term illness (public sector)',
    abs27: 'Non-occupational disease',
    abs28: 'Non-occupational disease (ALD)',
    abs29: 'Non-occupational illness (IJSSAT/multi-employer)',
    abs30: 'Occupational disease',
    abs31: 'Therapeutic part-time',
    abs32: 'Therapeutic part-time (AT)',
    abs33: 'Absence family event (without deduction)',
    abs34: 'Adoption',
    abs35: 'Conventional leave / partial maintenance',
    abs36: 'Conventional leave / total maintenance',
    abs37: 'Parental presence leave',
    abs38: 'Carer leave',
    abs39: 'Family solidarity leave',
    abs40: 'Parental leave',
    abs41: 'Bereavement leave',
    abs42: 'Child sickness',
    abs43: 'Severe spouse illness',
    abs44: 'Serious illness child',
    abs45: 'Maternity',
    abs46: 'Paternity',
    abs47: 'Paid Leave',
    abs48: 'Additional CPs',
    abs49: 'Additional CP 2',
    abs50: 'Holiday off',
    abs51: 'Bank time',
    abs52: 'Additional compensatory rest',
    abs53: 'Replacement compensator rest',
    abs54: 'Mandatory compensatory rest',
    abs55: 'Day pass rest',
    abs56: 'RTT',
    abs57: 'Absence of work-study training',
    abs58: 'Absence Unpaid training',
    abs59: 'Mobility leave (beyond notice period)',
    abs60: 'Reclassification leave (beyond notice period)',
    abs61: 'Retraining leave',
    abs62: 'Restructuring leave',
    abs63: 'Paid training leave',
    abs64: 'Individual training leave',
    abs65: 'Leave for skills assessment',
    abs66: 'Leave for union training',
    abs67: 'Leave for VAE',
    abs68: 'Legal absence authorized without deduction',
    abs69: 'Absence for Staff Representation',
    abs70: 'Other legal absence with deduction',
    abs71: 'Sabbatical leave',
    abs72: 'Secure Voluntary Mobility',
    abs73: 'International solidarity',
    abs74: 'Unpaid absence (authorized)',
    abs75: 'Unpaid absence (unauthorized)',
    abs76: 'Paid absence',
    abs77: 'Partial activity (team reduction)',
    abs78: 'Partial activity (full suspension)',
    abs79: 'Partial activity/ training',
    abs80: 'Concerted work stoppage (strike)',
    abs81: 'Bad weather unemployment',
    abs82: 'Partial unemployment (long-term partial activity)',
    abs83: 'Partial unemployment paid leave',
    abs84: 'Leave Without Pay',
    abs85: 'Temporary detention',
    abs86: 'Holiday not paid',
    abs87: 'Site closing',
    abs88: 'Conservative layoff',
    abs89: 'Disciplinary suspension',
    abs90: 'Notice not given',
    abs91: 'Advance notice not paid',
    abs92: 'Paid notice not given (mobility leave)',
    abs93: 'Not paid notice not given (reclassification leave)',
    abs94: 'Other',
  },
  unavailability: {
    unavailability: 'Fixed unavailability',
    label: 'Label',
    empty: 'No unavailability',
    add: 'Add an unavailability',
    added: 'Successfully added unavailability',
    update: 'Update an unavailability',
    updated: 'Successfully updated unavailability',
    deleted: 'Successfully deleted unavailability',
    confirm_delete: 'Are you sure you want to delete this unavailability ?',
    startDate: 'Start date',
    timeFrom: 'From',
    endDate: 'End date',
    timeTo: 'To',
    wholeDay: 'Whole day',
    weeksNbr1: 'Every',
    weeksNbr2: 'week(s), on',
    monday: 'M',
    tuesday: 'Tu',
    wednesday: 'W',
    thursday: 'Th',
    friday: 'F',
    saturday: 'Sa',
    sunday: 'Su',
    days: 'Days',
  },
  employee_unavailability: {
    unavailability: 'Indisponibilité fixe',
    label: 'Libellé',
    type: 'Type',
    comment: 'Commentaire',
    empty: 'Aucune indisponibilité fixe',
    manage: 'Gérer les indisponibilités fixes',
    add: 'Nouvelle indisponibilité fixe',
    edit: "Modifier l'indisponibilité fixe",
    remove: 'Supprimer une indisponibilité fixe',
    change: 'Changer une indisponibilité fixe',
    added: "L'indisponibilité fixe a bien été ajoutée",
    update: 'Modifier une indisponibilité fixe',
    updated: "L'indisponibilité fixe a bien été modifiée",
    deleted: 'Indisponibilité fixe annulée avec succés',
    confirm_delete:
      'Attention, vous souhaitez supprimer une indisponibilité fixe. Souhaitez-vous confirmer cette suppression ?',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    startDateNew: 'Nouvelle date de début',
    endDateNew: 'Nouvelle date de fin',
    wholeDay: 'Journée entière',
    timeFrom: 'De',
    timeTo: 'À',
    weeksNbr1: 'Toutes les',
    weeksNbr2: 'semaine(s), le',
    monday: 'L',
    tuesday: 'Ma',
    wednesday: 'Me',
    thursday: 'J',
    friday: 'V',
    saturday: 'S',
    sunday: 'D',
    days: 'Jours',
  },
  contract_settings: {
    contract: 'Contract',
    amendment: 'Amendment',
    amendment_add: 'Add an amendment',
    type: 'Type',
    label: 'Label',
    empty: 'No contracts',
    add: 'Add a contract',
    added: 'Successfully added contract',
    added_amendment: 'Successfully added amendment',
    update: 'Update a contract',
    updated: 'Successfully updated contract',
    confirm_delete: 'Are you sure you want to delete this contract/amendment ?',
    confirm_contract_delete: 'Are you sure you want to delete this contract ?',
    deleted: 'Successfully deleted contract/amendment',
  },
  item_label: {
    achat_hote_type: "Type d'achat hôte",
    confirm_delete_achat_hote_type: "Êtes-vous sûr de vouloir supprimer ce type d'achat hôte ?",
    achat_hote_type_added: "Le type d'achat hôte a bien été ajouté",
    achat_hote_type_empty: "Aucun type d'achat hôte",
    achat_hote_type_updated: "Le type d'achat hôte a bien été modifié",
    raison_achat_hote: "Raison d'achat hôte",
    confirm_delete_raison_achat_hote: "Êtes-vous sûr de vouloir supprimer cette raison d'achat hôte ?",
    raison_achat_hote_added: "La raison d'achat hôte a bien été ajoutée",
    raison_achat_hote_empty: "Aucune raison d'achat hôte",
    raison_achat_hote_updated: "La raison d'achat hôte a bien été modifiée",
    motif_deplacement: 'Motif de déplacement',
    confirm_delete_motif_deplacement: 'Êtes-vous sûr de vouloir supprimer ce motif de déplacement ?',
    motif_deplacement_added: 'Le motif de déplacement a bien été ajouté',
    motif_deplacement_empty: 'Aucun motif de déplacement',
    motif_deplacement_updated: 'Le motif de déplacement a bien été modifié',
    mandat_cluster_rm: 'Cluster RM',
    mandat_cluster_rm_added: 'Le cluster RM a bien été ajouté',
    mandat_cluster_rm_empty: 'Aucun cluster RM',
    mandat_cluster_rm_updated: 'Le cluster RM a bien été modifié',
    confirm_delete_mandat_cluster_rm: 'Êtes-vous sûr de vouloir supprimer ce cluster RM ?',
    error_item_label_cluster_used: 'Impossible de supprimer ce cluster, il est utilisé dans un mandat',
    error_item_label_achat_hote_used:
      "Impossible de supprimer cette raison d'achat, elle est utilisée dans une charge hôte",
    error_item_label_motif_deplacement_used:
      'Impossible de supprimer ce motif de déplacement, il est utilisé dans une charge hôte',
  },
  resiliation_reason: {
    list: 'Raisons de résiliation',
    item: 'Raison de résiliation',
    empty: 'Aucune raison de résiliation',
    update: 'Modifier une raison de résiliation',
    updated: 'La raison de résiliation a bien été modifiée',
    add: 'Ajouter une raison de résiliation',
    added: 'La raison de résiliation a bien été ajoutée',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer cette raison de résiliation ?',
    delete: 'Supprimer une raison de résiliation',
  },
  accommodationRole: {
    attribute: "M'attribuer",
    attributeTo: 'Attribuer à',
    unattribute: 'Retirer',
    chooseUser: 'Choisir un salarié',
    error_already_exist: 'Cette attribution existe déjà',
  },
  error: {
    USER_MAIL_ALREADY_EXISTS: 'Cette adresse mail est déjà utilisée',
    RESERVATION_ALREADY_EXISTS: 'Une réservation existe déjà pour ce créneau',
    MANDAT_TYPE_ALREADY_EXISTS: 'Il existe déjà un mandat avec le même type pour ce logement',
    MANDAT_NUM_ALREADY_EXISTS: 'Il existe déjà un mandat avec le même numéro',
    ACCOUNT_NUM_ALREADY_EXISTS: 'Il existe déjà un compte avec le même numéro',
    AVANTIO_ID_ALREADY_EXISTS: 'Il existe déjà un logement avec le même identifiant Avantio',
    CANT_RENDER_TEMPLATE: 'Un problème est survenue pour générer le template',
    SMILY_TIME_OUT:
      'Une erreur est survenue pour la synchronisation vers Smily, merci de réessayer pour synchroniser correctement le logement',
    OWNER_CHARGE_ALREADY_VALIDATED: 'Cette charge hôte est déjà validée',
    ERROR_CANCEL_SIGNATURE_REQUEST: "Impossible d'annuler la demande de signature",
    NO_PARTENAIRE_FOUND: 'Aucun partenaire trouvé sur ce litige',
    NO_LITIGE_ID: "Le litige n'a pas d'id",
    NO_RESERVATION_ID: 'Le litige ne contient pas de reservationId',
    NO_PARTENAIRE_ID: 'Le litige ne contient pas de partenaireId',
    NO_PARTENAIRE_PARTENAIRE_ID: "Le partenaire ne contient pas d'id",
    NO_PARTENAIRE_CONTACT: 'Le partenaire ne contient pas de contact',
    NO_PARTENAIRE_COMPANY: 'Le partenaire ne contient pas de companie',
    NO_PARTENAIRE_ACCOMMODATION: "Le partenaire n'a pas de logement",
    ERROR_CANCEL_RESERVATION: "Une erreur est survenue lors de l'annulation de réservation",
    ERROR_DATE_RESILIATION_3_MONTH: 'La date de fin doit être au moins 3 mois après la date de résiliation',
    NO_MANDAT_ID: 'Aucun mandat id trouvé',
    MAINTENANCE_STATUS: 'Cette maintenance a déjà été résolue',
    FILE_IS_MANDATORY: 'La pièce-jointe est obligatoire',
    ERROR_AMOUNT_PAID_OTA: 'Le montant déjà payé de la commission OTA est supérieur au montant saisie',
    unbalanced_transaction: "Une erreur est survenue: la transaction n'est pas équilibrée",
    undefined: 'An error occured',
    error: 'Une erreur est survenue',
  },
  knowledgesource: {
    knowledgesource: 'Knowledge source',
    label: 'Label',
    empty: 'No knowledge sources',
    add: 'Add a knowledge source',
    added: 'Successfully added knowledge source',
    update: 'Update a knowledge source',
    updated: 'Successfully updated knowledge source',
    confirm_delete: 'Are you sure you want to delete this knowledge source ?',
  },
  geosector: {
    geosector: 'Geographical sector',
    label: 'Label',
    empty: 'No geographical sectors',
    add: 'Add a geographical sector',
    added: 'Successfully added geographical sector',
    update: 'Update a geographical sector',
    updated: 'Successfully updated geographical sector',
    confirm_delete: 'Are you sure you want to delete this geographical sector ?',
  },
  filetype: {
    filetype: 'File type',
    label: 'Label',
    empty: 'No file type',
    add: 'Add a file type',
    added: 'Successfully added file type',
    update: 'Update a file type',
    updated: 'Successfully updated file type',
    confirm_delete: 'Are you sure you want to delete this file type ?',
  },
  epitype: {
    epitype: 'EPI type',
    label: 'Label',
    empty: 'No EPI type',
    add: 'Add an EPI type',
    added: 'Successfully added EPI type',
    update: 'Update an EPI type',
    updated: 'Successfully updated EPI type',
    confirm_delete: 'Are you sure you want to delete this EPI type ?',
  },
  cdcmodel: {
    reset: 'Réinitialiser les CDCs',
    cdc_reset_done: 'Réinitialisation effectuée',
    confirm_reset: 'Etes-vous sûr de vouloir réinitialiser les CDCs ?',
    model: 'Modèle de cahier des charges',
    type: 'Type de modèle',
    item_count: 'Nombre de blocs',
    add_item: 'Ajouter un bloc',
    edit_item: 'Editer un bloc',
    delete_item: 'Supprimer un bloc',
    cdcmodelType_check_in: 'Check-in',
    cdcmodelType_check_out: 'Check-out',
    cdcmodelType_cleaning: 'Ménage interlocatif',
    cdcmodelType_cleaning_before: 'Ménage retour hôte',
    cdcmodelType_cleaning_after: 'Ménage départ hôte',
    cdcmodelType_running: 'Running',
    cdcmodelType_quality_control: 'Contrôle qualité',
    cdcmodelType_maintenance: 'Maintenance',
    error_reset_cdc_prestation: 'Un cdc est utilisé dans une prestation, réinitialisation impossible',
  },
  cdc: {
    type: 'Type',
    duration: 'Durée',
    price: 'Prix',
    itemsNb: 'Nombre de blocs',
    tasksNb: 'Nombre de tâches',
    comment: 'Commentaire',
    updated: 'Le cahier des charges a bien été modifié',
    error_update: 'Une erreur est survenue lors de la mise à jour du cahier des charges',
  },
  prestataire: {
    prestataire: 'Prestataire',
    title: 'Prestataires',
    status_available: 'Disponible',
    status_unavailable: 'Indisponible',
    status_in_mission: 'En mission',
    create: 'Créer un prestataire',
    filter: 'Filtrer',
    search: 'Recherche',
    civility: 'Civilité',
    phone: 'Numéro de téléphone',
    availability: 'Disponibilité',
    lastName: 'Nom',
    firstName: 'Prénom',
    email: 'E-mail',
    enabled: 'Actif',
    company: 'Conciergerie locale',
  },
  prestation: {
    filter: 'Filtrer',
    label: 'Libellé',
    type: 'Type',
    statusRealisation: 'Statut de réalisation',
    status: 'Statut de la mission',
    assigned_staff: 'Personnel affecté',
    dateStart: 'Date de début',
    dateEnd: 'Date de fin',
    timeStart: 'Heure de début',
    timeEnd: 'Heure de fin',
    date: "Date de création de l'ordre",
    schedule: 'Horaires',
    company: 'Agence',
    accommodation_name: 'Nom du logement',
    date_checkin: 'Date de check-in',
    date_checkout: 'Date de check-out',
    details: 'Fiche de prestation',
    empty: 'Aucune prestation',
    list: 'Prestations',
    add: 'Ajouter une prestation',
    update: 'Modifier une prestation',
    updateThis: 'Modifier',
    added: 'La prestation a bien été ajoutée',
    updated: 'La prestation a bien été modifiée',
    deleted: 'La prestation a bien été supprimée',
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer cette prestation ?',
    prestationType_check_in: 'Check-in',
    prestationType_check_out: 'Check-out',
    prestationType_cleaning: 'Ménage interlocatif',
    prestationType_cleaning_before: 'Ménage retour hôte',
    prestationType_cleaning_after: 'Ménage départ hôte',
    prestationType_running: 'Running',
    prestationType_quality_control: 'Contrôle qualité',
    prestationType_maintenance: 'Maintenance',
    status_accepted: 'Acceptée',
    status_proposed: 'Proposée',
    status_partially_accepted: 'Partiellement Acceptée',
    status_to_plan: 'A planifier',
    status_todo: 'Planifiée',
    status_to_do: 'A faire',
    status_doing: 'En cours',
    status_completed: 'Terminée',
    status_canceled: 'Annulée',
    status_partially_canceled: 'Partiellement annulée',
    status_litigation: 'Litige',
  },
  contract: {
    add: 'Add a contract',
    contract: 'Contract',
    file: 'File',
    added: 'Successfully added contract',
    amendment_pattern: 'Amendment pattern',
    startDate: 'Start date',
    endDate: 'End date',
    startPattern: 'End pattern',
    startingEmploymentReason: 'Starting employment reason',
    startTime: 'Start time',
    trialPeriodDuration: 'Trial period duration',
    trialEndDate: 'Trial end date',
    occupationClassification: 'Occupation classification',
    employmentNature: "Nature de l'emploi",
    grossHourlyTx: 'Gross hourly Tx',
    conventionalMinimumWage: 'Conventional minimum wage',
    doNotTransmitTheRate: 'Do not transmit the rate',
    endReason1: 'Change in the administrative situation of the employee or the insured',
    endReason2: 'Parental attendance leave',
    endReason3: 'Family solidarity leave',
    endReason4: 'Half pay leave',
    endReason5: 'Parental education leave',
    endReason6: 'Sabbatical leave, availability, leave without pay for trainee public officials, senior civil servants',
    endReason7: 'Leave without pay greater than or equal to 30 consecutive days',
    endReason8: 'Business continuity at the end of the period',
    endReason9: 'Conversion agreement',
    endReason10: 'Entrepreneur',
    endReason11: 'Death, disappearance',
    endReason12: 'Resignation',
    endReason13: 'Departure on secondment',
    endReason14: 'Voluntary retirement',
    endReason15: 'Provident events (reserved for event-driven provident declarations)',
    endReason16: 'End of employment contract, end of activity, end of secondment, loss of cultural quality (cults)',
    endReason17: 'End of apprenticeship period under a permanent contract',
    endReason18: 'End of IEG pre-retirement period (reserved for inter-agency exchanges)',
    endReason19: 'End of RATP period (reserved for inter-agency exchanges)',
    endReason20: 'Dismissal',
    endReason21: 'Automatic retirement at the initiative of the employer',
    endReason22: 'Payment of paid leave',
    endReason23: 'Conventional termination of the employment contract',
    endReason24:
      'Employee leaving or having left the company (sums paid including non-competition indemnities for Agirc)',
    endReason25: 'Voluntary national service (including civic service volunteers)',
    endReason26: 'Exit from activity of a retiree combining retirement and activity',
    endReason27: 'Cancellation of the employment contract',
    daysNumber: 'Days Nbr / Week',
    monday: 'M',
    tuesday: 'Tu',
    wednesday: 'W',
    thursday: 'Th',
    friday: 'F',
    workedDays: 'Worked days',
    signed: 'Signed',
    signatureDate: 'Signature date',
    startPattern1:
      'hiring, start of activity, direct recruitment or competitive examination (civil service), start of secondment, start',
    startPattern2: 'resumption of activity following suspension of the employment contract',
    startPattern3: 'leave without pay greater than or equal to 30 consecutive days',
    startPattern4: 'half pay leave',
    startPattern5: 'voluntary national service (including civic service volunteers)',
    startPattern6: 'paid leave payment',
    startPattern7: 'parental education leave',
    startPattern8: 'hiring a retiree taking over an activity',
    startPattern9: 'employee leaving or having left the company (sums paid including non-competition indemnities)',
    startPattern10: 'business continuity at start of period',
    startPattern11: 'family solidarity leave',
    startPattern12: 'parental attendance leave',
    startPattern13: 'start of IEG pension period (reserved for inter-agency exchanges)',
    startPattern14: 'sabbatical',
    startPattern15: 'start of RATP period',
    startPattern16: 'forecast event',
    startPattern17: 'return from detachment',
    startPattern18: 'change of administrative status of the employee or the insured',
    startPattern19: 'start of apprenticeship period as part of a permanent contract',
    occupation1: 'Do-it-yourself maintenance worker',
    occupation2: 'Maintainer minor gardening work',
    occupation3: 'Life assistant 1',
    occupation4: 'Childcare(s) 1',
    occupation5: 'Housekeeper 1',
    occupation6: 'Childcare(s) 2',
    occupation7: 'Housekeeper 2',
    occupation8: 'Life assistant 2',
    occupation9: 'Childcare(s) 3',
    occupation10: 'Life assistant 3',
    amendmentReason1: '10-Mail hourly rate increase',
    amendmentReason2: '11-Modification of the annual working time',
    amendmentReason3: '5-Amendment to convert part-time monthly permanent contract to real annualized contract',
    amendmentReason4: '6-Amendment to convert CD2i to real annualized',
    amendmentReason5: '7-Appendix for provision of the business travel vehicle',
    amendmentReason6: '8-Appendix for provision of the home-work business vehicle',
    amendmentReason7: '9-Cell Phone Provisioning Schedule',
  },
  period: {
    days: 'Days',
    weeks: 'Weeks',
    months: 'Months',
  },
  ppe: {
    ppe: 'Personal protective equipment',
    label: 'Label',
    empty: 'No equipment',
    add: 'Add an equipment',
    added: 'Successfully added equipment',
    update: 'Update an equipment',
    updated: 'Successfully updated equipment',
    deleted: 'Successfully deleted equipment',
    confirm_delete: 'Are you sure you want to delete this equipment ?',
    deliveryDate: 'Delivery date',
    quantity: 'Quantity',
    epiType: 'Type',
  },
  nationalities: {
    nat1: 'Afghan',
    nat2: 'Albanian',
    nat3: 'Algerian',
    nat4: 'German',
    nat5: 'American',
    nat6: 'Angolan',
    nat7: 'Argentinian',
    nat8: 'Armenian',
    nat9: 'Aruban',
    nat10: 'Australian',
    nat11: 'Austrian',
    nat12: 'Azerbaijani',
    nat13: 'Bahamian',
    nat14: 'Bangladeshi',
    nat15: 'Belgian',
    nat16: 'Benin',
    nat17: 'Belarusian',
    nat18: 'Bolivian',
    nat19: 'Bosnian',
    nat20: 'Botswana',
    nat21: 'Bouthan',
    nat22: 'Brazilian',
    nat23: 'British',
    nat24: 'Bulgarian',
    nat25: 'Burkinabe',
    nat26: 'Burundian',
    nat27: 'Caimanian',
    nat28: 'Cambodian',
    nat29: 'Cameroonian',
    nat30: 'Canadian',
    nat31: 'Chilean',
    nat32: 'Chinese',
    nat33: 'Cypriot',
    nat34: 'Colombian',
    nat35: 'Congolese',
    nat36: 'Costa Rican',
    nat37: 'Croatian',
    nat38: 'Cuban',
    nat39: 'Danish',
    nat40: 'Dominican',
    nat41: 'Egyptian',
    nat42: 'Emirati',
    nat43: 'Ecuadorian',
    nat44: 'Spanish',
    nat45: 'Estonian',
    nat46: 'Ethiopian',
    nat47: 'European',
    nat48: 'Finnish',
    nat49: 'French',
    nat50: 'Gabonese',
    nat51: 'Georgian',
    nat52: 'Ghanaian',
    nat53: 'Greek',
    nat54: 'Guatemala',
    nat55: 'Guinean',
    nat56: 'Haitian',
    nat57: 'Dutch',
    nat58: 'Honduran',
    nat59: 'Hong Kong',
    nat60: 'Hungarian',
    nat61: 'Indian',
    nat62: 'Indonesian',
    nat63: 'Iraqi',
    nat64: 'Iranian',
    nat65: 'Irish',
    nat66: 'Icelandic',
    nat67: 'Israeli',
    nat68: 'Italian',
    nat69: 'Ivorian',
    nat70: 'Jamaican',
    nat71: 'Japanese',
    nat72: 'Jordanian',
    nat73: 'Kazakh',
    nat74: 'Kenyan',
    nat75: 'Kyrgyz',
    nat76: 'Kosovar',
    nat77: 'Kuwaiti',
    nat78: 'Kurdish',
    nat79: 'Laotian',
    nat80: 'Lesothian',
    nat81: 'Latvian',
    nat82: 'Lebanese',
    nat83: 'Libyan',
    nat84: 'Liechtenstein',
    nat85: 'Lithuanian',
    nat86: 'Luxembourgish',
    nat87: 'Macedonian',
    nat88: 'Madagascar',
    nat89: 'Malaysian',
    nat90: 'Malian',
    nat91: 'Maltese',
    nat92: 'Moroccan',
    nat93: 'Mauritanian',
    nat94: 'Mauritian',
    nat95: 'Mexican',
    nat96: 'Monegasque',
    nat97: 'Mongolian',
    nat98: 'Montenegrin',
    nat99: 'Mozambique',
    nat100: 'Namibian',
    nat101: 'New Zealander',
    nat102: 'Nepalese',
    nat103: 'Nicaraguan',
    nat104: 'Nigerian',
    nat105: 'North Korean',
    nat106: 'Norwegian',
    nat107: 'Ugandan',
    nat108: 'Pakistani',
    nat109: 'Palestinian',
    nat110: 'Panamanian',
    nat111: 'Paraguayan',
    nat112: 'Peruvian',
    nat113: 'Philippians',
    nat114: 'Polish',
    nat115: 'Puerto Rican',
    nat116: 'Portuguese',
    nat117: 'Qatar',
    nat118: 'Romanian',
    nat119: 'Russian',
    nat120: 'Rwandan',
    nat121: 'Saudi',
    nat122: 'Senegalese',
    nat123: 'Serbian',
    nat124: 'Singapore',
    nat125: 'Slovak',
    nat126: 'Somali',
    nat127: 'Sudanese',
    nat128: 'Soviet',
    nat129: 'Sri Lankan',
    nat130: 'South African',
    nat131: 'South Korean',
    nat132: 'Swedish',
    nat133: 'Switzerland',
    nat134: 'Syrian',
    nat135: 'Tajik',
    nat136: 'Taiwanese',
    nat137: 'Tanzanian',
    nat138: 'Chadian',
    nat139: 'Czech',
    nat140: 'Chechen',
    nat141: 'Thai',
    nat142: 'Tunisian',
    nat143: 'Turkish',
    nat144: 'Ukranian',
    nat145: 'Uruguayan',
    nat146: 'Venezuelan',
    nat147: 'Vietnamese',
    nat148: 'Yemeni',
    nat149: 'Yugoslavian',
    nat150: 'Zimbabwean',
  },
  countries: {
    country1: 'South Africa',
    country2: 'Afghanistan',
    country3: 'Albania',
    country4: 'Algeria',
    country5: 'Germany',
    country6: 'Andorra',
    country7: 'Angola',
    country8: 'Antigua and Barbuda',
    country9: 'Saudi Arabia',
    country10: 'Argentina',
    country11: 'Armenia',
    country12: 'Australia',
    country13: 'Austria',
    country14: 'Azerbaijan',
    country15: 'Bahamas',
    country16: 'Bahrain',
    country17: 'Bangladesh',
    country18: 'Barbados',
    country19: 'Belgium',
    country20: 'Belize',
    country21: 'Benin',
    country22: 'Bhutan',
    country23: 'Belarus',
    country24: 'Burma',
    country25: 'Bolivia',
    country26: 'Bosnia and Herzegovina',
    country27: 'Botswana',
    country28: 'Brazil',
    country29: 'Brunei',
    country30: 'Bulgaria',
    country31: 'Burkina Faso',
    country32: 'Burundi',
    country33: 'Cambodia',
    country34: 'Cameroon',
    country35: 'Canada',
    country36: 'Cape Verde',
    country37: 'Chile',
    country38: 'China',
    country39: 'Cyprus',
    country40: 'Colombia',
    country41: 'Comoros',
    country42: 'North Korea',
    country43: 'South Korea',
    country44: 'Costa Rica',
    country45: 'Ivory Coast',
    country46: 'Croatia',
    country47: 'Cuba',
    country48: 'Denmark',
    country49: 'Djibouti',
    country50: 'Dominica',
    country51: 'Egypt',
    country52: 'United Arab Emirates',
    country53: 'Ecuador',
    country54: 'Eritrea',
    country55: 'Spain',
    country56: 'Eswatini',
    country57: 'Estonia',
    country58: 'United States',
    country59: 'Ethiopia',
    country60: 'Fiji',
    country61: 'Finland',
    country62: 'France',
    country63: 'Gabon',
    country64: 'Gambia',
    country65: 'Georgia',
    country66: 'Ghana',
    country67: 'Greece',
    country68: 'Grenada',
    country69: 'Guatemala',
    country70: 'Guinea',
    country71: 'Equatorial Guinea',
    country72: 'Guinea-Bissau',
    country73: 'Guyana',
    country74: 'Haiti',
    country75: 'Honduras',
    country76: 'Hungary',
    country77: 'Cook Islands',
    country78: 'Marshall Islands',
    country79: 'India',
    country80: 'Indonesia',
    country81: 'Iraq',
    country82: 'Iran',
    country83: 'Ireland',
    country84: 'Iceland',
    country85: 'Israel',
    country86: 'Italy',
    country87: 'Jamaica',
    country88: 'Japan',
    country89: 'Jordan',
    country90: 'Kazakhstan',
    country91: 'Kenya',
    country92: 'Kyrgyzstan',
    country93: 'Kiribati',
    country94: 'Kuwait',
    country95: 'Laos',
    country96: 'Lesotho',
    country97: 'Latvia',
    country98: 'Lebanon',
    country99: 'Liberia',
    country100: 'Libya',
    country101: 'Liechtenstein',
    country102: 'Lithuania',
    country103: 'Luxembourg',
    country104: 'Macedonia',
    country105: 'Madagascar',
    country106: 'Malaysia',
    country107: 'Malawi',
    country108: 'Maldives',
    country109: 'Mali',
    country110: 'Malta',
    country111: 'Morocco',
    country112: 'Mauritius',
    country113: 'Mauritania',
    country114: 'Mexico',
    country115: 'Micronesia',
    country116: 'Moldova',
    country117: 'Monaco',
    country118: 'Mongolia',
    country119: 'Montenegro',
    country120: 'Mozambique',
    country121: 'Namibia',
    country122: 'Nauru',
    country123: 'Nepal',
    country124: 'Nicaragua',
    country125: 'Nigeria',
    country126: 'Nigeria',
    country127: 'Niue',
    country128: 'Norway',
    country129: 'New Zealand',
    country130: 'Oman',
    country131: 'Uganda',
    country132: 'Uzbekistan',
    country133: 'Pakistan',
    country134: 'Palau',
    country135: 'Palestine',
    country136: 'Panama',
    country137: 'Papua New Guinea',
    country138: 'Paraguay',
    country139: 'Netherlands',
    country140: 'Peru',
    country141: 'Philippines',
    country142: 'Poland',
    country143: 'Portugal',
    country144: 'Qatar',
    country145: 'Central African Republic',
    country146: 'Democratic Republic of the Congo',
    country147: 'Dominican Republic',
    country148: 'Republic of Congo',
    country149: 'Czech Republic',
    country150: 'Romania',
    country151: 'United Kingdom',
    country152: 'Russia',
    country153: 'Rwanda',
    country154: 'Saint Kitts and Nevis',
    country155: 'Saint Vincent and the Grenadines',
    country156: 'Saint Lucia',
    country157: 'San Marino',
    country158: 'Solomon',
    country159: 'Salvador',
    country160: 'Samoa',
    country161: 'São Tomé and Principe',
    country162: 'Senegal',
    country163: 'Serbia',
    country164: 'Seychelles',
    country165: 'Sierra Leone',
    country166: 'Singapore',
    country167: 'Slovakia',
    country168: 'Slovenia',
    country169: 'Somalia',
    country170: 'Sudan',
    country171: 'South Sudan',
    country172: 'Sri Lanka',
    country173: 'Sweden',
    country174: 'Switzerland',
    country175: 'Suriname',
    country176: 'Syria',
    country177: 'Tajikistan',
    country178: 'Tanzania',
    country179: 'Chad',
    country180: 'Thailand',
    country181: 'East Timor',
    country182: 'Togo',
    country183: 'Tonga',
    country184: 'Trinidad and Tobago',
    country185: 'Tunisia',
    country186: 'Turkmenistan',
    country187: 'Turkey',
    country188: 'Tuvalu',
    country189: 'Ukraine',
    country190: 'Uruguay',
    country191: 'Vanuatu',
    country192: 'Vatican',
    country193: 'Venezuela',
    country194: 'Vietnam',
    country195: 'Yemen',
    country196: 'Zambia',
    country197: 'Zimbabwe',
  },
  idProofs: {
    idproof1: 'National Identity Card',
    idproof2: 'Residence card',
    idproof3: 'Resident card',
    idproof4: 'Receipt',
    idproof5: "Driver's license",
    idproof6: 'Passport',
    idproof7: 'Visa',
    idproof8: "Residence permit 'private and family life'",
    idproof9: "'Employee' residence permit",
    idproof10: "'Student' residence permit",
    idproof11: "'Temporary worker' residence permit",
    idproof12: "'Seasonal worker' residence permit",
    idproof13: "Provisional residence permit bearing the mention 'authorizes its holder to work'",
    idproof14: 'Algerian Residence Certificate',
  },
  litige: {
    list: 'Litiges',
    empty: 'Aucun litige',
    add: 'Ajouter un litige',
    update: 'Modifier un litige',
    delete: 'Supprimer un litige',
    details: 'Détails du litige',
    status: 'Statut du litige',
    status_list: 'Statut',
    date: 'Date de litige',
    status_1: 'Statut n°1',
    status_2: 'Statut n°2',
    status_3: 'Statut n°3',
    comment: 'Commentaire',
    added: 'Votre litige a bien été créé',
    updated: 'Le litige a bien été modifié',
    list_creation_in_progress: 'Fichier en cours de création',
    accommodation: 'Logement',
    reservation: 'Réservation',
    error_empty_files: 'Le champ Fichier est requis',
    error_missing_fields: 'Le lien ou la facture sont requis',
    error_missing_amount: 'Le montant estimé est requis',
    date_decision: 'Date de décision',
    resolution: 'Résolution',
    reason: 'Raison',
    description: 'Description',
    amount_estimated_ttc: 'Montant estimé TTC',
    total_amount_estimated_ttc: 'Montant total estimé TTC',
    amount_ttc: 'Montant réellement encaissé TTC',
    amount_gas: 'Part pour GAS',
    amount_gav: 'Part pour GAV',
    amount_owner: "Part pour l'hôte",
    link: 'Lien',
    trait_litige: 'Traiter le litige',
    traited: 'Le litige a bien été traité',
    file: 'Facture',
    resolve: 'Résoudre le litige',
    resolved: 'Le litige a bien été résolu',
    gagne: 'Gagné',
    perdu: 'Perdu',
    a_resoudre: 'A résoudre',
    a_traiter: 'A traiter',
    en_cours: 'En cours',
    resolu: 'Résolu',
    description_litige: 'Description du litige',
    description_maintenance: 'Description de la maintenance',
    problem_maintenance: 'Problème de la maintenance',
    date_maintenance: 'Date de découverte de la maintenance',
    date_declaration: 'Date de déclaration du litige',
    litige_complete: 'Dossier complet',
    litige_complete_validated: 'Dossier validé',
    litige_complete_refused: 'Dossier refusé',
    litige_completed: 'Le dossier a été correctement complété',
    validate_complete: 'Valider le dossier',
    refuse_complete: 'Refuser le dossier',
    complete_status: 'Statut du dossier',
    complete_date: 'Date de revue du dossier',
    complete_validated: 'Le dossier a été validé',
    complete_status_validated: 'Validé',
    complete_refused: 'Le dossier a été refusé',
    complete_status_refused: 'Refusé',
    refused_comment: 'Commentaire de refus',
    step_validated: 'Etape validée',
    waiting_gav_completion: 'Dossier en attente de complétion par la ville',
    waiting_gas_validation: 'Dossier en attente de validation par le support Guestadom',
  },
  partenaire: {
    partenaire: 'Partenaire',
    title: 'Partenaires',
    name: 'Nom du partenaire',
    details: 'Fiche partenaire',
    status_available: 'Disponible',
    status_unavailable: 'Indisponible',
    status_in_mission: 'En mission',
    create: 'Créer un partenaire',
    deleted: 'Le partenaire a bien été supprimé',
    updated: 'Le partenaire a bien été modifié',
    enabled: 'Le partenaire a bien été activé',
    disabled: 'Le partenaire a bien été désactivé',
    added: 'Le partenaire a bien été ajouté',
    access_sent: 'Les accès ont été envoyés',
    filter: 'Filtrer',
    search: 'Recherche',
    phone: 'Numéro de téléphone',
    representantLastName: 'Nom du représentant',
    representantFirstName: 'Prénom du représentant',
    availability: 'Disponibilité',
    email: 'E-mail',
    empty: 'Aucun partenaire',
    errorNoId: "Ce partenaire n'a pas de compte utilisateur",
  },
  housekeeper: {
    title: 'Housekeepers',
    name: "housekeeper's name",
    details: 'Housekeeper details',
    create: 'Create housekeeper',
    update: 'Update housekeeper',
    list: 'Housekeepers',
    updated: 'The housekeeper has been updated',
    enabled: 'The housekeeper has been activated',
    enabledState: 'Active / Inactive',
    disabled: 'The housekeeper has been deactivated',
    accessSent: 'Accesses have been sent',
    representantLastName: "Representative's lastname",
    representantFirstName: "Representative's firstname",
    empty: 'No housekeeper',
    updateThis: 'Update',
    createAccess: 'Create access',
    resendAccess: 'Resend access',
    accommodation: 'Accommodation',
    company: 'Local concierge service',
    errorNoId: 'This housekeeper does not have a user account',
    accommodation_number: 'Number of accommodations',
  },
  add: 'Ajouter',
  update: 'Modifier',
  interlocutor: {
    interlocutor: 'Interlocuteur',
    interlocutorType: "Type d'interlocuteur",
    proprietaire: 'Hôte',
    prospect: 'Prospect',
    salarie: 'Salarié',
    candidat: 'Candidat',
    voyageur: 'Voyageur',
    prestataire: 'Prestataire',
    partenaire: 'Partenaire',
    housekeeper: 'Concierge',
    accommodation: 'Logement',
    reservation: 'Réservation',
  },
  planning_accommodation: {
    title: 'Planning logement',
    last_week: 'Semaine dernière',
    next_week: 'Semaine prochaine',
    search: 'Rechercher un logement',
    filter: 'Filtrer',
    assigned_staff: 'Personne affectée',
    status: 'Statut',
    company: 'Agence',
    mission_type: 'Type de mission',
    accommodation_name: 'Nom du logement',
  },
  planning_intervenant: {
    title: 'Planning des intervenants',
    last_week: 'Semaine dernière',
    next_week: 'Semaine prochaine',
    current_week: 'Semaine actuelle',
    search: 'Rechercher un intervenant',
    filter: 'Filtrer',
    assigned_staff: 'Type de personnel affecté',
    status: 'Statut',
    company: 'Agence',
    mission_type: 'Type de mission',
    participant: 'Intervenant',
    reservation: 'Numéro de réservation',
    accommodation: 'Logement',
    planned_start_date: 'Date de début prévue',
    duration: 'Durée prévue',
    duration_minutes: 'Durée prévue (en minutes)',
    nb_participants_needed: 'Nombre de personnes requises',
    schedule: 'Planifier',
    schedule_mission: 'Planifier une mission',
    start_date: 'Date de début de la mission',
    start_hour: 'Heure de début',
    end_hour: 'Heure de fin',
    participant_name: "Nom de l'intervenant",
    comment: "Commentaire pour l'intervenant",
    success: 'La mission a bien été affectée aux intervenants',
    hour_end_error: "L'heure de fin ne peut pas être inférieure à celle de début",
    duration_error: "La durée ne peut pas être inférieure à celle prévue par l'ordre de mission",
  },
  mission_monitoring: {
    title: 'Suivi de missions',
    time_start_low_margin: 'Start time (low margin)',
    time_start_high_margin: 'Start time (high margin)',
    time_end_low_margin: 'End time (low margin)',
    time_end_high_margin: 'End time (high margin)',
    filter: 'Filtrer',
    todo: 'A faire',
    doing: 'En cours',
    done: 'Terminé',
    finish: 'Terminée',
    canceled: 'Annulée',
    status: 'Statut de la mission',
    empty: 'Aucune mission',
    prestation_date: 'Date de prestation',
    prestation_type: 'Type de prestation',
    accommodation_name: 'Logement',
    reservation_num: 'Numéro de réservation',
    date_checkin: 'Date de check-in',
    date_checkout: 'Date de check-out',
    cancel_mission: 'Annulation de la mission',
    cancel_comment: "Raison de l'annulation",
    success_complete: 'La mission a bien été marquée comme "Terminée"',
    success_cancel: 'La mission a bien été marquée comme "Annulée"',
  },
  undetermined_date: 'Undetermined date',
  messaging: 'Mailbox',
  revenueManagement: 'Revenue Management',
  review: {
    title: 'Avis',
    filter: 'Filtrer',
    export: 'Exporter',
    list_creation_in_progress: 'Export en cours...',
    empty: 'Aucun avis',
    reservation_num: 'Numéro de réservation',
    rating: 'Note globale (sur 10)',
    review_title: 'Titre',
    reservation_site: 'Plateforme',
    company_name: 'Agence',
    accommodation_name: 'Nom du logement',
    owner_name: "Nom de l'hôte",
    submitted_at: "Date de dépôt de l'avis",
    name: 'Voyageur',
    companies: 'Agences',
    reservation_sites: 'Plateformes',
    accommodations: 'Logements',
    details: "Détails de l'avis",
    data: "Données de l'avis",
    comment: 'Commentaire',
    public_comment: 'Commentaire public',
    private_comment: 'Commentaire privé',
    criterias: 'Critères',
    criteria: {
      cleanliness: 'Propreté',
      checkin: 'Arrivée',
      communication: 'Communication',
      location: 'Emplacement',
      facilities: 'Équipements',
      comfort: 'Confort',
      staff: 'Personnel',
      value_for_money: 'Qualité-prix',
      accuracy: 'Précision',
      property_condition: 'État de la propriété',
      eco_friendliness: "Respect de l'environnement",
    },
    fetching: 'Récupération en cours...',
  },
  anomaly: 'Anomalie',
};
