<script>
/* COMPOSANTS */
import PrestatairePageDetails from './tabs/PrestatairePageDetails.vue';
import PrestatairePageMissions from './tabs/PrestatairePageMissions.vue';
import GaView from '@components/layout/GaView';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import backMixin from '@mixins/backMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import prestataireService from '@services/prestataireService';

export default {
  components: {PrestatairePageDetails, PrestatairePageMissions, GaView},
  mixins: [randomRef, backMixin, messageMixin],
  metaInfo() {
    return {
      title: this.title,
    };
  },
  data() {
    return {
      prestataire: {
        id: null,
        companyId: null,
        contact: {
          civility: '',
          firstName: '',
          lastName: '',
          birthDate: new Date(),
          email: '',
          phone: '',
        },
        files: [],
        newFiles: [],
      },
      activeTab: 0,
      editable: false,
    };
  },
  mounted() {
    this.refresh();
  },
  computed: {
    title() {
      return this.prestataire.contact.firstName + ' ' + this.prestataire.contact.lastName;
    },
  },
  methods: {
    refresh() {
      prestataireService
        .get(this.$route.params.prestataireId)
        .then((data) => {
          this.prestataire = data;
        })
        .catch((e) => {
          this.addError(e.response.data.error);
        });
    },

    updatePresta() {
      const payload = {
        companyId: this.prestataire.companyId,
        contact: {
          civility: this.prestataire.contact.civility,
          firstName: this.prestataire.contact.firstName,
          lastName: this.prestataire.contact.lastName,
          birthDate: this.prestataire.contact.birthDate,
          email: this.prestataire.contact.email,
          phone: this.prestataire.contact.phone,
        },
      };
      const files = this.prestataire.newFiles ?? [];
      prestataireService
        .update(this.$route.params.prestataireId, payload, files)
        .then(() => {
          this.success('Le prestataire a bien été modifié');
          this.editable = false;
          this.refresh();
        })
        .catch((e) => {
          this.addError(e.response.data.error);
        });
    },

    onPrestataireEnable() {
      prestataireService
        .enable(this.prestataire.id)
        .then(() => {
          this.prestataire.enabled = true;
        })
        .catch((e) => {
          this.addError(e.response.data.error);
        });
    },
    onPrestataireDisable() {
      prestataireService
        .disable(this.prestataire.id)
        .then(() => {
          this.prestataire.enabled = false;
        })
        .catch((e) => {
          this.addError(e.response.data.error);
        });
    },
  },
};
</script>

<template>
  <GaView :ref="ref" :title="this.title">
    <template #back>
      <a class="mb-1 d-block pointer" @click="goBack()"><i class="fe fe-arrow-left pr-1" />{{ $t('back') }}</a>
    </template>
    <template #action>
      <div class="d-flex justify-content-end" style="column-gap: 16px">
        <a v-if="prestataire.enabled" type="button" class="btn btn-danger ml-auto" @click="onPrestataireDisable"
          >Désactiver le prestataire</a
        >
        <a v-else type="button" class="btn btn-danger ml-auto" @click="onPrestataireEnable">Activer le prestataire</a>
        <a v-if="editable === false" type="button" class="btn btn-create ml-2" @click="editable = true"
          >Modifier le prestataire</a
        >
        <button v-if="editable === true" type="button" class="btn btn-create ml-2" @click="updatePresta">
          Enregistrer
        </button>
      </div>
    </template>
    <template #content>
      <div class="h-100 d-flex flex-column prestataire-list">
        <div class="d-flex align-items-center top-container">
          <div class="tab-container align-self-end">
            <div class="tab" :class="activeTab === 0 ? 'active' : ''" @click="activeTab = 0">Informations</div>
            <div class="tab" :class="activeTab === 1 ? 'active' : ''" @click="activeTab = 1">Missions</div>
          </div>
        </div>
        <div class="content-list">
          <PrestatairePageDetails v-if="activeTab === 0" :model-value="prestataire" :editable="editable" />
          <PrestatairePageMissions :prestataire-id="this.$route.params.prestataireId" v-else />
        </div>
      </div>
    </template>
  </GaView>
</template>

<style lang="scss" scoped>
.prestataire-list {
  .top-container {
    padding: 0 24px 0 52px;
    display: flex;
    justify-content: space-between;
  }

  .tab-container {
    display: flex;
    flex-direction: row;
    height: 32px;
    gap: 24px;
  }

  .tab {
    width: fit-content;
    cursor: pointer;
    color: #667085;
    height: 32px;
    box-sizing: border-box;

    font-family: 'Nunito';
    font-size: 14px;
    font-weight: 600;
  }

  .tab.active {
    color: #092858;
    border-bottom: solid 2px #092858;
  }

  .content-list {
    flex: 1 0 auto;
  }
}

.btn-danger {
  height: 44px;
  background-color: #a30000;
  color: #ffffff;
}

.btn-create {
  height: 44px;
  background-color: #0b3575;
  color: #ffffff;
}
</style>
