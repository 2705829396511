export default {
  data() {
    return {
      mandatStatusFilterConst: [
        {label: this.$t('mandat.all'), value: null},
        {label: this.$t('mandat.enabled_only'), value: 'EN_LIGNE'},
        {label: this.$t('mandat.disabled_only'), value: 'RESILIE'},
        {label: this.$t('mandat.resiliation_pending'), value: 'EN_COURS_DE_RESILIATION'},
        {label: this.$t('mandat.en_cours'), value: 'EN_COURS'},
      ],
    };
  },
  methods: {
    displayForMandatStatusFilterConst(value) {
      let result = this.mandatStatusFilterConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
