<script>
import prettyBytes from 'pretty-bytes';
import {getColorIconFromMIME, getFontAwesomeIconFromMIME} from '@utils/FontAwesomeMimeType';
import {FilterMatchMode} from 'primevue/api';

/* COMPOSANTS */
import GaContentView from '@components/layout/GaContentView';
import AkDropdown from '@components/input/AkDropdown';
import AkDialog from '@components/general/AkDialog';
import InputText from 'primevue/inputtext';
import PButton from 'primevue/button';

/* MIXINS */
import loaderMixin from '@mixins/loaderMixin';
import randomRef from '@mixins/randomRef';
import yesNoConst from '@mixins/const/yesNoConst';
import quantityConst from '@mixins/const/quantityConst';
import receptionTypeConst from '@mixins/const/receptionTypeConst';
import residenceTypeConst from '@mixins/const/residenceTypeConst';
import permissionsMixin from '@mixins/permissionsMixin';
import envConst from '@mixins/const/envConst';

/* SERVICES */
import ficService from '@services/ficService';
import itemLabelService from '@services/itemLabelService';
import FileUpload from 'primevue/fileupload';

export default {
  components: {
    InputText,
    PButton,
    FileUpload,
    AkDropdown,
    AkDialog,
  },
  mixins: [
    randomRef,
    permissionsMixin,
    loaderMixin,
    yesNoConst,
    quantityConst,
    receptionTypeConst,
    residenceTypeConst,
    envConst,
  ],
  props: {
    modelValue: {required: true},
  },
  data() {
    return {
      filter: undefined,
      messages: [],
      fics: [],
      types: [],
      typeId: undefined,
      employeeId: undefined,
      file: undefined,
      item: undefined,
    };
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  created() {
    this.initFilters();
  },
  mounted() {
    this.getRef().showLoader(this.$refs.form);
    this.employeeId = this.$route.params.employeeId;

    let p1 = ficService.fics({tableName: 'employee', tableId: this.employeeId});
    p1.then((data) => {
      this.fics = data;
    });

    let p2 = itemLabelService.itemLabels({type: 'FILE_TYPE', enabled: true});
    p2.then((data) => {
      this.types = data;
    });

    Promise.all([p1, p2]).then(this.getRef().hideLoader());
  },
  methods: {
    reloadList() {
      ficService.fics({tableName: 'employee', tableId: this.employeeId}).then((data) => {
        this.fics = data;
      });
    },
    downloadFile(fic, isPreview) {
      ficService.download(fic, isPreview).catch((e) => {
        if (e.response.status === 404) {
          this.error("Le fichier n'existe pas !");
        }
      });
    },
    openCreateDialog() {
      this.$refs.dialogCreate.show();
    },
    cleanCreateDialog() {
      this.typeId = undefined;
      this.file = undefined;
    },
    create() {
      if (!this.file) return;

      let fic = {};
      fic.tableName = 'employee';
      fic.tableId = this.employeeId;
      fic.ficItemLabelId = this.typeId;

      ficService
        .createItem(fic, this.file)
        .then((data) => {
          this.reloadList();
          this.$refs.dialogCreate.hide();
          this.getRef().success(this.$t('employee.file_added'));
        })
        .catch(() => {
          this.getRef().error('Une erreur est survenue');
        });
    },
    clearFile() {
      this.file = undefined;
    },
    openDeleteDialog(item) {
      this.item = item;
      this.$refs.dialogDelete.show();
    },
    delete() {
      ficService
        .delete(this.item)
        .then(() => {
          this.reloadList();
          this.$refs.dialogDelete.hide();
          this.getRef().success(this.$t('employee.file_deleted'));
        })
        .catch(() => {
          this.getRef().error('Une erreur est survenue');
        });
    },
    clearFilter() {
      this.initFilters();
    },
    initFilters() {
      this.filter = {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
      };
    },
    displayLabel(data) {
      return data.label;
    },
    displayType(data) {
      return data.ficItemLabel;
    },
    displaySize(fic) {
      return prettyBytes(fic.size, {locale: 'en', maximumFractionDigits: 1});
    },
    getFileIcon(fic) {
      return getFontAwesomeIconFromMIME(fic.fileName);
    },
    getColorIcon(fic) {
      return getColorIconFromMIME(fic.fileName);
    },
  },
};
</script>

<template>
  <GaContentView :ref="ref" :title="$t('employee.files')">
    <template #action>
      <button class="btn btn-inverse-primary btn-xs" @click="openCreateDialog()">
        <i class="gst-plus pr-1" />{{ $t('add') }}
      </button>
    </template>

    <template #content>
      <DataTable
        v-model:filters="filter"
        :always-show-paginator="false"
        :global-filter-fields="['fileName', 'ficType']"
        :paginator="true"
        :rows="100"
        :value="fics"
        class="table"
        current-page-report-template="{first}-{last}/{totalRecords}"
        filter-display="menu"
        removable-sort
        responsive-layout="scroll"
        sort-field="position"
        striped-rows>
        <template #header>
          <div class="d-flex justify-content-between">
            <PButton
              class="p-button-outlined"
              icon="pi pi-filter-slash"
              label="Effacer"
              type="button"
              @click="clearFilter()" />
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="filter['global'].value" placeholder="Recherche" />
            </span>
          </div>
        </template>
        <template #empty>
          {{ $t('file.empty') }}
        </template>

        <Column :header="$t('file.name')">
          <template #body="slotProps">
            <i :class="[getFileIcon(slotProps.data), getColorIcon(slotProps.data)]" class="fa mr-1" />
            {{ slotProps.data.fileName }}
          </template>
        </Column>

        <Column :header="$t('file.type')" header-style=" width:400px">
          <template #body="slotProps">
            {{ displayType(slotProps.data) }}
          </template>
        </Column>

        <Column :header="$t('file.size')" header-style=" width:160px;">
          <template #body="slotProps">
            {{ displaySize(slotProps.data) }}
          </template>
        </Column>

        <Column body-style="text-align: right; overflow: visible" header-style=" width: 200px; text-align: btn">
          <template #body="slotProps">
            <div class="d-flex justify-content-end align-items-center">
              <span class="btn btn-xs btn-inverse-primary mr-1" @click="downloadFile(slotProps.data, true)">
                <i class="fe fe-eye" />
              </span>
              <span class="btn btn-xs btn-inverse-primary mr-1" @click="downloadFile(slotProps.data, false)">
                <i class="fe fe-download" />
              </span>
              <span class="btn btn-xs btn-inverse-danger mr-1" @click="openDeleteDialog(slotProps.data)">
                <i class="fe fe-trash" />
              </span>
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
    <template #extra>
      <AkDialog
        ref="dialogCreate"
        :title="$t('employee.add_file')"
        :validate-label="$t('add')"
        width="450px"
        @cancel="cleanCreateDialog()"
        @validate="create()">
        <div class="form-row">
          <AkDropdown
            v-model="typeId"
            :label="$t('employee.filetype')"
            :options="types"
            :option-label="displayLabel"
            :option-value="(data) => data.id"
            class-name="col-md-12" />
        </div>
        <div class="form-row">
          <div class="col-md-6 form-group">
            <FileUpload
              v-if="!file"
              ref="uploader"
              :auto="true"
              :choose-label="$t('add_file')"
              :maxFileSize="getMaxFileUpload()"
              :invalidFileSizeMessage="$t('file.err_size')"
              :custom-upload="true"
              :multiple="false"
              :show-upload-button="false"
              class="form-control"
              mode="basic"
              name="uploader"
              @select="(event) => (file = event.files[0])" />
            <div v-if="file" class="file-container">
              <span class="file-name">{{ file.name }}</span>
              <i class="fa fa-times" @click="clearFile()" />
            </div>
          </div>
        </div>
      </AkDialog>

      <AkDialog
        ref="dialogDelete"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.delete()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span>{{ $t('employee.file_confirm_delete') }}</span>
        </div>
      </AkDialog>
    </template>
  </GaContentView>
</template>
