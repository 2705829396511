import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  owners(filter) {
    return Api()
      .get('/owners', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  ownersAutocomplete(filter) {
    return Api()
      .get('/owners/autocomplete', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  ownersPagination(filter) {
    return Api()
      .get('/owners/pagination', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach(this.formatRes);
        let response = {};
        response.data = res.data;
        response.totalItems = parseInt(res.headers['x-pagination-total']);
        return response;
      });
  },
  count(filter) {
    return Api(true, false)
      .get('/owner/count', {params: removeEmptyParams(filter)})
      .then((res) => res.data);
  },
  owner(id) {
    return Api()
      .get('/owner/' + id)
      .then((res) => this.formatRes(res.data));
  },
  create(owner) {
    return Api()
      .post('/owner', owner)
      .then((res) => this.formatRes(res.data));
  },
  update(owner) {
    return Api()
      .put('/owner/' + owner.id, owner)
      .then((res) => this.formatRes(res.data));
  },
  enable(owner) {
    return Api()
      .get('/owner/enable/' + owner.id)
      .then((res) => this.formatRes(res.data));
  },
  disable(owner) {
    return Api()
      .get('/owner/disable/' + owner.id)
      .then((res) => this.formatRes(res.data));
  },
  delete(owner) {
    return Api()
      .delete('/owner/' + owner.id)
      .then(() => owner);
  },
  forceDelete(owner) {
    return Api()
      .delete('/owner/' + owner.id, {params: {force: true}})
      .then(() => owner);
  },
  access(owner) {
    return Api()
      .post('/owner/' + owner.id + '/access')
      .then((res) => this.formatRes(res.data));
  },
  formatRes(e) {
    if (e.dateCreated) e.dateCreated = new Date(e.dateCreated);
    return e;
  },
};
