export default {
  data() {
    return {
      litigeCompleteStatusConst: [
        {label: this.$t('litige.complete_status_validated'), value: 'VALIDATED'},
        {label: this.$t('litige.complete_status_refused'), value: 'REFUSED'},
      ],
    };
  },
  methods: {
    displayForLitigeCompleteStatusConst(value) {
      let result = this.litigeStatusConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
    // liste utilisée pour le filtre de la liste des litiges
    litigeStatusCompleteConstForFilter() {
      return this.litigeStatusConst.filter((el) => el.value !== 'RESOLU');
    },
  },
};
