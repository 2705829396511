export default {
  data() {
    return {
      activityMotifConst: [
        {label: 'Absence', value: 'ABSENCE'},
        {label: 'Litiges', value: 'LITIGES'},
        {label: 'Suivi litiges', value: 'SUIVI_LITIGES'},
        {label: 'Perte de clés', value: 'PERTE_DE_CLES'},
        {label: 'Changement de planning', value: 'CHANGEMENT_DE_PLANNING'},
        {label: "Modifications d'annonce", value: 'MODIFICATIONS_D_ANNONCE'},
        {label: 'Demande de RDV', value: 'DEMANDE_DE_RDV'},
        {label: "Demande d'information", value: 'DEMANDE_D_INFORMATION'},
        {label: 'Document administratif', value: 'DOCUMENT_ADMINISTRATIF'},
        {label: "Demande d'informations CRG", value: 'DEMANDE_D_INFORMATIONS_CRG'},
        {label: "Demande d'informations réservation", value: 'DEMANDE_D_INFORMATIONS_RESERVATION'},
        {label: 'Annulation de réservation', value: 'ANNULATION_DE_RESERVATION'},
        {label: 'Règlement de réservation', value: 'REGLEMENT_DE_RESERVATION'},
        {label: 'Règlement de CRG', value: 'REGLEMENT_DE_CRG'},
        {label: "Relance d'impayé", value: 'RELANCE_D_IMPAYE'},
        {label: 'Bloquer calendrier hôte', value: 'BLOQUER_CALENDRIER_HOTE'},
        {label: 'Achat hôte', value: 'ACHAT_HOTE'},
        {label: 'Geste commerciale (modification du prix à la nuitée quand long séjour)', value: 'GESTE_COMMERCIALE'},
        {label: 'Stock de linge', value: 'STOCK_DE_LINGE'},
      ],
    };
  },
  methods: {
    displayForActivityMotifConst(value) {
      let result = this.activityMotifConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
