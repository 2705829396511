export default {
  data() {
    return {
      idProofsConst: [
        {label: this.$t('idProofs.idproof1'), value: 'ID_PROOF_1'},
        {label: this.$t('idProofs.idproof2'), value: 'ID_PROOF_2'},
        {label: this.$t('idProofs.idproof3'), value: 'ID_PROOF_3'},
        {label: this.$t('idProofs.idproof4'), value: 'ID_PROOF_4'},
        {label: this.$t('idProofs.idproof5'), value: 'ID_PROOF_5'},
        {label: this.$t('idProofs.idproof6'), value: 'ID_PROOF_6'},
        {label: this.$t('idProofs.idproof7'), value: 'ID_PROOF_7'},
        {label: this.$t('idProofs.idproof8'), value: 'ID_PROOF_8'},
        {label: this.$t('idProofs.idproof9'), value: 'ID_PROOF_9'},
        {label: this.$t('idProofs.idproof10'), value: 'ID_PROOF_10'},
        {label: this.$t('idProofs.idproof11'), value: 'ID_PROOF_11'},
        {label: this.$t('idProofs.idproof12'), value: 'ID_PROOF_12'},
        {label: this.$t('idProofs.idproof13'), value: 'ID_PROOF_13'},
        {label: this.$t('idProofs.idproof14'), value: 'ID_PROOF_14'},
      ],
    };
  },
  methods: {
    displayForIDProofConst(value) {
      let result = this.idProofsConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
