<script>
import ficService from '@services/ficService';
import {getColorIconFromMIME, getFontAwesomeIconFromMIME} from '@utils/FontAwesomeMimeType';
import FileUpload from 'primevue/fileupload';
import messageMixin from '@mixins/messageMixin';
import envConst from '@mixins/const/envConst';
import loaderMixin from '@mixins/loaderMixin';
import AkPictureAccommodation from '../general/AkPictureAccommodation';

export default {
  components: {AkPictureAccommodation, FileUpload},
  mixins: [messageMixin, envConst, loaderMixin],
  props: {
    modelValue: {
      required: true,
    },
    reference: {
      required: true,
    },
    tableName: {
      type: String,
      required: true,
    },
    displayDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
    tableId: {
      type: Number,
      required: true,
    },
    ficType: {
      type: String,
      required: false,
      default: null,
    },
    chooseLabel: {
      type: String,
      required: false,
      default: 'Choisir un fichier',
    },
    title: {
      type: String,
      required: true,
    },
    accept: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    secondOption: {
      type: Object,
      required: false,
      default: undefined,
    },
    preview: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update:modelValue', 'delete-item'],
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    hasCurrentValue() {
      return this.currentValue !== undefined && this.currentValue !== null;
    },
  },
  methods: {
    delete() {
      this.$emit('delete-item');
    },
    getFileIcon() {
      if (!this.hasCurrentValue) return 'fa-file';
      return getFontAwesomeIconFromMIME(this.currentValue.fileName);
    },
    getFileName() {
      if (!this.hasCurrentValue) return '';
      return this.currentValue.fileName;
    },
    getColorIcon() {
      if (!this.hasCurrentValue) return 'text-muted';
      return getColorIconFromMIME(this.currentValue.fileName);
    },
    downloadFile(isPreview) {
      if (!this.hasCurrentValue) return;
      ficService.download(this.currentValue, isPreview).catch((e) => {
        if (e.response.status === 404) {
          this.addError("Le fichier n'existe pas !");
        }
      });
    },
    uploadFile() {
      this.$refs.uploader.$refs.fileInput.click();
    },
    clearFile() {
      this.$refs.uploader.clear();
      ficService
        .delete(this.currentValue)
        .then(() => {
          this.currentValue = undefined;
          this.success('Le fichier a bien été supprimé');
        })
        .catch((e) => {
          this.addError('Une erreur est survenue');
        });
    },
    selectFiles(event) {
      if (event.files) {
        let fic = {};
        fic.ficType = this.ficType;
        fic.tableName = this.tableName;
        fic.tableId = this.tableId;
        this.showTotalLoaderWithAfter(this.$t('onboarding.document_upload_in_progress'));
        ficService
          .createItem(fic, event.files[0])
          .then((data) => {
            this.currentValue = data;
            this.success('Fichier uploadé');
          })
          .catch((e) => {
            this.addError('Une erreur est survenue');
          })
          .finally(() => {
            this.hideLoader();
          });
      }
    },
  },
};
</script>

<template>
  <div class="accommodation-type onboarding-fic-type onboarding-fic-type-light">
    <div class="title-type">
      <label>{{ title }} <span v-if="required">*</span></label>
      <i v-if="secondOption" :class="secondOption.class" @click="secondOption.action" />
      <i v-if="displayDelete" class="fa fa-trash delete" @click="this.delete" />
    </div>
    <div v-if="hasCurrentValue" class="fic-line add-file">
      <i v-if="!preview" :class="[getFileIcon(), getColorIcon()]" class="fa" />
      <div :class="{disabled: disabled}">
        <span v-if="!preview" class="pointer" @click="downloadFile(true)">{{ getFileName() }}</span>
        <AkPictureAccommodation :md5="this.currentValue.md5" height="80" radius="5" v-if="preview" />
      </div>
    </div>
    <div v-if="hasCurrentValue" class="icon-div full-width">
      <span class="download pointer" @click="downloadFile(false)"> <i class="fa fa-download" />Télécharger </span>
      <span v-if="!disabled" class="delete pointer" @click="clearFile()"><i class="fa fa-times" />Supprimer</span>
    </div>
    <div v-if="!hasCurrentValue && !disabled" class="fic-line add-file" @click="uploadFile()">
      <i :class="[getColorIcon()]" class="pi pi-plus" />
      <div>
        <span class="text-muted">{{ chooseLabel }}</span>
      </div>
    </div>
    <div v-if="!hasCurrentValue && disabled" class="fic-line add-file disabled">
      <i :class="[getColorIcon()]" class="pi pi-plus" />
      <div>
        <span class="text-muted">{{ chooseLabel }}</span>
      </div>
    </div>
    <FileUpload
      ref="uploader"
      :accept="accept"
      :auto="true"
      :choose-label="chooseLabel"
      :custom-upload="true"
      :invalidFileSizeMessage="$t('file.err_size')"
      :maxFileSize="getMaxFileUpload()"
      :multiple="false"
      :show-upload-button="false"
      class="form-control d-none"
      mode="basic"
      name="uploader"
      @select="(event) => selectFiles(event)" />
  </div>
</template>
