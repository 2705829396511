<script>
/* COMPOSANTS */

/* MIXINS */
/* SERVICES */

import AkCalendar from '@components/input/AkCalendar';
import AkInputSwitch from '@components/input/AkInputSwitch';
import AkOwnerAutocomplete from '@components/general/AkOwnerAutocomplete';
import AkAccommodationAutocomplete from '@components/general/AkAccommodationAutocomplete';
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@/mixins/sidebarMixin';
import {Icon} from '@iconify/vue/dist/iconify';
import companyService from '@services/companyService';
import MultiSelect from 'primevue/multiselect';
import AkInputText from '@components/input/AkInputText';
import accommodationService from '@services/accommodationService';
import AkDropdown from '@components/input/AkDropdown';
import AkReservationAutocomplete from '@components/general/AkReservationAutocomplete.vue';

export default {
  components: {
    AkReservationAutocomplete,
    AkDropdown,
    AkInputText,
    MultiSelect,
    AkCalendar,
    AkInputSwitch,
    AkOwnerAutocomplete,
    AkAccommodationAutocomplete,
    Icon,
  },
  mixins: [permissionsMixin, utilsMixin, sidebarMixin],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      companies: undefined,
      accommodations: [],
    };
  },
  mounted() {
    companyService.companies().then((data) => {
      this.companies = data;
    });
    if (this.isOwner()) {
      accommodationService.accommodations({userId: this.myUserId()}).then((data) => {
        this.accommodations = data;
      });
    }
  },
  methods: {
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.ownerAutocomplete) this.$refs.ownerAutocomplete.reset();
      if (this.$refs.accommodationAutocomplete) this.$refs.accommodationAutocomplete.reset();
      if (this.$refs.reservationAutocomplete) this.$refs.reservationAutocomplete.reset();
      this.callback[1]();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    loadingAccommodations() {
      return this.accommodations.length === 0;
    },
    loadingCompanies() {
      return this.companies === undefined;
    },
  },
};
</script>

<template>
  <h2>Filtrer</h2>
  <form class="d-flex flex-column" style="row-gap: 16px" @submit.prevent="search">
    <MultiSelect
      v-if="this.isGas()"
      v-model="currentValue.companyIds"
      :options="this.companies"
      :loading="this.loadingCompanies"
      :placeholder="$t('employee.company')"
      class="flex-1 form-control"
      option-label="name"
      option-value="id" />

    <AkInputText
      v-if="!this.isOwner()"
      :inline="true"
      v-model="currentValue.accommodationCity"
      :placeholder="$t('accommodation.city')"
      className="flex-1 form-control" />

    <AkCalendar
      v-model="currentValue.dateCheckin"
      className="flex-1 form-control"
      :inline="true"
      :placeholder="$t('reservation.dateCheckin')"
      date-format="dd/mm/yy"
      selection-mode="single" />

    <AkCalendar
      v-if="!this.isOwner()"
      v-model="currentValue.dateCreated"
      className="flex-1 form-control"
      :inline="true"
      :placeholder="$t('ticket.dateCreated')"
      date-format="dd/mm/yy"
      selection-mode="single" />
    <AkOwnerAutocomplete
      v-if="!this.isOwner()"
      ref="ownerAutocomplete"
      v-model="currentValue.ownerId"
      :inline="true"
      className="flex-1 form-control"
      :placeholder="$t('owner.ownerDisplay')" />
    <AkReservationAutocomplete
      ref="reservationAutocomplete"
      v-if="!this.isOwner()"
      v-model="currentValue.avantioLocalizer"
      :inline="true"
      :placeholder="$t('reservation.number')"
      class-name="flex-1 form-control" />
    <AkAccommodationAutocomplete
      ref="accommodationAutocomplete"
      v-model="currentValue.accommodationId"
      className="flex-1 form-control"
      :inline="true"
      v-if="!this.isOwner()"
      :placeholder="$t('accommodation.name')" />
    <AkDropdown
      v-if="this.isOwner()"
      v-model="currentValue.accommodationId"
      :inline="true"
      :loading="this.loadingAccommodations"
      className="flex-1 form-control"
      :options="this.accommodations"
      optionLabel="name"
      optionValue="id"
      :placeholder="$t('accommodation.name')"
      :label="$t('accommodation.name')" />
    <AkInputText
      v-if="!this.isOwner()"
      v-model="currentValue.guestName"
      :placeholder="$t('reservation.guestName')"
      className="flex-1 form-control"
      :inline="true" />
    <AkInputSwitch
      v-if="!this.isOwner()"
      v-model="currentValue.litigePending"
      :label="$t('reservation.litigePending')"
      :inline="true"
      :labelBefore="false"
      class-name="flex-1" />
    <AkInputSwitch
      v-if="!this.isOwner()"
      v-model="currentValue.reclamationPending"
      :label="$t('reservation.reclamationPending')"
      :inline="true"
      :labelBefore="false"
      class-name="flex-1" />
    <div class="d-flex flex-row">
      <button class="btn btn-primary btn-search flex-1" type="submit">Rechercher</button>
      <Icon
        v-on:click="this.reset()"
        class="pointer"
        icon="basil:cross-outline"
        :style="{fontSize: '50px', width: '50px !important'}" />
    </div>
  </form>
</template>
