<script>
import useVuelidate from '@vuelidate/core';
import {required, requiredIf} from '@vuelidate/validators';

/* COMPOSANTS */
import AkInputTextArea from '@components/input/AkInputTextArea.vue';
import AkInputMoney from '@components/input/AkInputMoney.vue';

/* MIXINS */

export default {
  components: {AkInputMoney, AkInputTextArea},
  props: {
    modelValue: {
      required: true,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    index: {
      type: Number,
      required: true,
    },
    showPriceInput: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  mixins: [],
  validations() {
    return {
      currentValue: {
        priceHt: {required: requiredIf(this.showPriceInput)},
        commentForPrestataire: {},
      },
    };
  },
  mounted() {},
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {};
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  watch: {
    submitted: function (newType) {
      this.pSubmitted = newType;
    },
    disabled: function (newType) {
      this.pDisabled = newType;
    },
  },
  methods: {},
};
</script>

<template>
  <AkInputMoney
    v-if="this.showPriceInput"
    v-model="currentValue.priceHt"
    :label="this.$t('mission_order.offer_price') + (index + 1)"
    :placeholder="this.$t('mission_order.offer_price') + (index + 1)"
    :validator="v$.currentValue.priceHt"
    :min="0"
    class-name="col-md-12" />
  <AkInputTextArea
    v-model="currentValue.commentForPrestataire"
    :label="this.$t('mission_order.comment_on_offer') + (index + 1)"
    :placeholder="this.$t('mission_order.comment_on_offer') + (index + 1)"
    :validator="v$.currentValue.commentForPrestataire"
    class-name="col-md-12"
    :rows="4" />
</template>
