<script>
/* COMPOSANTS */
import AkColorPicker from '@components/input/AkColorPicker';
import AkDocumentModelDialog from '@components/general/AkDocumentModelDialog';
import AkDropdown from '@components/input/AkDropdown';
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import AkInputGroup from '@components/input/AkInputGroup';
import AkInputInteger from '@components/input/AkInputInteger';
import AkInputText from '@components/input/AkInputText';
import AkLoader from '@components/general/AkLoader';
import AkMultiSelect from '@components/input/AkMultiSelect';
import AkYesNoButton from '@components/input/AkYesNoButton';
import GaContentView from '@components/layout/GaContentView';

/* MIXINS */
import categoryConst from '@mixins/const/categoryConst';
import civilitiesConst from '@mixins/const/civilitiesConst';
import countryConst from '@mixins/const/countryConst';
import idProofsConst from '@mixins/const/idProofsConst';
import languageConst from '@mixins/const/languageConst';
import maritalStatusConst from '@mixins/const/maritalStatusConst';
import motifConst from '@mixins/const/motifConst';
import otherCategoryConst from '@mixins/const/otherCategoryConst';
import permissionsMixin from '@mixins/permissionsMixin';
import prospectSourceConst from '@mixins/const/prospectSourceConst';
import randomRef from '@mixins/randomRef';
import roleKeysConst from '@mixins/const/roleKeysConst';
import roleTypesConst from '@mixins/const/roleTypesConst';
import typologyConst from '@mixins/const/typologyConst';
import usualTransportModeConst from '@mixins/const/usualTransportModeConst';
import utilsMixin from '@mixins/utilsMixin';

/* SERVICES */
import companyService from '@services/companyService';
import employeeService from '@services/employeeService';
import geoSectorService from '@services/geoSectorService';
import itemLabelService from '@services/itemLabelService';
import permissionService from '@services/permissionService';
import poleService from '@services/poleService';
import AkAddressFull from '@components/input/AkAddressFull';
import AkDropdownGroup from '@components/input/AkDropdownGroup.vue';

export default {
  components: {
    AkDropdownGroup,
    AkAddressFull,
    AkColorPicker,
    AkDocumentModelDialog,
    AkDropdown,
    AkFormSubmitted,
    AkInputGroup,
    AkInputInteger,
    AkInputText,
    AkLoader,
    AkMultiSelect,
    AkYesNoButton,
    GaContentView,
  },
  mixins: [
    categoryConst,
    civilitiesConst,
    countryConst,
    idProofsConst,
    languageConst,
    maritalStatusConst,
    motifConst,
    otherCategoryConst,
    permissionsMixin,
    prospectSourceConst,
    randomRef,
    roleKeysConst,
    roleTypesConst,
    typologyConst,
    usualTransportModeConst,
    utilsMixin,
  ],
  props: {
    modelValue: {required: true},
  },
  data() {
    return {
      geoSector: [],
      companies: [],
      poles: [],
      jobs: [],
    };
  },
  mounted() {
    this.$refs.loader.load();

    let p1 = geoSectorService.sectors();
    p1.then((data) => {
      this.geoSector = data;
    });

    let p2 = companyService.companies();
    p2.then((data) => {
      this.companies = data;
    });

    let p3 = poleService.poles();
    p3.then((data) => {
      this.poles = data;
    });

    let p4 = itemLabelService.itemLabels({type: 'JOBS', enabled: true});
    p4.then((data) => {
      this.jobs = data;
    });

    Promise.all([p1, p2, p3, p4]).then(() => {
      this.$refs.loader.hide();
    });
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    isUserAdmin() {
      return permissionService.isAdmin();
    },
    isUserGas() {
      return permissionService.isGas();
    },
    isUserGav() {
      return permissionService.isGav();
    },
    isRoleTypeGav() {
      return this.currentValue.roleType === 'GAV';
    },
    isRoleTypeGas() {
      return this.currentValue.roleType === 'GAS';
    },
    isRoleTypeDefined() {
      return this.isRoleTypeGav || this.isRoleTypeGas;
    },
    buildRoleKeys() {
      if (this.isRoleTypeGav) return this.roleKeysForGav;
      if (this.isRoleTypeGas) return this.roleKeysForGas;
      return null;
    },
    disableRoleType() {
      return !this.isUserAdmin;
    },
    displayCompany() {
      return this.isUserAdmin && this.isRoleTypeGav;
    },
    displayRoleKey() {
      return this.isRoleTypeDefined;
    },
    requireCompany() {
      return this.isUserAdmin && this.isRoleTypeGav;
    },
    hasDrivingPermit() {
      return this.currentValue.drivingPermit === true;
    },
    hasRegisteredEmploymentCenter() {
      return this.currentValue.registeredEmploymentCenter === true;
    },
    hasNoComplementaryHealth() {
      return this.currentValue.subscribedToComplementaryHealth === false;
    },
    hasHandicappedWorkerRecognition() {
      return this.currentValue.handicappedWorkerRecognition === true;
    },
    canDisable() {
      if (this.isMe(this.currentValue.userId)) return false;
      return this.isGav();
    },
  },
  methods: {
    displayLabel(data) {
      return data.label;
    },
    enableUser() {
      this.getRef().showLoader(this.$refs.form);
      employeeService
        .enable(this.currentValue)
        .then((data) => {
          this.currentValue = data;
          this.getRef().hideLoader();
          this.getRef().success(this.$t('employee.enabled'));
        })
        .catch((e) => {
          this.getRef().hideLoader();
          this.getRef().error(this.$t('error.' + e.response.data.error));
        });
    },
    disableUser() {
      this.getRef().showLoader(this.$refs.form);
      employeeService
        .disable(this.currentValue)
        .then((data) => {
          this.currentValue = data;
          this.getRef().hideLoader();
          this.getRef().success(this.$t('employee.disabled'));
        })
        .catch((e) => {
          this.getRef().hideLoader();
          this.getRef().error(this.$t('error.' + e.response.data.error));
        });
    },
    openDownloadDialog() {
      this.$refs.dialogDownload.show();
    },
    downloadTemplate(templateId) {
      return employeeService.generateModel(templateId, this.currentValue.id);
    },
  },
};
</script>

<template>
  <AkLoader ref="loader" :message="$t('currently_loading')" :scale="1.5" color="black">
    <template #content>
      <GaContentView :ref="ref" :title="$t('employee.details')">
        <template #action>
          <button v-if="false" class="btn btn-inverse-secondary btn-xs mr-1" @click="openDownloadDialog">
            <i class="fe fe-download pr-1" />{{ $t('document_model.working_contract') }}
          </button>
          <router-link
            v-if="currentValue.id"
            :to="{name: 'employeeUpdate', params: {employeeId: modelValue.id}}"
            class="btn btn-inverse-primary btn-xs">
            <i class="fe fe-edit pr-1" />{{ $t('employee.updateThis') }}
          </router-link>
          <span v-if="!currentValue.userEnabled" class="btn btn-success btn-xs ml-1" @click="enableUser()">
            <i class="gst-check pr-1" />
            {{ $t('enable') }}
          </span>
          <span
            v-if="this.canDisable && currentValue.userEnabled"
            class="btn btn-danger btn-xs ml-1"
            @click="disableUser()">
            <i class="fe fe-x pr-1" />
            {{ $t('disable') }}
          </span>
        </template>

        <template #content>
          <AkFormSubmitted :disabled="true" reference="form">
            <h6>{{ $t('employee.personalInformation') }}</h6>
            <div class="form-row">
              <AkDropdown
                v-model="currentValue.roleType"
                :label="$t('employee.roleType')"
                :options="roleTypesConst"
                class-name="col-md-4" />

              <AkDropdown
                v-if="isRoleTypeGav"
                v-model="currentValue.roleKey"
                :label="$t('employee.roleKey')"
                :options="buildRoleKeys"
                class-name="col-md-4" />

              <AkDropdownGroup
                v-if="isRoleTypeGas"
                v-model="currentValue.roleKey"
                :label="$t('employee.roleKey')"
                :options="buildRoleKeys"
                class-name="col-md-4" />

              <AkDropdown
                v-if="displayCompany"
                v-model="currentValue.companyId"
                :label="$t('employee.company')"
                :options="companies"
                class-name="col-md-4"
                option-label="name"
                option-value="id" />
              <AkDropdown
                v-model="currentValue.civility"
                :label="$t('employee.civility')"
                :options="civilitiesConst"
                class-name="col-md-4" />
            </div>
            <div class="form-row">
              <AkInputText v-model="currentValue.firstName" :label="$t('employee.firstName')" class-name="col-md-4" />
              <AkInputText v-model="currentValue.lastName" :label="$t('employee.lastName')" class-name="col-md-4" />
              <AkInputText v-model="currentValue.maidenName" :label="$t('employee.maidenName')" class-name="col-md-4" />
            </div>
            <div class="form-row">
              <AkInputText
                v-model="currentValue.birthdate"
                :displayer="displayDate"
                :label="$t('employee.birthdate')"
                class-name="col-md-4" />
              <AkInputText v-model="currentValue.birthPlace" :label="$t('employee.birthPlace')" class-name="col-md-4" />
              <AkInputText
                v-model="currentValue.birthDepartment"
                :label="$t('employee.birthDepartment')"
                class-name="col-md-4" />
            </div>
            <div class="form-row">
              <AkInputText
                v-model="currentValue.birthCountry"
                :label="$t('employee.birthCountry')"
                :model-value="displayForCountryConst(currentValue.birthCountry)"
                class-name="col-md-4" />
              <AkDropdown
                v-model="currentValue.maritalStatus"
                :label="$t('employee.maritalStatus')"
                :options="maritalStatusConst"
                class-name="col-md-4" />
              <AkInputInteger
                v-model="currentValue.childrenNumber"
                :label="$t('employee.childrenNumber')"
                class-name="col-md-4" />
            </div>
            <div class="form-row">
              <AkInputGroup
                v-model="currentValue.email"
                :label="$t('employee.email')"
                class-name="col-md-4"
                icon="fa fa-envelope" />
              <AkColorPicker v-model="currentValue.color" :label="$t('employee.color')" class-name="col-md-4" />
              <AkMultiSelect
                v-if="isRoleTypeGas"
                v-model="currentValue.poleIds"
                :label="$t('employee.poles')"
                :options="poles"
                class-name="col-md-4"
                option-label="name"
                option-value="id" />
            </div>
            <div class="form-row">
              <AkDropdown
                v-model="currentValue.source"
                :label="$t('employee.source')"
                :options="prospectSourceConst"
                class-name="col-md-4" />
            </div>
            <h6>{{ $t('employee.contact') }}</h6>
            <AkAddressFull
              v-model="currentValue"
              :always-display-map="false"
              :can-change-position="false"></AkAddressFull>
            <div class="form-row">
              <AkInputGroup
                v-model="currentValue.phoneNumber"
                :label="$t('employee.phoneNumber')"
                class-name="col-md-4"
                icon="fa fa-phone" />
              <AkInputGroup
                v-model="currentValue.mobileNumber"
                :label="$t('employee.mobileNumber')"
                class-name="col-md-4"
                icon="fa fa-phone" />
            </div>
            <h6>{{ $t('employee.identityDocument') }}</h6>
            <div class="form-row">
              <AkInputText
                v-model="currentValue.identityDocument"
                :label="$t('employee.identityDocument')"
                :model-value="displayForIDProofConst(currentValue.identityDocument)"
                class-name="col-md-4" />
              <AkInputText v-model="currentValue.idNumber" :label="$t('employee.idNumber')" class-name="col-md-4" />
              <AkInputText v-model="currentValue.idIssuedAt" :label="$t('employee.idIssuedAt')" class-name="col-md-4" />
            </div>
            <div class="form-row">
              <AkInputText
                v-model="currentValue.idValidUntil"
                :displayer="displayDate"
                :label="$t('employee.idValidUntil')"
                class-name="col-md-4" />
              <AkInputText
                v-model="currentValue.nationality"
                :label="$t('employee.nationality')"
                :model-value="displayForCountryConst(currentValue.nationality)"
                class-name="col-md-4" />
              <AkInputText
                v-model="currentValue.socialSecurityNumber"
                :label="$t('employee.socialSecurityNumber')"
                class-name="col-md-4" />
            </div>
            <div class="form-row">
              <AkYesNoButton
                v-model="currentValue.drivingPermit"
                :label="$t('employee.drivingPermit')"
                class-name="col-md-4" />
              <AkInputText
                v-if="hasDrivingPermit"
                v-model="currentValue.drivingPermitNumber"
                :label="$t('employee.drivingPermitNumber')"
                class-name="col-md-4" />
              <AkInputText
                v-if="hasDrivingPermit"
                v-model="currentValue.drivingPermitObtainingDate"
                :displayer="displayDate"
                :label="$t('employee.drivingPermitObtainingDate')"
                class-name="col-md-4" />
              <AkInputText
                v-if="hasDrivingPermit"
                v-model="currentValue.drivingPermitValidityDate"
                :displayer="displayDate"
                :label="$t('employee.drivingPermitValidityDate')"
                class-name="col-md-4" />
            </div>
            <div class="form-row">
              <AkYesNoButton
                v-model="currentValue.registeredEmploymentCenter"
                :label="$t('employee.registeredEmploymentCenter')"
                class-name="col-md-4" />
              <AkInputText
                v-if="hasRegisteredEmploymentCenter"
                v-model="currentValue.employmentCenterMembershipNumber"
                :label="$t('employee.employmentCenterMembershipNumber')"
                class-name="col-md-4" />
            </div>
            <h6>{{ $t('employee.professionalInformation') }}</h6>
            <div class="form-row">
              <AkInputText
                v-model="currentValue.seniorityDate"
                :displayer="displayDate"
                :label="$t('employee.seniorityDate')"
                class-name="col-md-4" />
              <AkInputText
                v-model="currentValue.entryDate"
                :displayer="displayDate"
                :label="$t('employee.entryDate')"
                class-name="col-md-4" />
              <AkInputText v-model="currentValue.entryHour" :label="$t('employee.entryHour')" class-name="col-md-4" />
            </div>
            <div class="form-row">
              <AkInputText v-model="currentValue.dpaeNumber" :label="$t('employee.dpaeNumber')" class-name="col-md-4" />
              <AkInputText
                v-model="currentValue.lastMedicalVisitDate"
                :displayer="displayDate"
                :label="$t('employee.lastMedicalVisitDate')"
                class-name="col-md-4" />
              <AkInputText
                v-model="currentValue.nextMedicalVisitDate"
                :displayer="displayDate"
                :label="$t('employee.nextMedicalVisitDate')"
                class-name="col-md-4" />
            </div>
            <div class="form-row">
              <AkDropdown
                v-model="currentValue.usualTransportMode"
                :label="$t('employee.usualTransportMode')"
                :options="usualTransportModeConst"
                class-name="col-md-4" />
            </div>
            <div class="form-row">
              <AkYesNoButton
                v-model="currentValue.subscribedToComplementaryHealth"
                :label="$t('employee.subscribedToComplementaryHealth')"
                class-name="col-md-4" />
              <AkDropdown
                v-if="hasNoComplementaryHealth"
                v-model="currentValue.motif"
                :label="$t('employee.motif')"
                :options="motifConst"
                class-name="col-md-4" />
              <AkInputText
                v-if="hasNoComplementaryHealth"
                v-model="currentValue.commitmentEndDate"
                :displayer="displayDate"
                :label="$t('employee.commitmentEndDate')"
                class-name="col-md-4" />
            </div>
            <div class="form-row">
              <AkYesNoButton
                v-model="currentValue.handicappedWorkerRecognition"
                :label="$t('employee.handicappedWorkerRecognition')"
                class-name="col-md-4" />
            </div>
            <div v-if="hasHandicappedWorkerRecognition">
              <h6>{{ $t('employee.recognitionGrantedByTheCDAPH') }}</h6>
              <div class="form-row">
                <AkYesNoButton v-model="currentValue.rqth" :label="$t('employee.rqth')" class-name="col-md-4" />
                <AkYesNoButton v-model="currentValue.aah" :label="$t('employee.aah')" class-name="col-md-4" />
                <AkYesNoButton
                  v-model="currentValue.disabilityCard"
                  :label="$t('employee.disabilityCard')"
                  class-name="col-md-4" />
              </div>
              <div class="form-row">
                <AkInputText
                  v-model="currentValue.validityStartDate"
                  :displayer="displayDate"
                  :label="$t('employee.validityStartDate')"
                  class-name="col-md-4" />
                <AkInputText
                  v-model="currentValue.validityEndDate"
                  :displayer="displayDate"
                  :label="$t('employee.validityEndDate')"
                  class-name="col-md-4" />
                <AkInputText
                  v-model="currentValue.renewalApplicationSubmissionDate"
                  :displayer="displayDate"
                  :label="$t('employee.renewalApplicationSubmissionDate')"
                  class-name="col-md-4" />
                <AkInputText
                  v-model="currentValue.disabilityRate"
                  :label="$t('employee.disabilityRate')"
                  class-name="col-md-4" />
              </div>
              <h6>{{ $t('employee.disabilityCauses') }}</h6>
              <div class="form-row">
                <AkDropdown
                  v-model="currentValue.typology"
                  :label="$t('employee.typology')"
                  :options="typologyConst"
                  class-name="col-md-3" />
                <AkInputText
                  v-model="currentValue.disabilityValidityStartDate"
                  :displayer="displayDate"
                  :label="$t('employee.disabilityValidityStartDate')"
                  class-name="col-md-3" />
                <AkInputText v-model="currentValue.ippRate" :label="$t('employee.IPPRate')" class-name="col-md-3" />
                <AkDropdown
                  v-model="currentValue.category"
                  :label="$t('employee.category')"
                  :options="categoryConst"
                  class-name="col-md-3" />
              </div>
              <h6>{{ $t('employee.otherCategories') }}</h6>
              <div class="form-row">
                <AkDropdown
                  v-model="currentValue.otherCategory"
                  :label="$t('employee.otherCategory')"
                  :options="otherCategoryConst"
                  class-name="col-md-3" />
                <AkInputText
                  v-model="currentValue.pensionStartDate"
                  :displayer="displayDate"
                  :label="$t('employee.pensionStartDate')"
                  class-name="col-md-3" />
              </div>
            </div>
            <h6>{{ $t('employee.bankingInformation') }}</h6>
            <div class="form-row">
              <AkInputText v-model="currentValue.iban" :label="$t('employee.iban')" class-name="col-md-4" />
              <AkInputText v-model="currentValue.bic" :label="$t('employee.bic')" class-name="col-md-4" />
            </div>
            <h6>{{ $t('employee.skills') }}</h6>
            <div class="form-row">
              <AkMultiSelect
                v-model="currentValue.languages"
                :label="$t('employee.languages')"
                :options="languages"
                class-name="col-md-4"
                return-type="string"
                separator=";" />
              <AkYesNoButton v-model="currentValue.graduate" :label="$t('employee.graduate')" class-name="col-md-4" />
              <AkInputText v-model="currentValue.diplomas" :label="$t('employee.diplomas')" class-name="col-md-4" />
            </div>
            <h6>{{ $t('employee.job') }}</h6>
            <div class="form-row">
              <AkDropdown
                v-model="currentValue.jobId"
                :label="$t('employee.job')"
                :option-label="displayLabel"
                :option-value="(data) => data.id"
                :options="jobs"
                class-name="col-md-4" />
              <AkMultiSelect
                v-model="currentValue.geoSectorIds"
                :label="$t('employee.geosector')"
                :option-label="displayLabel"
                :option-value="(data) => data.id"
                :options="geoSector"
                class-name="col-md-8"
                separator=";" />
            </div>
            <div class="form-row">
              <AkYesNoButton
                v-model="currentValue.multiEmployer"
                :label="$t('employee.multiEmployer')"
                class-name="col-md-4" />
            </div>
            <div class="form-row">
              <AkInputText
                v-if="currentValue.employer1 != null"
                v-model="currentValue.employer1"
                :label="$t('employee.employer1')"
                class-name="col-md-4" />
              <AkInputText
                v-if="currentValue.duration1 != null"
                v-model="currentValue.duration1"
                :label="$t('employee.duration')"
                class-name="col-md-4" />
            </div>
            <div class="form-row">
              <AkInputText
                v-if="currentValue.employer2 != null"
                v-model="currentValue.employer2"
                :label="$t('employee.employer2')"
                class-name="col-md-4" />
              <AkInputText
                v-if="currentValue.duration2 != null"
                v-model="currentValue.duration2"
                :label="$t('employee.duration')"
                class-name="col-md-4" />
            </div>
            <div class="form-row">
              <AkInputText
                v-if="currentValue.employer3 != null"
                v-model="currentValue.employer3"
                :label="$t('employee.employer3')"
                class-name="col-md-4" />
              <AkInputText
                v-if="currentValue.duration3 != null"
                v-model="currentValue.duration3"
                :label="$t('employee.duration')"
                class-name="col-md-4" />
            </div>
            <div class="form-row">
              <AkInputText
                v-if="currentValue.employer4 != null"
                v-model="currentValue.employer4"
                :label="$t('employee.employer4')"
                class-name="col-md-4" />
              <AkInputText
                v-if="currentValue.duration4 != null"
                v-model="currentValue.duration4"
                :label="$t('employee.duration')"
                class-name="col-md-4" />
            </div>
            <div class="form-row">
              <AkInputText
                v-if="currentValue.employer5 != null"
                v-model="currentValue.employer5"
                :label="$t('employee.employer5')"
                class-name="col-md-4" />
              <AkInputText
                v-if="currentValue.duration5 != null"
                v-model="currentValue.duration5"
                :label="$t('employee.duration')"
                class-name="col-md-4" />
            </div>
          </AkFormSubmitted>
        </template>
      </GaContentView>
    </template>
  </AkLoader>

  <AkDocumentModelDialog
    v-if="false"
    ref="dialogDownload"
    :auto-hide-on-validate="true"
    :call="
      (templateId) => {
        this.downloadTemplate(templateId);
      }
    "
    sub-type="contrat"
    title="Contrat de travail"
    type="RH"
    width="450px">
    <span>Télécharger</span>
  </AkDocumentModelDialog>
</template>
