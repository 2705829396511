<script>
import moment from 'moment/moment';
import {Icon} from '@iconify/vue';

/* COMPOSANTS */
import AkReservationLine from '@components/general/AkReservationLine';
import AkReservationTooltip from '@components/general/AkReservationTooltip';

/* MIXINS */
import reservationTypesConst from '@mixins/const/reservationTypesConst';
import blockadeReasonsConst from '@mixins/const/blockadeReasonsConst';

/* SERVICES */

export default {
  components: {
    AkReservationLine,
    AkReservationTooltip,
    Icon,
  },
  mixins: [blockadeReasonsConst, reservationTypesConst],
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    dateRef: {
      type: moment,
      required: false,
      default: undefined,
    },
    nbDaysDisplay: {
      required: false,
      default: 15,
    },
  },
  emits: ['hide-loader', 'next', 'prev', 'update:modelValue', 'needReload', 'add-reservation', 'edit-reservation'],
  data() {
    return {
      datePlanning: moment(),
      width: 0,
      reservationTooltip: undefined,
    };
  },
  mounted() {
    this.width = this.$refs.slots.clientWidth;
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    pixelMinus() {
      return this.nbDaysDisplay === 15 ? -1 : -1;
    },
    getWidth() {
      return this.width / this.nbDaysDisplay;
    },
    dateReference() {
      if (this.dateRef === undefined) return this.datePlanning;
      return this.dateRef;
    },
    nbDays() {
      return this.dateReference.daysInMonth();
    },
    slots() {
      let list = [];

      //N'afficher que deux semaines
      //for (let i = -10; i < 20; i++) {$
      let start = -10;
      let end = 20;
      if (this.nbDaysDisplay === 15) {
        start = -5;
        end = 10;
      }
      for (let i = start; i < end; i++) {
        let date = moment(this.dateReference).add(i, 'd').startOf('date');
        let slot = {};
        slot.date = date;
        slot.firstDayOfMonth = date.date() === 1;
        slot.firstDay = i === start;
        slot.dayNumber = date.format('D');
        slot.wk = date.isoWeekday() === 7 || date.isoWeekday() === 6;
        slot.today = date.isSame(moment().startOf('date'));
        slot.filter = date.isSame(moment(this.dateReference).startOf('date'));
        list.push(slot);
      }
      return list;
    },
  },
  watch: {},
  updated() {
    this.$emit('hide-loader');
  },
  methods: {
    getDuration(n) {
      let endOfMonth = moment(n.date).endOf('month');
      let lastSlot = this.slots[this.slots.length - 1];
      let endOfPeriod = lastSlot.date;

      if (endOfPeriod.isBefore(endOfMonth)) return endOfPeriod.diff(n.date, 'days') + 1;
      return endOfMonth.diff(n.date, 'days') + 1;
    },
    formatMonth(n) {
      return n.date.format('MMMM YYYY');
    },
    formatDayLabel(n) {
      return n.date.format('dd');
    },
    formatDayNumber(n) {
      return n.date.format('DD');
    },
    nextDays() {
      this.$emit('next');
    },
    prevDays() {
      this.$emit('prev');
    },
    displayTooltip(event, reservation) {
      this.$refs.tooltip.hide();
      let that = this;
      setTimeout(function () {
        that.reservationTooltip = reservation ? reservation : undefined;
        if (that.reservationTooltip) {
          let target = event.target;
          if (target !== undefined) that.$refs.tooltip.toggle(event, target);
        }
      }, 100);
    },
  },
};
</script>

<template>
  <div class="ak-planning">
    <div class="header">
      <div class="left">Logement</div>
      <div class="center">
        <div class="action">
          <Icon icon="eva:chevron-left-outline" @click="prevDays()" />
          <Icon icon="eva:chevron-right-outline" @click="nextDays()" />
        </div>
        <div ref="slots" class="slots-title">
          <div
            v-for="n in slots"
            v-show="n.firstDayOfMonth || n.firstDay"
            :key="n"
            :class="{first: n.firstDayOfMonth || n.firstDay, firstDayMonth: n.firstDayOfMonth}"
            class="slot">
            <span
              v-show="(n.firstDayOfMonth || n.firstDay) && getDuration(n) > 3"
              :style="{width: this.getWidth * getDuration(n) + this.pixelMinus + 'px'}"
              class="title"
              >{{ formatMonth(n) }}</span
            >

            <span
              v-show="(n.firstDayOfMonth || n.firstDay) && getDuration(n) <= 3"
              :style="{width: this.getWidth * getDuration(n) + this.pixelMinus + 'px'}"
              class="title" />
          </div>
        </div>
        <div class="slots">
          <div
            v-for="n in slots"
            :key="n"
            :class="{wk: n.wk, today: n.today, firstDayMonth: n.firstDayOfMonth}"
            class="slot">
            <span class="header">{{ formatDayLabel(n) }}</span>
            <span class="header">{{ formatDayNumber(n) }}</span>
          </div>
        </div>
      </div>
    </div>
    <perfect-scrollbar>
      <div v-if="currentValue && currentValue.length > 0" class="body" style="max-height: calc(100vh - 370px)">
        <AkReservationLine
          v-for="(p, idx) in currentValue"
          :key="p"
          v-model="currentValue[idx]"
          :nb-days="nbDays"
          :slots="slots"
          :width-slot="this.getWidth"
          @tooltip="displayTooltip" />
      </div>
      <div v-else class="empty-planning">
        <div>
          <Icon icon="bi:calendar-range" />
        </div>
        <div>Aucune réservation</div>
      </div>
    </perfect-scrollbar>
  </div>
  <AkReservationTooltip
    @add-reservation="
      (year, month, day) => {
        this.$emit('add-reservation', year, month, day);
      }
    "
    @edit-reservation="
      (args) => {
        this.$emit('edit-reservation', args);
      }
    "
    @need-reload="
      (args) => {
        this.$emit('needReload', args);
      }
    "
    ref="tooltip"
    v-model="reservationTooltip" />
</template>
