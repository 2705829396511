<script>
import useVuelidate from '@vuelidate/core';
import {ref} from 'vue';

/* COMPOSANTS */
import PButton from 'primevue/button';

/* MIXINS */
import loaderMixin from '@mixins/loaderMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';

/* SERVICES */
import comptaAccountService from '@services/comptaAccountService';
import companyService from '@services/companyService';

export default {
  components: {
    PButton,
  },
  mixins: [loaderMixin, utilsMixin, sidebarMixin],
  metaInfo() {
    return {
      title: 'accounting.third_party_account',
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      accounts: [],
      loading: true,
      companies: [],
      filter: {
        mandatResiliated: false,
        sort: ['mandat_num:DESC'],
      },
    };
  },
  validations() {
    return {};
  },
  mounted() {
    this.loading = true;

    let p1 = comptaAccountService.thirdPartyAccount(this.filter).then((data) => {
      this.accounts = data;
      this.trimStartForAccountsKeys();
    });

    let p2 = companyService
      .companies()
      .then((data) => {
        this.companies = data;
      })
      .catch((e) => {
        this.addError(this.$t('unknow_error'));
      });

    Promise.all([p1, p2]).then(() => {
      this.loading = false;
    });
  },
  methods: {
    ref,
    search() {
      this.loading = true;
      comptaAccountService.thirdPartyAccount(this.filter).then((data) => {
        this.accounts = data;
        this.trimStartForAccountsKeys();
        this.loading = false;
      });
    },
    trimStartForAccountsKeys() {
      this.$nextTick(() => {
        this.accounts = this.trimStartForArrayKeys(this.accounts, ['accommodationName', 'ownerName']);
      });
    },
    clickSearch() {
      this.search();
    },
    submitForm() {
      this.search();
    },
    exportThirdPartyDetails() {
      this.showTotalLoaderWithAfter(this.$t('account.third_party_creation_in_progress'));
      comptaAccountService.exportThirdPartyAccount(this.filter).then(() => {
        this.hideLoader();
      });
    },
    openFilterPanel() {
      this.toggleFilter('GaThirdPartyAccountFilterList', this.filter, this.clickSearch, this.resetFilter);
    },
    resetFilter() {
      this.filter = {
        sort: ['mandat_num:DESC'],
      };
      this.toggleFilter('GaThirdPartyAccountFilterList', this.filter, this.search, this.resetFilter);
    },
  },
  computed: {
    numberOfResults() {
      return this.accounts.length;
    },
  },
};
</script>
<template>
  <GaView :ref="ref" :title="$t('accounting.third_party_account')">
    <template #action>
      <div class="d-flex justify-content-end" style="gap: 16px">
        <PButton
          :badge="this.countFilter(this.filter)"
          badgeClass="p-badge-primary"
          class="btn btn-primary"
          label="Filtrer"
          @click="openFilterPanel()" />
        <button v-if="accounts.length > 0" class="btn btn-primary" @click="exportThirdPartyDetails()">
          {{ this.$t('payment.download') }}
        </button>
      </div>
    </template>

    <template #content>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive">
                <DataTable
                  :always-show-paginator="false"
                  :paginator="true"
                  :rows="50"
                  :rows-per-page-options="[10, 20, 50]"
                  :value="accounts"
                  :loading="loading"
                  class="table"
                  current-page-report-template="{first}-{last}/{totalRecords}"
                  removable-sort
                  responsive-layout="scroll"
                  sort-field="mandatNum"
                  :sort-order="-1"
                  striped-rows>
                  <template #header>
                    <div class="d-flex align-items-center justify-content-start header-legend-result">
                      {{ $t('payment.number_results') }} {{ numberOfResults }}
                    </div>
                  </template>
                  <template #empty>
                    {{ $t('accounting.empty') }}
                  </template>
                  <Column :header="$t('settings.mandat_num')" field="mandatNum" :sortable="true">
                    <template #body="slotProps">
                      <router-link
                        v-if="slotProps.data.accountId !== null"
                        :to="{name: 'accountingThirdPartyDetails', params: {accountId: slotProps.data.accountId}}">
                        {{ slotProps.data.mandatNum }}
                      </router-link>
                      <span v-else>
                        {{ slotProps.data.mandatNum }}
                      </span>
                    </template>
                  </Column>
                  <Column :header="$t('accommodation.name')" sortable field="accommodationName">
                    <template #body="slotProps">
                      <router-link
                        v-if="slotProps.data.accommodationId !== null"
                        :to="{
                          name: 'accommodationPage',
                          params: {accommodationId: slotProps.data.accommodationId},
                          hash: '#thirdParty',
                        }">
                        {{ slotProps.data.accommodationName }}
                      </router-link>
                      <span v-else>
                        {{ slotProps.data.accommodationName }}
                      </span>
                    </template>
                  </Column>
                  <Column :header="$t('accommodation.owner')" sortable field="ownerName">
                    <template #body="slotProps">
                      <router-link
                        v-if="slotProps.data.ownerId !== null"
                        :to="{name: 'ownerPage', params: {ownerId: slotProps.data.ownerId}}">
                        {{ slotProps.data.ownerName }}
                      </router-link>
                      <span v-else>
                        {{ slotProps.data.ownerName }}
                      </span>
                    </template>
                  </Column>
                  <Column :header="$t('account.balance')" class="text-right" sortable field="remain">
                    <template #body="slotProps">
                      {{ formatCurrencyEUR(slotProps.data.remain) }}
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </GaView>
</template>

<style>
div .p-column-header-content {
  color: #9e9e9e;
}
</style>
