export default {
  data() {
    return {
      clientTypeConst: [
        {label: this.$t('undefined'), value: undefined},
        {label: 'Particulier', value: 'INDIVIDUAL'},
        {label: 'Societé', value: 'SOCIETY'},
      ],
    };
  },
  methods: {
    displayForClientTypeConst(value) {
      let result = this.clientTypeConst.find((el) => el.value === value);
      return result ? result.label : '';
    },
  },
};
