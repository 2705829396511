<script>
import {ref} from 'vue';

/* COMPOSANTS */
/* MIXINS */
import messageMixin from '@mixins/messageMixin';
import loaderMixin from '@mixins/loaderMixin';

/* SERVICES */
import comptaOperationService from '@services/comptaOperationService';
import AkLoader from '@components/general/AkLoader.vue';
import AkCalendar from '@components/input/AkCalendar.vue';
import AkOdUpdateLine from '@components/input/AkOdUpdateLine';
import bigDecimal from 'js-big-decimal';
import backMixin from '@mixins/backMixin';

export default {
  components: {
    AkOdUpdateLine,
    AkCalendar,
    AkLoader,
  },
  mixins: [messageMixin, loaderMixin, backMixin],
  metaInfo() {
    return {
      title: this.$t('transaction.update'),
    };
  },
  data() {
    return {
      lines: [],
      totalTransaction: 0,
      dateOperation: undefined,
    };
  },
  mounted() {
    this.initTransaction();
  },
  computed: {
    canDeleteLine() {
      return this.lines.length > 2;
    },
  },
  methods: {
    ref,
    initTransaction() {
      this.$refs.loader.load();
      this.dateOperation = undefined;
      let filter = {transactionId: this.$route.params.transactionId};
      comptaOperationService
        .comptaOperations(filter)
        .then((data) => {
          this.lines = data;
          for (let i = 0; i < this.lines.length; i++) {
            this.lines[i].randomId = Math.random().toString(36);
            if (this.lines[i].debit < 0) {
              this.lines[i].debit = this.lines[i].debit * -1;
            }
          }
          this.dateOperation = this.lines[0].date;
          this.typeTransaction = this.lines[0].typeTransaction;
        })
        .finally(() => {
          this.$refs.loader.hide();
        });
    },
    addNewLine(index) {
      let item = {};
      if (this.dateOperation !== undefined) {
        item.date = this.dateOperation;
      }
      if (this.typeTransaction !== undefined) {
        item.typeTransaction = this.typeTransaction;
      }
      item.randomId = Math.random().toString(36);
      item.transactionId = this.$route.params.transactionId;
      this.lines.splice(index + 1, 0, item);
    },
    deleteLine(index) {
      if (this.canDeleteLine) {
        this.lines.splice(index, 1);
      } else {
        this.addError(this.$t('operation.limit_size_line'), true, true);
      }
    },
    setDefaultDate() {
      for (let line of this.lines) {
        line.date = this.dateOperation;
      }
    },
    updateTransaction() {
      this.totalTransaction = new bigDecimal(0);
      for (let freeOd of this.lines) {
        //VALIDATION
        if (freeOd.date === undefined) {
          this.addError(this.$t('operation.empty_date'), true, true);
          return;
        }
        if (freeOd.accountId == undefined) {
          this.addError(this.$t('operation.empty_account_num'), true, true);
          return;
        }
        if (freeOd.label === undefined || freeOd.label === '') {
          this.addError(this.$t('operation.empty_label'), true, true);
          return;
        }

        if (freeOd.credit == undefined || freeOd.credit === '') {
          freeOd.credit = 0;
        }
        if (freeOd.debit == undefined || freeOd.debit === '') {
          freeOd.debit = 0;
        }
        if (freeOd.credit !== 0 && freeOd.debit !== 0) {
          this.addError(this.$t('operation.double_amount'), true, true);
          return;
        }
        if (freeOd.credit === 0 && freeOd.debit === 0) {
          this.addError(this.$t('operation.empty_amount'), true, true);
          return;
        }

        let credit = new bigDecimal(freeOd.credit);
        let debit = new bigDecimal(freeOd.debit).negate();
        freeOd.amount = credit.add(debit).round(2, bigDecimal.RoundingModes.HALF_EVEN).getValue();
        this.totalTransaction = this.totalTransaction.add(new bigDecimal(freeOd.amount));
      }
      if (this.totalTransaction.round(2, bigDecimal.RoundingModes.HALF_EVEN).getValue() !== '0.00') {
        this.addError(this.$t('operation.error_transaction'), true, true);
        return;
      }
      this.$refs.loader.load();
      comptaOperationService
        .updateTransaction(this.$route.params.transactionId, this.lines)
        .then(() => {
          this.success(this.$t('transaction.updated'));
          this.initTransaction();
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 412) {
              this.addError(this.$t('error.' + e.response.data.error), true, true);
            }
          }
          this.$refs.loader.hide();
        });
    },
  },
};
</script>
<template>
  <GaView :ref="ref" :title="$t('transaction.update')">
    <template #back>
      <a class="mb-1 d-block pointer" @click="goBack()">
        <i class="fe fe-arrow-left pr-1" />
        {{ $t('back') }}
      </a>
    </template>
    <template #content>
      <AkLoader ref="loader" :message="$t('currently_loading')" :scale="1.5">
        <template #content>
          <div class="row">
            <div class="col-lg-12">
              <div class="card card-statistics">
                <div class="card-body">
                  <div class="table-responsive">
                    <form ref="form" @submit.prevent="updateTransaction">
                      <div class="mb-1">
                        <div class="form-inline" style="column-gap: 15px">
                          <AkCalendar
                            v-model="dateOperation"
                            :display-label="false"
                            :inline="true"
                            :placeholder="$t('operation.date')"
                            class-name="flex-1"
                            @update:model-value="setDefaultDate">
                          </AkCalendar>
                          <span class="flex-1 form-control form-control-invisible"></span>
                          <span class="flex-1 form-control form-control-invisible d-flex align-items-end color-gray">{{
                            $t('accounting.debit')
                          }}</span>
                          <span class="flex-1 form-control form-control-invisible d-flex align-items-end color-gray">{{
                            $t('accounting.credit')
                          }}</span>
                          <span class="d-flex flex-1"></span>
                        </div>
                      </div>

                      <div v-for="(freeOd, index) in this.lines" :key="freeOd.randomId" class="mb-1">
                        <AkOdUpdateLine
                          v-model="this.lines[index]"
                          :can-delete="canDeleteLine"
                          :index="index"
                          @add="addNewLine"
                          @change="setDefaultDate"
                          @delete="deleteLine" />
                      </div>
                      <button
                        :title="$t('operation.edit')"
                        class="btn btn-inverse-primary btn-xs col-md-1"
                        type="submit">
                        {{ $t('operation.edit') }}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </AkLoader>
    </template>
  </GaView>
</template>
