<script>
/* COMPOSANTS */
import GaContentView from '@components/layout/GaContentView';
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import AkInputGroup from '@components/input/AkInputGroup';
import AkInputText from '@components/input/AkInputText';
import AkDropdown from '@components/input/AkDropdown';
import AkLoader from '@components/general/AkLoader';
import AkAddressFull from '@components/input/AkAddressFull';
import AkInputMoney from '@components/input/AkInputMoney.vue';
import AkPictureAccommodation from '@components/general/AkPictureAccommodation.vue';
import HousekeeperPrestationForm from '@views/housekeepers/HousekeeperPrestationForm.vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import civilitiesConst from '@mixins/const/civilitiesConst';
import messageMixin from '@mixins/messageMixin';
import permissionsMixin from '@mixins/permissionsMixin';

/* SERVICES */
import itemLabelService from '@services/itemLabelService';
import accommodationService from '@services/accommodationService';
import housekeeperService from '@services/housekeeperService';
import companyService from '@services/companyService';

export default {
  components: {
    HousekeeperPrestationForm,
    AkPictureAccommodation,
    AkInputMoney,
    AkAddressFull,
    AkDropdown,
    AkInputText,
    AkInputGroup,
    AkFormSubmitted,
    GaContentView,
    AkLoader,
  },
  mixins: [civilitiesConst, messageMixin, permissionsMixin, randomRef, utilsMixin],
  metaInfo() {
    return {
      title: 'housekeeper.details',
    };
  },
  props: {
    modelValue: {
      required: true,
    },
  },
  data() {
    return {
      listFormeJuridique: [],
      accommodations: [],
      companies: [],
      housekeeperPrestations: [],
    };
  },
  mounted() {
    this.$refs.loader.load();
    this.initHousekeeperPrestations();

    const promises = [];
    promises.push(
      itemLabelService.itemLabels({type: 'FORME_JURIDIQUE'}).then((data) => {
        this.listFormeJuridique = data;
      }),
    );
    promises.push(
      accommodationService.accommodations({companyId: this.currentValue.companyId}).then((data) => {
        this.accommodations = data.filter((a) => this.currentValue.accommodationIds.includes(a.id));
      }),
    );
    promises.push(
      companyService.companies().then((data) => {
        this.companies = data;
      }),
    );

    Promise.all(promises).then(() => {
      this.$refs.loader.hide();
    });
  },
  methods: {
    initHousekeeperPrestations() {
      if (this.currentValue.housekeeperPrestations) {
        this.housekeeperPrestations = this.currentValue.housekeeperPrestations;
      }
    },
    getHousekeeperPrestationIndex(accommodation) {
      return this.housekeeperPrestations.map((el) => el.accommodationId).indexOf(accommodation.id);
    },
    generateAccess() {
      this.getRef().showLoader(this.$refs.form);
      housekeeperService
        .access(this.currentValue)
        .then((data) => {
          this.currentValue = data;
          this.getRef().hideLoader();
          this.getRef().success(this.$t('housekeeper.accessSent'));
        })
        .catch((e) => {
          this.getRef().hideLoader();
          this.addError(this.$t('error.' + e.response.data.error));
        });
    },
    enableHousekeeper() {
      this.getRef().showLoader(this.$refs.form);
      housekeeperService
        .enable(this.currentValue)
        .then((data) => {
          this.currentValue = data;
          this.currentValue.userEnabled = true;
          this.getRef().hideLoader();
          this.getRef().success(this.$t('housekeeper.enabled'));
        })
        .catch((e) => {
          this.getRef().hideLoader();
          this.addError(this.$t('error.' + e.response.data.error));
        });
    },
    disableHousekeeper() {
      this.getRef().showLoader(this.$refs.form);
      housekeeperService
        .disable(this.currentValue)
        .then((data) => {
          this.currentValue = data;
          this.currentValue.userEnabled = false;
          this.getRef().hideLoader();
          this.getRef().success(this.$t('housekeeper.disabled'));
        })
        .catch((e) => {
          this.getRef().hideLoader();
          this.addError(this.$t('error.' + e.response.data.error));
        });
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    isLoadingFormeJuridique() {
      return this.listFormeJuridique === undefined;
    },
    hasOwnerAccess() {
      return this.currentValue.userId;
    },
  },
};
</script>

<template>
  <AkLoader ref="loader" :message="$t('currently_loading')" :scale="1.5" color="black">
    <template #content>
      <GaContentView :ref="ref" :title="$t('housekeeper.details')">
        <template #action>
          <a v-if="!hasOwnerAccess" class="btn btn-inverse-secondary btn-xs" @click="generateAccess()">
            <i class="fe fe-lock pr-1" />{{ $t('housekeeper.createAccess') }}
          </a>
          <a v-if="hasOwnerAccess" class="btn btn-inverse-secondary btn-xs" @click="generateAccess()">
            <i class="fe fe-lock pr-1" />{{ $t('housekeeper.resendAccess') }}
          </a>
          <router-link
            :to="{name: 'housekeeperUpdate', params: {housekeeperId: currentValue.id}}"
            class="btn btn-inverse-primary btn-xs ml-1">
            <i class="fe fe-edit pr-1" /> {{ $t('housekeeper.updateThis') }}
          </router-link>
          <span
            v-if="!currentValue.userEnabled"
            class="btn btn-inverse-success btn-xs ml-1"
            @click="enableHousekeeper()">
            <i class="gst-check pr-1" />
            {{ $t('enable') }}
          </span>
          <span
            v-if="currentValue.userEnabled"
            class="btn btn-inverse-danger btn-xs ml-1"
            @click="disableHousekeeper()">
            <i class="fe fe-x pr-1" />
            {{ $t('disable') }}
          </span>
        </template>
        <template #content>
          <AkFormSubmitted :disabled="true" reference="ownerForm">
            <h6 class="title">Contact</h6>
            <div class="form-row">
              <AkDropdown
                v-model="currentValue.contact.formeJuridiqueId"
                :disabled="true"
                :label="$t('contact.formeJuridique')"
                :loading="isLoadingFormeJuridique"
                :options="this.listFormeJuridique"
                class-name="col-3"
                optionLabel="label"
                optionValue="id" />
              <AkInputText
                v-model="currentValue.contact.raisonSociale"
                :disabled="true"
                :label="$t('contact.raisonSociale')"
                class-name="col-3" />
              <AkInputText
                v-model="currentValue.contact.companyName"
                :disabled="true"
                :label="$t('contact.companyName')"
                class-name="col-3" />
              <AkInputMoney
                v-model="currentValue.contact.capital"
                :disabled="true"
                class-name="col-3"
                label="Capital (€)" />
            </div>
            <AkAddressFull
              v-model="currentValue.contact"
              :always-display-map="false"
              :can-change-position="false"
              :disabled="true"
              :label="$t('contact.address1Manager')" />
            <div class="form-row">
              <AkInputGroup
                v-model="currentValue.contact.email"
                :disabled="true"
                :label="$t('contact.email')"
                class-name="col-3"
                icon="fa fa-envelope" />
              <AkInputGroup
                v-model="currentValue.contact.phone"
                :disabled="true"
                :label="$t('contact.phone')"
                class-name="col-3"
                icon="fa fa-phone" />
              <span class="col-6"></span>

              <AkInputText
                v-model="currentValue.contact.villeRcs"
                :disabled="true"
                :label="$t('contact.villeRcs')"
                class-name="col-3" />
              <AkInputText
                v-model="currentValue.contact.siret"
                :disabled="true"
                :label="$t('contact.siret')"
                class-name="col-3" />
              <span class="col-6"></span>

              <AkDropdown
                v-model="currentValue.contact.civility"
                :disabled="true"
                :label="$t('contact.civilityManager')"
                :options="this.getCivilitiesConstForProspect()"
                class-name="col-3" />
              <AkInputText
                v-model="currentValue.contact.lastName"
                :disabled="true"
                :label="$t('contact.lastNameManager')"
                class-name="col-3" />
              <AkInputText
                v-model="currentValue.contact.firstName"
                :disabled="true"
                :label="$t('contact.firstNameManager')"
                class-name="col-3" />
              <AkInputText
                v-model="currentValue.contact.function"
                :disabled="true"
                :label="$t('contact.functionManager')"
                class-name="col-3" />
            </div>
            <h6 v-if="isGas() || isAdmin()" class="title mt-3">Informations complémentaires</h6>
            <div v-if="isGas() || isAdmin()" class="form-row">
              <AkDropdown
                v-model="currentValue.companyId"
                :disabled="true"
                :options="companies"
                class-name="col-6"
                label="Conciergerie locale"
                option-label="name"
                option-value="id" />
            </div>
            <template v-if="accommodations && accommodations.length > 0 && currentValue.companyId">
              <h6 class="title mt-3">Logements</h6>
              <div class="accommodation-cards">
                <div :key="accommodation.id" class="accommodation-card col-4" v-for="accommodation in accommodations">
                  <div class="blue-border accommodation-item">
                    <div class="accommodation-image">
                      <AkPictureAccommodation :md5="accommodation.picture" />
                    </div>
                    <h6 class="accommodation-name">
                      {{ accommodation.name }}
                    </h6>
                    <div class="owner-name">
                      {{ accommodation.ownerContactName }}
                    </div>
                  </div>
                  <div class="accommodation-missions">
                    <HousekeeperPrestationForm
                      v-model="housekeeperPrestations"
                      :index="getHousekeeperPrestationIndex(accommodation)"
                      :isConsultation="true"
                      :receptionTypeCheckin="accommodation.receptionTypeCheckin"
                      :receptionTypeCheckout="accommodation.receptionTypeCheckout" />
                  </div>
                </div>
              </div>
            </template>
          </AkFormSubmitted>
        </template>
      </GaContentView>
    </template>
  </AkLoader>
</template>

<style scoped>
.accommodation-cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .accommodation-card {
    margin-bottom: 1rem;

    > .accommodation-item {
      background-color: white;
      border: 2px solid transparent;
      border-radius: 7px;

      display: grid;
      grid-template-columns: 33%;
      grid-template-rows: auto;
      grid-template-areas:
        'image a-name'
        'image o-name';

      &.blue-border {
        border: 4px solid #40b4e5;
      }

      .accommodation-image {
        grid-area: image;
      }

      .accommodation-name {
        grid-area: a-name;
        font-weight: bold;
        padding: 0.5rem 1rem;
      }

      .owner-name {
        grid-area: o-name;
        align-self: end;
        padding: 0.5rem 1rem;
      }
    }
  }

  .accommodation-missions {
    background: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-bottom: 10px;
    padding-top: 10px;
  }
}
</style>
