<script>
import {Vue3Lottie} from 'vue3-lottie';

export default {
  page: {
    title: 'Unauthorized',
    meta: [{name: 'description', content: 'Unauthorized'}],
  },
  components: {Vue3Lottie},
  props: {
    resource: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
  <div class="d-flex" style="width: 300px; margin: auto">
    <Vue3Lottie :animationData="require('@assets/animations/403.json')" :loop="true" />
  </div>
  <h3 :class="$style.title">
    {{ $t('403') }}
  </h3>
</template>

<style lang="scss" module>
.title {
  text-align: center;
}
</style>
