<script>
import {Icon} from '@iconify/vue/dist/iconify';

/* COMPOSANTS */
import AkInputText from '@components/input/AkInputText.vue';
import AkDropdown from '@components/input/AkDropdown.vue';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import avenantStatusFilterConst from '@mixins/const/avenantStatusFilterConst';

/* SERVICES */

export default {
  components: {
    AkDropdown,
    AkInputText,
    Icon,
  },
  mixins: [utilsMixin, sidebarMixin, avenantStatusFilterConst],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.companyAutocomplete) this.$refs.companyAutocomplete.reset();
      if (this.$refs.ownerAutocomplete) this.$refs.ownerAutocomplete.reset();
      this.callback[1]();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <h2>Filtrer</h2>
  <form class="d-flex flex-column" style="row-gap: 16px" @submit.prevent="search">
    <AkInputText
      v-model="currentValue.mandatNum"
      :inline="true"
      :label="$t('mandat.num')"
      :placeholder="$t('mandat.num')"
      class-name="flex-1" />
    <AkDropdown
      v-model="currentValue.status"
      inline
      :label="$t('mandat.all_avenants')"
      :options="avenantStatusFilterConst"
      class-name="flex-1 form-control"
      option-label="label"
      option-value="value" />

    <div class="d-flex flex-row">
      <button class="btn btn-primary btn-search flex-1" type="submit">
        {{ this.$t('visitor_tax.filter') }}
      </button>
      <Icon
        v-on:click="this.reset()"
        class="pointer"
        icon="basil:cross-outline"
        :style="{fontSize: '50px', width: '50px !important'}" />
    </div>
  </form>
</template>
