<script>
import AkLabel from '@components/general/AkLabel';
import Calendar from 'primevue/calendar';

export default {
  components: {AkLabel, Calendar},
  inject: {pSubmitted: {default: null}, pDisabled: {default: null}},
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    className: {
      type: String,
      required: false,
      default: 'col-md-4 col-6',
    },
    format: {
      type: String,
      required: false,
      default: 'HH:mm',
    },
    validator: {
      type: Object,
      required: false,
      default: undefined,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    isInvalid() {
      return this.validator !== undefined && this.validator.$invalid;
    },
    isRequired() {
      return this.validator !== undefined && this.validator.required !== undefined;
    },
    isValidatorRequired() {
      return (
        this.validator !== undefined &&
        this.validator.required !== undefined &&
        this.validator.required.$invalid &&
        this.alreadySubmitted
      );
    },
  },
  methods: {},
};
</script>

<template>
  <div :class="className" class="form-group">
    <AkLabel :required="isRequired">
      {{ label }}
    </AkLabel>
    <Calendar
      v-model="currentValue"
      :class="{'p-invalid': isInvalid && alreadySubmitted}"
      :date-format="format"
      :disabled="isDisabled"
      hourFormat="24"
      :placeholder="getPlaceholder"
      :time-only="true"
      class="form-control" />
    <small v-if="isValidatorRequired" class="p-error">{{ $t('field_required', [label]) }}</small>
  </div>
</template>
