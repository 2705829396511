<script>
import moment from 'moment/moment';

/* COMPOSANTS */
import AkReservation from '@components/general/AkReservation';
import {Icon} from '@iconify/vue/dist/iconify';
import permissionsMixin from '@mixins/permissionsMixin';
import AkPictureAccommodation from './AkPictureAccommodation';

export default {
  components: {AkPictureAccommodation, AkReservation, Icon},
  mixins: [permissionsMixin],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    slots: {
      type: Array,
      required: true,
    },
    nbDays: {
      type: Number,
      required: true,
    },
    widthSlot: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:modelValue', 'tooltip'],
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  methods: {
    tooltip(event, data) {
      this.$emit('tooltip', event, data);
    },
    getStatus() {
      for (let r of this.currentValue.reservationList) {
        if (this.isActiveToday(r)) {
          return 'busy';
        }
      }
      return 'free';
    },
    getReservationsSlots() {
      return this.currentValue.reservationList;
    },
    isActive(data, resa) {
      return (
        resa.dateStart.startOf('date').isSameOrBefore(data.date) &&
        resa.dateEnd.startOf('date').isSameOrAfter(data.date)
      );
    },
    isActiveToday(resa) {
      return (
        resa.dateStart.startOf('date').isSameOrBefore(moment()) && resa.dateEnd.startOf('date').isSameOrAfter(moment())
      );
    },
  },
};
</script>

<template>
  <div class="line">
    <div class="left">
      <span class="title">
        <div style="width: calc(100% - 14px)" class="d-flex align-items-center overflow-hidden">
          <AkPictureAccommodation
            :height="31"
            :md5="currentValue.accommodation.pictureMd5"
            :radius="2"
            style="margin-right: 5px" />
          <span
            class="overflow-hidden text-overflow-ellipsis white-space-pre"
            :title="
              currentValue.ownerLastName + ' ' + currentValue.ownerFirstName + ' • ' + currentValue.accommodation.name
            "
            ><Icon
              v-if="currentValue.physical && !this.isOwner()"
              class="icon-tooltip-list"
              color="black"
              icon="mdi:human-greeting" />
            <Icon
              v-if="currentValue.laundry && !this.isOwner()"
              class="icon-tooltip-list"
              color="black"
              icon="iconoir:bed-ready" /><span v-if="!this.isOwner()"
              ><span class="owner-slot">{{ currentValue.ownerLastName }}</span>
              {{ currentValue.ownerFirstName }} • </span
            >{{ currentValue.accommodation.name }}
          </span>
        </div>

        <span v-if="!this.isOwner()" :class="getStatus()" class="status" />
      </span>
    </div>
    <div class="center">
      <div class="slots">
        <div
          v-for="n in slots"
          :key="n"
          :class="{wk: n.wk, today: n.today, firstDayMonth: n.firstDayOfMonth}"
          class="slot slot-day">
          {{ n.dayNumber }}
          <span v-if="n.filter" class="date"></span>
          <span v-if="n.today" class="today"></span>
        </div>
      </div>
      <AkReservation
        :accommodation="currentValue.accommodation"
        :reservations="getReservationsSlots()"
        :slots="slots"
        :width-slot="widthSlot"
        @tooltip="tooltip" />
    </div>
  </div>
</template>
