<script>
/* COMPOSANTS */
import GaContentView from '@components/layout/GaContentView';
import AkNewActivity from '@components/general/AkNewActivity';
import AkDialog from '@components/general/AkDialog';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import utilsMixin from '@mixins/utilsMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import activityContactWayConst from '@mixins/const/activityContactWayConst';
import activityMotifConst from '@mixins/const/activityMotifConst';

/* SERVICES */
import activityService from '@services/activityService';
import employeeService from '@services/employeeService';

export default {
  components: {
    GaContentView,
    AkNewActivity,
    AkDialog,
  },
  mixins: [randomRef, utilsMixin, permissionsMixin, activityContactWayConst, activityMotifConst],
  props: {
    modelValue: {required: true},
  },
  data() {
    return {
      loading: true,
      list: [],
      item: {},
    };
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    employeeId() {
      return this.currentValue.id;
    },
  },
  mounted() {
    this.reload();
  },
  methods: {
    reload() {
      this.loading = true;

      let p1 = activityService.activitys({interlocutorId: this.employeeId, interlocutorType: 'SALARIE'});
      p1.then((data) => {
        this.list = data;
      });

      Promise.all([p1]).then(() => {
        this.loading = false;
      });
    },
    delete() {
      activityService
        .delete(this.item)
        .then((data) => {
          this.removeInListById(this.list, data.id);
          this.getRef().success(this.$t('activity.deleted'));
        })
        .catch(() => {
          this.getRef().error(this.$t('unknow_error'));
        });
    },
    openDeleteDialog(item) {
      this.item = item;
      this.$refs.dialogDelete.show();
    },
    newActivityAdded() {
      this.getRef().success(this.$t('activity.added'));
      this.reload();
    },
    newActivityFailed() {
      this.getRef().error(this.$t('unknow_error'));
    },
  },
};
</script>

<template>
  <GaContentView :ref="ref" :title="$t('employee.activity')">
    <template #action>
      <AkNewActivity
        v-if="(!loading && isGav()) || isGas() || isAdmin()"
        interlocutorType="SALARIE"
        :employeeId="employeeId"
        @added="newActivityAdded"
        @failed="newActivityFailed" />
    </template>

    <template #content>
      <div class="row">
        <div class="col-lg-12">
          <div class="table-responsive">
            <DataTable
              :always-show-paginator="false"
              :loading="loading"
              :paginator="true"
              :rows="10"
              :rows-per-page-options="[10, 20, 50]"
              :value="list"
              class="table"
              current-page-report-template="{first}-{last}/{totalRecords}"
              removable-sort
              responsive-layout="scroll"
              striped-rows>
              <template #empty>
                {{ $t('activity.empty') }}
              </template>

              <Column :header="$t('activity.dateHour')" :sortable="true">
                <template #body="slotProps">
                  <router-link :to="{name: 'activityDetails', params: {activityId: slotProps.data.id}}">
                    {{ displayDateTime(slotProps.data.date) }}
                  </router-link>
                </template>
              </Column>
              <Column :header="$t('activity.interlocutor')" :sortable="true">
                <template #body="slotProps">
                  <span>
                    {{ slotProps.data.interlocutorDisplay }}
                  </span>
                </template>
              </Column>
              <Column :header="$t('activity.who')" :sortable="true">
                <template #body="slotProps">
                  <router-link :to="{name: 'activityDetails', params: {activityId: slotProps.data.id}}">
                    {{ slotProps.data.whoDisplay }}
                  </router-link>
                </template>
              </Column>
              <Column :header="$t('activity.contactWay')" :sortable="true">
                <template #body="slotProps">
                  <router-link :to="{name: 'activityDetails', params: {activityId: slotProps.data.id}}">
                    {{ displayForActivityContactWayConst(slotProps.data.contactWay) }}
                  </router-link>
                </template>
              </Column>
              <Column :header="$t('activity.motif')" :sortable="true">
                <template #body="slotProps">
                  <router-link :to="{name: 'activityDetails', params: {activityId: slotProps.data.id}}">
                    {{ displayForActivityMotifConst(slotProps.data.motif) }}
                  </router-link>
                </template>
              </Column>

              <Column body-style="text-align: right; overflow: visible" header-style=" width: 180px; text-align: btn">
                <template #body="slotProps">
                  <div class="d-flex justify-content-end align-items-center">
                    <router-link
                      :to="{name: 'activityDetails', params: {activityId: slotProps.data.id}, hash: '#employee'}"
                      class="btn btn-xs btn-inverse-primary mr-1">
                      <i class="fe fe-eye" />
                    </router-link>
                    <router-link
                      :to="{name: 'activityUpdate', params: {activityId: slotProps.data.id}}"
                      class="btn btn-xs btn-inverse-primary mr-1">
                      <i class="fe fe-edit" />
                    </router-link>
                    <span class="btn btn-xs btn-inverse-danger pointer" @click="openDeleteDialog(slotProps.data)">
                      <i class="fe fe-trash" />
                    </span>
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </template>

    <template #extra>
      <AkDialog
        ref="dialogDelete"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.delete()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span>{{ $t('activity.confirm_delete') }}</span>
        </div>
      </AkDialog>
    </template>
  </GaContentView>
</template>
