<script>
import moment from 'moment/moment';
import {Icon} from '@iconify/vue';

/* MIXINS */
import blockadeReasonsConst from '@mixins/const/blockadeReasonsConst';
import reservationTypesConst from '@mixins/const/reservationTypesConst';
import permissionsMixin from '@mixins/permissionsMixin';

export default {
  components: {Icon},
  mixins: [blockadeReasonsConst, reservationTypesConst, permissionsMixin],
  props: {
    slots: {
      type: Array,
      required: true,
    },
    reservations: {
      type: Array,
      required: true,
    },
    accommodation: {
      type: Object,
      required: true,
    },
    widthSlot: {
      type: Number,
      required: true,
    },
  },
  emits: ['tooltip'],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    tooltip(event, r, s) {
      if (this.isActive(r, s)) this.$emit('tooltip', event, r);
    },
    getDuration(r, s, idx) {
      if (r === undefined) return 0;
      let timeStart = moment(r.dateStart);
      let timeEnd = moment(r.dateEnd);
      let minus = 25;
      let addedTime = 0;
      if (idx === 0 && this.isActive(r, s)) {
        timeStart = moment(s.date);
        minus = this.widthSlot / 2 + 16;
        addedTime = 1;
      }
      let lastSlot = this.slots[this.slots.length - 1];
      if (lastSlot.date.isBefore(timeEnd)) {
        timeEnd = lastSlot.date;
        minus = this.widthSlot / 2 + 16;
        addedTime = 1;
      }
      let size = this.widthSlot * (timeEnd.startOf('day').diff(timeStart.startOf('day'), 'days') + addedTime) - minus;
      return size < 16 ? 16 : size;
    },
    isFirst(r, slot) {
      if (r === undefined) return false;
      return r.dateStart.startOf('date').isSame(slot.date);
    },
    isFirstSlot(r, slot, idx) {
      if (r === undefined) return false;
      return r.dateStart.startOf('date').isSameOrBefore(slot.date) && idx === 0 && this.isActive(r, slot);
    },
    isLast(r, slot) {
      if (r === undefined) return false;
      return r.dateEnd.startOf('date').isSame(slot.date);
    },
    isActive(r, slot) {
      if (r === undefined) return false;
      return (
        r.dateStart.startOf('date').isSameOrBefore(slot.date) && r.dateEnd.startOf('date').isSameOrAfter(slot.date)
      );
    },
    airbnb(r) {
      if (r === undefined) return '';
      return r.reservationSite === 'Airbnb.com';
    },
    booking(r) {
      if (r === undefined) return '';
      return r.reservationSite === 'Booking.com';
    },
    blockade(r) {
      if (r === undefined) return '';
      return r.type === 'BLOCKADE';
    },
    owner(r) {
      if (r === undefined) return '';
      return r.type === 'OWNER';
    },
    hasUpsell(r) {
      return !!(r.upsellList && r.upsellList.length > 0);
    },
    typeCss(r) {
      if (r === undefined) return '';
      if (this.isOwner()) {
        if (r.statusSmily === 'Unavailable' && r.type === 'OWNER') return 'owner';
        if (r.statusSmily === 'Unavailable' && r.type === 'BLOCKADE') return 'blockade';
        if (r.statusSmily === 'Booked') return 'occuped';
        return '';
      }
      if (r.statusSmily === 'Unavailable' && r.type === 'OWNER') return 'owner';
      if (r.statusSmily === 'Unavailable' && r.type === 'BLOCKADE') return 'blockade';
      if (r.statusSmily === 'Booked') return 'confirmada';
      if (r.statusSmily === 'Tentative') return 'peticion';
      return '';
    },
    cssId(r, slot, idxS) {
      if (r === undefined) return '';
      if (this.isFirstSlot(r, slot, idxS)) return r.id.toString();
      if (this.isFirst(r, slot)) return r.id.toString();
      return '';
    },
    title(r) {
      if (r === undefined) return '';
      if (r.type === 'GUEST') return r.title;
      if (r.type === 'BLOCKADE') return this.displayForBlockadeReason(r.title);
      if (r.type === 'OWNER') return this.displayForReservationTypeConst(r.type);
      return '';
    },
    icon(r) {
      if (r === undefined) return '';
      if (this.airbnb(r)) return 'tabler:brand-airbnb';
      if (this.booking(r)) return 'tabler:brand-booking';
      if (this.blockade(r)) return 'tabler:tools';
      if (this.owner(r)) return 'tabler:user';
      return 'mdi:globe';
    },
    canDisplayTitle(r) {
      if (!r) return true;
      if (!r.dateCheckout) return true;
      if (!r.dateCheckin) return true;

      let duration = moment.duration(r.dateCheckout.diff(r.dateCheckin));
      let days = duration.asDays();
      if (days > 3) return true;
      return false;
    },
    period(data) {
      return (
        data.reservation[0].dateStart.format('DD/MM/YYYY') + ' - ' + data.reservation[0].dateEnd.format('DD/MM/YYYY')
      );
    },
  },
  computed: {
    displayableReservations() {
      if (this.isGav()) {
        return this.reservations.filter(
          (o) =>
            o.hidden !== true &&
            this.typeCss(o) !== '' &&
            (o.statusSmily === 'Booked' || o.statusSmily === 'Unavailable'),
        );
      }
      return this.reservations.filter((o) => o.hidden !== true && this.typeCss(o) !== '');
    },
  },
};
</script>

<template>
  <div>
    <div v-for="(r, idx) in this.displayableReservations" :key="idx" class="slots-reservations">
      <div
        v-for="(s, idxS) in slots"
        :key="s"
        :class="[
          typeCss(r),
          cssId(r, s, idxS),
          {
            first: isFirst(r, s) && !isFirstSlot(r, s, idxS),
            firstSlot: isFirstSlot(r, s, idxS),
            last: isLast(r, s),
            'active pointer': isActive(r, s),
          },
        ]"
        class="slot"
        @click="tooltip($event, r, s)">
        <span
          v-if="isFirst(r, s) || isFirstSlot(r, s, idxS)"
          :style="{width: getDuration(r, s, idxS) + 'px'}"
          class="title">
          <Icon :icon="icon(r)" color="white" />
          <Icon v-if="hasUpsell(r)" icon="solar:dollar-outline" color="white" />

          <span v-if="canDisplayTitle(r)">{{ title(r) }}</span>
        </span>
      </div>
    </div>
  </div>
</template>
