import Api from '@/services/api';
import {createFormDataFile, removeEmptyParams} from '@utils';

export default {
  employeeContracts(filter) {
    return Api()
      .get('/employeecontract', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  employeeContract(contractId) {
    return Api()
      .get('/employeecontract/' + contractId)
      .then((res) => this.formatRes(res.data));
  },
  employeeContractsByEmployeeId(employeeId) {
    return Api()
      .get('/employeecontract/employee/' + employeeId)
      .then((res) => this.formatRes(res.data));
  },
  create(contract, file) {
    let data = createFormDataFile(contract, file);
    return Api()
      .post('/employeecontract', data)
      .then((res) => this.formatRes(res.data));
  },
  update(contract) {
    return Api()
      .put('/employeecontract/' + contract.id, contract)
      .then((res) => this.formatRes(res.data));
  },
  delete(contract) {
    return Api()
      .delete('/employeecontract/' + contract.id)
      .then(() => contract);
  },
  formatRes(e) {
    if (e.dateStart) e.dateStart = new Date(e.dateStart);
    if (e.dateEnd) e.dateEnd = new Date(e.dateEnd);
    if (e.trialEndDate) e.trialEndDate = new Date(e.trialEndDate);
    if (e.signatureDate) e.signatureDate = new Date(e.signatureDate);
    if (e.startTime) e.startTime = new Date(e.startTime);

    return e;
  },
};
