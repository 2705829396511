<script>
/* COMPOSANTS */
import GaTabView from '@components/layout/GaTabView';
import MaintenanceOwner from '@views/maintenance/tabs/MaintenanceOwner.vue';
import LitigeOwner from '@views/maintenance/tabs/LitigeOwner.vue';
import AkLoader from '@components/general/AkLoader.vue';

/* MIXINS */
import loaderMixin from '@mixins/loaderMixin';
import utilsMixin from '@mixins/utilsMixin';
import messageMixin from '@mixins/messageMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import maintenanceOwnerTabMixin from '@mixins/tab/maintenanceOwnerTabMixin';

/* SERVICES */

export default {
  components: {
    LitigeOwner,
    MaintenanceOwner,
    AkLoader,
    GaTabView,
  },
  mixins: [messageMixin, utilsMixin, loaderMixin, permissionsMixin, maintenanceOwnerTabMixin],
  metaInfo() {
    return {
      title: 'maintenance.list',
    };
  },
  data() {
    return {
      activeTab: undefined,
    };
  },
  computed: {},
  mounted() {
    this.$refs.loader.load();
    if (this.$route.hash) {
      this.activeTab = this.$route.hash.slice(1);
    }
    this.$refs.loader.hide();
  },
  methods: {
    getFirstTab() {
      if (this.$route.hash) {
        return this.$route.hash.slice(1);
      }
      return 'maintenanceOwner';
    },
  },
};
</script>

<template>
  <AkLoader ref="loader" :message="$t('currently_loading')" :scale="1.5">
    <template #content>
      <GaTabView :firstTab="getFirstTab()" :title="$t('maintenance.list')" :tabs="tabs">
        <template #maintenanceOwner>
          <MaintenanceOwner />
        </template>
        <template #litigeOwner>
          <LitigeOwner />
        </template>
      </GaTabView>
    </template>
  </AkLoader>
</template>
<style></style>
